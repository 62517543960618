import React from 'react';
import Helmet from 'react-helmet';
import { Header, Size, SplashText } from '@pointdotcom/pds';
import AuthenticatedDashboard from 'components/AuthenticatedDashboard';
import DashboardMainHeader from 'components/DashboardMainHeader';
import MainFooter from 'components/MainFooter';
import DashboardModel from 'models/DashboardModel';
import FundingStageListM2 from './FundingStageList';
import i18n from './i18n';
import * as styles from './styles';

const DashboardProcessOverviewPage = ({ dashboard }: { dashboard: DashboardModel }) => {
  return (
    <styles.DashboardProcessOverviewPageStyle>
      <Helmet title={i18n.pageTitle} />
      <DashboardMainHeader
        dashboard={dashboard}
        crumbProps={{ currentCrumb: i18n.subheaderBreadcrumb }}
      />
      <styles.OverviewWrapperStyle flex mobileColumn>
        <styles.HeroStyle>
          <Header styleSize={Size.Splash} noMargin>
            {i18n.processOverview}
          </Header>
          <SplashText>{i18n.thisIsYourRoadmap}</SplashText>
          <img src="https://cdn.point.com/houseandhills.png" alt="house and hills" />
        </styles.HeroStyle>
        <styles.FundingStageWrapperStyle>
          <FundingStageListM2 dashboard={dashboard} />
        </styles.FundingStageWrapperStyle>
      </styles.OverviewWrapperStyle>
      <MainFooter />
    </styles.DashboardProcessOverviewPageStyle>
  );
};

const DashboardProcessOverviewPageWithAuth = () => {
  return (
    <AuthenticatedDashboard>
      {({ dashboard }) =>
        dashboard != null && <DashboardProcessOverviewPage dashboard={dashboard} />
      }
    </AuthenticatedDashboard>
  );
};

export default DashboardProcessOverviewPageWithAuth;
