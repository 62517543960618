import styled from 'styled-components';

export const CapShieldIconStyle = styled.div.attrs({ className: 'CapShieldIconStyle' })`
  height: 1rem;
  width: 0.75rem;
`;

export const CapShieldSvgStyle = styled.svg.attrs({
  className: 'CapShieldSvgStyle',
})`
  display: block;
  width: 100%;
  height: 100%;
  fill: currentColor;
`;
