import { useCallback, useMemo, useState } from 'react';
import { useDeleteTaskFileMutation } from 'services/api/homeownerApi';
import { TaskFile } from 'services/apiTypes/taskTypes';
import { isNonNullish } from 'types/helpers';
import { TaskFileUpload, TaskFileUploader, useTaskFileUpload } from '../useTaskFileUpload';
import i18n from './i18n';

declare const fileItemKeySymbol: unique symbol;
export type FileItemKey = string & { [fileItemKeySymbol]: true };

function getFileItemKeyForTaskFile(taskFile: TaskFile) {
  return `TF${taskFile.fileId}` as FileItemKey;
}
function getFileItemKeyForUpload(upload: TaskFileUpload) {
  return `U${upload.uploadKey}` as FileItemKey;
}

export interface FileItem {
  key: FileItemKey;
  taskFile?: TaskFile;
  upload?: TaskFileUpload;
  isDeleting?: boolean;
  deleteFileError?: string | null;
}

const updateKeys = (
  setKeys: React.Dispatch<React.SetStateAction<Set<FileItemKey>>>,
  fileKey: FileItemKey,
  action: 'add' | 'remove'
) => {
  setKeys((prevKeys) => {
    const newSet = new Set(prevKeys);
    if (action === 'add') {
      newSet.add(fileKey);
    } else {
      newSet.delete(fileKey);
    }
    return newSet;
  });
};

export default function useTaskFiles({
  taskFiles,
  taskId,
  documentCategory,
  taskFileUploader: taskFileUploaderFromProps,
}: {
  taskFiles: TaskFile[];
  taskId: string;
  documentCategory?: string;
  taskFileUploader?: TaskFileUploader;
}) {
  const [deleteFileErrorKeys, setDeleteFileErrorKeys] = useState<Set<FileItemKey>>(new Set());
  const [deletingKeys, setDeletingKeys] = useState<Set<FileItemKey>>(new Set());
  const [deleteTaskFile] = useDeleteTaskFileMutation();
  const taskFileUploaderFromHook = useTaskFileUpload(taskId);
  const taskFileUploader = taskFileUploaderFromProps ?? taskFileUploaderFromHook;
  const uploadValues = useMemo(
    () =>
      [...taskFileUploader.uploads.values()].filter(
        (upload) => documentCategory == null || upload.documentCategory === documentCategory
      ),
    [documentCategory, taskFileUploader.uploads]
  );
  const addFiles = useCallback(
    (files: File[]) => taskFileUploader.addFiles(files, documentCategory),
    [documentCategory, taskFileUploader]
  );
  const { deleteUpload } = taskFileUploader;

  const handleAddFiles = (files: File[]) => {
    // Don't re-add files that have already been uploaded (unless they have an error):
    const filteredFiles = files.filter(
      (file) =>
        !uploadValues.some(
          (upload) =>
            upload.error == null && upload.file.name === file.name && upload.file.size === file.size
        )
    );
    if (filteredFiles.length > 0) {
      addFiles(filteredFiles);
    }
  };

  const fileItems: FileItem[] = useMemo(
    () =>
      [
        ...taskFiles.map((taskFile) =>
          !uploadValues.some((upload) => upload.taskFileId === taskFile.fileId)
            ? { key: getFileItemKeyForTaskFile(taskFile), taskFile }
            : null
        ),
        ...uploadValues.map((upload) => ({
          key: getFileItemKeyForUpload(upload),
          upload,
          taskFile:
            upload.taskFileId != null
              ? taskFiles.find((taskFile) => taskFile.fileId === upload.taskFileId)
              : undefined,
        })),
      ]
        .filter(isNonNullish)
        .map((item) => ({
          deleteFileError: deleteFileErrorKeys.has(item.key) ? i18n.thereWasAnIssue : null,
          isDeleting: deletingKeys.has(item.key),
          ...item,
        })),
    [taskFiles, uploadValues, deletingKeys, deleteFileErrorKeys]
  );

  const haveUploadsInProgress = uploadValues.some(
    (upload) => upload.error == null && !upload.uploadFinished
  );
  const haveErrorsInUploads = uploadValues.some((upload) => upload.error != null);
  const haveUploadsToSubmit = taskFiles.length > 0;

  const addDeletingKey = (fileKey: FileItemKey) => updateKeys(setDeletingKeys, fileKey, 'add');
  const removeDeletingKey = (fileKey: FileItemKey) =>
    updateKeys(setDeletingKeys, fileKey, 'remove');
  const addFileErrorKey = (fileKey: FileItemKey) =>
    updateKeys(setDeleteFileErrorKeys, fileKey, 'add');
  const removeFileErrorKey = (fileKey: FileItemKey) =>
    updateKeys(setDeleteFileErrorKeys, fileKey, 'remove');

  const deleteFile = async (fileKey: FileItemKey) => {
    const fileItem = fileItems.find((item) => item.key === fileKey);
    if (fileItem == null) {
      return;
    }

    addDeletingKey(fileKey);
    try {
      const taskFile = fileItem.taskFile;
      if (taskFile != null) {
        await deleteTaskFile({ taskId, fileId: taskFile.fileId }).unwrap();
      }
      const upload = fileItem.upload;
      if (upload != null) {
        deleteUpload(upload.uploadKey);
      }
    } catch (error) {
      addFileErrorKey(fileKey);
      throw error;
    } finally {
      removeDeletingKey(fileKey);
    }
  };

  return {
    handleAddFiles,
    removeFileErrorKey,
    deleteUpload,
    deleteFile,
    fileItems,
    haveUploadsInProgress,
    haveErrorsInUploads,
    haveUploadsToSubmit,
    taskFileUploader,
  };
}
