import React from 'react';
import BBB from 'components/BBB';
import bloombergImg from './images/bloomberg.svg';
import fastCompany from './images/fastcompany.svg';
import financialTimes from './images/financialtimes.svg';
import forbesImg from './images/forbes.svg';
import fortuneImg from './images/fortune.svg';
import foxImg from './images/fox.svg';
import theAtlantic from './images/theatlantic.svg';
import thewallImg from './images/thewall.svg';
import washingtonPostImg from './images/washingtonpost.svg';
import * as styles from './styles';

const logoSetLogosMap = {
  [styles.LOGOSETS.DEFAULT]: [
    <img
      src={thewallImg}
      alt="The Wall Street Journal"
      className="tj"
      key="tj"
      width="66px"
      height="50px"
    />,
    <img src={foxImg} alt="Fox" className="fx" key="fx" />,
    <img src={theAtlantic} alt="The Atlantic" className="ta" key="ta" />,
    <img src={fastCompany} alt="Fast Company" className="fc" key="fc" />,
    <img src={financialTimes} alt="Financial Times" className="ft" key="ft" />,
  ],
  [styles.LOGOSETS.GRID]: [
    <img
      src={thewallImg}
      alt="The Wall Street Journal"
      className="tj"
      key="tj"
      width="66px"
      height="50px"
    />,
    <img src={bloombergImg} alt="Bloomberg" className="bg" key="bg" width="74px" height="23px" />,
    <img src={fortuneImg} alt="Fortune" className="fe" key="fe" width="56px" height="13px" />,
    <img src={forbesImg} alt="Forbes" className="fs" key="fs" width="60px" height="16px" />,
    <img
      src={washingtonPostImg}
      alt="Washington Post"
      className="wt"
      key="wt"
      width="181px"
      height="28px"
    />,
    <img src={foxImg} alt="Fox" className="fx" key="fx" width="55px" height="26px" />,
  ],
};

const LogoBar = ({
  className,
  useBBB = true,
  grid = false,
  useLogoSet = styles.LOGOSETS.DEFAULT,
} = {}) => {
  return (
    <styles.LogoBarStyle className={className} grid={grid} useLogoSet={useLogoSet}>
      {useBBB && <BBB className="BBB" bordered />}
      {logoSetLogosMap[useLogoSet]}
    </styles.LogoBarStyle>
  );
};

LogoBar.LOGOSETS = styles.LOGOSETS;

export default LogoBar;
