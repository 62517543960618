import React from 'react';
import * as styles from './styles';

export const FishingLine = () => {
  return (
    <styles.FishingLineStyle viewBox="0 0 100 200" preserveAspectRatio="xMidYMid meet">
      <path d="M50,10 C40,50 60,150 50,190" fill="none" />
    </styles.FishingLineStyle>
  );
};

export default function FishingGraphic() {
  return (
    <styles.FishingGraphicStyle>
      <styles.FishingBoatGraphicStyle>
        <FishingLine />
        <FishingLine />
      </styles.FishingBoatGraphicStyle>
    </styles.FishingGraphicStyle>
  );
}
