import styled from 'styled-components';
import { UnstyledButtonStyles } from '@pointdotcom/pds';
import * as appStyles from 'styles/';

export const PhoneAndHELOCLinkSectionStyle = styled.div.attrs({
  className: 'PhoneAndHELOCLinkSectionStyle',
})`
  ${appStyles.PaddedSectionCSS}
  background: ${(props) => props.theme.Color.PurpleBlue6};
  color: white;
  border-top: 1px solid ${(props) => props.theme.line.color.invertedSubtle};
  section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 2rem;
  }
  span {
    display: block;
    font-family: ${(props) => props.theme.fonts.serif.name};
  }
  ${UnstyledButtonStyles.UnstyledButtonStyle} {
    flex: ${(props) => (props.hideHelocLink ? 'none' : '1')};
    padding: 4% 6%;
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.line.color.invertedSubtle};
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-out;
    &:hover {
      border-color: white;
    }
    &.supportButton {
      span {
        &:first-child {
          font-size: 3rem;
          margin-bottom: 0.1em;
        }
        &:last-child {
          font-family: inherit;
        }
      }
    }
    &.helocButton {
      span {
        font-size: 2rem;
        font-size: 1.3rem;
        line-height: 1.8rem;
      }
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    ${UnstyledButtonStyles.UnstyledButtonStyle} {
      &:first-child {
        span {
          &:first-child {
            font-size: 5vw;
          }
        }
      }
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    section {
      flex-flow: column nowrap;
    }
    ${UnstyledButtonStyles.UnstyledButtonStyle} {
      padding: 10% 8% 6%;
      justify-content: flex-end;
      align-items: flex-start;
      text-align: left;
    }
  }

  // Magic number here accounting for length of phone number
  // and the size screen at which it starts to wrap
  @media (max-width: 500px) {
    ${UnstyledButtonStyles.UnstyledButtonStyle} {
      &:first-child {
        span {
          &:first-child {
            font-size: 8vw;
          }
        }
      }
    }
  }
`;
