import styled, { css, keyframes } from 'styled-components';
import { TwinkleGraphicStyle } from '../TwinkleGraphic/styles';
import hill from '../images/hill.png';
import shineImage from '../images/shine.png';
import soldHouseImage from './images/soldhouse.png';
import soldSignImage from './images/soldsign.png';

export const pulse = keyframes`
  0%, 100% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.8;
  }
`;

const ANIM_SPEED_MS = 800;

const SoldHouseBaseStyle = styled.div.attrs({ className: 'SoldHouseBaseStyle' })`
  transition: all ${ANIM_SPEED_MS}ms ease-in-out;
  position: absolute;
  bottom: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  transform: translateY(100%);
`;

export const ShineStyle = styled(SoldHouseBaseStyle).attrs({ className: 'ShineStyle' })`
  --shineSize: 25%;
  width: var(--shineSize);
  padding-bottom: var(--shineSize);
  background-image: url('${shineImage}');
  right: 55%;
  bottom: -10%;
  opacity: 0;
`;

export const SoldHouseStyle = styled(SoldHouseBaseStyle).attrs({ className: 'SoldHouseStyle' })`
  --soldHouseSize: 28%;
  width: var(--soldHouseSize);
  padding-bottom: var(--soldHouseSize);
  background-image: url('${soldHouseImage}');
  right: 40%;
`;

export const HillStyle = styled(SoldHouseBaseStyle).attrs({ className: 'HillStyle' })`
  --hillSize: 58%;
  width: var(--hillSize);
  padding-bottom: var(--hillSize);
  background-image: url('${hill}');
  right: 0;
`;

export const SoldSignStyle = styled(SoldHouseBaseStyle).attrs({ className: 'SoldSignStyle' })`
  --signSize: 15%;
  width: var(--signSize);
  padding-bottom: var(--signSize);
  background-image: url('${soldSignImage}');
  right: 14%;
  bottom: 11%;
  transition-delay: calc(${ANIM_SPEED_MS}ms * 0.5);
  transform: translateY(calc(200%));

  ${TwinkleGraphicStyle} {
    &:first-child {
      --twinkleSize: 5px;
      bottom: 80%;
      left: 95%;
    }
    &:nth-child(2) {
      --twinkleSize: 10px;
      top: -20%;
      left: 30%;
    }
    &:last-child {
      --twinkleSize: 7px;
      bottom: 77%;
      left: -1%;
    }
  }
`;

export const SoldHouseGraphicStyle = styled.div.attrs({ className: 'SoldHouseGraphicStyle' })<{
  isShown?: boolean;
}>`
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;

  ${({ isShown }) =>
    isShown &&
    css`
      ${SoldHouseBaseStyle} {
        transform: translateY(0%);
      }
      ${ShineStyle} {
        opacity: 1;
        animation: ${pulse} 3s infinite ease-in-out;
      }
    `}
`;
