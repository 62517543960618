import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { CSSTransition } from 'react-transition-group';
import {
  Container,
  Header,
  Icon,
  IconName,
  Logo,
  Size,
  TemplatedText,
  useIsMobile,
} from '@pointdotcom/pds';
import ResumeApplicationBanner from 'components/Banners/ResumeApplicationBanner';
import HomeAddressForm from 'components/HomeAddressForm';
import LogoBar from 'components/LogoBar';
import { TagPage, Workflow } from 'components/TagPage';
import { generateUrlFromPage, getURLParam, pages } from 'containers/helpers';
import { LayoutStyles, URLParms } from 'containers/prequal/pages/PreQualLandingPage/constants';
import { Redirect } from 'containers/routerHelpers';
import { currencyMask } from 'models/helpers';
import { pricing } from 'point';
import { Products } from 'store/constants';
import Graphic from './Graphic';
import i18n from './i18n';
import * as styles from './styles';

const CallOutSection = ({ children }) => (
  <styles.CallOutSectionStyle>
    <div>{children}</div>
  </styles.CallOutSectionStyle>
);

const CalloutList = ({ items }) => {
  return (
    <styles.CalloutListStyle>
      {items.map((item) => (
        <li key={item}>
          <aside>
            <Icon name={IconName.Check} />
          </aside>
          <span>{item}</span>
        </li>
      ))}
    </styles.CalloutListStyle>
  );
};

const defaultPageHeader = (
  <Header styleSize={Size.Splash}>
    <TemplatedText
      values={{
        br: () => <br />,
        amount: currencyMask.getFormatted(pricing.MAX_OFFER_AMOUNT),
        ever: () => (
          <span style={{ whiteSpace: 'nowrap' }}>
            <mark>{i18n.ever}</mark>
            <sup>1</sup>
          </span>
        ),
      }}
    >
      {i18n.getUpTo}
    </TemplatedText>
  </Header>
);

const LockKeyLandingPage = ({
  product = Products.HEI,
  showLogoBar = false,
  children,
  pageHeader = defaultPageHeader,
  calloutItems = [i18n.creditOkay, i18n.noIncome, i18n.prequalifying, i18n.secondsTo],
}) => {
  const [fieldFocused, setFieldFocused] = useState(false);
  const { isMobile } = useIsMobile();
  let timeout;
  const handleFocused = () => {
    // Fixes a jerkiness in the field focus zoom animation on ios
    // We also want this to trigger somewhere been the beginning and the end of CSS transition
    // (with slower devices, the longer delay the better) - the 'smooth' behavior helps rid the jerkiness
    if (isMobile) {
      timeout = setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 650);
    }
    setFieldFocused(true);
  };

  const handleBlurred = () => {
    setFieldFocused(false);
  };

  useEffect(() => () => clearTimeout(timeout), [timeout]);

  const styleParam = getURLParam(URLParms.Style);
  if (styleParam?.toLowerCase() === LayoutStyles.BasicWhite.toLowerCase()) {
    return <Redirect to={generateUrlFromPage(pages.PREQUAL_START, { product })} />;
  }

  return (
    <>
      <Helmet title={i18n[`${product}PageTitle`]} />
      <TagPage page="Landing Page" workflow={Workflow.Prequal} />
      <CSSTransition
        in={fieldFocused}
        timeout={styles.FOCUS_ANIM_SPEED_MS + styles.FOCUS_ANIM_DELAY_MS}
        classNames="fieldFocused"
      >
        <styles.LockKeyLandingPageStyle showLogoBar={showLogoBar}>
          <ResumeApplicationBanner />
          <div className="graphicWrapper">
            <Graphic />
          </div>
          <header>
            <Container className="headerTop">
              <Logo />
              {pageHeader}
              <CalloutList items={calloutItems} />
            </Container>
            <Container mobileCollapse>
              <CallOutSection>
                <HomeAddressForm
                  placeholder={i18n.whatsYour}
                  buttonText={i18n.seeIf}
                  onFocus={handleFocused}
                  onBlur={handleBlurred}
                  product={product}
                />
              </CallOutSection>
              {showLogoBar && <LogoBar className="hideWhenFieldFocused" />}
            </Container>
          </header>
          {children}
        </styles.LockKeyLandingPageStyle>
      </CSSTransition>
    </>
  );
};

export default LockKeyLandingPage;
