import React from 'react';
import { dashboardTaskCrumbs } from 'components/DashboardTasksCrumbs';
import { navItemsClosingDisclosure } from 'components/MainHeader/nav';
import SubNav from 'components/SubNav';
import SubNavPaginator from 'components/SubNav/SubNavPaginator';
import { TagPage, Workflow } from 'components/TagPage';
import FollowUpPage from 'containers/FollowUpPage';
import MobilePaginator from 'containers/FollowUpPage/MobilePaginator';
import { FollowUpPageWrapperProps } from 'containers/FollowUpPage/types';
import i18n from './i18n';
import * as styles from './styles';

const ClosingDisclosurePage = ({
  children,
  followUpFetchResults,
  pageName,
}: FollowUpPageWrapperProps) => {
  const { followUpId } = followUpFetchResults;
  const navProps = { followUpId };
  const followUpName = i18n.closingDisclosure;
  const navItems = navItemsClosingDisclosure;

  return (
    <FollowUpPage
      navItems={navItems}
      navProps={navProps}
      followUpName={followUpName}
      pageName={pageName}
      followUpFetchResults={followUpFetchResults}
      crumbProps={{ navItems: dashboardTaskCrumbs, currentCrumb: i18n.closingDisclosure }}
      subHeaderProps={{
        rightContent: (
          <styles.SubNavWrapperStyle>
            <SubNav navItems={navItems} navProps={navProps} />
            <SubNavPaginator navItems={navItems} navProps={navProps} />
          </styles.SubNavWrapperStyle>
        ),
      }}
    >
      <TagPage page={pageName} workflow={Workflow.ClosingDisclosureFlow} />
      <MobilePaginator navItems={navItems} navProps={navProps} />
      {children}
    </FollowUpPage>
  );
};

export default ClosingDisclosurePage;
