import React, { useRef, useState } from 'react';
import {
  Button,
  CheckBoxOnChangeEvent,
  Checkbox,
  DirectionAndPlacement,
  Header,
  HelpTextAnimated,
  IconName,
  Size,
  Style,
  Validation,
  messagingI18n,
  templatedString,
} from '@pointdotcom/pds';
import { usePostQuizAnswersMutation } from 'services/api/homeownerApi';
import { Answer, PostAnswers } from 'services/apiTypes/quizTypes';
import { useDispatch, useSelector } from 'store/hooks';
import {
  addQuizAnswer,
  closeQuiz,
  getAnswerIdByQuestionId,
  getAnswers,
  nextQuestion,
  prevQuestion,
  resetQuizAnswers,
} from 'store/productQuiz';
import i18n from './i18n';
import * as styles from './styles';

interface QuestionQuestionProps {
  questionId: number;
  questionIndex: number;
  totalQuestions: number;
  questionText: string;
  answerOptions: Array<Answer>;
  quizVersion: number;
}
const QuizQuestion = ({
  questionId,
  questionIndex,
  totalQuestions,
  questionText,
  answerOptions,
  quizVersion,
}: QuestionQuestionProps) => {
  const inputRefs = useRef<Array<HTMLInputElement>>([]);
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const answerId = useSelector(getAnswerIdByQuestionId(questionId));
  const answers = useSelector(getAnswers);
  const onLastQuestion = questionIndex === totalQuestions - 1;
  const [postQuizAnswers, { isLoading }] = usePostQuizAnswersMutation();
  const preHeader = templatedString({
    template: i18n.questionIndexOfTotalQuestions,
    values: { questionIndex: questionIndex + 1, totalQuestions },
  });

  const handleChange: CheckBoxOnChangeEvent = (e, { value }) => {
    setError(null);
    const answerIdAsInt = typeof value === 'string' ? parseInt(value, 10) : value;
    if (answerIdAsInt) {
      dispatch(
        addQuizAnswer({
          questionId,
          answerId: answerIdAsInt,
        })
      );
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!answerId) {
      inputRefs.current[0].focus();
      setError(Validation.i18n.questionRequired);
      return;
    }

    try {
      if (onLastQuestion) {
        const quizAnswers: PostAnswers = {
          quizVersion,
          answers: answers.map((a) => ({ answerId: a.answerId })),
        };

        await postQuizAnswers(quizAnswers).unwrap();

        // On successful submission, reset quiz and dashboard data
        dispatch(closeQuiz());
        dispatch(resetQuizAnswers());
      } else {
        dispatch(nextQuestion(totalQuestions));
      }
    } catch (err: unknown) {
      console.error({ err }); // TODO: bugsnag
      setError(messagingI18n.errors.unknownError);
    }
  };

  const handleBack = () => {
    dispatch(prevQuestion());
  };

  return (
    <styles.QuizQuestionStyle>
      <Header styleSize={Size.Large} preHeader={preHeader} tabIndex={-1}>
        {questionText}
      </Header>
      <HelpTextAnimated show={!!error} styleType={Style.Error} role="alert">
        {error}
      </HelpTextAnimated>
      <form onSubmit={handleSubmit}>
        <fieldset aria-required="true">
          <legend>{questionText}</legend>
          {answerOptions?.map(({ id, text }, i) => (
            <Checkbox
              key={id}
              required
              label={text}
              value={String(id)}
              checkVal={String(answerId)}
              onChange={handleChange}
              last={i === answerOptions.length - 1}
              ref={(el: HTMLInputElement) => {
                if (el) {
                  inputRefs.current[i] = el;
                }
              }}
            />
          ))}
        </fieldset>
        <footer>
          <Button
            content={onLastQuestion ? i18n.submit : i18n.nextQuestion}
            iconType={onLastQuestion ? undefined : IconName.ChevronRight}
            type="submit"
            loading={isLoading}
          />
          {questionIndex > 0 && (
            <Button
              content={i18n.back}
              styleType={Style.Tertiary}
              iconType={IconName.ChevronLeft}
              iconStyleAlign={DirectionAndPlacement.Left}
              type="button"
              onClick={handleBack}
            />
          )}
        </footer>
      </form>
    </styles.QuizQuestionStyle>
  );
};

export default QuizQuestion;
