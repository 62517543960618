import styled from 'styled-components';
import { ButtonStyles } from '@pointdotcom/pds';

export const DashboardTaskStyle = styled.div.attrs({
  className: 'DashboardTaskStyle',
})`
  background: ${({ theme }) => theme.Color.White};

  ${ButtonStyles.ButtonContentStyle} {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
  }

  ${ButtonStyles.ButtonContainerStyle}.tertiary {
    ${ButtonStyles.ButtonContentStyle} {
      color: ${({ theme }) => theme.Color.GrayLightestAccessible};
    }
  }
`;
