import { useEffect } from 'react';
import DashboardModel from 'models/DashboardModel';
import { currencyMask } from 'models/helpers';
import { getEstimatesLoadNotStarted, getHEIEstimateModel } from 'store/estimates';
import { useDispatch, useSelector } from 'store/hooks';
import { getEstimate } from 'store/thunks/estimates';

// Get the offer amount from the context of the dashboard
// There may be no LATEST offer or "latest_offer" attached to the docket, in that case, we
// get the offer from the estimate. latest_offer will eventually be populated by the account manager.
// (This does not use the "initial_offer" field returned from UW since that value isn't the correct one to use)

export function useDashboardOfferAmount(dashboard: DashboardModel | null) {
  const dispatch = useDispatch();
  const estimateLoadNotStarted = useSelector(getEstimatesLoadNotStarted);
  const currentOfferFromDocket = dashboard?.getCurrentOffer();
  const estimateKey = dashboard?.application?.estimateKey;
  const estimate = useSelector(getHEIEstimateModel);
  const offerAmount = currentOfferFromDocket ?? estimate?.getMaxOptionAmount();

  useEffect(() => {
    if (!estimateKey || !estimateLoadNotStarted || estimate || currentOfferFromDocket != null) {
      return;
    }
    dispatch(getEstimate({ estimateKey }));
  }, [estimateKey, estimate, dispatch, estimateLoadNotStarted, currentOfferFromDocket]);

  if (!dashboard) {
    return { offerAmount: null, offerAmountFormatted: null };
  }

  return {
    offerAmount,
    offerAmountFormatted: offerAmount ? currencyMask.getFormatted(offerAmount) : null,
  };
}
