import React from 'react';
import * as styles from './styles';

interface ShadowBoxProps {
  children: React.ReactNode;
  framed?: boolean;
}

export default function ShadowBox({ children, framed }: ShadowBoxProps) {
  return (
    <styles.ShadowBoxStyle>
      {framed && <styles.FrameStyle />}
      <styles.ShadowBoxContentStyle>{children}</styles.ShadowBoxContentStyle>
    </styles.ShadowBoxStyle>
  );
}
