export enum ErrorType {
  FourOhFour = '404',
  General = 'general',
  ApplicationExpired = 'application_expired',
  ApplicationRejected = 'application_rejected',
  ApplicationClosed = 'application_closed',
  OfferExpired = 'offer_expired',
  ContractExpired = 'contract_expired',
  LinkExpired = 'link_expired',
}
