import React, { useEffect, useMemo } from 'react';
import { produce } from 'immer';
import { useLocation, useNavigate } from 'react-router';
import { Page, generateUrlFromPage } from 'containers/helpers';
import { useUtmParameters } from 'containers/prequal/hooks';
import LockKeyLandingPageSingleProduct from 'containers/prequal/pages/PreQualLandingPage/LockKeyLandingPageSingleProduct';
import { NotInServiceAreaError, verifyServiceArea } from 'containers/prequal/pages/utils';
import { getNextPage } from 'containers/prequal/productPageFlow';
import bugsnagClient from 'lib/bugsnagClient';
import { AddressField, StructuredAddress, getAddressStructureFromParser } from 'lib/smartyStreets';
import { useLazyGetProductsForServiceAreaQuery } from 'services/api/prequalApi';
import { Products } from 'store/constants';
import { useDispatch } from 'store/hooks';
import { answerAddress } from 'store/property';
import FullScreenLoading from '../FullScreenLoading';
import {
  FlexibleFormParams,
  flexibleFormAddressFields,
  flexibleFormFields,
} from './flexibleFormParams';

const hasAddressFields = (data: FlexibleFormParams) =>
  flexibleFormAddressFields.some((field) => data[field] != null);

function getApiErrorStatus(error: unknown): undefined | number {
  if (
    error != null &&
    typeof error === 'object' &&
    'status' in error &&
    typeof error.status === 'number'
  ) {
    return error.status;
  }

  return undefined;
}

const FlexibleFormEntrypoint = (): null | JSX.Element => {
  useUtmParameters();

  const dispatch = useDispatch();
  const [getProductsForAddress] = useLazyGetProductsForServiceAreaQuery();

  const location = useLocation();

  const flexibleFormParams: FlexibleFormParams = useMemo(() => {
    const params = new URLSearchParams(location.search);

    return flexibleFormFields.reduce((data, field) => {
      if (params.has(field)) {
        return {
          ...data,
          [field]: params.get(field),
        };
      }
      return data;
    }, {});
  }, [location.search]);

  const product = useMemo(
    () => Object.values(Products).find((p) => p === flexibleFormParams.product) ?? Products.HEI,
    [flexibleFormParams.product]
  );

  const structuredAddressFromParams: null | StructuredAddress = useMemo(() => {
    if (product !== Products.HEI) {
      return null;
    }

    try {
      if (flexibleFormParams.address != null && flexibleFormParams.address.length > 0) {
        return getAddressStructureFromParser(flexibleFormParams.address);
      }
    } catch (_) {
      // Unable to parse `address` param, so continue
    }

    if (hasAddressFields(flexibleFormParams)) {
      return {
        [AddressField.STREET]: flexibleFormParams.streetAddress,
        [AddressField.CITY]: flexibleFormParams.city,
        [AddressField.STATE]: flexibleFormParams.state?.toUpperCase(),
        [AddressField.ZIP]: flexibleFormParams.zip,
        [AddressField.CONFIRMATION_REQUIRED]:
          flexibleFormParams.hasUnit === '1' ||
          flexibleFormParams.addressConfirmationRequired === '1',
      };
    }

    return null;
  }, [flexibleFormParams, product]);

  const navigate = useNavigate();

  useEffect(() => {
    if (structuredAddressFromParams == null) {
      return;
    }

    const abortController = new AbortController();

    const checkAddressAndNavigate = async (abortSignal: AbortSignal) => {
      try {
        let structuredAddress = structuredAddressFromParams;
        const { streetAddress, city, state, zip } = structuredAddress;
        let confirmFullAddress = structuredAddress[AddressField.CONFIRMATION_REQUIRED] ?? false;

        // If the address is not complete send the user to the confirmation page
        if (!(streetAddress && city && state && zip)) {
          structuredAddress = produce(structuredAddress, (draft) => {
            draft[AddressField.CONFIRMATION_REQUIRED] = true;
          });
          confirmFullAddress = true;
        }

        // Verify product availability
        let serviceAreaResponse = null;
        if (
          structuredAddress[AddressField.STREET] &&
          structuredAddress[AddressField.CITY] &&
          structuredAddress[AddressField.STATE] &&
          structuredAddress[AddressField.ZIP]
        ) {
          try {
            serviceAreaResponse = await getProductsForAddress(structuredAddress).unwrap();
          } catch (error) {
            // Status 400 means address was incomplete or not found
            if (getApiErrorStatus(error) !== 400) {
              throw error;
            }
          }
          if (abortSignal.aborted) {
            return;
          }
        }

        if (serviceAreaResponse?.matchedAddress) {
          dispatch(answerAddress(serviceAreaResponse.matchedAddress));
        } else {
          dispatch(answerAddress(structuredAddress));
        }

        let notInServiceArea = false;

        if (!serviceAreaResponse || serviceAreaResponse.addressConfirmationRequired) {
          confirmFullAddress = true;
        }
        if (!confirmFullAddress && serviceAreaResponse != null) {
          try {
            // This will throw if the product is not available
            verifyServiceArea(product, serviceAreaResponse.availableProducts);
          } catch (error) {
            if (error instanceof NotInServiceAreaError) {
              notInServiceArea = true;
            } else {
              throw error;
            }
          }
        }

        // Head to the next page
        if (notInServiceArea) {
          navigate(generateUrlFromPage(Page.PREQUAL_WAITLIST_SIGNUP, { product }), {
            replace: true,
          });
        } else if (confirmFullAddress) {
          const confirmationPage = generateUrlFromPage(Page.PREQUAL_UNIT_NUMBER, { product });
          navigate(confirmationPage, { replace: true });
        } else {
          navigate(getNextPage(product, Page.PREQUAL_HOME_ADDRESS), { replace: true });
        }
      } catch (error) {
        if (error instanceof Error) {
          bugsnagClient.notify(error);
        }
        if (abortSignal.aborted) {
          return;
        }

        navigate(generateUrlFromPage(Page.PREQUAL_DEFAULT), { replace: true });
      }
    };

    checkAddressAndNavigate(abortController.signal);
    return () => abortController.abort();
  }, [dispatch, getProductsForAddress, navigate, product, structuredAddressFromParams]);

  if (structuredAddressFromParams != null) {
    return <FullScreenLoading />;
  } else {
    return <LockKeyLandingPageSingleProduct />;
  }
};

export default FlexibleFormEntrypoint;
