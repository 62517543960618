import axios from 'axios';
import {
  attachRequestId,
  logErrorWithContext,
  makeInternalRequestsWithCredentials,
} from './interceptors';

export default async () => {
  axios.interceptors.request.use(attachRequestId);
  axios.interceptors.request.use(makeInternalRequestsWithCredentials);
  axios.interceptors.response.use((res) => res, logErrorWithContext);
};
