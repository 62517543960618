import * as React from 'react';
import { Navigate, Route, useParams, useSearchParams } from 'react-router-dom';
import { PageRouteMap, PageRouteMapItem, generateUrl } from 'containers/helpers';

interface NavigateFromLegacyProps {
  routeMapItem: PageRouteMapItem;
}

function NavigateFromLegacyPath({ routeMapItem }: NavigateFromLegacyProps) {
  const routeParams = useParams();
  const [searchParams] = useSearchParams();

  return (
    <Navigate
      to={generateUrl(routeMapItem.path, routeParams, Object.fromEntries(searchParams.entries()))}
      replace
    />
  );
}

// Note: This is a regular function rather than a component, because React Router expects the children to be <Route/>s
// (or fragments etc.), and rejects any other component.
export default function redirectLegacyRoutes(pageRouteMap: PageRouteMap): JSX.Element {
  return (
    <>
      {Object.entries(pageRouteMap).map(([, routeMapItem]) =>
        (routeMapItem.legacyPaths ?? []).map((legacyPath) => (
          <Route
            key={legacyPath}
            path={legacyPath}
            element={<NavigateFromLegacyPath routeMapItem={routeMapItem} />}
          />
        ))
      )}
    </>
  );
}
