import { useEffect, useState } from 'react';
import { templatedString } from '@pointdotcom/pds';
import i18n from './i18n';

const useClampConfirmation = ({
  formattedMaxValue,
  defaultErrorText,
  defaultButtonText,
  defaultButtonStyle = 'primary',
}) => {
  const [needsClampConfirmation, setNeedsClampConfirmation] = useState(false);
  const [showClampConfirmation, setShowClampConfirmation] = useState(false);
  const buttonText = showClampConfirmation ? i18n.proceed : defaultButtonText;
  const buttonStyle = showClampConfirmation ? 'error' : defaultButtonStyle;
  const clampWarning = templatedString({
    template: i18n.clampWarning,
    values: {
      number: formattedMaxValue,
    },
  });
  const errorText = showClampConfirmation ? clampWarning : defaultErrorText;
  const shouldBlockSubmitUntilConfirm = needsClampConfirmation && !showClampConfirmation;

  const onChange = (e, { clamped, typing }) => {
    if (typing) {
      setNeedsClampConfirmation(clamped === 'max');
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (shouldBlockSubmitUntilConfirm) {
      setShowClampConfirmation(true);
    } else {
      setNeedsClampConfirmation(false);
      setShowClampConfirmation(false);
    }
  };

  useEffect(() => {
    if (!needsClampConfirmation) {
      setShowClampConfirmation(false);
    }
  }, [setShowClampConfirmation, needsClampConfirmation]);

  return {
    onChange,
    onSubmit,
    shouldBlockSubmitUntilConfirm,
    errorText,
    buttonText,
    buttonStyle,
  };
};

export default useClampConfirmation;
