export default {
  description:
    'Adjust the values to see how your funding amount and term length could impact repayment.',
  fundingAmountModalText:
    'Your current offer is for %amount%. If you would like to request a different funding amount, please contact your account manager.',
  requestDifferentAmount: 'Request a different amount',
  scheduleACall: 'Schedule a call',
  yourCostEstimator: 'Your Cost Estimator',
  yourDashboardCostEstimator: 'Your dashboard – HEI cost estimator',
};
