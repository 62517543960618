import styled from 'styled-components';
import { mixins } from '@pointdotcom/pds';
import { CapShieldStyle } from 'containers/hei/HeiOfferEstimatorPage/CapShield/styles';

export const ScenariosTableCapTipStyle = styled.div.attrs({
  className: 'ScenariosTableCapTipStyle',
})`
  position: absolute;
  width: 0;
  height: 0;
  z-index: 20;
`;

export const arrowSize = 11;

export const ScenariosTableCapTipCardStyle = styled.div.attrs({
  className: 'ScenariosTableCapTipCardStyle',
})`
  position: absolute;
  bottom: ${arrowSize}px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.Color.White};
  border-radius: 3px;
  box-shadow: 0px 2px 13px 5px ${({ theme }) => mixins.rgba(theme.Color.Gray4, 0.18)};
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
  gap: 1rem;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.responsive.tabletBreakpoint}) {
    width: 340px;
    height: 140px;
    padding: 20px;
    right: ${-arrowSize * 4}px;
  }
  @media not (min-width: ${({ theme }) => theme.responsive.tabletBreakpoint}) {
    width: 240px;
    height: 125px;
    padding: 20px 40px;
    left: ${-arrowSize * 2}px;
  }

  /* Small arrow pointing at page content: */
  &::before {
    display: block;
    content: '';
    position: absolute;
    border: ${arrowSize}px solid transparent;
    border-top-color: ${({ theme }) => theme.Color.White};
    border-bottom-width: 0;
    box-sizing: content-box;
    width: 0;
    height: 0;
    bottom: ${-arrowSize}px;

    @media (min-width: ${({ theme }) => theme.responsive.tabletBreakpoint}) {
      right: ${arrowSize * 3}px;
    }
    @media not (min-width: ${({ theme }) => theme.responsive.tabletBreakpoint}) {
      left: ${arrowSize}px;
    }
  }

  ${CapShieldStyle} {
    width: 63px;
    flex-shrink: 0;
    margin: 0.25rem;

    @media not (min-width: ${({ theme }) => theme.responsive.tabletBreakpoint}) {
      width: 42px;
      position: absolute;
      margin: 0;
      top: 16px;
      right: 16px;
    }
  }

  a {
    color: inherit;
    white-space: normal;
  }
`;

export const ScenariosTableCapTipTextStyle = styled.div.attrs({
  className: 'ScenariosTableCapTipTextStyle',
})`
  min-width: 0;
`;
