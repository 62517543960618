import React from 'react';
import { directory } from '@pointdotcom/pds';
import * as styles from './styles';

const SocialContainer = () => (
  <styles.SocialContainerStyle>
    <a href={directory.PointSocial.LinkedIn} target="_blank" rel="noopener noreferrer">
      <svg
        width="24"
        height="23"
        viewBox="0 0 24 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path d="M5.16001 22.08H0.630014V7.70001H5.16001V22.08ZM7.88001 7.72001H13V9.99001C13.32 8.99001 15 7.59001 17.55 7.59001C20.76 7.59001 23.29 9.67001 23.35 14.09V22.09H18.16V14.64C18.16 12.64 17.34 11.36 15.57 11.36C15.0351 11.3661 14.5154 11.5389 14.0834 11.8544C13.6514 12.1699 13.3286 12.6123 13.16 13.12C13.0522 13.5104 13.0083 13.9156 13.03 14.32V22.11H7.88001C7.88001 22.11 8.00001 8.92001 7.88001 7.72001ZM2.56001 0.990008H3.00001C3.32832 1.0038 3.65069 1.08211 3.94873 1.22049C4.24677 1.35887 4.51463 1.55459 4.73703 1.79649C4.95942 2.03838 5.132 2.32172 5.24489 2.63031C5.35779 2.9389 5.4088 3.2667 5.39501 3.59501C5.38123 3.92331 5.30291 4.24569 5.16453 4.54372C5.02616 4.84176 4.83043 5.10962 4.58853 5.33202C4.34664 5.55442 4.06331 5.72699 3.75472 5.83989C3.44612 5.95279 3.11832 6.0038 2.79001 5.99001V5.93001H2.71001C2.38819 5.96466 2.06269 5.93546 1.75217 5.84407C1.44166 5.75269 1.15223 5.60093 0.90047 5.39748C0.648715 5.19403 0.439585 4.9429 0.285067 4.65848C0.130549 4.37406 0.0336807 4.06194 1.43494e-05 3.74001C-0.00496898 3.65008 -0.00496898 3.55994 1.43491e-05 3.47001C0.00522572 3.13906 0.0755807 2.81239 0.207061 2.50864C0.338542 2.20489 0.528572 1.93002 0.766298 1.69972C1.00402 1.46942 1.28479 1.28821 1.59256 1.16644C1.90033 1.04466 2.22907 0.984713 2.56001 0.990008Z" />
        </g>
      </svg>
    </a>
    <a href={directory.PointSocial.Twitter} target="_blank" rel="noopener noreferrer">
      <svg
        width="24"
        height="21"
        viewBox="0 0 24 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path d="M2.94003 12.695C3.22879 12.7584 3.52487 12.782 3.82003 12.765C4.24766 12.7721 4.6735 12.7079 5.08003 12.575C3.96164 12.3508 2.95715 11.7419 2.24104 10.854C1.52492 9.96618 1.14244 8.85555 1.16003 7.71504V7.64504C1.81851 8.04468 2.57001 8.2653 3.34003 8.28504C2.69004 7.79955 2.16068 7.17082 1.79302 6.44763C1.42535 5.72445 1.22928 4.92626 1.22003 4.11504C1.21033 3.25077 1.42756 2.39908 1.85003 1.64504C3.05117 3.17073 4.5651 4.42152 6.28999 5.31329C8.01487 6.20506 9.91072 6.71712 11.85 6.81504C11.7777 6.42903 11.7376 6.03769 11.73 5.64504C11.7032 5.01375 11.8013 4.38339 12.0187 3.7901C12.2361 3.19682 12.5685 2.65229 12.9968 2.18775C13.4251 1.72322 13.9409 1.34781 14.5146 1.08308C15.0883 0.818339 15.7086 0.669481 16.34 0.645038C17.0438 0.620261 17.7446 0.748724 18.3938 1.02154C19.043 1.29435 19.6252 1.70499 20.1 2.22504C21.1926 2.03193 22.2381 1.63139 23.18 1.04504C22.7996 2.16396 22.1053 3.14983 21.18 3.88504C22.1428 3.79612 23.0832 3.54243 23.96 3.13504C23.3415 4.12934 22.5408 4.99789 21.6 5.69504V6.32504C21.6 12.885 16.68 20.455 7.78003 20.455C5.137 20.4637 2.54974 19.6952 0.340027 18.245C0.716535 18.3082 1.09862 18.3316 1.48003 18.315C3.6688 18.3156 5.78976 17.5556 7.48003 16.165C6.45582 16.1327 5.46739 15.7808 4.65324 15.1585C3.8391 14.5362 3.24004 13.6749 2.94003 12.695Z" />
        </g>
      </svg>
    </a>
    <a href={directory.PointSocial.Facebook} target="_blank" rel="noopener noreferrer">
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path d="M0.960022 21.79V1.22998C0.972781 0.902673 1.11053 0.592712 1.34492 0.363903C1.57931 0.135094 1.8925 0.00484875 2.22002 -2.07078e-05H22.85C23.0159 -0.00135279 23.1803 0.0303285 23.3338 0.0931777C23.4872 0.156027 23.6267 0.248787 23.7439 0.366055C23.8612 0.483324 23.954 0.622755 24.0168 0.776227C24.0797 0.929698 24.1114 1.09414 24.11 1.25998V21.79H24.18C24.1827 22.1181 24.0563 22.4341 23.8281 22.6699C23.5998 22.9056 23.2881 23.0421 22.96 23.05H16.96V14.05H19.96L20.4 10.58H16.96V8.41998C16.96 7.41998 17.22 6.70998 18.67 6.70998H20.5V3.55998C19.5968 3.46157 18.6885 3.41816 17.78 3.42998C15.14 3.42998 13.31 5.06998 13.31 8.03998V10.63H10.31V14.1H13.31V23.1H2.22002C1.88585 23.1 1.56536 22.9672 1.32907 22.7309C1.09277 22.4946 0.960022 22.1742 0.960022 21.84L0.960022 21.79Z" />
        </g>
      </svg>
    </a>
  </styles.SocialContainerStyle>
);

export default SocialContainer;
