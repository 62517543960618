import * as React from 'react';
import * as styles from './iconStyles';

export function PaperclipIcon() {
  return (
    <styles.PaperclipIconStyle width="26" height="22" viewBox="0 0 26 22" fill="none">
      <g>
        <path d="M8.43736 10.3929L8.42338 10.4063L3.56879 15.0616C2.96756 15.6458 2.67394 16.4105 2.67394 17.1913C2.67394 17.9721 2.96756 18.7017 3.56879 19.2887L3.61074 19.321C4.20358 19.881 4.98378 20.1611 5.76119 20.1611C6.53859 20.1611 7.35235 19.873 7.95917 19.286L13.429 14.0196L13.443 14.0061L19.3266 8.34121C19.5923 8.08542 19.7153 7.74348 19.7153 7.40693C19.7153 7.07037 19.5923 6.73651 19.3266 6.48072C19.061 6.22494 18.7114 6.10647 18.3563 6.10647C18.0011 6.10647 17.66 6.22494 17.3943 6.48072L8.94351 14.6388C8.55481 14.9862 7.95917 14.9862 7.57047 14.6388C7.20134 14.2646 7.20134 13.6803 7.57047 13.3169L16.0352 5.17219C16.6756 4.56639 17.5173 4.25138 18.3563 4.25138C19.1952 4.25138 20.0425 4.56639 20.6773 5.17219C21.3177 5.79146 21.6393 6.60458 21.6393 7.40693C21.6393 8.20928 21.3177 9.03047 20.6773 9.64974L14.7992 15.3093L14.7796 15.3281L14.7657 15.3416L9.30984 20.5945C8.33389 21.5288 7.05034 22 5.76119 22C4.47204 22 3.24161 21.5531 2.26566 20.6457L2.20973 20.5945C1.23937 19.6468 0.75 18.4244 0.75 17.1886C0.75 15.9528 1.24217 14.6981 2.20973 13.7584L7.67953 8.50006L7.70749 8.47314L14.5895 1.84702C15.8647 0.611186 17.5397 0 19.212 0C20.8842 0 22.5705 0.611186 23.84 1.84702C25.1096 3.06401 25.7528 4.67409 25.7528 6.28956C25.7528 7.90503 25.1124 9.51781 23.84 10.7402L14.377 19.8703C13.9938 20.2176 13.3814 20.2176 13.0123 19.8703C12.6376 19.4879 12.6376 18.9118 13.0123 18.5429L22.481 9.43165C23.3758 8.57006 23.8261 7.42577 23.8261 6.28956C23.8261 5.15335 23.3758 4.00906 22.481 3.14747C21.5861 2.2805 20.4116 1.86048 19.212 1.86048C18.0123 1.86048 16.849 2.2805 15.9625 3.14747L8.44016 10.3902L8.43736 10.3929Z" />
      </g>
    </styles.PaperclipIconStyle>
  );
}

export function CheckmarkIcon() {
  return (
    <styles.CheckmarkIconStyle width="21" height="22" viewBox="0 0 21 22" fill="none">
      <path d="M6.22217 10.1111L9.33328 13.2222L14.7777 7.77777" strokeWidth="2.4" />
    </styles.CheckmarkIconStyle>
  );
}
