import React from 'react';
import { DirectionAndPlacement, Header, Icon, IconName } from '@pointdotcom/pds';
import DashboardModel, { DashboardStage, formatStageName } from 'models/DashboardModel';
import i18n from './i18n';
import * as styles from './styles';

const NewlineToParagraph = (text: string) => {
  const paragraphs = text.split('\n\n');
  return paragraphs.length > 1 ? paragraphs.map((part) => <p key={part}>{part}</p>) : text;
};

interface TaskListItemProps {
  title: string;
  children: React.ReactNode;
}

const TaskListItemComplete = ({ title, children }: TaskListItemProps) => (
  <styles.TaskItemContainerCompleteStyle>
    <Header
      styleAlign={DirectionAndPlacement.Left}
      styleAlignMobile={DirectionAndPlacement.Left}
      noMargin
    >
      <styles.StatusDotStyle>
        <Icon name={IconName.Check} />
      </styles.StatusDotStyle>
      {title}
    </Header>
    {children}
  </styles.TaskItemContainerCompleteStyle>
);

const TaskListItemActive = ({ title, children }: TaskListItemProps) => (
  <styles.TaskItemContainerActiveStyle>
    <Header
      styleAlign={DirectionAndPlacement.Left}
      styleAlignMobile={DirectionAndPlacement.Left}
      noMargin={!children}
    >
      <styles.StatusDotStyle />
      {title}
    </Header>
    {children}
  </styles.TaskItemContainerActiveStyle>
);

const TaskListItemIncomplete = ({ title, children }: TaskListItemProps) => (
  <styles.TaskItemContainerIncompleteStyle>
    <Header
      styleAlign={DirectionAndPlacement.Left}
      styleAlignMobile={DirectionAndPlacement.Left}
      noMargin={!children}
    >
      <styles.StatusDotStyle />
      {title}
    </Header>
    {children}
  </styles.TaskItemContainerIncompleteStyle>
);

interface StageDetailProps {
  stage: DashboardStage;
  isComplete: boolean;
}

const StageDetail = ({ stage, isComplete }: StageDetailProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const hasSteps = (stage.steps?.length ?? 0) > 0;

  const detail = (
    <>
      {NewlineToParagraph(stage.description)}
      {hasSteps && <ul>{stage.steps?.map((step) => <li key={step.id}>{step.description}</li>)}</ul>}
    </>
  );

  const handleSummaryClick = () => {
    setIsOpen(!isOpen);
  };

  if (isComplete) {
    return (
      <styles.StepDetailsStyle>
        <styles.StepSummaryStyle onClick={handleSummaryClick}>
          <span>{isOpen ? i18n.hideDetails : i18n.expandFor}</span>
          <Icon name={IconName.ChevronRight} styleSize="12" />
        </styles.StepSummaryStyle>
        {detail}
      </styles.StepDetailsStyle>
    );
  }
  return detail;
};

export enum FundingStateListDetailLevel {
  Normal,
  Minimal,
  Tiny,
}

interface FundingStageListM2Props {
  dashboard: DashboardModel;
  detailLevel?: FundingStateListDetailLevel;
}

export const FundingStageListM2 = ({
  dashboard,
  detailLevel = FundingStateListDetailLevel.Normal,
}: FundingStageListM2Props) => {
  if (detailLevel === FundingStateListDetailLevel.Tiny) {
    const activeStageName = dashboard.getFormattedActiveStageName();

    return (
      <styles.TinyTasklistStyle>
        {activeStageName ? (
          <styles.TaskItemContainerActiveStyle>
            {activeStageName}
          </styles.TaskItemContainerActiveStyle>
        ) : null}
      </styles.TinyTasklistStyle>
    );
  }

  const minimal = detailLevel !== FundingStateListDetailLevel.Normal;

  return (
    <styles.TasklistStyle small={minimal}>
      {dashboard?.stages?.map((stage) => {
        const isComplete = dashboard.getStageIsComplete(stage);
        const stageDetail = !minimal ? <StageDetail stage={stage} isComplete={isComplete} /> : null;
        const stageTitle = !minimal ? stage.title : formatStageName(stage);

        if (isComplete) {
          return (
            <TaskListItemComplete key={stage.name} title={stageTitle}>
              {stageDetail}
            </TaskListItemComplete>
          );
        }
        if (dashboard.getStageIsActive(stage)) {
          return (
            <TaskListItemActive key={stage.name} title={stageTitle}>
              {stageDetail}
            </TaskListItemActive>
          );
        }
        return (
          <TaskListItemIncomplete key={stage.name} title={stageTitle}>
            {stageDetail}
          </TaskListItemIncomplete>
        );
      })}
    </styles.TasklistStyle>
  );
};

export default FundingStageListM2;
