import styled from 'styled-components';
import { AccordionStyles, Modal, ModalStyles, mixins } from '@pointdotcom/pds';

const horizPadding = '8%';
const vertPadding = '50px';

export const HelpParagraphStyle = styled.p.attrs({ className: 'HelpParagraphStyle' })`
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  margin: 0;

  & + & {
    margin-top: 1rem;
  }
`;

export const HelpListStyle = styled.ul.attrs({ className: 'HelpListStyle' })`
  margin-top: 1.5em;
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  li {
    margin-bottom: 0.4em;
  }
`;

export const DocExampleStyle = styled.div.attrs({ className: 'DocExampleStyle' })`
  background: url('https://cdn.point.com/exampleBgPattern.svg');
  background-position: -1%;
  background-size: 6%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 800px;
  padding: ${vertPadding} ${horizPadding};
  img {
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 0 11px 4px ${({ theme }) => mixins.rgba(theme.Color.PointBlack, 0.05)};
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    height: 550px;
  }
`;

export const DocumentHelpModalStyle = styled(Modal).attrs({ className: 'DocumentHelpModalStyle' })`
  ${ModalStyles.ModalContainerInnerStyle} {
    padding: 0;
    display: flex;
    flex-flow: column;
  }
  ${ModalStyles.UpperHeaderStyle} {
    padding-left: ${horizPadding};
  }
  ${AccordionStyles.AccordionStyle} {
    --accordionLeftRightPadding: ${horizPadding};
    border-block: 1px solid ${({ theme }) => theme.line.color.default};
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${AccordionStyles.AccordionStyle} {
      --accordionTopBottomPadding: ${vertPadding};
      --accordionLeftRightPadding: 2rem;
    }
  }
`;
