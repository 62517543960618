import { useEffect } from 'react';

interface Props {
  href: string;
}

const RedirectPage = ({ href }: Props): null => {
  useEffect(() => {
    window.location.replace(href);
  }, [href]);

  return null;
};

export default RedirectPage;
