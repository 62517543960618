import React from 'react';
import Helmet from 'react-helmet';
import { Header, Size, SplashText } from '@pointdotcom/pds';
import WhiteContainer from 'containers/prequal/WhiteContainer';
import { useProduct } from 'containers/prequal/hooks/useProduct';
import { Products } from 'store/constants';
import i18n from './i18n';
import * as styles from './styles';

const Waitlist = () => {
  const { product = Products.HEI } = useProduct();
  const explanation = i18n[`${product}Explanation`] || i18n.heiExplanation;

  return (
    <styles.WaitlistStyle className="Waitlist">
      <Helmet title={i18n[`${product}PageTitle`]} />
      <WhiteContainer>
        <Header styleSize={Size.Splash}>You are on the waitlist!</Header>
        <SplashText>{explanation}</SplashText>
      </WhiteContainer>
    </styles.WaitlistStyle>
  );
};

export default Waitlist;
