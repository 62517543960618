import React from 'react';
import { DefaultTheme } from 'styled-components';
import {
  DirectionAndPlacement,
  Header,
  Size,
  Style,
  TemplatedText,
  templatedString,
} from '@pointdotcom/pds';
import Table2Col from 'components/Table2Col';
import BaseEstimateModel, { ShareType } from 'models/BaseEstimateModel';
import FollowUpModel from 'models/FollowUpModel';
import { currencyMask, percMask } from 'models/helpers';
import { Products } from 'store/constants';
import { ScenarioType } from '../ScenariosTable/scenarios';
import i18n from '../i18n';
import {
  CarouselInnerItemContainerStyle,
  CarouselItemAsideStyle,
  CarouselItemContainerStyle,
  CarouselItemCopyStyle,
  CarouselItemHeaderComboStyle,
  CarouselItemInfoStyle,
  CarouselItemMathStyle,
  CarouselItemOverStyle,
  CarouselItemUnderStyle,
  CarouselSplashItemStyle,
} from './styles';

interface CarouselItemHeaderComboProps {
  preHeader: React.ReactNode;
  header: React.ReactNode;
}

const CarouselItemHeaderCombo = ({ preHeader, header }: CarouselItemHeaderComboProps) => (
  <CarouselItemHeaderComboStyle>
    {preHeader}
    <Header
      styleSize={Size.Large}
      noMargin
      styleAlign={DirectionAndPlacement.Center}
      styleAlignMobile={DirectionAndPlacement.Center}
    >
      {header}
    </Header>
  </CarouselItemHeaderComboStyle>
);

export interface CarouselItemProps {
  capIsHit: () => boolean;
  estimate: BaseEstimateModel;
  followUp?: FollowUpModel;
  getAppreciation: () => number;
  getFinalHomeValue: () => number;
  getHomeOwnerShare: () => number;
  getPointsShare: () => number;
  getScenarioPerc: () => number;
  getSelectedYear: () => number;
  product: Products;
  selectedYear: number;
}

export type CarouselItemContentProps = CarouselItemProps & {
  carouselItems: ReadonlyArray<CarouselItem>;
  highlightedValue: undefined | string;
  scenario: ScenarioType;
  theme?: DefaultTheme;
};

type ValueOrFunction<TValue, TProps extends object = CarouselItemProps> =
  | TValue
  | ((props: TProps) => TValue);

export interface CarouselItem {
  key: string;
  Content: React.ComponentType<CarouselItemContentProps>;
  desc: ValueOrFunction<React.ReactNode>;
  highlightedValue?: ValueOrFunction<undefined | string>;
  summaryDesc?: ValueOrFunction<string>;
  summaryShow?: ValueOrFunction<boolean>;
  title: ValueOrFunction<string, Pick<CarouselItemProps, 'capIsHit' | 'followUp' | 'product'>>;
}

export const yourEstimateHomeValueItem: CarouselItem = {
  key: 'yourEstimateHomeValueItem',
  title: ({ followUp }) => {
    return followUp?.isAppraised() ? i18n.yourHomeValue : i18n.yourEstHome;
  },
  desc: ({ followUp }) => {
    return followUp?.isAppraised() ? i18n.thisIsTheAppraised : i18n.weEstimated;
  },
  highlightedValue: ({ estimate }) => {
    const estHomeValue = estimate.getFormattedAppraisedPropertyValue();
    return estHomeValue;
  },
  Content: ({ highlightedValue, followUp }) => {
    const preHeader = followUp?.isAppraised() ? i18n.appraisedHomeValue : i18n.yourEstHome;

    return (
      <CarouselItemContainerStyle>
        <CarouselItemOverStyle>
          <CarouselItemHeaderCombo preHeader={preHeader} header={highlightedValue} />
        </CarouselItemOverStyle>
      </CarouselItemContainerStyle>
    );
  },
};

export const originalAgreedValueItem: CarouselItem = {
  key: 'originalAgreedValueItem',
  title: i18n.originalAgreedValue,
  desc: ({ followUp, estimate }) => {
    const riskAdj = estimate.getPricing()?.getFormattedRiskAdjustment();
    const template = followUp?.isAppraised() ? i18n.weDiscountedAppraised : i18n.weDiscounted;

    return template
      ? templatedString({
          template,
          values: {
            riskAdjustment: riskAdj,
          },
        })
      : '';
  },
  highlightedValue: ({ estimate }) => estimate.getPricing()?.getFormattedRiskAdjustedHomeValue(),
  Content: ({ scenario, theme, product, estimate, followUp, highlightedValue }) => {
    const estHomeValue = estimate.getFormattedAppraisedPropertyValue();
    const riskAdj = estimate.getPricing()?.getFormattedRiskAdjustment();
    const preHeader = followUp?.isAppraised() ? i18n.appraisedHomeValue : i18n.yourEstHome;

    return (
      <CarouselItemContainerStyle>
        <CarouselItemUnderStyle scenario={scenario} theme={theme} product={product}>
          <CarouselItemHeaderCombo preHeader={preHeader} header={estHomeValue} />
        </CarouselItemUnderStyle>

        <CarouselItemMathStyle>-</CarouselItemMathStyle>

        <CarouselItemUnderStyle scenario={scenario} theme={theme} product={product}>
          <CarouselItemHeaderCombo preHeader={i18n.riskAdj} header={riskAdj} />
        </CarouselItemUnderStyle>

        <CarouselItemMathStyle>=</CarouselItemMathStyle>

        <CarouselItemOverStyle>
          <CarouselItemHeaderCombo preHeader={i18n.originalAgreedValue} header={highlightedValue} />
        </CarouselItemOverStyle>
      </CarouselItemContainerStyle>
    );
  },
};

export const originalAgreedValueItemForSSEDV2: CarouselItem = {
  key: 'originalAgreedValueItemForSSEDV2',
  title: i18n.appreciationStarting,
  desc: ({ followUp }) => {
    if (followUp?.isAppraised()) {
      return i18n.weCalculatedAppraised;
    }
    return i18n.weCalculated;
  },
  highlightedValue: ({ estimate }) => estimate.getPricing()?.getFormattedRiskAdjustedHomeValue(),
  Content: ({ highlightedValue }) => (
    <CarouselItemContainerStyle>
      <CarouselItemOverStyle>
        <CarouselItemHeaderCombo preHeader={i18n.appreciationStarting} header={highlightedValue} />
      </CarouselItemOverStyle>
    </CarouselItemContainerStyle>
  ),
};

export const theAppreciationItem: CarouselItem = {
  key: 'theAppreciationItem',
  title: i18n.theAppreciation,
  summaryShow: ({ capIsHit }) => !capIsHit(),
  desc: ({ estimate, getFinalHomeValue }) => {
    const startingHomeValue = estimate.getPricing()?.getFormattedRiskAdjustedHomeValue();
    const finalHomeValue = getFinalHomeValue();
    const appreciation = finalHomeValue - (estimate.getPricing()?.getRiskAdjustedHomeValue() || 0);

    return templatedString({
      template: i18n.theDifference,
      values: {
        termDescription: i18n.originalAgreedValue,
        startingHomeValue: currencyMask.getFormatted(startingHomeValue as TSFixMe),
        finalHomeValue: currencyMask.getFormatted(finalHomeValue),
        appreciation: currencyMask.getFormatted(appreciation),
      },
    });
  },
  highlightedValue: ({ getAppreciation }) => currencyMask.getFormatted(getAppreciation()),
  Content: ({ scenario, theme, product, estimate, getFinalHomeValue, highlightedValue }) => {
    const startingHomeValue = estimate.getPricing()?.getRiskAdjustedHomeValue();
    const finalHomeValue = getFinalHomeValue();

    return (
      <CarouselItemContainerStyle>
        <CarouselItemUnderStyle scenario={scenario} theme={theme} product={product}>
          <CarouselItemHeaderCombo
            preHeader={i18n.finalHomeValue}
            header={currencyMask.getFormatted(finalHomeValue)}
          />
        </CarouselItemUnderStyle>

        <CarouselItemMathStyle>-</CarouselItemMathStyle>

        <CarouselItemUnderStyle scenario={scenario} theme={theme} product={product}>
          <CarouselItemHeaderCombo
            preHeader={i18n.originalAgreedValue}
            header={currencyMask.getFormatted(startingHomeValue as TSFixMe)}
          />
        </CarouselItemUnderStyle>

        <CarouselItemMathStyle>=</CarouselItemMathStyle>

        <CarouselItemOverStyle>
          <CarouselItemHeaderCombo preHeader={i18n.appreciation} header={highlightedValue} />
        </CarouselItemOverStyle>
      </CarouselItemContainerStyle>
    );
  },
};

export const theAppreciationItemForSSEDV2: CarouselItem = {
  ...theAppreciationItem,
  key: 'theAppreciationItemForSSEDV2',
  desc: ({ estimate, getFinalHomeValue }) => {
    const startingHomeValue = estimate.getPricing()?.getFormattedRiskAdjustedHomeValue();
    const finalHomeValue = getFinalHomeValue();
    const appreciation = finalHomeValue - (estimate.getPricing()?.getRiskAdjustedHomeValue() || 0);

    return templatedString({
      template: i18n.theDifference,
      values: {
        termDescription: i18n.appreciationStarting,
        startingHomeValue: currencyMask.getFormatted(startingHomeValue as TSFixMe),
        finalHomeValue: currencyMask.getFormatted(finalHomeValue),
        appreciation: currencyMask.getFormatted(appreciation),
      },
    });
  },
  Content: ({ scenario, theme, product, estimate, getFinalHomeValue, highlightedValue }) => {
    const startingHomeValue = estimate.getPricing()?.getRiskAdjustedHomeValue();
    const finalHomeValue = getFinalHomeValue();
    return (
      <CarouselItemContainerStyle>
        <CarouselItemUnderStyle scenario={scenario} theme={theme} product={product}>
          <CarouselItemHeaderCombo
            preHeader={i18n.finalHomeValue}
            header={currencyMask.getFormatted(finalHomeValue)}
          />
        </CarouselItemUnderStyle>

        <CarouselItemMathStyle>-</CarouselItemMathStyle>

        <CarouselItemUnderStyle scenario={scenario} theme={theme} product={product}>
          <CarouselItemHeaderCombo
            preHeader={i18n.appreciationStarting}
            header={currencyMask.getFormatted(startingHomeValue as TSFixMe)}
          />
        </CarouselItemUnderStyle>

        <CarouselItemMathStyle>=</CarouselItemMathStyle>

        <CarouselItemOverStyle>
          <CarouselItemHeaderCombo preHeader={i18n.appreciation} header={highlightedValue} />
        </CarouselItemOverStyle>
      </CarouselItemContainerStyle>
    );
  },
};

export const theOptionPercItem: CarouselItem = {
  key: 'theOptionPercItem',
  title: i18n.theOptionPerc,
  desc: () => i18n.thePercOf,
  summaryShow: ({ capIsHit }) => !capIsHit(),
  highlightedValue: ({ estimate }) => estimate.getPricing()?.getFormattedOptionPercentage(),
  Content: ({ highlightedValue }) => (
    <CarouselItemContainerStyle>
      <CarouselItemOverStyle>
        <CarouselItemHeaderCombo preHeader={i18n.optionPerc} header={highlightedValue} />
        <CarouselItemAsideStyle>
          <TemplatedText values={{ nearest: percMask.getPrecisionAsText() }}>
            {i18n.roundedTo}
          </TemplatedText>
        </CarouselItemAsideStyle>
      </CarouselItemOverStyle>
    </CarouselItemContainerStyle>
  ),
};

export const pointShareOfTheApprItem: CarouselItem = {
  key: 'pointShareOfTheApprItem',
  title: i18n.pointsShareOfAppr,
  summaryShow: ({ capIsHit }) => !capIsHit(),
  desc: ({ estimate, getAppreciation }) => {
    const values = {
      optionPerc: estimate.getPricing()?.getFormattedOptionPercentage(),
      appreciation: currencyMask.getFormatted(getAppreciation()),
    };
    return <TemplatedText values={values}>{i18n.pointsShareOfApprIsCalculated}</TemplatedText>;
  },
  highlightedValue: ({ getPointsShare }) => currencyMask.getFormatted(getPointsShare()),
  Content: ({ estimate, scenario, product, theme, getAppreciation, highlightedValue }) => (
    <CarouselItemContainerStyle>
      <CarouselItemUnderStyle scenario={scenario} theme={theme} product={product}>
        <CarouselItemHeaderCombo
          preHeader={i18n.optionPerc}
          header={estimate.getPricing()?.getFormattedOptionPercentage()}
        />
        <CarouselItemAsideStyle>
          <TemplatedText values={{ nearest: percMask.getPrecisionAsText() }}>
            {i18n.roundedTo}
          </TemplatedText>
        </CarouselItemAsideStyle>
      </CarouselItemUnderStyle>

      <CarouselItemMathStyle>*</CarouselItemMathStyle>

      <CarouselItemUnderStyle scenario={scenario} theme={theme} product={product}>
        <CarouselItemHeaderCombo
          preHeader={i18n.totalAppr}
          header={currencyMask.getFormatted(getAppreciation())}
        />
      </CarouselItemUnderStyle>

      <CarouselItemMathStyle>=</CarouselItemMathStyle>

      <CarouselItemOverStyle>
        <CarouselItemHeaderCombo preHeader={i18n.pointsShare} header={highlightedValue} />
      </CarouselItemOverStyle>
    </CarouselItemContainerStyle>
  ),
};

interface CapContentProps {
  cost: number;
}

const CapContent = ({ cost }: CapContentProps) => (
  <CarouselInnerItemContainerStyle>
    <CarouselItemHeaderCombo preHeader={i18n.cappedCost} header={currencyMask.getFormatted(cost)} />
    <CarouselItemCopyStyle>{i18n.aTimeBased}</CarouselItemCopyStyle>
  </CarouselInnerItemContainerStyle>
);

export const yourTotalRepaymentItemForSSEDV2: CarouselItem = {
  key: 'yourTotalRepaymentItemForSSEDV2',
  title: ({ capIsHit }) => `${i18n.yourTotalRepayment}${capIsHit() ? ` (${i18n.capped})` : ''}`,
  desc: i18n.toDetermine,
  summaryDesc: ({ estimate, getSelectedYear, getPointsShare, capIsHit }) => {
    const selectedYear = getSelectedYear();
    const MONTHS_PER_YEAR = 12;
    const capValues = {
      loanAmount: estimate.getPricing()?.getFormattedOptionInvestmentAmount(),
      apr: estimate.getFormattedCapPercentage(selectedYear * MONTHS_PER_YEAR),
      duration:
        selectedYear === 1 ? `${selectedYear} ${i18n.year}` : `${selectedYear} ${i18n.years}`,
    };

    const apprValues = {
      origInvestment: estimate.getPricing()?.getFormattedOptionInvestmentAmount(),
      pointsShare: currencyMask.getFormatted(getPointsShare()),
    };

    const capText = templatedString({ template: i18n.inThisScenario, values: capValues });
    const aprText = templatedString({
      template: i18n.theOrigInvestment,
      values: apprValues,
    });
    return capIsHit() ? capText : aprText;
  },
  highlightedValue: ({ estimate, getScenarioPerc, getFinalHomeValue, selectedYear }) => {
    const scenarioPercDec = getScenarioPerc() / 100;
    const finalHomeValue = getFinalHomeValue();
    const repayment = estimate.getHomeownerRepaymentBase({
      lessTheOfferAmount: false,
      appreciationPerc: scenarioPercDec,
      durationInYears: selectedYear,
      homeValue: finalHomeValue,
    });
    return currencyMask.getFormatted(repayment);
  },
  Content: ({ scenario, product, estimate, getScenarioPerc, getPointsShare, selectedYear }) => {
    const percDec = getScenarioPerc() / 100;
    const apprValues = {
      origInvestment: estimate.getPricing()?.getFormattedOptionInvestmentAmount(),
      pointsShare: currencyMask.getFormatted(getPointsShare()),
    };
    const apprBasedCost = estimate.getHomeownerRepayment({
      appreciationPerc: percDec,
      durationInYears: selectedYear,
      shareType: ShareType.AppreciationBased,
    });
    const capBasedCost = estimate.getHomeownerRepayment({
      appreciationPerc: percDec,
      durationInYears: selectedYear,
      shareType: ShareType.CapBased,
    });

    // TODO: PPC-2456 resolve lint error
    // eslint-disable-next-line react/no-unstable-nested-components
    const Wrapper = ({
      children,
      highLight,
      infoPosition,
    }: {
      children: React.ReactNode;
      highLight: boolean;
      infoPosition: 'top' | 'bottom';
    }) => {
      if (highLight) {
        return (
          <CarouselItemOverStyle stretch hasItemInfo itemInfoPosition={infoPosition}>
            {infoPosition === 'top' && (
              <CarouselItemInfoStyle scenario={scenario} position={infoPosition}>
                {i18n.sinceThisResult}
              </CarouselItemInfoStyle>
            )}
            {children}
            {infoPosition === 'bottom' && (
              <CarouselItemInfoStyle scenario={scenario} position={infoPosition}>
                {i18n.sinceThisResult}
              </CarouselItemInfoStyle>
            )}
          </CarouselItemOverStyle>
        );
      }

      return (
        <CarouselItemUnderStyle scenario={scenario} stretch product={product}>
          {children}
        </CarouselItemUnderStyle>
      );
    };

    // TODO: PPC-2456 resolve lint error
    // eslint-disable-next-line react/no-unstable-nested-components
    const ApprContent = () => (
      <CarouselInnerItemContainerStyle>
        <CarouselItemHeaderCombo
          preHeader={i18n.apprBasedCost}
          header={currencyMask.getFormatted(apprBasedCost)}
        />
        <CarouselItemCopyStyle>
          <TemplatedText values={apprValues}>{i18n.theOrigInvestment}</TemplatedText>
        </CarouselItemCopyStyle>
      </CarouselInnerItemContainerStyle>
    );

    return (
      <CarouselItemContainerStyle left>
        <Wrapper highLight={apprBasedCost < capBasedCost} infoPosition="top">
          <ApprContent />
        </Wrapper>
        <CarouselItemMathStyle textContent>vs</CarouselItemMathStyle>
        <Wrapper highLight={apprBasedCost > capBasedCost} infoPosition="bottom">
          <CapContent cost={capBasedCost} />
        </Wrapper>
      </CarouselItemContainerStyle>
    );
  },
};

export const youKeepItem: CarouselItem = {
  key: 'youKeepItem',
  title: i18n.youKeep,
  desc: () => i18n.thisIsHowMuch,
  highlightedValue: ({ getHomeOwnerShare }) => currencyMask.getFormatted(getHomeOwnerShare()),
  Content: ({ highlightedValue }) => (
    <CarouselSplashItemStyle>
      <Header inverted styleSize={Size.Massive} sideLines styleAlign={DirectionAndPlacement.Center}>
        {highlightedValue}
      </Header>
    </CarouselSplashItemStyle>
  ),
};

export const summaryItem: CarouselItem = {
  key: 'summaryItem',
  title: i18n.scenarioSummary,
  desc: i18n.theseNumbers,
  Content: (props) => {
    const { carouselItems, scenario, product } = props;
    const items = carouselItems.filter((item) => {
      const highlightValue = !!item.highlightedValue;
      let shouldShow = true;

      if (typeof item.summaryShow === 'function') {
        shouldShow = item.summaryShow(props);
      } else if (typeof item.summaryShow !== 'undefined') {
        shouldShow = item.summaryShow;
      }

      return highlightValue && shouldShow;
    });
    const labels = items.map((item) =>
      typeof item.title === 'function' ? item.title(props) : item.title
    );
    const values = items.map((item) =>
      typeof item.highlightedValue === 'function'
        ? item.highlightedValue(props)
        : item.highlightedValue
    );
    const descs = items.map((item) => {
      const src = item.summaryDesc ? item.summaryDesc : item.desc;
      return typeof src === 'function' ? src(props) : src;
    });

    return (
      <CarouselItemContainerStyle left scenario={scenario} product={product}>
        <Table2Col
          labels={labels}
          values={values}
          foldingContent={descs}
          styleType={Style.White}
          isBordered={false}
        />
      </CarouselItemContainerStyle>
    );
  },
};
