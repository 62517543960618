import * as z from "zod";
import * as Common from "./Common.types";
import * as Underwrite_Core_Dockets from "./Underwrite.Core.Dockets.types";
import * as HomeownerService_Tasks from "./HomeownerService.Tasks.types";

export const contactInfoSchema = z
  .object({
    firstName: z.string().min(1),
    middleName: z.string().optional(),
    lastName: z.string().min(1),
    email: z.string().email().min(1),
    phone: z
      .string()
      .min(1, { message: "This field is required" })
      .regex(new RegExp("^(\\D*\\d){10}\\D*$"), {
        message: "Phone numeric is invalid",
      })
      .transform((value) => value.replace(new RegExp("\\D", "g"), "")),
  })
  .catchall(z.unknown() as z.ZodType);
export type ContactInfo = z.infer<typeof contactInfoSchema>;

export const spouseContactInfoSchema = z
  .object({
    firstName: z.string().min(1),
    middleName: z.string().optional(),
    lastName: z.string().min(1),
  })
  .catchall(z.unknown() as z.ZodType);
export type SpouseContactInfo = z.infer<typeof spouseContactInfoSchema>;

export const addressSchema = z
  .object({
    streetAddress: z.string(),
    unit: z.string().nullable().optional(),
    city: z.string(),
    countyFipsCode: z.string().optional(),
    state: z.string(),
    zip: z.string(),
  })
  .catchall(z.unknown() as z.ZodType);
export type Address = z.infer<typeof addressSchema>;

export const balanceConfirmationSchema = z.string().min(1);

export const zeroMortgageSchema = z
  .object({
    mortgageBalance: z.literal(0),
    balanceConfirmation: balanceConfirmationSchema.optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type ZeroMortgage = z.infer<typeof zeroMortgageSchema>;

export const mortgageBalanceSchema = z
  .number()
  .min(0)
  .max(10000000, { message: "Mortgage balance should be below $10,000,000" });

export const yesNoExplainFieldSchema = z.union([
  z
    .object({
      answer: z.literal(false),
      explanation: z.string().optional(),
    })
    .catchall(z.unknown() as z.ZodType),
  z
    .object({
      answer: z.literal(true),
      explanation: z.string().min(1),
    })
    .catchall(z.unknown() as z.ZodType),
]);
export type YesNoExplainField = z.infer<typeof yesNoExplainFieldSchema>;

export const missedLastMortgagePaymentSchema = z.union([
  z
    .object({
      answer: z.literal(false),
    })
    .catchall(z.unknown() as z.ZodType),
  z
    .object({
      answer: z.literal(true),
      agreeToPayoff: z.boolean(),
      howManyMortgagePaymentsMissed: z
        .object({
          answer: z.enum(["most_recent", "two_or_more"]),
          explanation: z.string().min(1),
        })
        .catchall(z.unknown() as z.ZodType),
    })
    .catchall(z.unknown() as z.ZodType),
]);
export type MissedLastMortgagePayment = z.infer<
  typeof missedLastMortgagePaymentSchema
>;

export const nonZeroMortgageSchema = z
  .object({
    mortgageBalance: mortgageBalanceSchema,
    balanceConfirmation: balanceConfirmationSchema.optional(),
    hasMortgageModification: yesNoExplainFieldSchema,
    hasActiveForbearance: z.boolean(),
    missedPayment: missedLastMortgagePaymentSchema,
    hasDeferredPayment: yesNoExplainFieldSchema,
  })
  .catchall(z.unknown() as z.ZodType);
export type NonZeroMortgage = z.infer<typeof nonZeroMortgageSchema>;

export const additionalOwnerSchema = z
  .object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
  })
  .catchall(z.unknown() as z.ZodType);
export type AdditionalOwner = z.infer<typeof additionalOwnerSchema>;

export const additionalOwnersSchema = z.array(additionalOwnerSchema);
export type AdditionalOwners = z.infer<typeof additionalOwnersSchema>;

export enum OwnershipStatus {
  Individual = "As an individual",
  JointlyWithSpouse = "Jointly with a spouse/domestic partner",
  JointlyWithOther = "Jointly with someone other than a spouse/domestic partner",
  JointlyWithMultiple = "Jointly with more than 2 owners",
  Trust = "Through a trust",
  LLC = "Through an LLC",
  LP = "Through an LP",
}
export const ownershipStatusSchema = z.nativeEnum(OwnershipStatus);

export const ownershipStatusWithOthersEnumSchema = z.enum([
  OwnershipStatus.JointlyWithOther,
  OwnershipStatus.JointlyWithMultiple,
]);
export type OwnershipStatusWithOthersEnum = z.infer<
  typeof ownershipStatusWithOthersEnumSchema
>;

export const ownershipSchemaWithOwnersSchema = z
  .object({
    ownershipStatus: ownershipStatusWithOthersEnumSchema,
    additionalOwners: additionalOwnersSchema.min(1),
  })
  .catchall(z.unknown() as z.ZodType);
export type OwnershipSchemaWithOwners = z.infer<
  typeof ownershipSchemaWithOwnersSchema
>;

export const ownershipSchemaWithoutOwnersSchema = z
  .object({
    ownershipStatus: z.enum([
      OwnershipStatus.Individual,
      OwnershipStatus.JointlyWithSpouse,
      OwnershipStatus.LLC,
      OwnershipStatus.LP,
      OwnershipStatus.Trust,
    ]),
  })
  .catchall(z.unknown() as z.ZodType);
export type OwnershipSchemaWithoutOwners = z.infer<
  typeof ownershipSchemaWithoutOwnersSchema
>;

export const socialSecurityNumberSchema = z
  .string()
  .min(1, { message: "This field is required" })
  .regex(
    new RegExp(
      "^(?!(\\d)\\1{2}-\\1{2}-\\1{4})(?!000|666|9)[0-9]{3}([ -]?)(?!00)[0-9]{2}\\2(?!0000)[0-9]{4}$",
    ),
    { message: "Social Security numeric is invalid" },
  );

export enum CitizenshipStatus {
  US = "US Citizen",
  Alien = "Permanent Resident Alien",
  Visa = "Visa Holder",
  None = "None of the above",
}
export const citizenshipStatusSchema = z.nativeEnum(CitizenshipStatus);

export enum EmploymentStatus {
  SelfEmployed = "Self-employed",
  Employed = "Employed",
  NotEmployed = "Not employed",
  Retired = "Retired",
}
export const employmentStatusSchema = z.nativeEnum(EmploymentStatus);

export enum ReferralSource {
  Broker = "Broker or contractor",
  SocialMedia = "Social media (e.g., Facebook)",
  Financial = "Financial website (e.g., Credit Karma)",
  TV = "TV",
  USPS = "USPS mail",
  Friend = "Friend",
  Radio = "Radio / podcast",
  Google = "Google / Bing",
  Other = "Other",
}
export const referralSourceSchema = z.nativeEnum(ReferralSource);

export const spouseFieldsSchema = z.union([
  z
    .object({
      answer: z.literal(false),
    })
    .catchall(z.unknown() as z.ZodType),
  z
    .object({
      answer: z.literal(true),
      isOnTitle: z.boolean(),
      contactInfo: spouseContactInfoSchema,
    })
    .catchall(z.unknown() as z.ZodType),
]);
export type SpouseFields = z.infer<typeof spouseFieldsSchema>;
export const applicantSignatureSchema = z.string().min(1);

export const heiApplicationApplicantSchema = z
  .object({
    contactInfo: contactInfoSchema,
    ssn: socialSecurityNumberSchema,
    birthdate: z.string().date(),
    citizenshipStatus: citizenshipStatusSchema,
    employmentStatus: employmentStatusSchema,
    referral: referralSourceSchema.optional(),
    hasSpouse: spouseFieldsSchema,
    signature: applicantSignatureSchema,
  })
  .catchall(z.unknown() as z.ZodType);
export type HeiApplicationApplicant = z.infer<
  typeof heiApplicationApplicantSchema
>;

export const inProgressHeiApplicationApplicantSchema = z
  .object({
    ssn: socialSecurityNumberSchema.optional(),
    birthdate: z.string().date().optional(),
    citizenshipStatus: citizenshipStatusSchema.optional(),
    employmentStatus: employmentStatusSchema.optional(),
    referral: referralSourceSchema.optional(),
    hasSpouse: spouseFieldsSchema.optional(),
    signature: applicantSignatureSchema.optional(),
    contactInfo: contactInfoSchema.partial().optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type InProgressHeiApplicationApplicant = z.infer<
  typeof inProgressHeiApplicationApplicantSchema
>;

export const ownershipSchema = z.union([
  ownershipSchemaWithOwnersSchema,
  ownershipSchemaWithoutOwnersSchema,
]);
export type Ownership = z.infer<typeof ownershipSchema>;

export const primaryResidenceSchema = z.union([
  z
    .object({
      answer: z.literal(true),
    })
    .catchall(z.unknown() as z.ZodType),
  z
    .object({
      answer: z.literal(false),
      primaryAddress: z.string().min(1),
      ownsPrimaryAddress: z.boolean(),
    })
    .catchall(z.unknown() as z.ZodType),
]);
export type PrimaryResidence = z.infer<typeof primaryResidenceSchema>;

export const mortgageSchema = z.union([
  zeroMortgageSchema,
  nonZeroMortgageSchema,
]);
export type Mortgage = z.infer<typeof mortgageSchema>;

export const isHelocWithinDrawPeriodSchema = z.union([
  z
    .object({
      answer: z.literal(false),
      currentLoanBalance: z.number().min(0),
    })
    .catchall(z.unknown() as z.ZodType),
  z
    .object({
      answer: z.literal(true),
      totalCreditLimit: z.number().min(0),
    })
    .catchall(z.unknown() as z.ZodType),
]);
export type IsHelocWithinDrawPeriod = z.infer<
  typeof isHelocWithinDrawPeriodSchema
>;

export const propertyHasLoanSchema = z.union([
  z
    .object({
      answer: z.literal(false),
    })
    .catchall(z.unknown() as z.ZodType),
  z
    .object({
      answer: z.literal(true),
      isHelocWithinDrawPeriod: isHelocWithinDrawPeriodSchema,
    })
    .catchall(z.unknown() as z.ZodType),
]);
export type PropertyHasLoan = z.infer<typeof propertyHasLoanSchema>;

export const hoaSchema = z.union([
  z
    .object({
      answer: z.literal(false),
    })
    .catchall(z.unknown() as z.ZodType),
  z
    .object({
      answer: z.literal(true),
      pendingLitigation: yesNoExplainFieldSchema,
      delinquent: yesNoExplainFieldSchema,
    })
    .catchall(z.unknown() as z.ZodType),
]);
export type Hoa = z.infer<typeof hoaSchema>;

export const heiApplicationPropertySchema = z
  .object({
    addressOneLine: z.string(),
    ownership: ownershipSchema,
    isPrimaryResidence: primaryResidenceSchema,
    isMobileHome: z.boolean(),
    mortgage: mortgageSchema,
    hasLoan: propertyHasLoanSchema,
    hasEnergyEfficiencyLoan: yesNoExplainFieldSchema,
    hasPendingLiensOrJudgments: yesNoExplainFieldSchema,
    hoa: hoaSchema,
    isDelinquentOnPropertyTaxes: yesNoExplainFieldSchema,
    hasHazardousSubstances: yesNoExplainFieldSchema,
    hasEnvironmentalLawViolations: yesNoExplainFieldSchema,
    hasImpairedPropertyValue: yesNoExplainFieldSchema,
    hasUnpermittedWorks: yesNoExplainFieldSchema,
    hasAccessoryDwellingUnit: yesNoExplainFieldSchema,
    isInRenovation: yesNoExplainFieldSchema,
    hasPendingLawsuits: yesNoExplainFieldSchema,
  })
  .catchall(z.unknown() as z.ZodType);
export type HeiApplicationProperty = z.infer<
  typeof heiApplicationPropertySchema
>;

export enum IntendedUse {
  DebtRepayment = "Debt repayment",
  SmallBusiness = "Small business",
  HomeRemodeling = "Home remodeling",
  InvestmentProperty = "Investment property",
  EducationExpenses = "Education expenses",
  Other = "Other",
}
export const intendedUseSchema = z.nativeEnum(IntendedUse);

export const heiApplicationFinancialsSchema = z
  .object({
    hasFelony: yesNoExplainFieldSchema,
    hasJudgments: yesNoExplainFieldSchema,
    hasBankruptcy: yesNoExplainFieldSchema,
    hasForeclosure: yesNoExplainFieldSchema,
    planningToCloseLoans: yesNoExplainFieldSchema,
    amountRequested: z
      .number()
      .min(30000, { message: "Amount requested must be at least $30,000" })
      .max(1000000, {
        message: "Amount requested cannot be more than $1,000,000",
      }),
    intendedUse: intendedUseSchema,
    intendedUseDetail: z.string().min(1),
  })
  .catchall(z.unknown() as z.ZodType);
export type HeiApplicationFinancials = z.infer<
  typeof heiApplicationFinancialsSchema
>;

export const heiApplicationSchema = z
  .object({
    applicant: heiApplicationApplicantSchema,
    property: heiApplicationPropertySchema,
    financials: heiApplicationFinancialsSchema,
  })
  .catchall(z.unknown() as z.ZodType);
export type HeiApplication = z.infer<typeof heiApplicationSchema>;

export const inProgressHeiApplicationSchema = z
  .object({
    applicant: inProgressHeiApplicationApplicantSchema.optional(),
    property: heiApplicationPropertySchema.partial().optional(),
    financials: heiApplicationFinancialsSchema.partial().optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type InProgressHeiApplication = z.infer<
  typeof inProgressHeiApplicationSchema
>;

export const heiApplicationConsentSchema = z
  .object({
    employmentVerificationAuthorize: z.string().min(1),
    creditCheckAuthorize: z.string().min(1),
    escrowDisclosureAuthorize: z.string().min(1),
    contractorSharingAuthorize: z.string().min(1),
    creditCheckTerm: z.string().min(1),
    agencyRelationshipDisclosure: z.string().optional(),
    confirmationOfAgencyRelationship: z.string().optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type HeiApplicationConsent = z.infer<typeof heiApplicationConsentSchema>;

/**
 * GET /application/:estimateKey
 */
export const getApplicationResponseSchema = z
  .object({
    application: inProgressHeiApplicationSchema,
    submittedAt: z.string().datetime({ offset: true }).nullable(),
  })
  .catchall(z.unknown() as z.ZodType);
export type GetApplicationResponse = z.infer<
  typeof getApplicationResponseSchema
>;

/**
 * POST /application
 */
export const postApplicationRequestSchema = z
  .object({
    applicant: z
      .object({
        contactInfo: contactInfoSchema,
      })
      .catchall(z.unknown() as z.ZodType),
    property: z
      .object({
        /**
         * Address of the property for which HEI application is created
         */
        normalizedAddress: addressSchema,
      })
      .catchall(z.unknown() as z.ZodType),
    financials: z
      .object({
        amountRequested: z.number(),
      })
      .catchall(z.unknown() as z.ZodType)
      .optional(),
    docketId: z.number().optional(),
    estimateKey: z.string().optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type PostApplicationRequest = z.infer<
  typeof postApplicationRequestSchema
>;

/**
 * POST /application/submit
 */
export const postApplicationSubmitRequestSchema = z
  .object({
    html: z.string().min(1),
    application: heiApplicationSchema,
    consents: heiApplicationConsentSchema,
  })
  .catchall(z.unknown() as z.ZodType);
export type PostApplicationSubmitRequest = z.infer<
  typeof postApplicationSubmitRequestSchema
>;

export const validationErrorsSchema = z.record(z.string(), z.string());
export type ValidationErrors = z.infer<typeof validationErrorsSchema>;

export const getApplicationNotFoundResponseSchema = z
  .object({
    /**
     * followUpUrl from Underwrite. Returned if application in UW is available, needed for redirection form CP to the old UW flow
     */
    followUpUrl: z.string().optional(),
    applicationStatus: Common.applicationStatusSchema.optional(),
    docketStatus: Underwrite_Core_Dockets.docketStatusSchema,
  })
  .catchall(z.unknown() as z.ZodType);
export type GetApplicationNotFoundResponse = z.infer<
  typeof getApplicationNotFoundResponseSchema
>;

export const applicationStatusResponseSchema = z
  .object({
    status: HomeownerService_Tasks.taskStatusSchema.nullable(),
    adqStatus:
      Underwrite_Core_Dockets.automatedDocketQualificationStatusSchema.nullable(),
  })
  .catchall(z.unknown() as z.ZodType);
export type ApplicationStatusResponse = z.infer<
  typeof applicationStatusResponseSchema
>;

export const applicationApiResponseSchema = z.union([
  z
    .object({
      /**
       * Each property describes a path to a filed with problem and value describes the problem
       */
      validationErrors: validationErrorsSchema.optional(),
    })
    .catchall(z.unknown() as z.ZodType),
  z.string(),
]);
export type ApplicationApiResponse = z.infer<
  typeof applicationApiResponseSchema
>;
