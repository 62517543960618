import React from 'react';
import { Container } from '@pointdotcom/pds';
import * as styles from './styles';

const FeaturedIn = () => {
  const featuredInItems = [
    {
      key: 'theWallStreetJournal',
      title: 'The Wall Street Journal',
    },
    {
      key: 'bloomberg',
      title: 'Bloomberg',
    },
    {
      key: 'theWashingtonPost',
      title: 'The Washington Post',
    },
    {
      key: 'fortune',
      title: 'Fortune',
    },
    {
      key: 'fox',
      title: 'Fox Business',
    },
    {
      key: 'forbes',
      title: 'Forbes',
    },
  ];

  const fearturedIn = featuredInItems.map((featuredInItem) => (
    <div
      key={featuredInItem.key}
      role="img"
      aria-label={featuredInItem.title}
      title={featuredInItem.title}
      className={featuredInItem.key}
    />
  ));

  return (
    <styles.FeaturedInStyle>
      <Container>
        <h5>Featured in</h5>
        <div>{fearturedIn}</div>
      </Container>
    </styles.FeaturedInStyle>
  );
};

export default FeaturedIn;
