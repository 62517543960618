export interface Availability {
  email: string;
  timestamp: string;
  name: string;
  memberId: string;
  calendar: string;
}

export enum YCBMCal {
  Prod = 'mypoint-am',
  Staging = 'mypoint-am-staging',
  QA = 'mypoint-qa',
  Training = 'mypoint-training',
  PartnerProd = 'partner-ams',
  PartnerStaging = 'partner-ams-staging',
}
