import React from 'react';

const PointCopyright = () => {
  return (
    <div className="pointCopyright">
      &copy;
      {`${new Date().getFullYear()} Point Digital Finance, Inc.`}
    </div>
  );
};

export default PointCopyright;
