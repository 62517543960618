import React from 'react';
import { DirectionAndPlacement } from '@pointdotcom/pds';
import TwinkleGraphic from '../TwinkleGraphic';
import { ShieldGraphicProps } from './constants';
import * as styles from './styles';

export default function ShieldGraphic({
  direction = DirectionAndPlacement.Left,
}: ShieldGraphicProps) {
  return (
    <styles.ShieldGraphicStyle direction={direction}>
      <TwinkleGraphic />
      <TwinkleGraphic />
      <TwinkleGraphic />
    </styles.ShieldGraphicStyle>
  );
}
