import styled, { css } from 'styled-components';
import { ContainerStyles, IconStyles, mixins } from '@pointdotcom/pds';
import { appTheme } from 'styles/';

const navLineHeight = '2px';
const navLineSpace = '3px';
const navBoxSize = parseInt(navLineHeight, 10) * 3 + parseInt(navLineSpace, 10) * 2;

const MainHeaderStyle = styled.div.attrs({ className: 'MainHeaderStyle' })`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > * {
    flex-shrink: 0;
  }
`;

const MainNavStyle = styled.nav.attrs({ className: 'MainNavStyle' })``;

const FullNavStyle = styled.div.attrs({ className: 'FullNavStyle' })<{
  showAsPopup?: boolean;
  hasMinPopupItems?: boolean;
}>`
  display: flex;
  ${IconStyles.IconStyle} {
    margin: 0 0.5em;
  }
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    display: ${({ showAsPopup = false, hasMinPopupItems = false }) => {
      if (showAsPopup || hasMinPopupItems) {
        return 'none';
      }
      return 'flex';
    }};
  }
  ${({ showAsPopup = false, hasMinPopupItems = false }) =>
    showAsPopup && hasMinPopupItems
      ? css`
          display: none;
        `
      : ``}
`;

const PopupNavStyle = styled.button.attrs({ className: 'PopupNavStyle' })<{
  isOnlyPopup?: boolean;
}>`
  display: none;
  width: ${navBoxSize + navBoxSize * 0.4}px;
  height: ${navBoxSize}px;
  cursor: pointer;
  ${mixins.appearance()}
  > * {
    content: '';
    display: block;
    background: ${({ theme }) => theme.Color.PointBlue};
    width: 100%;
    height: ${navLineHeight};
    border-radius: 1px;
    margin-bottom: ${navLineSpace};
  }
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    display: block;
  }
  ${({ isOnlyPopup = false }) =>
    isOnlyPopup
      ? css`
          display: block;
        `
      : ``}
`;

const MainNavItemStyle = styled.div.attrs({ className: 'MainNavItem' })<{ current: boolean }>`
  display: flex;
  align-items: center;
  > button {
    color: ${({ current, theme }) =>
      current ? theme.link.color.default : theme.Color.GrayLightestAccessible};
    text-decoration: none;
    &:hover {
      color: ${({ current, theme }) => (current ? theme.link.color.hover : theme.Color.Gray4)};
    }
  }
  > .ChevronStyle {
    box-sizing: content-box;
    padding: 0 1rem;
  }
  @media only print {
    > .ChevronStyle,
    > button {
      display: none;
    }
  }
`;

const MainHeaderContainerStyle = styled.div.attrs({ className: 'MainHeaderContainerStyle' })<{
  scrollShown: boolean;
}>`
  transition: all 320ms ease-in-out;
  background: ${({ theme }) => theme.Color.White};
  display: flex;
  align-items: center;
  height: ${({ scrollShown }) =>
    scrollShown ? appTheme.mainHeaders.height.mobile : appTheme.mainHeaders.height.default};
  border-bottom: 1px solid
    ${({ scrollShown, theme }) => (!scrollShown ? theme.line.color.default : 'transparent')};
  box-shadow: ${({ scrollShown, theme }) =>
    scrollShown ? `0 0 10px ${theme.line.color.default}` : 'none'};
  ${ContainerStyles.ContainerStyle} {
    flex: 1;
  }
  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    height: ${appTheme.mainHeaders.height.mobile};
  }
  @media only print {
    box-shadow: none;
  }
`;

export {
  MainHeaderContainerStyle,
  MainHeaderStyle,
  MainNavStyle,
  MainNavItemStyle,
  FullNavStyle,
  PopupNavStyle,
};
