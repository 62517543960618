class BaseModel<T> {
  static getFromSessionStorage<T>(KEY: string): BaseModel<T> | null {
    const storageStr = window.sessionStorage.getItem(KEY) || '';
    try {
      const storageObj = JSON.parse(storageStr);
      if (storageObj) {
        return new this(storageObj);
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  constructor(props: T | undefined) {
    Object.assign(this, props);
  }

  setFloat(prop: string | number): void {
    // TypeScript error: No index signature with a parameter of type 'string' was found on type 'BaseModel<T>'.
    if ((this as TSFixMe)[prop] !== undefined) {
      (this as TSFixMe)[prop] = parseFloat((this as TSFixMe)[prop]);
    }
  }

  setBool(prop: string | number): void {
    // TypeScript error: No index signature with a parameter of type 'string' was found on type 'BaseModel<T>'.
    if ((this as TSFixMe)[prop] !== undefined) {
      (this as TSFixMe)[prop] = Boolean((this as TSFixMe)[prop]);
    }
  }

  async saveToSessionStorage(KEY: string): Promise<string | null | never> {
    if (KEY) {
      window.sessionStorage.setItem(KEY, JSON.stringify(this.toJSON()));
      const dataInStorage = await window.sessionStorage.getItem(KEY);
      if (dataInStorage) {
        return dataInStorage;
      }
      throw new Error('Could not save data to storage');
    } else {
      throw new Error('no storage key given');
    }
  }

  toJSON(): Record<string, unknown> {
    return Object.getOwnPropertyNames(this).reduce((jsonData, prop) => {
      const copy: Record<string, unknown> = { ...jsonData };
      if (prop !== 'className') {
        // TypeScript error: No index signature with a parameter of type 'string' was found on type 'BaseModel<T>'.
        copy[prop] = (this as TSFixMe)[prop];
      }

      return copy;
    }, {});
  }
}

export default BaseModel;
