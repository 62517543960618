import React from 'react';
import {
  Container,
  DirectionAndPlacement,
  Header,
  Logo,
  Size,
  Style,
  TrustPilotWidget,
} from '@pointdotcom/pds';
import ResumeApplicationBanner from 'components/Banners/ResumeApplicationBanner';
import FeaturedIn from 'components/prequal/FeaturedIn';
import MainFooter from 'components/prequal/MainFooter';
import mailerImg from './images/mailer_and_blue_envelope.png';
import * as styles from './styles';

const MailerLanding = ({ headerText, children }) => (
  <>
    <ResumeApplicationBanner blueTheme />

    <styles.MailerLandingStyle>
      <section className="topSection">
        <Container className="mainContainer">
          <div className="headerContainer">
            <Logo styleType={Style.Inverted} />
          </div>

          <div className="heroContainer">
            <div className="leftCol">
              <img
                src={mailerImg}
                width="95%"
                className="mailerImg"
                alt="An offer letter from Point and its blue envelope"
              />
            </div>
            <div className="rightCol">
              <Header
                styleSize={Size.Splash}
                styleAlign={DirectionAndPlacement.Left}
                maxWidth="300px"
              >
                {headerText || 'Enter your offer code'}
              </Header>
              <a href="/" className="dontHave">
                Donʼt have an offer code?
              </a>
              {children}
              <div className="thisWont">(This wonʼt impact your credit score)</div>
            </div>
          </div>
        </Container>
      </section>
      <FeaturedIn />
      <section className="tpSectionStyle">
        <Container>
          <TrustPilotWidget theme="light" />
        </Container>
      </section>
      <MainFooter hideTpWiget />
    </styles.MailerLandingStyle>
  </>
);

export default MailerLanding;
