import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { Waypoint } from 'react-waypoint';
import { ThemeContext } from 'styled-components';
import {
  Container,
  DirectionAndPlacement,
  Header,
  Icon,
  IconName,
  Size,
  SplashText,
  useIsMobile,
} from '@pointdotcom/pds';
import i18n from './i18n';
import * as styles from './styles';

const HowItWorks3ImgsSection = () => {
  const theme = useContext(ThemeContext);
  const [wayPointSectionIn, setWayPointSectionIn] = useState(false);
  const { currentWindowSize: windowSize } = useIsMobile();
  let iconScale = 1.4;

  if (windowSize < 1000) {
    iconScale = 1;
  } else if (windowSize < 500) {
    iconScale = 0.8;
  }

  return (
    <Waypoint onEnter={() => setWayPointSectionIn(true)} bottomOffset="30%">
      <styles.HowItWorks3ImgsSectionStyle className={classnames({ entered: wayPointSectionIn })}>
        <Container>
          <Header
            styleSize={Size.Large}
            styleAlign={DirectionAndPlacement.Left}
            styleAlignMobile={DirectionAndPlacement.Center}
          >
            {i18n.howIt}
          </Header>
        </Container>
        <Container mobileCollapse>
          <ul>
            <li>
              <div>
                <Icon
                  name={IconName.AccentComputerForm}
                  color={theme.Color.PointBlack}
                  accentColor={theme.Color.Gray1}
                  styleScale={iconScale}
                />
                <SplashText styleAlign="left" styleAlignMobile="left" maxWidth="100px">
                  {i18n.applyOnline}
                </SplashText>
              </div>
            </li>
            <li>
              <div>
                <Icon
                  name={IconName.AccentVerify}
                  color={theme.Color.PointBlack}
                  accentColor={theme.Color.Gray1}
                  styleScale={iconScale}
                />
                <SplashText styleAlign="left" styleAlignMobile="left" maxWidth="137px">
                  {i18n.verifyYour}
                </SplashText>
              </div>
            </li>
            <li>
              <div>
                <Icon
                  name={IconName.AccentMoneyPile}
                  color={theme.Color.PointBlack}
                  accentColor={theme.Color.Gray1}
                  styleScale={iconScale}
                />
                <SplashText styleAlign="left" styleAlignMobile="left" maxWidth="200px">
                  {i18n.closeInAsLittle}
                </SplashText>
              </div>
            </li>
          </ul>
        </Container>
      </styles.HowItWorks3ImgsSectionStyle>
    </Waypoint>
  );
};

export default HowItWorks3ImgsSection;
