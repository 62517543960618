import React, { useCallback, useEffect } from 'react';
import {
  Button,
  DirectionAndPlacement,
  IconName,
  Input,
  InputChangeEvent,
  Style,
} from '@pointdotcom/pds';
import {
  AdditionalOwner as AdditionalOwnerType,
  AdditionalOwners as AdditionalOwnersType,
} from 'services/apiTypes/homeownerTypes';
import { useDispatch } from 'store/hooks';
import { setData } from 'store/productApplication';
import { FieldProps } from '../constants';
import i18n from '../i18n';
import { AdditionalOwnersStyle } from '../styles';
import { getHelpTextFromZodIssue } from './validationUtils';

const AdditionalOwner = ({
  field,
  styleSize,
  index,
  value: valueFromProps,
  path,
  issues,
  error,
  shouldShowErrors = false,
  shouldShowErrorMessages = false,
}: FieldProps & { index: number }) => {
  const firstNameIssue = issues?.find((issue) => {
    return (
      issue.path.includes('additionalOwners') &&
      issue.path.includes(index) &&
      issue.path.includes('firstName')
    );
  });
  const lastNameIssue = issues?.find((issue) => {
    return (
      issue.path.includes('additionalOwners') &&
      issue.path.includes(index) &&
      issue.path.includes('lastName')
    );
  });

  const revisedAdditionalOwners = [...((valueFromProps as AdditionalOwnersType) || [])];
  const owner = { ...revisedAdditionalOwners[index] };
  const dispatch = useDispatch();
  const handleDelete = () => {
    revisedAdditionalOwners.splice(index, 1);
    dispatch(setData({ dataPath: path, value: revisedAdditionalOwners }));
  };
  const handleNameChange =
    (ownerFirstOrLast: keyof AdditionalOwnerType): InputChangeEvent =>
    (e, { value }) => {
      owner[ownerFirstOrLast] = value;
      revisedAdditionalOwners.splice(index, 1, owner);
      dispatch(setData({ dataPath: path, value: revisedAdditionalOwners }));
    };
  return (
    <li>
      <Input
        aria-label={`${i18n.firstName} ${index + 1}`}
        placeholder={i18n.firstName}
        styleSize={styleSize}
        value={owner.firstName}
        onChange={handleNameChange('firstName')}
        helptext={
          firstNameIssue && shouldShowErrorMessages
            ? getHelpTextFromZodIssue(firstNameIssue, field)
            : ''
        }
        error={firstNameIssue && shouldShowErrors && error}
      />
      <Input
        aria-label={`${i18n.lastName} ${index + 1}`}
        placeholder={i18n.lastName}
        styleSize={styleSize}
        value={owner.lastName}
        onChange={handleNameChange('lastName')}
        helptext={
          lastNameIssue && shouldShowErrorMessages
            ? getHelpTextFromZodIssue(lastNameIssue, field)
            : ''
        }
        error={lastNameIssue && shouldShowErrors && error}
      />
      <aside>
        <Button
          aria-label={`${i18n.remove} ${index + 1}`}
          styleType={Style.Tertiary}
          styleSize={styleSize}
          onClick={handleDelete}
          iconType={IconName.Close}
          iconStyleAlign={DirectionAndPlacement.Left}
        >
          {i18n.remove}
        </Button>
      </aside>
    </li>
  );
};

const AdditionalOwners = (fieldProps: FieldProps) => {
  const { value, path, styleSize } = fieldProps;
  const dispatch = useDispatch();
  const additionalOwners = value as AdditionalOwnersType;

  const handleClick = useCallback(() => {
    const newOwner = { firstName: '', lastName: '' };
    const revisedAdditionalOwners = [...(additionalOwners || []), newOwner];
    dispatch(setData({ dataPath: path, value: revisedAdditionalOwners }));
  }, [additionalOwners, dispatch, path]);

  useEffect(() => {
    if (!additionalOwners?.length) {
      handleClick();
    }
  }, [additionalOwners?.length, handleClick]);

  return (
    <AdditionalOwnersStyle styleSize={styleSize}>
      <ul>
        {additionalOwners?.map((owner, i) => {
          return (
            <AdditionalOwner
              {...fieldProps}
              index={i}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            />
          );
        })}
      </ul>
      <Button
        styleType={Style.Tertiary}
        styleSize={styleSize}
        onClick={handleClick}
        iconType={IconName.Plus}
        iconStyleAlign={DirectionAndPlacement.Left}
      >
        {i18n.add}
      </Button>
    </AdditionalOwnersStyle>
  );
};

export default AdditionalOwners;
