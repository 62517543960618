import React, { useState } from 'react';
import {
  Button,
  Container,
  DirectionAndPlacement,
  Header,
  Size,
  Style,
  Validation,
} from '@pointdotcom/pds';
import { Page, generateUrlFromPage } from 'containers/helpers';
import { useTaskCompletionModal } from 'containers/hooks/useTaskCompletionModal';
import { useFollowUpFetch } from 'containers/prequal/hooks';
import { Redirect } from 'containers/routerHelpers';
import FollowUpModel from 'models/FollowUpModel';
import { useCounselingSelectionMutation } from 'services/api/underwriteApi';
import { CompletedTask } from 'store/general';
import { HeroContainerStyle, SplashCopySerifStyle } from 'styles/';
import i18n from '../i18n';
import FinancialCounselingPage from '../index';
import * as styles from './styles';

const COUNSELING = 'counseling';

interface ContinueButtonProps {
  followUpId: string | undefined;
  followUp: FollowUpModel | null;
  onAfterSubmit: () => void;
}

interface CounselingStatus {
  choice: string | null;
}

const ContinueButton = ({ followUpId, followUp, onAfterSubmit }: ContinueButtonProps) => {
  const [error, setError] = useState<null | string>(null);
  const [submitChoice, { isLoading: loading }] = useCounselingSelectionMutation();

  if (!followUp) {
    return null;
  }

  const handleContinue = async () => {
    const { csrfToken } = followUp;
    try {
      setError(null);

      if (!followUpId) throw new Error();

      const response = await submitChoice({
        followUpId,
        csrfToken,
        choice: COUNSELING,
      }).unwrap();
      if (!response) {
        return;
      }
      if (!response.success) {
        throw new Error();
      }

      onAfterSubmit();
    } catch (_) {
      setError(Validation.i18n.unknownError);
    }
  };

  return (
    <Button
      onClick={handleContinue}
      loading={loading}
      styleType={error ? Style.Error : Style.Primary}
      helptext={error || ''}
    >
      {i18n.proceedToCounseling}
    </Button>
  );
};

const FinancialCounselingSelectionPage = () => {
  const completeTask = useTaskCompletionModal();
  const followUpFetchResults = useFollowUpFetch();
  const { followUp, followUpId } = followUpFetchResults;

  const onAfterSubmit = () => {
    const task: CompletedTask = {
      title: i18n.prepareForCounseling,
      summary: i18n.youWillParticipate,
      detailList: {
        ordered: true,
        items: i18n.navicoreNextSteps,
      },
    };
    completeTask(task);
  };

  const checkForRedirect = ({ choice }: CounselingStatus = { choice: null }) => {
    let redirectUrl = null;

    if (choice) {
      const baseUrlForRedirect = generateUrlFromPage(Page.FINANCIAL_COUNSELING_DEFAULT, {
        followUpId,
      });
      redirectUrl = `${baseUrlForRedirect}/${choice}`;
    }

    return redirectUrl;
  };

  // If the counseling requirement has already been acknowledged, proceed to the next page.
  const redirectPage = checkForRedirect(followUp?.acknowledgmentOfHeir);
  if (redirectPage) {
    return <Redirect to={redirectPage} />;
  }

  return (
    <FinancialCounselingPage followUpFetchResults={followUpFetchResults}>
      <HeroContainerStyle styleSize={Size.Small}>
        <styles.SelectionContentStyle>
          <Container>
            <Header styleSize={Size.Splash} styleAlignMobile={DirectionAndPlacement.Center}>
              {i18n.financialCounseling}
            </Header>
            <SplashCopySerifStyle mobileSeparator>{i18n.pointIsCommitted}</SplashCopySerifStyle>
            <ContinueButton
              followUp={followUp}
              followUpId={followUpId}
              onAfterSubmit={onAfterSubmit}
            />
          </Container>
        </styles.SelectionContentStyle>
      </HeroContainerStyle>
    </FinancialCounselingPage>
  );
};

export default FinancialCounselingSelectionPage;
