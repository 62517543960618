import React, { useEffect, useRef } from 'react';
import * as styles from './styles';

interface Butterfly {
  x: number;
  y: number;
  size: number;
  speedX: number;
  speedY: number;
  angle: number;
  angleSpeed: number;
  wingAngle: number;
  bodyColor: string;
  wingColor: string;
}

const ButterflyAnimation: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const generateMonochromaticColor = (): string => {
    const baseHue = 42;
    const saturation = Math.random() * 20 + 80; // 80% to 100%
    const lightness = Math.random() * 30 + 50; // 50% to 80%
    return `hsl(${baseHue}, ${saturation}%, ${lightness}%)`;
  };

  const createButterflies = (count: number, scaleFactor: number = 1): Butterfly[] => {
    const butterflies: Butterfly[] = [];
    for (let i = 0; i < count; i++) {
      butterflies.push({
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        size: (Math.random() * 8 + 4) * scaleFactor,
        speedX: (Math.random() * 2 - 1) * scaleFactor,
        speedY: (Math.random() * 2 - 1) * scaleFactor,
        angle: Math.random() * Math.PI * 2,
        angleSpeed: Math.random() * 0.05 + 0.02,
        wingAngle: 0,
        bodyColor: generateMonochromaticColor(),
        wingColor: generateMonochromaticColor(),
      });
    }
    return butterflies;
  };

  const calculateOpacity = (x: number, y: number, width: number, height: number): number => {
    const fadeDistance = 100;
    const dx = Math.min(x, width - x);
    const dy = Math.min(y, height - y);
    const distance = Math.min(dx, dy);
    return Math.max(0, Math.min(1, distance / fadeDistance));
  };

  const drawButterfly = (
    ctx: CanvasRenderingContext2D,
    butterfly: Butterfly,
    canvasWidth: number,
    canvasHeight: number
  ) => {
    const { x, y, size, wingAngle, bodyColor, wingColor } = butterfly;
    const opacity = calculateOpacity(x, y, canvasWidth, canvasHeight);

    /* eslint-disable no-param-reassign */
    ctx.globalAlpha = opacity;
    ctx.fillStyle = bodyColor;
    ctx.beginPath();
    ctx.ellipse(x, y, size / 3, size, 0, 0, Math.PI * 2);
    ctx.fill();

    ctx.fillStyle = wingColor;
    ctx.beginPath();
    ctx.ellipse(x - size, y, size, size / 2, wingAngle, 0, Math.PI * 2);
    ctx.fill();

    ctx.beginPath();
    ctx.ellipse(x + size, y, size, size / 2, -wingAngle, 0, Math.PI * 2);
    ctx.fill();

    ctx.globalAlpha = 1;
  };

  const updateButterflies = (
    butterflies: Butterfly[],
    canvasWidth: number,
    canvasHeight: number
  ) => {
    butterflies.forEach((butterfly) => {
      butterfly.x += butterfly.speedX;
      butterfly.y += butterfly.speedY;
      butterfly.angle += butterfly.angleSpeed;
      butterfly.wingAngle = (Math.sin(butterfly.angle) * Math.PI) / 4;

      if (butterfly.x < 0) butterfly.x = canvasWidth;
      if (butterfly.x > canvasWidth) butterfly.x = 0;
      if (butterfly.y < 0) butterfly.y = canvasHeight;
      if (butterfly.y > canvasHeight) butterfly.y = 0;
    });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    let animationFrameId: number;

    const butterflies = createButterflies(5, 1.5);

    const render = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      butterflies.forEach((butterfly) =>
        drawButterfly(ctx, butterfly, canvas.width, canvas.height)
      );
      updateButterflies(butterflies, canvas.width, canvas.height);

      animationFrameId = requestAnimationFrame(render);
    };

    render();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <styles.ButterflyCanvasStyle ref={canvasRef} />;
};

export default function TandemBikeGraphic() {
  return (
    <styles.TandemBikeGraphicStyle>
      <ButterflyAnimation />
    </styles.TandemBikeGraphicStyle>
  );
}
