import React from 'react';
import { Container, DirectionAndPlacement, Header, Size } from '@pointdotcom/pds';
import { HeroContainerStyle, SplashCopySerifStyle } from 'styles/';
import i18n from './i18n';
import * as styles from './styles';

const HeroContainerWrapper = ({ children }) => {
  return (
    <HeroContainerStyle>
      <Container>
        <styles.EstimatorPageInnerContentStyle>
          <styles.HeroSectionLeftStyle>
            <Header styleSize={Size.Splash} styleAlignMobile={DirectionAndPlacement.Center}>
              {i18n.yourCostEstimator}
            </Header>
          </styles.HeroSectionLeftStyle>
          <styles.HeroSectionRightStyle>
            <SplashCopySerifStyle>{children}</SplashCopySerifStyle>
          </styles.HeroSectionRightStyle>
        </styles.EstimatorPageInnerContentStyle>
      </Container>
    </HeroContainerStyle>
  );
};

const FollowUpHero = () => {
  return (
    <HeroContainerWrapper>
      <p>{i18n.whenYoure}</p>
      <p>{i18n.useTheControls}</p>
    </HeroContainerWrapper>
  );
};

const EstimateHero = ({ isFinalPricing }) => {
  return (
    <HeroContainerWrapper>
      <p>{i18n.whenYoureInquiry}</p>
      <p>{i18n.useTheToolsInquiry}</p>
      {!isFinalPricing && (
        <styles.MarkSplashStyle key="inquiryTools">
          {i18n.useTheToolsEstimateNote}
        </styles.MarkSplashStyle>
      )}
    </HeroContainerWrapper>
  );
};

const HeroContainer = ({ estimate, followUp, isFinalPricing, product }) => {
  if (estimate) {
    return <EstimateHero product={product} isFinalPricing={isFinalPricing} />;
  }
  if (followUp) {
    return <FollowUpHero />;
  }
  return null;
};

export default HeroContainer;
