// Make all singular
export default {
  nextEstimate: 'Next: Estimate my cost',
  estimateCost: 'Estimate cost',
  seeExample: 'See example scenarios',
  example: 'Example scenarios',
  yourDecision: 'Your decision',
  goToThe: 'Go to the application',
  reviewTerms: 'Review terms',
  continueApplication: 'Continue application',
  application: 'Application',
  preparingYour: 'Preparing your application...',
  viewPreviousItem: 'View previous %item%',
  viewNextItem: 'View next %item%',
  prev: 'Prev',
  next: 'Next',
  back: 'Back',
};
