import { createSelector, createSlice } from '@reduxjs/toolkit';
import ApplicantModel from 'models/ApplicantModel';
import { prequalApi } from 'services/api/prequalApi';
import { RootState } from 'store';
import { Applicant } from 'types';
import { clearApplication } from './actions';
import { getEstimate, saveEstimate } from './thunks/estimates';

export const initialApplicantState: Applicant = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  authorizeCreditPull: '',
  acknowledgeContact: '',
  contactPolicyConsentDate: '',
};

const applicantSlice = createSlice({
  name: 'applicant',
  initialState: initialApplicantState,
  reducers: {
    answerSsn: (state, { payload: { ssnLastFour, firstName, lastName } }) => {
      state.ssnLastFour = ssnLastFour;
      state.firstName = firstName;
      state.lastName = lastName;
    },

    answerCreditScore: (state, { payload }) => {
      state.ficoRange = payload;
    },

    answerContactInfo: (
      state,
      {
        payload: {
          phoneNumber,
          phone,
          firstName,
          lastName,
          emailAddress,
          email,
          contactPolicyConsentDate,
        },
      }
    ) => {
      const answerTimestamp = new Date().toISOString();

      state.firstName = firstName;
      state.lastName = lastName;
      state.phone = (phone || phoneNumber)?.replace(/[^0-9]/g, '');
      state.email = emailAddress || email;
      state.authorizeCreditPull = answerTimestamp;
      state.acknowledgeContact = answerTimestamp;
      state.contactPolicyConsentDate = contactPolicyConsentDate;
    },

    answerIncome: (state, { payload }) => {
      state.householdIncome = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearApplication, () => initialApplicantState);

    // thunk reducers
    builder.addCase(getEstimate.fulfilled, (state, { payload }) => {
      const { applicant } = payload;
      if (applicant) {
        (Object.keys(applicant) as (keyof Applicant)[]).forEach((key) => {
          state[key] = applicant[key] as TSFixMe;
        });
      }
    });

    builder.addCase(saveEstimate.fulfilled, (state, response) => {
      applicantSlice.caseReducers.answerContactInfo(state, {
        payload: response.payload.applicant,
        type: response.type,
      });
    });

    builder.addMatcher(
      prequalApi.endpoints.getOfferCodeDetail.matchFulfilled,
      (state, { payload: { firstName, lastName } }) => {
        state.firstName = firstName;
        state.lastName = lastName;
      }
    );
  },
});

export const getApplicantModel = createSelector(
  ({ applicant }: RootState) => applicant,
  (applicant) => {
    return new ApplicantModel(applicant);
  }
);

export const { answerSsn, answerContactInfo, answerCreditScore, answerIncome } =
  applicantSlice.actions;

export default applicantSlice.reducer;
