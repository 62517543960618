export default {
  next: 'Next',
  submit: 'Submit',
  whatHappensAfter: 'What happens after you submit?',
  weWillAskYou:
    'We will ask you to upload documents to verify the information you provided. Weʼll review your application and documents, and email you with any questions as needed.',
  anAppraiser:
    'An appraiser will call you to schedule a visit to your home so we can establish its value.',
  afterTheAppraisal:
    'After the appraisal is complete, weʼll finalize our investment terms and schedule a day to complete the deal.',
};
