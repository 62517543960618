export default {
  goodNews: 'Good news!',
  goodNewsName: 'Good news, %name%!',
  monthlyPayment: 'Monthly payment',
  monthlyPaymentsDescription:
    'Open up your cash flow — there are no monthly payments for the life of the contract.',
  youCouldGet: 'You could get up to %amount% from Point with no monthly payments',
  termLength: 'Term length',
  termLengthDescription:
    'Homeowners typically end their term when they have cash on hand from a refinance or sale.',
  watchAShortVideoAbout: 'Watch a short video about HEI pricing',
  watchAShortVideo: 'Watch a short video',
};
