import React from 'react';
import {
  ButtonLefRightClickFunc,
  ButtonLeftRight,
  DirectionAndPlacement,
  Style,
  templatedString,
} from '@pointdotcom/pds';
import i18n from './i18n';
import * as styles from './styles';

export type PaginateFunctionType = (direction: DirectionAndPlacement) => void;
export interface PaginatorProps {
  currentPageNum: number;
  totalNumPages: number;
  onPaginate?: PaginateFunctionType;
}

const LeftRightPaginator = ({ currentPageNum, totalNumPages, onPaginate }: PaginatorProps) => {
  const leftNavigationDisabled = currentPageNum === 1;
  const rightNavigationDisabled = currentPageNum === totalNumPages;

  const handleClick: ButtonLefRightClickFunc = (_, { direction }) => {
    onPaginate?.(direction);
  };

  return (
    <styles.LeftRightPaginatorStyle>
      <ButtonLeftRight
        direction={DirectionAndPlacement.Left}
        styleType={Style.Tertiary}
        disabled={leftNavigationDisabled}
        onClick={handleClick}
      />
      {templatedString({
        template: i18n.pageXofX,
        values: { current: String(currentPageNum), total: String(totalNumPages) },
      })}
      <ButtonLeftRight
        direction={DirectionAndPlacement.Right}
        styleType={Style.Tertiary}
        disabled={rightNavigationDisabled}
        onClick={handleClick}
      />
    </styles.LeftRightPaginatorStyle>
  );
};

export default LeftRightPaginator;
