export default {
  cashToYou: 'Cash to you',
  heiPercentage: 'HEI Percentage',
  thisIsTheEstimated:
    'This is the estimated amount of cash you would receive if you were to increase your offer amount to %previewAmount%. A full breakdown of terms, fees and any associated payoffs will be included in your updated offer if you choose to proceed with this new offer amount.',
  thisIsThePercentage:
    'This is the percentage of future appreciation you’ll pay to Point when you choose to buy back your equity.',
  previewADifferent: 'Preview a different offer amount',
  yourEligibleFor:
    'You’re eligible for up to %maxAmount%. Adjust the offer amount below to see how your terms and pricing are impacted. Note that these values are estimates and do not update your current offer. If you would like to proceed with a different offer amount, you may request an official updated offer below.',
  requestAnUpdated: 'Request an updated offer for %previewAmount%',
  keepMyOffer: 'Keep my %offerAmount% offer',
  requestComplete: 'Request complete!',
  youveSuccessfully:
    'You’ve successfully submitted a request for an updated offer of %amount%. Here’s what to expect next:',
  yourAccountManager:
    'Your Account Manager will issue the updated offer shortly, usually within a few hours.',
  onceIssued:
    'Once issued, you will receive an email and your updated offer will be available for review via your Point dashboard.',
  reviewTheNew:
    'Review the new offer and associated pricing and terms, and let us know how you would like to proceed. ',
};
