export default {
  seeYourPersonalized: 'See your personalized pricing, explained',
  yourPersonalizedPricing: 'Your personalized pricing, based on receiving %amount%',
  firstSelect:
    'First, select how much money you’re looking for. You can change this amount at any time. ',
  next: 'Next',
  prev: 'Prev',
  previousSlide: 'Previous slide',
  nextSlide: 'Next slide',
};
