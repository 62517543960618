import React from 'react';
import * as styles from './styles.js';

const Arm = () => {
  return (
    <styles.ArmStyle viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
      <g className="arm">
        <path d="M50 50 L100 100" strokeLinecap="round" />
        <g className="forearm">
          <path d="M100 100 L150 150" />
        </g>
      </g>
    </styles.ArmStyle>
  );
};

export default function HandShakeGraphic() {
  return (
    <styles.HandShakeGraphicStyle>
      <styles.ShakeShineStyle />
      <Arm />
      <Arm />
    </styles.HandShakeGraphicStyle>
  );
}
