import styled from 'styled-components';

export const WhatsNextListStyle = styled.ul.attrs({ className: 'WhatsNextListStyle' })`
  padding: 0;
  margin: 0;
  padding-left: 1rem;
  text-align: left;
  li {
    padding-left: 1em;
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
