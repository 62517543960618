import * as React from 'react';
import { Products } from 'store/constants';

export const ProductContext = React.createContext<undefined | Products>(undefined);

interface ProductProviderProps {
  product: Products;
  children: React.ReactNode;
}

export function ProductProvider({ product, children }: ProductProviderProps): JSX.Element {
  return <ProductContext.Provider value={product}>{children}</ProductContext.Provider>;
}
