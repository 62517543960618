import styled from 'styled-components';
import { mixins } from '@pointdotcom/pds';
import { HeroSectionLeftStyle, HeroSectionRightStyle } from 'containers/EstimatorPage/styles';
import { PaddedSectionStyle } from 'styles/';

const ListStyle = styled.ol.attrs({ className: 'ListStyle' })`
  font-size: ${(props) => mixins.getRemFromPx(20, props.theme.globals.baseFontSize.mobile)};
  font-weight: bold;
  margin: 0;
`;

const ListItemStyle = styled.li.attrs({ className: 'ListItemStyle' })`
  margin-bottom: 1.5em;
  padding-left: 0.5em;

  &:last-child {
    margin-bottom: 0;
  }

  .unbold {
    font-weight: normal;
  }
`;

const OverviewHeroLeftStyle = styled(HeroSectionLeftStyle)`
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding-bottom: 74px;
  }
`;

const OverviewHeroRightStyle = styled(HeroSectionRightStyle)`
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding-top: 74px;
  }
`;

const OverviewContentStyle = styled(PaddedSectionStyle).attrs({
  className: 'OverviewContentStyle',
})`
  padding: 12%;
  border: 1px solid ${(props) => props.theme.line.color.default};
  position: relative;

  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    border: none;
    padding: 0%;
  }
`;

const BikeImageStyle = styled.div.attrs({ className: 'BikeImageStyle' })`
  background-image: url(https://cdn.point.com/bike-Jr2ENEC0jJuwNnplcjCcZ3B4WCW8pqwUvW7z0S4lPpM.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: absolute;
  width: 300px;
  height: 190px;
  bottom: -120px;
  left: 30px;

  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    display: none;
    padding: 0;
  }
`;

const BikeImageMobileStyle = styled.div.attrs({ className: 'BikeImageStyle' })`
  background-image: url(https://cdn.point.com/bike-Jr2ENEC0jJuwNnplcjCcZ3B4WCW8pqwUvW7z0S4lPpM.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin: 0 auto;
  width: 66%;
  display: none;
  height: 120px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  bottom: 0;

  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    display: block;
  }
`;

export {
  ListStyle,
  ListItemStyle,
  OverviewHeroRightStyle,
  OverviewHeroLeftStyle,
  OverviewContentStyle,
  BikeImageStyle,
  BikeImageMobileStyle,
};
