import * as React from 'react';
import { useState } from 'react';
import {
  Container,
  DirectionAndPlacement,
  Header,
  LinkButton,
  Size,
  Style,
  TemplatedText,
} from '@pointdotcom/pds';
import { HorizontalBracket } from 'components/HorizontalBracket/HorizontalBracket';
import RangeEstimatePrepayment from 'components/RangeEstimatePrepayment';
import OfferEstimateModel from 'models/OfferEstimateModel';
import { PricingRangeType } from 'store/estimates';
import WhyTheseNumbersOverlay from '../WhyTheseNumbersOverlay';
import PieWithSlice from './PieWithSlice';
import i18n from './i18n';
import plusSvg from './images/plus.svg';
import * as styles from './styles';

interface HeiOfferEstimatorPagePricingSectionProps {
  pricingRange: null | PricingRangeType;
  estimate: OfferEstimateModel;
}

export default function HeiOfferEstimatorPagePricingSection({
  pricingRange,
  estimate,
}: HeiOfferEstimatorPagePricingSectionProps): null | JSX.Element {
  const [showWhyTheseNumbersOverlay, setShowWhyTheseNumbersOverlay] = useState(false);

  if (!pricingRange) {
    return null;
  }

  const estimatePricing = estimate.getPricing();
  const showPricingRangeSlider = pricingRange.hasMinEstimatesForUIControl();

  return (
    <>
      <styles.PricingSectionStyle>
        <Container>
          <styles.PartsStyle>
            {showPricingRangeSlider && (
              <div>
                <Header
                  styleSize={Size.Medium}
                  styleAlign={DirectionAndPlacement.Center}
                  styleAlignMobile={DirectionAndPlacement.Left}
                  className="pricingSectionHeader"
                >
                  {i18n.toSeeYourEstimatedPricing}
                </Header>
                <RangeEstimatePrepayment
                  pricingRange={pricingRange}
                  styleType={Style.Default}
                  dropdownButtonProps={{
                    styleType: Style.Dark,
                  }}
                />
              </div>
            )}
            {estimatePricing != null ? (
              <div>
                <Header
                  styleSize={showPricingRangeSlider ? Size.Small : Size.Medium}
                  styleAlign={DirectionAndPlacement.Center}
                  styleAlignMobile={DirectionAndPlacement.Left}
                  className="estimatedPricingSectionHeader"
                >
                  <styles.IfYouReceiveHeadingStyle splitLine={!showPricingRangeSlider}>
                    <TemplatedText
                      values={{ amount: estimatePricing.getFormattedOptionInvestmentAmount() }}
                    >
                      {i18n.ifYouReceive}
                    </TemplatedText>
                  </styles.IfYouReceiveHeadingStyle>
                </Header>
                <styles.HorizontalBracketImageHolderStyle>
                  <HorizontalBracket fillOpacity=".2" />
                </styles.HorizontalBracketImageHolderStyle>
                <styles.RepaymentRowStyle>
                  <styles.BoxWithShadowStyle>
                    <styles.InvestmentAmountBoxStyle>
                      <div>{i18n.investmentAmount}</div>
                      <styles.LargeRepaymentAmountStyle>
                        {estimatePricing.getFormattedOptionInvestmentAmount()}
                      </styles.LargeRepaymentAmountStyle>
                    </styles.InvestmentAmountBoxStyle>
                  </styles.BoxWithShadowStyle>
                  <styles.PlusStyle>
                    <img src={plusSvg} alt="+" />
                    <div>{i18n.plus}</div>
                  </styles.PlusStyle>
                  <styles.BoxWithShadowStyle>
                    <styles.RepaymentPercentageBoxStyle>
                      <styles.PieImageHolderStyle>
                        {estimatePricing.optionPercentage != null ? (
                          <PieWithSlice percentage={estimatePricing.optionPercentage} />
                        ) : null}
                      </styles.PieImageHolderStyle>
                      <styles.LargeRepaymentAmountStyle>
                        {estimatePricing.getFormattedOptionPercentage()}
                      </styles.LargeRepaymentAmountStyle>
                      <div>
                        <TemplatedText
                          values={{
                            appreciationStartingValue: (
                              <strong>{estimatePricing.getFormattedRiskAdjustedHomeValue()}</strong>
                            ),
                          }}
                        >
                          {i18n.futureAppreciation}
                        </TemplatedText>
                      </div>
                      <styles.WhyTheseNumbersLinkStyle>
                        <LinkButton onClick={() => setShowWhyTheseNumbersOverlay(true)}>
                          {i18n.whyTheseNumbers}
                        </LinkButton>
                      </styles.WhyTheseNumbersLinkStyle>
                    </styles.RepaymentPercentageBoxStyle>
                  </styles.BoxWithShadowStyle>
                </styles.RepaymentRowStyle>
              </div>
            ) : null}
          </styles.PartsStyle>
        </Container>
      </styles.PricingSectionStyle>
      {estimatePricing != null ? (
        <WhyTheseNumbersOverlay
          estimatePricing={estimatePricing}
          isOpen={showWhyTheseNumbersOverlay}
          onModalClose={() => setShowWhyTheseNumbersOverlay(false)}
        />
      ) : null}
    </>
  );
}
