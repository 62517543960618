import React from 'react';
// import Modal from './Modal';
import { Modal } from '@pointdotcom/pds';
import Paginator from 'components/Paginator';
import { TagModal } from 'components/TagPage';
import VideoButton from 'components/VideoPlayer/VideoButton';
import { VideoButtonStyleType } from 'components/VideoPlayer/VideoButton/constants';
import { QuestionsApiResponse } from 'services/apiTypes/quizTypes';
import { useDispatch, useSelector } from 'store/hooks';
import {
  closeQuiz,
  getCurrentQuestionIndex,
  getQuizIsOpen,
  openVideoFromQuiz,
} from 'store/productQuiz';
import QuizQuestion from '../QuizQuestion';
import i18n from './i18n';
import * as styles from './styles';

interface QuizModalProps {
  quizData: QuestionsApiResponse;
}

interface ModalFooterProps {
  videoReferenceTime: number;
}
const ModalFooter = ({ videoReferenceTime }: ModalFooterProps) => {
  const label = `${i18n.needHelp} ${i18n.referTo}`;
  const dispatch = useDispatch();
  const handleVideoButtonClick = () => {
    dispatch(openVideoFromQuiz(videoReferenceTime));
  };

  return (
    <styles.ReferenceSectionStyle role="button" onClick={handleVideoButtonClick}>
      <div>
        <div>{i18n.needHelp}</div>
        <div>{i18n.referTo}</div>
      </div>
      <div>
        <VideoButton styleType={VideoButtonStyleType.Mini} label={label} />
      </div>
    </styles.ReferenceSectionStyle>
  );
};

const QuizModal = ({ quizData }: QuizModalProps) => {
  const dispatch = useDispatch();
  const currentQuestionIndex = useSelector(getCurrentQuestionIndex);
  const quizIsOpen = useSelector(getQuizIsOpen);
  const handleModalClose = () => dispatch(closeQuiz());

  const paginatorItems = quizData.questions.map(({ id, text, answers }, i) => {
    return (
      <QuizQuestion
        key={id}
        questionId={id}
        questionIndex={i}
        totalQuestions={quizData.questions.length}
        questionText={text}
        answerOptions={answers}
        quizVersion={quizData.quizVersion}
      />
    );
  });

  return (
    <Modal
      width="800px"
      isOpen={quizIsOpen}
      onModalClose={handleModalClose}
      bgClickToClose={false}
      escToClose={false}
      shadeBg
      footer={
        <ModalFooter
          videoReferenceTime={quizData.questions[currentQuestionIndex].videoReferencePosition}
        />
      }
    >
      <TagModal overlay="Product Quiz" />
      <Paginator
        items={paginatorItems}
        currentIndex={currentQuestionIndex ?? 0}
        focusSelector=".HeaderStyle h2"
      />
    </Modal>
  );
};

export default QuizModal;
