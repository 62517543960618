import styled from 'styled-components';
import { Container, HeaderStyles, SplashTextStyles, mixins } from '@pointdotcom/pds';
import { SubHeaderStyle } from 'components/SubHeader/styles';
import { appTheme } from 'styles';

export const DashboardProcessOverviewPageStyle = styled.div.attrs({
  className: 'DashboardProcessOverviewPageStyle',
})`
  background: white;

  ${SubHeaderStyle} {
    position: relative;
    z-index: 3;
  }
`;

export const OverviewWrapperStyle = styled(Container).attrs({ className: 'OverviewWrapperStyle' })`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: start;

  img {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    grid-template-columns: 1fr;

    img {
      max-width: 300px;
      align-self: center;
    }
  }
`;

export const HeroStyle = styled.div.attrs({ className: 'HeroStyle' })`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  --splashWidth: 85%;
  --topPadding: 8.75rem;

  padding-top: var(--topPadding);

  @media (min-width: ${({ theme }) => theme.responsive.tabletBreakpoint}) {
    position: sticky;
    top: calc(${appTheme.mainHeaders.height.mobile} - var(--topPadding) + 3rem);
  }

  ${SplashTextStyles.SplashCopySerifStyle} {
    width: var(--splashWidth);
  }

  ${HeaderStyles.HeaderStyle} {
    > * {
      font-size: ${(props) => mixins.getRemFromPx(72, props.theme.globals.baseFontSize.default)};
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    --topPadding: 3rem;
    --textWidth: 100%;
    ${HeaderStyles.HeaderStyle} {
      > * {
        font-size: ${(props) => mixins.getRemFromPx(50, props.theme.globals.baseFontSize.default)};
      }
    }
  }
`;

export const FundingStageWrapperStyle = styled.div.attrs({ className: 'FundingStageWrapperStyle' })`
  --padding: 5.5rem;
  padding-top: var(--padding);
  padding-bottom: var(--padding);

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    --padding: 1rem;
  }
`;
