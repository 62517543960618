import styled from 'styled-components';
import { ContainerStyles, HeaderStyles, mixins } from '@pointdotcom/pds';
import { BorderedSectionStyle, HeroContainerStyle, MainPageStyle } from 'styles/';

const innerPadding = '2rem';
const innerPaddingMobile = '6%';

export const EstimatorPageInnerContentStyle = styled.div.attrs({
  className: 'EstimatorPageInnerContentStyle',
})`
  display: flex;
  align-items: center;
  ${HeroContainerStyle} {
    align-items: center;
  }
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    flex-flow: column nowrap;
  }
`;

export const HeroSectionLeftStyle = styled.div.attrs({ className: 'HeroSectionLeftStyle' })`
  padding-right: ${innerPadding};
  flex: 1 1 auto;
  position: relative;
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    padding-right: 0;
    padding-bottom: ${innerPaddingMobile};
    ${HeaderStyles.HeaderStyle} {
      text-align: center;
    }
  }
  @media only print {
    padding: 0 ${innerPadding};
  }
`;

export const MarkSplashStyle = styled.p.attrs({ className: 'MarkSplashStyle' })`
  display: block;
  background: ${(props) => mixins.rgba(props.theme.Color.PointBlue, 0.15)};
`;

export const HeroSectionRightStyle = styled.div.attrs({ className: 'HeroSectionRightStyle' })`
  padding-left: ${innerPadding};
  padding-top: ${innerPadding};
  padding-bottom: ${innerPadding};
  border-style: solid;
  border-color: ${(props) => props.theme.line.color.default};
  border-width: 0;
  border-left-width: 1px;
  > p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    border-left-width: 0;
    border-top-width: 1px;
    padding-left: 0;
    padding-top: ${innerPaddingMobile};
    padding-bottom: 0;
  }
`;

export const ControlSectionStyle = styled(BorderedSectionStyle).attrs({
  className: 'ControlSectionStyle',
})`
  background: white;
  width: 100%;
  top: 0; /* required */
  transition: all 200ms ease-in-out;
  @media only print {
    display: none;
  }
`;

export const HeaderDescriptionStyle = styled.div.attrs({ className: 'HeaderDescriptionStyle' })`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.3em;
  align-items: ${(props) => (props.collapsed ? 'center' : 'flex-start')};
  position: relative;

  ${HeaderStyles.HeaderStyle} {
    flex: 5;
    @media only print {
      margin: 0 auto;
    }
  }
  p {
    flex: 2;
    margin: 0;
    display: ${(props) => (props.collapsed ? 'none' : 'block')};
    box-sizing: border-box;
    font-size: 14px;
    margin-bottom: 1rem;
    @media only screen and (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
      font-size: inherit;
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: left;
  }
`;

export const HeaderDescInputContainerStyle = styled.div.attrs({
  className: 'HeaderDescInputContainerStyle',
})`
  display: flex;
  width: 100%;
  transition: all 500ms ease-in-out;
  flex-flow: ${(props) => (props.collapsed ? 'row nowrap' : 'column nowrap')};
  ${HeaderDescriptionStyle} {
    flex: ${(props) => (props.collapsed ? '1' : 'auto')};
  }
`;

export const EstimatorPageStyle = styled(MainPageStyle).attrs({ className: 'EstimatorPageStyle' })`
  @media only print {
    ${HeroContainerStyle} {
      padding-bottom: 0;
    }
    .FollowStyle {
      display: none;
    }
  }
`;

export const FollowControlContainer = styled.div.attrs({ className: 'FollowControlContainer' })`
  flex: 1;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  > * {
    &:first-child {
      flex: 0 0 auto;
      padding-right: 10px;
    }
    &:last-child {
      flex: 1;
    }
  }
  &:first-child {
    border-right: 1px solid ${(props) => props.theme.line.color.default};
  }
  &:last-child {
    flex: 1;
    border-right: none;
  }
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    align-items: flex-start;
    flex-flow: column nowrap;
    > * {
      width: 100%;
      flex: 100%;
    }
  }
`;

export const FollowControlsContainer = styled.div.attrs({ className: 'FollowControlsContainer' })`
  ${ContainerStyles.ContainerStyle} {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    @media only screen and (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
      margin: 0 !important; /* TODO: remove vendor container and !important's */
      width: 100% !important; /* TODO: remove vendor container and !important's */
    }
  }
`;

export const ChildSectionStyle = styled.div.attrs({ className: 'ChildSectionSyle' })`
  margin-top: 2.6rem;
  &:empty {
    display: none;
  }
`;
