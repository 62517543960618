import styled, { css } from 'styled-components';
import { TwinkleGraphicStyle } from '../TwinkleGraphic/styles';
import goldenHouseImage from '../images/caphousegold.png';

const ANIM_SPEED_MS = 600;

export const GoldenHouseStyle = styled.div.attrs({ className: 'GoldenHouseStyle' })`
  --goldenHouseSize: 25%;
  transition:
    transform ${ANIM_SPEED_MS}ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity ${ANIM_SPEED_MS}ms ease-in-out ${ANIM_SPEED_MS * 0.3}ms;
  background-image: url('${goldenHouseImage}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: absolute;
  bottom: 0;
  right: 40%;
  width: var(--goldenHouseSize);
  padding-bottom: var(--goldenHouseSize);
  transform: scaleX(-1) translateX(-50%);
  opacity: 0;
`;

export const GoldenHousesGraphicStyle = styled.div.attrs({
  className: 'GoldenHousesGraphicStyle',
})<{ isShown?: boolean }>`
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;
  ${GoldenHouseStyle} {
    &:first-child {
      &:after {
        transition: opacity ${ANIM_SPEED_MS}ms ease-in-out ${ANIM_SPEED_MS * 0.8}ms;
        content: '';
        background: ${({ theme }) => theme.Color.Orange};
        width: 100%;
        height: 100%;
        position: absolute;
        display: block;
        border-radius: 50%;
        border-radius: 50%;
        bottom: -38%;
        right: 58%;
        mix-blend-mode: multiply;
        filter: blur(7px);
        opacity: 0;
      }
      --goldenHouseSize: 17%;
      ${TwinkleGraphicStyle} {
        z-index: 10000;
        &:first-child {
          --twinkleSize: 12px;
          bottom: 80%;
          left: 106%;
        }
        &:nth-child(2) {
          --twinkleSize: 8px;
          top: -10%;
          left: 30%;
        }
      }
    }
    &:last-child {
      transition-delay: ${ANIM_SPEED_MS * 0.3}ms;
      ${TwinkleGraphicStyle} {
        --twinkleSize: 8px;
      }
      --goldenHouseSize: 25%;
      right: 18%;
    }
  }
  ${({ isShown }) =>
    isShown &&
    css`
      ${GoldenHouseStyle} {
        transform: scaleX(-1) translateX(0);
        opacity: 1;
        &:first-child {
          &:after {
            opacity: 0.5;
          }
        }
      }
    `}
`;
