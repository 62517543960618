export default {
  pageName: 'Home Value',
  continue: 'Continue',
  errorMessage: 'Please enter an estimated home value.',
  yourHomeValue: 'Your home value',
  howMuch: 'How much do you think your home is worth?',
  theZillow: 'The Zillow Zestimate<sup>&reg;</sup> home valuation for %address% is %amount%',
  theHomeValuation: 'The estimated home valuation for %address% is %amount%',
  howWillPoint: 'How will Point determine the value of my home?',
  weWillUse:
    'We will use the value from a third-party valuation service. The value you submit on this page will only be used for your initial estimate and rate when the third-party service isn’t immediately available.',
  adjustValue: 'Adjust value',
  useMyValue: 'Use my value',
  zillowIsAn:
    '*Zillow is an online real estate database company that gathers and analyzes publicly available information about real estate values.',
  ourEstimate:
    '*Our estimate is based on publicly available information about real estate values. It does not take into account the condition of your home or other private information.',
  laterIn:
    'Later in the process, your property value will be determined using independent third-party reports including an in-person appraisal of your home. So itʼs possible youʼll see your offer change.',
  theValueYouZillow:
    'The value you entered is about %amount% %higherLower% than the value Zillow* estimates. When we calculate your initial estimated offer, weʼll use the home value you entered.',
  theValueYou:
    'The value you entered is about %amount% %higherLower% than the value we estimated.* When we calculate your initial estimated offer, weʼll use the home value you entered.',
  learnMore: 'Learn more here.',

  howWasThis: 'How was this estimate calculated?',
  theEstimated:
    'The estimated home valuation listed above was generated from publicly available data and is intended to be informational. Please accept or update this figure based on what you believe your home to be worth. The stated value you submit on this page will be used for your initial estimate. If you submit a complete application, we will verify your stated home value by a third-party valuation service.',
  doINeed: 'Do I need to own my home?',
  pointsHei: 'Point’s HEI is only available to individuals who own their home.',
};
