import React from 'react';
import { OfferPage } from 'containers/FollowUpOfferPage';
import { useFollowUpFetch } from 'containers/prequal/hooks';
import EstimateCalculatorPage from '..';
import i18n from './i18n';

const EstimateCalculatorOfferPage = () => {
  const followUpFetchResults = useFollowUpFetch();
  return (
    <EstimateCalculatorPage followUpFetchResults={followUpFetchResults} pageName={i18n.pageName}>
      <OfferPage followUpFetchResults={followUpFetchResults} />
    </EstimateCalculatorPage>
  );
};

export default EstimateCalculatorOfferPage;
