import * as React from 'react';
import { useEffect, useState } from 'react';
import { PostHogProvider as DefaultPostHogProvider, usePostHog } from 'posthog-js/react';
import { posthogFeatureFlagDefinitions } from './featureFlagDefinitions';
import {
  FeatureFlag,
  FeatureFlagValue,
  FeatureFlags,
  FeatureFlagsContext,
} from './featureFlagObjects';

interface FeatureFlagsProviderProps {
  children: React.ReactNode;
  PostHogProvider?: React.ComponentType<React.ComponentProps<typeof DefaultPostHogProvider>>;
}

function PosthogFeatureFlagsProvider({ children }: FeatureFlagsProviderProps): JSX.Element {
  const posthog = usePostHog();

  const [featureFlags, setFeatureFlags] = useState<null | FeatureFlags>(null);

  useEffect(() => {
    if (posthog != null) {
      const getValue = <TFlag extends FeatureFlag>(
        flag: TFlag
      ): undefined | FeatureFlagValue<TFlag> => {
        const featureFlagDefinition = posthogFeatureFlagDefinitions[flag];
        if (featureFlagDefinition == null) {
          return undefined;
        }

        const value = posthog.getFeatureFlag(featureFlagDefinition.key) as
          | undefined
          | FeatureFlagValue<TFlag>;
        return value;
      };

      return posthog.onFeatureFlags(() => {
        setFeatureFlags({
          getValue,
        });
      });
    }
    return undefined;
  }, [posthog]);

  return (
    <FeatureFlagsContext.Provider value={featureFlags}>{children}</FeatureFlagsContext.Provider>
  );
}

const posthogEnabled = process.env.REACT_APP_ENABLE_POSTHOG === 'true';

export function PostHogClientAndFeatureFlagsProvider({
  children,
  PostHogProvider = DefaultPostHogProvider,
}: FeatureFlagsProviderProps): JSX.Element {
  const posthogApiToken = process.env.REACT_APP_PUBLIC_POSTHOG_API_TOKEN;
  const posthogApiHost = process.env.REACT_APP_PUBLIC_POSTHOG_API_HOST;
  if (!posthogEnabled || !posthogApiToken || !posthogApiHost) {
    // eslint-disable-next-line react/destructuring-assignment
    return <>{children}</>;
  }

  return (
    <PostHogProvider
      apiKey={posthogApiToken}
      options={{
        api_host: posthogApiHost,
        // $pageview events will be captured via `usePageViewMonitor`
        capture_pageview: false,
        // Re-enable $pageleave events, disabled by line above
        capture_pageleave: true,
        disable_compression: process.env.REACT_APP_DISABLE_POSTHOG_COMPRESSION === 'true',
      }}
    >
      <PosthogFeatureFlagsProvider>{children}</PosthogFeatureFlagsProvider>
    </PostHogProvider>
  );
}
