// B variant for https://github.com/pointdotcom/customer-portal/issues/92
import styled, { css } from 'styled-components';
import {
  ContainerStyles,
  HeaderStyles,
  IconStyles,
  LinkButtonStyles,
  mixins,
} from '@pointdotcom/pds';

const highlightLineWidth = '2px';
export const calloutContainerBreakpoint = '780px';

export const PricingPresentationWithMathStyle = styled.div.attrs({
  className: 'PricingPresentationWithMathStyle',
})`
  ${ContainerStyles.ContainerStyle} {
    > ${HeaderStyles.HeaderStyle} {
      margin: 6% 0 calc(5% + 3rem);
    }
  }
  @media (max-width: ${calloutContainerBreakpoint}) {
    ${ContainerStyles.ContainerStyle} {
      > ${HeaderStyles.HeaderStyle} {
        margin: 3.5em 0 2em;
        > * {
          max-width: 70%;
        }
      }
    }
  }
`;

export const CalloutPreheaderTextStyle = styled.header.attrs({
  className: 'CalloutPreheaderTextStyle',
})`
  font-family: ${(props) => props.theme.fonts.serif.name};
  font-size: 1.4rem;
  margin-bottom: 1em;
  @media (max-width: ${calloutContainerBreakpoint}) {
    font-family: ${(props) => props.theme.fonts.sansSerif.name};
    font-weight: 700;
    font-size: ${(props) => props.theme.header.fontSize.default.mobile};
    margin-bottom: 0.3em;
  }
`;

export const CalloutHeaderTextStyle = styled.header.attrs({ className: 'CalloutHeaderTextStyle' })`
  font-family: ${(props) => props.theme.fonts.serif.name};
  font-size: 2.4em;
  margin-bottom: 2px;
  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    font-size: 2.1em;
  }
  @media (max-width: ${calloutContainerBreakpoint}) {
    margin-bottom: 5px;
    max-width: 200px;
    ${(props) => {
      if (props.isPlaceholder) {
        return css`
          display: none;
        `;
      }
      return null;
    }}
  }
`;

export const CalloutPlainTextStyle = styled.aside.attrs({ className: 'CalloutPlainTextStyle' })`
  display: block;
  margin-bottom: 5px;
`;

export const CalloutItemStyle = styled.div.attrs({ className: 'CalloutItemStyle' })`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 10% 8%;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.line.color.invertedSubtle};
  text-align: center;

  > * {
    width: 100%;
  }
  &.firstCallout {
    padding: 6% 5% 8%;
  }

  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    padding: 8% 6%;
  }

  @media (max-width: ${calloutContainerBreakpoint}) {
    align-items: flex-start;
    text-align: left;
    padding: 55px 40px 0;

    &.firstCallout {
      text-align: center;
      align-items: center;
      min-height: 150px;
      justify-content: center;
      padding: 8% 5% 8%;
    }
    &.thirdCallout {
      ${CalloutPreheaderTextStyle} {
        margin-bottom: 1em;
      }
    }
    ${CalloutHeaderTextStyle} {
      &:last-child {
        margin: 0;
      }
    }
  }
`;

export const CalloutItemPlusStyle = styled.div.attrs({ className: 'CalloutItemPlusStyle' })`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.callOutTextColor || props.theme.Color.Yorange};
  flex: 0.5;
  padding: 15px;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    flex: 0.3;
  }
  @media (max-width: ${calloutContainerBreakpoint}) {
    flex: auto;
    padding: 20px 0 0;
    height: 75px;
    align-items: flex-start;
    ${HeaderStyles.HeaderStyle} {
      position: relative;
      &:before {
        content: '';
        background: ${(props) => props.callOutLineColor || props.theme.Color.Yorange};
        width: ${highlightLineWidth};
        height: 23px;
        position: absolute;
        top: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`;

export const CalloutContainerStyle = styled.div.attrs({ className: 'CalloutContainerStyle' })`
  display: flex;
  flex-flow: row nowrap;
  @media (max-width: ${calloutContainerBreakpoint}) {
    flex-flow: column nowrap;
  }
`;

export const CalloutLinkContainerStyle = styled.div.attrs({
  className: 'CalloutLinkContainerStyle',
})`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  ${IconStyles.IconStyle} {
    display: none;
  }
  @media (max-width: ${calloutContainerBreakpoint}) {
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid ${(props) => props.theme.line.color.invertedSubtle};
    ${IconStyles.IconStyle} {
      display: block;
      transform: scale(0.8);
    }
  }
`;

const callOutLinkHoverCSS = css`
  color: inherit;
  border-color: ${(props) => props.theme.Color.White};
`;

export const CalloutLinkStyle = styled(LinkButtonStyles.ButtonLinkStyle)`
  transition: all 180ms ease-in-out;
  color: ${(props) => props.theme.Color.White};
  text-decoration: none;
  display: inline-block;
  white-space: normal;
  border: 1px solid ${(props) => props.theme.line.color.invertedSubtle};
  padding: 12px;
  width: 100%;
  ${mixins.desktopHoverCondition()} {
    &:hover {
      ${callOutLinkHoverCSS}
    }
  }
  @media (max-width: ${calloutContainerBreakpoint}) {
    color: white;
    padding: 30px 0;
    text-align: left;
    max-width: 80%;
    border: none;
    width: calc(100% - 40px);
  }
`;

export const CalloutOrItemsContainerStyle = styled(CalloutContainerStyle).attrs({
  className: 'CalloutOrItemsContainerStyle',
})`
  flex: 3;
  position: relative;
  ${CalloutItemStyle} {
    flex: 1;
    &:last-child {
      margin-left: 20px;
    }
  }

  &:before {
    content: attr(data-preText);
    display: block;
    position: absolute;
    width: 100%;
    color: ${(props) => props.callOutTextColor || props.theme.Color.Yorange};
    left: 0;
    bottom: 100%;
    text-align: center;
    padding-bottom: 0.5em;
    border-bottom: ${highlightLineWidth} solid
      ${(props) => props.callOutLineColor || props.theme.Color.Yorange};
    transform: translateY(-20px);
    font-weight: bold;
    font-family: ${(props) => props.theme.header.fontFamily.default};
    font-size: ${(props) => props.theme.header.fontSize.default.default};
  }
  @media (max-width: ${calloutContainerBreakpoint}) {
    flex: auto;
    width: 100%;
    ${CalloutItemStyle} {
      border: ${highlightLineWidth} solid
        ${(props) => props.callOutLineColor || props.theme.Color.Yorange};
      &:last-child {
        margin-left: 0;
        border-top: none;
      }
    }
    &:before {
      display: none;
    }
  }
`;
