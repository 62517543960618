export default {
  propertyAddress: 'Property address',
  howDoYouOwn: 'How do you own this property?',
  chooseOne: 'Choose one',

  isThisYourPrimary: 'Is this your primary residence?',
  isYourPropertyAMobile: 'Is your property a mobile, manufactured or modular home?',
  whatIsTheCurrent:
    'What is the current balance of your primary mortgage including any deferred principal balance? (Do not include the balance of any other loans on your home.)',

  doYouHaveAHomeEquity:
    'Do you have a home equity loan, HELOC, or any other loan secured by your home?',
  isThisAHELOC: 'Is this a HELOC within its draw period?',
  whatIsTheTotal: 'What is the total credit limit?',
  whatIsTheCurrentBalance:
    'What is the current balance of your home equity loan, HELOC, or other loan secured by your home?',
  doYouHaveAZero:
    'Do you have a HERO loan, a PACE loan, or any other type of solar or energy-efficiency loan on this property? This may include loans that are repaid monthly or that attach to your property taxes.',
  areThereAnyOutstanding:
    'Are there any other outstanding or pending liens or judgments against this property?',
  isYourPropertyPartOfanHOA: 'Is your property part of an HOA or condo association?',
  isYourHOAorCondo:
    'Is your HOA or condo association involved in any ongoing or pending litigation?',
  areYouCurrentlyDelinquent:
    'Are you currently delinquent on your property taxes? (i.e. do you have any past-due payments?)',

  areThereAny:
    'Are there any hazardous substances or waste at the property (such as contaminants, oils, asbestos, radon, or PCBs) that would put it in violation of any local, state, or federal environmental law?',
  areThereAnyPending:
    'Are there any pending actions directly involving your property alleging a violation of any environmental law, rule, or regulation?',
  areThereAnyConditions:
    'Are there any conditions that adversely affect or impair the value of the property?',
  areThereAnyUnpermitted:
    'Are there any unpermitted works, code violations, unpermitted additional dwelling units, or non-conforming uses pertaining to the property?',
  doesYourPropertyInclude:
    'Does your property include an accessory dwelling unit, in-law unit, or granny flat?',
  areYouInTheMiddle:
    'Are you in the middle of a remodel or renovation on your property, or planning to start a remodel or renovation prior to receiving funds from Point?',
  areThereAnyOngoing: 'Are there any ongoing or pending lawsuits directly involving your property?',
  areYouCurrentlyDelinquentOnYourHOA:
    'Are you currently delinquent on your HOA or condo association dues? (i.e. do you have any past-due payments?)',
  doYouHaveModification:
    'Do you have a modification on your mortgage or home loan? Or have the terms of your mortgage or home loan changed since it was originated?',
  doYouOwnYourPrimary: 'Do you own your primary residence?',
  whoElseIsOnTitle: 'Who else is on title for your property?',
  thisIncludesAnyone:
    'This includes anyone who is a legal owner of the home. All owners will be required to sign your Point agreement even if they are not applying.',
  doYouHaveActiveForbearance:
    'Do you currently have a mortgage or home loan that is in %active% forbearance?',
  doYouHaveDeferredPayment:
    'Do you currently have a mortgage or home loan that has a deferred payment plan?',
  iConfirmThisMortgageBalance: 'I confirm this mortgage balance is correct',
  pleaseConfirm: 'Please confirm your current mortgage balance is correct',
  active: 'active',
  didYouMiss: 'Did you miss your last mortgage payment?',
  doYouAgree: 'Do you agree to use your HEI proceeds to pay the balance of your past due mortgage?',
  doYouAgreeNote:
    'NOTE: If you do not wish to use your HEI funds to pay off the balance of your past due mortgage, you may not be eligible for an HEI.',
  howManyMortgage: 'How many mortgage payments have you missed?',
  justTheMost: 'Just the most recent mortgage payment',
  theLastTwo: 'The last two or more mortgage payments',
};
