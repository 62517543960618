import * as React from 'react';
import { TemplatedText } from '@pointdotcom/pds';
import CapShield from 'containers/hei/HeiOfferEstimatorPage/CapShield';
import { currencyMask } from 'models/helpers';
import i18n from './i18n';
import * as styles from './styles';

interface ScenariosTableCapTipProps {
  capDifference: number;
  onClickCapLink: () => void;
}

export default function ScenariosTableCapTip({
  capDifference,
  onClickCapLink,
}: ScenariosTableCapTipProps): JSX.Element {
  const handleCapLinkClick = () => onClickCapLink();

  const handleCapLinkKeyDown: React.KeyboardEventHandler = (event) => {
    if (event.code === 'Enter') {
      onClickCapLink();
    }
  };

  return (
    <styles.ScenariosTableCapTipStyle>
      <styles.ScenariosTableCapTipCardStyle>
        <CapShield />
        <styles.ScenariosTableCapTipTextStyle>
          <TemplatedText
            values={{
              capModalLink: (
                // Use an anchor tag instead of `LinkButton` to support proper word wrap.
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  role="button"
                  aria-label={i18n.homeownerProtectionCap}
                  tabIndex={0}
                  onClick={handleCapLinkClick}
                  onKeyDown={handleCapLinkKeyDown}
                >
                  {i18n.homeownerProtectionCap}
                </a>
              ),
              reductionAmount: <strong>{currencyMask.getFormatted(capDifference)}</strong>,
            }}
          >
            {i18n.reducesYourRepayment}
          </TemplatedText>
        </styles.ScenariosTableCapTipTextStyle>
      </styles.ScenariosTableCapTipCardStyle>
    </styles.ScenariosTableCapTipStyle>
  );
}
