export default {
  thisIs:
    'This is an estimate only. Your actual offer will depend on a thorough review of your finances.',
  thisIsMobile: 'This is an estimate only.',
  thisIsAnEstimate:
    'This is an estimate only, based on the information you provided. Your final offer and eligibility will depend on a thorough review of your finances.',
  yourFinal: 'Your final offer and eligibility will depend on a thorough review of your finances.',
  thereWasAProblem: 'There was a problem logging you out. Please refresh the page and try again.',
  looksLikeOffer: 'Looks like you have a more recently created offer',
  viewNewOffer: 'View new offer',

  // TODO: delete these after old application is dead
  previouslyStartedAnApplication: 'You previously started an application that is no longer active.',
  createNewOffer: 'You can create a new offer by going here',
  ifYouHaveQuestions:
    '%newOffer%. If you have questions, please email us at %email% or call us at %phone%.',
  // / TODO
};
