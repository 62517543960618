import * as z from "zod";
import * as Underwrite from "./Underwrite.types";

export enum DocketStatus {
  New = "new",
  Active = "active",
  Completed = "completed",
  Closed = "closed",
  Inactive = "inactive",
  Rejected = "rejected",
  Canceled = "canceled",
}
export const docketStatusSchema = z.nativeEnum(DocketStatus);

export enum AutomatedDocketQualificationStatus {
  AutoRejected = "auto_rejected",
  Errored = "errored",
  FlaggedForReview = "flagged_for_review",
  Passed = "passed",
  Processing = "processing",
  RecommendedForRejection = "recommended_for_rejection",
  Waitlisted = "waitlisted",
  CiqRequired = "ciq_required",
}
export const automatedDocketQualificationStatusSchema = z.nativeEnum(
  AutomatedDocketQualificationStatus,
);

export enum DocketStage {
  Drafting = "drafting",
  Application = "application",
  Verification = "verification",
  Underwriting = "underwriting",
  FinalPricing = "final_pricing",
  Closing = "closing",
}
export const docketStageSchema = z.nativeEnum(DocketStage);

export const applicantFlowStatusSuccessResponseSchema = z
  .object({
    success: z.literal(true),
    status: z.string(),
    adq_status: automatedDocketQualificationStatusSchema.nullable(),
  })
  .catchall(z.unknown() as z.ZodType);
export type ApplicantFlowStatusSuccessResponse = z.infer<
  typeof applicantFlowStatusSuccessResponseSchema
>;

export const applicantFlowStatusResponseSchema = z.union([
  applicantFlowStatusSuccessResponseSchema,
  Underwrite.errorResponseSchema,
]);
export type ApplicantFlowStatusResponse = z.infer<
  typeof applicantFlowStatusResponseSchema
>;
