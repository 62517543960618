import styled, { css } from 'styled-components';
import { DirectionAndPlacement, mixins } from '@pointdotcom/pds';
import { SectionShadowProps } from './constants';

const heightPx = 36;
export const SectionShadowShadowStyle = styled.div.attrs({
  className: 'SectionShadowShadowStyle',
})<SectionShadowProps>`
  z-index: -1;
  height: ${heightPx}px;
  position: relative;
  overflow: hidden;
  &::after {
    position: absolute;
    ${({ stylePosition }) =>
      stylePosition === DirectionAndPlacement.Top
        ? css`
            top: -100%;
          `
        : css`
            top: 100%;
          `}
    width: 100%;
    height: 100%;
    display: block;
    background: ${({ theme }) => mixins.rgba(theme.line.color.default, 0.2)};
    border-radius: 50%;
    filter: blur(10px);
    content: '';
  }
`;

const positionedShadowCss = css<SectionShadowProps>`
  pointer-events: none;
  position: absolute;
  width: 100%;
  left: 50%;
  /* border: 1px solid red; */
  transform: translateX(-50%);
  z-index: 2;
  ${({ stylePosition }) =>
    stylePosition === DirectionAndPlacement.Top
      ? css`
          top: 0;
        `
      : css`
          bottom: 0;
        `}
`;

export const SectionShadowStyle = styled.div.attrs({
  className: 'SectionShadowStyle',
})<SectionShadowProps>`
  ${({ stylePosition }) => (stylePosition ? positionedShadowCss : undefined)}
`;
