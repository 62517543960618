import { darken } from 'polished';
import styled, { css } from 'styled-components';
import { ButtonStyles, CloseButtonStyles, HelpTextStyles } from '@pointdotcom/pds';

const CLOSE_BT_SIZE = '35px';
const CLOSE_BT_SIZE_MOBILE = '25px';

export const GenericMessageBannerStyle = styled.div.attrs({
  className: 'GenericMessageBannerStyle',
})<{ show: boolean; bordered: boolean; blueTheme: boolean }>`
  border-bottom: ${(props) =>
    props.bordered && props.show ? `1px solid ${props.theme.line.color.default}` : 'none'};
  ${(props) => {
    // hacky stuff here to account for our blue mountains page
    // remove if new variant for prequal landing page test wins
    if (props.blueTheme) {
      const darkBlue = darken(0.05, props.theme.Color.PurpleBlue6);
      const darkerBlue = darken(0.1, props.theme.Color.PurpleBlue6);
      return css`
        color: white;
        border: none;
        ${HelpTextStyles.HelpTextStyle} {
          color: inherit;
          background: ${darkBlue};
        }
        ${ButtonStyles.ButtonBaseStyle} {
          color: inherit;
          background: ${darkBlue};
          &:hover {
            background: ${darkerBlue};
          }
        }
      `;
    }

    return null;
  }}
`;

export const MessageStyle = styled.div.attrs({ className: 'MessageStyle' })<{
  hasAction: boolean;
  hasClose: boolean;
}>`
  display: grid;
  grid-gap: 1rem; // No IE support, but no significant consequence to user
  align-items: flex-start;
  grid-template-columns: ${({ hasAction, hasClose }) => {
    if (hasAction && hasClose) {
      return `1fr auto ${CLOSE_BT_SIZE}`;
    }
    if (hasClose) {
      return `1fr ${CLOSE_BT_SIZE}`;
    }
    if (hasAction) {
      return '1fr auto';
    }

    return '1fr';
  }};
  grid-template-areas: 'message cta close';
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  > * {
    text-align: ${({ hasAction, hasClose }) => {
      if (hasAction || hasClose) {
        return 'left';
      }
      return 'center';
    }};
  }
  .message {
    grid-area: message;
  }

  //TODO: add to PDS
  .closeContainer {
    grid-area: close;
  }

  ${ButtonStyles.ButtonBaseStyle} {
    grid-area: cta;
    border: 1px solid ${(props) => props.theme.line.color.invertedSubtle};
    &:hover {
      border-color: transparent;
    }
  }

  ${CloseButtonStyles.CloseButtonStyle} {
    position: relative;
    top: 0;
    right: 0;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    grid-template-columns: ${({ hasAction, hasClose }) => {
      if (!hasClose) {
        return `calc(100% - ${CLOSE_BT_SIZE_MOBILE})`;
      }
      if (hasAction || hasClose) {
        return `calc(100% - ${CLOSE_BT_SIZE_MOBILE}) ${CLOSE_BT_SIZE_MOBILE}`;
      }
      return '1fr';
    }};
    grid-template-areas:
      ${({ hasAction, hasClose }) => {
        if (hasAction && hasClose) {
          return `
        "message close"
        "cta ...";
      `;
        }

        if (hasAction) {
          return `
        "message"
        "cta";
      `;
        }

        if (hasClose) {
          return `
        "message close";
      `;
        }

        return null;
      }}
      ${CloseButtonStyles.CloseButtonStyle} {
      width: ${CLOSE_BT_SIZE_MOBILE};
      height: ${CLOSE_BT_SIZE_MOBILE};
    }
  }
`;
