import React from 'react';
import { z } from 'zod';
import {
  Checkbox,
  Dropdown,
  IconName,
  InputMaskType,
  TemplatedText,
  TextArea,
} from '@pointdotcom/pds';
import SmartyStreetsAutocomplete from 'components/SmartyStreetsAutocomplete';
import { FormTemplateFunction } from 'containers/hei/ApplicationPage/constants';
import {
  OwnershipStatus,
  balanceConfirmationSchema,
  hoaSchema,
  mortgageBalanceSchema,
  mortgageMaxAmount,
  mortgageSchema,
  ownershipSchema,
  ownershipStatusWithOthersEnumSchema,
  primaryResidenceSchema,
  propertyHasLoanSchema,
} from 'services/apiTypes/homeownerTypes';
import { getHEIEstimateModel } from 'store/estimates';
import reduxStore from 'store/index';
import AdditionalOwners from '../../FormComponents/AdditionalOwners';
import CheckboxStackField from '../../FormComponents/CheckboxStackField';
import { yesNoExplainSectionsCreator } from '../../FormComponents/FormTemplate';
import YesNoField from '../../FormComponents/YesNoField';
import { validateConditionalFields } from '../../FormComponents/validationUtils';
import { ShowFunc } from '../../constants';
import topLeveli18n from '../../i18n';
import i18n from './i18n';

const validateMortgageBalanceResult = ({
  mortgageBalance,
}: {
  mortgageBalance: z.infer<typeof mortgageBalanceSchema>;
}) => {
  const estimate = getHEIEstimateModel(reduxStore.getState());
  const balanceMin = 10_000;
  const balanceMax = estimate?.getPricing()?.getHomeValue() || mortgageMaxAmount;
  const dynamicBalanceSchema = z.number().min(balanceMin).max(balanceMax);
  return dynamicBalanceSchema.safeParse(mortgageBalance);
};

const showOnNonZeroMortgage: ShowFunc = ({ getFieldValueByPath }) => {
  const answer = getFieldValueByPath('property.mortgage.mortgageBalance');
  if (answer) {
    const balance = parseInt(String(answer), 10);
    return balance > 0;
  }
  return false;
};

const propertyTemplate: FormTemplateFunction = () => {
  return [
    {
      key: 'property',
      cols: 1,
      fields: [
        {
          path: 'property.addressOneLine',
          props: {
            disabled: true,
          },
          label: i18n.propertyAddress,
        },
      ],
    },
    {
      key: 'propertyOwnership',
      cols: 1,
      fields: [
        {
          path: 'property.ownership.ownershipStatus',
          Component: Dropdown,
          label: i18n.howDoYouOwn,
          props: {
            disableFirst: true,
            options: [i18n.chooseOne, ...Object.values(OwnershipStatus)],
          },
          validation: ({ getFieldValueByPath } = {}) => {
            const result = ownershipSchema.safeParse(getFieldValueByPath?.('property.ownership'));
            if (!result.success) {
              const thisError = result.error.format().ownershipStatus;
              if (!thisError) {
                // eslint-disable-next-line no-underscore-dangle
                if (result.error.format()._errors.includes('Required')) {
                  return result.error;
                }

                // there are errors with the array
                return new z.ZodError([
                  {
                    code: z.ZodIssueCode.custom,
                    path: [''],
                    message: '',
                    params: { associated: true },
                  },
                ]);
              }

              return result.error;
            }
            return true;
          },
        },
      ],
    },
    {
      key: 'additionalOwners',
      cols: 2,
      inset: true,
      label: i18n.whoElseIsOnTitle,
      description: i18n.thisIncludesAnyone,
      fields: [
        {
          path: 'property.ownership.additionalOwners',
          Component: AdditionalOwners,
          validation: ({ getFieldValueByPath } = {}) => {
            const result = ownershipSchema.safeParse(getFieldValueByPath?.('property.ownership'));
            if (!result.success) {
              const additionalOwnersErrors = result.error.issues.filter((issue) =>
                issue.path.includes('additionalOwners')
              );
              return new z.ZodError(additionalOwnersErrors);
            }
            return true;
          },
        },
      ],
      show: ({ getFieldValueByPath }) => {
        const value = getFieldValueByPath?.('property.ownership.ownershipStatus');
        return ownershipStatusWithOthersEnumSchema.safeParse(value).success;
      },
    },
    {
      key: 'isThisYourPrimary',
      cols: 2,

      fields: [
        {
          label: i18n.isThisYourPrimary,
          path: 'property.isPrimaryResidence.answer',
          parentPath: 'property.isPrimaryResidence',
          relatedPaths: [
            'property.isPrimaryResidence.primaryAddress',
            'property.isPrimaryResidence.ownsPrimaryAddress',
          ],
          Component: YesNoField,
          validation: validateConditionalFields(primaryResidenceSchema),
        },
      ],
    },
    {
      key: 'primaryAddress',
      inset: true,
      cols: 2,
      fields: [
        {
          label: 'Primary Address',
          path: 'property.isPrimaryResidence.primaryAddress',
          parentPath: 'property.isPrimaryResidence',
          relatedPaths: ['property.isPrimaryResidence.ownsPrimaryAddress'],
          Component: SmartyStreetsAutocomplete,
          props: {
            inputProps: { styleSize: 'small' },
          },
          validation: validateConditionalFields(primaryResidenceSchema),
        },
      ],
      show: ({ getFieldValueByPath }) => {
        const answer = getFieldValueByPath('property.isPrimaryResidence.answer');
        return answer !== undefined && Boolean(answer) === false;
      },
    },
    {
      key: 'doYouOwnYourPrimaryResidence',
      cols: 2,
      inset: true,
      fields: [
        {
          label: i18n.doYouOwnYourPrimary,
          path: 'property.isPrimaryResidence.ownsPrimaryAddress',
          parentPath: 'property.isPrimaryResidence',
          relatedPaths: ['property.isPrimaryResidence.primaryAddress'],
          Component: YesNoField,
          validation: validateConditionalFields(primaryResidenceSchema),
        },
      ],
      show: ({ getFieldValueByPath }) => {
        const answer = getFieldValueByPath('property.isPrimaryResidence.answer');
        return answer !== undefined && Boolean(answer) === false;
      },
    },
    {
      key: 'isMobileHome',
      cols: 2,

      fields: [
        {
          label: i18n.isYourPropertyAMobile,
          path: 'property.isMobileHome',
          Component: YesNoField,
        },
      ],
    },
    {
      key: 'currentBalance',
      cols: 2,
      fields: [
        {
          path: 'property.mortgage.mortgageBalance',
          label: i18n.whatIsTheCurrent,
          parentPath: 'property.mortgage',
          validation: (props) => {
            const { getFieldValueByPath, fieldValue: mortgageBalance } = props || {};
            const confirmationValue = getFieldValueByPath?.(
              'property.mortgage.balanceConfirmation'
            );
            const confirmationResult = balanceConfirmationSchema.safeParse(confirmationValue);

            // show an associated error if the confirmation isnt made
            if (
              mortgageBalanceSchema.safeParse(mortgageBalance).success &&
              !validateMortgageBalanceResult({
                mortgageBalance: mortgageBalance as z.infer<typeof mortgageBalanceSchema>,
              }).success &&
              !confirmationResult.success
            ) {
              return new z.ZodError([
                {
                  code: z.ZodIssueCode.custom,
                  path: [],
                  message: '',
                  params: { associated: true },
                },
              ]);
            }

            // otherwise validate the mortgageSchema like normal
            return validateConditionalFields(mortgageSchema)(props);
          },
          props: {
            iconType: IconName.Money,
            mask: InputMaskType.Number,
            EXPERIMENTAL_useUnformattedValues: true,
            inputMode: 'numeric',
          },
        },
      ],
    },
    {
      key: 'mortgageConfirmation',
      cols: 2,
      inset: true,
      show: ({ getFieldValueByPath, correcting }) => {
        if (!correcting) {
          return false;
        }

        const mortgageBalance = getFieldValueByPath('property.mortgage.mortgageBalance') as z.infer<
          typeof mortgageBalanceSchema
        >;

        // show the confirmation if the balance doesnt have its own errors
        // and the dynamic min/max schema for mortgage balance doesnt pass
        return (
          mortgageBalanceSchema.safeParse(mortgageBalance).success &&
          !validateMortgageBalanceResult({ mortgageBalance }).success
        );
      },
      fields: [
        {
          label: i18n.pleaseConfirm,
          path: 'property.mortgage.balanceConfirmation',
          Component: Checkbox,
          props: {
            type: 'checkbox',
            label: i18n.iConfirmThisMortgageBalance,
            value: i18n.iConfirmThisMortgageBalance,
          },
          validation: ({ getFieldValueByPath, fieldValue } = {}) => {
            const mortgageBalance = getFieldValueByPath?.(
              'property.mortgage.mortgageBalance'
            ) as z.infer<typeof mortgageBalanceSchema>;

            // show an associated error so that it stays in the corrections modal when there is a problem with mortgage balance
            if (!mortgageBalanceSchema.safeParse(mortgageBalance).success) {
              return new z.ZodError([
                {
                  code: z.ZodIssueCode.custom,
                  path: [],
                  message: '',
                  params: { associated: true },
                },
              ]);
            }

            // otherwise check if the mortgageBalance is outside the range and if the confirmation field hasnt been ticked
            if (!validateMortgageBalanceResult({ mortgageBalance }).success) {
              const thisFieldResult = balanceConfirmationSchema.safeParse(fieldValue);
              if (!thisFieldResult.success) {
                return thisFieldResult.error;
              }
            }

            return true;
          },
        },
      ],
    },
    ...yesNoExplainSectionsCreator({
      forBasePath: 'property.mortgage.hasMortgageModification',
      label: i18n.doYouHaveModification,
      show: showOnNonZeroMortgage,
      parentPath: 'property.mortgage',
      validation: validateConditionalFields(mortgageSchema),
    }),
    {
      key: 'hasActiveForbearance',
      cols: 2,
      fields: [
        {
          label: (
            <TemplatedText values={{ active: <em>{i18n.active}</em> }}>
              {i18n.doYouHaveActiveForbearance}
            </TemplatedText>
          ),
          path: 'property.mortgage.hasActiveForbearance',
          Component: YesNoField,
          parentPath: 'property.mortgage',
          validation: validateConditionalFields(mortgageSchema),
        },
      ],
      show: showOnNonZeroMortgage,
    },
    ...yesNoExplainSectionsCreator({
      forBasePath: 'property.mortgage.hasDeferredPayment',
      label: i18n.doYouHaveDeferredPayment,
      show: showOnNonZeroMortgage,
      parentPath: 'property.mortgage',
      validation: validateConditionalFields(mortgageSchema),
    }),
    {
      key: 'property.mortgage.missedPayment',
      cols: 2,
      fields: [
        {
          label: i18n.didYouMiss,
          path: 'property.mortgage.missedPayment.answer',
          Component: YesNoField,
          parentPath: 'property.mortgage',
          validation: validateConditionalFields(mortgageSchema),
          relatedPaths: [
            'property.mortgage.missedPayment.howManyMortgagePaymentsMissed.answer',
            'property.mortgage.missedPayment.howManyMortgagePaymentsMissed.explanation',
          ],
        },
      ],
      show: showOnNonZeroMortgage,
    },
    {
      key: 'property.mortgage.missedPayment.agreeToPayoff',
      cols: 2,
      fields: [
        {
          label: i18n.doYouAgree,
          description: i18n.doYouAgreeNote,
          path: 'property.mortgage.missedPayment.agreeToPayoff',
          Component: YesNoField,
          parentPath: 'property.mortgage',
          validation: validateConditionalFields(mortgageSchema),
        },
      ],
      show: ({ getFieldValueByPath }) => {
        return (
          Boolean(getFieldValueByPath?.('property.mortgage.missedPayment.answer')) &&
          showOnNonZeroMortgage({ getFieldValueByPath })
        );
      },
    },
    {
      key: 'property.mortgage.missedPayment.howManyMortgagePaymentsMissed.answer',
      cols: 2,
      fields: [
        {
          label: i18n.howManyMortgage,
          path: 'property.mortgage.missedPayment.howManyMortgagePaymentsMissed.answer',
          Component: CheckboxStackField,
          props: {
            type: 'radio',
            keyValues: {
              most_recent: i18n.justTheMost,
              two_or_more: i18n.theLastTwo,
            },
          },
          parentPath: 'property.mortgage',
          validation: validateConditionalFields(mortgageSchema),
          relatedPaths: [
            'property.mortgage.missedPayment.howManyMortgagePaymentsMissed.explanation',
          ],
        },
      ],
      show: ({ getFieldValueByPath }) => {
        return (
          Boolean(getFieldValueByPath?.('property.mortgage.missedPayment.answer')) &&
          showOnNonZeroMortgage({ getFieldValueByPath })
        );
      },
    },
    {
      key: 'property.mortgage.missedPayment.howManyMortgagePaymentsMissed.explanation',
      cols: 2,
      inset: true,
      fields: [
        {
          label: topLeveli18n.pleaseExplain,
          path: 'property.mortgage.missedPayment.howManyMortgagePaymentsMissed.explanation',
          Component: TextArea,
          parentPath: 'property.mortgage',
          validation: validateConditionalFields(mortgageSchema),
        },
      ],
      show: ({ getFieldValueByPath }) => {
        const parentAnswerYes = Boolean(
          getFieldValueByPath?.('property.mortgage.missedPayment.answer')
        );
        const childQuestionAnswered = Boolean(
          getFieldValueByPath?.(
            'property.mortgage.missedPayment.howManyMortgagePaymentsMissed.answer'
          )
        );
        return (
          parentAnswerYes && childQuestionAnswered && showOnNonZeroMortgage({ getFieldValueByPath })
        );
      },
    },
    {
      key: 'hasLoan',
      cols: 2,

      fields: [
        {
          label: i18n.doYouHaveAHomeEquity,
          path: 'property.hasLoan.answer',
          Component: YesNoField,
          relatedPaths: ['property.hasLoan.isHelocWithinDrawPeriod'],
          parentPath: 'property.hasLoan',
          validation: validateConditionalFields(propertyHasLoanSchema),
        },
      ],
    },
    {
      key: 'isHeloc',
      cols: 2,
      show: ({ getFieldValueByPath }) => {
        return Boolean(getFieldValueByPath('property.hasLoan.answer'));
      },
      fields: [
        {
          label: i18n.isThisAHELOC,
          path: 'property.hasLoan.isHelocWithinDrawPeriod.answer',
          Component: YesNoField,
          relatedPaths: [
            'property.hasLoan.answer',
            'property.hasLoan.isHelocWithinDrawPeriod.totalCreditLimit',
            'property.hasLoan.isHelocWithinDrawPeriod.currentLoanBalance',
          ],
          parentPath: 'property.hasLoan',
          validation: validateConditionalFields(propertyHasLoanSchema),
        },
      ],
    },
    {
      key: 'totalCreditLimit',
      cols: 2,
      show: ({ getFieldValueByPath }) => {
        return (
          Boolean(getFieldValueByPath('property.hasLoan.answer')) &&
          Boolean(getFieldValueByPath('property.hasLoan.isHelocWithinDrawPeriod.answer'))
        );
      },
      fields: [
        {
          path: 'property.hasLoan.isHelocWithinDrawPeriod.totalCreditLimit',
          label: i18n.whatIsTheTotal,
          props: {
            iconType: IconName.Money,
            mask: InputMaskType.Number,
            EXPERIMENTAL_useUnformattedValues: true,
            inputMode: 'numeric',
          },
          relatedPaths: [
            'property.hasLoan.answer',
            'property.hasLoan.isHelocWithinDrawPeriod.answer',
            'property.hasLoan.isHelocWithinDrawPeriod.currentLoanBalance',
          ],
          parentPath: 'property.hasLoan',
          validation: validateConditionalFields(propertyHasLoanSchema),
        },
      ],
    },
    {
      key: 'currentLoanBalance',
      cols: 2,
      show: ({ getFieldValueByPath }) => {
        const fieldVal = getFieldValueByPath('property.hasLoan.isHelocWithinDrawPeriod.answer');
        return (
          Boolean(getFieldValueByPath('property.hasLoan.answer')) &&
          fieldVal !== undefined &&
          Boolean(fieldVal) === false
        );
      },
      fields: [
        {
          path: 'property.hasLoan.isHelocWithinDrawPeriod.currentLoanBalance',
          label: i18n.whatIsTheCurrentBalance,
          props: {
            iconType: IconName.Money,
            mask: InputMaskType.Number,
            EXPERIMENTAL_useUnformattedValues: true,
            inputMode: 'numeric',
          },
          relatedPaths: [
            'property.hasLoan.answer',
            'property.hasLoan.isHelocWithinDrawPeriod.totalCreditLimit',
            'property.hasLoan.isHelocWithinDrawPeriod.answer',
          ],
          parentPath: 'property.hasLoan',
          validation: validateConditionalFields(propertyHasLoanSchema),
        },
      ],
    },
    ...yesNoExplainSectionsCreator({
      forBasePath: 'property.hasEnergyEfficiencyLoan',
      label: i18n.doYouHaveAZero,
    }),
    ...yesNoExplainSectionsCreator({
      forBasePath: 'property.hasPendingLiensOrJudgments',
      label: i18n.areThereAnyOutstanding,
    }),
    {
      key: 'propertyHoaAnswer',
      cols: 2,

      fields: [
        {
          label: i18n.isYourPropertyPartOfanHOA,
          path: 'property.hoa.answer',
          Component: YesNoField,
          parentPath: 'property.hoa',
          relatedPaths: ['property.hoa.pendingLitigation', 'property.hoa.delinquent'],
          validation: validateConditionalFields(hoaSchema),
        },
      ],
    },
    {
      key: 'propertyHoaPendingLitigationAnswer',
      cols: 2,
      show: ({ getFieldValueByPath }) => {
        return Boolean(getFieldValueByPath('property.hoa.answer'));
      },
      fields: [
        {
          label: i18n.isYourHOAorCondo,
          path: 'property.hoa.pendingLitigation.answer',
          Component: YesNoField,
          parentPath: 'property.hoa',
          relatedPaths: ['property.hoa.pendingLitigation.explanation'],
          validation: validateConditionalFields(hoaSchema),
        },
      ],
    },
    {
      key: 'propertyHoaPendingLitigationExplanation',
      cols: 2,
      inset: true,
      show: ({ getFieldValueByPath }) => {
        const hasTopAnswer = Boolean(getFieldValueByPath('property.hoa.answer'));
        const hasParentAnswer = Boolean(
          getFieldValueByPath('property.hoa.pendingLitigation.answer')
        );
        return hasTopAnswer && hasParentAnswer;
      },
      fields: [
        {
          label: topLeveli18n.pleaseExplain,
          path: 'property.hoa.pendingLitigation.explanation',
          Component: TextArea,
          parentPath: 'property.hoa',
          relatedPaths: ['property.hoa.delinquent'],
          props: {
            noMargin: true,
          },
          validation: validateConditionalFields(hoaSchema),
        },
      ],
    },
    {
      key: 'propertyHoaDelinquentAnswer',
      cols: 2,
      show: ({ getFieldValueByPath }) => {
        return Boolean(getFieldValueByPath('property.hoa.answer'));
      },
      fields: [
        {
          label: i18n.areYouCurrentlyDelinquentOnYourHOA,
          path: 'property.hoa.delinquent.answer',
          Component: YesNoField,
          parentPath: 'property.hoa',
          relatedPaths: ['property.hoa.delinquent.explanation'],
          validation: validateConditionalFields(hoaSchema),
        },
      ],
    },
    {
      key: 'propertyHoaDelinquentExplanation',
      cols: 2,
      inset: true,
      show: ({ getFieldValueByPath }) => {
        return (
          Boolean(getFieldValueByPath('property.hoa.answer')) &&
          Boolean(getFieldValueByPath('property.hoa.delinquent.answer'))
        );
      },
      fields: [
        {
          label: topLeveli18n.pleaseExplain,
          path: 'property.hoa.delinquent.explanation',
          Component: TextArea,
          parentPath: 'property.hoa',
          relatedPaths: ['property.hoa.pendingLitigation'],
          props: {
            noMargin: true,
          },
          validation: validateConditionalFields(hoaSchema),
        },
      ],
    },
    ...yesNoExplainSectionsCreator({
      forBasePath: 'property.isDelinquentOnPropertyTaxes',
      label: i18n.areYouCurrentlyDelinquent,
    }),
    ...yesNoExplainSectionsCreator({
      forBasePath: 'property.hasHazardousSubstances',
      label: i18n.areThereAny,
    }),
    ...yesNoExplainSectionsCreator({
      forBasePath: 'property.hasEnvironmentalLawViolations',
      label: i18n.areThereAnyPending,
    }),
    ...yesNoExplainSectionsCreator({
      forBasePath: 'property.hasImpairedPropertyValue',
      label: i18n.areThereAnyConditions,
    }),
    ...yesNoExplainSectionsCreator({
      forBasePath: 'property.hasUnpermittedWorks',
      label: i18n.areThereAnyUnpermitted,
    }),
    ...yesNoExplainSectionsCreator({
      forBasePath: 'property.hasAccessoryDwellingUnit',
      label: i18n.doesYourPropertyInclude,
    }),
    ...yesNoExplainSectionsCreator({
      forBasePath: 'property.isInRenovation',
      label: i18n.areYouInTheMiddle,
    }),
    ...yesNoExplainSectionsCreator({
      forBasePath: 'property.hasPendingLawsuits',
      label: i18n.areThereAnyOngoing,
    }),
  ];
};

export default propertyTemplate;
