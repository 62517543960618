import styled from 'styled-components';

export const QuizQuestionStyle = styled.div.attrs({ className: 'QuizQuestionStyle' })`
  form {
    fieldset,
    legend {
      border: none;
      border: 0;
      padding: 0;
      margin: 0;
      min-width: 0;
      legend {
        max-height: 0px;
        overflow: hidden;
      }
    }
    footer {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      flex-direction: row-reverse;
      .tertiary:only-child {
        margin-right: auto;
      }
    }
  }
`;
