import React, { useState } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import {
  DirectionAndPlacement,
  Header,
  Key,
  LinkButton,
  ModalStyles,
  eventHasKey,
} from '@pointdotcom/pds';
import BaseEstimateModel from 'models/BaseEstimateModel';
import OfferEstimateModel from 'models/OfferEstimateModel';
import i18n from './i18n';
import * as styles from './styles';

export const capDescriptionTitleText = i18n.howTheCapWorks;

interface PricingTableRowProps {
  estimate: BaseEstimateModel;
  year: number;
  isSelected: boolean;
  onClick: React.MouseEventHandler;
  tabIndex?: number | undefined;
  onKeyDown: React.KeyboardEventHandler;
}

const PricingTableRow = ({
  estimate,
  year,
  isSelected,
  onClick,
  tabIndex,
  onKeyDown,
}: PricingTableRowProps) => (
  <>
    <TransitionGroup component={null}>
      {isSelected && (
        <Transition timeout={{ enter: 0, exit: 320 }} in={isSelected}>
          {(status) =>
            [...new Array(11)].map((_, month) => {
              const protectionCapByMonth = estimate.getFormattedProtectionCapByMonths(
                (year - 1) * 12 + (month + 1)
              );
              return (
                <styles.PricingTableMonthRowStyle
                  lastChild={month === 10}
                  key={protectionCapByMonth}
                  animationStatus={status}
                >
                  <td>
                    <div>
                      {year - 1 > 0 ? `${year - 1} yr,` : ''} {`${month + 1} mo`}
                    </div>
                  </td>
                  <td>
                    <div>{protectionCapByMonth}</div>
                  </td>
                </styles.PricingTableMonthRowStyle>
              );
            })
          }
        </Transition>
      )}
    </TransitionGroup>
    <styles.PricingTableYearRow
      onClick={onClick}
      selected={isSelected}
      tabIndex={tabIndex}
      role="button"
      onKeyDown={onKeyDown}
    >
      <td>{isSelected ? `${year} ${year > 1 ? i18n.years : i18n.year}` : year}</td>
      <td>{estimate.getFormattedProtectionCapByMonths(year * 12)}</td>
    </styles.PricingTableYearRow>
  </>
);

interface PricingTableProps {
  estimate: BaseEstimateModel;
  numRowsVisible?: null | number;
}

export function PricingTable({ estimate, numRowsVisible }: PricingTableProps) {
  const [selectedYear, setSelectedYear] = useState<null | number>(null);

  const handleYearSelect = (newSelectedYear: number) => {
    setSelectedYear((currentSelectedYear) =>
      newSelectedYear === currentSelectedYear ? null : newSelectedYear
    );
  };

  const handleRowKeyDown = (rowYear: number) => (e: React.KeyboardEvent) => {
    if (eventHasKey(e as TSFixMe, [Key.Space, Key.Enter])) {
      e.preventDefault();
      handleYearSelect(rowYear);
    }
  };

  const tableRows = numRowsVisible || estimate.getTerm();

  return (
    <styles.PricingTableStyle>
      <thead>
        <tr>
          <th style={{ width: '38%' }}>
            <Header noMargin styleAlign={DirectionAndPlacement.Center}>
              {i18n.timePassed}
            </Header>
          </th>
          <th>
            <Header noMargin styleAlign={DirectionAndPlacement.Center}>
              {i18n.homeownerProtection}
            </Header>
          </th>
        </tr>
      </thead>
      <tbody>
        {[...new Array(tableRows)].map((_, year) => {
          const y1 = year + 1;
          return (
            <PricingTableRow
              key={y1}
              estimate={estimate}
              onClick={() => handleYearSelect(year)}
              year={y1}
              isSelected={year === selectedYear}
              tabIndex={y1}
              onKeyDown={handleRowKeyDown(year)}
            />
          );
        })}
      </tbody>
    </styles.PricingTableStyle>
  );
}

interface CapPriceTableProps {
  estimate: BaseEstimateModel | OfferEstimateModel;
  onModalClose: () => void;
  numRowsVisible?: null | number;
  verbose?: boolean;
}

export default function CapPriceTable({
  estimate,
  onModalClose,
  numRowsVisible = undefined,
  verbose = false,
}: CapPriceTableProps) {
  const { ModalSplashHeaderStyle } = ModalStyles;
  const text = verbose ? (
    <ModalSplashHeaderStyle styleAlign={DirectionAndPlacement.Left}>
      {i18n.limitsYourRepaymentAmount}
    </ModalSplashHeaderStyle>
  ) : (
    <ModalSplashHeaderStyle styleAlign={DirectionAndPlacement.Left}>
      {i18n.pointIsTheOnly}
    </ModalSplashHeaderStyle>
  );

  return (
    <styles.CapPriceTableStyle>
      {text}
      <styles.TipTextStyle>{i18n.tipSelect}</styles.TipTextStyle>
      <PricingTable estimate={estimate} numRowsVisible={numRowsVisible} />
      <LinkButton onClick={onModalClose}>{i18n.close}</LinkButton>
    </styles.CapPriceTableStyle>
  );
}
