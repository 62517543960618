import React, { Children } from 'react';
import { FormDescriptionType, FormLabel } from '../constants';
import * as styles from '../styles';

// TODO: these should be in PDS
interface LabeledSectionProps {
  children: React.ReactNode;
  cols?: number;
  label: FormLabel;
  description?: FormDescriptionType;
  inset?: boolean;
}

const LabeledSection = ({ children, cols = 1, description, label, inset }: LabeledSectionProps) => {
  const numChildren = Children.toArray(children).length;

  if (numChildren === 1) {
    return (
      <styles.LabeledContainerStyle description={description} inset={inset}>
        <h3>{label}</h3>
        {description && <styles.ParagraphBlockStyle>{description}</styles.ParagraphBlockStyle>}
        {children}
      </styles.LabeledContainerStyle>
    );
  }

  return (
    <styles.LabeledFieldStyle description={description}>
      <legend>{label}</legend>
      {description && <aside>{description}</aside>}
      <styles.FormRowStyle cols={cols}>{children}</styles.FormRowStyle>
    </styles.LabeledFieldStyle>
  );
};

export default LabeledSection;
