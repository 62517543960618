import React from 'react';
import { To, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { NavItem, NavProps, getNavItemProp } from 'components/MainHeader/nav';
import * as styles from './styles';

export type SubNavItem = Pick<NavItem, 'text' | 'href'>;

export type SubNavProps = {
  navItems: Array<SubNavItem>;
  navProps?: NavProps;
};

const SubNav = ({ navItems, navProps = {} }: SubNavProps) => {
  const location = useLocation();

  const getHref = getNavItemProp('href', navProps);
  const getText = getNavItemProp('text', navProps);
  const separator = '|';
  return (
    <styles.SubNavStyle>
      {navItems.map((item, i) => {
        const href = getHref(item);
        const text = getText(item);
        const current = location.pathname === href;
        let content = (
          <Link key={text} to={href as To}>
            {text}
          </Link>
        );

        if (current) {
          content = <span>{text}</span>;
        }
        return (
          <React.Fragment key={text}>
            {content}
            {i! < navItems.length - 1 ? separator : null}
          </React.Fragment>
        );
      })}
    </styles.SubNavStyle>
  );
};

export default SubNav;
