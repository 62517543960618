import styled, { keyframes } from 'styled-components';
import { ringsCSS } from '../RingsGraphic/styles';
import { TwinkleGraphicStyle } from '../TwinkleGraphic/styles';
import shineImage from '../images/shine.png';
import coinImage from './images/coin.png';
import piggyImage from './images/piggy.png';

export const bob = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
`;

const twinkle = keyframes`
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.4;
    
  }
`;

export const PiggyBankGraphicStyle = styled.div.attrs({ className: 'PiggyBankGraphicStyle' })`
  --piggyBankSize: 40%;
  background-image: url('${piggyImage}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom -1px;
  position: absolute;
  bottom: 0;
  right: 33%;
  width: var(--piggyBankSize);
  padding-bottom: var(--piggyBankSize);
  &::before {
    content: '';
    ${ringsCSS};
    width: 60%;
    left: -15%;
    background-position: center -550%;
  }
`;

export const CoinGraphicStyle = styled.div.attrs({ className: 'CoinGraphicStyle' })`
  position: absolute;
  width: 43.3%;
  height: 33%;
  left: 23.5%;
  bottom: 73%;

  clip-path: polygon(0% 30%, 62% 100%, 100% 100%, 100% 0, 0 0);
  &:after {
    content: '';
    background: ${({ theme }) => theme.Color.Yorange};
    width: 50%;
    height: 50%;
    position: absolute;
    display: block;
    border-radius: 50%;
    border-radius: 50%;
    bottom: -38%;
    left: 39%;
    filter: blur(7px);
    mix-blend-mode: multiply;
  }
  &:before {
    --coinSize: 50%;
    content: '';
    background-image: url('${coinImage}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: var(--coinSize);
    padding-bottom: var(--coinSize);
    left: 61%;
    margin-left: calc(var(--coinSize) * -0.3);
    bottom: -50%;

    animation: ${bob} 3s infinite ease-in-out;
  }
`;

export const CoinShineStyle = styled.div.attrs({ className: 'CoinShineStyle' })`
  --coinShineSize: 42%;
  position: absolute;
  width: var(--coinShineSize);
  padding-bottom: var(--coinShineSize);
  z-index: -1;
  background-image: url('${shineImage}');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  left: 33.2%;
  bottom: 47%;
  animation:
    ${bob} 3s infinite ease-in-out,
    ${twinkle} 2s infinite ease-in-out;
  ${TwinkleGraphicStyle} {
    &:first-child {
      --twinkleSize: 8px;
      bottom: 80%;
      left: 70%;
    }
    &:nth-child(2) {
      --twinkleSize: 5px;
      top: 25%;
      left: 30%;
    }
    &:last-child {
      --twinkleSize: 10px;
      bottom: 85%;
      left: 40%;
    }
  }
`;
