import styled, { css, keyframes } from 'styled-components';
import { HeaderStyles, IconStyles, SplashTextStyles, mixins } from '@pointdotcom/pds';
import * as appStyles from 'styles/';

const pulseAnimation = keyframes`
  0% { transform: scale(0); opacity: 0; }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); opacity: 1; }
`;

const SectionHeaderCSS = css`
  margin-bottom: 4em;
`;

export const HowItWorks3ImgsSectionStyle = styled.div.attrs({
  className: 'HowItWorks3ImgsSectionStyle',
})`
  ${appStyles.PaddedSectionCSS}
  background: ${(props) => props.theme.Color.Gray0};
  ${HeaderStyles.HeaderStyle} {
    ${SectionHeaderCSS}
  }
  ul {
    display: flex;
    flex-flow: row nowrap;
    gap: 2rem;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      background: white;
      padding: 20px;
      flex: 1;
      padding-bottom: 30%;
      position: relative;
      > div {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 60%;
        height: 60%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-flow: column nowrap;
        transform: translate(-50%, -50%);
        ${IconStyles.IconStyle} {
          margin-bottom: 17%;
          opacity: 0;
          transform: scale(0);
        }
      }
    }
  }
  &.entered {
    ul li {
      ${IconStyles.IconStyle} {
        animation: ${pulseAnimation} 1.2s ease-in-out 0s 1;
        animation-fill-mode: both;
      }
      &:nth-child(2) {
        ${IconStyles.IconStyle} {
          animation-delay: 0.3s;
        }
      }
      &:last-child {
        ${IconStyles.IconStyle} {
          animation-delay: 0.5s;
        }
      }
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    ${HeaderStyles.HeaderStyle} {
      margin-bottom: 0;
    }
    ul {
      flex-flow: column nowrap;
      gap: 0;
      li {
        background: none;
        /* border-bottom: 1px solid ${(props) => props.theme.line.color.default}; */
        padding-bottom: 25%;
        &:after {
          content: '';
          display: block;
          width: 2px;
          height: 33%;
          background: ${(props) => props.theme.line.color.default};
          position: absolute;
          left: calc(${(props) => props.theme.container.gutter.default} + 55px);
          bottom: -17%;
        }
        &:last-child {
          border: none;
          &:after {
            display: none;
          }
        }
        > div {
          width: 100%;
          height: 100%;
          flex-flow: row nowrap;
          justify-content: flex-start;
          background: transparent;
          align-items: center;
          padding-left: ${(props) => props.theme.container.gutter.default};
          padding-right: ${(props) => props.theme.container.gutter.default};
          ${IconStyles.IconStyle} {
            width: 110px;
            margin-bottom: 0;
            margin-right: 5%;
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    ul {
      li {
        padding-bottom: 60%;
        position: relative;
        border-bottom: none;
        > div {
          flex-flow: column nowrap;
          justify-content: center;
          ${IconStyles.IconStyle} {
            margin: 0 0 5%;
          }
        }
        &:after {
          content: '';
          left: 50%;
          margin-left: -1px;
        }
      }
    }
    ${HeaderStyles.HeaderStyle} {
      > * {
        text-align: center;
      }
    }
    ${SplashTextStyles.SplashCopySerifStyle} {
      text-align: center;
    }
  }
  @media (max-width: 840px) {
    ${SplashTextStyles.SplashCopySerifStyle} {
      font-size: ${(props) => mixins.getRemFromPx(20, props.theme.globals.baseFontSize.default)};
    }
  }
`;
