import React, { useState } from 'react';
import { LinkButton } from '@pointdotcom/pds';
import ErrorPage, { ErrorType } from 'containers/ErrorPage';
import { getPathFromPage, pages } from 'containers/helpers';
import { Redirect } from 'containers/routerHelpers';
import bugsnagClient from 'lib/bugsnagClient';
import { useMagicLinkMutation } from 'services/api/underwriteApi';
import { UnderwriteSuccessResponse } from 'services/apiTypes/underwriteTypes';
import i18n from './i18n';

const ExpiredLoginLinkPage = ({ urlKey }: { urlKey: string }) => {
  const [status, setStatus] = useState({ loading: false, sent: false, error: false });
  const [sendMagicLink] = useMagicLinkMutation();
  const handleClick = async () => {
    let response: UnderwriteSuccessResponse;
    setStatus({ loading: true, sent: false, error: false });
    try {
      response = await sendMagicLink(urlKey).unwrap();
      setStatus({ loading: false, sent: response.success, error: !response.success });
    } catch (error) {
      setStatus({ loading: false, sent: false, error: true });
      bugsnagClient.notify(error as Error, (event) => {
        event.addMetadata('UW Response', response);
      });
    }
  };

  let content: React.ReactNode = <LinkButton onClick={handleClick}>{i18n.sendMeANew}</LinkButton>;
  if (status.sent) {
    content = i18n.thanksWeveSent;
  } else if (status.loading) {
    content = i18n.sending;
  } else if (status.error) {
    content = <Redirect to={getPathFromPage(pages.DASHBOARD) ?? '/'} />;
  }

  return (
    <ErrorPage errorType={ErrorType.LinkExpired} headerText={i18n.sorryTheLink}>
      {content}
    </ErrorPage>
  );
};

export default ExpiredLoginLinkPage;
