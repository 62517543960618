import styled, { css } from 'styled-components';
import { mixins } from '@pointdotcom/pds';

export const styleTypes = {
  OVERDARK: 'OVERDARK',
  OVERLIGHT: 'OVERLIGHT',
};

const getStylePropFromMap = (theme, styleType, prop) => {
  const styleMap = {
    [styleTypes.OVERDARK]: {
      inactiveColor: mixins.rgba(theme.Color.PointBlack, 0.15),
      activeColor: theme.Color.White,
      blendMode: 'difference',
    },
    [styleTypes.OVERLIGHT]: {
      inactiveColor: theme.Color.Gray1,
      activeColor: theme.Color.PointBlue,
      blendMode: 'normal',
    },
  };

  return styleMap[styleType][prop];
};

const indicatorSize = 10;
const ieAndEdgeOnlyCSS = css`
  &::before {
    display: ${({ theme, styleType }) =>
      getStylePropFromMap(theme, styleType, 'blendMode') === 'normal' ? 'none' : 'block'};
    ${({ theme, current }) => {
      const color = mixins.rgba(theme.Color.PointBlack, 0.15);
      return color && !current
        ? `background-color: ${mixins.lightenDarkenColor(color, -0.13)}`
        : '';
    }};
  }
`;

const backgroundHoverCSS = css`
  cursor: pointer;
  background: ${({ theme, styleType, current, styleActiveColor, styleInactiveColor }) =>
    current
      ? styleActiveColor || getStylePropFromMap(theme, styleType, 'activeColor')
      : styleInactiveColor ||
        mixins.rgba(getStylePropFromMap(theme, styleType, 'inactiveColor'), 0.5)};
`;

export const DotIndicatorItemCSS = css`
  ${mixins.appearance('none')}
  width: ${indicatorSize}px;
  height: ${indicatorSize}px;
  border-radius: ${indicatorSize / 2}px;
  margin-right: ${indicatorSize + indicatorSize / 3}px;
  position: relative;

  &::before {
    content: '';
    display: ${({ theme, styleType }) =>
      getStylePropFromMap(theme, styleType, 'blendMode') === 'normal' ? 'none' : 'block'};
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    mix-blend-mode: ${({ theme, styleType }) => getStylePropFromMap(theme, styleType, 'blendMode')};
    border-radius: ${indicatorSize / 2}px;
    background-color: ${({ theme }) => mixins.rgba(theme.Color.PointBlack, 0.15)};
  }

  ${mixins.ieAndEdgeOnly(ieAndEdgeOnlyCSS)}

  background: ${({ styleInactiveColor, styleActiveColor, styleType, current, theme }) => {
    let color = styleInactiveColor || getStylePropFromMap(theme, styleType, 'inactiveColor');
    if (current) {
      color = styleActiveColor || getStylePropFromMap(theme, styleType, 'activeColor');
    }
    return color;
  }};
  ${({ readOnly }) =>
    readOnly
      ? null
      : css`
        ${mixins.desktopHoverCondition()} {
            &:hover {
              ${backgroundHoverCSS}
            }
          }
        }
      `}
`;

export const DotIndicatorItemStyle = styled.button.attrs({ className: 'DotIndicatorItemStyle' })`
  ${DotIndicatorItemCSS}
`;

export const DotIndicatorItemReadOnlyStyle = styled.div.attrs({
  className: 'DotIndicatorItemStyle',
})`
  ${DotIndicatorItemCSS}
`;

export const DotIndicatorStyle = styled.nav.attrs({ className: 'DotIndicatorStyle' })`
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  ${DotIndicatorItemStyle} {
    &:last-child {
      margin-right: 0;
    }
  }
  @media only print {
    display: none;
  }
`;
