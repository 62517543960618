import styled, { keyframes } from 'styled-components';
import billPileImage from './images/billpile.png';
import noImage from './images/no.png';

export const pulse = keyframes`
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.05);
  }
`;

export const BillPileGraphicStyle = styled.div.attrs({ className: 'BillPileGraphicStyle' })`
  --billPileGraphicSize: 42%;
  position: absolute;
  width: var(--billPileGraphicSize);
  padding-bottom: var(--billPileGraphicSize);
  bottom: 0;
  left: 11%;
  background-image: url('${billPileImage}');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('${noImage}');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    animation: ${pulse} 3s infinite ease-in-out;
    top: 27%;
    left: -10%;
  }
`;
