import styled from 'styled-components';
import { mixins } from '@pointdotcom/pds';
import { ShieldGraphicStyle } from '../ShieldGraphic/styles';

export const ANIM_SPEED_MS = 300;

export const GraphBarStyle = styled.div.attrs({ className: 'GraphBarStyle' })<{ perc: number }>`
  --animSpeed: ${ANIM_SPEED_MS}ms;
  display: flex;
  align-items: flex-end;
  background: ${({ theme }) => theme.Color.Gray1};
  transition: all var(--animSpeed) cubic-bezier(0.4, 0, 0.2, 1.5);
  overflow-x: clip;
  background-image: repeating-linear-gradient(
    -45deg,
    ${({ theme }) => mixins.rgba(theme.Color.PointBlack, 0.04)},
    ${({ theme }) => mixins.rgba(theme.Color.PointBlack, 0.04)} 3px,
    transparent 3px,
    transparent 7px
  );

  &:after {
    content: '$';
    display: none;
    position: absolute;
    font-size: 3rem;
    font-family: ${(props) => props.theme.fonts.serif.name};
    color: white;
    position: absolute;
    line-height: 0.9em;
    opacity: 0.5;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: var(--graphicsHighlightColor);
    transform: scaleY(${({ perc }) => perc});
    transform-origin: bottom;
    transition: inherit;
  }
`;

export const GraphBarContainerStyle = styled.div.attrs({ className: 'GraphBarContainerStyle' })`
  display: flex;
  flex-flow: row nowrap;
  gap: 5%;
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden;
  ${GraphBarStyle} {
    flex: 1;
    &:nth-child(1) {
      transition-delay: 0ms;
    }
    &:nth-child(2) {
      transition-delay: ${ANIM_SPEED_MS * 0.5}ms;
    }
    &:nth-child(3) {
      transition-delay: calc(${ANIM_SPEED_MS * 0.5}ms * 2);
    }
    &:nth-child(4) {
      transition-delay: calc(${ANIM_SPEED_MS * 0.5}ms * 3);
    }
    &:nth-child(5) {
      transition-delay: calc(${ANIM_SPEED_MS * 0.5}ms * 4);
    }
    &:nth-child(6) {
      transition-delay: calc(${ANIM_SPEED_MS * 0.5}ms * 5);
    }
    &:nth-child(7) {
      transition-delay: calc(${ANIM_SPEED_MS * 0.5}ms * 6);
    }
    &:nth-child(8) {
      transition-delay: calc(${ANIM_SPEED_MS * 0.5}ms * 7);
    }
  }
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const CapGraphGraphicStyle = styled.div.attrs({ className: 'CapGraphGraphicStyle' })`
  --capGraphicSize: 30%;
  position: absolute;
  right: 10%;
  bottom: 0;
  width: var(--capGraphicSize);
  padding-bottom: var(
    --capGraphicSize
  ); /* This makes the height equal to the width, creating a square */
  ${ShieldGraphicStyle} {
    left: 72%;
    top: -30%;
    bottom: unset;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    right: 8%;
  }
`;

export const CapGraphGraphicThreeBarStyle = styled(CapGraphGraphicStyle).attrs({
  className: 'CapGraphGraphicThreeBarStyle',
})`
  right: unset;
  left: 15%;
  ${ShieldGraphicStyle} {
    --shieldSize: 60%;
    --line-size: 180%;
    left: -30%;
    top: -10%;
    bottom: unset;
  }
  ${GraphBarStyle} {
    --animSpeed: 800ms;
    position: relative;
    &:after {
      font-size: 2rem;
      display: block;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10%;
      opacity: 0.6;
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${GraphBarStyle} {
      &:after {
        font-size: 1.5rem;
      }
    }
  }
`;

export const CapGraphGraphicEightBarStyle = styled(CapGraphGraphicStyle).attrs({
  className: 'CapGraphGraphicEightBarStyle',
})`
  width: 100%;
  height: 100%;
  bottom: 0;
  right: unset;
  left: 0;
  overflow: hidden;
  ${ShieldGraphicStyle} {
    --shieldSize: 55%;
    --line-size: 95%;
    --dot-size: 3px;
    left: 0;
    top: -15%;
  }
  ${GraphBarContainerStyle} {
    gap: 0.3em;
    width: 30%;
    left: 47%;
    height: 45%;
    bottom: 0;
  }
`;
