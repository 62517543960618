export default {
  thankYou: 'Thank you for your feedback!',
  whatsNext: 'What’s next',
  youCanCall:
    'You can call us at %phone% anytime if you change your mind. This offer is valid until %expDate%. Best of luck!',
  pleaseLetUs: 'Please let us know why you are declining your offer:',
  whyAre: 'Why are you declining this offer?',
  wereSorry: 'We’re sorry this offer wasn’t right for you. ',
  yourDecision: 'Your decision',
  ifYouWould: 'If you’d like to proceed with this offer, type your name and click Proceed.',
  ifYouWouldPoint: 'If you’d like to proceed with Point, type your name and click Proceed.',
  decline: 'Decline',
  proceed: 'Proceed',
  byProceeding:
    'By proceeding you are acknowledging that you have received the following initial disclosure via email: Borrower Information Document. Please contact your Account Manager if you have any questions.',

  reviewYourOffer: 'Review your offer',
  youveChosen: 'You’ve chosen to accept your %offerAmount% offer. Here’s what to expect next:',
  wereSorryCallUs:
    'We’re sorry the offer wasn’t right for you. You can call us at %phone% anytime if you change your mind. This offer is valid until %validUntil%. Best of luck!',

  whatsNext1:
    'If your home hasn’t been appraised yet, a third-party appraiser will contact you to schedule a visit to establish its value.',
  whatsNext2:
    'After the appraisal is complete, we’ll finalize our investment terms and schedule a day to complete the deal.',

  whatsNextClosing1:
    'Have your closing call. During the call, we’ll discuss the documents you’ll be signing, and go over any remaining questions you may have. We’ve sent you an email to schedule this.',
  whatsNextClosing2:
    'Have your closing appointment. We’ll send a notary to a location of your choosing so you can sign all the necessary documents.',
  whatsNextClosing3: 'After a short, mandatory waiting period, you’ll receive your funds.',

  whatsNextPrelim1: 'We will review your information.',
  whatsNextPrelim2:
    'If your home hasn’t been appraised yet, a third-party appraiser will contact you to schedule a visit to establish its value.',
  whatsNextPrelim3: 'We’ll finalize our investment terms and schedule a day to complete the deal.',

  whatsNext1Appraised: 'We’ll work with you to complete any outstanding items needed for closing.',
  whatsNext2Appraised: 'We’ll review the legal documents with you and go over any questions.',
  whatsNext3Appraised:
    'The escrow company we work with will schedule an appointment with you to sign the documents.',
  whatsNext4Appraised: 'You’ll receive your money after a brief rescission period.',
};
