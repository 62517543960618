import React from 'react';
import { Checkbox, HelpText, Style } from '@pointdotcom/pds';
import { reactNodeToString } from 'helpers';
import { FieldProps } from '../constants';
import i18n from '../i18n';
import * as styles from '../styles';

const YesNoField = ({
  value: valueFromProps,
  onChange,
  styleSize,
  noMargin,
  error,
  onFocus,
  field,
  helptext,
}: Partial<Omit<FieldProps, 'value'>> & {
  noMargin: boolean;
  value?: boolean;
}) => {
  const formattedValue = valueFromProps !== undefined ? String(valueFromProps) : undefined;
  const labelAsString = field?.label ? reactNodeToString(field.label) : '';
  const name = labelAsString.replace(/\s+/g, '');
  return (
    <styles.YesNoFieldStyle styleSize={styleSize}>
      <Checkbox
        aria-label={`${labelAsString} - ${i18n.yes}`}
        type="radio"
        value="true"
        checkVal={formattedValue}
        label={i18n.yes}
        onChange={onChange}
        onFocus={onFocus}
        styleSize={styleSize}
        noMargin={noMargin}
        error={error}
        name={name}
      />
      <Checkbox
        aria-label={`${labelAsString} - ${i18n.no}`}
        type="radio"
        value="false"
        checkVal={formattedValue}
        label={i18n.no}
        onChange={onChange}
        onFocus={onFocus}
        styleSize={styleSize}
        noMargin={noMargin}
        error={error}
        name={name}
      />
      {helptext && <HelpText styleType={error ? Style.Error : Style.Default}>{helptext}</HelpText>}
    </styles.YesNoFieldStyle>
  );
};

export default YesNoField;
