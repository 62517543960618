import styled from 'styled-components';
import { mixins } from '@pointdotcom/pds';

export const ZillowPartnerBrandingStyle = styled.div.attrs({
  className: 'ZillowPartnerBrandingStyle',
})`
  display: inline-block;
  font-size: ${(props) => mixins.getRemFromPx(14, props.theme.globals.baseFontSize.default)};
  color: ${(props) => props.theme.Color.Gray4};
  a {
    white-space: normal;
  }
  p {
    margin: 0;
  }
`;

export const ImageLinkStyle = styled.a.attrs({ className: 'ImageLinkStyle' })`
  display: block;
  width: 100px;
  height: 20px;
  margin-bottom: 20px;
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    width: 80px;
    height: 10px;
  }
`;

export const AddressLinkStyle = styled.a.attrs({ className: 'AddressLinkStyle' })`
  display: inline-block;
  margin-bottom: 10px;
  text-decoration: none;
`;
