import React from 'react';
import * as styles from './styles';

export default function ChatIcon() {
  return (
    <styles.ChatIconStyle viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 0C6.27049 0 0 6.27049 0 14C0 21.7295 6.27049 28 14 28H28V14C28 6.27049 21.7377 0 14 0ZM8.56557 15.8525C7.54098 15.8525 6.71311 15.0246 6.71311 14C6.71311 12.9754 7.54098 12.1475 8.56557 12.1475C9.59016 12.1475 10.418 12.9754 10.418 14C10.418 15.0246 9.59016 15.8525 8.56557 15.8525ZM13.8852 15.8525C12.8607 15.8525 12.0328 15.0246 12.0328 14C12.0328 12.9754 12.8607 12.1475 13.8852 12.1475C14.9098 12.1475 15.7377 12.9754 15.7377 14C15.7377 15.0246 14.9098 15.8525 13.8852 15.8525ZM19.2049 15.8525C18.1803 15.8525 17.3525 15.0246 17.3525 14C17.3525 12.9754 18.1803 12.1475 19.2049 12.1475C20.2295 12.1475 21.0574 12.9754 21.0574 14C21.0574 15.0246 20.2295 15.8525 19.2049 15.8525Z" />
    </styles.ChatIconStyle>
  );
}
