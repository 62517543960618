export enum PolicyType {
  Contact = 'contact',
  CreditReport = 'creditReport',
  Share = 'share',
  ECES = 'eces',
  ShareLender = 'shareLender',
  Prequalification = 'prequalification',
}

export const policyType = Object.values(PolicyType);

export interface PoliciesProps {
  policies?: Array<PolicyType>;
}

export const defaultPolicies = policyType;
