export default {
  congratulations: 'Congratulations! You’ve accepted your final offer of %offerAmount%',
  yesHelpPay: 'Yes! Help pay down my debt',
  noIDont: 'No, I don’t need assistance',
  wouldYouBe: 'Would you be interested in assistance paying off your debt at closing?',
  yourSteps: 'Your steps to funding',

  reviewYourOffer: 'Review your offer',
  thanksForLetting: 'Thanks for letting us know!',
  notYetAvailable:
    'This service is not yet available but thank you for your feedback. Here’s what to expect next:',
  thisTaskIs: 'This task is complete. Here’s what to expect next:',

  acceptYourOffer: 'Accept your %offerAmount% offer.',
  wellWorkWith: 'We’ll work with you to complete any outstanding items needed for closing.',
  wellReviewLegal: 'We’ll review the legal documents with you and go over any questions.',
  theEscrowCompany:
    'The escrow company we work with will schedule an appointment with you to sign the documents.',
  youllReceiveMoney: 'You’ll receive your money after a brief rescission period.',
};
