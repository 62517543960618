import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import { z } from 'zod';

extendZodWithOpenApi(z);

export const contactSchema = z
  .object({
    email: z.string(),
    firstName: z.string(),
    lastName: z.string().optional(),
    phone: z.string().optional(),
  })
  .openapi('Contact');
export type ContactDetails = z.infer<typeof contactSchema>;

export const contractExitDetailsSchema = z
  .object({
    contact: contactSchema,
    optionId: z.string(),
    dateFunded: z.string(),
    homeValue: z.number(),
    payoffEstimate: z.number(),
    payoffDate: z.string(),
    quarterlyPayoffDate: z.string(),
    capped: z.boolean(),
    investmentPayment: z.number(),
    optionPercentage: z.number(),
    originalAgreedValue: z.number(),
    appreciation: z.number(),
    pointShare: z.number(),
    capAmount: z.number(),
    capPercentage: z.number(),
    uncappedAmount: z.number(),
    contractTerm: z.number(),
    contractEndDate: z.string(),
    subservicerId: z.string(),
  })
  .openapi('ContractExitDetails');
export type ContractExitDetails = z.infer<typeof contractExitDetailsSchema>;

export const payoffProjectionSchema = z
  .object({
    payoffDate: z.string(),
    payoffEstimate: z.number(),
    capped: z.boolean(),
  })
  .openapi('PayoffProjection');
export type PayoffProjection = z.infer<typeof payoffProjectionSchema>;

export const contractProjectionsSchema = z
  .object({
    optionId: z.string(),
    projectedAppreciationRate: z.number(),
    payoffProjections: z.array(payoffProjectionSchema),
  })
  .openapi('ContractProjections');
export type ContractProjections = z.infer<typeof contractProjectionsSchema>;

export enum ContractErrorCode {
  OutOfRange = 'OutOfRange',
  InvalidFormat = 'InvalidFormat',
  Unknown = 'Unknown',
}

export const contractErrorsSchema = z
  .object({
    homeValue: z.nativeEnum(ContractErrorCode).optional(),
    payoffDate: z.nativeEnum(ContractErrorCode).optional(),
    projectedAppreciationRate: z.nativeEnum(ContractErrorCode).optional(),
    unknown: z.nativeEnum(ContractErrorCode).optional(),
  })
  .openapi('ContractErrors');
export type ContractErrors = z.infer<typeof contractErrorsSchema>;
