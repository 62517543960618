/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
export default class DataModel {
  /**
   *
   * @param given {{}} The properties for this object.
   * @param options
   * @param className The name of this class.
   */
  constructor(given, options, className) {
    this.className = className;

    this.applyProps(given, options);
  }

  /**
   * Applies the properties supplied in `given` to this object as constrained by the
   * provided options.
   *
   * @param given
   * @param options
   */
  applyProps(given, options) {
    const { required, optional, ignoreForeignError } = options;

    if (required) {
      required.forEach((item) => {
        if (!given || typeof given[item] === 'undefined') {
          const e = new Error(
            `'${item}' is a required param in model: ${
              this.className
            }. Required fields are: [${required.join(', ')}]`
          );
          e.name = 'Model.Data.UnmetRequirementsError';
          throw e;
        }
      });
    }

    const all = (optional || []).concat(required || []);
    for (const item in given) {
      if (!ignoreForeignError && !all.includes(item)) {
        const e = new Error(
          `'${item}' is an invalid param in model: ${this.className} ${JSON.stringify(options)}`
        );
        e.name = 'Model.Data.InvalidParamError';
        throw e;
      }
      this[item] = given[item];
    }
  }

  /**
   * Adapts an array of object literal values into an array containing instances of
   * the supplied model.
   *
   * @protected
   * @param {Object[]} values An array of objects
   * @param {typeof DataModel} Model The class to be used to reify instances. The
   *    constructor must accept a single element, the object literal containing the
   *    properties to be instantiated.
   * @param {string} [name] The name of the property being assigned, used for
   *    error reporting.
   * @returns {*} An array of instances of the supplied data model.
   */
  adaptArray(values, Model, name) {
    if (!values) {
      return [];
    }

    if (Array.isArray(values)) {
      return values.map((item) => new Model(item));
    }

    const e = new Error(`Param "${name}" should be an array in class ${this.className}`);
    e.name = 'Model.Data.InvalidTypeError.NonArray';
    throw e;
  }

  toJSON() {
    return Object.getOwnPropertyNames(this).reduce((jsonData, prop) => {
      const jsonDataNew = { ...jsonData };
      if (prop !== 'className') {
        jsonDataNew[prop] = this[prop];
      }

      return jsonDataNew;
    }, {});
  }
}
