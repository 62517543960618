export default {
  close: 'Close',
  howTheCapWorks: 'How the cap works',
  pointIsTheOnly:
    'Point is the only program of its kind that limits its upside if your home appreciates a lot. In such a case, your cost will be the Homeowner Protection Cap amount instead of the appreciation-based amount. Note that this cost is in addition to the original amount you receive.',
  limitsYourRepaymentAmount:
    'The Homeowner Protection Cap limits your repayment amount. It is most often applicable during times of high appreciation and the early years of your HEI. It ensures that even if your home’s value significantly increases, you won’t have to pay more than this predetermined maximum amount. The Capped Amount increases monthly based on how much money you get from Point and an annual rate, compounded monthly.',
  tipSelect: 'Tip: Select a year to see your cap by month',
  timePassed: 'Time passed from term start (years)',
  homeownerProtection: 'Homeowner Protection Cap',
  year: 'year',
  years: 'years',
};
