import React from 'react';
import { Input, InputFocusEvent, InputMaskType } from '@pointdotcom/pds';
import { useDispatch, useSelector } from 'store/hooks';
import { getSSNFocused, setSSNFocused } from 'store/productApplication';
import { FieldProps } from '../constants';

const SSNField = ({ onChange, value, styleSize, helptext, error, onFocus, field }: FieldProps) => {
  const valString = value ? String(value) : '';
  const focused = useSelector(getSSNFocused);
  const dispatch = useDispatch();
  const VALID_FORMATTED_SSN_LENGTH = 11;

  // We dispatch to the store so that both SSN and SSN confirm fields will
  // show when focused (important so user can see if+why they dont match)
  const handleFocus: InputFocusEvent = (e, props) => {
    dispatch(setSSNFocused(true));
    onFocus(e, props);
  };
  const handleBlur = () => {
    dispatch(setSSNFocused(false));
  };

  return (
    <Input
      aria-label={String(field?.label || '')}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={onChange}
      value={valString}
      overlayValue={
        !focused && valString.length === VALID_FORMATTED_SSN_LENGTH
          ? valString.replace(/\d(?!\d{0,1}$)/g, '•')
          : ''
      }
      mask={InputMaskType.SSN}
      styleSize={styleSize}
      helptext={helptext}
      error={error}
    />
  );
};

export default SSNField;
