import * as React from 'react';
import { useCallback, useState } from 'react';
import { Container } from '@pointdotcom/pds';
import { FileItem, FileItemKey } from 'containers/hooks/useTaskFiles/useTaskFiles';
import FilePreviewModal from 'containers/tasks/GenericFollowupPageContent/FilePreviewModal';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import UploadedFileItemPreview from './UploadedFileItemPreview';
import UploadedFileItemRow from './UploadedFileItemRow';
import * as styles from './styles';

interface UploadedFilesProps {
  taskId: string;
  fileItems: FileItem[];
  onDeleteFile: (fileKey: FileItemKey) => void;
}

export default function UploadedFiles({ taskId, fileItems, onDeleteFile }: UploadedFilesProps) {
  const [filePreviewKey, setFilePreviewKey] = useState<null | FileItemKey>(null);
  const filePreview = fileItems.find((item) => item.key === filePreviewKey);

  // TODO: enable clicking on thumbnails
  const showPreviews = useFeatureFlag(FeatureFlag.ShowFileUploadPreviews) ?? false;
  const canClickOnThumbnails = showPreviews;

  const handleFilePreviewModalClose = useCallback(() => {
    setFilePreviewKey(null);
  }, []);

  if (fileItems.length === 0) {
    return null;
  }

  return (
    <>
      {showPreviews ? (
        <styles.UploadedFilePreviewsScrollerStyle>
          <Container>
            <styles.UploadedFilePreviewsContainerStyle>
              {fileItems.map(({ key, taskFile, upload, isDeleting, deleteFileError }) => (
                <UploadedFileItemPreview
                  key={key}
                  taskFile={taskFile}
                  upload={upload}
                  onDeleteFile={() => onDeleteFile(key)}
                  onFileClick={
                    canClickOnThumbnails && taskFile != null && !isDeleting
                      ? () => setFilePreviewKey(key)
                      : undefined
                  }
                  isDeleting={isDeleting}
                  deleteFileError={deleteFileError}
                />
              ))}
            </styles.UploadedFilePreviewsContainerStyle>
          </Container>
        </styles.UploadedFilePreviewsScrollerStyle>
      ) : (
        <styles.UploadedFileRowsContainerStyle>
          {fileItems.map(({ key, taskFile, upload, isDeleting, deleteFileError }) => (
            <UploadedFileItemRow
              key={key}
              taskFile={taskFile}
              upload={upload}
              onDeleteFile={() => onDeleteFile(key)}
              onFileClick={
                canClickOnThumbnails && taskFile != null && !isDeleting
                  ? () => setFilePreviewKey(key)
                  : undefined
              }
              isDeleting={isDeleting}
              deleteFileError={deleteFileError}
            />
          ))}
        </styles.UploadedFileRowsContainerStyle>
      )}
      <FilePreviewModal
        taskId={taskId}
        taskFile={filePreview?.taskFile}
        isOpen={!!filePreview}
        onModalClose={handleFilePreviewModalClose}
        isDeleting={filePreview?.isDeleting}
        onDeleteFile={() => {
          filePreviewKey && onDeleteFile(filePreviewKey);
        }}
        deleteFileError={filePreview?.deleteFileError}
      />
    </>
  );
}
