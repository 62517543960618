import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import throttle from 'lodash.throttle';
import { Checkbox, Container, Input, TextArea } from '@pointdotcom/pds';
import { reactNodeToString } from 'helpers';
import { yesNoExplainFieldSchema } from 'services/apiTypes/homeownerTypes';
import {
  FormLabel,
  FormStructure,
  FormStructureField,
  FormStructureSection,
  GetBaseFormFieldPropsFunc,
  GetFieldValueByPathFunc,
  ShowFunc,
  ValidationFunc,
  ValidationProps,
} from '../constants';
import i18n from '../i18n';
import * as styles from '../styles';
import LabeledSection from './LabeledSection';
import YesNoField from './YesNoField';
import { baseFieldValidation, validateConditionalFields } from './validationUtils';

// Responsibilities of this page should be all things having to do with the form schema.
// If it has to do with the UI produced by the FormSchema, that should go in ApplicationForm

// Add an explainer field to yesno questions
export const yesNoExplainSectionsCreator = ({
  parentPath,
  forBasePath,
  label,
  show = () => true,
  validation,
}: {
  parentPath?: string;
  forBasePath: string;
  label: FormLabel;
  show?: ShowFunc;
  validation?: ValidationFunc;
}): Array<FormStructureSection> => {
  return [
    {
      key: `${forBasePath}Answer`,
      cols: 2,
      show,
      fields: [
        {
          label,
          path: `${forBasePath}.answer`,
          Component: YesNoField,
          relatedPaths: [`${forBasePath}.explanation`],
          parentPath: parentPath || forBasePath,
          validation: validation || validateConditionalFields(yesNoExplainFieldSchema),
        },
      ],
    },
    {
      key: `${forBasePath}Explanation`,
      cols: 2,
      inset: true,
      show: ({ getFieldValueByPath }) => {
        // Only display when the yes/no parent is showing and the answer is yes
        return (
          show({ getFieldValueByPath }) && Boolean(getFieldValueByPath(`${forBasePath}.answer`))
        );
      },
      fields: [
        {
          path: `${forBasePath}.explanation`,
          Component: TextArea,
          label: i18n.pleaseExplain,
          props: {
            noMargin: true,
            'aria-label': `${label} ${i18n.pleaseExplain}`,
          },
          relatedPaths: [`${forBasePath}.answer`],
          parentPath: parentPath || forBasePath,
          validation: validation || validateConditionalFields(yesNoExplainFieldSchema),
        },
      ],
    },
  ];
};

// extract all the fields from a template
export const getFieldsFromTemplate = (template: FormStructure, path?: string) => {
  const fields: Array<FormStructureField> = [];
  template.forEach((formSection) => {
    if (path) {
      fields.push(
        ...(formSection?.fields || []).filter((field) => {
          if (field.path === path || field.path.startsWith(`${path}.`)) {
            return true;
          }
          return false;
        })
      );
    } else {
      fields.push(...(formSection.fields ? formSection.fields : []));
    }
  });
  return fields;
};

export const getPathIsChild = ({
  checkPaths = [],
  path = '',
}: {
  checkPaths?: Array<string>;
  path?: string;
}): boolean => {
  return (checkPaths || []).some((checkPath) => checkPath !== path && path.includes(checkPath));
};

export const getInvalidFormTemplate = ({
  template: templateFromProps,
  sectionName,
  paths,
  getFieldValueByPath,
}: ValidationProps & {
  template: FormStructure;
  sectionName: string;
  paths?: Array<string>; // If paths are given, "show" functions are overridden to always return true. Paths was added to allow for manual intervention of error handling from the BE.
  getFieldValueByPath: GetFieldValueByPathFunc;
}) => {
  const invalidFormTemplate: FormStructure = [];
  templateFromProps.forEach((formSection) => {
    const invalidFieldsInSection: Array<FormStructureField> = [];
    formSection.fields?.forEach((field) => {
      if (
        (paths && paths.includes(field.path)) ||
        getPathIsChild({ checkPaths: paths, path: field.path })
      ) {
        invalidFieldsInSection.push(field);
      }

      const fieldValue = field.path ? getFieldValueByPath(field.path) : null;
      const validator = field.validation || baseFieldValidation;
      const validationResult = validator({ fieldValue, field, getFieldValueByPath });
      if (validationResult !== true) {
        invalidFieldsInSection.push(field);
      }
    });
    if (invalidFieldsInSection.length) {
      invalidFormTemplate.push({
        ...{ ...formSection, fields: invalidFieldsInSection },
        show: paths ? () => true : formSection.show,
        majorLabel: undefined,
      });
    }
  });

  if (invalidFormTemplate.length) {
    invalidFormTemplate[0].majorLabel = sectionName;
  }

  return invalidFormTemplate;
};

const FormSection = ({
  label,
  cols,
  children,
  description,
  majorLabel,
  show,
  inset,
}: Pick<
  FormStructureSection,
  'label' | 'cols' | 'fields' | 'majorLabel' | 'description' | 'inset'
> & {
  children: React.ReactNode;
  show?: boolean;
}) => {
  // HACK: header labels over inputs can vary in size, and so can the help text underneath
  // because of this, theres no CSS alignment that can account for both being varied
  // this normalizes header height so that all inputs align

  const rowRef = useRef<HTMLDivElement>(null);
  const handleResize = useCallback(() => {
    const headers = rowRef.current?.querySelectorAll('.LabeledContainerStyle h3') || [];
    headers.forEach((header) => {
      /* eslint-disable no-param-reassign */
      (header as HTMLElement).style.height = '';
    });
    const tallestDivHeight = Math.max(
      ...Array.from(headers).map((div) => (div as HTMLDivElement).clientHeight)
    );
    headers.forEach((header) => {
      /* eslint-disable no-param-reassign */
      (header as HTMLElement).style.height = `${tallestDivHeight}px`;
    });
  }, []);

  const throttledResize = useMemo(() => throttle(handleResize, 500), [handleResize]);
  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', throttledResize);
    return () => {
      window.removeEventListener('resize', throttledResize);
    };
  }, [handleResize, throttledResize]);

  // TODO: animate hiding and showing
  if (!show) {
    return null;
  }

  const majorLabelHeader = (
    <styles.MajorLabelStyle>
      <Container>
        <h3>{majorLabel}</h3>
      </Container>
    </styles.MajorLabelStyle>
  );

  if (label) {
    return (
      <div className="FormSection">
        {majorLabel && majorLabelHeader}
        <Container>
          <LabeledSection cols={cols} label={label} description={description} inset={inset}>
            {children}
          </LabeledSection>
        </Container>
      </div>
    );
  }

  return (
    <div className="FormSection">
      {majorLabel && majorLabelHeader}
      <Container>
        {description && <styles.ParagraphBlockStyle>{description}</styles.ParagraphBlockStyle>}
        {children && (
          <styles.FormRowStyle cols={cols} ref={rowRef}>
            {children}
          </styles.FormRowStyle>
        )}
      </Container>
    </div>
  );
};

interface FormTemplateProps {
  cols?: number;
  template: FormStructure;
  correcting?: boolean;
  getFieldValueByPath: GetFieldValueByPathFunc;
  getBaseFormFieldProps: GetBaseFormFieldPropsFunc;
}

const FormTemplate = ({
  cols = 3,
  template,
  correcting,
  getFieldValueByPath,
  getBaseFormFieldProps,
}: FormTemplateProps) => {
  return (
    <>
      {template.map((section) => {
        return (
          <FormSection
            majorLabel={section.majorLabel}
            label={section.label}
            cols={section.cols || cols}
            key={section.key}
            show={
              section.show !== undefined ? section.show({ getFieldValueByPath, correcting }) : true
            }
            description={section.description}
            inset={section.inset}
          >
            {section.fields?.map((field, i) => {
              const key = field.path || reactNodeToString(field.label) || i;
              const Component = field.Component || Input;
              const props = field.props || {};
              const baseProps = getBaseFormFieldProps(field);

              // if the component is a checkbox, we need to pass the value from the store
              // into its props in order to know if it should be checked or not
              if (Component === Checkbox) {
                props.checkVal = baseProps.value;
              }
              const renderedComponent = (
                <Component
                  aria-label={`${[field.label || section.label, field.props?.placeholder]
                    .filter((value) => !!value)
                    .join(' - ')}`}
                  {...baseProps}
                  {...props}
                  key={key}
                />
              );
              if (field.label) {
                return (
                  <LabeledSection
                    cols={cols}
                    label={field.label}
                    key={key}
                    description={field.description}
                    inset={section.inset}
                  >
                    {renderedComponent}
                  </LabeledSection>
                );
              }
              return renderedComponent;
            })}
          </FormSection>
        );
      })}
    </>
  );
};

export default FormTemplate;
