import styled, { css } from 'styled-components';
import { ContainerStyles } from '@pointdotcom/pds';
import { BorderedSectionStyle } from 'styles/';

export const ColStyle = styled.div.attrs({ className: 'ColStyle' })`
  display: flex;
  flex-flow: row nowrap;
`;

export const BackColStyle = styled(ColStyle).attrs({ className: 'BackColStyle' })`
  flex: 1;
  justify-content: ${(props) => (props.styleAlign === 'center' ? 'center' : 'flex-start')};
`;

export const NextColStyle = styled(ColStyle).attrs({ className: 'NextColStyle' })`
  flex: 10;
  justify-content: ${(props) => (props.styleAlign === 'center' ? 'center' : 'flex-end')};
`;

const mobileBlockCSS = css`
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    ${ContainerStyles.ContainerStyle} {
      display: flex;
    }
  }
`;
export const FooterNavStyle = styled(BorderedSectionStyle).attrs({ className: 'FooterNavStyle' })`
  background: ${(props) => (props.styleType === 'error' ? props.theme.Color.Red : 'transparent')};
  padding: ${(props) => (props.weightedBottom ? '3em 0 10%' : '3em 0')};
  ${ContainerStyles.ContainerStyle} {
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.styleAlign || 'space-between'};
  }
  @media only print {
    display: none !important;
  }
  ${(props) => (props.mobileBlock ? mobileBlockCSS : null)};
  ${(props) =>
    !props.bordered
      ? css`
          border-color: transparent;
        `
      : null};
`;

export const ButtonGroupStyle = styled.div.attrs({ className: 'ButtonGroupStyle' })`
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${({ styleAlign }) => styleAlign};
  gap: 1rem;
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    justify-content: space-between;
  }
`;
