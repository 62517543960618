import React from 'react';
import { Container, Header } from '@pointdotcom/pds';
import RangeEstimatePrepayment from 'components/RangeEstimatePrepayment';
import i18n from './i18n';
import * as styles from './styles';

const AmountControlSection = ({ headerText, pricingRange, estimate, children = null }) => {
  return pricingRange?.hasMinEstimatesForUIControl?.() && pricingRange && estimate ? (
    <styles.ControlSectionStyle data-testid="ControlSectionStyle">
      <Container>
        <styles.HeaderDescInputContainerStyle>
          <styles.HeaderDescriptionStyle>
            <Header styleAlign="left" styleAlignMobile="left">
              {headerText || i18n.howMuchMoney}
            </Header>
          </styles.HeaderDescriptionStyle>
          <RangeEstimatePrepayment pricingRange={pricingRange} estimate={estimate} />
        </styles.HeaderDescInputContainerStyle>
      </Container>
      {children && <styles.ChildSectionStyle>{children}</styles.ChildSectionStyle>}
    </styles.ControlSectionStyle>
  ) : null;
};

export default AmountControlSection;
