import React, { useLayoutEffect, useRef } from 'react';
import nullthrows from 'nullthrows';
import { Route, useLocation } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import FlexibleFormEntrypoint from 'components/FlexibleFormEntrypoint';
import ClosingDisclosureAboutAgreementPage from 'containers/ClosingDisclosurePage/ClosingDisclosureAboutAgreementPage';
import ClosingDisclosureDecisionPage from 'containers/ClosingDisclosurePage/ClosingDisclosureDecisionPage';
import ClosingDisclosureDocumentsPage from 'containers/ClosingDisclosurePage/ClosingDisclosureDocumentsPage';
import ClosingDisclosureEstimatorPage from 'containers/ClosingDisclosurePage/ClosingDisclosureEstimatorPage';
import ClosingDisclosureOfferPage from 'containers/ClosingDisclosurePage/ClosingDisclosureOfferPage';
import ClosingDisclosureOverviewPage from 'containers/ClosingDisclosurePage/ClosingDisclosureOverviewPage';
import DashboardEstimatorPage from 'containers/DashboardEstimatorPage';
import DashboardM2Page from 'containers/DashboardPage';
import DashboardProcessOverviewPage from 'containers/DashboardProcessOverviewPage';
import ErrorPage, { ErrorType } from 'containers/ErrorPage';
import EstimateCalculatorDecisionPage from 'containers/EstimateCalculatorPage/EstimateCalculatorDecisionPage';
import EstimateCalculatorEstimatorPage from 'containers/EstimateCalculatorPage/EstimateCalculatorEstimatorPage';
import EstimateCalculatorOfferPage from 'containers/EstimateCalculatorPage/EstimateCalculatorOfferPage';
import FinancialCounselingCompletionPage from 'containers/FinancialCounselingPage/FinancialCounselingCompletionPage';
import FinancialCounselingSelectionPage from 'containers/FinancialCounselingPage/FinancialCounselingSelectionPage';
import FollowUpScheduleCompletePage from 'containers/FollowUpScheduleCompletePage';
import LoginPage from 'containers/LoginPage';
import PostFundingCalculator from 'containers/PostFundingCalculator';
import ProductQuizPage from 'containers/ProductQuizPage';
import ScheduleCompletePage from 'containers/ScheduleCompletePage';
import SchedulePage from 'containers/SchedulePage';
import HeiOfferEstimatorPage from 'containers/hei/HeiOfferEstimatorPage';
import { Page, getPageRouteItemByPage, getPathFromPage, pageRouteMap } from 'containers/helpers';
import ConfirmName from 'containers/prequal/pages/ConfirmName';
import Contact from 'containers/prequal/pages/Contact';
import CoopProperty from 'containers/prequal/pages/CoopProperty';
import CreditScore from 'containers/prequal/pages/CreditScore';
import FullAddress from 'containers/prequal/pages/FullAddress';
import HomeAddress from 'containers/prequal/pages/HomeAddress';
import HomeValue from 'containers/prequal/pages/HomeValue';
import Ineligible from 'containers/prequal/pages/Ineligible';
import MortgageBalance from 'containers/prequal/pages/MortgageBalance';
import PrequalOfferCodeDirectLandingPage from 'containers/prequal/pages/OfferCodeDirectLandingPage';
import SsnLastFour from 'containers/prequal/pages/SsnLastFour';
import Waitlist from 'containers/prequal/pages/Waitlist';
import WaitlistSignup from 'containers/prequal/pages/WaitlistSignup';
import { clearDirectMailFormKeyFromURL } from 'containers/prequal/pages/helpers';
import {
  Redirect,
  RedirectWithParams,
  RoutesWithProduct,
  useHistory,
} from 'containers/routerHelpers';
import DashboardTaskPage from 'containers/tasks/DashboardTaskPage';
import DashboardTasksListPage from 'containers/tasks/DashboardTasksListPage';
import EstimatesController from 'controllers/EstimatesController';
import PrequalOfferCodePageController from 'controllers/prequal/OfferCodePage';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import { usePageViewMonitor } from 'lib/posthogEvents';
import redirectLegacyRoutes from 'redirectLegacyRoutes';

smoothscroll.polyfill();

interface LocationListenerProps {
  children?: React.ReactNode;
}

function LocationListener({ children }: LocationListenerProps): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  useLayoutEffect(() => {
    clearDirectMailFormKeyFromURL(history, location);
  }, [history, location]);

  const prevLocationRef = useRef(location);

  useLayoutEffect(() => {
    const prevLocation = prevLocationRef.current;
    prevLocationRef.current = location;
    if (location !== prevLocation) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  usePageViewMonitor();

  return <>{children}</>;
}

type FourOhFourProps = Omit<React.ComponentProps<typeof ErrorPage>, 'errorCode' | 'errorType'>;

const FourOhFour = (props: FourOhFourProps) => (
  <ErrorPage errorType={ErrorType.FourOhFour} {...props} />
);

// prettier-ignore
// Routes become unreadable if we let Prettier break them up.
export default (
  <LocationListener>
    {/* Pages */}
    <RoutesWithProduct>
      {/* Dashboard */}
      <Route {...getPageRouteItemByPage(Page.DASHBOARD_LOGIN)} Component={LoginPage} />
      <Route {...getPageRouteItemByPage(Page.DASHBOARD)} element={<DashboardM2Page/>} />
      <Route {...getPageRouteItemByPage(Page.DASHBOARD_ESTIMATOR)} element={<DashboardEstimatorPage />} />
      <Route {...getPageRouteItemByPage(Page.DASHBOARD_PROCESS_OVERVIEW)} element={<DashboardProcessOverviewPage />} />
      <Route {...getPageRouteItemByPage(Page.DASHBOARD_TASKS_LIST)} element={<DashboardTasksListPage />} />
      <Route {...getPageRouteItemByPage(Page.DASHBOARD_TASK)} element={<DashboardTaskPage />} />
      <Route {...getPageRouteItemByPage(Page.HEI_EDUCATION)} Component={ProductQuizPage} />

      {/* Call Scheduling */}
      <Route {...getPageRouteItemByPage(Page.PREQUAL_SCHEDULE)} Component={SchedulePage} />
      <Route path="/schedule"
        Component={() => {
          const location = useLocation();
          return (
            <Redirect
              to={{
                pathname: '/hei/schedule-call',
                search: location.search,
              }}
            />
          );
        }}
      />
      <Route path="/schedule/:calendar"
        Component={() => {
          const location = useLocation();
          return (
            <RedirectWithParams
              to={{
                pathname: '/hei/schedule-call/:calendar',
                search: location.search,
              }}
            />
          );
        }}
      />

      {/* Renamed follow-up path to estimate-calculator, maintain backwards compatibility via redirect */}
      <Route path={nullthrows(getPathFromPage(Page.FOLLOW_UP_DEFAULT))} element={<RedirectWithParams to={nullthrows(getPathFromPage(Page.ESTIMATE_CALCULATOR_DEFAULT))} />} />
      <Route path={nullthrows(getPathFromPage(Page.FOLLOW_UP_PAGE))} element={<RedirectWithParams to={nullthrows(getPathFromPage(Page.ESTIMATE_CALCULATOR_PAGE))} />} />

      {/* All follow ups */}
      <Route {...getPageRouteItemByPage(Page.FOLLOW_UP_SCHEDULE_COMPLETE)} Component={FollowUpScheduleCompletePage} />

      {/* Estimate-calculator folllow up */}
      <Route {...getPageRouteItemByPage(Page.ESTIMATE_CALCULATOR_OFFER)} Component={EstimateCalculatorOfferPage} />
      <Route {...getPageRouteItemByPage(Page.ESTIMATE_CALCULATOR_ESTIMATOR)} Component={EstimateCalculatorEstimatorPage} />
      <Route {...getPageRouteItemByPage(Page.ESTIMATE_CALCULATOR_DECISION)} Component={EstimateCalculatorDecisionPage} />
      <Route {...getPageRouteItemByPage(Page.ESTIMATE_CALCULATOR_DEFAULT)} Component={LoginPage} />

      {/* Financial Counseling follow up */}
      <Route {...getPageRouteItemByPage(Page.FINANCIAL_COUNSELING_SELECTION)} element={<FinancialCounselingSelectionPage />} />
      <Route {...getPageRouteItemByPage(Page.FINANCIAL_COUNSELING_COMPLETE)} element={<FinancialCounselingCompletionPage />} />
      <Route {...getPageRouteItemByPage(Page.FINANCIAL_COUNSELING_DEFAULT)} Component={LoginPage} />

      {/* Closing disclosure follow up */}
      <Route {...getPageRouteItemByPage(Page.CLOSING_DISCLOSURE_OVERVIEW)} Component={ClosingDisclosureOverviewPage} />
      <Route {...getPageRouteItemByPage(Page.CLOSING_DISCLOSURE_OFFER)} Component={ClosingDisclosureOfferPage} />
      <Route {...getPageRouteItemByPage(Page.CLOSING_DISCLOSURE_ESTIMATOR)} Component={ClosingDisclosureEstimatorPage} />
      <Route {...getPageRouteItemByPage(Page.CLOSING_DISCLOSURE_ABOUT_AGREEMENT)} Component={ClosingDisclosureAboutAgreementPage} />
      <Route {...getPageRouteItemByPage(Page.CLOSING_DISCLOSURE_DOCUMENTS)} Component={ClosingDisclosureDocumentsPage} />
      <Route {...getPageRouteItemByPage(Page.CLOSING_DISCLOSURE_DECISION)} Component={ClosingDisclosureDecisionPage} />
      <Route {...getPageRouteItemByPage(Page.CLOSING_DISCLOSURE_DEFAULT)} Component={LoginPage} />

      {/* HEI Offer */}
      <Route {...getPageRouteItemByPage(Page.HEI_ABOUT)} element={<EstimatesController page={Page.HEI_ABOUT} />} />
      <Route {...getPageRouteItemByPage(Page.HEI_PRICING)} element={<EstimatesController page={Page.HEI_PRICING} />} />
      <Route {...getPageRouteItemByPage(Page.HEI_OFFER)} element={<HeiOfferEstimatorPage />} />
      <Route {...getPageRouteItemByPage(Page.HEI_ESTIMATOR)} element={<RedirectWithParams to={nullthrows(getPathFromPage(Page.HEI_OFFER))} />} />
      <Route {...getPageRouteItemByPage(Page.HEI_SCHEDULE_COMPLETE)} Component={ScheduleCompletePage as TSFixMe} />
      <Route {...getPageRouteItemByPage(Page.HEI_APPLICATION)} element={<EstimatesController page={Page.HEI_APPLICATION} />} />
      <Route {...getPageRouteItemByPage(Page.HEI_DEFAULT)} element={<EstimatesController />} />


      {/* Prequal */}
      <Route {...getPageRouteItemByPage(Page.PREQUAL_START)} Component={HomeAddress} />
      <Route
        {...getPageRouteItemByPage(Page.PREQUAL_DEFAULT)}
        Component={FlexibleFormEntrypoint}
      />

      <Route {...getPageRouteItemByPage(Page.PREQUAL_OFFER_CODE_DIRECT)} Component={PrequalOfferCodeDirectLandingPage} />
      <Route {...getPageRouteItemByPage(Page.PREQUAL_OFFER_CODE)} Component={PrequalOfferCodePageController} />

      {/* Static Prequal */}
      <Route {...getPageRouteItemByPage(Page.PREQUAL_HOME_ADDRESS)} Component={HomeAddress} />
      <Route {...getPageRouteItemByPage(Page.PREQUAL_UNIT_NUMBER)} Component={FullAddress} />
      <Route {...getPageRouteItemByPage(Page.PREQUAL_HOME_VALUE)} Component={HomeValue} />
      <Route {...getPageRouteItemByPage(Page.PREQUAL_MORTGAGE_BALANCE)} Component={MortgageBalance} />
      <Route {...getPageRouteItemByPage(Page.PREQUAL_CONTACT_INFO)} Component={Contact} />
      <Route {...getPageRouteItemByPage(Page.PREQUAL_INELIGIBLE)} Component={Ineligible} />
      <Route {...getPageRouteItemByPage(Page.PREQUAL_WAITLIST)} Component={Waitlist as TSFixMe} />
      <Route {...getPageRouteItemByPage(Page.PREQUAL_WAITLIST_SIGNUP)} Component={WaitlistSignup} />
      <Route {...getPageRouteItemByPage(Page.PREQUAL_SSN)} Component={SsnLastFour} />
      <Route {...getPageRouteItemByPage(Page.PREQUAL_COOP_PROPERTY)} Component={CoopProperty} />
      <Route {...getPageRouteItemByPage(Page.PREQUAL_CREDIT_SCORE)} Component={CreditScore} />
      <Route {...getPageRouteItemByPage(Page.PREQUAL_CONFIRM_NAME)} Component={ConfirmName} />
      
      {/* post-funding calculator */}
      <Route {...getPageRouteItemByPage(Page.POST_FUNDING_CALCULATOR)} Component={() => {
        const postFundingCalcEnabled = useFeatureFlag(FeatureFlag.PostFundingCalc) === 'enabled';
        if (postFundingCalcEnabled) {
          return <PostFundingCalculator />
        }

        return <FourOhFour />;
      }} />

      {/* Generic error page */}
      <Route {...getPageRouteItemByPage(Page.ERROR_GENERAL)} Component={ErrorPage} />

      {/* Everything else */}
      <Route {...getPageRouteItemByPage(Page.SCHEDULE_COMPLETE)} Component={ScheduleCompletePage as TSFixMe} />
      <Route path="*" Component={FourOhFour} />

      {redirectLegacyRoutes(pageRouteMap)}
    </RoutesWithProduct>
  </LocationListener>
);
