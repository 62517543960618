import { Expand } from 'types';
import BaseModel from './BaseModel';
import ContactModel from './ContactModel';
import FollowUpEstimateModel, { FollowUpEstimate } from './FollowUpEstimateModel';
import { normalizeProps } from './helpers';

export enum FollowUpCategory {
  FinancialCounseling = 'acknowledgment-of-heir-flow', // Underwrite still uses the legacy AoH name for this category
  CD = 'closing-disclosure-flow',
  EstimateCalculator = 'estimate-calculator-flow',
}

export enum FollowUpStatus {
  Active = 'active',
  Inactive = 'inactive',
  Closed = 'closed',
  Open = 'open',
  Submitted = 'submitted',
}

export interface FollowUp {
  appraised: boolean;
  category: FollowUpCategory;
  csrfToken: string;
  readOnly: boolean;
  status: FollowUpStatus;
  rejected: boolean;
  authenticate: boolean;
  firstName: string;
  lastName: string;
  estimate: FollowUpEstimate;
  pdfAccessUrl?: string;
  documentPath?: string;
  phone?: string;
  email?: string;
  acknowledgmentOfHeir?: {
    choice: null | string;
  };
}

/* eslint-disable camelcase */
export interface FollowUpProps extends FollowUp {
  first_name?: string;
  last_name?: string;
}

class FollowUpModel extends BaseModel<FollowUp> implements FollowUpProps {
  appraised: TSFixMe;

  category: TSFixMe;

  csrfToken: TSFixMe;

  readOnly: TSFixMe;

  status: TSFixMe;

  rejected: TSFixMe;

  authenticate: TSFixMe;

  firstName: TSFixMe;

  lastName: TSFixMe;

  estimate: TSFixMe;

  pdfAccessUrl: TSFixMe;

  documentPath: TSFixMe;

  phone: TSFixMe;

  email: TSFixMe;

  acknowledgmentOfHeir: TSFixMe;

  displayBorrowerInfoDocAcknowledgment: TSFixMe;

  constructor(props: Expand<FollowUpProps>) {
    const reshapedProps = normalizeProps<FollowUpProps>(props, {
      first_name: 'firstName',
      last_name: 'lastName',
      pdf_access_url: 'pdfAccessUrl',
      document_path: 'documentPath',
      csrf_token: 'csrfToken',
      read_only: 'readOnly',
      acknowledgment_of_heir: 'acknowledgmentOfHeir',
      display_borrower_info_doc_acknowledgment: 'displayBorrowerInfoDocAcknowledgment',
    });
    super(reshapedProps);
    Object.assign(this, reshapedProps);
  }

  getEstimate(): FollowUpEstimateModel | null {
    if (this.estimate) {
      return new FollowUpEstimateModel(this.estimate);
    }

    return null;
  }

  getContact(): ContactModel {
    return new ContactModel({
      firstName: this.firstName,
      lastName: this.lastName,
    });
  }

  isEstimateCalculator(): boolean {
    return this.category === FollowUpCategory.EstimateCalculator;
  }

  isClosingDisclosure(): boolean {
    return this.category === FollowUpCategory.CD;
  }

  isFinancialCounseling(): boolean {
    return this.category === FollowUpCategory.FinancialCounseling;
  }

  isPreliminary(): boolean {
    const preliminary = this.getEstimate()?.preliminary || false;
    return preliminary;
  }

  isFinalPricing(): boolean {
    return (
      (this.category === FollowUpCategory.EstimateCalculator && !this.isPreliminary()) ||
      this.isClosingDisclosure()
    );
  }

  isAppraised(): boolean {
    return this.getEstimate()?.preliminary === false && this.appraised === true;
  }

  getFormattedOfferAmount(): string {
    return this.getEstimate()?.getPricing()?.getFormattedOptionInvestmentAmount() || '';
  }

  getPdfAccessUrl(): string {
    return this.pdfAccessUrl;
  }

  getDocumentPath(): string {
    return this.documentPath;
  }

  getRepaymentTerm(): string {
    return this.getEstimate()?.getFormattedTerm() || '';
  }
}

export default FollowUpModel;
