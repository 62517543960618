import styled, { css } from 'styled-components';
import {
  ButtonStyles,
  ContainerStyles,
  FormStyles,
  HeaderStyles,
  HelpTextStyles,
  IconStyles,
  LoaderStyles,
  mixins,
} from '@pointdotcom/pds';
import { PaddedSectionStyle } from 'styles';
import { FormDescriptionType } from '../constants';

export const PageFormStyle = styled(PaddedSectionStyle).attrs({
  className: 'PageFormStyle' as string,
})`
  padding-bottom: 0;
  background: white;
`;

export const formCSS = css`
  display: flex;
  flex-flow: column nowrap;
  gap: 2.3rem;
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    gap: 2rem;
  }
`;

export const MultiRowFormFieldStyle = styled.div.attrs({
  className: 'MultiRowFormFieldStyle' as string,
})`
  ${formCSS};
`;

export const FormStyle = styled.form.attrs({ className: 'FormStyle' as string })`
  ${formCSS}

  ${LoaderStyles.LoaderStyle} {
    margin: auto;
  }
`;

export const FormRowStyle = styled.div.attrs({ className: 'FormRowStyle' as string })<{
  cols?: number;
}>`
  display: grid;
  grid-template-columns: ${({ cols }) => `repeat(${cols || 1}, 1fr)`};
  gap: 1rem;
  align-items: flex-start;
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    grid-template-columns: 1fr;
  }
`;

export const ParagraphBlockStyle = styled.div.attrs({
  className: 'ParagraphBlockStyle' as string,
})`
  display: flex;
  flex-flow: column nowrap;
  gap: 1em;
  a {
    display: inline-block;
    white-space: normal;
  }
  p {
    margin-bottom: 0;
  }
  margin-bottom: 1rem;
`;

const labelMargin = '1.2rem';
const insetCSS = css`
  position: relative;
  margin-top: -1.3rem;
  margin-left: 2rem;
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    margin-top: -1rem;
  }
`;

interface LabeledSectionProps {
  description?: FormDescriptionType;
  inset?: boolean;
}

const labeledSectionCSS = css<LabeledSectionProps>`
  ${({ inset }) => (inset ? insetCSS : null)}
  > h3,
  > legend {
    font-size: inherit;
    display: table-cell;
    vertical-align: bottom;
    font-weight: bold;
    padding-bottom: ${({ description }) => (description ? '0.5rem' : labelMargin)};
  }
  ${FormStyles.FormComponentStyle} {
    margin-bottom: 0;
  }
  ${ParagraphBlockStyle} {
    font-size: ${({ theme }) => mixins.getRemFromPx(14, theme.globals.baseFontSize.default)};
    margin-bottom: ${labelMargin};
    transition: all 200ms ease-in-out;
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
    &:hover {
      color: ${({ theme }) => theme.Color.PointBlack};
    }
  }
`;

export const LabeledContainerStyle = styled.div.attrs({
  className: 'LabeledContainerStyle' as string,
})<LabeledSectionProps>`
  ${labeledSectionCSS}
`;

export const LabeledFieldStyle = styled.fieldset.attrs({
  className: 'LabeledFieldStyle' as string,
})<LabeledSectionProps>`
  padding: 0;
  ${mixins.appearance('none')}
  ${labeledSectionCSS}
`;

// Consider exporting FormComponentStyleProps from PDS to resolve TSFixMe here
export const CustomFieldBaseStyle = styled.div<TSFixMe>`
  ${FormStyles.helpTextInComponentCSS}
`;

export const CheckboxStackFieldStyle = styled(CustomFieldBaseStyle).attrs({
  className: 'CheckboxStackFieldStyle' as string,
})`
  ${HelpTextStyles.HelpTextStyle} {
    margin-top: 0.6rem;
  }
`;

export const BirthDateInputStyle = styled(CustomFieldBaseStyle).attrs({
  className: 'BirthDateInputStyle' as string,
})`
  gap: 0.5rem;
  display: grid;
  grid-template-columns: 1.9fr 1.5fr 1.5fr;
  > * {
    &:nth-child(4) {
      grid-column: 1 / 4;
    }
  }
`;

export const YesNoFieldStyle = styled(CustomFieldBaseStyle).attrs({
  className: 'YesNoFieldStyle' as string,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  > * {
    &:first-child {
      grid-column: 1 / 2;
    }
    &:nth-child(2) {
      grid-column: 2 / 2;
    }
    &:nth-child(3) {
      grid-column: 1 / 3;
    }
  }
`;

export const ListOfElementsStyle = styled.div.attrs({ className: 'ListOfElementsStyle' as string })`
  display: flex;
  flex-flow: column nowrap;
  gap: 1em;
  margin-bottom: 0;
  a {
    display: inline-block;
    white-space: normal;
  }
`;

export const MajorLabelStyle = styled.div.attrs({ className: 'MajorLabelStyle' as string })`
  border: 1px solid ${({ theme }) => theme.line.color.default};
  border-left: none;
  border-right: none;
  padding: 3% 0;
  font-family: ${({ theme }) => theme.fonts.serif.name};
  margin: 3rem 0;
  h3 {
    max-width: 50%;
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: ${(props) => mixins.getRemFromPx(18, props.theme.globals.baseFontSize.default)};
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    font-size: ${(props) => mixins.getRemFromPx(16, props.theme.globals.baseFontSize.default)};
    padding: 5% 0;
    h3 {
      max-width: none;
    }
  }
`;

export const CorrectionsModalStyle = styled.div.attrs({ className: 'CorrectionsModalStyle' })<{
  strongWarning: boolean;
}>`
  ${ContainerStyles.ContainerStyle} {
    padding: 0 ${({ theme }) => theme.container.gutter.default};
    > ${HeaderStyles.HeaderStyle} {
      &::before {
        content: '!';
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 1.8rem;
        height: 1.8rem;
        background: ${({ theme, strongWarning }) =>
          strongWarning ? theme.Color.Red : theme.Color.Yorange};
        font-weight: bold;
        color: white;
        font-size: 1.2rem;
        border-radius: 50%;
        margin-bottom: 1.5rem;
      }
    }
  }
  footer {
    padding: ${({ theme }) => theme.container.gutter.default} 0;
    border-top: 1px solid ${({ theme }) => theme.line.color.default};
    > ${ContainerStyles.ContainerStyle} {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
  ${BirthDateInputStyle} {
    min-width: 302px;
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    ${BirthDateInputStyle} {
      min-width: 100%;
    }
  }
`;

export const AdditionalOwnersStyle = styled(CustomFieldBaseStyle).attrs({
  className: 'AdditionalOwnersStyle',
})`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  ul {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    gap: 0.5rem;
    &:first-child {
      > *:last-child {
        visibility: hidden;
      }
    }
  }
  > ${ButtonStyles.ButtonContainerStyle} {
    align-self: flex-start;
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    li {
      grid-template-columns: 1fr;
      border: 1px solid ${({ theme }) => theme.line.color.default};
      padding: 2rem;
      box-sizing: border-box;
      &:first-child {
        > *:last-child {
          display: none;
        }
      }
      ${ButtonStyles.ButtonBaseStyle} {
        padding: 0;
        margin-top: 0.5rem;
        display: inline-flex;
        align-items: center;
        flex-flow: row-reverse;
        width: auto;
        border: none;
        color: ${({ theme }) => theme.Color.GrayLightestAccessible};
        font-weight: normal;
        &:hover {
          background-color: transparent;
        }
        ${IconStyles.IconStyle} {
          position: static;
          transform: none;
          path {
            fill: ${({ theme }) => theme.Color.Gray4};
          }
        }
      }
    }
  }
`;
