export default {
  productEducation: 'HEI Education',
  logout: 'Log out',
  subheaderBreadcrumb: 'HEI educational video',
  currentOffer: 'Your current offer is for %offerAmount%!',
  watchVideo: 'Watch an HEI Overview Video',
  beforeWeCan:
    'Before we can begin scheduling an appraisal, we ask that you watch the HEI overview video below and answer a short questionnaire.',
  thenQuiz: 'Then, take the quiz',
  aFewThings: 'A few things to note before you begin',
  fourOfSix: 'You must answer at least 4 out of 6 questions correctly',
  condition1: '%fourOfSix% in order to opt out of the educational call with an expert',
  condition2:
    'You may re-watch the video and submit the quiz as many times as you’d like to get a passing score.',
  condition3:
    'Please note that you can still speak with your account manager if you choose to do so. We are just giving you an opportunity to skip the call if you prefer to skip the call.',
  startTheQuiz: 'Start the quiz',
  resumeTheQuiz: 'Resume the quiz',
  preferExpert: 'Prefer to speak with an expert?',
  ourExperts:
    'Our experts are happy to discuss the home equity investment with you on a 20-minute educational call.',
  close: 'Close',
  jumpToTheAnswer: 'Jump to the answer',
  jumpToSection: 'Jump to section',
  chapters: 'Chapters',
  backToQuiz: 'Back to quiz',
  startQuiz: 'Start quiz',
};
