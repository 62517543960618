import styled from 'styled-components';
import { ContainerStyles, GraphicsStyles, IconStyles, mixins } from '@pointdotcom/pds';
import * as appStyles from 'styles';

const midCalloutBreakPoint = '895px';
const smallCalloutBreakPoint = '465px';

export const PointExplained3ImgsSectionStyle = styled.div.attrs({
  className: 'PointExplained3ImgsSectionStyle',
})`
  ${appStyles.PaddedSectionCSS}
`;

export const CalloutTextStyle = styled.aside.attrs({ className: 'CalloutTextStyle' })`
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  white-space: normal;
  padding: 12%;
  box-sizing: border-box;
  line-height: 1.4em;
  @media (max-width: ${midCalloutBreakPoint}) and (min-width: ${smallCalloutBreakPoint}) {
    padding: 8%;
    width: 50% !important;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-family: ${(props) => props.theme.fonts.serif.name};
    font-size: ${(props) => mixins.getRemFromPx(18, props.theme.globals.baseFontSize.default)};
  }
`;

export const IllustratedCalloutsContainerStyle = styled.div.attrs({
  className: 'IllustratedCalloutsContainerStyle',
})`
  white-space: nowrap;
  margin-top: 9%;
  &.entered {
    > div {
      opacity: 1;
      transform: translate(0, 0) rotate(0) !important;
    }
  }
  > div {
    width: 36%;
    display: inline-block;
    box-sizing: content-box;
    height: 0;
    padding-bottom: 38%;
    position: relative;
    opacity: 0;
    @media (max-width: ${midCalloutBreakPoint}) {
      display: block;
      width: 100%;
      left: 0 !important;
      top: 0 !important;
      ${GraphicsStyles.GraphicStyle} {
        width: 50% !important;
        height: 100% !important;
      }
    }
    @media (max-width: ${smallCalloutBreakPoint}) {
      padding-bottom: 100%;
      ${GraphicsStyles.GraphicStyle} {
        width: 97% !important;
        height: 97% !important;
      }
    }
    &:first-child {
      background-color: ${(props) => props.theme.Color.AccentBlue};
      top: 25px;
      left: 0;
      transition: all 0.5s ease-out;
      transform: translate(-10%, 20%) rotate(-5deg);
      ${GraphicsStyles.GraphicStyle} {
        left: 0;
        top: 7%;
        height: 90%;
        width: 90%;
      }
      ${CalloutTextStyle} {
        width: 86%;
        @media (max-width: ${midCalloutBreakPoint}) and (min-width: ${smallCalloutBreakPoint}) {
          right: 0;
          left: auto;
        }
      }
    }
    &:nth-child(2) {
      background-color: ${(props) => props.theme.Color.PurpleBlue5};
      top: 75px;
      left: -4%;
      transform: translate(0, 10%) rotate(0);
      transition: all 0.5s ease-out 0.4s;
      ${GraphicsStyles.GraphicStyle} {
        left: 3%;
        height: 88%;
        width: 88%;
        @media (max-width: ${midCalloutBreakPoint}) and (min-width: ${smallCalloutBreakPoint}) {
          left: auto;
          right: 0;
        }
      }
      ${CalloutTextStyle} {
        width: 97%;
      }
    }
    &:last-child {
      background-color: ${(props) => props.theme.Color.PurpleBlue7};
      left: -8%;
      transform: translate(10%, 10%) rotate(4deg);
      transition: all 0.5s ease-out 0.8s;
      ${GraphicsStyles.GraphicStyle} {
        height: 83%;
        width: 83%;
        left: 50%;
        transform: translateX(-50%);
        @media (max-width: ${midCalloutBreakPoint}) and (min-width: ${smallCalloutBreakPoint}) {
          left: 0;
          transform: translateX(0);
        }
      }
      ${CalloutTextStyle} {
        width: 100%;
        @media (max-width: ${midCalloutBreakPoint}) and (min-width: ${smallCalloutBreakPoint}) {
          left: auto;
          right: 0;
        }
      }
    }
  }
  ${GraphicsStyles.GraphicStyle} {
    position: absolute;
    left: 0;
  }
`;

export const ItemCalloutContainerStyle = styled(ContainerStyles.ContainerStyle).attrs({
  className: 'ItemCalloutContainerStyle',
})`
  @media (max-width: ${midCalloutBreakPoint}) {
    max-width: none;
    padding: 0;
  }
`;

export const RepayContainerStyle = styled.div.attrs({ className: 'RepayContainerStyle' })`
  ${IconStyles.IconStyle} {
    opacity: 0;
    transition: all 0.3s ease-out;
    transform: scale(0.5) translateY(10%);
    display: inline-block;
    margin-bottom: 5%;
  }
  &.entered {
    ${IconStyles.IconStyle} {
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }
  text-align: center;
  max-width: 640px;
  margin: 17% auto 0;
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    text-align: left;
  }
`;
