import { z } from 'zod';

/** Components */
export const smartyStreetsComponentsSchema = z.object({
  urbanization: z.string().optional(),
  primary_number: z.string().optional(),
  street_name: z.string().optional(),
  street_predirection: z.string().optional(),
  street_postdirection: z.string().optional(),
  street_suffix: z.string().optional(),
  secondary_number: z.string().optional(),
  secondary_desigantor: z.string().optional(),
  extra_secondary_number: z.string().optional(),
  extra_secondary_designator: z.string().optional(),
  pmb_designator: z.string().optional(),
  pmb_number: z.string().optional(),
  city_name: z.string().optional(),
  default_city_name: z.string().optional(),
  state_abbreviation: z.string().optional(),
  zipcode: z.string().optional(),
  plus4_code: z.string().optional(),
  delivery_point: z.string().optional(),
  delivery_point_check_digit: z.string().optional(),
});
export type SmartyStreetsComponents = z.infer<typeof smartyStreetsComponentsSchema>;

/** Metadata */
export const smartyStreetsMetadataSchema = z.object({
  record_type: z.string().optional(),
  zip_type: z.string().optional(),
  county_fips: z.string().optional(),
  county_name: z.string().optional(),
  ews_match: z.string().optional(),
  carrier_route: z.string().optional(),
  congressional_district: z.string().optional(),
  building_default_indicator: z.string().optional(),
  rdi: z.string().optional(),
  elot_sequence: z.string().optional(),
  elot_sort: z.string().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
  precision: z.string().optional(),
  time_zone: z.string().optional(),
  utc_offset: z.number().int().optional(),
  dst: z.boolean().optional(),
});
export type SmartyStreetsMetadata = z.infer<typeof smartyStreetsMetadataSchema>;

/** Analysis */
export const smartyStreetsAnalysisSchema = z.object({
  dpv_match_code: z.string().optional(),
  dpv_footnotes: z.string().optional(),
  dpv_cmra: z.string().optional(),
  dpv_vacant: z.string().optional(),
  active: z.string().optional(),
  footnotes: z.string().optional(),
  lacslink_code: z.string().optional(),
  lacslink_indicator: z.string().optional(),
  suitelink_match: z.boolean().optional(),
});
export type SmartyStreetsAnalysis = z.infer<typeof smartyStreetsAnalysisSchema>;

export const smartyStreetsStreetAddressSchema = z.object({
  input_id: z.string().optional(),
  input_index: z.number().int().optional(),
  candidate_index: z.number().int().optional(),
  addressee: z.string().optional(),
  delivery_line_1: z.string().optional(),
  delivery_line_2: z.string().optional(),
  last_line: z.string().optional(),
  delivery_point_barcode: z.string().optional(),
  components: smartyStreetsComponentsSchema.optional(),
  metadata: smartyStreetsMetadataSchema.optional(),
  analysis: smartyStreetsAnalysisSchema.optional(),
});
export type SmartyStreetsStreetAddress = z.infer<typeof smartyStreetsStreetAddressSchema>;

/**
 * Response for /street-address (GET, status 200)
 */
export const smartyStreetsStreetAddressResponseSchema = z.array(smartyStreetsStreetAddressSchema);
export type SmartyStreetsStreetAddressResponse = z.infer<
  typeof smartyStreetsStreetAddressResponseSchema
>;

export const smartyStreetsStreetAddressQueryParamsSchema = z.object({
  license: z.string().optional(),
  street: z.string().optional(),
  street2: z.string().optional(),
  secondary: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zipcode: z.string().optional(),
  lastline: z.string().optional(),
  addressee: z.string().optional(),
  urbanization: z.string().optional(),
  candidates: z.string().optional(),
  match: z.string().optional(),
  input_id: z.string().optional(),
});
export type SmartyStreetsStreetAddressQueryParams = z.infer<
  typeof smartyStreetsStreetAddressQueryParamsSchema
>;
