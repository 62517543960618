import DataModel from './data';

class PointPerson extends DataModel {
  static NAMESPACE = 'Availability';

  static CONFIG = {
    required: ['firstName'],
    optional: ['lastName', 'pic'],
  };

  constructor(data) {
    super(data, PointPerson.CONFIG, PointPerson.NAMESPACE);
  }
}

export default PointPerson;
