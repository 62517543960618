import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { ContainerStyles } from '@pointdotcom/pds';

export const PartnerFooterStyle = styled.div.attrs({ className: 'PartnerFooterStyle' })`
  border-top: 1px solid ${(props) => props.theme.line.color.default};
  padding: 3% 0px 5%;
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding: 10% 0 20%;
  }
  ${ContainerStyles.ContainerStyle} {
    text-align: right;
  }
`;

export const ContactSuccessContainerStyle = styled.div.attrs({
  className: 'ContactSuccessContainerStyle',
})`
  p {
    &:last-child {
      margin-bottom: 10%;
    }
  }
`;

export const ContactConsentContainerStyle = styled.div.attrs({
  className: 'ContactConsentContainerStyle',
})`
  margin-top: 2rem;
`;

export const FooterCTAWrapperStyle = styled.div.attrs({ className: 'FooterCTAWrapperStyle' })<{
  animationStatus: TransitionStatus;
}>`
  transition: all 0.3s ease-in-out;
  ${(props) => {
    let scale = '1';
    let opacity = '1';
    if (props.animationStatus === 'entering' || props.animationStatus === 'entered') {
      scale = '0';
      opacity = '0';
    }

    return css`
      transform: scale(${scale});
      opacity: ${opacity};
    `;
  }}
`;
