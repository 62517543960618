import styled, { css } from 'styled-components';
import {
  Button,
  ButtonStyles,
  Color,
  Container,
  Header,
  Size,
  SplashTextStyles,
  Style,
  mixins,
} from '@pointdotcom/pds';
import { ChatIconStyle } from 'components/ChatIcon/styles';
import { SubNavBaseStyle } from 'components/SubNav/styles';
import { PlayButtonStyle } from 'components/VideoPlayer/PlayButton';
import { TasklistStyle } from 'containers/DashboardProcessOverviewPage/FundingStageList/styles';
import { pxToRem } from 'styleHelpers';
import { BorderedSectionStyle, HeroContainerStyle } from 'styles/';

export { BorderedSectionStyle };

const sectionMargins = '2.625rem';

export const LifeSaverIconStyle = styled.svg.attrs({ className: 'LifeSaverIconStyle' })`
  stroke: ${({ theme }) => theme.Color.Gray1};
`;

export const DashboardStyle = styled.div.attrs({ className: 'DashboardStyle' })`
  background: ${({ theme }) => theme.Color.White};
  // Sub nav is only a single crumb on the dashboard,
  // so hide it on mobile in favor of the current offer text
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${SubNavBaseStyle} {
      display: none;
    }
  }
`;

export const CTAHeaderStyle = styled(Header).attrs({
  className: 'CTAHeaderStyle',
  styleSize: Size.Large,
})`
  max-width: 620px;
  & > * {
    font-size: ${(props) => mixins.getRemFromPx(42, props.theme.globals.baseFontSize.default)};
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    & > * {
      font-size: ${({ theme }) => mixins.getRemFromPx(30, theme.globals.baseFontSize.default)};
    }
  }
`;

export const GreetingHeaderStyle = styled(Header).attrs({
  className: 'GreetingHeaderStyle',
})`
  color: ${({ theme }) => theme.Color.Gray3};

  margin-bottom: 1.125rem;

  & > * {
    font-family: ${({ theme }) => theme.fonts.serif.name};
    font-style: italic;
    font-weight: 400;
  }

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    font-size: ${(props) => mixins.getRemFromPx(18, props.theme.globals.baseFontSize.default)};
  }
`;

export const HeaderContainerStyle = styled.div.attrs({
  className: 'HeaderContainerStyle',
})``;

export const SectionHeaderStyle = styled(Header).attrs({
  className: 'SectionHeaderStyle',
})<{ mobileShadowTop?: boolean }>`
  margin-bottom: ${sectionMargins};

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    margin-bottom: 2rem;

    ${({ theme, mobileShadowTop = false }) =>
      mobileShadowTop
        ? css`
            &::before {
              display: block;
              content: '';
              height: 2rem;
              box-shadow: ${mixins.rgba(theme.Color.Gray4, 0.25)} 0px -12px 15px -12px;
              margin: 0 -20px 0;
            }
          `
        : ''}

    & > * {
      font-size: 1rem;
    }
  }
`;

export const taskLeftPaddingDesktop = '25px';
export const taskLeftPaddingMobile = '40px';

export const TasksSectionFooterStyle = styled(Container).attrs({
  className: 'TasksSectionFooterStyle',
})`
  a {
    ${({ theme }) => theme.globals.linkInvertedCSS}
    display: inline-block;
    margin-left: 0.5em;
  }
  aside {
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
    flex-flow: row nowrap;
  }
  ${SplashTextStyles.SplashCopySerifStyle} {
    flex-flow: column;
    a {
      margin-left: 0;
      margin-top: 0.5em;
    }
  }
`;

export const ActiveTasksContainerStyle = styled.div.attrs({
  className: 'ActiveTasksContainerStyle',
})`
  margin-bottom: 0;
  ${ButtonStyles.ButtonLinkBorderedStyle} {
    background: white;
  }
  @media (min-width: ${({ theme }) => theme.responsive.tabletBreakpoint}) {
    ${ButtonStyles.ButtonLinkBorderedStyle} {
      padding-left: ${taskLeftPaddingDesktop};
      box-shadow: 0px 0px 9px -3px ${({ theme }) => theme.line.color.default};
    }
    ${ButtonStyles.ButtonContainerStyle} {
      &.borderedLink + ${ButtonStyles.ButtonContainerStyle}.borderedLink {
        margin-top: 0.5rem;
      }
    }
  }
`;

export const TaskListSectionStyle = styled(HeroContainerStyle).attrs({
  className: 'TaskListSectionStyle',
})<{ hasOpenTasks: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  gap: clamp(2rem, 5vh, 4rem);
  > * {
    width: 100%;
  }
  ${({ hasOpenTasks }) =>
    hasOpenTasks
      ? css`
          @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
            padding-bottom: 0;
            ${ActiveTasksContainerStyle} {
              ${ButtonStyles.ButtonBaseStyle} {
                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
        `
      : null}
`;

export const ReadOnlyCheckboxStyle = styled.div.attrs({ className: 'ReadOnlyCheckboxStyle' })`
  box-sizing: border-box;
  width: 1rem;
  height: 1rem;
  border: 2px solid ${({ theme }) => theme.line.color.default};
`;

export const itemGapDesktop = '0.75rem';
export const itemGapMobile = '1.25rem';

export const ActionItemContentRowStyle = styled.div.attrs({
  className: 'ActionItemContentRowStyle',
})`
  display: flex;
  align-items: center;
  gap: ${itemGapDesktop};

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    gap: ${itemGapMobile};
  }

  > ${ReadOnlyCheckboxStyle} {
    flex-shrink: 0;
  }
`;

export const FundingStatusSectionStyle = styled(BorderedSectionStyle).attrs({
  className: 'FundingStatusSectionStyle',
})`
  isolation: isolate;

  ${ButtonStyles.ButtonContainerStyle} {
    margin-top: 66px;

    @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
      margin-top: 48px;
    }
  }
`;

export const CardGridStyle = styled.div.attrs({ className: 'CardGridStyle' })`
  display: grid;
  gap: ${sectionMargins};
  grid-template-columns: 1fr 1fr;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    grid-template-columns: 1fr;
  }
`;

export const CardHeaderStyle = styled(Header).attrs({
  className: 'CardTitleStyle',
})`
  > * {
    font-size: inherit;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    margin-bottom: 1.125rem;
  }
`;

export const CardStyle = styled.div.attrs({ className: 'CardStyle' })<{
  isFlatStyle?: boolean;
  withImage?: boolean;
  isClickable?: boolean;
  hoverColor?: null | Color;
}>`
  --text-color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  display: block;
  text-align: left;
  white-space: normal;
  text-decoration: none;
  position: relative;
  background: ${({ theme, isFlatStyle = false }) =>
    isFlatStyle ? theme.Color.GrayLight1 : theme.Color.White};

  ${({ theme, isFlatStyle = false }) =>
    !isFlatStyle
      ? css`
          border: ${theme.line.color.default} 1px solid;
          box-shadow: 0px 0px 15px -3px ${theme.line.color.default};
        `
      : css`
          border: none;
        `};

  color: var(--text-color);

  font-family: ${({ theme }) => theme.fonts.sansSerif.name};
  font-size: 1rem;
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    font-size: 1.125;
  }

  ${({ withImage = false }) =>
    withImage
      ? css`
          padding: calc(6% + 1.25rem) calc(9% + 0.75rem);
        `
      : css`
          padding: 12.5% calc(20% - 1.5rem);
        `};

  ${CardHeaderStyle} {
    margin-bottom: ${({ withImage }) => (withImage ? '1.3rem' : '3.2rem')};
  }

  > p {
    margin: 0;
  }

  transition:
    transform 250ms ease-in-out,
    border-color 250ms ease-in-out;

  ${({ hoverColor = null, isClickable = false }) =>
    isClickable
      ? css`
          cursor: pointer;
          ${mixins.desktopHoverCondition()} {
            &:hover {
              transform: scale(1.01);
              color: var(--text-color);
              ${hoverColor != null
                ? `
                  border: ${hoverColor} 1px solid;
                `
                : ''};

              ${PlayButtonStyle} {
                transform: scale(1.1);
              }
            }
          }
        `
      : ''}
`;

export const CardImageStyle = styled.div.attrs({ className: 'CardImageStyle' })<{ src: string }>`
  background: no-repeat url(${({ src }) => src}) center center / cover;
  position: relative;
  aspect-ratio: 378 / 159;
  margin-bottom: 2.5rem;

  ${PlayButtonStyle} {
    position: absolute;
    bottom: -1.25rem;
    left: 7.5%;
  }
`;

export const HelpCenterCardStyle = styled(CardStyle).attrs({ className: 'HelpCenterCardStyle' })`
  grid-column: span 2;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  > section {
    flex: auto;
    display: flex;
    flex-flow: row nowrap;
    gap: 5%;
  }
  ${LifeSaverIconStyle} {
    transition: all 250ms ease-in-out;
  }
  &:hover {
    ${LifeSaverIconStyle} {
      stroke: ${({ theme }) => theme.Color.Gray3};
    }
  }
  ${ButtonStyles.ButtonBaseStyle} {
    color: inherit;
  }
  @media (min-width: ${({ theme }) => theme.responsive.tabletBreakpoint}) {
    padding: calc(3% + 1.25rem) calc(4.2% + 0.75rem);
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    > * {
      width: 100%;
    }
    section {
      gap: 2rem;
    }
    gap: 2.5rem;
    flex-flow: column;
    grid-column: span 1;
    > section {
      flex-flow: column;
    }
  }
`;

export const AccountManagerSectionWrapper = styled(BorderedSectionStyle).attrs({
  className: 'AccountManagerSectionWrapper',
})`
  padding: 6.25rem 0;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    padding: 54px 0;
  }
`;

export const AccountManagerSectionStyle = styled(Container).attrs({
  className: 'AccountManagerSectionStyle',
})`
  // Push the AM detail over a bit when the photo is missing
  --gap: 5rem;
  &:not(:has(img)) {
    --gap: 10rem;
  }

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap);
  align-items: center;

  a {
    ${({ theme }) => theme.globals.linkInvertedCSS}
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    gap: ${(props) => props.theme.container.gutter.default};
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    grid-template-columns: 1fr;
  }
`;

export const QuestionHeaderStyle = styled(Header).attrs({
  className: 'QuestionHeaderStyle',
})<{ shortHeadline?: boolean }>`
  > h2 {
    ${({ shortHeadline }) =>
      shortHeadline
        ? css`
            font-size: ${pxToRem(45)};
            @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
              font-size: ${pxToRem(36)};
            }
          `
        : css`
            font-size: clamp(2.2rem, 2.5vw, 3.5rem);
            @media (max-width: ${({ theme }) => theme.responsive.mobileMidBreakpoint}) {
              font-size: clamp(1.8rem, 2.5vw, 3rem);
            }
          `}
  }
`;

export const AccountManagerDetailStyle = styled.div.attrs({
  className: 'AccountManagerDetailStyle',
})`
  display: flex;
  gap: 3rem;
  --image-size: clamp(100px, 20vw, 180px);
  align-items: center;

  > img {
    width: var(--image-size);
    height: var(--image-size);
    border-radius: 50%;
    object-fit: cover;
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    flex-direction: column;
    gap: 2rem;
    align-items: start;
  }
`;

export const ContactMethodsStyle = styled.div.attrs({
  className: 'ContactMethodsStyle',
})`
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
`;

export const ContactMethodsDividedStyle = styled.div.attrs({
  className: 'ContactMethodsDividedStyle',
})`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-self: center;

  div {
    border-right: ${({ theme }) => `1px solid ${theme.line.color.default}`};
  }

  @media (max-width: ${({ theme }) => theme.responsive.mobileMidBreakpoint}) {
    flex-direction: column;
    text-align: center;

    div {
      width: 100%;
      align-self: center;
      border-bottom: ${({ theme }) => `1px solid ${theme.line.color.default}`};
    }
  }
`;

export const ChatWithAccountManagerStyle = styled.div.attrs({
  className: 'ChatWithAccountManagerStyle',
})`
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
`;

export const ChatButtonStyle = styled(Button).attrs({ styleType: Style.Quaternary })`
  ${ButtonStyles.ButtonContentStyle} {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    justify-content: center;
  }
  button {
    width: 100%;
    background-color: ${({ theme }) => theme.Color.PurpleBlue5};

    &:hover {
      background-color: ${({ theme }) => theme.Color.PurpleBlue6};
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${ChatIconStyle} {
      --size: clamp(2em, 5vw, 3em);
      width: var(--size);
      height: var(--size);
    }
  }
`;

export const DividerStyle = styled.div.attrs({ className: 'DividerStyle' })`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 1rem;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};

  div {
    height: 1px;
    background-color: ${({ theme }) => theme.line.color.default};
  }
`;

export const ReducedAccountManagerDetailStyle = styled.div.attrs({
  className: 'ReducedAccountManagerDetailStyle',
})`
  display: flex;
  gap: 1rem;
  align-self: center;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    gap: 1.5rem;
    flex-direction: column;
    align-items: center;
  }

  img {
    --image-size: 50px;
    width: var(--image-size);
    height: var(--image-size);
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const AccountManagerContactInfoStyle = styled.div.attrs({
  className: 'AccountManagerContactInfoStyle',
})`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  span {
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  }

  ${ReducedAccountManagerDetailStyle} & {
    gap: 0.5rem;

    @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
      gap: 1rem;
    }

    span {
      display: inline-block;
      font-weight: bold;
      white-space: nowrap;

      @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
        padding-inline: 2rem;
        text-align: center;
        white-space: pretty;
      }
    }
  }
`;

export const DividerWithImageStyle = styled.div.attrs({ className: 'DividerWithImageStyle' })`
  display: flex;
  margin: 3.5rem 0;

  &::before {
    display: block;
    content: '';
    height: 1px;
    flex-grow: 1;
    background: ${({ theme }) => theme.line.color.default};
  }

  > div {
    position: relative;
    width: 224px;

    > img {
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      top: 50%;
      transform: scaleX(-1) translateY(-66%);
      z-index: -1;
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    display: none;
  }
`;

export const FundingStatusRowStyle = styled.div.attrs({ className: 'FundingStatusRowStyle' })`
  display: flex;
  gap: 2rem;
  align-items: center;

  > hr {
    width: 1px;
    height: auto;
    margin: 0;
    align-self: stretch;
    background: ${({ theme }) => theme.line.color.default};
  }

  > * {
    &:first-child {
      padding: 0 ${taskLeftPaddingDesktop};
    }
  }
  ${TasklistStyle} {
    flex-shrink: 0;
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    > * {
      &:first-child {
        padding: 0;
      }
    }
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
    > hr {
      display: none;
    }
  }
`;

export const RecentActivitySummaryStyle = styled.div.attrs({
  className: 'RecentActivitySummaryStyle',
})`
  display: flex;
  flex-flow: column;
  flex: 3;
  justify-content: center;
  align-items: center;
  padding: 0 8%;
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  a {
    ${({ theme }) => theme.globals.linkInvertedCSS}
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    justify-content: flex-start;
    width: auto;
    padding: 0;
  }

  aside {
    flex: 1;
    margin-top: 1.5em;
    font-size: 0.875rem;
    font-style: italic;
    width: 100%;
  }
`;

export const FundingStatusMobileRowWithImage = styled.div.attrs({
  className: 'FundingStatusMobileRowWithImage',
})`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;

  > * {
    flex-shrink: 0;
  }
  > div {
    width: 40%;
    max-width: 225px;
    flex-shrink: 1;
    position: relative;

    > img {
      position: absolute;
      left: 0;
      width: 100%;
      top: 50%;
      transform: scaleX(-1) translateY(-60%);
      z-index: -1;
    }
  }
`;
