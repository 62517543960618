import styled, { css } from 'styled-components';
import { ringsCSS } from '../RingsGraphic/styles';
import { ShieldGraphicStyle } from '../ShieldGraphic/styles';
import hillImage from '../TandemBikeGraphic/images/hill.png';
import capHouseGoldImage from '../images/caphousegold.png';
import capHouseGoldFadedImage from '../images/caphousegoldfaded.png';
import { CapHouseGraphicMode, CapHouseGraphicProps } from './constants';
import capHouseGreyImage from './images/caphousegrey.png';
import capHouseGreyFadedImage from './images/caphousegreyfaded.png';
import smallCoinsImage from './images/smallcoins.png';

export const ANIM_SPEED_MS = 500;

export const MiniCoinsStyle = styled.div.attrs({ className: 'MiniCoinsStyle' })`
  transition: all ${ANIM_SPEED_MS}ms ease-in-out;
  --miniCoinsSize: 33%;
  background-image: url('${smallCoinsImage}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: absolute;
  width: var(--miniCoinsSize);
  padding-bottom: var(--miniCoinsSize);
  position: absolute;
  bottom: 90.5%;
  left: 28%;
  z-index: -1;
  transform: translateY(50%);
`;

export const CapHouseGoldStyle = styled.div.attrs({ className: 'CapHouseGoldStyle' })`
  transition: all ${ANIM_SPEED_MS}ms ease-in-out;
  background-image: url('${capHouseGoldImage}');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: absolute;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    background-image: url('${capHouseGoldFadedImage}');
  }
`;

export const CapHouseGraphicStyle = styled.div.attrs({
  className: 'CapHouseGraphicStyle',
})<CapHouseGraphicProps>`
  --valuePosition: 35%;
  --valueChangePosition: 35%;
  --shieldTopOffset: -50%;
  --capHouseSize: 28.5%;
  background-image: url('${capHouseGreyImage}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  width: var(--capHouseSize);
  padding-bottom: var(--capHouseSize);
  position: absolute;
  bottom: 0;
  right: 12%;
  &:after {
    transition: all ${ANIM_SPEED_MS}ms ease-in-out;
    content: '';
    position: absolute;
    display: block;
    width: 0;
  }
  &::before {
    content: '';
    ${ringsCSS};
    width: 90%;
    height: 60%;
    left: -43%;
    background-position: center -50%;
    z-index: -1;
  }
  ${ShieldGraphicStyle} {
    transition: all ${ANIM_SPEED_MS}ms ease-in-out;
    left: -90%;
    bottom: unset;
  }
  ${CapHouseGoldStyle} {
    clip-path: inset(var(--valueChangePosition) 0 0 0);
  }
  ${ShieldGraphicStyle} {
    top: calc(var(--shieldTopOffset) + var(--valuePosition));
    transform: scale(0.7);
  }
  ${({ mode }) => {
    if (mode === CapHouseGraphicMode.AtStartingValue) {
      return css`
        ${ShieldGraphicStyle} {
          transform: scale(1);
        }
      `;
    }
    if (mode === CapHouseGraphicMode.BelowStartingValue) {
      return css`
        --valuePosition: 35%;
        --valueChangePosition: 70%;
        &:after {
          --lineHeight: 2px;
          z-index: 1;
          top: var(--valueChangePosition);
          left: -40%;
          width: 100%;
          height: var(--lineHeight);
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 50%,
            rgba(255, 255, 255, 1) 70%,
            rgba(255, 255, 255, 0) 100%
          );
        }
      `;
    }
    if (mode === CapHouseGraphicMode.AboveStartingValue) {
      return css`
        --valueChangePosition: 0%;
        ${CapHouseGoldStyle} {
          clip-path: inset(0 0 calc(100% - var(--valuePosition)) 0);
        }
        ${MiniCoinsStyle} {
          transform: translateY(0);
        }
      `;
    }
  }}
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    background-image: url('${capHouseGreyFadedImage}');
  }
`;

export const CapHouseHillStyle = styled.div.attrs({ className: 'CapHouseHillStyle' })`
  --hillSize: 100%;
  display: none;
  background-image: url('${hillImage}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  width: var(--hillSize);
  padding-bottom: var(--hillSize);
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    display: block;
  }
`;
