import React, { useState } from 'react';
import {
  Button,
  DirectionAndPlacement,
  FormChangeEvent,
  HelpTextAnimated,
  Style,
  TextArea,
  Validation,
} from '@pointdotcom/pds';
import FollowUpModel from 'models/FollowUpModel';
import { useRejectFollowupMutation } from 'services/api/underwriteApi';
import i18n from './i18n';

interface RejectReasonFormProps {
  onAfterReject: (text: string) => void;
  followUp: FollowUpModel | null;
  followUpId: string;
}

const RejectReasonForm = ({ onAfterReject, followUp, followUpId }: RejectReasonFormProps) => {
  const [text, setText] = useState('');
  const [error, setError] = useState<null | string>(null);

  const [rejectFollowup, { isLoading: loading }] = useRejectFollowupMutation();

  const handleFocus = () => {
    setError(null);
  };

  const handleTextChange: FormChangeEvent = (e, { value }) => {
    setText(value);
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    if (text.replace(/\s+/g, '').length === 0) {
      setError(Validation.i18n.fieldRequired);
      return;
    }

    try {
      const csrfToken = followUp ? followUp.csrfToken : null;
      const response = await rejectFollowup({ followUpId, csrfToken, rejectReason: text }).unwrap();

      if (!response.success) {
        throw new Error();
      }

      onAfterReject(text);
    } catch (_) {
      setError(Validation.i18n.unknownError);
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <TextArea rows={5} onChange={handleTextChange} value={text} onFocus={handleFocus} />
      <Button
        type="submit"
        styleType={error ? Style.Error : Style.Primary}
        loading={loading}
        styleAlign={DirectionAndPlacement.Right}
      >
        {i18n.submit}
      </Button>
      <HelpTextAnimated
        show={!!error}
        styleMarginPosition={DirectionAndPlacement.Top}
        styleType={Style.Error}
        styleAlign={DirectionAndPlacement.Center}
      >
        {error}
      </HelpTextAnimated>
    </form>
  );
};

export default RejectReasonForm;
