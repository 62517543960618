import React from 'react';
import {
  Button,
  ButtonProps,
  Container,
  DirectionAndPlacement,
  Header,
  IconName,
  Size,
  Style,
  TemplatedText,
} from '@pointdotcom/pds';
import ConfettiBackground from 'components/ConfettiBackground';
import Sticky from 'components/Sticky';
import { useTaskCompletionModal } from 'containers/hooks/useTaskCompletionModal';
import { useGetQuizResultsQuery } from 'services/api/homeownerApi';
import {
  AnswersApiResponse,
  ApplicantStatus,
  AttemptStatus,
  QuizItemResponse,
} from 'services/apiTypes/quizTypes';
import { CompletedTask } from 'store/general';
import i18n from './i18n';
import imgDog from './images/partyDog.png';
import * as styles from './styles';

interface QuizScoreProps {
  quizData: AnswersApiResponse;
  onVideoClick: (videoPosition: number) => void;
  onDashboardClick: () => void;
  scheduleCallUrl: string;
}

const QuizScore = ({
  quizData,
  onVideoClick,
  onDashboardClick,
  scheduleCallUrl,
}: QuizScoreProps) => {
  const { attemptStatus, applicantStatus, score, maxScore } = quizData;
  const passedQuiz = attemptStatus === AttemptStatus.Passed;
  const quizPassedPreviously = applicantStatus === ApplicantStatus.Passed;
  const quizPassedPreviouslyThenFailedOnRetry =
    quizPassedPreviously && attemptStatus === AttemptStatus.Failed;
  const neverPassed = !passedQuiz && !quizPassedPreviously;
  const numMissed = maxScore - score;
  const completeTask = useTaskCompletionModal();

  let resultDescription = i18n.youOnly;
  if (!passedQuiz) {
    resultDescription = quizPassedPreviously ? i18n.previouslyPassed : i18n.youMissed;
  } else if (numMissed === 0) {
    resultDescription = i18n.perfectScore;
  }

  const buttonProps: ButtonProps = {
    iconType: IconName.ChevronRight,
    styleSize: Size.Small,
    minWidth: '100%',
    styleAlignText: DirectionAndPlacement.Left,
  };

  const resultText = (
    <TemplatedText
      values={{
        numMissed,
        numTotal: maxScore,
        answers: numMissed > 1 ? i18n.answers : i18n.answer,
      }}
    >
      {resultDescription}
    </TemplatedText>
  );

  const handleTaskCompleteClick = () => {
    const task: CompletedTask = {
      title: i18n.completeYourHomeowner,
      summary: i18n.congratulationsYouPassed,
    };
    completeTask(task);
  };

  return (
    <Sticky>
      <styles.QuizScoreStyle passedQuiz={passedQuiz}>
        <Container>
          {passedQuiz && (
            <aside>
              <ConfettiBackground size={{ width: 500, height: 350 }} numberOfPieces={100} />
              <img src={imgDog} alt="good dog" />
            </aside>
          )}
          <Header styleSize={Size.Large}>{passedQuiz ? i18n.niceJob : i18n.unfortunately}</Header>
          <p>{resultText}</p>

          {neverPassed ? (
            <>
              <Button {...buttonProps} content={i18n.tryAgain} onClick={() => onVideoClick(0)} />
              <Button
                {...buttonProps}
                content={i18n.proceedDashboard}
                styleType={Style.Tertiary}
                onClick={onDashboardClick}
              />

              <styles.ScheduleCallStyle>
                <div>{i18n.preferExpert}</div>
                <a href={scheduleCallUrl}>{i18n.scheduleACall}</a>
              </styles.ScheduleCallStyle>
            </>
          ) : (
            <>
              <Button
                {...buttonProps}
                content={
                  quizPassedPreviouslyThenFailedOnRetry
                    ? i18n.proceedDashboard
                    : i18n.completeThisTask
                }
                onClick={
                  quizPassedPreviouslyThenFailedOnRetry ? onDashboardClick : handleTaskCompleteClick
                }
              />
              <Button
                {...buttonProps}
                content={i18n.rewatchVideo}
                styleType={Style.Tertiary}
                onClick={() => onVideoClick(0)}
              />
            </>
          )}
        </Container>
      </styles.QuizScoreStyle>
    </Sticky>
  );
};

interface QuestionReportProps {
  questionNumber: number;
  quizResponse: QuizItemResponse;
  onVideoClick: () => void;
}

const QuestionReport = ({ quizResponse, questionNumber, onVideoClick }: QuestionReportProps) => {
  const { question, givenAnswerId, correctAnswerId, explanation } = quizResponse;
  const answeredCorrectly = givenAnswerId === correctAnswerId;
  return (
    <styles.QuestionReportStyle>
      <styles.IndicatorIconStyle
        name={answeredCorrectly ? IconName.Check : IconName.Close}
        color="white"
        styleSize={28}
        isCorrect={answeredCorrectly}
      />
      <Header styleSize={Size.Small}>
        <span>{`${questionNumber}.`}</span>
        <span>{question.text}</span>
      </Header>
      {question.answers.map((potentialAnswer) => (
        <styles.AnswersOptionWrapper key={potentialAnswer.id}>
          <input
            type="radio"
            tabIndex={-1}
            id={`answer${potentialAnswer.id}`}
            checked={potentialAnswer.id === givenAnswerId || potentialAnswer.id === correctAnswerId}
            data-correct={potentialAnswer.id === correctAnswerId}
            readOnly
          />
          <styles.OptionLabelStyle
            htmlFor={`answer${potentialAnswer.id}`}
            isCorrect={potentialAnswer.id === correctAnswerId}
            wasChosen={potentialAnswer.id === givenAnswerId}
          >
            {potentialAnswer.text}
          </styles.OptionLabelStyle>
        </styles.AnswersOptionWrapper>
      ))}
      <Button styleSize={Size.Small} styleType={Style.Tertiary} onClick={() => onVideoClick()}>
        {i18n.rewatchSection}
      </Button>
      {!answeredCorrectly && (
        <styles.CorrectionStyle>
          <span>{i18n.correctAnswer}</span>
          <span>{explanation}</span>
        </styles.CorrectionStyle>
      )}
    </styles.QuestionReportStyle>
  );
};

interface QuizResultProps {
  onVideoClick: (videoPosition: number) => void;
  onDashboardClick: () => void;
  scheduleCallUrl: string;
}

const QuizResult = ({ onVideoClick, onDashboardClick, scheduleCallUrl }: QuizResultProps) => {
  const { data } = useGetQuizResultsQuery();
  const quizData = data as AnswersApiResponse;

  if (!quizData) return null;

  return (
    <styles.QuizResultStyle>
      <Container mobileCollapse mobileColumn flex>
        <QuizScore
          quizData={quizData}
          onVideoClick={onVideoClick}
          onDashboardClick={onDashboardClick}
          scheduleCallUrl={scheduleCallUrl}
        />
        <styles.QuizSummaryColumnStyle>
          <styles.QuestionSummaryStyle>
            <styles.SummaryScrollStyle>
              <p>{i18n.yourResults}</p>
              <Container>
                <ul>
                  {quizData.responses.map((response, idx) => (
                    <QuestionReport
                      key={response.id}
                      quizResponse={response}
                      questionNumber={idx + 1}
                      onVideoClick={() => onVideoClick(response.question.videoReferencePosition)}
                    />
                  ))}
                </ul>
              </Container>
            </styles.SummaryScrollStyle>
          </styles.QuestionSummaryStyle>
        </styles.QuizSummaryColumnStyle>
      </Container>
    </styles.QuizResultStyle>
  );
};

export default QuizResult;
