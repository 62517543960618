import axios, { AxiosResponse } from 'axios';
import { YCBMCal } from 'components/ScheduleCalendar/constants';
import {
  getYCBMAPIDomain,
  getYCBMCalendarURLFromCalendar,
} from 'components/ScheduleCalendar/helpers';
import { Contact } from 'types';
import { Availability, Booking } from '../constants';

// YCBM API's
// https://github.com/pointdotcom/middleware-api/tree/master/api/youcanbook

export const BOOKING_CONFLICT_STATUS_CODE = 409;

export const defaultCalendarURL = getYCBMCalendarURLFromCalendar(YCBMCal.Prod);

const timeOut = 7000;

export const getScheduleAvailability = async ({
  apiDomain,
  filterNoImages = false,
  skipBusy = true,
  calendar,
  team,
}: {
  apiDomain: string;
  filterNoImages?: boolean;
  skipBusy?: boolean;
  calendar?: string | YCBMCal;
  team?: string;
}) => {
  const url = `${getYCBMAPIDomain(apiDomain)}/${calendar}/calendar`;
  const { data } =
    (await axios({
      method: 'get',
      url,
      params: {
        skipBusy,
        team,
      },
      withCredentials: false,
      timeout: timeOut,
    })) || {};
  const filtered = filterNoImages ? data?.filter(({ image }: { image?: string }) => !!image) : data;
  if (filtered?.length) {
    return filtered[0];
  }
  return null;
};

export const getPostScheduleData = ({
  contact,
  availability,
}: {
  contact?: Contact;
  availability?: Availability;
}) => {
  const now = new Date();
  const clientTimeZone = {
    string: new Date().toString(),
    offset: now.getTimezoneOffset(),
    offsetInJan: new Date(now.getFullYear(), 0, 1).getTimezoneOffset(),
    offsetInJul: new Date(now.getFullYear(), 6, 1).getTimezoneOffset(),
  };
  const postScheduleData = {
    form: {
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      email: contact?.email,
      phoneNumber: contact?.phone, // IMPORTANT: format matters
      comments: '',
    },
    memberId: availability?.memberId,
    timestamp: availability?.timestamp,
    calendar: availability?.calendar,
    clientTimeZone,
    pathname: window.location.pathname,
  };
  return postScheduleData;
};

export const postSchedule = async (
  data: typeof getPostScheduleData,
  { apiDomain, calendar }: { apiDomain: string; calendar: string }
) => {
  const url = `${getYCBMAPIDomain(apiDomain)}/${calendar}/schedule-form`;

  const response = await axios({
    method: 'post',
    data,
    url,
    timeout: timeOut,
  });

  return response as AxiosResponse<Booking>;
};

// Example response
/*

{
  "form": {
    "firstName": "rob",
    "lastName": "cox",
    "email": "testgroup@point.com",
    "phoneNumber": "4084313521",
    "comments": ""
  },
  "memberId": "m317943",
  "timestamp": "1679351400000",
  "calendar": "e296c0a5-671c-4d75-9b9d-74f183f94542",
  "clientTimeZone": {
    "string": "Mon Mar 20 2023 14:43:50 GMT-0700 (Pacific Daylight Time)",
    "offset": 420,
    "offsetInJan": 480,
    "offsetInJul": 420
  },
  "pathname": "/hei/offer/CA2Gdw_b9FMVQx63w/estimates/E2Pi9l6t3iBfqmBA/estimator"
}
*/
