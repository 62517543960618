import React from 'react';
import { Accordion, AccordionItemObj } from '@pointdotcom/pds';

interface ContextItem {
  headline: string;
  body: React.ReactNode;
}

interface ContextHelpProps {
  contextHelp: Array<ContextItem>;
}

export default function ContextHelp({ contextHelp }: ContextHelpProps) {
  const items: Array<AccordionItemObj> = contextHelp.map(({ headline, body }) => ({
    id: headline,
    title: headline,
    content: body,
  }));
  return <Accordion items={items} />;
}
