import React from 'react';
import { directory } from '@pointdotcom/pds';
import * as styles from './styles';

const BBB = ({ className, bordered }) => (
  <styles.BBBStyle
    bordered={bordered}
    href={directory.pointLink.bbb}
    target="_blank"
    rel="noopener noreferrer"
    className={className}
  >
    <div>
      <svg
        width="46"
        height="69"
        viewBox="0 0 46 69"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M34.0098 43.2654H7.9962L7.0437 46.4313H13.9833L15.304 50.7937H26.71L28.0226 46.4313H34.9623L34.0098 43.2654Z"
            fill="black"
            fillOpacity="0.55"
          />
          <path
            d="M12.2223 25.4986C10.2293 28.2983 10.8376 32.2049 13.583 34.2396L19.8183 38.8379C20.5066 39.3507 20.6587 40.3273 20.1545 41.0354L21.091 41.7272L24.4447 37.1044C25.2051 36.0382 25.6133 34.7523 25.6133 33.4338C25.6133 33.1002 25.5893 32.7665 25.5333 32.4409C25.2771 30.8213 24.3967 29.3726 23.092 28.4123L16.8728 23.8139C16.5366 23.5697 16.3125 23.2035 16.2484 22.7884C16.2404 22.707 16.2404 22.6256 16.2484 22.5361C16.2484 22.2106 16.3445 21.8931 16.5366 21.6246L15.6001 20.9165L12.2223 25.4986Z"
            fill="black"
            fillOpacity="0.55"
          />
          <path
            d="M15.9924 6.99929C14.8878 8.54565 14.2875 10.4013 14.2955 12.3139C14.2955 12.7778 14.3275 13.2417 14.4075 13.6975C14.7757 16.074 16.0644 18.2063 17.9774 19.6224L25.7975 25.4335C26.83 26.1904 27.5184 27.3298 27.7105 28.6076C27.7345 28.868 27.7345 29.1203 27.7105 29.3808C27.7025 30.4062 27.3823 31.3992 26.79 32.2212L27.5344 32.7909L33.7136 24.1231C36.6031 20.0619 35.7147 14.3974 31.7286 11.4512L22.2276 4.46001C21.027 3.54034 20.7949 1.80679 21.6913 0.594126C21.6994 0.577849 21.7074 0.56971 21.7234 0.553432L20.979 0L15.9924 6.99929Z"
            fill="black"
            fillOpacity="0.55"
          />
          <path
            d="M0 53.4388H7.13172C8.6205 53.3249 10.0853 53.8213 11.2058 54.8224C11.8942 55.5223 12.2624 56.4827 12.2224 57.4756C12.2704 58.908 11.47 60.2265 10.1973 60.845C11.9262 61.2682 13.1349 62.8716 13.0788 64.6865C13.0788 67.5269 10.7976 68.9675 7.32382 68.9675H0V53.4388ZM6.41135 59.7544C7.90813 59.7544 8.84461 59.2743 8.84461 58.1104C8.84461 57.0849 8.06021 56.5071 6.62746 56.5071H3.28972V59.8033L6.41135 59.7544ZM7.30781 66.0457C8.80459 66.0457 9.70907 65.5085 9.70907 64.3609C9.70907 63.3111 8.93266 62.6762 7.19575 62.6762H3.28972V66.1108L7.30781 66.0457Z"
            fill="black"
            fillOpacity="0.55"
          />
          <path
            d="M14.5276 53.4388H21.7233C23.2121 53.3249 24.6769 53.8213 25.7975 54.8224C26.4938 55.5223 26.87 56.4827 26.846 57.4756C26.886 58.908 26.0936 60.2265 24.821 60.845C26.5499 61.2682 27.7505 62.8716 27.6865 64.6865C27.6865 67.5269 25.4053 68.9675 21.9395 68.9675H14.5276V53.4388ZM20.9389 59.7544C22.4197 59.7544 23.3722 59.2743 23.3722 58.1104C23.3722 57.0849 22.5878 56.5071 21.155 56.5071H17.8173V59.8033L20.9389 59.7544ZM21.8354 66.0457C23.3322 66.0457 24.2286 65.5085 24.2286 64.3609C24.2286 63.3111 23.4683 62.6762 21.7313 62.6762H17.8173V66.1108L21.8354 66.0457Z"
            fill="black"
            fillOpacity="0.55"
          />
          <path
            d="M29.0552 53.4388H36.1869C37.6757 53.3167 39.1404 53.8213 40.261 54.8224C40.9654 55.5142 41.3496 56.4745 41.3176 57.4756C41.3576 58.908 40.5652 60.2265 39.2925 60.845C41.0134 61.3008 42.182 62.9204 42.086 64.7272C42.086 67.5676 39.8048 69.0081 36.339 69.0081H29.0552V53.4388ZM35.4505 59.7544C36.9473 59.7544 37.8918 59.2743 37.8918 58.1104C37.8918 57.0849 37.1074 56.5071 35.6746 56.5071H32.3369V59.8033L35.4505 59.7544ZM36.363 66.0457C37.8598 66.0457 38.7562 65.5085 38.7562 64.3609C38.7562 63.3111 37.9798 62.6762 36.2589 62.6762H32.3449V66.1108L36.363 66.0457Z"
            fill="black"
            fillOpacity="0.55"
          />
          <path
            d="M43.2146 67.535C43.2146 66.7537 43.8389 66.1189 44.6073 66.1189C45.3757 66.1189 46 66.7537 46 67.535C46 68.3164 45.3757 68.9512 44.6073 68.9512C43.8389 68.9512 43.2146 68.3164 43.2146 67.535ZM45.84 67.535C45.912 66.8432 45.4157 66.2247 44.7434 66.1515C44.063 66.0782 43.4547 66.5828 43.3827 67.2665C43.3747 67.356 43.3747 67.4455 43.3827 67.5269C43.3106 68.2187 43.8069 68.8372 44.4793 68.9105C45.1596 68.9837 45.7679 68.4791 45.84 67.7955C45.848 67.7141 45.848 67.6246 45.84 67.535ZM44.031 66.7537H44.6954C44.8635 66.7456 45.0235 66.8026 45.1436 66.9165C45.2316 67.006 45.2797 67.1281 45.2637 67.2502C45.2717 67.4618 45.1436 67.649 44.9515 67.7222L45.3037 68.2594H44.9275L44.6153 67.7792H44.3592V68.2594H44.031V66.7537ZM44.6954 67.4862C44.8555 67.4862 44.9435 67.4048 44.9435 67.2827C44.9435 67.1444 44.8474 67.063 44.6874 67.063H44.3592V67.5025L44.6954 67.4862Z"
            fill="black"
            fillOpacity="0.55"
          />
        </g>
      </svg>
    </div>
    <div>
      <svg
        width="53"
        height="31"
        viewBox="0 0 53 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.592 23.2115H8.41722L5.52202 31H0L12.1257 0H18.2251L30.3345 31H24.5441L21.592 23.2115ZM10.2633 18.362H19.754L15.0127 5.68347L10.2633 18.362Z"
          fill="black"
          fillOpacity="0.55"
        />
        <path
          d="M40.3782 16.176V7.65082H44.721V16.176H53V20.2969H44.721V28.9112H40.3782V20.3941H32.0992V16.2732L40.3782 16.176Z"
          fill="black"
          fillOpacity="0.55"
        />
      </svg>
    </div>
  </styles.BBBStyle>
);

export default BBB;
