import styled, { keyframes } from 'styled-components';

const twinkle = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    filter: blur(1px);
  }
`;

const scale = keyframes`
    0%, 100% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1.2); /* Scale up to 1.2 times the original size */
    }
`;

export const TwinkleGraphicStyle = styled.div.attrs({ className: 'TwinkleGraphicStyle' })`
  --twinkleSize: 8px; /* Adjust size as needed */
  position: absolute;
  display: block;
  width: var(--twinkleSize);
  height: var(--twinkleSize);
  animation: ${scale} 2s infinite ease-in-out;
  svg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    animation:
      ${twinkle} 2s infinite ease-in-out,
      ${spin} 10s linear infinite;
  }

  path {
    fill: var(--graphicsHighlightColor); /* Star color */
  }

  &:first-child {
    svg {
      animation-duration: 2500ms;
    }
  }
  &:nth-child(2) {
    svg {
      animation-duration: 3500ms;
    }
  }
  &:last-child {
    svg {
      animation-duration: 5500ms;
    }
  }
`;
