import { mapImagePaths } from '../util';
import animationData from './anim.json';
import img0 from './img/img_0.png';
import img1 from './img/img_1.png';
import img2 from './img/img_2.png';
import img3 from './img/img_3.png';
import img4 from './img/img_4.png';
import img5 from './img/img_5.png';
import img6 from './img/img_6.png';

const imageMap = {
  'img_0.png': img0,
  'img_1.png': img1,
  'img_2.png': img2,
  'img_3.png': img3,
  'img_4.png': img4,
  'img_5.png': img5,
  'img_6.png': img6,
};

export default mapImagePaths(animationData, imageMap);
