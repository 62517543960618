import styled from 'styled-components';

export const ReadOnlyFlagStyle = styled.div.attrs({ className: 'ReadOnlyFlagStyle' })`
  position: fixed;
  right: 0;
  bottom: 0;
  background: ${(props) => props.theme.Color.Red};
  color: white;
  padding: 1em 2em;
  text-transform: uppercase;
  z-index: 10;
  animation: ${(props) => props.theme.animations.blink} 2s ease-in-out infinite;
`;
