export default {
  scheduleACall: 'Schedule a call',
};

export const heiFAQs = {
  q1: 'What exactly is a Home Equity Investment?',
  a1: 'A Home Equity Investment (HEI) lets you unlock your home equity with no monthly payments. Instead, you get cash in exchange for a share of your future home appreciation.  Point is your equity partner, profiting in your home appreciation as an investor. This structure means our incentives are aligned—we do well when you do well.',

  q2: 'What if my home loses value?',
  a2: 'In that case, your cost may be lower. In some extreme cases, Point may even lose money, and you’d pay back less than what you received. To protect our investment from such temporary market downswings, we build a cushion into the appreciation calculation. Generally we start calculating appreciation at a value about 15-20% lower than your current home value.',

  q3: 'How does the cost compare to a traditional loan?',
  a3: 'With Point’s HEI, your cost is proportional to the change in value of your home. Meanwhile, with a traditional loan, you pay a set amount every month. You can use Point’s calculator tool to see what your costs might be, based on different home appreciation scenarios.',

  q4: 'What’s the maximum I might pay?',
  a4: 'If your home appreciates a lot during the term, we’ll cap your costs so that you never pay more than you would on a traditional loan with an 18-20% APR.',

  q5: 'Will you own part of my home?',
  a5: 'No. You remain the sole owner, and Point is not added to the title. Similar to how a mortgage works, our stake in your home is secured by a lien on the property.',

  q6: 'What if I don’t want to sell or refinance?',
  a6: 'If you have another means of repaying (for example, personal savings), that’s okay too. Since it is one lump sum payment, most homeowners find it easiest to handle the repayment via a home sale transaction or refinance.',
};
