export default {
  submit: 'Submit',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email address',
  phone: 'Phone number',
  invalidInput: 'Invalid input',
  byClicking:
    'By clicking the “%buttonText%” button, you are  authorizing a soft credit inquiry and agreeing to the following:',
  optional: '(optional)',
  termsAndConditions: 'Terms and conditions:',
};
