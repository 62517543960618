/* eslint-disable camelcase */
import nullthrows from 'nullthrows';
import { DEFAULT_PROCESSING_FEE_PERCENT, SourceOrigin } from 'store/constants';
import { Estimate, Expand } from 'types';
import BaseEstimateModel from './BaseEstimateModel';
import { PayOff } from './ClosingCostsModel';
import PricingModel from './PricingModel';
import { currencyMask, normalizeProps } from './helpers';

export interface FollowUpEstimate extends Estimate {
  cashToClose?: number;
  originationFeeRate?: number;
}

type OwnerPaid = Record<string, { label: string; value: number }>;

export interface SteppedPricingItem {
  optionInvestmentAmount: number;
  optionPercentage: number;
  cashToClose: number;
}

interface SteppedPricingProps {
  option_investment_amount: number;
  option_percentage: number;
  is_eligible: boolean;
  cash_to_close: number;
}

export interface FollowUpEstimateProps extends FollowUpEstimate {
  // normalized props
  risk_adjustment?: number;
  prepayment?: number;
  option_percentage?: number;
  cap_percentage?: number;
  term?: number;
  fee_rate?: number;
  appraised_property_value?: number;
  debt_payoff_amount?: number;
  debt_payoff?: null | Array<PayOff>;
  owner_paid: OwnerPaid;
  cash_to_close?: number;
  stepped_pricing: Array<SteppedPricingProps>;
}

class FollowUpEstimateModel extends BaseEstimateModel implements FollowUpEstimate {
  key = '';

  steppedPricing: Array<SteppedPricingItem> = [];

  originationFeeRate: TSFixMe;

  cashToClose: TSFixMe;

  preliminary: TSFixMe;

  source = {
    origin: SourceOrigin.Underwrite,
  };

  constructor(props: Expand<FollowUpEstimateProps>) {
    // reshape followUp estimates to offer estimates
    const revisedProps = normalizeProps<FollowUpEstimate>(props, [
      {
        oldKey: 'maximum_possible_option_payment',
        newKey: 'maxOptionAmount',
      },
      {
        oldKey: 'cash_to_close',
        newKey: 'cashToClose',
      },
      {
        oldKey: 'valid_until',
        newKey: 'expires',
      },
      {
        oldKey: 'risk_adjustment',
        newKey: 'pricing.riskAdjustment',
      },
      {
        oldKey: 'prepayment',
        newKey: 'pricing.optionInvestmentAmount',
      },
      {
        oldKey: 'option_percentage',
        newKey: 'pricing.optionPercentage',
      },
      {
        oldKey: 'cap_percentage',
        newKey: 'pricing.capPercentage',
      },
      {
        oldKey: 'term',
        newKey: 'pricing.termYears',
      },
      {
        oldKey: 'fee_rate',
        newKey: 'pricing.originationFeeRate',
      },
      {
        oldKey: 'appraised_property_value',
        newKey: 'pricing.homeValue',
      },
      {
        oldKey: 'debt_payoff_amount',
        newKey: 'pricing.closingCosts.totalPayoffAmount',
      },
      {
        oldKey: 'debt_payoff',
        newKey: 'pricing.closingCosts.totalPayoffs',
      },
      {
        oldKey: 'owner_paid',
        newKey: 'pricing.closingCosts.fees',
        newValue: props.owner_paid
          ? Object.entries(props.owner_paid as OwnerPaid).map(([key, { value, ...val }]) => {
              const reshaped = { ...val, key, amount: value };
              return reshaped;
            })
          : [],
      },
      {
        oldKey: 'stepped_pricing',
        newKey: 'steppedPricing',
        newValue: props.stepped_pricing
          ? Object.entries(props.stepped_pricing).map(([, steppedPricing]) => {
              return normalizeProps(steppedPricing, [
                {
                  oldKey: 'option_investment_amount',
                  newKey: 'optionInvestmentAmount',
                },
                {
                  oldKey: 'option_percentage',
                  newKey: 'optionPercentage',
                },
                { oldKey: 'cash_to_close', newKey: 'cashToClose' },
              ]);
            })
          : [],
      },
    ]);
    super(revisedProps);
    Object.assign(this, revisedProps);
  }

  getPricing(): PricingModel | null {
    if (this.pricing) {
      return new PricingModel(this.pricing);
    }
    return null;
  }

  isBelowMaximumOffer(): boolean {
    const pricing = this.getPricing();
    if (!pricing) {
      return false;
    }
    const optionInvestmentAmount = pricing.getOptionInvestmentAmount();
    const maxOfferAmount = this.getMaxOptionAmount();
    return optionInvestmentAmount < maxOfferAmount;
  }

  getFormattedMaxPossibleOptionPayment(): string {
    return currencyMask.getFormatted(this.getMaxOptionAmount());
  }

  getFormattedCashToClose(): string {
    return currencyMask.getFormatted(this.cashToClose);
  }

  getFormattedTotalPayoffAmount(): string {
    return currencyMask.getFormatted(nullthrows(this.pricing?.closingCosts?.totalPayoffAmount));
  }

  // It seems that the current absolute floor for processing fee is $1,200, and is not accounted for by fee_rate from the backend.
  // So fee_rate as a percentage from the backend might be 3.0% but the $1,200 processing fee on a $35,000 offer is something like 3.4%
  // Therefore fee_rate from the backend is the proposed fee_rate, but the actual fee_rate is derived from
  // something basically like (Math.max(processing_fee, processing_fee_floor) / offerAmount)
  isDefaultProcessingFee(): boolean {
    const pricing = this.getPricing();
    if (!pricing) {
      return false;
    }
    const PRECISION = 2;
    // set up a power of 10 to multiply floats by for accurate comparison, because IEEE 754 floats are inaccurate
    // eslint-disable-next-line no-restricted-properties
    const INTEGER_COMPARISON_SCALE_VALUE = Math.pow(10, PRECISION);

    const defaultProcessingFeePerc = parseFloat(DEFAULT_PROCESSING_FEE_PERCENT.toFixed(PRECISION));

    // TypeScript error: Property 'ownerPaid' does not exist on type 'ClosingCostsModel'
    const processingFee = (pricing.closingCosts as TSFixMe)?.ownerPaid?.origination_fee?.value;
    const pointOffer = pricing.getOptionInvestmentAmount();

    // if there is a processing fee, check if it is greater than the default fee % as a percentage of the offer
    if (processingFee) {
      const feeAsPercentageOfOffer = parseFloat(
        ((processingFee / pointOffer) * 100).toFixed(PRECISION)
      );
      if (
        feeAsPercentageOfOffer * INTEGER_COMPARISON_SCALE_VALUE >
        defaultProcessingFeePerc * INTEGER_COMPARISON_SCALE_VALUE
      ) {
        return false;
      }
    }

    // if there is not a fee, check the written fee_rate
    return (
      // TypeScript error: Object is possibly 'undefined'.
      parseFloat((pricing.originationFeeRate as TSFixMe).toFixed(PRECISION)) *
        INTEGER_COMPARISON_SCALE_VALUE ===
      DEFAULT_PROCESSING_FEE_PERCENT * INTEGER_COMPARISON_SCALE_VALUE
    );
  }
}

export default FollowUpEstimateModel;
