import React, { useEffect, useRef } from 'react';
import { parseToRgb } from 'polished';
import { useTheme } from 'styled-components';
import * as styles from './styles';

const Rain = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const theme = useTheme();

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Set up canvas dimensions
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    // Parse hex color to RGB
    const dropColorHex = theme.Color.Gray3;
    const { red, green, blue } = parseToRgb(dropColorHex);
    const dropColorRgb = `${red}, ${green}, ${blue}`;

    // Raindrop properties
    interface Raindrop {
      x: number;
      y: number;
      length: number;
      speed: number;
      opacity: number;
    }

    const numberOfDrops = 200;
    const dropSizeMin = 15; // Minimum drop length
    const dropSizeMax = 70; // Maximum drop length
    const dropThickness = 2;

    const raindrops: Raindrop[] = Array.from({ length: numberOfDrops }, () => ({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      length: Math.random() * (dropSizeMax - dropSizeMin) + dropSizeMin,
      speed: Math.random() * 2 + 2,
      opacity: 0, // Start fully transparent for fade-in
    }));

    // Draw raindrops
    const drawRain = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      raindrops.forEach((drop) => {
        ctx.strokeStyle = `rgba(${dropColorRgb}, ${drop.opacity})`;
        ctx.lineWidth = dropThickness;
        ctx.beginPath();
        ctx.moveTo(drop.x, drop.y);
        ctx.lineTo(drop.x, drop.y + drop.length);
        ctx.stroke();
      });
    };

    // Update raindrop positions and opacity
    const updateRain = () => {
      raindrops.forEach((drop) => {
        /* eslint-disable no-param-reassign */
        drop.y += drop.speed;

        const distanceFromTop = drop.y;
        const distanceToBottom = canvas.height - drop.y;

        // Fade in as the raindrop appears near the top
        if (distanceFromTop < 100) {
          drop.opacity = Math.min(1, distanceFromTop / 100);
        }

        // Fade out as the raindrop approaches the bottom
        if (distanceToBottom < 100) {
          drop.opacity = Math.min(drop.opacity, distanceToBottom / 100);
        }

        // Reset position, size, speed, and opacity when raindrop goes off-screen
        if (drop.y > canvas.height) {
          drop.y = -drop.length;
          drop.x = Math.random() * canvas.width;
          drop.length = Math.random() * (dropSizeMax - dropSizeMin) + dropSizeMin;
          drop.speed = Math.random() * 2 + 2;
          drop.opacity = 0; // Reset opacity for fade-in
        }
      });
    };

    // Animation loop
    const animate = () => {
      drawRain();
      updateRain();
      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, [theme.Color.Gray3]);

  return <styles.RainStyle ref={canvasRef} />;
};

export default function TandemBikeDownGraphic() {
  return (
    <styles.TandemBikeDownGraphicStyle>
      <styles.CloudGraphicStyle>
        <Rain />
      </styles.CloudGraphicStyle>
    </styles.TandemBikeDownGraphicStyle>
  );
}
