import styled from 'styled-components';
import { BreadCrumbsStyle } from 'components/Breadcrumbs/styles';
import { HeroContainerStyle, MainPageStyle } from 'styles';

export const FollowUpPageStyle = styled(MainPageStyle)`
  ${HeroContainerStyle} {
    ${BreadCrumbsStyle} {
      display: none;
    }
  }
`;
