import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { useIsMobile } from '@pointdotcom/pds';
import OfferEstimateModel from 'models/OfferEstimateModel';
import { Products } from 'store/constants';
import Carousel from './Carousel';
import ScenariosTable from './ScenariosTable';
import { ScenarioType } from './ScenariosTable/scenarios';
import i18n from './i18n';

interface ScenariosTableAndCarouselProps {
  estimate: null | OfferEstimateModel;
  product?: Products;
  isFinalPricing?: boolean;
  showTablePreHeader?: boolean;
  getBuyBackTime: () => string | number;
}

export default function ScenariosTableAndCarousel({
  estimate,
  product = Products.HEI,
  isFinalPricing,
  showTablePreHeader = false,
  getBuyBackTime,
}: ScenariosTableAndCarouselProps) {
  const savedScroll = useRef(0);
  const [customScenarioPerc, setCustomScenarioPerc] = useState<string>('');
  const [activeScenarioCalc, setActiveScenarioCalc] = useState<null | ScenarioType>(null);
  const theme = useTheme();
  const { isMobile } = useIsMobile();

  let scrollTimeout: undefined | NodeJS.Timeout;

  useEffect(() => {
    return () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  }, [scrollTimeout]);

  const onCalcClick = (e: React.MouseEvent, { scenario }: { scenario: ScenarioType }) => {
    e.stopPropagation();
    savedScroll.current = window.scrollY;
    setActiveScenarioCalc(scenario);
  };
  const onCarouselClose = () => {
    setActiveScenarioCalc(null);
    if (isMobile) {
      scrollTimeout = setTimeout(() => {
        window.scrollTo(0, savedScroll.current);
      }, 0);
    }
  };

  if (!estimate) {
    return null;
  }

  return (
    <>
      <ScenariosTable
        estimate={estimate}
        onCalcClick={onCalcClick}
        product={product}
        preTableHeaderText={showTablePreHeader ? i18n.useTheControls : null}
        customScenarioPerc={customScenarioPerc}
        setCustomScenarioPerc={setCustomScenarioPerc}
        getBuyBackTime={getBuyBackTime}
      />
      <Carousel
        customScenarioPerc={customScenarioPerc}
        scenario={activeScenarioCalc}
        onCarouselClose={onCarouselClose}
        estimate={estimate}
        theme={theme}
        product={product}
        isFinalPricing={isFinalPricing}
        getBuyBackTime={getBuyBackTime}
      />
    </>
  );
}
