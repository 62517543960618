import styled from 'styled-components';
import { LinkButtonStyles } from '@pointdotcom/pds';

const DocumentViewerStyle = styled.div.attrs({ className: 'DocumentViewerStyle' })`
  background-color: rgb(50, 54, 57);
  padding: 5%;
  color: white;
  text-align: center;

  .pdfViewer {
    margin-bottom: 2em;
  }
`;

const ViewerErrorStyle = styled.div.attrs({ className: 'ViewerTextStyle' })`
  margin-bottom: 5rem;

  ${LinkButtonStyles.ButtonLinkStyle} {
    color: inherit;

    &:hover {
      color: whiteSmoke;
    }
  }
`;

const DownloadLinkStyle = styled.a.attrs({ className: 'DownloadLinkStyle' })`
  color: currentColor;
  margin: 5% auto 0 auto;
  display: block;

  &:hover {
    color: whiteSmoke;
  }
`;

export { DocumentViewerStyle, DownloadLinkStyle, ViewerErrorStyle };
