export interface FeatureFlagDefinition<TValues> {
  key: string;
  values: readonly TValues[];
}

export function defineFeatureFlag<TValues extends string | boolean>(
  key: string,
  values: readonly TValues[]
): FeatureFlagDefinition<TValues> {
  return {
    key,
    values,
  };
}
