import i18n from './i18n';

export enum ContentMode {
  PayoffAmount,
  PointsShare,
  TotalAppreciation,
  CappedPayoff,
  Summary,
}

export type ContentModeExcludingSummary = Exclude<ContentMode, ContentMode.Summary>;

enum ExpressionType {
  Plus = '+',
  Minus = '-',
  Multiply = '*',
  Versus = 'vs',
}

export enum ForwardNavigation {
  SeeTheMath,
  Summary,
  None,
}

export const expressionLabelTextMap = {
  [ExpressionType.Plus]: i18n.plus,
  [ExpressionType.Minus]: i18n.minus,
  [ExpressionType.Multiply]: i18n.times,
  [ExpressionType.Versus]: i18n.versus,
};

// ExitValues are the dynamic values which are inserted into the expression
export type ExitValues = {
  primaryAmount: number | string;
  operand1: number | string;
  operand2: number | string;
} & Record<string, string | null>;

interface ExpressionContent {
  headline: string;
  description: string;
  expressionType: ExpressionType;
  operand1Title: string;
  operand1Description?: string;
  operand2Title: string;
  operand2Description?: string;
  forwardNavigation: ForwardNavigation;
}

// this map holds the static content values for each type of expression modal
export const contentMap: Record<ContentModeExcludingSummary, ExpressionContent> = {
  [ContentMode.PayoffAmount]: {
    headline: i18n.payoffAmountHeadline,
    description: i18n.payoffAmountDescription,
    expressionType: ExpressionType.Plus,
    operand1Title: i18n.yourInvestmentAmount,
    operand1Description: i18n.originalLumpSum,
    operand2Title: i18n.pointsShare,
    forwardNavigation: ForwardNavigation.SeeTheMath,
  },
  [ContentMode.PointsShare]: {
    headline: i18n.pointsShareHeadline,
    description: i18n.pointsShareDescription,
    expressionType: ExpressionType.Multiply,
    operand1Title: i18n.heiPercentage,
    operand1Description: i18n.percentAppreciation,
    operand2Title: i18n.totalAppreciation,
    forwardNavigation: ForwardNavigation.SeeTheMath,
  },
  [ContentMode.TotalAppreciation]: {
    headline: i18n.totalAppreciationHeadline,
    description: i18n.totalAppreciationDescription,
    expressionType: ExpressionType.Minus,
    operand1Title: i18n.finalHomeValue,
    operand1Description: i18n.whenYouChoose,
    operand2Title: i18n.appreciationStartingValue,
    operand2Description: i18n.thisIsTheValue,
    forwardNavigation: ForwardNavigation.Summary,
  },
  [ContentMode.CappedPayoff]: {
    headline: i18n.payoffAmountHeadline,
    description: i18n.cappedPayoffDescription,
    expressionType: ExpressionType.Versus,
    operand1Title: i18n.appreciationBased,
    operand1Description: i18n.orignalInvestment,
    operand2Title: i18n.cappedRepayment,
    operand2Description: i18n.annualRate,
    forwardNavigation: ForwardNavigation.None,
  },
};
