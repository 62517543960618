import styled, { keyframes } from 'styled-components';
import {
  MagnifyContentStyle,
  MagnifyGraphicStyle,
  MagnifyShineStyle,
  magnifyShineMoveAnim,
} from '../MagnifyGraphic/styles';
import goldHouseImage from './images/goldhouseonhill.png';

export const moveBackground = keyframes`
  0%, 100% {
    background-position: left 33% top -40%;
  }
  50% {
    background-position: left 33% top -47%;
  }
`;

export const bob = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
`;

export const GoldHouseMagnifyGraphicStyle = styled.div.attrs({
  className: 'GoldHouseMagnifyGraphicStyle',
})`
  --goldHouseMagnifySize: 55%;
  background-image: url('${goldHouseImage}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  width: var(--goldHouseMagnifySize);
  padding-bottom: var(
    --goldHouseMagnifySize
  ); /* This makes the height equal to the width, creating a square */
  position: absolute;
  bottom: 0;
  left: 0;
  ${MagnifyGraphicStyle} {
    left: -10%;
    animation: ${bob} 3s infinite ease-in-out;
  }
  ${MagnifyContentStyle} {
    background-image: url('${goldHouseImage}');
    background-size: 287%;
    background-position: left 33% top -40%;
    animation: ${moveBackground} 3s infinite ease-in-out;
  }
  ${MagnifyShineStyle} {
    animation: ${magnifyShineMoveAnim} 3s infinite ease-in-out;
    opacity: 1;
  }
`;
