import styled, { css } from 'styled-components';
import {
  ButtonStyles,
  Header,
  HeaderStyles,
  LinkButtonStyles,
  Modal,
  ModalStyles,
  Size,
  SplashTextStyles,
  mixins,
} from '@pointdotcom/pds';
import { pxToRem } from 'styleHelpers';
import { PaddedFooterStyle, paddedSectionPadding } from 'styles';

export const PayoffModalStyle = styled(Modal).attrs({ className: 'PayoffModalStyle' })<{
  showProgress: boolean;
}>`
  ${ModalStyles.ModalContainerInnerStyle} {
    padding: 10%;
  }

  ${ModalStyles.UpperHeaderStyle} {
    border-bottom: 1px solid ${({ theme }) => theme.line.color.default};
    ${({ showProgress }) => {
      if (showProgress) {
        return css`
          display: grid;
          grid-template-columns: 35px 1fr 35px;
        `;
      }
    }}
  }

  ${LinkButtonStyles.ButtonLinkStyle} {
    color: ${({ theme }) => theme.Color.PointBlue};
    text-decoration: none;

    ${mixins.desktopHoverCondition()} {
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const PayoffExpressionStyle = styled.div.attrs({
  className: 'PayoffExpressionStyle',
})`
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center;
  text-align: center;
`;

export const SubheaderStyle = styled(SplashTextStyles.SplashCopySerifStyle).attrs({
  className: 'SubheaderStyle',
})`
  padding-inline: 8rem;

  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    padding-inline: 5rem;
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding-inline: 0;
  }
`;

export const HeadlineAmountStyle = styled(Header).attrs({
  className: 'AmountHeaderStyle',
})`
  margin-bottom: 1.5rem;
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    margin-bottom: 1rem;
  }
`;

export const BracketStyle = styled.div.attrs({ className: 'BracketStyle' })`
  width: 50%;
  display: flex;

  svg {
    width: 100%;
    height: auto;

    @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
      path {
        fill-opacity: 0.4;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    display: none;
  }
`;

export const VertLineStyle = styled.div.attrs({ className: 'VertLineStyle' })`
  height: 50px;
  border-right: ${({ theme }) => `2px solid ${theme.line.color.default}`};
`;

export const EquationSectionStyle = styled.div.attrs({ className: 'EquationSectionStyle' })`
  display: grid;
  grid-template-columns: 1fr 50px 1fr;
  grid-template-areas: 'operand1 operator operand2';

  gap: 1rem;
  align-items: center;
  --operand-width: 32vw;
  --operand-height: 300px;

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    --operand-height: 250px;
    --operand-width: calc(100vw - 8rem);

    grid-template-columns: unset;
    grid-template-rows: var(--operand-height) 4.25rem var(--operand-height);
    grid-template-areas:
      'operand1'
      'operator'
      'operand2';
    gap: 0;
  }
`;

export const OperandStyle = styled.div.attrs({ className: 'OperandStyle' })<{ capped: boolean }>`
  height: var(--operand-height);
  width: var(--operand-width);
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 3rem 2rem;
  ${SplashTextStyles.SplashCopySerifStyle} {
    font-size: ${pxToRem(45)};
    padding-top: 0.5rem;
  }

  ${ButtonStyles.ButtonContainerStyle} {
    align-self: stretch;
  }

  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    padding-inline: 1.5rem;
  }

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding: 2rem;

    ${SplashTextStyles.SplashCopySerifStyle} {
      font-size: ${pxToRem(30)};
    }
  }
`;

export const Operand1Style = styled(OperandStyle).attrs({ className: 'Operand1Style' })<{
  capped: boolean;
}>`
  grid-area: operand1;
  border: ${({ theme }) => `1px solid ${theme.line.color.default}`};

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    grid-area: ${({ capped }) => (capped ? 'operand2' : 'operand1')};
  }
`;

export const Operand2Style = styled(OperandStyle).attrs({ className: 'Operand2Style' })<{
  capped: boolean;
}>`
  position: relative;
  grid-area: operand2;
  border: ${({ theme, capped }) => `${capped ? '8px' : '1px'} solid ${theme.line.color.default}`};

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    grid-area: ${({ capped }) => (capped ? 'operand1' : 'operand2')};
    border: ${({ theme, capped }) => `${capped ? '6px' : '2px'} solid ${theme.line.color.default}`};
  }
`;

export const ProtectionImageStyle = styled.img.attrs({ className: 'ProtectionStyle' })`
  width: 45%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(calc(-50%));

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    width: 100px;
    transform: translate(50%, -50%);
    right: 50%;
  }
`;

export const OperandDescriptionStyle = styled.span.attrs({ className: 'OperandDescriptionStyle' })`
  text-align: left;
  padding-inline: 0.5rem;
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding-inline: 0;
  }
`;

export const OperatorStyle = styled.span.attrs({ className: 'OperatorStyle' })<{ capped: boolean }>`
  font-weight: bold;
  font-size: ${({ capped }) => pxToRem(capped ? 28 : 50)};
  text-align: center;
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    font-size: ${({ capped }) => pxToRem(capped ? 27 : 40)};
  }
`;

export const CloseFooterStyle = styled.div.attrs({ className: 'CloseFooterStyle' })`
  display: none;
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    display: flex;
    place-content: center;
    padding-bottom: 2.5rem;
  }
`;

export const ContentFooterStyle = styled.footer.attrs({ className: 'ContentFooterStyle' })`
  margin-top: ${paddedSectionPadding};
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    display: none;
  }
`;

export const SummaryButtonStyle = styled.div.attrs({ className: 'SummaryButtonStyle' })`
  margin-top: 2rem;
  min-width: 300px;
`;

export const OperandConnectorLineContainer = styled.div.attrs({
  className: 'OperandConnectorLineContainer',
})`
  --line-color: ${({ theme }) => theme.Color.PointBlack};
  display: none;
  position: absolute;
  width: calc(var(--operand-padding) / 2 + var(--operator-column-width) / 2 + 50%);
  left: calc(((var(--operand-padding) / 2) * -1) - var(--operator-column-width) / 2);
  top: calc(100% - var(--operand-padding) / 2);
  height: calc(
    (var(--operand-padding) / 2) + var(--summary-item-padding) + var(--summary-item-padding) / 2 +
      var(--summary-item-gap)
  );
  --first-line-height: calc((var(--operand-padding) / 2) + (var(--summary-item-padding) / 2));
  .topVertLine {
    height: var(--first-line-height);
  }
  .topVertLine,
  .bottomVertLine {
    position: absolute;
    width: 1px;
    background: var(--line-color);
    right: 0;
    &:before,
    &:after {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      position: absolute;
      border-radius: 5px;
      background: var(--line-color);
      left: calc(-50% + 1px);
      transform: translateX(-50%);
    }
    &:after {
      display: none;
      top: 100%;
    }
  }
  .middleHorizLine {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    background: var(--line-color);
    top: var(--first-line-height);
    right: 0;
  }
  .bottomVertLine {
    left: 0;
    top: var(--first-line-height);
    height: calc(var(--summary-item-padding) + var(--summary-item-gap) + 5px);
    &:before {
      bottom: 0;
    }
    &:after {
      display: none;
    }
  }

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    .middleHorizLine,
    .bottomVertLine {
      display: none;
    }
    .topVertLine {
      height: calc(
        var(--operand-padding) / 2 + var(--summary-item-padding) + var(--summary-item-gap) +
          var(--summary-item-padding) / 2
      );
      &:after {
        display: block;
      }
    }
  }
`;

export const SummaryItemStyle = styled.li.attrs({ className: 'SummaryItemStyle' })`
  border: 1px solid ${({ theme }) => theme.line.color.default};
  padding: var(--summary-item-padding);
  ${HeaderStyles.HeaderStyle} {
    margin: unset;
    margin-block-end: 1em;
    &.splash {
      margin-block-end: 2em;
    }
  }
  &:first-child {
    p {
      max-width: 100%;
    }
  }
  > p {
    max-width: 80%;
    margin: 0 auto 1em;
    text-align: center;
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  }
  ${EquationSectionStyle} {
    grid-template-columns: 1fr var(--operator-column-width) 1fr;
  }
  ${OperatorStyle} {
    font-size: 1.75rem;
  }
  ${OperandStyle} {
    max-width: unset;
    width: unset;
    height: unset;
    padding: var(--operand-padding);
  }
  ${OperandDescriptionStyle} {
    display: none;
  }
  ${ButtonStyles.ButtonContainerStyle} {
    display: none;
  }
  ${SplashTextStyles.SplashCopySerifStyle} {
    font-family: ${({ theme }) => theme.fonts.sansSerif.name};
    font-weight: ${({ theme }) => theme.header.fontWeight[Size.Default]};
    font-size: ${({ theme }) => theme.header.fontSize[Size.Default].default};
  }

  &:last-child {
    ${OperandConnectorLineContainer} {
      display: none;
    }
  }

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    ${EquationSectionStyle} {
      --operand-height: auto;
      grid-template-columns: unset;
      grid-template-rows: var(--operand-height) 3.5rem var(--operand-height);
    }
    font-size: ${({ theme }) => theme.header.fontSize[Size.Default].mobile};
    ${Operand2Style} {
      border-width: 1px;
    }
  }
`;

export const SummaryItemContainerStyle = styled.ul.attrs({
  className: 'SummaryItemContainerStyle',
})`
  display: flex;
  flex-flow: column nowrap;
  gap: var(--summary-item-gap);
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const SummaryItemFooterStyle = styled(PaddedFooterStyle).attrs({
  className: 'SummaryItemFooterStyle',
})`
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  h5 {
    font-family: ${({ theme }) => theme.fonts.serif.name};
    font-style: italic;
    font-weight: normal;
    margin: 0;
    font-size: ${pxToRem(16)};
  }
  a {
    color: inherit;
    ${mixins.hover(css`
      text-decoration: none;
    `)}
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    > * {
      &:last-child {
        display: none;
      }
    }
  }
`;

export const ContactOptionsStyle = styled.div.attrs({ className: 'ContactOptionsStyle' })`
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
  > *:after {
    content: '|';
    margin-inline: 0.75rem;
    color: ${({ theme }) => theme.line.color.default};
  }

  > *:last-child:after {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    flex-direction: column;
    gap: 0.25rem;
    padding: 0;

    a:after {
      display: none;
    }
  }
`;

export const SummaryModalContentStyle = styled.div.attrs({
  className: 'SummaryModalContentStyle',
})`
  --summary-item-gap: 2.5rem;
  --summary-item-padding: ${(props) => props.theme.container.gutter.default};
  --operand-padding: 2rem;
  --operator-column-width: 1.3rem;
  ${OperandConnectorLineContainer} {
    display: block;
  }
  > ${HeaderStyles.HeaderStyle} {
    margin: unset;
    margin-block-end: 3em;
  }
`;
