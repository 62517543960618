import React, { useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { Autocomplete, Size, messagingI18n } from '@pointdotcom/pds';
import {
  formatSuggestionAsString,
  getReshapedAddressStructureFromAutocompleteAPI,
} from 'lib/smartyStreets';
import { fetchAutocompleteResults } from 'services/smartyStreets';

const THROTTLE_MILLISECONDS = 275;
const MIN_CHARS_BEFORE_SEARCH = 3;

const SmartyStreetsAutocomplete = ({
  'aria-label': ariaLabel = null,
  value = '',
  styleSize = Size.Default,
  inputProps,
  placeholder,
  error: errorFromProps = false,
  helptext: helpTextFromProps,
  onFocus = () => {},
  onBlur = () => {},
  // eslint-disable-next-line no-unused-vars
  onSelect = (values) => {},
  // eslint-disable-next-line no-unused-vars
  onChange = (e, values) => {},
  // eslint-disable-next-line no-unused-vars
  onSubmit = (e) => {},
}) => {
  const abortControllerRef = useRef(null);
  const [typed, setTyped] = useState(value);
  const [suggestions, setSuggestions] = useState([]);
  const [manualErrorText, setManualErrorText] = useState(null);

  // See guidance in https://kyleshevlin.com/debounce-and-throttle-callbacks-with-react-hooks
  const requestAndSetResultsDebounced = useMemo(() => {
    const requestAndSetResults = async (userTyped) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      try {
        abortControllerRef.current = new AbortController();
        const results = await fetchAutocompleteResults(userTyped, abortControllerRef.current);
        setSuggestions(results?.suggestions);
      } catch (_) {
        setManualErrorText(messagingI18n.errors.unknownError);
      }
    };

    return debounce(requestAndSetResults, THROTTLE_MILLISECONDS);
  }, []);

  useEffect(() => {
    const smartyStreetRequest = async () => {
      const typedTrimmed = typed.trim();
      if (!typedTrimmed.length || typedTrimmed.length < MIN_CHARS_BEFORE_SEARCH) {
        setSuggestions([]);
        return;
      }
      await requestAndSetResultsDebounced(typed);
    };

    smartyStreetRequest();

    return () => abortControllerRef?.current?.abort();
  }, [typed, requestAndSetResultsDebounced]);

  const handleChange = async (e, { value }) => {
    setTyped(value);
    onChange(e, { value });
  };

  const handleSelect = ({ value, unformatted, type }) => {
    onSelect({
      value,
      structured: getReshapedAddressStructureFromAutocompleteAPI(unformatted),
      unformatted,
      selectionType: type,
    });
  };

  // filter out duplicates that are present when an address has multiple units
  // not doing so shows multiple results of an apartment like so -
  // [ 123 test st, APT (3 entries), some city CA, 12345 ]
  // [ 123 test st, some city CA, 12345 ]
  const useFilter = ({ item }) => item.entries === 0;

  // handle when the user hits the enter key when not making a selection
  const handleSubmit = (props) => onSubmit(props);

  const helptext = manualErrorText || helpTextFromProps;
  const hasError = !!errorFromProps || !!manualErrorText;

  return (
    <Autocomplete
      aria-label={ariaLabel}
      error={hasError}
      helptext={helptext}
      data={suggestions}
      placeholder={placeholder}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      itemFormatter={formatSuggestionAsString}
      value={typed}
      onSelect={handleSelect}
      onSubmit={handleSubmit}
      inputProps={inputProps}
      filter={useFilter}
      styleSize={styleSize}
    />
  );
};

export default SmartyStreetsAutocomplete;
