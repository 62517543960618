import { DocketStatus } from 'services/apiTypes/homeownerTypes';
import { DocketOffer, Docket as UnderwriteDocket } from 'services/apiTypes/underwriteTypes';
import { Expand, Pricing } from 'types';
import BaseModel from './BaseModel';
import { normalizeProps } from './helpers';

export { DocketStatus };

export interface Docket {
  id?: number;
  status?: DocketStatus;
  funded?: boolean;
  latestOffer?: null | DocketOffer;
}

class DocketModel extends BaseModel<Docket> implements Docket {
  id?: number;

  status: TSFixMe;

  funded: TSFixMe;

  latestOffer?: null | DocketOffer;

  constructor(props: Expand<Docket> | UnderwriteDocket) {
    const revisedProps = normalizeProps<Docket>(props as TSFixMe, {
      latest_offer: 'latestOffer',
    });
    super(revisedProps);
    Object.assign(this, revisedProps);
    this.setBool('funded');
  }

  getLatestOffer(): null | Pricing {
    const dashboardOffer = this.latestOffer;
    if (dashboardOffer == null) {
      return null;
    }

    const homeValue = parseFloat(dashboardOffer.appraised_property_value);
    const riskAdjustment = parseFloat(dashboardOffer.risk_adjustment);
    const riskAdjustmentPercentage = riskAdjustment / 100;
    const riskAdjustedHomeValue = homeValue * (1 - riskAdjustmentPercentage);

    return {
      acceptable: true,
      capPercentage: parseFloat(dashboardOffer.cap_percentage),
      homeValue,
      optionInvestmentAmount: parseFloat(dashboardOffer.option_investment_amount),
      optionPercentage: parseFloat(dashboardOffer.option_percentage),
      originationFeeRate: parseFloat(dashboardOffer.origination_fee_rate),
      riskAdjustedHomeValue,
      riskAdjustedValue: riskAdjustedHomeValue,
      riskAdjustmentPercentage,
      riskAdjustment,
      termYears: dashboardOffer.term,
    };
  }
}

export default DocketModel;
