import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Container, Logo } from '@pointdotcom/pds';
import GenericMessageBanner from 'components/GenericMessageBanner';
import { MainHeaderRefs } from 'components/MainHeader/constants';
import * as MainHeaderStyles from '../MainHeader/styles';
import * as styles from './styles';

interface MainHeaderNoNavProps {
  logoHref?: string;
  bannerProps?: Record<string, TSFixMe>;
  scrolled?: boolean;
}

const MainHeaderNoNav = forwardRef<MainHeaderRefs, MainHeaderNoNavProps>(
  ({ logoHref, bannerProps, scrolled }, ref) => {
    // Banners can be set with props or generically through the store
    const bannerMessageNew = bannerProps?.message;
    const hasBannerMessage = !!bannerMessageNew || !!bannerProps?.messageType;
    const bannerRef = useRef<HTMLDivElement>(null);
    const headerContainerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      bannerRef,
      headerContainerRef,
    }));

    return (
      <styles.MainHeaderNoNavStyle>
        {!bannerProps?.hideBanner && (
          <GenericMessageBanner show={hasBannerMessage} {...(bannerProps || {})} ref={bannerRef} />
        )}
        <MainHeaderStyles.MainHeaderContainerStyle scrolled={scrolled} ref={headerContainerRef}>
          <Container flex>
            <styles.HeaderContentStyle>
              <Logo href={logoHref} />
            </styles.HeaderContentStyle>
          </Container>
        </MainHeaderStyles.MainHeaderContainerStyle>
      </styles.MainHeaderNoNavStyle>
    );
  }
);

export default MainHeaderNoNav;
