import styled from 'styled-components';
import { ContainerStyles, mixins } from '@pointdotcom/pds';
import { appTheme } from 'styles/';

const progressDotSize = 10;
const progressDotBorderSize = 13;
const progressDotBorderSizeMobile = 10;
const progressHeight = 5;

export const MainHeaderWithProgressStyle = styled.header.attrs({
  className: 'MainHeaderWithProgressStyle',
})<{ shouldHideProgressBar?: boolean }>`
  transition: all 320ms ease-in-out;
  background: ${(props) => props.theme.Color.White};
  align-items: center;
  position: relative;
  isolation: isolate;
  display: flex;
  align-content: center;
  flex-flow: column nowrap;
  height: ${appTheme.mainHeaders.height.default};
  border-bottom: ${(props) =>
    props.shouldHideProgressBar ? `1px solid ${props.theme.line.color.default}` : 'none'};
  > ${ContainerStyles.ContainerStyle} {
    position: relative;
    height: 100%;
    width: 100%;
  }

  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    height: ${appTheme.mainHeaders.height.mobile};
  }

  @media only print {
    align-items: flex-start;
  }
`;

export const HeaderContentStyle = styled.div.attrs({ className: 'HeaderContentStyle' })`
  display: flex;
  justify-content: space-between;
`;

export const ProgressItemStyle = styled.button.attrs({
  className: 'ProgressItemStyle' as string,
})<{ completed?: boolean; className?: string; shouldHideProgressBar?: boolean }>`
  ${mixins.appearance('none')}
  cursor: pointer;
  font-family: inherit;
  font-size: ${({ theme }) => theme.button.fontSize.default?.default};
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
  color: ${(props) =>
    props.completed ? props.theme.Color.PointBlue : props.theme.Color.GrayLightestAccessible};
  /* cursor: ${(props) => (props.completed ? 'pointer' : 'default')}; */
  &:before,
  &:after {
    display: ${(props) => (props.shouldHideProgressBar ? 'none' : 'block')};
    position: absolute;
    width: 1px;
    left: 50%;
    content: '';
  }

  &:before {
    top: calc(50% + 16px);
    transform: translateX(-50%);
    height: 20%;
    border-left: 1px solid
      ${(props) =>
        props.completed ? props.theme.Color.Gray1 : props.theme.progress.backgroundColor.default};
  }

  &:after {
    margin-left: -${progressDotSize / 2}px;
    width: ${progressDotSize}px;
    height: ${progressDotSize}px;
    background: ${(props) =>
      props.completed ? props.theme.Color.PointBlue : props.theme.Color.Gray1};
    border-radius: 50%;
    bottom: -${(progressDotSize - progressHeight) / 2}px;
    box-shadow: 0 0 0 ${progressDotBorderSize}px white;
  }

  &:hover {
    color: ${(props) => (props.completed ? props.theme.Color.PointBlue : props.theme.Color.Gray4)};
  }

  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    font-size: ${({ theme }) => theme.button.fontSize.default?.mobile};
    &:before {
      display: none;
    }
    &:after {
      box-shadow: 0 0 0 ${progressDotBorderSizeMobile}px white;
    }
    > * {
      visibility: hidden;
      width: 40px;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

export const ProgressItemContainerStyle = styled.nav.attrs({
  className: 'ProgressItemContainerStyle',
})`
  display: flex;
  flex-flow: row nowrap;
  position: absolute;
  right: 0;
  top: 0%;
  height: 100%;
  z-index: 2;
  @media only print {
    display: none !important;
  }
`;

export const HeaderProgressStyle = styled.div.attrs({
  className: 'HeaderProgressStyle',
})<{
  shouldHideProgressBar?: boolean;
}>`
  position: absolute;
  bottom: 0;
  left: 0;
  background: ${(props) => props.theme.progress.backgroundColor.default};
  height: ${progressHeight}px;
  width: 100%;
  visibility: ${(props) => (props.shouldHideProgressBar ? 'hidden' : 'visible')};
  @media only print {
    height: 1px;
  }
`;

export const FillStyle = styled.div.attrs({
  className: 'FillStyle' as string,
})<{ perc?: number }>`
  width: ${({ perc }) => `${Math.floor(perc ?? 0)}%`};
  height: 100%;
  background: ${({ theme }) => theme.Color.PointBlue};
  @media only print {
    display: none;
  }
`;
