import styled, { keyframes } from 'styled-components';

const bounceIn = keyframes`
  0% {
    opacity:0;
    transform:  translate(0px, 200px);
  }
  60% {
    opacity:1;
    transform:  translate(0px, -5px);
  }
  80% {
    transform:  translate(0px, 5px);
  }
  100% {
    opacity:1;
    transform:  translate(0px,0px);
  }
`;

const bounceInMain = keyframes`
  0% {
    opacity:0;
  }
  60% {
    /* transform:  translate(0px, 5px); */
  }
  80% {
    /* transform:  translate(0px, -5px); */
  }
  100% {
    opacity:1;
    /* transform:  translate(0px,0px); */
  }
`;

const keyBob = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5%);
  }
`;

const spin = keyframes`
  0% {
    transform: scale(0) rotate(0);
  }
  50% {
    transform: scale(1.5) rotate(180deg);
  }
  100% {
    transform: scale(0) rotate(360deg);
  }
`;

const blink = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

const cloud = keyframes`
  0% {
    opacity: 0;
    transform: translateX(0);
  }
  10% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(-350%);
  }
`;

const animDurationInMS = 1200;
const animSpacingInMS = 100;

export const GraphicStyle = styled.div.attrs({ className: 'GraphicStyle' })`
  width: 955px;
  height: 236px;
  position: absolute;
  right: 0;
  opacity: 0;
  &.enter,
  &.enter-active,
  &.enter-done {
    animation: ${bounceInMain} 1500ms ease-in-out 200ms 1 both;
    .sun {
      transition: all 1000ms ease-in-out 600ms;
      transform: translateY(0);
      opacity: 1;
    }
    .tree {
      animation: ${bounceIn} ${animDurationInMS}ms ease-in-out 0ms;
    }
    .smhouse {
      animation: ${bounceIn} ${animDurationInMS}ms ease-in-out ${animSpacingInMS}ms 1 backwards;
    }
    .lghouse,
    .lghouseM {
      animation: ${bounceIn} ${animDurationInMS}ms ease-in-out ${animSpacingInMS * 2}ms 1 backwards;
    }
    .stack1 {
      animation: ${bounceIn} ${animDurationInMS}ms ease-in-out ${animSpacingInMS * 3}ms 1 backwards;
    }
    .stack2 {
      animation: ${bounceIn} ${animDurationInMS}ms ease-in-out ${animSpacingInMS * 3}ms 1 backwards;
    }
    .mountains,
    .mountainsM {
      animation: ${bounceIn} ${animDurationInMS}ms ease-in-out ${animSpacingInMS * 4}ms 1 backwards;
    }
    .lock {
      transform: scale(1);
      opacity: 1;
    }
  }
  img {
    max-width: 100%;
    position: absolute;
    bottom: 0;
    object-fit: cover;
    height: auto;
  }
  .overflowWrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .mainWrap {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .birds {
    z-index: 3;
    top: -37%;
    transform: rotate(8deg);
  }
  .sun {
    opacity: 0;
    transform: translateY(-50%);
    width: 29.817%;
    top: -60%;
    left: 16%;
    z-index: 11;
  }
  .tree {
    width: 5.969%;
    right: 54.6%;
    bottom: -1%;
  }
  .smhouse {
    right: 45.9%;
    bottom: -0.6%;
    width: 9.531%;
    z-index: 9;
  }
  .mask,
  .maskM {
    z-index: 10;
    bottom: -1px;
  }
  .maskM {
    display: none;
  }
  .lghouseM {
    display: none;
    width: 26.174%;
    right: 0;
    bottom: -70%;
    z-index: 11;
    right: 13%;
  }
  .lghouse {
    width: 18.195%;
    bottom: -42%;
    right: 27.8%;
    z-index: 9;
  }
  .mountainsM {
    display: none;
    width: 109.094%;
    right: -12.4%;
    bottom: -28%;
  }
  .mountains {
    top: 30%;
    right: -14.9%;
  }
  .cloud {
    left: 40%;
    top: 6%;
    width: 15.28%;
    z-index: 12;
    animation: ${cloud} 60000ms linear 0ms infinite backwards;
  }
  .stack1,
  .stack2 {
    z-index: 9;
    width: 5.494%;
  }
  .stack1 {
    right: 22.4%;
    bottom: -10%;
  }
  .stack2 {
    right: 16.9%;
    bottom: -24.1%;
  }
  .lock {
    transition: all 800ms ease-out 400ms;
    width: 5.216%;
    z-index: 10;
    right: 33.9%;
    transform: scale(0);
    opacity: 0;
  }
  .keyWrap {
    position: absolute;
    width: 12.372%;
    z-index: 10;
    right: 5%;
    bottom: -25%;
    animation: ${keyBob} 4000ms ease-in-out 0ms infinite backwards;
    height: 176px;
    .shine {
      display: block;
      position: absolute;
      animation: ${spin} 3000ms linear 0ms infinite;
      width: 7px;
      height: 7px;
      content: '';
      left: 0;
      top: 0;
      &:after {
        content: '';
        display: block;
        background: white;
        position: absolute;
        animation: ${blink} 5000ms linear 0ms infinite;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
      &.l {
        left: -12%;
        top: -2%;
        width: 6px;
        height: 6px;
      }
      &.t {
        top: -10%;
        left: 3%;
        width: 4px;
        height: 4px;
        &:after {
          animation-delay: 1000ms;
          animation-duration: 3000ms;
        }
      }
      &.r {
        left: 55%;
        top: 9%;
        &:after {
          animation-delay: 500ms;
          animation-duration: 6000ms;
        }
      }
    }
    img {
      width: 100%;
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    .lghouseM,
    .mountainsM,
    .maskM {
      display: block;
    }
    .lock,
    .mountains,
    .stack1,
    .stack2,
    .lghouse,
    .key,
    .mask {
      display: none;
    }
    .sun {
      left: -11%;
      top: -49%;
    }
    .smhouse {
      width: 17.196%;
      right: 39.1%;
      bottom: -26%;
      z-index: 9;
    }
    .birds {
      top: -28%;
    }
    .tree {
      width: 10.243%;
      right: 54.6%;
      bottom: -17%;
      z-index: 8;
    }
    .cloud {
      z-index: 8;
    }
  }
`;
