import React from 'react';
import navI18n from 'components/MainHeader/i18n';
import HeiEstimatorPage from 'containers/hei/HeiEstimatorPage';
import { useFollowUpFetch } from 'containers/prequal/hooks';
import ClosingDisclosurePage from '..';

const ClosingDisclosureEstimatorPage = () => {
  const followUpFetchResults = useFollowUpFetch();
  const { followUp } = followUpFetchResults;

  const estimate = followUp?.getEstimate();
  return (
    <ClosingDisclosurePage pageName={navI18n.yourCost} followUpFetchResults={followUpFetchResults}>
      <HeiEstimatorPage
        followUp={followUp}
        estimate={estimate}
        isFinalPricing={!estimate?.preliminary}
      />
    </ClosingDisclosurePage>
  );
};

export default ClosingDisclosureEstimatorPage;
