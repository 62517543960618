import React, { useState } from 'react';
import classnames from 'classnames';
import { Transition, TransitionGroup } from 'react-transition-group';
import {
  Container,
  DirectionAndPlacement,
  Header,
  Icon,
  IconName,
  LinkButton,
  Size,
  useIsMobile,
} from '@pointdotcom/pds';
import { getAnswers, getQuestions } from 'components/ProductFAQs';
import i18n from './i18n';
import * as styles from './styles';

const MainContent = ({ animationStatus, answers, selectedQuestion }) => (
  <main className={animationStatus}>{answers[selectedQuestion]}</main>
);

const FAQTable = () => {
  const { isMobile } = useIsMobile();
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const questions = getQuestions();
  const answers = getAnswers();

  const handleQuestionClick = (questionIndex) => () => setSelectedQuestion(questionIndex);
  return (
    <styles.FAQTableStyle>
      <ul>
        {questions.map((q, i) => {
          const selected = selectedQuestion === i;
          return (
            <li key={q} className={classnames({ active: selected })}>
              <LinkButton onClick={handleQuestionClick(i)}>
                <span>{q}</span>
                <Icon name={IconName.ChevronRight} strokeSize={1} color="#FFFFFF" />
              </LinkButton>
              <TransitionGroup component={null}>
                {isMobile && selected && (
                  <Transition in timeout={{ enter: 0, exit: 320 }}>
                    {(status) => (
                      <MainContent
                        animationStatus={status}
                        answers={answers}
                        selectedQuestion={selectedQuestion}
                      />
                    )}
                  </Transition>
                )}
              </TransitionGroup>
            </li>
          );
        })}
      </ul>
      {!isMobile ? <MainContent answers={answers} selectedQuestion={selectedQuestion} /> : null}
    </styles.FAQTableStyle>
  );
};

const FAQSection = ({ children }) => {
  return (
    <styles.FAQSectionStyle>
      <Container>
        <Header
          styleSize={Size.Large}
          styleAlign={DirectionAndPlacement.Center}
          styleAlignMobile={DirectionAndPlacement.Left}
        >
          {i18n.frequently}
        </Header>
      </Container>
      <Container mobileCollapse>
        <FAQTable />
      </Container>
      <Container styleAlign={DirectionAndPlacement.Center}>{children}</Container>
    </styles.FAQSectionStyle>
  );
};

export default FAQSection;
