import { createGlobalStyle } from 'styled-components';

// HACK: fix withScrollFollow in PDS
// This animates the body padding changes so that fixed position
// elements dont glitch their positions when the body padding changs
export const BodyAnimationStyle = createGlobalStyle<{ animationReady: boolean }>`
  body {
    transition: ${({ animationReady }) => (animationReady ? 'padding 200ms ease-in-out' : 'none')};
  }
`;
