import _cloneDeep from 'lodash.clonedeep';
import _get from 'lodash.get';
import _set from 'lodash.set';
import _unset from 'lodash.unset';
import { InputMask, InputMaskFormatter, InputMaskType } from '@pointdotcom/pds';

export const currencyMask = new InputMask({
  type: InputMaskType.Number,
  options: { format: InputMaskFormatter.Currency, precision: 0 },
});

export const percMask = new InputMask({
  type: InputMaskType.Number,
  options: { format: InputMaskFormatter.Percentage, precision: 1, chopZeros: false },
});

export const round = (num: number, roundingToNearest = 1): number => {
  return Math.round(num / roundingToNearest) * roundingToNearest;
};

// provides a way to reshape props
export function normalizeProps<T>(
  props: T | undefined,
  reshape:
    | Record<string, string>
    | Array<{
        oldKey?: string;
        newKey: string;
        newValue?: TSFixMe;
      }>
): T {
  if (!props) {
    return {} as T;
  }
  const newShape: TSFixMe = _cloneDeep(props);

  if (Array.isArray(reshape)) {
    reshape.forEach(({ oldKey, newKey, newValue }) => {
      let value = newValue;
      if (oldKey && !value) {
        value = _get(newShape, oldKey);
      }
      if (oldKey) {
        _unset(newShape, oldKey);
      }

      if (value !== undefined && newKey) {
        _set(newShape, newKey, value);
      }
    });
  } else if (typeof reshape === 'object') {
    Object.entries(reshape).forEach(([givenKey, reshapedValue]) => {
      delete newShape[givenKey];
      let newKey;
      let newValue;
      if (typeof reshapedValue === 'string') {
        newKey = reshapedValue;
        newValue = (props as TSFixMe)[givenKey] || (props as TSFixMe)[newKey];
      }

      newShape[newKey as TSFixMe] = newValue;
    });
  }

  return newShape;
}
