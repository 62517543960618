import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container } from '@pointdotcom/pds';
import i18n from './i18n';
import * as styles from './styles';

export const messageType = {
  COVID19: 'covid19',
};

const messageTypeContentMap = {
  [messageType.COVID19]: () => (
    <>
      <div className="text">{i18n.aMessageFrom}</div>
      <form method="get" action="https://welcome.point.com/a-note-from-our-ceo">
        <Button type="submit">{i18n.learnMore}</Button>
      </form>
    </>
  ),
};

export const BannerMessage = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const Content = messageTypeContentMap[props.messageType];
  return Content ? (
    <styles.BannerMessageStyle {...props}>
      <Container>
        <Content />
      </Container>
    </styles.BannerMessageStyle>
  ) : null;
};

BannerMessage.defaultProps = {
  blueTheme: false,
  messageType: process.env.REACT_APP_PREQUAL_BANNER_MESSAGE_TYPE,
};

BannerMessage.propTypes = {
  blueTheme: PropTypes.bool,
  messageType: PropTypes.oneOf(Object.keys(messageTypeContentMap)),
};

export default BannerMessage;
