import React from 'react';
import { Helmet } from 'react-helmet';
import { Transition } from 'react-transition-group';
import {
  DirectionAndPlacement,
  Logo,
  TPWidgetType,
  TrustPilotWidget,
  templatedString,
} from '@pointdotcom/pds';
import ResumeApplicationBanner from 'components/Banners/ResumeApplicationBanner';
import ConsentManagementLink from 'components/ConsentManagementLink';
import DotIndicators, { styleTypes } from 'components/DotIndicators';
import { TagPage, Workflow } from 'components/TagPage';
import BannerMessage from 'components/prequal/BannerMessage';
import ContextHelp from 'components/prequal/ContextHelp';
import PageContent from 'components/prequal/PageContent';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { SupportLink, usePointContact } from 'containers/prequal/hooks/usePointContact';
import { useProduct } from 'containers/prequal/hooks/useProduct';
import { ProductType } from 'services/apiTypes/commonTypes';
import i18n from './i18n';
import * as styles from './styles';
import { FlexFlow } from './styles';

export { FlexFlow };

interface ModalPageProps {
  children: React.ReactNode;
  pageName: string;
  flexFlow?: FlexFlow;
  numIndicators?: number;
  currentIndicatorIndex?: number;
  content: React.ComponentProps<typeof PageContent.Header>['content'];
  contextHelp?: { body: React.ReactNode; headline: string }[];
  titleMaxWidth?: string;
  subTitleMaxWidth?: string;
  afterContextContent?: React.ReactNode;
}

const ModalPage = ({
  children,
  pageName,
  flexFlow = FlexFlow.Row,
  numIndicators = 3,
  currentIndicatorIndex = 0,
  content,
  contextHelp,
  titleMaxWidth,
  subTitleMaxWidth,
  afterContextContent,
}: ModalPageProps) => {
  const { product } = useProduct();
  const logoHref = generateUrlFromPage(pages.PREQUAL_START, { product });
  const { supportNumber } = usePointContact();

  return (
    <>
      <Helmet
        title={templatedString({
          template: i18n[`${product ?? ProductType.HEI}PageTitle`],
          values: { pageName },
        })}
      />
      <TagPage
        page={product ? `${pageName} (${product.toUpperCase()})` : pageName}
        workflow={Workflow.Prequal}
      />
      {process.env.REACT_APP_PREQUAL_BANNER_MESSAGE_TYPE ? (
        <BannerMessage />
      ) : (
        <ResumeApplicationBanner />
      )}
      <styles.ModalPageStyle>
        <styles.ModalPageHeaderStyle>
          <Logo href={logoHref} />
          {numIndicators > 0 && (
            <DotIndicators
              items={Array.from({ length: numIndicators }).map((indicatorPage, i) => ({
                key: `page${i}`,
              }))}
              currentIndex={currentIndicatorIndex}
              styleType={styleTypes.OVERLIGHT}
              readOnly
            />
          )}
        </styles.ModalPageHeaderStyle>
        <styles.ModalPageContainerStyle flexFlow={flexFlow}>
          <Transition timeout={{ enter: 0, exit: styles.transitionSpeedMS }} in>
            {(status) => (
              <styles.MainContentStyle animationStatus={status}>
                <section className="topSection">
                  <PageContent.Header
                    content={content}
                    titleMaxWidth={titleMaxWidth}
                    subTitleMaxWidth={subTitleMaxWidth}
                  />
                </section>
                <section className="bottomSection">
                  <div className={`contentSection ${!contextHelp ? 'weighted' : ''}`}>
                    {children}
                  </div>
                  {contextHelp && contextHelp.length > 0 && (
                    <div className="contextSection">
                      <ContextHelp contextHelp={contextHelp} />
                    </div>
                  )}
                  {afterContextContent && (
                    <div className="afterContextSection">{afterContextContent}</div>
                  )}
                </section>
              </styles.MainContentStyle>
            )}
          </Transition>
        </styles.ModalPageContainerStyle>
        <styles.FooterSectionStyle>
          <TrustPilotWidget widgetType={TPWidgetType.MicroStar} />
          <SupportLink supportNumber={supportNumber}>{`Questions? ${supportNumber}`}</SupportLink>
        </styles.FooterSectionStyle>
      </styles.ModalPageStyle>
      <ConsentManagementLink styleAlign={DirectionAndPlacement.Center} />
    </>
  );
};

export default ModalPage;
