import React, { useEffect, useRef, useState } from 'react';
import { Container } from '@pointdotcom/pds';
import bellSound from './bell.mp3';
import * as styles from './styles';

export const BirdAnimation = ({
  left,
  start,
  inverted = false,
}: styles.BirdImgStyleProps & {
  start: number;
}) => {
  const [loadAnim, setLoadAnim] = useState(false);

  useEffect(() => {
    // Setup the timeout to toggle the animation state
    const timeoutId = setTimeout(() => {
      setLoadAnim((current) => !current);
    }, start);

    // Clear the timeout when the component unmounts or start changes
    return () => clearTimeout(timeoutId);
  }, [start]); // Only re-run the effect if `start` changes

  // Render the BirdImgStyle if loadAnim is true, otherwise render nothing
  return loadAnim ? <styles.BirdImgStyle left={left} inverted={inverted} /> : null;
};

const AnimatedGraphic = () => {
  const soundRef = useRef<HTMLAudioElement | null>(null);
  const anaRef = useRef<HTMLDivElement | null>(null);
  const handleAnaClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (anaRef.current && soundRef.current) {
      const rect = anaRef.current.getBoundingClientRect();

      // Extract the position and dimension from the rectangle
      const divX = rect.left;
      const divY = rect.top;
      const divWidth = rect.width;
      const divHeight = rect.height;

      // Get click coordinates relative to the viewport
      const clickX = e.clientX;
      const clickY = e.clientY;

      // Check if the click is within the div bounds
      if (
        clickX >= divX &&
        clickX <= divX + divWidth &&
        clickY >= divY &&
        clickY <= divY + divHeight
      ) {
        soundRef.current.pause();
        soundRef.current.currentTime = 0;
        soundRef.current.volume = 0.08;
        soundRef.current && soundRef.current.play();
      }
    }
  };

  return (
    <styles.AnimatedGraphicStyle onMouseDown={handleAnaClick}>
      <Container>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio ref={soundRef} src={bellSound} preload="auto" />
        <BirdAnimation left={22} start={4800} />
        <BirdAnimation left={30} start={6800} inverted />
        <BirdAnimation left={50} start={10000} />
        <BirdAnimation left={60} start={15000} />
        <BirdAnimation left={80} start={20000} inverted />
        <styles.BackgroundStyle />
        <styles.AnaImgContainerStyle>
          <styles.AnaStyle>
            <styles.AnaImgStyle ref={anaRef} />
          </styles.AnaStyle>
        </styles.AnaImgContainerStyle>
        <styles.ForegroundStyle />
      </Container>
    </styles.AnimatedGraphicStyle>
  );
};

export default AnimatedGraphic;
