import React from 'react';
import throttle from 'lodash.throttle';
import PropTypes from 'prop-types';
import Confetti from 'react-confetti';
import { withTheme } from 'styled-components';
import * as styles from './styles';

class ConfettiBackground extends React.PureComponent {
  constructor(props) {
    super(props);
    this.throttledResize = throttle(this.handleResize, 100);
    this.state = {
      size: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.throttledResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledResize);
  }

  handleResize = () => {
    this.setState({
      size: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  };

  render() {
    const { style, theme } = this.props;
    const { size } = this.state;

    const colors = [
      theme.Color.Yorange,
      theme.Color.DarkYorange1,
      theme.Color.PointBlue,
      theme.Color.PurpleBlue2,
    ];

    return (
      <styles.ConfettiBackgroundStyle {...style}>
        <Confetti {...size} colors={colors} {...this.props} />
      </styles.ConfettiBackgroundStyle>
    );
  }
}

ConfettiBackground.defaultProps = {
  wind: 0.005,
  size: { width: 0, height: 0 },
  style: {},
  numberOfPieces: 100,
  recycle: true,
  gravity: 0.015,
  opacity: 0.2,
  run: true,
};

ConfettiBackground.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  wind: PropTypes.number,
  numberOfPieces: PropTypes.number,
  recycle: PropTypes.bool,
  gravity: PropTypes.number,
  run: PropTypes.bool,
  opacity: PropTypes.number,
  size: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};

export default withTheme(ConfettiBackground);
