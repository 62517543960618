import React from 'react';
import {
  DropDownProps,
  Range,
  RangeChangeEvent,
  RangeProps,
  Size,
  useIsMobile,
} from '@pointdotcom/pds';
import OfferEstimateModel from 'models/OfferEstimateModel';
import i18n from './i18n';

interface RangeBuyBackTimeProps {
  dropdownButtonProps?: DropDownProps;
  estimate: null | OfferEstimateModel;
  rangeProps?: Partial<Omit<RangeProps, 'dropdownProps' | 'min' | 'max' | 'onChange' | 'value'>>;
  buyBackTime: number | string;
  setBuyBackTime: (newValue: number | string) => unknown;
}

export default function RangeBuyBackTime({
  dropdownButtonProps = {},
  estimate,
  rangeProps,
  buyBackTime,
  setBuyBackTime,
}: RangeBuyBackTimeProps) {
  const { isMobile } = useIsMobile();
  const formatRangeAfterLabelValue = (val: number) =>
    `${new Date().getFullYear() + parseInt(val as TSFixMe, 10)}`;
  const formatRangeLabelValue = (val: number | string) =>
    `${val} ${parseInt(val as TSFixMe, 10) === 1 ? i18n.year : i18n.years}`;
  const handleChange: RangeChangeEvent = (i, { value }) => {
    setBuyBackTime(value);
  };

  if (!estimate) {
    return null;
  }

  const rangeYearStart = 1;
  const rangeNumYears = estimate.getTerm();

  let tickVisibility;
  if (rangeNumYears > 20) {
    tickVisibility = isMobile ? '5n+0' : '3n+0';
  }

  return (
    <Range
      label={i18n.whenDoYou}
      dropdownProps={dropdownButtonProps}
      min={rangeYearStart}
      max={rangeNumYears}
      tickVisibility={tickVisibility}
      valFormatter={formatRangeLabelValue}
      onChange={handleChange}
      value={buyBackTime}
      gaTrackingId="RangeBuyBackTime"
      afterLabelFormatter={formatRangeAfterLabelValue}
      styleSize={Size.Large}
      {...rangeProps}
    />
  );
}
