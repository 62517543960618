import { TransitionStatus } from 'react-transition-group';
import styled, { createGlobalStyle, css } from 'styled-components';
import {
  ButtonStyles,
  GraphicsStyles,
  HeaderStyles,
  LinkButtonStyles,
  SplashTextStyles,
  mixins,
} from '@pointdotcom/pds';
import { pxToRem } from 'styleHelpers';

const gutterPadding = '13vw';
export const messageAnimSpeedMS = 250;
const gridTemplateColsRows = '1.7fr 3fr';

export const EmailSentContentStyle = styled.div.attrs({ className: 'EmailSentContentStyle' })`
  display: grid;
  grid-template-rows: auto;
  ${ButtonStyles.ButtonContainerStyle} {
    margin-bottom: 1.1em; // magic number to match input margin buttom
    &.tertiary {
      button {
        color: ${({ theme }) => theme.Color.GrayLightestAccessible};
      }
    }
    &.quaternary {
      button {
        background-color: ${({ theme }) => theme.Color.PurpleBlue4};
        &:hover {
          background-color: ${({ theme }) => theme.Color.PurpleBlue5};
        }
      }
    }
  }
  ${LinkButtonStyles.ButtonLinkStyle} {
    justify-self: center;
    margin-top: 2rem;
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ContentContainerStyle = styled.div.attrs({ className: 'ContentContainerStyle' })`
  display: flex;
  flex-flow: column nowrap;
  width: 50%;
  margin: 0 auto;
  z-index: 1;
  position: relative;
  ${SplashTextStyles.SplashCopySerifStyle} {
    font-size: 1.2rem;
  }
  @media (max-width: 800px) {
    ${HeaderStyles.HeaderStyle} {
      margin-bottom: clamp(2rem, 2vw, 3rem);
      > * {
        font-size: clamp(2rem, 5.8vw, 3rem);
      }
    }
    ${SplashTextStyles.SplashCopySerifStyle} {
      font-size: clamp(1rem, 2.4vw, 1.3rem);
    }
  }
  @media (max-height: 800px) {
    ${HeaderStyles.HeaderStyle} {
      margin-bottom: 2vh;
      > * {
        font-size: clamp(2rem, 5.8vh, 3rem);
      }
    }
    ${SplashTextStyles.SplashCopySerifStyle} {
      font-size: clamp(1rem, 2.5vh, 1.3rem);
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    width: 100% !important;
  }
`;
export const MessageOverlayStyle = styled.div.attrs({ className: 'MessageOverlayStyle' })<{
  animationStatus: TransitionStatus;
}>`
  --openAmount: 95%;
  display: grid;
  align-items: center;
  justify-content: center;
  align-items: center;
  transform-origin: top;
  position: absolute;
  height: 100%;
  transform: translateX(-100%);
  transition: all ${messageAnimSpeedMS}ms ease-out;
  z-index: 2;
  transform-origin: 0 0;
  background: white;
  overflow: hidden;
  width: var(--openAmount);
  box-shadow: 0 0 0 0 transparent;

  /* background: linear-gradient(
    204deg,
    ${({ theme }) => theme.Color.PurpleBlue4} 62%,
    ${({ theme }) => theme.Color.Yorange} 127%
  ); */
  ${ContentContainerStyle} {
    transition: opacity ${messageAnimSpeedMS}ms ease-in-out ${messageAnimSpeedMS / 3}ms;
    opacity: 0;
    width: 58%;
    ${HeaderStyles.HeaderStyle} {
      > * {
        font-size: clamp(2rem, 3.5vw, 2.7rem);
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    ${ContentContainerStyle} {
      width: 67%;
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    justify-self: center;
    grid-template-columns: 1fr;
    align-self: flex-start;
    width: 100%;
    height: var(--openAmount);
    ${ContentContainerStyle} {
      grid-column: auto;
      padding: 0 ${gutterPadding};
      box-sizing: border-box;
    }
    transform: ${({ animationStatus }) =>
      animationStatus.includes('enter') ? `translate(0, 0)` : `translate(0, -100%)`};
    box-shadow: ${({ animationStatus, theme }) =>
      animationStatus.includes('enter')
        ? `0 1.9rem 1.4rem -1.6rem ${mixins.rgba(theme.Color.PointBlack, 0.1)}`
        : 'none'};
  }

  ${({ animationStatus }) => {
    // handle the animation
    if (animationStatus.includes('enter')) {
      return css`
        transform: translate(0, 0);
        box-shadow: 1.9rem 0 1.4rem -1.6rem ${({ theme }) => mixins.rgba(theme.Color.PointBlack, 0.1)};

        ${ContentContainerStyle} {
          opacity: 1;
        }
      `;
    }

    return css`
      ${ContentContainerStyle} {
        transition: all ${messageAnimSpeedMS / 2}ms ease-in-out;
      }
    `;
  }}
`;

export const TitleColumnStyle = styled.section.attrs({ className: 'TitleColumnStyle' })`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all ${messageAnimSpeedMS}ms ease-in-out;
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  z-index: 2;
  &:after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    background-image: url('https://cdn.point.com/login_desktop_graphic.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom left;
    left: -20%;
    width: 190%;
    height: 32vh;
    max-height: 270px;
    transition: all ${messageAnimSpeedMS}ms ease-in-out;
    /* Using a clip path to avoid complex z-indexing on overlaid image */
    clip-path: polygon(
      0px 100%,
      10% 62%,
      18% 56%,
      17% 30%,
      19% 27%,
      34% -8%,
      44% 21%,
      51% 21%,
      55% 31%,
      66% 21%,
      76% 31%,
      82% 48%,
      83% 73%,
      107% 111%,
      0px 100%
    );
  }
  h1,
  h3 {
    line-height: 1.4em;
    font-size: ${pxToRem(18)};
  }
  h1 {
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin: 0 0 1.6em;
  }
  > div {
    width: 58%;
    max-width: 11.75rem;
    margin: -50% auto 0px;
    transition: all ${messageAnimSpeedMS}ms ease-in-out;
  }
  .messageOpen-enter &,
  .messageOpen-enter-done & {
    > div {
      transform: scale(0.95);
      opacity: 0.6;
    }
  }
  @media (max-height: 800px) {
    h1,
    h3 {
      font-size: clamp(1rem, 3vh, ${pxToRem(18)});
    }
  }
  @media (max-width: 800px) {
    h1,
    h3 {
      font-size: clamp(1rem, 2.8vw, ${pxToRem(18)});
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    align-items: center;
    justify-content: flex-start;
    padding: 0 ${gutterPadding};

    > div {
      width: 50%;
      margin: 0;
    }
    &:after {
      clip-path: none;
      background-image: url('https://cdn.point.com/login_mobile_graphic.png');
      left: auto;
      top: 0;
      right: 0;
      width: 60%;
      background-position: right bottom;
      background-size: contain;
      bottom: 0;
      top: auto;
      max-height: none;
      height: calc(100% + 150px);
    }
    h1 {
      margin: 0 0 1em;
    }
  }
`;
export const InputColumnStyle = styled.section.attrs({ className: 'InputColumnStyle' })`
  display: grid;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &:before {
    position: absolute;
    content: '';
    display: block;
    height: 70%;
    background: inherit;
    width: 50px;
    left: -50px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 0 1.5rem -0.3rem ${({ theme }) => mixins.rgba(theme.Color.PointBlack, 0.2)};
    mix-blend-mode: multiply;
    z-index: 3;
    transition: all ${messageAnimSpeedMS}ms ease-out;
    border-right: 1px solid transparent;
  }
  .messageOpen-enter &,
  .messageOpen-enter-done & {
    &:before {
      box-shadow: none;
      border-right-color: ${({ theme }) => theme.line.color.default};
    }
  }
  p {
    margin-bottom: 10%;
  }
  @media (max-width: 980px) {
    ${ContentContainerStyle} {
      width: 60%;
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    padding: 0 ${gutterPadding};
    .messageOpen-enter &,
    .messageOpen-enter-done & {
      &:before {
        border-bottom-color: ${({ theme }) => theme.line.color.default};
      }
    }
    &:before {
      width: calc(100% - ${gutterPadding});
      height: 50px;
      left: auto;
      right: 0;
      top: -50px;
      transform: translateX(0);
      border-right: none;
      border-bottom: 1px solid transparent;
    }
  }
`;

export const MainContainerStyle = styled.main.attrs({ className: 'MainContainerStyle' as string })`
  box-shadow: 0 0 1.9rem 0 ${({ theme }) => mixins.rgba(theme.Color.PointBlack, 0.1)};
  display: grid;
  grid-template-columns: ${gridTemplateColsRows};
  position: relative;
  min-height: 550px;
  background: white;
  &:before {
    ${GraphicsStyles.TextureGrayCSS};
    background-position: right top;
    content: '';
    display: block;
    position: absolute;
    width: 900px;
    height: 800px;
    top: -14vh;
    right: -4vw;
    z-index: -1;
    opacity: 0.25;
    transform: rotate(155deg);
    mix-blend-mode: multiply;
    background-size: contain;
    transition: opacity 250ms ease-out;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    &:before {
      display: none;
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    grid-template-columns: 1fr;
    grid-template-rows: ${gridTemplateColsRows};
    box-shadow: none;
    height: 90vh; // header is 10vh
    &:before {
      display: none;
    }
  }
`;

export const LoginPageStyle = styled.div.attrs({ className: 'LoginPageStyle' })`
  display: grid;
  grid-template-rows: 110px auto 110px;
  height: 100vh;
  max-height: 1100px;
  width: 80%;
  max-width: 1084px;
  margin: 0 auto;
  transition: all 250ms ease-out;
  header,
  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 250ms ease-out;
  }
  footer {
    font-size: ${pxToRem(14)};
    color: ${({ theme }) => theme.Color.Gray3};
    a {
      color: ${({ theme }) => theme.Color.GrayLightestAccessible};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  @media (max-height: 800px), (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    grid-template-rows: clamp(75px, 10vh, 100px) auto clamp(75px, 20vh, 300px);
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    width: 100%;
    max-width: none;
    header,
    footer {
      padding: 0 ${gutterPadding};
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    header {
      background: white;
      align-items: flex-end;
    }
    footer {
      flex-flow: column nowrap;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      gap: 1.2vh;
    }
  }
`;

export const LoginPageWrapperStyle = styled.div.attrs({ className: 'LoginPageWrapperStyle' })`
  height: 100vh;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
`;

export const LoginPageBodyStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.Color.GrayLight1};
  }
`;
