import React from 'react';
import { Container, Header, Size, SplashText, TemplatedText } from '@pointdotcom/pds';
import LeftRightPaginator, { PaginatorProps } from 'components/LeftRightPaginator';
import { ApplicationPageType } from '../constants';
import i18n from './i18n';
import * as styles from './styles';

interface ApplicationPageHeroProps extends PaginatorProps {
  page: ApplicationPageType;
  stringTemplateValues: Record<string, string>;
}

const pageToHeroContentMap = {
  [ApplicationPageType.AboutYou]: {
    formSectionTitle: i18n.applyToUnlock,
    formSectionDescription: i18n.youreApplyingFor,
    img: 'https://cdn.point.com/keyonpaper.png',
    imgAlt: 'key on paper',
  },
  [ApplicationPageType.Property]: {
    formSectionTitle: i18n.nowTellUs,
    formSectionDescription: null,
    img: 'https://cdn.point.com/houseandtree.png',
    imgAlt: 'house and tree',
  },
  [ApplicationPageType.Financials]: {
    formSectionTitle: i18n.almostDone,
    formSectionDescription: null,
    img: 'https://cdn.point.com/coinsandmagnify.png',
    imgAlt: 'coins and magnifying glass',
  },
  [ApplicationPageType.Review]: {
    formSectionTitle: i18n.lastStep,
    formSectionDescription: null,
    img: 'https://cdn.point.com/pen.png',
    imgAlt: 'pen on application',
  },
};

const ApplicationPageHero = ({
  currentPageNum = 1,
  totalNumPages,
  page = ApplicationPageType.AboutYou,
  onPaginate,
  stringTemplateValues,
}: ApplicationPageHeroProps) => {
  const content = pageToHeroContentMap[page];
  const { formSectionTitle, formSectionDescription, img, imgAlt } = content;

  return (
    <styles.ApplicationPageHeroStyle>
      <Container flex mobileColumn>
        <div>
          <LeftRightPaginator
            currentPageNum={currentPageNum}
            totalNumPages={totalNumPages}
            onPaginate={onPaginate}
          />
          <Header styleSize={Size.Large}>
            <TemplatedText values={stringTemplateValues}>{formSectionTitle}</TemplatedText>
          </Header>
          {formSectionDescription && <SplashText>{formSectionDescription}</SplashText>}
        </div>
        <div>
          <img src={img} alt={imgAlt} />
        </div>
      </Container>
    </styles.ApplicationPageHeroStyle>
  );
};

export default ApplicationPageHero;
