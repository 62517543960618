export default {
  whatYour: 'What your offer might look like:',
  yourOffer: 'Your offer from Point',
  cashToYou: 'Cash to you',
  debtPayoff: 'Debt payoff',
  thisMoney:
    'This money will be sent to you via electronic wire or check within 4-5 days of signing your closing documents.',
  thisIsWhat:
    'This is what you’ll pay to Point when you exit your contract. Homeowners typically get the funds to buy back their equity via a refinance, home sale, or their own savings',
  wellWrite: 'We’ll write checks to the following creditors:',
  thisIncludes:
    'This includes the fee you pay to Point for arranging the transaction and underwriting your file, and the escrow fee paid to the title company.',
  wellWork:
    'We’ll work with you to identify your highest priority debts. Once you sign your closing documents, we’ll write checks to your creditors.',
  fees: 'Fees',
  pricing: 'Pricing',
  homeownerProtectionCap: 'Homeowner Protection Cap',
  percOf: '% of Future Appreciation You’ll Pay to Point',
  processing_fee: '%fee_rate% Processing Fee',
  processing_fee_no_rate: 'Processing Fee',
  startingValue: 'Starting Value from which Appreciation will be Calculated',
  seeMyCap: 'See my cap by year',
  eligibleUpTo:
    'You’re eligible for %maximumPossibleOptionPayment%. If you’d like to increase your offer amount, just let your Account Manager know.',
  termsWillExpire:
    'These terms will expire on %humanizedExpirationDate%. If you’d like to extend the expiration date, please contact us.',
  thisOfferWillExpire: 'This offer will expire on %humanizedExpirationDate%',
  youreEligibleFor: 'You’re eligible for up to %maximumPossibleOptionPayment%',
  eligibleUpToWithExpiration1:
    'You’re eligible for %maximumPossibleOptionPayment%. These terms will expire on %humanizedExpirationDate%.',
  eligibleUpToWithExpiration2:
    'If you’d like to increase your offer amount or extend your expiration date, just let your Account Manager know.',
  previewADifferent: 'Preview a different offer amount',
};
