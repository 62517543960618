import styled from 'styled-components';
import { mixins } from '@pointdotcom/pds';

export const SectionCheckmarkIconStyle = styled.svg.attrs({
  className: 'SectionCheckmarkIconStyle',
})`
  path {
    fill: currentColor;
  }
`;

export const SectionCheckmarkStyle = styled.div.attrs({ className: 'SectionCheckmarkStyle' })`
  --size: 3rem;

  background: ${({ theme }) => mixins.rgba(theme.Color.PointBlue, 0.1)};
  color: ${({ theme }) => theme.Color.PointBlue};

  width: var(--size);
  height: var(--size);
  box-sizing: border-box;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    --size: 2.25rem;
  }
`;
