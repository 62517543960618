import styled, { keyframes } from 'styled-components';
import { ContainerStyles } from '@pointdotcom/pds';

const ana = 'https://cdn.point.com/watchVideoPage/ana_sm.png';
const backgroundAvif = 'https://cdn.point.com/watchVideoPage/background.avif';
const backgroundWebP = 'https://cdn.point.com/watchVideoPage/background.webp';
const birds = 'https://cdn.point.com/watchVideoPage/birds_sm.gif';
const birdsInv = 'https://cdn.point.com/watchVideoPage/birds_sm_inv.gif';
const foregroundAvif = 'https://cdn.point.com/watchVideoPage/foreground.avif';
const foregroundWebp = 'https://cdn.point.com/watchVideoPage/foreground.webp';

const BackgroundImageStyle = styled.div`
  background-size: 1000px;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const birdAnimation = keyframes`
  0%, 5% { 
    transform: translate(0, -100); 
  }
  50% {
    opacity: 1;
  }
  95%, 100% { 
    transform: scale(0) translate(-100px, -80px); 
    opacity: 0;
  }
`;

const birdAnimationInv = keyframes`
  0%, 5% { 
    transform: translate(0, -100); 
  }
  50% {
    opacity: 1;
  }
  95%, 100% { 
    transform: scale(0) translate(100px, -80px); 
    opacity: 0;
  }
`;

export interface BirdImgStyleProps {
  left: number;
  inverted?: boolean;
}

const BIRD_ANIM_TIME_MS = 4800;
export const BirdImgStyle = styled(BackgroundImageStyle).attrs({
  className: 'BirdImgStyle',
})<BirdImgStyleProps>`
  animation: ${({ inverted }) => (inverted ? birdAnimationInv : birdAnimation)}
    ${BIRD_ANIM_TIME_MS}ms linear;
  animation-fill-mode: forwards;
  background-image: url(${({ inverted }) => (inverted ? birdsInv : birds)});
  background-size: contain;
  width: 100px;
  height: 80px;
  left: ${({ left }) => left}%;
  bottom: 50%;
`;

const anaSize = '50px';

const horizAnimation = keyframes`
  0%, 2% { 
    transform: translateX(-${anaSize}); 
  }
  98%, 100% { 
    transform: translateX(100%); 
  }
`;

export const BackgroundStyle = styled(BackgroundImageStyle).attrs({
  className: 'BackgroundStyle',
})`
  background-image: image-set(
    url(${backgroundAvif}) type('image/avif'),
    url(${backgroundWebP}) type('image/webp')
  );
`;

export const ForegroundStyle = styled(BackgroundImageStyle).attrs({
  className: 'ForegroundStyle',
})`
  background-image: image-set(
    url(${foregroundAvif}) type('image/avif'),
    url(${foregroundWebp}) type('image/webp')
  );
`;

export const AnaImgContainerStyle = styled.div.attrs({ className: 'AnaImgContainerStyle' })`
  position: absolute;
  width: 90%;
  left: 0;
  margin-left: 3%;
  overflow: hidden;
  height: ${anaSize};
  bottom: 36px;
  @media (max-width: ${({ theme }) => theme.container.width.default}) {
    width: 100%;
    margin: 0;
  }
`;

export const AnaImgStyle = styled.div.attrs({ className: 'AnaImgStyle' })`
  width: ${anaSize};
  height: ${anaSize};
  background-image: url(${ana});
  background-size: ${anaSize};
  background-repeat: no-repeat;
  background-position: left;
`;

export const AnaStyle = styled.div.attrs({ className: 'AnaImgStyle' })`
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  display: block;
  animation: ${horizAnimation} 25s linear infinite;
`;

export const AnimatedGraphicStyle = styled.div.attrs({ className: 'AnimatedGraphicStyle' })`
  > ${ContainerStyles.ContainerStyle} {
    position: relative;
    height: 157px;
    width: 100%;
    margin: 0 auto;
    margin-top: 2rem;
  }
`;
