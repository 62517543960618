import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import bugsnagClient from 'lib/bugsnagClient';
import { homeownerApi } from 'services/api/homeownerApi';
import { HeiApplicationConsent, InProgressHeiApplication } from 'services/apiTypes/homeownerTypes';
import { RootState } from 'store';

export type ApplicationDataType = InProgressHeiApplication & {
  // This value is used on the front end form but is not required by the API
  applicant?: { ssnConfirm?: string };
} & {
  consents?: Partial<HeiApplicationConsent>;
};

export enum DocketCreationFailure {
  None = 'none',
  RedirectRequired = 'redirectRequired',
  Unknown = 'unknown',
}

export interface ProductApplicationState {
  version: number;
  data: ApplicationDataType;
  docketCreationFailure: DocketCreationFailure;
  ssnFocused: boolean;
}

export const initialState: ProductApplicationState = {
  version: 1,
  data: {},
  docketCreationFailure: DocketCreationFailure.None,
  ssnFocused: false,
};

export const modifyDataValueFromPath = ({
  path,
  data,
  value,
}: {
  path: string;
  data: ApplicationDataType;
  value?: unknown;
}) => {
  // traverse/build path to leaf
  const nodes = path.split('.');
  const leaf = nodes.pop();
  let object = data as Record<string, unknown>;
  nodes.forEach((node) => {
    if (object[node] === undefined) {
      object[node] = {};
    }
    object = object[node] as Record<string, unknown>;
  });
  if (leaf && value !== undefined) {
    object[leaf] = value;
  }
};

type RejectedAction = PayloadAction<unknown, string, { requestStatus: 'rejected' }>;

interface HosApiErrorResponse {
  payload: {
    status: string | number;
  };
}

const productApplicationSlice = createSlice({
  name: 'productApplication',
  initialState,
  reducers: {
    setData: (state, { payload }: { payload: { dataPath: string; value?: unknown } }) => {
      modifyDataValueFromPath({ path: payload.dataPath, value: payload.value, data: state.data });
    },
    setSSNFocused: (state, { payload }) => {
      state.ssnFocused = payload;
    },
    resetApplication: (state) => {
      state.data = {};
    },
    clearDocketCreationFailure: (state) => {
      state.docketCreationFailure = DocketCreationFailure.None;
    },
  },
  extraReducers: (builder) => {
    // Load saved applicaiton data into state
    builder.addMatcher(
      homeownerApi.endpoints.getApplication.matchFulfilled,
      (state, { payload }) => {
        state.data = payload.application;
      }
    );

    // Watch for a rejection on the docket create request
    builder.addMatcher(
      homeownerApi.endpoints.createDocket.matchRejected,
      (state, action: RejectedAction) => {
        const { payload } = action as HosApiErrorResponse;
        const { status } = payload;
        if ([409, 412].includes(Number(status))) {
          state.docketCreationFailure = DocketCreationFailure.RedirectRequired;
        } else {
          state.docketCreationFailure = DocketCreationFailure.Unknown;
          bugsnagClient.notify(`Unknown failure in docket creation: ${JSON.stringify(action)}`);
        }
      }
    );
  },
});

export const getApplicationData = ({ productApplication: productApplicationStore }: RootState) => {
  return productApplicationStore.data;
};

export const checkDocketCreationFailure = ({
  productApplication: productApplicationStore,
}: RootState) => {
  return productApplicationStore.docketCreationFailure;
};

export const getSSNFocused = ({ productApplication: productApplicationStore }: RootState) => {
  return productApplicationStore.ssnFocused;
};

export const { setData, resetApplication, clearDocketCreationFailure, setSSNFocused } =
  productApplicationSlice.actions;

export default productApplicationSlice.reducer;
