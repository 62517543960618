import React, { useRef } from 'react';
import { Dropdown, FormChangeEvent, HelpText, Style, normalizeToNumber } from '@pointdotcom/pds';
import { FieldProps } from '../../constants';
import * as styles from '../../styles';
import i18n from './i18n';

const getDatesOfMonth = (month: number, year: number): number[] => {
  const startDate = new Date(year, month - 1, 1); // month is 0-based
  const endDate = new Date(year, month, 0); // last day of the month

  const dates: number[] = [];
  for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
    dates.push(new Date(date).getDate());
  }

  return dates;
};

const BirthDateInput = ({
  onChange,
  onFocus,
  value: valueFromProps,
  error,
  styleSize,
  helptext,
  field,
}: Partial<FieldProps>) => {
  const months = [
    { text: 'January', value: '01' },
    { text: 'February', value: '02' },
    { text: 'March', value: '03' },
    { text: 'April', value: '04' },
    { text: 'May', value: '05' },
    { text: 'June', value: '06' },
    { text: 'July', value: '07' },
    { text: 'August', value: '08' },
    { text: 'September', value: '09' },
    { text: 'October', value: '10' },
    { text: 'November', value: '11' },
    { text: 'December', value: '12' },
  ];

  let monthValue = '';
  let dateValue = '';
  let yearValue = '';
  if (valueFromProps) {
    const regex = /^(\d{0,4})-(\d{0,2})-(\d{0,2})$/;
    const match = regex.exec(valueFromProps as string);
    if (match) {
      [, yearValue, monthValue, dateValue] = match;
    }
  }
  const dateRef = useRef<HTMLSelectElement>(null);

  const monthDates = getDatesOfMonth(
    normalizeToNumber(monthValue || 1),
    normalizeToNumber(yearValue || new Date().getFullYear())
  ).map((text) => ({
    text: String(text),
    value: String(text).padStart(2, '0'),
    key: `${monthValue}${text}`,
  }));

  const MIN_AGE = 18;

  const years = Array.from(
    { length: 100 },
    (_, index) => index - MIN_AGE + new Date().getFullYear() - 100
  ).map((text) => ({
    text: String(text),
    value: String(text),
    key: `${monthValue}${dateValue}${text}`,
  }));

  const updateDateRef = () => {
    setTimeout(() => {
      dateRef.current?.dispatchEvent(new Event('change', { bubbles: true }));
    }, 0);
  };

  const handleMonthChange: FormChangeEvent = (e, { value }) => {
    updateDateRef();
    onChange?.(e, { value: `${yearValue}-${value}-${dateValue}` });
  };

  const handleYearChange: FormChangeEvent = (e, { value }) => {
    updateDateRef();
    onChange?.(e, { value: `${value}-${monthValue}-${dateValue}` });
  };

  const handleDateChange: FormChangeEvent = (e, { value }) => {
    const useValue = value === '-1' ? '' : value;
    onChange?.(e, { value: `${yearValue}-${monthValue}-${useValue}` });
  };

  return (
    <styles.BirthDateInputStyle styleSize={styleSize}>
      <Dropdown
        disableFirst
        options={[{ text: i18n.month, value: '-1' }, ...months]}
        value={monthValue}
        onChange={handleMonthChange}
        onFocus={onFocus}
        error={error}
        styleSize={styleSize}
        aria-label={`${field?.label} - ${i18n.month}`}
      />
      <Dropdown
        disableFirst
        options={[{ text: i18n.date, value: '-1' }, ...monthDates]}
        value={dateValue}
        onChange={handleDateChange}
        onFocus={onFocus}
        ref={dateRef}
        error={error}
        styleSize={styleSize}
        aria-label={`${field?.label} - ${i18n.date}`}
      />
      <Dropdown
        disableFirst
        options={[{ text: i18n.year, value: '-1' }, ...years]}
        onFocus={onFocus}
        value={yearValue}
        onChange={handleYearChange}
        error={error}
        styleSize={styleSize}
        aria-label={`${field?.label} - ${i18n.year}`}
      />
      {helptext && <HelpText styleType={error ? Style.Error : Style.Default}>{helptext}</HelpText>}
    </styles.BirthDateInputStyle>
  );
};

export default BirthDateInput;
