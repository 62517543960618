import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { ProductType } from 'services/apiTypes/commonTypes';
import {
  OfferStatus,
  PrequalEstimatesResponse,
  PricingRangeResponse,
} from 'services/apiTypes/prequalTypes';
import {
  EstimateServiceParams,
  FetchPricingRangeParams,
  SaveEstimateParams,
  fetchEstimate,
  fetchPricingRange,
  saveEstimate as postSaveEstimate,
} from 'services/estimates';
import { Estimate } from 'types';

type ShapedEstimate = Estimate & {
  expired?: boolean;
  postalCode?: string;
  applicationUrl?: null | string;
};

// Reshape Prequal response to match Estimate model
const getShapedEstimate = (payload: PrequalEstimatesResponse): ShapedEstimate => {
  return {
    ...payload.application,
    ...payload.estimate,
    ...payload.application?.applicant,
    ...payload.application?.property?.address,
    features: payload.features,
    status:
      'status' in payload && typeof payload.status === 'string'
        ? (payload.status as OfferStatus)
        : payload?.estimate?.status,
    expired: new Date(payload?.estimate?.expires).valueOf() < Date.now(),
    postalCode: payload?.application?.property?.address?.zip,
    applicationUrl: payload?.estimate?.links?.application,
    key: payload?.estimate?.key,
  };
};

export const getEstimate = createAsyncThunk<
  Estimate, // TODO: get BE to consistently return this in all estimates
  EstimateServiceParams,
  {
    rejectValue: AxiosResponse;
  }
>('estimates/getEstimate', async (params, { rejectWithValue }) => {
  // Since the status code is relevant to the UI, it needs to get passed in here manually
  // otherwise the axios error is swallowed somewhere in the RTK chain
  try {
    const response = await fetchEstimate(params);
    if (response?.estimate?.productType === ProductType.HEI) {
      return getShapedEstimate(response);
    }
    return response as TSFixMe;
  } catch (e) {
    const error = e as AxiosError;
    const response = error?.response as AxiosResponse;
    return rejectWithValue(response);
  }
});

export const getPricingRange = createAsyncThunk<
  PricingRangeResponse,
  FetchPricingRangeParams,
  {
    rejectValue: AxiosResponse;
  }
>('estimates/getHEIPricingRange', fetchPricingRange);

export const saveEstimate = createAsyncThunk<
  Estimate,
  SaveEstimateParams,
  {
    rejectValue: AxiosResponse;
  }
>('estimates/saveEstimate', async (params, { rejectWithValue }) => {
  try {
    const response = await postSaveEstimate(params);
    return response;
  } catch (e) {
    const error = e as AxiosError;
    const response = error?.response as AxiosResponse;
    return rejectWithValue(response);
  }
});
