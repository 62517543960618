import React, { useImperativeHandle, useState } from 'react';
import {
  Button,
  DirectionAndPlacement,
  HelpText,
  HelpTextAnimated,
  Input,
  InputChangeEvent,
  InputMaskType,
  InputProps,
  Style,
  TemplatedText,
  Validation,
} from '@pointdotcom/pds';
import { PolicyTextsAsList } from 'components/Policies';
import { PolicyType } from 'components/Policies/constants';
import { Applicant } from 'types';
import i18n from './i18n';
import * as styles from './styles';

const defaultPolicies: PolicyType[] = [
  PolicyType.Contact,
  PolicyType.CreditReport,
  PolicyType.Prequalification,
];

// NOTE: the values map to i18n text
// Order matters
// Use these as values for hiddenFields prop
export enum ContactFormField {
  FirstName = 'firstName',
  LastName = 'lastName',
  EmailAddress = 'email',
  PhoneNumber = 'phone',
}

const fieldInputProps: Record<
  ContactFormField,
  Pick<InputProps, 'autoCapitalize' | 'inputMode' | 'mask' | 'maxLength'>
> = {
  [ContactFormField.FirstName]: { autoCapitalize: 'words' },
  [ContactFormField.LastName]: { autoCapitalize: 'words' },
  [ContactFormField.EmailAddress]: { autoCapitalize: 'off', inputMode: 'email' },
  [ContactFormField.PhoneNumber]: { inputMode: 'tel', mask: InputMaskType.Phone, maxLength: 14 },
};

function trimEmptyFields(value: ContactFormValue): ContactFormValue {
  const newValue = { ...value };
  Object.values(ContactFormField).forEach((fieldKey) => {
    newValue[fieldKey] = newValue[fieldKey].replace(/^\s+$/, '');
  });
  return newValue;
}

interface EstimateObjWithApplicant {
  applicant: Applicant;
}

export type ContactFormValue = {
  [ContactFormField.FirstName]: string;
  [ContactFormField.LastName]: string;
  [ContactFormField.EmailAddress]: string;
  [ContactFormField.PhoneNumber]: string;
};

export interface ContactFormProps {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  hiddenFields?: readonly ContactFormField[];
  optionalFields?: readonly ContactFormField[];
  buttonText?: string;
  helpText?: null | React.ReactNode;
  policies?: null | true | PolicyType[];
  focused?: false | ContactFormField;
  loading?: boolean;
  onSubmitValid?: (
    event: React.FormEvent<HTMLFormElement> | undefined,
    props: SubmitProps
  ) => unknown;
  onSubmitInvalid?: (
    event: React.FormEvent<HTMLFormElement> | undefined,
    fieldError: FieldError
  ) => unknown;
  showPolicyHeader?: boolean;
  policyHeaderRef?: React.RefObject<HTMLHeadingElement>;
}

interface SubmitProps {
  setLoading: (loading: boolean) => void;
  setFormError: (formError: null | string) => void;
  setValidationError: (errorField: ContactFormField) => void;
  value: ContactFormValue;
  estimateObjWithApplicant: EstimateObjWithApplicant;
}

type FieldError = Partial<Record<ContactFormField, string>>;

type SubmitHandler = (e?: React.FormEvent<HTMLFormElement>) => Promise<void>;

export interface ContactFormRef {
  submit: SubmitHandler;
}

const ContactForm = React.forwardRef<ContactFormRef, ContactFormProps>(
  (
    {
      firstName = '',
      lastName = '',
      email = '',
      phone = '',
      hiddenFields: hiddenFieldsFromProps = [],
      optionalFields = [],
      buttonText = i18n.submit,
      helpText = null,
      policies = defaultPolicies,
      focused = false,
      onSubmitValid = () => undefined,
      onSubmitInvalid = () => undefined,
      showPolicyHeader = false,
      policyHeaderRef,
      loading: loadingFromProps,
    },
    ref
  ) => {
    let hiddenFields = hiddenFieldsFromProps;
    if (typeof hiddenFields === 'string') {
      hiddenFields = [hiddenFields];
    }

    const [value, setValue] = useState<ContactFormValue>({
      [ContactFormField.FirstName]: firstName,
      [ContactFormField.LastName]: lastName,
      [ContactFormField.PhoneNumber]: phone,
      [ContactFormField.EmailAddress]: email,
    });

    const [loadingFromState, setLoadingFromState] = useState<boolean>(false);
    const [fieldError, setFieldError] = useState<FieldError>({});
    const [formError, setFormError] = useState<null | string>(null);
    const loading = loadingFromProps ?? loadingFromState;

    const setValidationError = (errorField: ContactFormField) => {
      let invalidMessage;
      // FirstName and LastName don't have i18n in Utils
      switch (errorField) {
        case ContactFormField.PhoneNumber: {
          invalidMessage = Validation.i18n.invalidPhone;
          break;
        }
        case ContactFormField.EmailAddress: {
          invalidMessage = Validation.i18n.invalidEmail;
          break;
        }
        default: {
          invalidMessage = i18n.invalidInput;
        }
      }
      setFieldError({
        ...fieldError,
        [errorField]: invalidMessage,
      });
    };

    const clearErrors = () => {
      setFieldError({});
      setFormError(null);
    };

    const getFieldError = (): { trimmedValue: ContactFormValue; validationErrors: FieldError } => {
      const required = Object.values(ContactFormField).filter(
        (fieldKey) => !optionalFields.includes(fieldKey)
      );
      const errors: FieldError = {};

      const trimmedValue = trimEmptyFields(value);

      Object.values(ContactFormField).forEach((fieldKey) => {
        const fieldValue = trimmedValue[fieldKey] || '';

        if (fieldValue.replace(/\s+/g, '').length === 0) {
          // Check for blanks
          if (required.includes(fieldKey)) {
            errors[fieldKey] = Validation.i18n.fieldRequired;
          }
        } else if (fieldKey === ContactFormField.PhoneNumber) {
          // Check the phone
          if (!Validation.patterns.phoneFormatted.test(fieldValue)) {
            errors[fieldKey] = Validation.i18n.invalidPhone;
          }
        } else if (fieldKey === ContactFormField.EmailAddress) {
          // Check the email address
          if (!Validation.patterns.email.test(fieldValue)) {
            errors[fieldKey] = Validation.i18n.invalidEmail;
          }
        }
      });

      return { trimmedValue, validationErrors: errors };
    };

    const handleSubmit: SubmitHandler = async (e) => {
      e?.preventDefault();
      if (loading) {
        return;
      }

      const { trimmedValue, validationErrors } = getFieldError();
      const isValid = Object.keys(validationErrors).length === 0;

      if (!isValid) {
        setFieldError(validationErrors);
        setLoadingFromState(false);

        if (onSubmitInvalid) {
          onSubmitInvalid(e, validationErrors);
        }
        return;
      }

      setLoadingFromState(true);
      const estimateObjWithApplicant: EstimateObjWithApplicant = {
        applicant: {
          ...trimmedValue,
          contactPolicyConsentDate: policies ? new Date().toISOString() : null,
        },
      };
      if (onSubmitValid) {
        onSubmitValid(e, {
          setLoading: setLoadingFromState,
          setFormError,
          setValidationError,
          value: trimmedValue,
          estimateObjWithApplicant,
        });
      }
    };

    const handleChange =
      (fieldKey: ContactFormField): InputChangeEvent =>
      (e: unknown, { value: fieldValue }) => {
        const newValue = { ...value };
        if (newValue[fieldKey] !== undefined) {
          newValue[fieldKey] = fieldValue;
        }
        setValue(newValue);
      };

    useImperativeHandle(ref, () => ({
      submit: handleSubmit,
    }));

    return (
      <form noValidate onSubmit={handleSubmit}>
        <styles.FieldContainerStyle>
          {Object.values(ContactFormField).map((fieldKey) => {
            // hide the name fields if we already have applicant name
            if (hiddenFields.includes(fieldKey)) return null;

            let placeholder = i18n[fieldKey];
            if (optionalFields.includes(fieldKey)) {
              placeholder = `${placeholder} ${i18n.optional}`;
            }

            return (
              <Input
                key={fieldKey}
                placeholder={placeholder}
                onChange={handleChange(fieldKey)}
                value={value[fieldKey]}
                error={!!fieldError[fieldKey]}
                helptext={fieldError[fieldKey]}
                onFocus={clearErrors}
                focused={fieldKey === focused}
                {...fieldInputProps[fieldKey]}
              />
            );
          })}
        </styles.FieldContainerStyle>
        {policies && (
          <styles.ConsentTextContainerStyle>
            {showPolicyHeader && (
              <styles.PolicyHeaderStyle ref={policyHeaderRef}>
                {i18n.termsAndConditions}
              </styles.PolicyHeaderStyle>
            )}
            <HelpText>
              <TemplatedText values={{ buttonText }}>{i18n.byClicking}</TemplatedText>
            </HelpText>
            <HelpText>
              <PolicyTextsAsList policies={policies === true ? defaultPolicies : policies} />
            </HelpText>
          </styles.ConsentTextContainerStyle>
        )}
        <Button block type="submit" loading={loading}>
          {buttonText}
        </Button>
        <HelpTextAnimated
          styleAlign={DirectionAndPlacement.Center}
          show={!!formError}
          styleMarginPosition={DirectionAndPlacement.Top}
          styleType={Style.Error}
        >
          {formError}
        </HelpTextAnimated>
        <HelpText
          styleAlign={DirectionAndPlacement.Center}
          styleMarginPosition={DirectionAndPlacement.Top}
        >
          {helpText}
        </HelpText>
      </form>
    );
  }
);

export default ContactForm;
