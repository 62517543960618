import React, { useEffect } from 'react';
import * as styles from './styles';

const YCBM_PARAMS: Record<string, string> = {
  FNAME: 'FNAME',
  LNAME: 'LNAME',
  PHONENUMBER: 'PHONENUMBER',
  EMAIL: 'EMAIL',
  team: 'team',
};

export const getYCBMParamsFromURLSearchParams = (paramSource = window.location.search) => {
  const searchParams = new URLSearchParams(paramSource);
  const ycbmParams: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    if (YCBM_PARAMS[key]) {
      ycbmParams[key] = value;
    }
  });
  return Object.keys(ycbmParams).length > 0 ? ycbmParams : null;
};

export default function ScheduleCalendar({
  iframeParams,
  calendarURL,
}: {
  iframeParams?: string;
  calendarURL: string;
}) {
  const iframeRef = React.createRef<HTMLIFrameElement>();

  useEffect(() => {
    const handleIFrameResize = (event: MessageEvent) => {
      if (event.origin === calendarURL && iframeRef.current) {
        iframeRef.current.style.height = `${event.data}px`;
      }
    };

    window?.addEventListener('message', handleIFrameResize, false);

    return () => {
      window?.removeEventListener('message', handleIFrameResize);
    };
  }, [calendarURL, iframeRef]);

  const iframeStyle = {
    width: '100%',
    height: '1000px',
    border: '0px',
    backgroundColor: 'transparent',
  };

  let iframeSrc = `${calendarURL}?noframe=true&skipHeaderFooter=true`;
  if (iframeParams) {
    iframeSrc = `${iframeSrc}&${iframeParams}`;
  }

  return (
    <styles.ScheduleCalendarStyle>
      <iframe
        src={iframeSrc}
        ref={iframeRef}
        style={iframeStyle}
        frameBorder="0"
        title="Schedule a time to speak with a Point representative"
      />
    </styles.ScheduleCalendarStyle>
  );
}
