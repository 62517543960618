import { useCallback, useEffect, useReducer } from 'react';
import { enableMapSet, produce } from 'immer';
import nullthrows from 'nullthrows';
import { messagingI18n } from '@pointdotcom/pds';
import { useUploadTaskFileMutation } from 'services/api/homeownerApi';
import i18n from './i18n';

enableMapSet();

const MAX_FILE_SIZE_BYTES = 20 * 1024 * 1024; // 20 MB

// Source: https://github.com/pointdotcom/underwrite/blob/912a7816/app/models/supporting_document.rb#L19-L28
export const supportedContentTypes = new Set([
  'image/gif',
  'image/jpeg',
  'application/msword',
  'application/pdf',
  'image/png',
  'image/tiff',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]);

declare const uploadKeySymbol: unique symbol;
export type UploadKey = string & { [uploadKeySymbol]: true };

export interface TaskFileUpload {
  uploadKey: UploadKey;
  file: File;
  documentCategory: null | string;
  progress: number;
  error: null | {
    message: string;
    isRetriable: boolean;
  };
  uploadStarted: boolean;
  uploadFinished: boolean;
  taskFileId: null | string;
}

let nextFileKeyNumber = 0;
function getUploadKey(file: File) {
  const fileKey = `${file.name}-${file.size}-${nextFileKeyNumber}`;
  nextFileKeyNumber += 1;
  return fileKey as UploadKey;
}

export enum ActionType {
  AddFiles,
  SetError,
  SetUploadStarted,
  SetProgress,
  DeleteUpload,
  SetTaskFileId,
}

type Action =
  | {
      type: ActionType.AddFiles;
      payload: Array<{
        uploadKey: UploadKey;
        file: File;
        documentCategory: null | string;
      }>;
    }
  | {
      type: ActionType.SetError;
      payload: { uploadKey: UploadKey; message: string; isRetriable?: boolean };
    }
  | { type: ActionType.SetUploadStarted; payload: { uploadKey: UploadKey } }
  | { type: ActionType.SetProgress; payload: { uploadKey: UploadKey; progress: number } }
  | { type: ActionType.DeleteUpload; payload: UploadKey }
  | { type: ActionType.SetTaskFileId; payload: { uploadKey: UploadKey; taskFileId: string } };

const reducer = (
  state: Map<UploadKey, TaskFileUpload>,
  action: Action
): Map<UploadKey, TaskFileUpload> => {
  switch (action.type) {
    case ActionType.AddFiles:
      return produce(state, (draft) => {
        action.payload.forEach(({ uploadKey, file, documentCategory }) => {
          draft.set(uploadKey, {
            uploadKey,
            file,
            documentCategory,
            progress: 0,
            error: null,
            uploadStarted: false,
            uploadFinished: false,
            taskFileId: null,
          });
        });
      });

    case ActionType.SetError:
      return produce(state, (draft) => {
        const { uploadKey, message, isRetriable = false } = action.payload;
        const upload = nullthrows(draft.get(uploadKey));
        upload.error = {
          message,
          isRetriable,
        };
        upload.uploadFinished = true;
      });

    case ActionType.SetUploadStarted:
      return produce(state, (draft) => {
        const { uploadKey } = action.payload;
        nullthrows(draft.get(uploadKey)).uploadStarted = true;
      });

    case ActionType.SetProgress:
      return produce(state, (draft) => {
        const { uploadKey, progress } = action.payload;
        nullthrows(draft.get(uploadKey)).progress = progress;
      });

    case ActionType.DeleteUpload:
      return produce(state, (draft) => {
        draft.delete(action.payload);
      });

    case ActionType.SetTaskFileId: {
      return produce(state, (draft) => {
        const { uploadKey, taskFileId } = action.payload;
        const upload = nullthrows(draft.get(uploadKey));
        upload.taskFileId = taskFileId;
        upload.uploadFinished = true;
      });
    }

    default:
      return state;
  }
};

export interface TaskFileUploader {
  uploads: Map<UploadKey, TaskFileUpload>;
  addFiles: (files: File[], documentCategory?: null | string) => void;
  deleteUpload: (uploadKey: UploadKey) => void;
}

export const useTaskFileUpload = (taskId: string): TaskFileUploader => {
  const [uploads, dispatch] = useReducer(reducer, new Map<UploadKey, TaskFileUpload>());

  const [uploadTaskFile] = useUploadTaskFileMutation();

  const uploadToHomeownerService = useCallback(
    async (uploadKey: UploadKey, { file, documentCategory }: TaskFileUpload) => {
      if (!supportedContentTypes.has(file.type)) {
        dispatch({
          type: ActionType.SetError,
          payload: { uploadKey, message: i18n.fileTypeUnsupported },
        });
        return;
      }
      if (file.size > MAX_FILE_SIZE_BYTES) {
        dispatch({
          type: ActionType.SetError,
          payload: { uploadKey, message: i18n.fileSizeExceedsLimit },
        });
        return;
      }

      dispatch({ type: ActionType.SetUploadStarted, payload: { uploadKey } });
      dispatch({ type: ActionType.SetProgress, payload: { uploadKey, progress: 0 } });

      try {
        const response = await uploadTaskFile({
          taskId,
          file,
          documentCategory: documentCategory ?? undefined,
          onUploadProgress: (event) => {
            const { loaded, total } = event;
            if (total != null && total > 0) {
              dispatch({
                type: ActionType.SetProgress,
                payload: {
                  uploadKey,
                  progress: loaded / total,
                },
              });
            }
          },
        });

        if ('data' in response) {
          dispatch({
            type: ActionType.SetTaskFileId,
            payload: {
              uploadKey,
              taskFileId: response.data.fileId,
            },
          });
        } else {
          let message: string = messagingI18n.errors.unknownError;
          if ('status' in response.error) {
            switch (response.error.status) {
              case 415: // "Unsupported Media Type"
                message = i18n.fileTypeUnsupported;
            }
          }
          dispatch({
            type: ActionType.SetError,
            payload: {
              uploadKey,
              message,
            },
          });
        }
      } catch (error) {
        dispatch({
          type: ActionType.SetError,
          payload: {
            uploadKey,
            message:
              error instanceof Error
                ? `Error: ${error.message}`
                : messagingI18n.errors.unknownError,
            isRetriable: true,
          },
        });
        return;
      }
    },
    [taskId, uploadTaskFile]
  );

  useEffect(() => {
    for (const [uploadKey, upload] of uploads.entries()) {
      if (!upload.uploadStarted && !upload.uploadFinished) {
        uploadToHomeownerService(uploadKey, upload);
      }
    }
  }, [uploadToHomeownerService, uploads]);

  const addFiles = useCallback((files: File[], documentCategory: null | string = null) => {
    dispatch({
      type: ActionType.AddFiles,
      payload: files.map((file) => ({
        uploadKey: getUploadKey(file),
        file,
        documentCategory,
      })),
    });
  }, []);
  const deleteUpload = useCallback((uploadKey: UploadKey) => {
    dispatch({ type: ActionType.DeleteUpload, payload: uploadKey });
  }, []);

  return { uploads, addFiles, deleteUpload };
};
