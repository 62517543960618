import styled, { css } from 'styled-components';
import { IconStyles, Modal, ModalStyles, UnstyledButtonStyles, mixins } from '@pointdotcom/pds';

// import Modal from '../../Modal';

const TRANSITION_SPEED_MS = 200;
const MENU_ARROW_WIDTH_PX = 40;
const TOOLBAR_HEIGHT = '38px';

export const playIcon = encodeURIComponent(`
  <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 13L-1.14193e-06 25.1244L-8.1987e-08 0.875644L21 13Z" fill="white"/>
  </svg>
`);

export const pauseIcon = encodeURIComponent(`
  <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="16" y1="1.31134e-07" x2="16" y2="21" stroke="white" stroke-width="6"/>
    <line x1="3" y1="1.31134e-07" x2="3" y2="21" stroke="white" stroke-width="6"/>
  </svg>
`);

export const menuIcon = encodeURIComponent(`
  <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line y1="2" x2="30" y2="2" stroke="white" stroke-width="4"/>
    <line y1="13" x2="30" y2="13" stroke="white" stroke-width="4"/>
    <line y1="24" x2="30" y2="24" stroke="white" stroke-width="4"/>
  </svg>
`);

export const expandIcon = encodeURIComponent(`
  <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.4 2H2V11.5333" stroke="white" stroke-width="4"/>
    <path d="M19.3333 28L29.7333 28L29.7333 18.4667" stroke="white" stroke-width="4"/>
    <path d="M29.7333 11.5333L29.7333 2L19.3333 2" stroke="white" stroke-width="4"/>
    <path d="M2 18.4667L2 28L12.4 28" stroke="white" stroke-width="4"/>
  </svg>
`);

export const VideoPlayerStyle = styled.div<{ isLandscape: boolean }>`
  ${({ theme, isLandscape }) => {
    const menuDist = '70%';
    return css`

      ${() => {
        if (isLandscape) {
          return css`
            @media all and not (display-mode: fullscreen) {
              .video-js.vjs-fluid,
              video.video-js,
              video.vjs-tech {
                max-height: 100vh;
                position: relative !important;
                width: 100%;
                height: auto !important;
                max-width: 100% !important;
                padding-top: 0 !important;
                line-height: 0;
              }
            }
          `;
        }
        return null;
      }}

      .video-js {
        font-size: 16px;
        color: ${theme.Color.PointBlack};
        position: relative;
        display: block;
        background: ${theme.Color.PointBlack};
      }

      /* General control bar styling */
      .vjs-progress-control {
        z-index: 5 !important;
        position: absolute;
        transition: top ${TRANSITION_SPEED_MS}ms ease-in-out;
        top: -14px;
        width: 100%;
        height: 20px;
        @media (max-width: ${theme.responsive.largestMobileScreen}) {
          top: -12px;
        }
        @media (max-width: ${theme.responsive.largestMobileScreen}) and (display-mode: fullscreen) {
          top: -24px;
        }
      }
      .vjs-control-bar {
        transition: height ${TRANSITION_SPEED_MS}ms ease-in-out !important;
        transition-delay: 0ms !important;
        opacity: 1 !important;
        background-color: ${mixins.rgba(theme.Color.PointBlack, 0.9)};
        /* @media (max-width: ${theme.responsive.largestMobileScreen}) {
          height: 0px;
        }
        @media (max-width: ${theme.responsive.largestMobileScreen}) and (display-mode: fullscreen) {
          height: 30px;
        } */
      }
      /* This makes the control bar hide */
      .vjs-user-inactive .vjs-control-bar {
        transition: height ${TRANSITION_SPEED_MS}ms ease-in-out;
        height: 0px;
      }
      /* This makes the progress bar visible when control bar is hidden */
      .vjs-user-inactive .vjs-progress-control {
        top: -12px;
      }
      .vjs-picture-in-picture-control {
        display: none;
      }
      .vjs-progress-holder {
        margin: 0 !important;
        overflow: visible !important;
        z-index: 6 !important;
      }

      .vjs-slider {
        background: transparent;
      }

      /* The bar that shows how much of the video has loaded */
      .vjs-load-progress {
        /* display: none !important; */
        background: transparent;
        div {
          background: rgba(255,255,255,0.3);
        }
      }

      /* Hide the volume control */
      .vjs-volume-panel, .vjs-mute-control {
        display: none;
      }

      /* The bar that shows how much of the video was watched */
      .vjs-play-progress {
        background-color: ${theme.Color.Yorange};
        color: ${theme.Color.Yorange};
        &:before {
          text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        }
      }

      /* Settings for all buttons */
      .vjs-button > * {
        &:before {
          background-repeat: no-repeat !important;
          background-position: center center !important;
        }
      }

      /* Icon tweaks */
      .vjs-play-control.vjs-paused > .vjs-icon-placeholder {
        &:before {
          content: '';
          background: url('data:image/svg+xml,${playIcon}');
          background-size: 14px;
        }
      }
      .vjs-play-control.vjs-playing > .vjs-icon-placeholder {
        &:before {
          content: '';
          background: url('data:image/svg+xml,${pauseIcon}');
          background-size: 14px;
        }
      }
      .vjs-chapters-button .vjs-icon-placeholder {
        &:before {
          content: '';
          background: url('data:image/svg+xml,${menuIcon}');
          background-size: 22px;
        }
      }
      .vjs-mute-control .vjs-icon-placeholder {
        &:before {
          padding-top: 4px;
        }
      }
      .vjs-fullscreen-control .vjs-icon-placeholder {
        &:before {
          content: '';
          background: url('data:image/svg+xml,${expandIcon}');
          background-position: center center;
          background-size: 20px;
        }
      }

      .vjs-time-tooltip {
        font-size: ${mixins.getRemFromPx(14, theme.globals.baseFontSize.default)} !important;
      }

      .vjs-mouse-display .vjs-time-tooltip {
        background-color: ${theme.Color.PointBlack};
      }

      .vjs-spacer-fill {
        flex: 1 1 auto !important;
      }

      /* Chapter tweaks */
      .vjs-menu {
        right: 0;
        left: auto;
        filter: drop-shadow(0px 0px 35px rgba(0, 0, 0, 0.1));
      }

      /* dont show on hover */
      .vjs-workinghover .vjs-menu-button-popup:hover .vjs-menu {
        display: none;
      }
      .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
        color: ${theme.Color.PointBlack};
        font-size: ${theme.button.fontSize.small?.default};
        background-color: rgba(255, 255, 255, 0.97);
        bottom: 1em;
        width: 160%;
        left: auto;
        right: calc(-28% + ${MENU_ARROW_WIDTH_PX / 2}px);
        /* max-height: 600px; */
        padding-bottom: 40px;
        max-height: calc(100vh - ${TOOLBAR_HEIGHT});
        clip-path: polygon(
          0 0,
          100% 0,
          100% calc(100% - ${MENU_ARROW_WIDTH_PX / 2}px),
          calc(${menuDist} + ${MENU_ARROW_WIDTH_PX}px) calc(100% - ${MENU_ARROW_WIDTH_PX / 2}px),
          calc(${menuDist} + ${MENU_ARROW_WIDTH_PX / 2}px) 100%,
          ${menuDist} calc(100% - ${MENU_ARROW_WIDTH_PX / 2}px),
          0 calc(100% - ${MENU_ARROW_WIDTH_PX / 2}px)
        );
      }
      .vjs-menu li.vjs-menu-title {
        text-align: left;
        line-height: normal;
        padding: 3.5em 1.5em 1.5em;
      }
      .vjs-menu li.vjs-menu-item {
        padding: 1em 1.5em;
        border-top: 1px solid ${theme.Color.Gray1};
        text-align: start;
        font-size: inherit;
        position: relative;
        &:hover {
          background: ${theme.Color.Gray1};
        }
      }
      .vjs-menu li.vjs-menu-item.vjs-selected {
        &:after {
          content: '';
          border-radius: 50%;;
          display: block;
          width: 10px;
          height: 10px;
          background-color: ${theme.Color.Yorange}; !important;
          position: absolute;
          right: 1rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .vjs-menu {
        z-index: 15;
      }
      .vjs-menu li {
        text-transform: none;
      }
      .vjs-big-play-button {
        border-radius: 0%;
        border: none;
        width: 100px;
        height: 100px;
        background-color: ${mixins.rgba(theme.Color.PointBlack, 0)};
        left: calc(50% - 30px);
        top: calc(50% - 30px);
        border-radius: 40px;
        margin: 0;
        > .vjs-icon-placeholder {
          display: block;
          &:before {
            content: "";
            transition: all 200ms ease-in-out;
            position: absolute;
            left: calc(50% - 13px);
            top: calc(50% - 10px);
            width: 40%;
            height: 40%;
            background: url('data:image/svg+xml,${playIcon}');
            background-repeat: no-repeat;
            background-size: contain;
            transform: translate(-50%, -50%) scale(1);
            filter: drop-shadow(0px 0px 10px #000);
          }
        }
      }
      .video-js:hover .vjs-big-play-button, .video-js .vjs-big-play-button:focus {
        background-color: ${mixins.rgba(theme.Color.PointBlack, 0)};
        > .vjs-icon-placeholder {
          display: block;
          &:before {
            transform: translate(-50%, -50%) scale(0.9);
          }
        }
      }
      @media (max-width: ${theme.responsive.largestMobileScreen}) {
        .vjs-paused.vjs-has-started .vjs-big-play-button {
          display: block;
        }
        .vjs-paused {
          video {
            opacity: 0.3;
          }
        }
        .vjs-chapters-button {
          display: none;
        }
      }
    `;
  }}
`;

export const ButtonStyleModifiedStyle = styled(UnstyledButtonStyles.UnstyledButtonStyle).attrs({
  className: 'ButtonStyleModifiedStyle',
  // TODO: get props from UnstyledButtonStyles.UnstyledButtonStyle
})<{ onClick: () => void }>`
  button {
    border: 0;
  }

  text-align: left;
  display: flex;
  gap: 1em;
  padding: 1em ${({ theme }) => theme.container.gutter.default};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid ${({ theme }) => theme.line.color.default};
`;

export const MobileChapterContainerStyle = styled.div.attrs({
  className: 'MobileChapterContainerStyle' as string,
})`
  padding-bottom: 0;
`;

export const MobileChapterTitleStyle = styled.div.attrs({
  className: 'MobileChapterTitleStyle' as string,
})<{
  isOpen?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em ${({ theme }) => theme.container.gutter.default};
  font-weight: bold;
  border-bottom: 1px solid ${({ theme }) => theme.line.color.default};
  ${IconStyles.IconStyle} {
    path {
      fill: ${({ theme }) => theme.line.color.default};
    }
    transition: transform 100ms;
    transform: scale(0.8) rotate(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
  }
`;

export const MobileChapterListStyle = styled.div.attrs({
  className: 'MobileChapterListStyle' as string,
})<{ isOpen?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: max-height ${TRANSITION_SPEED_MS}ms;
  height: ${({ isOpen }) => (isOpen ? '100%' : '0')};
`;

export const CircleProgressStyle = styled.div.attrs({
  className: 'CircleProgressStyle' as string,
})<{ progress: number }>`
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  background: conic-gradient(
    ${({ theme }) => theme.Color.Yorange} ${({ progress }) => progress * 360}deg,
    ${({ theme }) => theme.line.color.default} 0deg
  );
  font-size: 0;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    background-color: white;
    width: 60%;
    height: 60%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
`;

export const ModalVideoStyle = styled(Modal).attrs({ className: 'ModalVideoStyle' as string })<{
  isLandscape?: boolean;
}>`
  ${ModalStyles.UpperHeaderStyle} {
    position: absolute;
    padding: calc(2rem - 9px);
    z-index: 2;
    mix-blend-mode: difference;
  }
  ${ModalStyles.ModalContainerInnerStyle} {
    padding: 4rem;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${ModalStyles.ModalContainerInnerStyle} {
      padding: 0;
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    ${({ isLandscape }) => {
      if (isLandscape) {
        return css`
          ${ModalStyles.ModalContainerInnerStyle} {
            padding: 0;
          }
          ${ModalStyles.ModalContentStyle} {
            padding: 0;
            width: 100%;
            margin: 0%;
            max-width: none;
          }
        `;
      }
      return null;
    }}
  }
`;
