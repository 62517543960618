import styled from 'styled-components';
import { CapShieldStyle } from '../CapShield/styles';

export const CapModalSectionStyle = styled.section.attrs({
  className: 'CapModalSectionStyle',
})`
  padding: 3.75rem 5rem;
  border-top: 1px solid ${({ theme }) => theme.line.color.default};

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    padding: 2.5rem;
  }

  &:first-child {
    border-top: none;
    padding-top: 0;
  }

  & p:last-child {
    margin: 0;
  }

  aside {
    font-size: 0.875rem;
    font-style: italic;
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  }

  ${CapShieldStyle} {
    width: clamp(94px, 24%, 132px);
    margin-top: -1.5rem;
    margin-bottom: 2.5rem;
  }
`;
