import React from 'react';
import { TemplatedText, directory } from '@pointdotcom/pds';
import { PoliciesProps, PolicyType, defaultPolicies } from './constants';
import { inlinePolicyTexts, policyLinkTexts, policyTexts } from './i18n';

const { pointLink } = directory;

const templateValues = {
  cellularPhoneContactPolicyLinkText: () => (
    <a href={pointLink.terms.cellular} target="_blank" rel="noreferrer">
      {policyLinkTexts.cellularPhoneContactPolicyLinkText}
    </a>
  ),
  termsOfUseLinkText: () => (
    <a href={pointLink.terms.use} target="_blank" rel="noreferrer">
      {policyLinkTexts.termsOfUseLinkText}
    </a>
  ),
  creditReportPolicyLinkText: () => (
    <a href={pointLink.terms.credit} target="_blank" rel="noreferrer">
      {policyLinkTexts.creditReportPolicyLinkText}
    </a>
  ),
  privacyPolicyLinkText: () => (
    <a href={pointLink.terms.privacy} target="_blank" rel="noreferrer">
      {policyLinkTexts.privacyPolicyLinkText}
    </a>
  ),
  ecesLinkText: () => (
    <a href={pointLink.terms.eces} target="_blank" rel="noreferrer">
      {policyLinkTexts.ecesLinkText}
    </a>
  ),
};

export const InlineContactPolicyConsent = ({
  withGCShareMessage,
}: {
  withGCShareMessage?: boolean;
}) => {
  const contactPolicy = (
    <TemplatedText values={templateValues}>{inlinePolicyTexts.contact}</TemplatedText>
  );

  return (
    <>
      {withGCShareMessage
        ? [contactPolicy, policyTexts.share].map((message, i) => (
            <p key={typeof message === 'string' ? message : i}>{message}</p>
          ))
        : contactPolicy}
    </>
  );
};

export const policiesTextsAsArray = (policies: Array<PolicyType> = defaultPolicies) => {
  return policies.map((policy) => {
    const policyText = policyTexts[policy];
    return policyText ? <TemplatedText values={templateValues}>{policyText}</TemplatedText> : null;
  });
};

export const PolicyTextsAsList = ({ policies = defaultPolicies }: PoliciesProps) => {
  return (
    <ul>
      {policies.map((policy) => {
        const policyText = policyTexts[policy];
        return policyText ? (
          <li key={policyText}>
            <TemplatedText values={templateValues}>{policyText}</TemplatedText>
          </li>
        ) : null;
      })}
    </ul>
  );
};
