import styled from 'styled-components';
import { mixins } from '@pointdotcom/pds';

export { DashboardStyle } from 'containers/DashboardPage/styles';

export const HighlightedNumberStyle = styled.span.attrs({ className: 'HighlightedNumberStyle' })`
  display: inline-block;
  background: ${({ theme }) => mixins.rgba(theme.Color.PointBlue, 0.13)};
  padding: 0.05em 0.125em;
  margin: -0.05em -0.125em;
`;
