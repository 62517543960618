import React, { useEffect, useState } from 'react';
import { ApplicationLoadingPage } from 'components/ApplicationLoading';
import { BannerMessageType, getMessageFromType } from 'components/GenericMessageBanner';
import { generateUrlFromPage, getPathFromPage, pages } from 'containers/helpers';
import { useUtmParameters } from 'containers/prequal/hooks';
import { getNextPage } from 'containers/prequal/productPageFlow';
import { useHistory, useParams } from 'containers/routerHelpers';
import { useLazyGetOfferCodeDetailQuery } from 'services/api/prequalApi';
import { Products } from 'store/constants';
import i18n from './i18n';

export const TOTAL_LOAD_TIME_MS = 3000;
const OfferCodeDirectLandingPage = () => {
  useUtmParameters();

  const [loading, setLoading] = useState(false);
  const [getOfferCodeDetail, { isError: offerCodeError }] = useLazyGetOfferCodeDetailQuery();
  const headerUrl = generateUrlFromPage(pages.PREQUAL_OFFER_CODE);
  const history = useHistory();
  const { offerCode } = useParams();

  // Use an minimum artifical wait time so that there is no blink in the graphic

  useEffect(() => {
    setLoading(true);
    const timeStart = new Date().getTime();
    let timeout;

    const onComplete = () => {
      setLoading(false);
      clearTimeout(timeout);
    };

    const onSuccess = () => {
      onComplete();
      history.replace(getNextPage(Products.HEI, pages.PREQUAL_OFFER_CODE_DIRECT));
    };

    const onFail = () => {
      onComplete();
      history.push(getPathFromPage(pages.PREQUAL_DEFAULT));
    };

    const processOfferCode = async () => {
      if (!offerCode) return;
      try {
        const response = await getOfferCodeDetail({ offerCode }).unwrap();
        if (response.error) {
          onFail();
          return;
        }

        const timeEnd = new Date().getTime();
        const waitTime = timeEnd - timeStart;

        if (waitTime < TOTAL_LOAD_TIME_MS) {
          const timeDiff = TOTAL_LOAD_TIME_MS - waitTime;
          timeout = setTimeout(() => {
            onSuccess();
          }, timeDiff);
        } else {
          onSuccess();
        }
      } catch (_) {
        onFail();
      }
    };
    processOfferCode();
    return () => {
      onComplete();
    };
  }, [offerCode, getOfferCodeDetail, history]);

  return (
    <ApplicationLoadingPage
      error={offerCodeError}
      errorParagraphText={getMessageFromType(BannerMessageType.GenericError)}
      headerUrl={headerUrl}
      title={i18n.fetchingYourOffer}
      loading={loading}
      headerText={i18n.fetchingYourOffer}
      maxWidth="600px"
    />
  );
};

export default OfferCodeDirectLandingPage;
