import styled, { css } from 'styled-components';
import { Style } from '@pointdotcom/pds';
import { HrVrProps } from './constants';

const baseCss = css<HrVrProps>`
  background-color: ${({ styleType, theme }) =>
    styleType === Style.InvertedSubtle
      ? theme.line.color.invertedSubtle
      : theme.line.color.default};
`;

export const HrStyle = styled.div.attrs({ className: 'HrStyle' })<HrVrProps & { width?: string }>`
  ${baseCss}
  height: 1px;
  width: ${({ width }) => (width ? width : undefined)};
`;

export const VrStyle = styled.div.attrs({ className: 'VrStyle' })<HrVrProps & { height?: string }>`
  ${baseCss}
  width: 1px;
  height: ${({ height }) => (height ? height : undefined)};
`;
