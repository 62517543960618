import {
  useDispatch as useUntypedDispatch,
  useSelector as useUntypedSelector,
  useStore as useUntypedStore,
} from 'react-redux';
import { AppDispatch } from 'store';

// This file is a placeholder for adding typed versions of `useDispatch` and `useSelector` after upgrading to react-redux v9.

export const useDispatch: () => AppDispatch = useUntypedDispatch;
export const useSelector = useUntypedSelector;
export const useStore = useUntypedStore;
