import React from 'react';
import * as styles from './styles';

export const LifeSaverIcon = () => {
  return (
    <styles.LifeSaverIconStyle
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.26 10.14C48.61 4.49 40.81 1 32.2 1C23.59 1 15.78 4.5 10.14 10.15C4.49 15.79 1 23.58 1 32.2C1 40.82 4.49 48.61 10.14 54.26C15.79 59.92 23.59 63.41 32.2 63.41C40.81 63.41 48.61 59.92 54.26 54.27C59.91 48.62 63.4 40.82 63.4 32.2C63.4 23.58 59.91 15.78 54.26 10.14ZM32.2 53.33C26.37 53.33 21.08 50.96 17.26 47.14C13.43 43.32 11.07 38.04 11.07 32.2C11.07 26.36 13.43 21.09 17.26 17.27C21.07 13.44 26.36 11.07 32.2 11.07C38.04 11.07 43.32 13.44 47.14 17.26C50.97 21.08 53.33 26.36 53.33 32.2C53.33 38.04 50.96 43.33 47.13 47.14C43.31 50.96 38.03 53.33 32.2 53.33Z"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M54.2599 54.2699L47.1299 47.1399" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M17.2601 17.2699L10.1401 10.1499" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M17.2601 47.1399L10.1401 54.2599V54.2699" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M54.2601 10.1399L47.1401 17.2599" strokeWidth="2" strokeMiterlimit="10" />
    </styles.LifeSaverIconStyle>
  );
};
