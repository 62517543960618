import bugsnagClient from 'lib/bugsnagClient';

// NOTE: The below allows a way for a banner message (toast) to be set in any context of the app.
export const BANNER_STORAGE_KEY = 'banner';

export const clearBannerMessageFromStorage = () => {
  window.sessionStorage.removeItem(BANNER_STORAGE_KEY);
};

export const addBannerToSessionStorage = (bannerObj) => {
  window.sessionStorage.setItem(BANNER_STORAGE_KEY, JSON.stringify(bannerObj));
};

export const getBannerFromSessionStorage = () => {
  const bannerInStorage = window.sessionStorage.getItem(BANNER_STORAGE_KEY);
  let banner;
  if (bannerInStorage) {
    try {
      banner = JSON.parse(bannerInStorage);
    } catch (e) {
      bugsnagClient.notify(`Could not parse banner - ${bannerInStorage}`);
    }
  }
  return banner;
};
