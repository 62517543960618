export default {
  an: 'an',
  a: 'a',
  heiPageTitle: 'Point HEI - Call Scheduled',
  youreNowNoData: 'You’re now scheduled for a phone call',
  wereGoing:
    'We’re going to be taking you through the basics of how Point works. We want you to have enough information to decide whether %aOrAnProductName% is right for your situation. You’ll have a chance to ask as many questions as you like.',
  inThe: 'In the meantime, here’s a %brochureLinkText% that explains a bit more about Point.',
  lookingForward: 'Looking forward to talking with you!',
  brochureLinkText: 'brochure',
  youreNowPreHeader: 'You’re now scheduled for',
  dateAtTime: '%date% at %time%',
  withName: 'with %name%.',
  we: 'We',
  nameWillCallYouAtPhone: '%firstName% will call you at %phone%.',
  duringTheCall:
    'During the call you’ll learn the basics of how Point works and have a chance to ask as many questions as you like. Before the call, please fill out the application if you have time. We’re experiencing high demand and completing your application early secures your place in line.',
  startApplication: 'Start application',
};
