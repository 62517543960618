import styled, { keyframes } from 'styled-components';
import fishingImage from './images/fishing.png';

const stringMovement = keyframes`
   0% {
    d: path("M50,10 C20,30 80,70 50,190");
  }
  10% {
    d: path("M50,10 C10,50 90,90 50,190");
  }
  20% {
    d: path("M50,10 C5,70 95,110 50,190");
  }
  30% {
    d: path("M50,10 C15,60 85,100 50,190");
  }
  40% {
    d: path("M50,10 C25,50 75,90 50,190");
  }
  50% {
    d: path("M50,10 C35,40 65,80 50,190");
  }
  60% {
    d: path("M50,10 C25,50 75,90 50,190");
  }
  70% {
    d: path("M50,10 C15,60 85,100 50,190");
  }
  80% {
    d: path("M50,10 C5,70 95,110 50,190");
  }
  90% {
    d: path("M50,10 C10,50 90,90 50,190");
  }
  100% {
    d: path("M50,10 C20,30 80,70 50,190");
  }
`;

const boatAnimation = keyframes`
    0% {
    transform: translateY(0px) rotate(0deg);
  }
  15% {
    transform: translateY(-2px) rotate(-1.2deg); /* Slight tilt backward */
  }
  30% {
    transform: translateY(-4px) rotate(-0.7deg); /* Upward, starting to roll forward */
  }
  50% {
    transform: translateY(-3px) rotate(1.1deg); /* Tilt forward as it comes down */
  }
  70% {
    transform: translateY(0px) rotate(0.6deg); /* Settling at the bottom with slight forward roll */
  }
  85% {
    transform: translateY(-1px) rotate(-1deg); /* Small bounce upward, leaning back */
  }
  100% {
    transform: translateY(0px) rotate(0deg); /* Back to neutral */
  }
`;

export const FishingLineStyle = styled.svg.attrs({ className: 'FishingLineStyle' })`
  position: absolute;
  width: 20%;
  path {
    animation: ${stringMovement} 7s ease-in-out infinite;
    stroke: #ccc;
    stroke-width: 2;
  }
`;

export const FishingGraphicStyle = styled.div.attrs({ className: 'FishingGraphicStyle' })`
  --fishingGraphicSize: 62%;
  width: var(--fishingGraphicSize);
  padding-bottom: calc(var(--fishingGraphicSize) * 0.35);
  position: absolute;
  bottom: -5%;
  right: -3%;
  overflow: hidden;
`;
export const FishingBoatGraphicStyle = styled.div.attrs({ className: 'FishingBoatGraphicStyle' })`
  animation: ${boatAnimation} 7s ease-in-out infinite;
  background-image: url('${fishingImage}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom -1px;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  bottom: -5%;
  ${FishingLineStyle} {
    &:first-child {
      left: 9%;
      transform: rotate(17deg);
      height: 124%;
      bottom: -16%;
    }
    &:last-child {
      path {
        animation-duration: 12s;
      }
      right: 10%;
      transform: rotate(-17deg);
      height: 124%;
      bottom: -43%;
    }
  }
`;
