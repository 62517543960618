import React from 'react';
import {
  Container,
  DirectionAndPlacement,
  Header,
  Size,
  TemplatedText,
  templatedString,
} from '@pointdotcom/pds';
import navI18n from 'components/MainHeader/i18n';
import { getNavItemProp, navItems } from 'components/MainHeader/nav';
import { EstimatorPageInnerContentStyle } from 'containers/EstimatorPage/styles';
import { useFollowUpFetch } from 'containers/prequal/hooks';
import { HeroContainerStyle, SplashCopySerifStyle } from 'styles/';
import ClosingDisclosurePage from '..';
import i18n from './i18n';
import {
  BikeImageMobileStyle,
  BikeImageStyle,
  ListItemStyle,
  ListStyle,
  OverviewContentStyle,
  OverviewHeroLeftStyle,
  OverviewHeroRightStyle,
} from './styles';

const ClosingDisclosureOverviewPage = () => {
  const followUpFetchResults = useFollowUpFetch();
  const { followUp } = followUpFetchResults;
  const getText = getNavItemProp('text');
  const listItems = [
    [i18n.reviewYourFinalOfferHead, i18n.reviewYourFinalOfferTail],
    [i18n.estimateCostsHead, i18n.estimateCostsTail],
    [
      templatedString({
        template: i18n.checkAgreementHead,
        values: {
          agreementPageName: getText(navItems.closingDisclosureAboutAgreement),
        },
      }),
      i18n.checkAgreementTail,
    ],
    [i18n.reviewDocumentsHead, i18n.reviewDocumentsTail],
    [i18n.tellDecisionHead, i18n.tellDecisionTail],
  ];
  const splashCopy = followUp?.isClosingDisclosure()
    ? i18n.closeToCashingClosing
    : i18n.closeToCashing;

  return (
    <ClosingDisclosurePage pageName={navI18n.overview} followUpFetchResults={followUpFetchResults}>
      <HeroContainerStyle styleSize={Size.Small}>
        <Container>
          <EstimatorPageInnerContentStyle>
            <OverviewHeroLeftStyle>
              <Header styleSize={Size.Splash} styleAlignMobile={DirectionAndPlacement.Center}>
                <TemplatedText values={{ name: followUp?.getContact().firstName }}>
                  {i18n.homeStretchNow}
                </TemplatedText>
                <BikeImageMobileStyle />
              </Header>
            </OverviewHeroLeftStyle>
            <OverviewHeroRightStyle>
              <SplashCopySerifStyle>
                <p>
                  <TemplatedText
                    values={{
                      offerAmount: followUp?.getFormattedOfferAmount(),
                    }}
                  >
                    {splashCopy}
                  </TemplatedText>
                </p>
              </SplashCopySerifStyle>
            </OverviewHeroRightStyle>
          </EstimatorPageInnerContentStyle>
        </Container>
      </HeroContainerStyle>
      <Container>
        <OverviewContentStyle>
          <ListStyle>
            {listItems.map(([head, tail]) => {
              return (
                <ListItemStyle key={head.slice(5)}>
                  {head} <span className="unbold">{tail}</span>
                </ListItemStyle>
              );
            })}
          </ListStyle>
          <BikeImageStyle />
        </OverviewContentStyle>
      </Container>
    </ClosingDisclosurePage>
  );
};

export default ClosingDisclosureOverviewPage;
