import clamp from 'lodash.clamp';
import { Estimate as EstimateApiType, EstimateApplication } from 'services/apiTypes/prequalTypes';
import { Products } from 'store/constants';
import { Estimate, Expand } from 'types';
import BaseEstimateModel from './BaseEstimateModel';
import { currencyMask } from './helpers';

export interface OfferEstimate extends Estimate {
  key: string;
  maxOptionAmount?: number;
  expires?: string;
  minOptionAmount?: number;
  maxOfferAmount?: number;
}

class OfferEstimateModel extends BaseEstimateModel implements OfferEstimate {
  key = '';

  applicant: TSFixMe;

  expires: TSFixMe;

  minOptionAmount: TSFixMe;

  maxOptionAmount: TSFixMe;

  productType?: Products.HEI;

  source: TSFixMe;

  constructor(
    props: Expand<OfferEstimate> | EstimateApiType | (EstimateApiType & EstimateApplication)
  ) {
    super(props as TSFixMe);
    Object.assign(this, props);
  }

  getIsExpired(): boolean {
    // Do not expire if testing
    if (process.env.REACT_APP_ENV === 'test') {
      return false;
    }

    if (this.expires) {
      return new Date() > new Date(this.expires);
    }

    return false;
  }

  getCustomOfferAmountFromURL({ formatted = false } = {}): string {
    const params = new URLSearchParams(window.location.search);
    const requestedAmount = parseFloat(params.get('requestedAmount') || '');
    if (!requestedAmount) {
      return '';
    }

    const clampedRequestedAmount = clamp(
      requestedAmount,
      this.minOptionAmount,
      this.maxOptionAmount
    );
    return formatted ? currencyMask.getFormatted(clampedRequestedAmount) : clampedRequestedAmount;
  }

  isBelowFirmOfferAmount(): boolean {
    return this.source?.offerAmount > this.getMaxOptionAmount();
  }
}

export default OfferEstimateModel;

export function isPrequalEstimateId(estimateId: null | undefined | string): boolean {
  if (estimateId == null) {
    return false;
  }

  return /^E2/.test(estimateId);
}
