import styled from 'styled-components';

export const NameFieldsContainerStyle = styled.div.attrs({ className: 'FieldContainerStyle' })`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem; // Okay that there is no support here for IE
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    > * {
      &:nth-child(3),
      &:nth-child(4) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
`;
