import * as z from "zod";

/**
 * GET /video/:label typings
 */
export const videoChapterSchema = z
  .object({
    /**
     * Label of the video, serves as unique identifier for a given video
     */
    label: z.string(),
    from: z.number(),
    to: z.number(),
    description: z.string(),
    metadata: z.unknown().optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type VideoChapter = z.infer<typeof videoChapterSchema>;

/**
 * Chapters of the the video, filed names coincides with chapters labels, value is the chapter itself
 */
export const videoChaptersSchema = z.record(z.string(), videoChapterSchema);
export type VideoChapters = z.infer<typeof videoChaptersSchema>;

export const videoApiResponseSchema = z
  .object({
    /**
     * Label of the video, serves as unique identifier by which video could be found
     */
    label: z.string(),
    description: z.string().nullable(),
    url: z.string().url(),
    length: z.number(),
    chapters: videoChaptersSchema,
  })
  .catchall(z.unknown() as z.ZodType);
export type VideoApiResponse = z.infer<typeof videoApiResponseSchema>;

/**
 * POST /video/:label/statistics typings
 */
export const postStatisticsSchema = z
  .object({
    /**
     * Array of [from, to] seconds of watched video
     */
    ranges: z.array(z.array(z.number())),
    tags: z.record(z.string(), z.string()).optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type PostStatistics = z.infer<typeof postStatisticsSchema>;
