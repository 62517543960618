import axios, { AxiosError, AxiosResponse } from 'axios';
import nullthrows from 'nullthrows';
import bugsnagClient, { attachAxiosError } from 'lib/bugsnagClient';
import { logError, logWarning } from 'lib/logger';
import { getUtmParams } from 'lib/utmParameters';
import { parseResponse } from './api/baseQuery';
import {
  SmartyStreetsUsAutocompleteProResponse,
  SmartyStreetsUsAutocompleteProSuggestion,
  smartyStreetsUsAutocompleteProResponseSchema,
} from './smartStreetsUsAutocompleteProApi';
import {
  SmartyStreetsStreetAddress,
  SmartyStreetsStreetAddressQueryParams,
  smartyStreetsStreetAddressResponseSchema,
} from './smartyStreetsStreetAddressApi';

export type { SmartyStreetsStreetAddress, SmartyStreetsUsAutocompleteProSuggestion };

type RequestParameters = Record<string, unknown>;

const baseParams = {
  key: process.env.REACT_APP_SMARTY_STREETS_WEBSITE_KEY,
};

enum RequestType {
  LOOKUP = 'lookup',
  STREET_ADDRESS = 'street-address',
}

/* PPC-2224: This is largely a placeholder in case we decide to enable proxy backup for SS failures.
             These values below should be moved to environment vars if we turn it on for production use.
*/
const ENABLE_PROXY_RETRIES = false;

const smartyStreetsProxyApi = process.env.REACT_APP_SMARTY_STREETS_US_PROXY_API || null;

const proxyEndpoints: null | Record<RequestType, string> = smartyStreetsProxyApi
  ? {
      // https://<point-proxy-server>/smarty-streets/lookup
      [RequestType.LOOKUP]: `${smartyStreetsProxyApi}/lookup`,
      // https://<point-proxy-server>/smarty-streets/street-address
      [RequestType.STREET_ADDRESS]: `${smartyStreetsProxyApi}/street-address`,
    }
  : null;

const enableSmartyStreetsProxy =
  proxyEndpoints != null &&
  document.cookie.split(';').some((item) => item.trim() === 'enableSmartyStreetsProxy=true');

const smartyStreetEndpoints: Record<RequestType, string> = enableSmartyStreetsProxy
  ? nullthrows(proxyEndpoints)
  : {
      [RequestType.LOOKUP]: nullthrows(process.env.REACT_APP_SMARTY_STREETS_US_AUTOCOMPLETE_API),
      [RequestType.STREET_ADDRESS]: nullthrows(
        process.env.REACT_APP_SMARTY_STREETS_US_STREET_ADDRESS_API
      ),
    };

async function fetchViaProxy<T>(
  requestType: RequestType,
  requestParams: RequestParameters
): Promise<null | AxiosResponse<T>> {
  if (ENABLE_PROXY_RETRIES && proxyEndpoints != null) {
    try {
      const proxyResult = await axios.get<T>(proxyEndpoints[requestType], {
        params: requestParams,
      });
      return proxyResult;
    } catch (err: TSFixMe) {
      logError({
        eventType: 'smartyStreetsProxy',
        detail: {
          message: err.message,
          err,
        },
      });
    }
  }
  return null;
}

function reportError(ssRequestParams: RequestParameters, error: AxiosError) {
  const location = window?.location;
  const detail = {
    message: error?.message,
    searchAddress: ssRequestParams?.search || ssRequestParams?.street,
    url: error.config?.url,
    data: error.response?.data,
    utmParams: location ? getUtmParams(location) : null,
  };

  bugsnagClient.notify(`SmartyStreets failure: ${error}`, (event) => {
    attachAxiosError(event, error);
    event.addMetadata('Smarty Request', detail);
  });
  logError({
    eventType: 'smartyStreets',
    detail,
  });
}

// takes in a address string and extracts a structured address along with other info.
// Having the full string in the street field is intentional (a hack given to us by the SS team).
// Note their extract API (https://www.smarty.com/docs/cloud/us-extract-api) which does the same thing,
// does not work through a client.
/*

Try the API here - https://www.smarty.com/products/apis/us-street-api?utm_term=address%20validation%20api&utm_campaign=Search+%7C+TopPerformer&utm_source=adwords&utm_medium=ppc&hsa_acc=9428111260&hsa_cam=14857922136&hsa_grp=128340417736&hsa_ad=554878767294&hsa_src=g&hsa_tgt=kwd-989112606&hsa_kw=address%20validation%20api&hsa_mt=b&hsa_net=adwords&hsa_ver=3&gclid=Cj0KCQiA0eOPBhCGARIsAFIwTs4XBEPFkdfi7ub2XwcO--JueuTVu_4J7U5hU0SpMgLIn2HMgYgjHEsaAmEXEALw_wcB
Expected response structure -

[
  {
    "input_index": 0,
    "candidate_index": 0,
    "delivery_line_1": "2 Oak Ct",
    "last_line": "Sunnyvale CA 94086-5159",
    "delivery_point_barcode": "940865159021",
    "components": {
      "primary_number": "2",
      "street_name": "Oak",
      "street_suffix": "Ct",
      "city_name": "Sunnyvale",
      "default_city_name": "Sunnyvale",
      "state_abbreviation": "CA",
      "zipcode": "94086",
      "plus4_code": "5159",
      "delivery_point": "02",
      "delivery_point_check_digit": "1"
    },
    "metadata": {
      "record_type": "S",
      "zip_type": "Standard",
      "county_fips": "06085",
      "county_name": "Santa Clara",
      "carrier_route": "C054",
      "congressional_district": "17",
      "rdi": "Residential",
      "elot_sequence": "0039",
      "elot_sort": "A",
      "latitude": 37.381120,
      "longitude": -122.026210,
      "precision": "Zip9",
      "time_zone": "Pacific",
      "utc_offset": -8,
      "dst": true
    },
    "analysis": {
      "dpv_match_code": "Y",
      "dpv_footnotes": "AABB",
      "dpv_cmra": "N",
      "dpv_vacant": "N",
      "dpv_no_stat": "N",
      "active": "Y"
    }
  }
]
*/

export const fetchAddressFromString = async (
  addressString: string
): Promise<undefined | SmartyStreetsStreetAddress> => {
  // Return `undefined` if `addressString` is empty or only whitespace:
  if (!/\S/.test(addressString)) {
    return undefined;
  }

  const params: SmartyStreetsStreetAddressQueryParams = {
    ...baseParams,
    license: 'us-standard-cloud',
    street: addressString,
    city: '',
    state: '',
    zipcode: '',
  };
  let response;

  try {
    response = await axios.get<unknown>(smartyStreetEndpoints[RequestType.STREET_ADDRESS], {
      params,
    });
  } catch (err: TSFixMe) {
    reportError(params, err);

    // Try to get address data via proxy if there is an authentication error
    if (err.response?.status === 401) {
      response = await fetchViaProxy<unknown>(RequestType.STREET_ADDRESS, params);
    } else {
      throw err;
    }
  }

  const address = parseResponse(response?.data, smartyStreetsStreetAddressResponseSchema)[0];
  if (!address) {
    logWarning({
      eventType: 'addressNotFound',
      detail: {
        message:
          'attempt to parse an address from a string using smartystreets us-street-api returned no results',
        address: addressString,
      },
    });
  }
  return address;
};

/*
Try the API here - https://www.smarty.com/products/apis/us-autocomplete-pro-api?utm_term=smartystreets%20autocomplete&utm_campaign=Search+%7C+Address+Validation+API&utm_source=adwords&utm_medium=ppc&hsa_acc=9428111260&hsa_cam=2058227930&hsa_grp=123514158176&hsa_ad=525644755438&hsa_src=g&hsa_tgt=kwd-1408139828323&hsa_kw=smartystreets%20autocomplete&hsa_mt=p&hsa_net=adwords&hsa_ver=3&gclid=Cj0KCQiA0eOPBhCGARIsAFIwTs5ptnlJ51Rx0Y8wYq0_D2YtXQ6T__1l0vru1q47vGbfFQ4-kf_5EVsaAp5oEALw_wcB
Expected response structure -
{
  "suggestions": [
    {
      "street_line": "2626 Ohio Ave",
      "secondary": "",
      "city": "Redwood City",
      "state": "CA",
      "zipcode": "94061",
      "entries": 0
    }
  ]
}
*/

// see abort controller doc here - https://axios-http.com/docs/cancellation
export const fetchAutocompleteResults = async (
  typedAddress: string,
  abortController: { signal: AbortSignal }
): Promise<SmartyStreetsUsAutocompleteProResponse> => {
  const params = {
    ...baseParams,
    license: 'us-autocomplete-pro-cloud',
    max_results: '5',
    prefer_geolocation: 'CITY',
    search: typedAddress,
    signal: abortController.signal,
  };

  try {
    const result = await axios.get<unknown>(smartyStreetEndpoints[RequestType.LOOKUP], {
      params,
    });
    return parseResponse(result.data, smartyStreetsUsAutocompleteProResponseSchema);
  } catch (err: TSFixMe) {
    reportError(params, err);

    // Try to get address data via proxy if there is an authentication error
    if (err.response?.status === 401) {
      const proxyResult = await fetchViaProxy<unknown>(RequestType.LOOKUP, params);
      if (proxyResult) {
        return parseResponse(proxyResult.data, smartyStreetsUsAutocompleteProResponseSchema);
      }
    }
    throw err;
  }
};
