import styled from 'styled-components';
import { ContainerStyles, GraphicsStyles } from '@pointdotcom/pds';
import { BorderedSectionStyle } from 'styles';

export const ApplicationPageFooterNavStyle = styled(BorderedSectionStyle).attrs({
  className: 'ApplicationPageFooterNavStyle' as string,
})`
  margin-top: 2rem;
  button {
    &:disabled {
      visibility: hidden;
    }
  }
  ${ContainerStyles.ContainerStyle} {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    align-items: space-between;
    gap: 1rem;
  }
`;

export const ApplicationPageReviewFooter = styled(BorderedSectionStyle).attrs({
  className: 'ApplicationPageReviewFooter' as string,
})`
  padding-bottom: 127px;
  ${ContainerStyles.ContainerStyle} {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    > * {
      flex: 1;
      &:last-child {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        gap: 2rem;
      }
    }
    &:after {
      content: '';
      display: block;
      ${GraphicsStyles.GoldHouseCSS};
      background-position: center;
      width: 200px;
      height: 200px;
      background-size: contain;
      position: absolute;
      bottom: -200px;
    }
  }
  ul {
    --size: 50px;

    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    li {
      --borderSize: 8px;
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      padding-bottom: 6%;
      gap: 0.8rem;
      &:last-child {
        padding-bottom: 0;
        &:before {
          display: none;
        }
      }
      &:before {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        position: absolute;
        left: calc(var(--size) / 2);
        top: 0;
        background-color: ${({ theme }) => theme.Color.Gray1};
      }
      &:after {
        position: relative;
        content: '';
        background-color: ${({ theme }) => theme.Color.Gray1};
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='14' viewBox='0 0 18 14' fill='none'%3E%3Cpath d='M1.5 5.85714L6.95455 11L16.5 2' stroke='white' stroke-width='3.4'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
        border-radius: 50%;
        top: calc(var(--borderSize) * -1);
        border: var(--borderSize) solid white;
        flex: var(--size) 0 0;
        height: var(--size);
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${ContainerStyles.ContainerStyle} {
      flex-flow: column-reverse;
      align-items: normal;
      gap: 4rem;
      &:before {
        left: -30px;
      }
      > * {
        &:last-child {
          justify-content: space-between;
        }
      }
    }
  }
`;
