import { useCallback, useState } from 'react';
import type { LottiePlayer } from 'lottie-web';
import { logError } from 'lib/logger';

// Singleton Lottie instance at the module level
let lottieInstance: LottiePlayer | null = null;

// Function to load the Lottie instance if it hasn't been loaded already
const loadLottieInstance = async (): Promise<LottiePlayer> => {
  if (!lottieInstance) {
    const LottieModule = await import('lottie-web');
    lottieInstance = LottieModule.default || LottieModule;
  }
  return lottieInstance;
};

// Custom hook to use Lottie
export default function useLottie() {
  const [lottie, setLottie] = useState<LottiePlayer | null>(lottieInstance);
  const [isLoading, setIsLoading] = useState(!lottieInstance); // Initially loading if instance doesn't exist
  const [isError, setIsError] = useState<boolean>(false);

  // Function to load Lottie or return existing instance
  const loadLottie = useCallback(async () => {
    if (lottieInstance) {
      setIsLoading(false);
      setIsError(false);
      return lottieInstance; // Return the existing instance if already loaded
    }

    try {
      setIsLoading(true);
      const instance = await loadLottieInstance();
      setLottie(instance);
      return instance;
    } catch (err) {
      logError({
        eventType: 'lottieLoadError',
        detail: {
          message: 'Could not lazy load Lottie',
          error: err,
        },
      });
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
    return null;
  }, []);

  return { loadLottie, lottie, isLoading, isError };
}
