export default {
  pageName: 'Credit Score',
  title: 'How’s your credit?',
  continue: 'Continue',
  thisField: 'This field is required.',
  oopsSoSorry: 'Oops, so sorry—we hit a technical glitch.',

  whatCredit: 'What credit scores does Point work with?',
  pointTreats:
    'Point treats our customers as more than just numbers. When we evaluate whether you’re a good fit for Point, we’ll look at your entire financial picture. We typically fund customers with scores above 580, but have made exceptions.',
  howLong: 'How long does it take to get funded?',
  typicallyAbout: `Typically about four weeks. If you have a current appraisal of your home, it can be as little as three weeks.`,
  fundingTimelines:
    'Funding timelines may vary depending on various factors including but not limited to appraisal turnaround times and follow-ups required for verification of property details and a clear title. As a result, application to funding may take over 60 days to complete, and may take up to 6 months.',

  whyDoesPoint: 'Why does Point need to know my credit score?',
  pointNeedsToKnow:
    'Point needs to know your credit score to accurately assess your creditworthiness and offer you the most accurate estimate. Your credit score helps us determine the risk involved in investing in your home  and the investment amount you can receive. However, itʼs important to note that your credit score is not the only factor we consider. We also evaluate other aspects of your financial profile to ensure we provide you with a fair and tailored financial solution.',
  pointTreatsScore500:
    'Point treats our customers as more than just numbers. When we evaluate whether you’re a good fit for Point, we’ll look at your entire financial picture. We typically fund customers with scores above 500.',
  pointNeedsToKnowExtended:
    'Point needs to know your credit score to accurately assess your creditworthiness and offer you the most accurate estimate. Your credit score helps us determine the risk involved in investing in your home  and the investment amount you can receive. However, it’s important to note that your credit score is not the only factor we consider. We also evaluate other aspects of your financial profile to ensure we provide you with a fair and tailored financial solution.',
};
