import styled from 'styled-components';
import { ContainerStyles } from '@pointdotcom/pds';
import { paddedSectionPadding } from 'styles';

export const OfferRejectionStyle = styled.div.attrs({ className: 'OfferRejectionStyle' })`
  padding: 8vh 0;
  ${ContainerStyles.ContainerStyle} {
    display: flex;
    flex-flow: column;
    gap: ${paddedSectionPadding};
  }
`;

export const MainContentSectionStyle = styled.section.attrs({
  className: 'MainContentSectionStyle',
})``;

export const StaticReasonSectionStyle = styled.section.attrs({
  className: 'StaticReasonSectionStyle',
})`
  h4 {
    font-size: inherit;
  }
`;
