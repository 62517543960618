import React, { forwardRef } from 'react';
import { ButtonClickFunc } from '@pointdotcom/pds';
import i18n from './i18n';
import * as styles from './styles';

interface VerificationErrorProps {
  problems: Array<string>;
  recommendations: Array<string>;
  onManualReview: ButtonClickFunc;
  submitting?: boolean;
  submitDisabled?: boolean;
}

const VerificationError = forwardRef<HTMLDivElement, VerificationErrorProps>(
  ({ problems, recommendations, onManualReview, submitting, submitDisabled }, ref) => {
    return (
      <styles.VerificationErrorStyle ref={ref}>
        <div>
          <div>
            <h5>{problems.length > 0 ? i18n.weHaveDetectedColon : i18n.weHaveDetected}</h5>
            {problems.length > 0 && (
              <ul>
                {problems.map((problem, index) => (
                  <li key={index}>{problem}</li>
                ))}
              </ul>
            )}
          </div>
          {recommendations.length > 0 && (
            <>
              <hr />
              <div>
                <h5>{i18n.howToProceed}</h5>
                <ul>
                  {recommendations.map((recommendation, index) => (
                    <li key={index}>{recommendation}</li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </div>
        <aside>
          {i18n.doYouBelieve}
          <styles.ErrorButtonStyle
            onClick={onManualReview}
            loading={submitting}
            disabled={submitDisabled}
          >
            {i18n.requestAManualReview}
          </styles.ErrorButtonStyle>
        </aside>
      </styles.VerificationErrorStyle>
    );
  }
);

export default VerificationError;
