import React from 'react';
import FollowUpDecisionPage from 'containers/FollowUpDecisionPage';
import { useFollowUpFetch } from 'containers/prequal/hooks';
import EstimateCalculatorPage from '..';
import i18n from './i18n';

const EstimateCalculatorDecisionPage = () => {
  const followUpFetchResults = useFollowUpFetch();
  return (
    <EstimateCalculatorPage pageName={i18n.pageName} followUpFetchResults={followUpFetchResults}>
      <FollowUpDecisionPage followUpFetchResults={followUpFetchResults} />
    </EstimateCalculatorPage>
  );
};

export default EstimateCalculatorDecisionPage;
