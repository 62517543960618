import axios from 'axios';
import { getMiddlewareHost } from 'lib/hosts';

/* https:middleware-staging/api/directory-lookup/users?email=jenn@point.com */
export async function fetchDirectoryUser(email) {
  const url = `${getMiddlewareHost()}/api/directory-lookup/users`;
  // const response = await axios.get(url, { params: { email }, withCredentials: false });
  const response = await axios({
    url,
    method: 'GET',
    params: { email },
    withCredentials: false,
  });
  return response.data;
}
