import styled, { keyframes } from 'styled-components';
import {
  MagnifyContentStyle,
  MagnifyGraphicStyle,
  MagnifyShineStyle,
  magnifyShineMoveAnim,
} from '../MagnifyGraphic/styles';
import handCheckImage from './images/handcheck.png';

export const bob = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
`;

export const moveBackground = keyframes`
  0%, 100% {
    background-position: right -5% top 38%;
  }
  50% {
    background-position: right -5% top 40%;
  }
`;

export const TextContainerStyle = styled.div.attrs({
  className: 'TextContainerStyle',
})`
  position: absolute;
  color: white;
  font-family: ${({ theme }) => theme.fonts.serif.name};
  bottom: 10%;
  left: 32%;
  font-size: clamp(0.8rem, 3vw, 2.1rem);
`;

export const HandCheckGraphicStyle = styled.div.attrs({
  className: 'HandCheckGraphicStyle',
})`
  --handCheckSize: 60%;
  background-image: url('${handCheckImage}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom -1px;
  position: absolute;
  bottom: 0;
  left: 10%;
  width: var(--handCheckSize);
  padding-bottom: var(--handCheckSize);
  &:before {
    content: '';
    display: block;
    width: 58%;
    height: 40%;
    position: absolute;
    background: ${({ theme }) => theme.Color.PointBlack};
    opacity: 0.5;
    filter: blur(10px) brightness(0);
    border-radius: 50%;
    z-index: -1;
    top: 56%;
    right: 7%;
  }
  ${MagnifyGraphicStyle} {
    left: 55%;
    top: 34%;
    animation: ${bob} 3s infinite ease-in-out;
  }
  ${MagnifyContentStyle} {
    background-image: url('${handCheckImage}');
    background-size: 300%;
    background-position: right -5% top 40%;
    animation: ${moveBackground} 3s infinite ease-in-out;
  }
  ${MagnifyShineStyle} {
    animation: ${magnifyShineMoveAnim} 3s infinite ease-in-out;
  }
`;
