import React, { useCallback } from 'react';
import nullthrows from 'nullthrows';
import { DirectionAndPlacement, HelpTextAnimated, Style } from '@pointdotcom/pds';
import { percMask } from 'models/helpers';
import { DeleteFileButton, UploadedFileItemProps } from './UploadedFileItem';
import { FileIcon } from './icons';
import * as styles from './styles';

export default function UploadedFileItemRow({
  taskFile,
  upload,
  isDeleting,
  deleteFileError,
  onDeleteFile,
  onFileClick,
}: UploadedFileItemProps) {
  if (taskFile == null && upload == null) {
    throw new Error('Must have either `taskFile` or `upload`');
  }

  const fileName = nullthrows(taskFile?.fileName ?? upload?.file.name);
  const uploadError = upload?.error;
  const showProgress = upload != null && uploadError == null && !upload.uploadFinished;
  const errorMessage = uploadError?.message ?? deleteFileError;
  const hasError = !!errorMessage;

  const handleCLick = useCallback(() => {
    if (onFileClick) {
      onFileClick(fileName);
    }
  }, [fileName, onFileClick]);

  return (
    <styles.UploadedFileItemRowStyle isError={hasError} isDeleting={isDeleting}>
      <FileIcon uploadProgress={upload?.progress} hasError={hasError} />
      <styles.UploadedFileNameStyle onClick={handleCLick}>{fileName}</styles.UploadedFileNameStyle>
      {showProgress && <div>{percMask.getFormatted(upload.progress * 100, { precision: 0 })}</div>}
      {!showProgress && (taskFile != null || uploadError != null) && (
        <DeleteFileButton
          onClick={onDeleteFile}
          fileName={fileName}
          hasError={hasError}
          deleting={isDeleting}
        />
      )}
      <HelpTextAnimated
        noMargin
        show={hasError}
        styleType={Style.Error}
        styleAlign={DirectionAndPlacement.Left}
      >
        {errorMessage}
      </HelpTextAnimated>
      <styles.UploadedFileRowProgressBar>
        {showProgress && <div style={{ width: `${upload.progress * 100}%` }} />}
      </styles.UploadedFileRowProgressBar>
    </styles.UploadedFileItemRowStyle>
  );
}
