import React, { RefObject } from 'react';
import { DirectionAndPlacement, useScrollSpy } from '@pointdotcom/pds';
import FollowSection, { FollowSectionProps } from 'components/FollowSection';
import { CallUsLink } from 'components/SubHeader';
import {
  ButtonPostApplicationLabel,
  ButtonPostApplicationWithMessaging,
} from 'containers/EstimatorPage/BookingAndNavFooter';
import { getHEIEstimateModel } from 'store/estimates';
import { useSelector } from 'store/hooks';
import * as styles from './styles';

export default function FloatingCTA({
  show,
  bannerRef,
}: Pick<FollowSectionProps, 'show'> & { bannerRef: RefObject<HTMLElement> | undefined }) {
  const { scrollIsPastSpyBottom } = useScrollSpy({
    spyOn: bannerRef,
  });
  const shouldShowFollowSection = show || scrollIsPastSpyBottom;
  const estimate = useSelector(getHEIEstimateModel);

  return (
    <FollowSection
      show={shouldShowFollowSection}
      placement={DirectionAndPlacement.Bottom}
      adjustBody
    >
      <styles.FloatingContainerStyle>
        <CallUsLink />
        {estimate && (
          <ButtonPostApplicationWithMessaging
            estimate={estimate}
            buttonLabel={ButtonPostApplicationLabel.ContinueApplication}
          />
        )}
      </styles.FloatingContainerStyle>
    </FollowSection>
  );
}
