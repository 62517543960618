export default {
  applicationReceived:
    'Application received. Check back here to see the latest activity on your application.',
  applicationStarted: 'Application started.',
  clickHere: 'Click here',
  completeApplication: '%clickHere% to complete your application!',
  completedOn: 'Completed on %date%',
  currentOffer: 'Your current offer is for %offerAmount%!',
  fundingProcess: 'Funding process stage',
  heiCostEstimator: 'HEI cost estimator',
  heiCostEstimatorDescr:
    'Use this tool to understand potential repayment amounts associated with different scenarios of home appreciation.',
  heiOverviewVideo: 'HEI overview video',
  heiOverviewVideoDescr:
    'Watch the video to learn more about how Point’s HEI product works, including how we calculate the homeowner protection cap.',
  hello: 'Hello, welcome to your dashboard!',
  helloWelcomeToYourDashboard: 'Hello %name%, welcome to your dashboard',
  noTasks: 'You have no tasks to complete',
  playVideo: 'Play video',
  recentActivity: 'Recent activity',
  seeFullFundingProcess: 'See the full funding process',
  seeFundingProcess: 'See the funding process',
  someItemsRequireAttention: 'Some items require your attention:',
  someItemsInYourTaskListRequireAttention: 'Some items in your task list require your attention.',
  yourDashboard: 'Your dashboard',
  yourFundingResources: 'Your funding resources',
  yourFundingStatus: 'Your funding status',
  yourTaskList: 'Your task list',
  stillHaveQuestions: 'Still have questions?',
  meetYourAccountManager: 'Meet your account manager.',
  meetYourAccountManagerName: 'Meet your account manager, %firstName%.',
  reachOutToName: 'Reach out to your account manager, %firstName%.',
  reachOutTo: 'Reach out to your account manager.',
  takeALook: 'Take a look at your task page to see what you’ve accomplished so far:',
  wereWorkingHard:
    'We’re working hard to move your application along. You currently have no open tasks to complete.',
  viewSubmitted: 'View submitted tasks',
  browseTheHelp: 'Browse the Help Center to find answers.',
  browseTheHelpButtonText: 'Browse the Help Center',
  needMoreHelp: 'Need more help?',
};
