import React from 'react';
import TwinkleGraphic from '../TwinkleGraphic';
import * as styles from './styles';

export default function PiggyBankGraphic() {
  return (
    <styles.PiggyBankGraphicStyle>
      <styles.CoinShineStyle>
        <TwinkleGraphic />
        <TwinkleGraphic />
        <TwinkleGraphic />
      </styles.CoinShineStyle>
      <styles.CoinGraphicStyle />
    </styles.PiggyBankGraphicStyle>
  );
}
