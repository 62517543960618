import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';
import { Button, Container, Style, directory } from '@pointdotcom/pds';
import { PlayButtonStyle } from 'components/VideoPlayer/PlayButton';
import VideoPlayerModal from 'components/VideoPlayer/VideoPlayerModal';
import { Page, generateUrlFromPage } from 'containers/helpers';
import useLinkProps from 'containers/hooks/useLinkProps';
import DashboardModel from 'models/DashboardModel';
import { isPrequalEstimateId } from 'models/OfferEstimateModel';
import i18n from './i18n';
import { LifeSaverIcon } from './icons';
import { houseGoldImage, housesBlueImage } from './images';
import * as styles from './styles';

function ExplainerVideoCard() {
  const [videoIsOpen, setVideoIsOpen] = useState<boolean>(false);

  const handleClickPlayButton = useCallback(() => setVideoIsOpen(true), []);
  const handleCloseVideo = useCallback(() => setVideoIsOpen(false), []);

  const theme = useTheme();

  return (
    <>
      <styles.CardStyle
        as="button"
        withImage
        onClick={handleClickPlayButton}
        isClickable
        hoverColor={theme.Color.Yorange}
      >
        <styles.CardImageStyle src={houseGoldImage}>
          <PlayButtonStyle aria-label={i18n.playVideo} inverted />
        </styles.CardImageStyle>
        <styles.CardHeaderStyle>{i18n.heiOverviewVideo}</styles.CardHeaderStyle>
        <p>{i18n.heiOverviewVideoDescr}</p>
      </styles.CardStyle>
      <VideoPlayerModal
        isOpen={videoIsOpen}
        onClose={handleCloseVideo}
        bgClickToClose
        escToClose
        showX
      />
    </>
  );
}

interface HeiCostEstimatorCardProps {
  dashboard: DashboardModel;
}

function HeiCostEstimatorCard({ dashboard }: HeiCostEstimatorCardProps) {
  const theme = useTheme();
  const linkProps = useLinkProps(generateUrlFromPage(Page.DASHBOARD_ESTIMATOR));

  if (
    dashboard.getDocket()?.getLatestOffer() == null &&
    !isPrequalEstimateId(dashboard.application?.estimateKey)
  ) {
    return null;
  }

  return (
    <styles.CardStyle
      as="a"
      withImage
      isClickable
      hoverColor={theme.Color.PointBlue}
      {...linkProps}
    >
      <styles.CardImageStyle src={housesBlueImage} />
      <styles.CardHeaderStyle>{i18n.heiCostEstimator}</styles.CardHeaderStyle>
      <p>{i18n.heiCostEstimatorDescr}</p>
    </styles.CardStyle>
  );
}

function HelpCenterCard() {
  const theme = useTheme();
  const { PointDomain } = directory;
  const linkProps = useLinkProps(PointDomain.Help, { newTab: true });
  return (
    <styles.HelpCenterCardStyle
      as="a"
      withImage
      isClickable
      hoverColor={theme.Color.Gray3}
      {...linkProps}
    >
      <section>
        <LifeSaverIcon />
        <header>
          <styles.CardHeaderStyle>{i18n.needMoreHelp}</styles.CardHeaderStyle>
          <div>{i18n.browseTheHelp}</div>
        </header>
      </section>
      <Button styleType={Style.Tertiary}>{i18n.browseTheHelpButtonText}</Button>
    </styles.HelpCenterCardStyle>
  );
}

interface DashboardFundingResourcesSectionProps {
  dashboard: DashboardModel;
}

export default function DashboardFundingResourcesSection({
  dashboard,
}: DashboardFundingResourcesSectionProps) {
  return (
    <styles.BorderedSectionStyle>
      <Container>
        <styles.SectionHeaderStyle>{i18n.yourFundingResources}</styles.SectionHeaderStyle>
        <styles.CardGridStyle>
          <ExplainerVideoCard />
          <HeiCostEstimatorCard dashboard={dashboard} />
          <HelpCenterCard />
        </styles.CardGridStyle>
      </Container>
    </styles.BorderedSectionStyle>
  );
}
