import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  Container,
  DirectionAndPlacement,
  Header,
  QueryComponent,
  Size,
  TemplatedText,
  directory,
} from '@pointdotcom/pds';
import FooterNav from 'components/FooterNav';
import MainHeader from 'components/MainHeader';
import PricingWithMath from 'components/PricingWithMath';
import RangeEstimatePrepayment from 'components/RangeEstimatePrepayment';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { Products } from 'store/constants';
import i18n from './i18n';
import * as styles from './styles';

export function EstimatedPricingSection({
  calendar,
  mainHeaderProps = {},
  showExpertExplainLinkSection = true,
  callOutTextColor = null,
  rangeStyleType = 'default',
  subHeaderProps,
  callOutLineColor,
  estimate,
  pricingRange,
  capModalOpen,
  onModalClose,
}) {
  if (!estimate || !pricingRange) {
    return null;
  }

  const headlineText = pricingRange.hasMinEstimatesForUIControl()
    ? i18n.toSeeYour
    : i18n.yourEstimated;
  const scheduleLink = calendar
    ? generateUrlFromPage(pages.PREQUAL_SCHEDULE, { product: Products.HEI, calendar })
    : directory.pointLink.schedule;
  return (
    <styles.EstimatedPricingSectionStyle
      style={!showExpertExplainLinkSection ? null : { paddingBottom: '0' }}
    >
      <Container>
        <QueryComponent
          queriedComponent={
            <Header
              className="estimatedPricingSectionMainHeader"
              content={
                <TemplatedText
                  values={{ amount: estimate.getPricing()?.getFormattedOptionInvestmentAmount() }}
                >
                  {i18n.pricingBased}
                </TemplatedText>
              }
              styleAlignMobile="left"
            />
          }
          queryPrint
        >
          <Header
            {...mainHeaderProps}
            content={headlineText}
            className="estimatedPricingSectionMainHeader"
          />
        </QueryComponent>
        {pricingRange.hasMinEstimatesForUIControl() && (
          <RangeEstimatePrepayment
            estimate={estimate}
            pricingRange={pricingRange}
            styleType={rangeStyleType}
            dropdownButtonProps={{
              styleType: rangeStyleType,
            }}
          />
        )}
      </Container>
      <PricingWithMath
        estimate={estimate}
        callOutTextColor={callOutTextColor}
        callOutLineColor={callOutLineColor}
        headerProps={subHeaderProps}
        capModalOpen={capModalOpen}
        onModalClose={onModalClose}
      />
      {showExpertExplainLinkSection && (
        <styles.ExpertExplainSectionStyle bordered>
          <Container flex styleAlignMobile="center">
            <a
              href={scheduleLink}
              target="_blank"
              rel="noopener noreferrer"
              data-ga-tracking-id="WantExpertToExplain"
            >
              {i18n.wantAnExpert}
            </a>
          </Container>
        </styles.ExpertExplainSectionStyle>
      )}
    </styles.EstimatedPricingSectionStyle>
  );
}

export default function PricingPage({
  calendar,
  navItems,
  navProps,
  bannerProps,
  headerType,
  subHeaderProps,
  estimateKey,
  estimate,
  pricingRange,
}) {
  const theme = useContext(ThemeContext);
  if (!estimate) {
    return null;
  }

  return (
    <styles.PricingPageStyle>
      <MainHeader
        bannerProps={bannerProps}
        navProps={navProps}
        navItems={navItems}
        headerType={headerType}
        subHeaderProps={subHeaderProps}
      />
      <EstimatedPricingSection
        calendar={calendar}
        estimateKey={estimateKey}
        estimate={estimate}
        pricingRange={pricingRange}
        callOutTextColor="inherit"
        callOutLineColor={theme.Color.Gray1}
        mainHeaderProps={{
          styleSize: Size.Large,
          styleAlign: DirectionAndPlacement.Left,
          styleAlignMobile: DirectionAndPlacement.Center,
          maxWidth: '620px',
        }}
        subHeaderProps={{
          styleSize: Size.Medium,
          styleAlign: DirectionAndPlacement.Left,
          styleAlignMobile: DirectionAndPlacement.Center,
          styleSizeMobile: Size.Default,
          maxWidth: '422px',
        }}
      />
      <FooterNav navItems={navItems} navProps={navProps} weightedBottom />
    </styles.PricingPageStyle>
  );
}
