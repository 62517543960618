import styled, { createGlobalStyle, css } from 'styled-components';
import { SplashTextStyles } from '@pointdotcom/pds';
import { ChatIconStyle } from 'components/ChatIcon/styles';
import { pxToRem } from 'styleHelpers';

export const ChatlioWidgetGlobalStyle = createGlobalStyle<{ show: boolean }>`
  #chatlio-widget {
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;

export const ANIM_SPEED_MS = 250;

const chatButtonFloatingCss = css`
  position: fixed;
  bottom: 0;
  right: 10px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

export const ChatButtonStyle = styled.div.attrs({ className: 'ChatButtonStyle' })<{ mode: string }>`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
  padding: 1.25rem 2rem;
  border-radius: 3px;
  cursor: pointer;
  ${({ mode }) => {
    if (mode === 'floating') {
      return chatButtonFloatingCss;
    }
  }}
  color: white;
  background-color: ${({ theme }) => theme.Color.PurpleBlue5};
  &:hover {
    background-color: ${({ theme }) => theme.Color.PurpleBlue6};
  }

  transition:
    background-color 180ms ease-in-out,
    transform ${ANIM_SPEED_MS}ms ease-in-out;
  &.slideout-enter,
  &.slideout-enter-active,
  &.slideout-enter-done {
    transform: translateY(125%);
  }

  ${ChatIconStyle} {
    --size: 2em;
    width: var(--size);
    height: var(--size);
  }
`;

export const ButtonLabelStyle = styled.div.attrs({ className: 'ButtonLabelStyle' })`
  display: flex;
  flex-direction: column;
  text-align: left;

  ${SplashTextStyles.SplashCopySerifStyle} {
    font-size: ${pxToRem(16)};
  }

  span {
    font-size: ${pxToRem(18)};
  }
`;

export const CloseButtonStyle = styled.button.attrs({ className: 'CloseButtonStyle' })`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
`;
