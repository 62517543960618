import styled from 'styled-components';
import { LinkButtonStyles, LoaderStyles, Modal, ModalStyles } from '@pointdotcom/pds';

export const FilePreviewModalStyle = styled(Modal).attrs({ className: 'FilePreviewModalStyle' })`
  background: red;
  ${ModalStyles.ModalContainerInnerStyle} {
    border-block: 1px solid ${({ theme }) => theme.line.color.default};
    padding: ${ModalStyles.upperHeaderPadding};
    min-height: 40vh;
    ${LoaderStyles.LoaderStyle} {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
    }
  }
  img {
    position: relative;
    width: 100%;
    z-index: 2;
  }
  footer {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    padding: ${ModalStyles.upperHeaderPadding};
    gap: 1em;
    ${LinkButtonStyles.ButtonLinkStyle} {
      color: ${({ theme }) => theme.Color.GrayLightestAccessible};
      display: flex;
      flex-flow: row nowrap;
      gap: 1em;
    }
  }
  ${LoaderStyles.LoaderBorderStyle} {
    border-top-color: ${({ theme }) => theme.line.color.default};
  }
  @media (min-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${ModalStyles.ModalContainerInnerStyle} {
      min-width: ${({ theme }) => theme.responsive.largestMobileScreen};
    }
  }
`;
