import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  Button,
  Container,
  DirectionAndPlacement,
  Display,
  Graphics,
  Header,
  QueryComponent,
  Size,
  Style,
  TemplatedText,
} from '@pointdotcom/pds';
import ConfettiBackground from 'components/ConfettiBackground';
import { PlayButtonStyle } from 'components/VideoPlayer/PlayButton';
import VideoPlayerModal from 'components/VideoPlayer/VideoPlayerModal';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import ApplicantModel from 'models/ApplicantModel';
import OfferEstimateModel from 'models/OfferEstimateModel';
import { currencyMask } from 'models/helpers';
import AnimatedGraphic from './AnimatedGraphic';
import i18n from './i18n';
import * as styles from './styles';

interface HeiOfferEstimatorPageGoodNewsSectionProps {
  applicant: null | ApplicantModel;
  estimate: OfferEstimateModel;
}

export default function HeiOfferEstimatorPageGoodNewsSection({
  applicant,
  estimate,
}: HeiOfferEstimatorPageGoodNewsSectionProps) {
  const PricingCarouselFlagEnabled = useFeatureFlag(FeatureFlag.PricingCarousel) === 'test';
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const VIDEO_OPEN_PARM = 'watching';
  const watching = queryParams.get(VIDEO_OPEN_PARM);

  const callOutItems = [
    {
      label: i18n.monthlyPayment,
      value: currencyMask.getFormatted(0),
      description: i18n.monthlyPaymentsDescription,
    },
    {
      label: i18n.termLength,
      value: estimate.getFormattedTerm({ showAsRange: false }),
      description: i18n.termLengthDescription,
    },
  ];

  const firstName = applicant?.firstName;
  const amountFromStore = estimate.getFormattedMaxOptionAmount();

  const mainHeadlineContent = (
    <>
      <styles.GoodNewsMessageTextStyle>
        <TemplatedText values={{ name: firstName }}>
          {firstName ? i18n.goodNewsName : i18n.goodNews}
        </TemplatedText>
      </styles.GoodNewsMessageTextStyle>
      <div>
        <Header
          styleSize={Size.Splash2}
          styleAlign={DirectionAndPlacement.Left}
          styleAlignMobile={DirectionAndPlacement.Left}
        >
          <TemplatedText
            values={{
              amount: (
                <styles.HighlightedNumberStyle>{amountFromStore}</styles.HighlightedNumberStyle>
              ),
            }}
          >
            {i18n.youCouldGet}
          </TemplatedText>
        </Header>
      </div>
    </>
  );

  const handleWatchVideoClick = () => {
    const currentPath = location.pathname; // Get current path
    const newSearch = `?${VIDEO_OPEN_PARM}=true`; // New search params to append

    // Navigate to the same path with updated search params
    navigate({
      pathname: currentPath,
      search: newSearch,
    });
  };

  const handleVideoModalClose = () => {
    navigate(-1);
  };

  return (
    <styles.GoodNewsSectionStyle>
      {!PricingCarouselFlagEnabled ? (
        <styles.TextContainerStyle offerEstimatorVideoPlayerFlagEnabled>
          <Container styleAlign={DirectionAndPlacement.Center}>
            {mainHeadlineContent}
            <styles.PlayButtonComboStyle>
              <Button
                styleAlign={DirectionAndPlacement.Center}
                styleType={Style.Secondary}
                onClick={handleWatchVideoClick}
              >
                <div>
                  <PlayButtonStyle />
                </div>
                <div>
                  <QueryComponent
                    display={Display.Contents}
                    queriedComponent={i18n.watchAShortVideo}
                  >
                    {i18n.watchAShortVideoAbout}
                  </QueryComponent>
                </div>
              </Button>
            </styles.PlayButtonComboStyle>
          </Container>
          <AnimatedGraphic />
        </styles.TextContainerStyle>
      ) : (
        <>
          <ConfettiBackground style={{ backgroundColor: 'white' }} />
          <Container>
            <styles.TextAndImageStyle>
              <styles.TextContainerStyle>{mainHeadlineContent}</styles.TextContainerStyle>
              <styles.DesktopImageContainerStyle>
                <Graphics.PartyDogSpeaker width={463} />
              </styles.DesktopImageContainerStyle>
              <styles.MobileImageContainerStyle>
                <div>
                  <Graphics.Punch width={126} />
                </div>
                <div>
                  <Graphics.PartyDog width={143} />
                </div>
              </styles.MobileImageContainerStyle>
            </styles.TextAndImageStyle>
          </Container>
        </>
      )}
      <styles.BasicDetailsTableStyle PricingCarouselFlagEnabled={PricingCarouselFlagEnabled}>
        {callOutItems.map((item) => (
          <styles.BasicDetailsColumnStyle key={item.label}>
            <styles.BasicDetailsHeaderStyle>
              <div>{item.label}</div>
              <div>{item.value}</div>
            </styles.BasicDetailsHeaderStyle>
            <styles.BasicDetailsDescriptionStyle>
              {item.description}
            </styles.BasicDetailsDescriptionStyle>
          </styles.BasicDetailsColumnStyle>
        ))}
      </styles.BasicDetailsTableStyle>
      <VideoPlayerModal
        isOpen={!!watching}
        onClose={handleVideoModalClose}
        bgClickToClose
        escToClose
        showX
      />
    </styles.GoodNewsSectionStyle>
  );
}
