import React, { useState } from 'react';
import MainHeader from 'components/MainHeader';
import ErrorPage, { ErrorType } from 'containers/ErrorPage';
import * as styles from './styles';

export const DEFAULT_BUY_BACK_TIME_IN_YEARS = 5;

const EstimatorPage = (props) => {
  const {
    product,
    navItems,
    navProps,
    bannerProps,
    headerType,
    subHeaderProps,
    estimate,
    children,
  } = props;

  const [headerHidden, setHeaderHidden] = useState(false);
  const [buyBackTime, setBuyBackTime] = useState();
  const getBuyBackTime = () => buyBackTime || DEFAULT_BUY_BACK_TIME_IN_YEARS;
  const [selectedEstimatorScenario, setSelectedEstimatorScenario] = useState();

  const onBuyBackScrollShownChange = (scrollShown) => {
    setHeaderHidden(scrollShown);
  };

  if (!estimate) {
    return null;
  }

  if (estimate.getIsExpired()) {
    return <ErrorPage errorType={ErrorType.OfferExpired} showMainHeader={false} />;
  }

  return (
    <styles.EstimatorPageStyle className="EstimatorPage">
      {navItems && navProps && (
        <MainHeader
          product={product}
          bannerProps={bannerProps}
          hidden={headerHidden}
          navItems={navItems}
          navProps={navProps}
          headerType={headerType}
          subHeaderProps={subHeaderProps}
        />
      )}
      {children
        ? children({
            ...props,
            selectedEstimatorScenario,
            setSelectedEstimatorScenario,
            getBuyBackTime,
            setBuyBackTime,
            onBuyBackScrollShownChange, // this communicates with the header and the BuyBackControlSection
          })
        : null}
    </styles.EstimatorPageStyle>
  );
};

export default EstimatorPage;
