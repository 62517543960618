import styled from 'styled-components';
import {
  AccordionStyles,
  DropdownStyles,
  LinkButtonStyles,
  Modal,
  ModalStyles,
  SplashTextStyles,
} from '@pointdotcom/pds';
import {
  Table2ColContentWrapperStyle,
  Table2ColLabelStyle,
  Table2ColTitleStyle,
  Table2ColValueStyle,
} from 'components/Table2Col/styles';
import { pxToRem } from 'styleHelpers';

export const TableLabelStyle = styled.div.attrs({ className: 'TableLabelStyle' })`
  display: grid;
  align-items: center;
  justify-content: center;
  h3 {
    font-weight: normal;
    text-transform: uppercase;
    font-size: ${pxToRem(14)};
    margin: 0;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    output {
      display: none;
    }
  }
`;

export const PreviewModalStyle = styled(Modal).attrs({
  className: 'PreviewModalContentStyle',
})`
  --left-right-padding: 5rem;
  ${DropdownStyles.DropDownMainControlContainerStyle} {
    label {
      font-size: ${({ theme }) => theme.header.fontSize.splash2.default};
    }
  }
  ${ModalStyles.ModalContainerInnerStyle} {
    padding: 0;
    > header {
      display: grid;
      gap: 2rem;
      padding: 1.5rem var(--left-right-padding) 3rem;
    }
    > footer {
      display: grid;
      gap: 1.5rem;
      align-items: center;
      justify-content: center;
      padding: 3rem var(--left-right-padding) 6rem;
    }
  }
  ${LinkButtonStyles.ButtonLinkStyle} {
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  }
  ${SplashTextStyles.SplashCopySerifStyle} {
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
    font-size: 20px;
    @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
      font-size: 16px;
    }
  }
  ${AccordionStyles.AccordionStyle} {
    --accordionLeftRightPadding: var(--left-right-padding);
    --accordionTopBottomPadding: 2rem;
  }
  ${AccordionStyles.AccordionItemStaticStyle} {
    gap: 2rem;
    flex-flow: row nowrap;
    padding: 3rem var(--left-right-padding);
    ${Table2ColContentWrapperStyle} {
      border: none;
      padding: 0;
    }
    ${Table2ColTitleStyle} {
      border: none;
      gap: 0.5rem;
      flex-flow: column nowrap;
      > * {
        justify-content: center;
      }
    }
    ${AccordionStyles.AccordionItemHeaderStyle} {
      padding: 0;
      flex: 0.4;
      border-right: 1px solid ${({ theme }) => theme.line.color.default};
    }
    ${AccordionStyles.AccordionItemContainerStyle} {
      padding: 0;
      flex: 1;
    }
  }
  ${Table2ColLabelStyle} {
    font-weight: normal;
    text-transform: uppercase;
    font-size: ${pxToRem(14)};
  }
  ${Table2ColValueStyle} {
    font-weight: 900;
    font-size: ${({ theme }) => theme.header.fontSize.default.default};
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    --left-right-padding: 10vw;
    ${DropdownStyles.DropDownMainControlContainerStyle} {
      label {
        font-size: ${pxToRem(64)};
      }
    }
    ${Table2ColValueStyle} {
      font-weight: 900;
      font-size: ${({ theme }) => theme.header.fontSize.default.mobile};
    }
  }
`;
