import styled from 'styled-components';
import { HeaderStyles } from '@pointdotcom/pds';
import { pxToRem } from 'styleHelpers';

export const QuestionsListStyle = styled.div.attrs({ className: 'QuestionsListStyle' })`
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    gap: 1.75rem;
  }

  ${HeaderStyles.HeaderStyle} {
    margin: 0;

    > * {
      font-weight: 900;
      font-size: ${pxToRem(21)};

      @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
        font-size: ${pxToRem(18)};
      }
    }
  }

  p {
    margin: 0;
  }
`;

export const QuestionAndAnswerStyle = styled.div.attrs({ className: 'QuestionAndAnswerStyle' })`
  display: flex;
  flex-direction: column;
  gap: 1.125rem;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    gap: 1.5rem;
  }
`;
