import * as React from 'react';
import { Container, TemplatedText } from '@pointdotcom/pds';
import { useGetOriginationRateQuery } from 'services/api/prequalApi';
import { DEFAULT_PROCESSING_FEE_PERCENT } from 'store/constants';
import i18n from './i18n';
import * as styles from './styles';

interface HeiTermsFooterProps {
  includePerStateTerms?: boolean;
  shorterTerms?: boolean;
}

const complaintUrl = 'https://dfpi.ca.gov/file-a-complaint';

export function TermsText({ includePerStateTerms, shorterTerms }: HeiTermsFooterProps) {
  const { data: originationRateData } = useGetOriginationRateQuery();

  const originationRate = originationRateData?.rate
    ? originationRateData.rate
    : DEFAULT_PROCESSING_FEE_PERCENT;

  return (
    <>
      <p>
        {!shorterTerms && `${i18n.newWay} `}
        <TemplatedText
          values={{
            originationRate: `${originationRate}%`,
          }}
        >
          {i18n.pointPartners}
        </TemplatedText>
      </p>
      {includePerStateTerms && (
        <p>
          <TemplatedText
            values={{
              complaintLink: (
                <a href={complaintUrl} target="_blank" rel="noopener noreferrer">
                  {complaintUrl}
                </a>
              ),
            }}
          >
            {i18n.termsByState}
          </TemplatedText>
        </p>
      )}
    </>
  );
}

export default function HeiTermsFooter({
  includePerStateTerms = false,
  shorterTerms = false,
}: HeiTermsFooterProps) {
  return (
    <styles.HeiTermsFooterSectionStyle>
      <Container>
        <TermsText includePerStateTerms={includePerStateTerms} shorterTerms={shorterTerms} />
      </Container>
    </styles.HeiTermsFooterSectionStyle>
  );
}
