import React from 'react';
import nullthrows from 'nullthrows';
import ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';
import { DefaultTheme } from 'styled-components';
import { Style } from '@pointdotcom/pds';
import FullScreenCarousel from 'components/FullScreenCarousel';
import GenericMessageBanner, { BannerMessageType } from 'components/GenericMessageBanner';
import { captureProperties } from 'lib/posthogEvents';
import BaseEstimateModel from 'models/BaseEstimateModel';
import { Products } from 'store/constants';
import scenarios, { ScenarioType } from '../ScenariosTable/scenarios';
import { scenarioRescourceMap } from '../ScenariosTable/styles';
import CalcCarouselItem from './CalcCarouselItem';
import * as carouselDataItems from './carouselItems';

interface CarouselProps {
  scenario: null | ScenarioType;
  onCarouselClose?: () => unknown;
  estimate: BaseEstimateModel;
  product: Products;
  theme: DefaultTheme;
  isFinalPricing?: boolean;
  customScenarioPerc: number | string;
  getBuyBackTime: () => number | string;
}

const Carousel = ({
  scenario = null,
  onCarouselClose = () => null,
  estimate,
  product,
  theme,
  isFinalPricing = false,
  customScenarioPerc,
  getBuyBackTime,
}: CarouselProps) => {
  let bannerMessageType;

  if (estimate) {
    const messageType = BannerMessageType.Estimate;
    bannerMessageType = isFinalPricing ? null : messageType;
  }

  const banner = bannerMessageType ? (
    <GenericMessageBanner messageType={bannerMessageType} styleType={Style.Mono} show />
  ) : null;
  const selectedYear = parseInt(getBuyBackTime() as string, 10);

  const carouselItemsDefault = [
    carouselDataItems.originalAgreedValueItemForSSEDV2,
    carouselDataItems.theAppreciationItemForSSEDV2,
    carouselDataItems.theOptionPercItem,
    carouselDataItems.pointShareOfTheApprItem,
    carouselDataItems.yourTotalRepaymentItemForSSEDV2,
    carouselDataItems.youKeepItem,
    carouselDataItems.summaryItem,
  ];

  const carouselItems = carouselItemsDefault;
  const scenarioStyleResource = scenarioRescourceMap({ scenario, theme });

  const root = document.getElementById('root') ?? document.body;

  const carouselOverlay = (
    <Transition in={scenario !== null} timeout={0} mountOnEnter={false} unmountOnExit>
      {(status) => {
        const style: React.CSSProperties = {};
        const indicatorProps = { styleInactiveColor: scenarioStyleResource.color };

        if (scenario) {
          style.backgroundColor = scenarioStyleResource.color;
          style.backgroundImage = `url(${scenarioStyleResource.svg})`;
          style.backgroundBlendMode = 'difference';
          style.backgroundRepeat = 'no-repeat';
          style.backgroundAttachment = 'fixed';
          style.backgroundPosition = 'center bottom -18vw';
          style.backgroundSize = '100%';
        }

        return (
          <FullScreenCarousel
            items={scenario ? carouselItems : []}
            banner={banner}
            show={status === 'entered'}
            style={style}
            indicatorProps={indicatorProps}
            bannerLineSeparationType={
              scenario === ScenarioType.CUSTOM ? Style.InvertedSubtle : null
            }
            onClose={onCarouselClose}
            dataAttributes={(item) =>
              captureProperties({
                overlay: scenario && `${scenarios[scenario].label} scenario Popup Page`,
                step:
                  typeof item?.title === 'function'
                    ? item.title({ capIsHit: () => false, product })
                    : item?.title,
              })
            }
          >
            {({ item, handlePrevNext, isCurrent, showPrev, showNext }) => (
              <CalcCarouselItem
                key={`${product}-${scenario}-${item.key}`}
                product={product}
                carouselItems={carouselItems}
                selectedYear={selectedYear}
                scenario={nullthrows(scenario)}
                item={item}
                handlePrevNext={handlePrevNext}
                isCurrent={isCurrent}
                showPrev={showPrev}
                showNext={showNext}
                estimate={estimate}
                customScenarioPerc={customScenarioPerc}
              />
            )}
          </FullScreenCarousel>
        );
      }}
    </Transition>
  );

  return ReactDOM.createPortal(carouselOverlay, root);
};

export default Carousel;
