import React, { useCallback, useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';
import { useIsMobile } from '@pointdotcom/pds';
import * as styles from './styles';

interface StickyProps {
  children: React.ReactElement;
  buffer?: number;
  breakOnMobile?: boolean;
  startAtPageTop?: boolean; // set page scroll to top
}

const getTop = (elm?: HTMLElement | null) => {
  return (elm?.getBoundingClientRect().top || 0) + window.scrollY;
};

// Hacky for now. Flesh out and put into PDS if used again
// Note that other external libs were tried but required too much setup for something so simple
// This attempts to make element sticky without the need to modify the element or surrounding structure at all
const Sticky = ({
  children,
  buffer = 100,
  breakOnMobile = true,
  startAtPageTop = true,
}: StickyProps) => {
  const { isMobile } = useIsMobile();
  const [ready, setReady] = useState(false);
  const [size, setSize] = useState<{ width?: number; top?: number; left?: number }>({});
  const [yOffset, setYOffset] = useState<number>(0);
  const ref = useRef<HTMLElement>();
  const cloneRef = useRef<HTMLElement>();
  const observer = useRef<ResizeObserver | null>(null);

  const getChildHeightPlusTopDistance = () => {
    const child = ref.current;
    const childClone = cloneRef.current;
    return (getTop(child) || 0) + (childClone?.offsetHeight || 0);
  };

  const getSize = () => {
    const child = ref.current;
    return {
      width: child?.offsetWidth,
      height: child?.offsetHeight,
      top: getTop(child),
      left: child?.getBoundingClientRect().left,
    };
  };

  const getYOffset = () => {
    const windowScrollAmount = window.scrollY;
    const parent = ref.current?.parentElement;
    const parentTopDistance = getTop(parent); // Top distance of the sticky components parent element
    const parentHeightPlusTopDistance = parentTopDistance + (parent?.offsetHeight || 0); // the parents height plus the distance from the top
    const childHeightPlusTopDistance = getChildHeightPlusTopDistance();
    const overflowAmount = Math.abs(Math.min(window.innerHeight - childHeightPlusTopDistance)); // the amount that the child component overflows beyond the height of the screen

    // Move the sticky component UP when scrolling
    const topScrollDiff =
      window.innerHeight < childHeightPlusTopDistance - buffer + overflowAmount // if the windows height is less than the child height ...
        ? Math.min(windowScrollAmount, parentTopDistance + overflowAmount) // use the Y offset up until it hits where the top of the parent container is
        : 0;

    // Move the sticky component DOWN when scrolling
    const scrollBottom = parentHeightPlusTopDistance - childHeightPlusTopDistance + topScrollDiff; // the amount that element can scroll downward (top scroll diff is added since it may have scroll locked at the top and that amount must be accounted for)
    const scrollDiff = Math.max(0, windowScrollAmount - scrollBottom + buffer); // the amount scrolled minus the bottom and the buffer

    return scrollDiff + topScrollDiff;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledResize = useCallback(
    throttle(() => {
      setSize(getSize());
    }, 100),
    [ref.current]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledScroll = useCallback(
    throttle(() => {
      setYOffset(getYOffset());
    }, 10),
    [ref.current, window.scrollY]
  );

  useEffect(() => {
    // use a resize observer if supported. This handles edge cases where window resizing is done with tools like divvy
    if (ref.current && window.ResizeObserver && !observer.current) {
      observer.current = new ResizeObserver(throttledResize);
      observer.current.observe(ref.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [throttledResize]);

  useEffect(() => {
    if (startAtPageTop) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        setSize(getSize());
      }, 200);
    } else {
      setSize(getSize());
    }
  }, [startAtPageTop]);

  useEffect(() => {
    window.addEventListener('resize', throttledResize);
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('resize', throttledResize);
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [startAtPageTop, throttledResize, throttledScroll]);

  // Break use if on mobile, if no size data loaded
  const breakUse = Object.keys(size).length === 0 || (breakOnMobile && isMobile);

  // delay animation on the body a so that header doesnt jump on load
  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 0);
  }, []);

  return (
    <>
      <styles.BodyAnimationStyle animationReady={ready} />
      {React.cloneElement(children, {
        ref,
        style: { visibility: breakUse ? 'visible' : 'hidden' },
      })}
      <div
        style={{
          position: 'fixed',
          top: size.top,
          left: size.left,
          transform: `translateY(-${yOffset}px)`,
          visibility: breakUse ? 'hidden' : 'visible',
        }}
      >
        {React.cloneElement(children, {
          ref: cloneRef,
          style: { width: size.width },
        })}
      </div>
    </>
  );
};

export default Sticky;
