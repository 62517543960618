import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';
import { PaginationDirection } from '../constants';

interface ItemStyleProps {
  direction: PaginationDirection;
  itemAfterCurrent: boolean;
  itemBeforeCurrent: boolean;
  animationStatus: TransitionStatus;
}

export const TRANSITION_SPEED_MS = 400;
const transition = `transition: all ${TRANSITION_SPEED_MS}ms ease-in-out, transform ${
  TRANSITION_SPEED_MS * 0.8
}ms ease-in-out`;

export const ItemStyle = styled.div.attrs({
  className: 'PaginatorItemStyle' as string,
})<ItemStyleProps>`
  top: 0;
  left: 0;
  width: 100%;
  ${({ itemAfterCurrent, itemBeforeCurrent, animationStatus, direction }) => {
    const offsetAmount = '50%';
    let opacity = '0';
    let position = 'absolute';
    let zIndex = '1';
    let x = itemAfterCurrent ? offsetAmount : '0';
    let scale = '0.7';
    x = itemBeforeCurrent ? `-${offsetAmount}` : x;

    if (animationStatus === 'entering' || animationStatus === 'entered') {
      scale = '1';
      opacity = '1';
    }

    if (animationStatus === 'entered') {
      position = 'relative;';
      zIndex = '2';
      x = '0';
    }

    if (animationStatus === 'exiting') {
      x = direction === 'right' ? `-${offsetAmount}` : offsetAmount;
    }

    return `
      ${transition};
      transform: translateX(${x}) scale(${scale});
      position: ${position};
      z-index: ${zIndex};
      opacity: ${opacity}
    `;
  }};
  &.exited {
    visibility: hidden;
  }
`;

export const PaginatorStyle = styled.div.attrs({ className: 'PaginatorStyle' })`
  position: relative;
  transition: min-height 400ms ease-out;
  overflow: hidden;
  min-height: 400px;
`;
