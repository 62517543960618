import styled from 'styled-components';

export const ConfettiBackgroundStyle = styled.div.attrs({ className: 'ConfettiBackgroundStyle' })`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  canvas {
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;
