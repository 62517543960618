import styled from 'styled-components';

const padding = '1.5rem';

export const TableMobileRowContentStyle = styled.div.attrs({
  className: 'TableMobileRowContentStyle',
})`
  display: flex;
  flex-flow: row nowrap;
`;

export const MainContentStyle = styled.div.attrs({ className: 'MainContentStyle' })`
  flex: 3;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: left;
  text-align: left;
`;

export const RightContentStyle = styled.div.attrs({ className: 'RightContentStyle' })`
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 0 0.5em;
  @media only print {
    padding: 0;
    justify-content: flex-end;
  }
`;

export const FoldingColStyle = styled.div.attrs({ className: 'FoldingColStyle' })`
  flex: 0.7;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  border-left: 1px solid
    ${(props) =>
      props.styleType === 'inverted'
        ? props.theme.line.color.invertedSubtle
        : props.theme.line.color.default};
  @media only print {
    display: none;
  }
`;

export const TableFoldingIconStyle = styled.div.attrs({ className: 'TableFoldingIconStyle' })``;

// TODO: animate this
export const FoldingContentStyle = styled.div.attrs({ className: 'FoldingContentStyle' })`
  margin-top: 1rem;
  padding-right: ${padding};
  @media only print {
    padding: 0;
  }
`;

export const TableMobileStyle = styled.div.attrs({ className: 'TableMobileStyle' })`
  border-top: 1px solid
    ${(props) =>
      props.styleType === 'inverted'
        ? props.theme.line.color.invertedSubtle
        : props.theme.line.color.default};
`;

export const TableMobileRowStyle = styled.div.attrs({ className: 'TableMobileRowStyle' })`
  padding: ${padding};
  padding-right: 0;
  border-bottom: 1px solid
    ${(props) =>
      props.styleType === 'inverted'
        ? props.theme.line.color.invertedSubtle
        : props.theme.line.color.default};
  ${FoldingContentStyle} {
    display: ${(props) => (props.expanded ? 'block' : 'none')};
  }
  @media only print {
    padding: ${padding} 0;
    border-bottom: 1px solid ${(props) => props.theme.line.color.default};
    ${FoldingContentStyle} {
      display: block;
    }
  }
`;
