import React from 'react';
import { useLocation } from 'react-router';
import { getPageRouteItemByPage, getPathFromPage, pages } from 'containers/helpers';
import BreadCrumbs, { BreadCrumbProps, Crumb } from '../Breadcrumbs';
import i18n from '../Breadcrumbs/i18n';

export const dashboardCrumb: Crumb = {
  href: getPathFromPage(pages.DASHBOARD),
  text: i18n.dashboard,
};
export const tasksCrumb: Crumb = {
  href: getPathFromPage(pages.DASHBOARD_TASKS_LIST),
  text: i18n.tasks,
};
export const dashboardTaskCrumbs = [dashboardCrumb, tasksCrumb];

export const DashboardCrumbs = (props: BreadCrumbProps) => {
  const autoCrumbs = [];
  const location = useLocation();
  let currentCrumb = props.currentCrumb;
  const dashboardPath = getPageRouteItemByPage(pages.DASHBOARD)?.path;
  const tasksPath = getPageRouteItemByPage(pages.DASHBOARD_TASKS_LIST)?.path;

  // handle the dashboard portion
  if (dashboardPath) {
    if (location.pathname === dashboardPath) {
      currentCrumb = i18n.dashboard;
    } else if (location.pathname.includes(dashboardPath)) {
      autoCrumbs.push(dashboardCrumb);
    }
  }

  // handle the tasks portion
  if (tasksPath) {
    if (location.pathname.endsWith(tasksPath)) {
      currentCrumb = i18n.tasks;
    } else if (location.pathname.includes(tasksPath)) {
      autoCrumbs.push(tasksCrumb);
    }
  }

  return <BreadCrumbs navItems={props.navItems || autoCrumbs} currentCrumb={currentCrumb} />;
};

const DashboardTasksCrumbs = ({ navItems, ...restOfProps }: BreadCrumbProps) => {
  return <BreadCrumbs navItems={[dashboardCrumb, tasksCrumb]} {...restOfProps} />;
};

export default DashboardTasksCrumbs;
