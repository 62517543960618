import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { Page, generateUrlFromPage } from 'containers/helpers';
import { useDashboardData } from 'containers/prequal/hooks';
import { Task, TaskStatus } from 'services/apiTypes/taskTypes';
import { CompletedTask, openTaskCompletedModal } from 'store/general';
import { useDispatch } from 'store/hooks';
import i18n from './i18n';

export type TaskCompletionModalOptions = Omit<CompletedTask, 'summary' | 'taskType'> & {
  subtle?: boolean;
  task?: Pick<Task, 'id' | 'type'>;
  title?: string;
  heading?: string;
  summary?: null | CompletedTask['summary'];
  detailList?: {
    ordered: boolean;
    items: string[];
  };
};

export const useTaskCompletionModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dashboard } = useDashboardData();

  return useCallback(
    ({
      noTasksFooterLinkType,
      subtle,
      task,
      title,
      heading,
      summary: summaryFromOptions,
      detailList,
    }: TaskCompletionModalOptions) => {
      let summary = summaryFromOptions;
      if (summary == null) {
        const openTasks = dashboard?.getTasks({ status: TaskStatus.Active });
        const allTasksCompleted =
          openTasks != null &&
          task != null &&
          !openTasks.some((otherTask) => otherTask.id !== task.id);
        summary = allTasksCompleted ? i18n.finishingTheseTasks : i18n.keepUpThe;
      }

      const taskCompleted: CompletedTask = {
        noTasksFooterLinkType,
        subtle,
        title,
        heading,
        summary,
        detailList,
        taskType: task?.type,
      };

      dispatch(openTaskCompletedModal(taskCompleted));
      navigate(generateUrlFromPage(Page.DASHBOARD));
    },
    [dashboard, dispatch, navigate]
  );
};
