import React from 'react';
import { FullPageLoading } from 'components/FullScreenLoading';
import HeiTermsFooter from 'components/HeiTermsFooter';
import EstimatorPage from 'containers/EstimatorPage';
import AmountControlSection from 'containers/EstimatorPage/AmountControlSection';
import BookingAndNavFooter from 'containers/EstimatorPage/BookingAndNavFooter';
import HeroContainer from 'containers/EstimatorPage/HeroContainer';
import PrepaymentAndBuyBackSection from 'containers/EstimatorPage/PrepaymentAndBuyBackSection';
import ScenariosTableAndCarousel from 'containers/EstimatorPage/ScenariosTableAndCarousel';
import { useGetOriginationRateQuery } from 'services/api/prequalApi';
import { Products } from 'store/constants';

const HeiEstimatorPage = ({ followUp, ...props }) => {
  const { isLoading: isOriginationRateDataLoading } = useGetOriginationRateQuery();

  if (isOriginationRateDataLoading) return <FullPageLoading />;

  return (
    <EstimatorPage {...props} product={Products.HEI} isFinalPricing={followUp?.isFinalPricing()}>
      {({ children, ...childProps }) => {
        return (
          <>
            <HeroContainer followUp={followUp} {...props} {...childProps} />
            <AmountControlSection {...childProps} />
            <PrepaymentAndBuyBackSection product={Products.HEI} {...childProps} />
            <ScenariosTableAndCarousel {...childProps} />
            <BookingAndNavFooter {...childProps} />
            <HeiTermsFooter />
          </>
        );
      }}
    </EstimatorPage>
  );
};

export default HeiEstimatorPage;
