export default {
  heiPageTitle: 'Point HEI | Unlock your home equity with no monthly payments',
  getUpTo: 'Get up to %amount% with%br% no monthly payments, %ever%.',
  ever: 'ever',
  noIncome: 'No income requirement',
  creditOkay: '500+ credit okay',
  prequalifying: 'Prequalifying won’t affect your credit score',
  secondsTo: '60 seconds to your estimate',
  whatsYour: 'What’s your home address?',
  seeIf: 'See if my home qualifies',
};
