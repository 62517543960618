import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { LinkButton, useIsMobile } from '@pointdotcom/pds';
import DotIndicators, { OnIndicatorClick } from 'components/DotIndicators';
import Paginator from 'components/Paginator';
import { ContractExitDetails } from 'services/apiTypes/contractTypes';
import PayoffExpressionModalContent from './PayoffExpressionModalContent';
import SummaryModalContent from './SummaryModalContent';
import { ContentMode } from './constants';
import i18n from './i18n';
import * as styles from './styles';

interface PayoffModalProps {
  isOpen: boolean;
  exitData?: ContractExitDetails;
  onClose: () => void;
}

export const getViewsByExitData = (exitData: ContractExitDetails | undefined) => {
  let views = [
    ContentMode.PayoffAmount,
    ContentMode.PointsShare,
    ContentMode.TotalAppreciation,
    ContentMode.Summary,
  ];
  if (exitData?.capped) {
    views = [ContentMode.CappedPayoff];
  }

  return views;
};

const PayoffModal = ({ isOpen, exitData, onClose }: PayoffModalProps) => {
  const [currentItem, setCurrentItem] = useState(0);
  const { isMobile } = useIsMobile();
  const theme = useTheme();
  const views = getViewsByExitData(exitData);
  const isMultiview = views.length > 1;
  const handleClose = () => {
    setCurrentItem(0);
    onClose();
  };
  const onNext = () => setCurrentItem((prev) => Math.min(views.length - 1, prev + 1));
  const onPrev = () => setCurrentItem((prev) => Math.max(0, prev - 1));
  const paginatorItems = views.map((contentMode, i) => {
    if (contentMode === ContentMode.Summary) {
      return (
        <SummaryModalContent
          exitData={exitData}
          modalClose={handleClose}
          key={`paginatoritemtype-${ContentMode.Summary}`}
        />
      );
    }
    return (
      <PayoffExpressionModalContent
        mode={contentMode}
        exitData={exitData}
        onNext={onNext}
        modalClose={handleClose}
        showFooterCloseButton={i === views.length - 1}
        key={`paginatoritemtype-${contentMode}`}
      />
    );
  });

  const handleDotIndicatorClick: OnIndicatorClick = (e, { index }) => {
    setCurrentItem(index);
  };

  return (
    <styles.PayoffModalStyle
      isOpen={isOpen}
      onModalClose={handleClose}
      width="1000px"
      maxWidth={isMobile ? '100vw' : 'calc(100vw - 40px)'}
      shadeBg
      showProgress={isMultiview}
      upperHeader={
        isMultiview ? (
          <>
            {currentItem > 0 ? <LinkButton onClick={onPrev}>{i18n.back}</LinkButton> : <span />}
            <DotIndicators
              onIndicatorClick={handleDotIndicatorClick}
              items={views.map((_, i) => ({ key: `page${i}` }))}
              currentIndex={currentItem}
              styleActiveColor={theme.Color.PointBlack}
              styleInactiveColor={theme.Color.Gray1Transparency}
            />
          </>
        ) : null
      }
      footer={
        <styles.CloseFooterStyle>
          <LinkButton onClick={handleClose}>Close</LinkButton>
        </styles.CloseFooterStyle>
      }
    >
      <Paginator items={paginatorItems} currentIndex={currentItem} focusSelector="h2" />
    </styles.PayoffModalStyle>
  );
};

export default PayoffModal;
