import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { defaultTheme } from '@pointdotcom/pds';
import bugsnagClient from 'lib/bugsnagClient';
import reduxStore from 'store/index';
import { shouldForwardProp } from 'styleHelpers';
import { FormTemplateOptions, pageTemplateMap } from '../constants';
import FormTemplate from './FormComponents/FormTemplate';
import { GetBaseFormFieldPropsFunc, GetFieldValueByPathFunc } from './constants';

interface PageFormProps {
  getFieldValueByPath: GetFieldValueByPathFunc;
  getBaseFormFieldProps: GetBaseFormFieldPropsFunc;
  formTemplateOptions: FormTemplateOptions;
}

const getSheet = () => {
  // Need to use for loop because document.styleSheets is not iterable
  for (let i = 0; i < document.styleSheets.length; i += 1) {
    const styleSheet = document.styleSheets[i];
    if (styleSheet.ownerNode) {
      const ownerNode = styleSheet.ownerNode as HTMLElement;
      if (ownerNode.attributes) {
        if (ownerNode.getAttribute('data-styled')) {
          return styleSheet;
        }
      }
    }
  }
  return null;
};

const styles = () => {
  const neededSheet = getSheet();
  if (!neededSheet) {
    bugsnagClient.notify(new Error('Could not find styled-components style sheet'));
    return '';
  }
  let rules = '';
  for (let i = 0; i < neededSheet.cssRules.length; i += 1) {
    rules += `
    ${neededSheet.cssRules[i].cssText}
    `;
  }

  return rules;
};

// This css is needed for grid layout to work
// since UW is using QT4 Webkit from 2017 for rendering PDFs
const extraCSS = `
  body {
    padding-top: 1rem;
  }

  legend {
    padding-bottom: 1rem;
  }

  .BirthDateInputStyle {
    display: inline-block;
    padding: 0.75rem 1rem 0.75rem 1rem;
    border: 1px solid rgb(115, 115, 115);
    background-color: rgb(255, 255, 255);
  }

  .BirthDateInputStyle .InputContainerStyle {
    display: inline-block;
  }

  .YesNoFieldStyle {
    display: block;
  }

  .YesNoFieldStyle .CheckboxStyle {
    display: inline-block;
    margin: 1rem;
  }

  .CheckboxStyle input {
    top: -2px;
    left: -1000px;
  }

  .CheckboxStyle.checked input {
    top: -2px;
    left: 10px;
  }

  .CheckboxStyle span {
    text-align: start;
    margin-left: 40px;
  }

  .CheckboxStyle a {
    margin: auto 4px auto 4px;
    white-space: nowrap;
    overflow-wrap: break-word;
    display: inline;
  }

  .ListOfElementsStyle {
    display: inline-block;
  }

  .ParagraphBlockStyle {
    display: inline-block;
  }

  .FormSection {
    margin: 2rem 1rem 2rem 1rem;
  }

  .LabeledContainerStyle {
    margin-bottom: 1rem;
  }

  .InputFieldLabelContainerStyle input {
    padding: 1rem;
  }

  .InputFieldLabelContainerStyle .InputStyle {
    margin-left: 1rem;
  }

  .TextAreaStyle p {
    border: 1px solid rgb(115, 115, 115);
    padding: 1rem;
  }
`;

const htmlWrapper = (body: string) => {
  const stylesString = styles();

  // textareas are fixed height and only JS can change that dynamically
  // so we replace them with paragraphs
  const fixedBody = body.replaceAll('textarea', 'p');

  return `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" /><meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" /><meta name="theme-color" content="#000000" />
    <style src="/true">
      ${stylesString}
    </style>
    <style src="/true">
      ${extraCSS}
    </style>
  </head>
  <body>${fixedBody}</body>
</html>
`;
};

const HiddenAuditForm = ({
  getBaseFormFieldProps,
  getFieldValueByPath,
  formTemplateOptions,
}: PageFormProps) => {
  return (
    <>
      {Object.entries(pageTemplateMap).map(([k, v]) => (
        <FormTemplate
          key={k}
          template={v(formTemplateOptions)}
          getBaseFormFieldProps={getBaseFormFieldProps}
          getFieldValueByPath={getFieldValueByPath}
        />
      ))}
    </>
  );
};

export default HiddenAuditForm;
export const getFormAsString = (props: PageFormProps) => {
  // TODO: figure out why this isnt working in the test env
  if (process.env.NODE_ENV === 'test') {
    return '<div>TODO</div>';
  }

  const container = document.createElement('div');

  // TODO: may need to eventually modify this to use createRoot
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    <ReduxProvider store={reduxStore}>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <ThemeProvider theme={defaultTheme}>
          <HiddenAuditForm {...props} />
        </ThemeProvider>
      </StyleSheetManager>
    </ReduxProvider>,
    container
  );
  return htmlWrapper(container.innerHTML.toString());
};
