import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  CSSTransition,
  Transition,
  TransitionGroup,
  TransitionStatus,
} from 'react-transition-group';
import {
  Button,
  Header,
  Input,
  InputChangeEvent,
  Key,
  LinkButton,
  Logo,
  QueryComponent,
  Size,
  SplashText,
  Style,
  TemplatedText,
  Validation,
  directory,
  eventHasKey,
  handleModalFocus,
  messagingI18n,
} from '@pointdotcom/pds';
import GenericMessageBanner from 'components/GenericMessageBanner';
import PointCopyright from 'components/MainFooter/PointCopyright';
import { Page, generateUrlFromPage, pages } from 'containers/helpers';
import { useDashboardData } from 'containers/prequal/hooks';
import { useHistory } from 'containers/routerHelpers';
import { usePostHogEvents } from 'lib/posthogEvents';
import { useDashboardLoginMutation } from 'services/api/homeownerApi';
import i18n from './i18n';
import * as styles from './styles';
import useLoginPageMessaging from './useLoginPageMessaging';

type OnBackToLoginType = () => void;

const EmailSentContent = ({
  onBackToLogin,
  email,
  isLoading,
  resendEmail,
}: {
  onBackToLogin: OnBackToLoginType;
  email: string;
  isLoading: boolean;
  resendEmail: () => void;
}) => {
  const history = useHistory();
  return (
    <styles.EmailSentContentStyle>
      <Header styleSize={Size.Large} id="modalMessage" tabIndex={-1}>
        {i18n.checkYourEmail}
      </Header>
      <SplashText>
        <TemplatedText values={{ email }}>{i18n.ifEmailExists}</TemplatedText>
      </SplashText>
      <Button
        styleType={Style.Tertiary}
        block
        loading={isLoading}
        disabled={isLoading}
        onClick={resendEmail}
      >
        <QueryComponent queriedComponent={i18n.resendLink}>{i18n.didntGetTheLink}</QueryComponent>
      </Button>
      <Button
        styleType={Style.Quaternary}
        block
        onClick={() => history.push(generateUrlFromPage(pages.PREQUAL_DEFAULT))}
      >
        <QueryComponent queriedComponent={i18n.prequalifyNow}>
          {i18n.needToPrequalify}
        </QueryComponent>
      </Button>
      <LinkButton onClick={onBackToLogin}>{i18n.backToLogin}</LinkButton>
    </styles.EmailSentContentStyle>
  );
};

const MessageOverlay = ({
  children,
  inputRef,
  animationStatus,
  onBackToLogin,
}: {
  children: React.ReactNode;
  inputRef: React.RefObject<HTMLInputElement>;
  animationStatus: TransitionStatus;
  onBackToLogin: OnBackToLoginType;
}) => {
  // keep focus in the overlay for accessibility
  const thisRef = useRef<HTMLDivElement>(null);
  const focusableSelector = 'button, h1, h2, h3';

  const handleBgClick = useCallback(
    (e: MouseEvent) => {
      if (!thisRef.current?.contains(e.target as Node)) {
        onBackToLogin();
      }
    },
    [thisRef, onBackToLogin]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (eventHasKey(e, Key.Tab) && thisRef.current) {
        handleModalFocus(e, {
          elementContext: thisRef.current,
          focusableSelector,
        });
      }

      if (eventHasKey(e, Key.Esc)) {
        onBackToLogin();
      }
    },
    [thisRef, focusableSelector, onBackToLogin]
  );

  useEffect(() => {
    const inputRefElmCopy = inputRef.current; // the linter told me to make this
    const focusableElements = thisRef.current?.querySelectorAll(focusableSelector);

    // focus on the first element
    if (focusableElements && focusableElements.length > 0) {
      const firstFocusableElement = focusableElements[0] as HTMLElement;
      firstFocusableElement.focus();
    }

    // add click and keydown handlers on the doc
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('click', handleBgClick);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleBgClick);
      inputRefElmCopy?.focus();
    };
  }, [handleBgClick, handleKeyDown, inputRef]);

  return (
    <styles.MessageOverlayStyle
      aria-modal="true"
      role="dialog"
      aria-labelledby="modalMessage"
      ref={thisRef}
      animationStatus={animationStatus}
    >
      <styles.ContentContainerStyle>{children}</styles.ContentContainerStyle>
    </styles.MessageOverlayStyle>
  );
};

const LoginPage = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [postLogin, { isLoading }] = useDashboardLoginMutation();
  const [emailAddress, setEmailAddress] = useState('');
  const [fieldError, setFieldError] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const { PointNumber } = directory;
  const useLoginMessaging = useLoginPageMessaging();

  const { dashboard, isLoading: isDashboardLoading } = useDashboardData();
  const needsAuth = dashboard?.getNeedsAuth();
  const isDashboardLoaded = !isDashboardLoading && dashboard != null;
  const posthogEvents = usePostHogEvents();
  const navigate = useNavigate();

  useEffect(() => {
    if (needsAuth) {
      posthogEvents.clearDashboardLogin();
    } else if (isDashboardLoaded) {
      navigate(generateUrlFromPage(Page.DASHBOARD));
    }
  }, [isDashboardLoaded, navigate, needsAuth, posthogEvents]);

  const handleFocus = () => {
    setFieldError('');
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setFieldError('');
    e.preventDefault();

    if (!Validation.patterns.email.test(emailAddress)) {
      setFieldError(Validation.i18n.invalidEmail);
      return;
    }

    try {
      await postLogin({ emailAddress }).unwrap();
      setEmailSent(true);
    } catch (err) {
      setFieldError(messagingI18n.errors.unknownError);
    }
  };

  const handleEmailChange: InputChangeEvent = (e, { value }) => {
    setFieldError('');
    setEmailAddress(value);
  };

  const handleOnBackToLogin = () => {
    setEmailAddress('');
    setEmailSent(false);
  };

  // Check for expired magic link
  if (useLoginMessaging.shouldShowExpiredPage) {
    return useLoginMessaging.expiredLinkPage;
  }

  return (
    <styles.LoginPageWrapperStyle>
      <GenericMessageBanner blueTheme show={!!useLoginMessaging.bannerMessage}>
        {useLoginMessaging.bannerMessage}
      </GenericMessageBanner>
      <styles.LoginPageBodyStyle />
      <styles.LoginPageStyle>
        <header>
          <Logo />
        </header>
        <CSSTransition
          in={emailSent}
          timeout={styles.messageAnimSpeedMS + styles.messageAnimSpeedMS}
          classNames="messageOpen"
        >
          <styles.MainContainerStyle>
            <styles.TitleColumnStyle>
              <div>
                <h1>{i18n.dashboardName}</h1>
                <Header italic styleSize={Size.Medium} noMargin>
                  {i18n.manageTheStatus}
                </Header>
              </div>
            </styles.TitleColumnStyle>
            <styles.InputColumnStyle>
              <styles.ContentContainerStyle>
                <Header styleSize={Size.Splash}>{i18n.logIn}</Header>
                <SplashText>{i18n.toReceive}</SplashText>
                <form onSubmit={handleSubmit}>
                  <Input
                    placeholder={i18n.yourEmail}
                    onChange={handleEmailChange}
                    value={emailAddress}
                    trim
                    error={!!fieldError}
                    helptext={fieldError}
                    onFocus={handleFocus}
                    inputMode="email"
                    ref={inputRef}
                  />
                  <Button type="submit" block loading={isLoading} disabled={isLoading}>
                    {i18n.continue}
                  </Button>
                </form>
              </styles.ContentContainerStyle>
              <TransitionGroup component={null}>
                {emailSent && (
                  <Transition in={emailSent} timeout={styles.messageAnimSpeedMS}>
                    {(status) => (
                      <MessageOverlay
                        inputRef={inputRef}
                        animationStatus={status}
                        onBackToLogin={handleOnBackToLogin}
                      >
                        <EmailSentContent
                          email={emailAddress}
                          onBackToLogin={handleOnBackToLogin}
                          resendEmail={() => postLogin({ emailAddress })}
                          isLoading={isLoading}
                        />
                      </MessageOverlay>
                    )}
                  </Transition>
                )}
              </TransitionGroup>
            </styles.InputColumnStyle>
          </styles.MainContainerStyle>
        </CSSTransition>
        <footer>
          <PointCopyright />
          <LinkButton href={`tel:${PointNumber.Support}`}>
            <TemplatedText values={{ phone: PointNumber.Support }}>{i18n.needHelp}</TemplatedText>
          </LinkButton>
        </footer>
      </styles.LoginPageStyle>
    </styles.LoginPageWrapperStyle>
  );
};

export default LoginPage;
