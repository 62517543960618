import * as React from 'react';
import { Container, DirectionAndPlacement, Header, LinkButton, Size } from '@pointdotcom/pds';
import CapShield from '../CapShield';
import i18n from './i18n';
import * as styles from './styles';

interface HeiOfferEstimatorPageHPCSectionProps {
  onOpenCapModal: () => void;
}

export default function HeiOfferEstimatorPageHPCSection({
  onOpenCapModal,
}: HeiOfferEstimatorPageHPCSectionProps): JSX.Element {
  return (
    <styles.HPCSectionStyle>
      <styles.HPCSectionTopDividerStyle>
        <CapShield />
      </styles.HPCSectionTopDividerStyle>
      <Container styleAlign={DirectionAndPlacement.Center}>
        <Header styleSize={Size.Small} styleAlign={DirectionAndPlacement.Center}>
          {i18n.youllNeverPayMore}
        </Header>
        <p>{i18n.theHPCEnsures}</p>
        <p>
          <LinkButton onClick={onOpenCapModal}>{i18n.howDoesTheCapWork}</LinkButton>
        </p>
      </Container>
    </styles.HPCSectionStyle>
  );
}
