import React from 'react';
import { Link } from 'react-router-dom';
import { getPathFromPage, pages } from 'containers/helpers';
import i18n from './i18n';
import * as styles from './styles';

const dashboardPath: string = getPathFromPage(pages.DASHBOARD)!;

interface WhatsNextListProps {
  listItems: string[];
}

const WhatsNextList = ({ listItems }: WhatsNextListProps) => {
  return (
    <styles.WhatsNextListStyle>
      {listItems.map((item) => (
        <li key={item}>{item}</li>
      ))}
      <li>
        <Link to={dashboardPath}>{i18n.whatsNextDashboard}</Link>
      </li>
    </styles.WhatsNextListStyle>
  );
};

export default WhatsNextList;
