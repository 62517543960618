export default {
  pageName: 'Verify Identity',

  continue: 'Continue',
  lastFour: 'Last four digits of SSN',
  firstName: 'First name',
  lastName: 'Last name',

  subtitle:
    '<p>We couldn’t obtain your credit profile with the name and address you provided.</p> <p>To finalize your offer, please confirm your legal name and provide the last four digits of your Social Security number below.</p>  <p>This will <b>not</b> affect your credit score.</p>',
  pleaseEnter: 'Please enter the last four digits of your SSN',

  willSubmitting: 'Will submitting this form impact my credit score?',
  noYourCredit:
    'No, your credit score will not be impacted during this pre-qualification because we are not making a “hard inquiry” to your credit report.',

  howDo: 'How do you protect my information?',
  pointTakes: `Point takes your privacy seriously and will never sell your information. All data
    entered into the Point site is encrypted using industry-standard 128-bit AES cipher
    and transported over secure TLS protocols. TLS allows sensitive information such as
    Social Security numbers to be transmitted securely. Your information is unreadable
    to anyone except Point.`,

  whyCouldnt: 'Why couldn’t you obtain my credit profile?',
  youMay: `You may have recently moved or changed your name; you may have a typo in your
      application; or you may have requested that the credit bureau TransUnion freeze
      your credit report. You can %freezeLink% here.`,
};
