/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Loader, Logo, Size, mixins } from '@pointdotcom/pds';
import * as styles from './styles';

// NOTE: Any changes made to this will need to be reflected in the static version within index.html
const FullScreenLoading = () => (
  <styles.FullScreenLoadingStyle>
    <mixins.BodyForFixedStyle />
    <Logo />
    <Loader styleSize={Size.Large} />
  </styles.FullScreenLoadingStyle>
);

// Same as the above, but static position instead of fixed
// Useful for showing loading content on a page with chrome
// already loaded, but not the content
const FullPageLoading = () => (
  <styles.FullScreenLoadingStyle fullPage>
    <Loader styleSize={Size.Large} />
  </styles.FullScreenLoadingStyle>
);

export default FullScreenLoading;
export { FullPageLoading };
