export default {
  thePointHEI:
    'The Point Home Equity Investment (HEI) is different from other options you may have considered. The magic is that there are no monthly payments — for up to %term% years.',
  withNoMonthly:
    'With no monthly payments, your cash flow will open up for other uses—such as savings or living expenses. And you won’t have any missed payments or late fees.',
  insteadOf:
    'Instead of monthly payments, you’ll repay in one lump sum when you exit. You can exit at any time in the %term% year term with no prepayment penalty. Homeowners typically exit when selling their home, doing a cash-out refinance, or through other means.',
  thereIsNo:
    'There is no interest rate with the HEI. Instead, Point makes money by making an investment in your home alongside you. The more your home appreciates, the more we may both earn. The less it appreciates, the less we may both earn.',
  whenYouExit:
    'When you exit, you’ll pay back the original amount received (%selectedAmount%) plus a portion of your home’s appreciation. Let’s take a closer look at what goes into the repayment calculation.',
  youIndicated:
    'You indicated that today your home is worth about %homeValue%. We start calculating appreciation at a value lower than this—%homeValueRiskAdjusted%. We do this to protect our investment, but also to enable us to share in any losses as well.',
  whenYouExitIfYourHome:
    'When you exit, if your home value is lower than %homeValueRiskAdjusted%, you’ll pay back less than you originally received. You’ll repay the original %selectedAmount% %minus% %optionPercentage% of the difference between the exit value and %homeValueRiskAdjusted%. We hope this doesn’t happen, but in case it does, this is a built in protection for you.',
  ifYourHomeValueIsHigher:
    'If your final home value is higher than %homeValueRiskAdjusted%, you’ll pay back the original %selectedAmount% %plus% %optionPercentage% of the difference between the exit value and %homeValueRiskAdjusted%. Remember, we won’t be receiving %optionPercentage% of your entire home value, just %optionPercentage% of this difference. You’ve worked hard to build up your equity and we don’t think it would be fair to share in all of it.',
  importantlyYoullNever:
    'Importantly, you’ll never pay more than the Homeowner Protection Cap. This is another safeguard for you that puts an upper limit on how much you’ll repay.',
  theHomeownerProtectionCap:
    'The Homeowner Protection Cap means you’ll never pay more than you would on an equivalent loan with an %capApr% APR, compounded monthly. This is the maximum you’d ever repay. Homeowners may pay much less than this.',
  letsWorkThrough:
    'Let’s work through an example. Imagine you sell your home 8 years from now for %homeValueAppreciated%. Out of the proceeds of the sale, we would receive the original %selectedAmount% back. And to calculate Point’s share of the appreciation...',
  wedLookAtTheDifference:
    '...we’d look at the difference between the sale price and the %homeValueRiskAdjusted% we started with. That’s %homeValueAppreciated% -  %homeValueRiskAdjusted% = %appreciatedDiff%. You’d then owe %optionPercentage% of that difference, which is %appreciatedOwnerPays%.',
  addingTheOriginal:
    'Adding the original %selectedAmount% to this %appreciatedOwnerPays% would equal your total repayment amount of %totalOwnerPays%. This is equivalent to a %equivApr% APR. Since this is less than the %capApr% cap, your repayment would be this %totalOwnerPays%.',
  thisIsJustAnExample:
    'This is just an example. If your home value increases less, you may repay less. if it increases more, you may repay more, though never more than the Homeowner Protection Cap max.',
  withAnHEI:
    'With an HEI from Point, you get the freedom of cash now with the flexibility to repay later. Meanwhile enjoy peace of mind knowing there are safeguards to protect you along the way. %continueMyApplication%',
  continueMyApplication: 'Continue my application',
  minus: 'minus',
  plus: 'plus',
};
