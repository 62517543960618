import * as z from "zod";

export const errorSchema = z
  .object({
    /**
     * HTTP response error code
     */
    code: z.number(),
    /**
     * Explanation what went wrong
     */
    message: z.string(),
  })
  .catchall(z.unknown() as z.ZodType);
export type Error = z.infer<typeof errorSchema>;
