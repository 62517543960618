import styled, { css } from 'styled-components';

export const LOGOSETS = {
  DEFAULT: 'default',
  GRID: 'grid',
};

const defaultLogoSetCSS = css`
  grid-template-areas: 'bbb tj fx ta fc ft';
  grid-auto-columns: 1fr;
  img {
    max-width: 100%;
  }
  .tj {
    grid-area: tj;
  }
  .fx {
    grid-area: fx;
    transform: scale(1.1);
  }
  .ta {
    grid-area: ta;
    transform: scale(0.65);
  }
  .fc {
    grid-area: fc;
    transform: scale(0.9);
  }
  .ft {
    grid-area: ft;
    transform: scale(0.8);
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    grid-template-columns: 1fr 2fr 2fr;
    grid-template-areas:
      'bbb tj ta'
      'fc fx ft';
  }
`;

const gridLogoSetCSS = css`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-auto-rows: minmax(min-content, max-content);
  align-items: center;
  justify-items: center;
  > * {
    &:first-child {
      padding-bottom: 8px;
    }
    &:nth-child(2) {
      padding: 8px 0 0;
    }
    &:nth-child(5) {
      padding: 0 20px 8px;
    }
  }
`;

const logosetCSSMap = {
  [LOGOSETS.DEFAULT]: defaultLogoSetCSS,
  [LOGOSETS.GRID]: gridLogoSetCSS,
};

export const LogoBarStyle = styled.div.attrs({ className: 'LogoBarStyle' })`
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 1rem;
  .BBB {
    transform: scale(0.8);
    grid-area: bbb;
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding: ${({ theme }) => theme.container.gutter.default};
    gap: 0.8rem;
    .BBB {
      padding: 0.5rem 10px;
      max-width: 37vw;
      > * {
        padding: 0 6px;
      }
    }
  }
  ${({ useLogoSet }) => logosetCSSMap[useLogoSet]}
`;
