import { pages } from 'containers/helpers';
import { Products } from 'store/constants';
import { MORE_INFO_REQUIRED, PQ_APPLICATION_STATUS } from 'types';

export const nextPageFromResponse = (product: Products, response: TSFixMe) => {
  const { status, problems } = response.products[product];

  let nextPage = pages.PREQUAL_INELIGIBLE;
  if (status === PQ_APPLICATION_STATUS.NEEDS_MORE_INFORMATION && problems?.length) {
    // figure out which page is next to get missing info
    const missingData = problems[0].code;
    if (missingData === MORE_INFO_REQUIRED.SSN) {
      nextPage = pages.PREQUAL_SSN;
    } else if (missingData === MORE_INFO_REQUIRED.FICO) {
      nextPage = pages.PREQUAL_CREDIT_SCORE;
    } else if (missingData === MORE_INFO_REQUIRED.MORTGAGE) {
      nextPage = pages.PREQUAL_MORTGAGE_BALANCE;
    }
  } else if (status === PQ_APPLICATION_STATUS.WAITLIST) {
    nextPage = pages.PREQUAL_WAITLIST_SIGNUP;
  }
  return nextPage;
};

export class NotInServiceAreaError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'NotInServiceAreaError';
  }
}

export function verifyServiceArea(product: Products, availableProducts: Products[]) {
  const productFound = availableProducts.includes(product);

  // we want to explicitly throw an error here if the product is not available
  if (!productFound) {
    throw new NotInServiceAreaError();
  }
}
