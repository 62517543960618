import styled from 'styled-components';
import { ModalStyles, mixins } from '@pointdotcom/pds';
import { VideoButtonStyle } from 'components/VideoPlayer/VideoButton/styles';

export const ReferenceSectionStyle = styled(ModalStyles.ModalInnerFooterStyle).attrs({
  className: 'ReferenceSectionStyle',
})`
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-top: 1px solid ${({ theme }) => theme.line.color.default};
  display: flex;
  flex-flow: row nowrap;
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  gap: 2rem;
  position: relative;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  > * {
    flex: 50%;
    &:last-child {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  ${VideoButtonStyle} {
    transition: all 200ms ease-in-out;
    position: absolute;
    top: -0.5rem;
  }
  ${mixins.desktopHoverCondition()} {
    &:hover {
      background: ${({ theme }) => mixins.rgba(theme.Color.PointBlack, 0.05)};
      border-top-color: transparent;
      ${VideoButtonStyle} {
        transform: scale(1.03);
      }
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    flex-flow: column;
    align-items: flex-start;
    ${VideoButtonStyle} {
      position: static;
    }
  }
`;
