import React, { useCallback, useState } from 'react';
import {
  Button,
  ButtonLeftRightIconType,
  DirectionAndPlacement,
  Dropdown,
  DropdownChangeEvent,
  DropdownChangeEventProps,
  Header,
  HelpTextAnimated,
  IncrementorLayoutType,
  LinkButton,
  ModalProps,
  Size,
  SplashText,
  Style,
  TemplatedText,
  Validation,
  normalizeToString,
  templatedString,
  useIsMobile,
} from '@pointdotcom/pds';
import Table2Col from 'components/Table2Col';
import { useTaskCompletionModal } from 'containers/hooks/useTaskCompletionModal';
import { useFollowUpFetch } from 'containers/prequal/hooks';
import FollowUpEstimateModel, { SteppedPricingItem } from 'models/FollowUpEstimateModel';
import { currencyMask, percMask } from 'models/helpers';
import { useRequestNewAmountMutation } from 'services/api/underwriteApi';
import { CompletedTask, NoTasksFooterLinkType } from 'store/general';
import i18n from './i18n';
import * as styles from './styles';

type PreviewOfferAmountModalProps = ModalProps &
  Pick<ReturnType<typeof useFollowUpFetch>, 'followUp' | 'followUpId'> & {
    defaultValue?: string;
    steppedPricingOptions?: Array<SteppedPricingItem>;
  };

export default function PreviewOfferAmountModal({
  defaultValue,
  followUp,
  followUpId,
  steppedPricingOptions,
  ...modalProps
}: PreviewOfferAmountModalProps) {
  const [manualError, setManualError] = useState(false);
  const [requestNewAmount, { isLoading }] = useRequestNewAmountMutation();
  const [userSelectedIndex, setUserSelectedIndex] = useState<number>(0);
  const { isMobile } = useIsMobile();
  const completeTask = useTaskCompletionModal();
  const estimate = followUp?.getEstimate() as FollowUpEstimateModel;
  const selectedItem =
    userSelectedIndex !== undefined ? steppedPricingOptions?.[userSelectedIndex] : undefined;
  const selectedOptionAmount = selectedItem?.optionInvestmentAmount;
  const selectedCashToClose = selectedItem?.cashToClose;
  const origOptionAmount = estimate?.getPricing()?.getOptionInvestmentAmount();
  const selectedHeiPerc = selectedItem?.optionPercentage;

  const handleChange: DropdownChangeEvent = useCallback(
    (e, props) => {
      setManualError(false);
      const { selectedIndex } = props as DropdownChangeEventProps;
      setUserSelectedIndex(selectedIndex);
    },
    [setUserSelectedIndex, setManualError]
  );

  const handleSubmit = useCallback(async () => {
    if (isLoading) {
      return;
    }
    setManualError(false);
    const csrfToken = followUp?.csrfToken;
    if (!followUpId || selectedOptionAmount === undefined || !csrfToken) {
      return;
    }

    try {
      const response = await requestNewAmount({
        followUpId,
        amountRequested: selectedOptionAmount,
        csrfToken,
      }).unwrap();

      if (!response.success) {
        setManualError(true);
        return;
      }

      const task: CompletedTask = {
        subtle: true,
        noTasksFooterLinkType: NoTasksFooterLinkType.Dashboard,
        heading: i18n.requestComplete,
        summary: templatedString({
          values: { amount: currencyMask.getFormatted(selectedOptionAmount) },
          template: i18n.youveSuccessfully,
        }),
        detailList: {
          ordered: false,
          items: [i18n.yourAccountManager, i18n.onceIssued, i18n.reviewTheNew],
        },
      };
      completeTask(task);
    } catch (e) {
      setManualError(true);
    }
  }, [
    completeTask,
    followUp,
    followUpId,
    isLoading,
    requestNewAmount,
    selectedOptionAmount,
    setManualError,
  ]);

  if (!steppedPricingOptions || steppedPricingOptions.length === 0) {
    return null;
  }

  if (selectedOptionAmount === undefined || selectedHeiPerc === undefined) {
    return null;
  }

  const options = steppedPricingOptions.map((option, i) => ({
    text: currencyMask.getFormatted(option.optionInvestmentAmount),
    value: String(i),
  }));

  const tableLabels = [i18n.cashToYou, i18n.heiPercentage];

  const tableValues = [
    currencyMask.getFormatted(normalizeToString(selectedCashToClose)),
    percMask.getFormatted(selectedHeiPerc),
  ];
  const tableContent = [
    templatedString({
      template: i18n.thisIsTheEstimated,
      values: {
        previewAmount: currencyMask.getFormatted(selectedOptionAmount),
      },
    }),
    i18n.thisIsThePercentage,
  ];

  return (
    <styles.PreviewModalStyle {...modalProps} shadeBg>
      <header>
        <Header
          styleSize={Size.Medium}
          styleAlign={DirectionAndPlacement.Center}
          noMargin
          sideLines
        >
          {i18n.previewADifferent}
        </Header>
        <SplashText noMargin>
          <TemplatedText values={{ maxAmount: estimate?.getFormattedMaxPossibleOptionPayment() }}>
            {i18n.yourEligibleFor}
          </TemplatedText>
        </SplashText>
        <Dropdown
          incrementable
          incrementorLayout={isMobile ? IncrementorLayoutType.Platform : undefined}
          styleSize={Size.Splash}
          options={options}
          controlStyleType={Style.Secondary}
          controlIconType={ButtonLeftRightIconType.PlusMinus}
          onChange={handleChange}
          value={userSelectedIndex}
        />
      </header>
      <Table2Col
        isStaticUntilMobile
        labels={tableLabels}
        values={tableValues}
        foldingContent={tableContent}
        hasStripedRows={false}
        isBordered
      />
      <footer>
        {selectedOptionAmount && (
          <Button loading={isLoading} onClick={handleSubmit} block error={manualError}>
            <TemplatedText
              values={{ previewAmount: currencyMask.getFormatted(selectedOptionAmount) }}
            >
              {i18n.requestAnUpdated}
            </TemplatedText>
          </Button>
        )}
        {manualError && (
          <HelpTextAnimated show={manualError} styleType={Style.Error}>
            {Validation.i18n.unknownError}
          </HelpTextAnimated>
        )}
        <LinkButton invertTextDecoration onClick={modalProps.onModalClose}>
          {origOptionAmount !== undefined && (
            <TemplatedText values={{ offerAmount: currencyMask.getFormatted(origOptionAmount) }}>
              {i18n.keepMyOffer}
            </TemplatedText>
          )}
        </LinkButton>
      </footer>
    </styles.PreviewModalStyle>
  );
}
