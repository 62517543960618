import omit from 'lodash.omit';
import { directory, messagingI18n, templatedString } from '@pointdotcom/pds';
import { ErrorType } from 'containers/ErrorPage';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { usePointContact } from 'containers/prequal/hooks/usePointContact';
import { useHistory, useParams } from 'containers/routerHelpers';
import bugsnagClient from 'lib/bugsnagClient';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import { usePostHogEvents } from 'lib/posthogEvents';
import BaseEstimateModel from 'models/BaseEstimateModel';
import {
  useCreateApplicationMutation,
  useCreateDocketMutation,
  useSubmitApplicationMutation,
} from 'services/api/homeownerApi';
import {
  HeiApplication,
  HeiApplicationConsent,
  PostApplicationRequest,
  PostApplicationSubmitRequest,
  postApplicationRequestSchema,
  postApplicationSubmitRequestSchema,
} from 'services/apiTypes/homeownerTypes';
import { Products } from 'store/constants';
import { useDispatch, useSelector } from 'store/hooks';
import { getApplicationData, setData } from 'store/productApplication';

export function useHomeownerApplication() {
  const history = useHistory();
  const dispatch = useDispatch();
  const posthogEvents = usePostHogEvents();
  const { estimateKey = '' } = useParams();
  const [createApplication] = useCreateApplicationMutation();
  const [createDocket] = useCreateDocketMutation();
  const [submitApplication, submitApplicationState] = useSubmitApplicationMutation({
    fixedCacheKey: 'submitApplication', // Needed so that a stable state of this mutation can be used across multiple instances of this hook
  });
  const applicationState = useSelector(getApplicationData);
  const isApplicationHEIAmountTestEnabled =
    useFeatureFlag(FeatureFlag.ApplicationHEIAmount) === 'test';

  const { supportNumber } = usePointContact({ product: Products.HEI });

  // We're really sorry, but it looks like something went wrong.
  // No worries though — we’ve saved your progress. To continue,
  // try refreshing the page now, or a bit later. If that doesn't work,
  // you can call %phone% or email %email% for assistance.
  const applicationErrorMessage = templatedString({
    template: messagingI18n.errors.wereSorrySavedProgress,
    values: {
      phone: supportNumber,
      email: directory.PointEmail.Support,
    },
  });

  const updateApplicationData = (dataPath: string, value: unknown) => {
    dispatch(setData({ dataPath, value }));
  };

  const createHomeownerApplication = async (estimate: BaseEstimateModel) => {
    const applicant = estimate.getApplicant();
    if (!applicant || !applicant.firstName || !applicant.lastName) {
      throw new Error(`Incomplete applicant data in estimate: ${JSON.stringify(applicant)}`);
    }

    // CR015: (CE-2317) Include option amount if test is enabled, to be prefilled on the application form
    const financialsSection = isApplicationHEIAmountTestEnabled
      ? { financials: { amountRequested: estimate.getMaxOptionAmount() } }
      : {};

    let applicationCreationData: PostApplicationRequest;
    try {
      const { firstName, lastName, email, phone } = applicant;
      applicationCreationData = {
        applicant: {
          contactInfo: { firstName, lastName, email, phone },
        },
        property: {
          normalizedAddress: estimate?.property?.address,
        },
        ...financialsSection,
        estimateKey,
      };

      const payload = postApplicationRequestSchema.parse(applicationCreationData);

      // There are two separate calls here to start an application. Should only call createDocket() after createApplication() succeeds,
      // but createDocket() is slow so we specifically don't want to wait on it. Handle the response from there via reducers in the store.
      await createApplication(payload).unwrap();
      posthogEvents.captureApplicationCreated({ estimate });
      (async () => {
        await createDocket(estimateKey);
        posthogEvents.captureDocketCreated({ estimate });
      })();
    } catch (e) {
      const typedError = e as { status: number; data: unknown };
      if (typedError.status === 409) {
        const dashboardPage = generateUrlFromPage(pages.DASHBOARD);
        history.push({ pathname: dashboardPage, search: 'source=authRequired' });
      } else if (typedError.status === 412) {
        const errorPage = generateUrlFromPage(pages.ERROR_GENERAL, {
          errorType: ErrorType.ApplicationExpired,
        });
        history.replace(errorPage);
      } else {
        bugsnagClient.notify(
          `HOS application creation error: ${JSON.stringify(e)} ${e}`,
          (event) => {
            event.addMetadata('HOS Application', applicationCreationData);
          }
        );
        throw e;
      }
    }
  };

  const submitHomeownerApplication = async ({
    fullApplicationHtml,
  }: {
    fullApplicationHtml: string;
  }): Promise<void> => {
    const applicationData: PostApplicationSubmitRequest = {
      html: fullApplicationHtml,
      consents: applicationState.consents as HeiApplicationConsent,
      application: applicationState as HeiApplication,
    };

    try {
      const payload = postApplicationSubmitRequestSchema.parse(applicationData);
      await submitApplication(payload).unwrap();
    } catch (e) {
      const sanitizedApplication = omit(applicationData.application, ['applicant.ssn']);
      bugsnagClient.notify(`HOS application submission error: ${JSON.stringify(e)}`, (event) => {
        event.addMetadata('HOS Application', { application: sanitizedApplication });
      });
      throw e;
    }

    // At this point we have successfully submitted the application to UW
    posthogEvents.captureApplicationSubmitted({
      estimateKey,
      email: applicationState.applicant?.contactInfo?.email,
    });
  };

  return {
    applicationErrorMessage,
    createHomeownerApplication,
    updateApplicationData,
    submitHomeownerApplication,
    submitApplicationState,
  };
}
