import * as React from 'react';
import dayjs from 'dayjs';
import {
  Button,
  Container,
  Header,
  QueryComponent,
  Size,
  SplashText,
  Style,
  TemplatedText,
} from '@pointdotcom/pds';
import FundingStageListM2, {
  FundingStateListDetailLevel,
} from 'containers/DashboardProcessOverviewPage/FundingStageList';
import { Page, generateUrlFromPage } from 'containers/helpers';
import useLinkProps from 'containers/hooks/useLinkProps';
import DashboardModel from 'models/DashboardModel';
import { DocketStage } from 'services/apiTypes/commonTypes';
import i18n from './i18n';
import * as styles from './styles';

interface RecentActivitySummaryProps {
  dashboard: DashboardModel;
}

function RecentActivitySummary({ dashboard }: RecentActivitySummaryProps) {
  const recentActivity = dashboard.getRecentActivity();
  const date = recentActivity ? dayjs(recentActivity.date).format('MMMM Do, YYYY') : null;

  let description: React.ReactNode = recentActivity?.description;
  const applicationStage = dashboard.getStageByName(DocketStage.Application);
  if (!description && applicationStage) {
    if (dashboard.getStageIsActive(applicationStage)) {
      const estimateKey = dashboard.getApplicationTask()?.detail.estimateKey;

      description = (
        <>
          {i18n.applicationStarted}
          {estimateKey != null && (
            <>
              <br />
              <TemplatedText
                values={{
                  clickHere: (
                    <a href={generateUrlFromPage(Page.HEI_APPLICATION, { estimateKey })}>
                      {i18n.clickHere}
                    </a>
                  ),
                }}
              >
                {i18n.completeApplication}
              </TemplatedText>
            </>
          )}
        </>
      );
    } else if (dashboard.getStageIsComplete(applicationStage)) {
      description = i18n.applicationReceived;
    }
  }

  if (!description) {
    return null;
  }

  return (
    <styles.RecentActivitySummaryStyle>
      <QueryComponent queriedComponent={<SplashText>{description}</SplashText>}>
        <Header styleSize={Size.Medium} noMargin>
          {description}
        </Header>
      </QueryComponent>
      {date && (
        <aside>
          <TemplatedText values={{ date }}>{i18n.completedOn}</TemplatedText>
        </aside>
      )}
    </styles.RecentActivitySummaryStyle>
  );
}

interface DashboardFundingStatusSectionProps {
  dashboard: DashboardModel;
}

export default function DashboardFundingStatusSection({
  dashboard,
}: DashboardFundingStatusSectionProps) {
  const linkProps = useLinkProps(generateUrlFromPage(Page.DASHBOARD_PROCESS_OVERVIEW));

  return (
    <styles.FundingStatusSectionStyle>
      <Container>
        <styles.SectionHeaderStyle>{i18n.yourFundingStatus}</styles.SectionHeaderStyle>
        <styles.DividerWithImageStyle>
          <div>
            <img src="https://cdn.point.com/houseandhills.png" alt="house and hills" />
          </div>
        </styles.DividerWithImageStyle>
        <styles.FundingStatusRowStyle>
          <QueryComponent
            queriedComponent={
              <styles.FundingStatusMobileRowWithImage>
                <FundingStageListM2
                  dashboard={dashboard}
                  detailLevel={FundingStateListDetailLevel.Tiny}
                />
                <div>
                  <img src="https://cdn.point.com/houseandhills.png" alt="house and hills" />
                </div>
              </styles.FundingStatusMobileRowWithImage>
            }
          >
            <FundingStageListM2
              dashboard={dashboard}
              detailLevel={FundingStateListDetailLevel.Minimal}
            />
          </QueryComponent>
          <hr />
          <RecentActivitySummary dashboard={dashboard} />
        </styles.FundingStatusRowStyle>
        <Button styleType={Style.Tertiary} block {...linkProps}>
          {i18n.seeFullFundingProcess}
        </Button>
      </Container>
    </styles.FundingStatusSectionStyle>
  );
}
