import styled from 'styled-components';

export const PaperclipIconStyle = styled.svg.attrs({ className: 'PaperclipIconStyle' })`
  display: block;
  width: 26px;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    width: 20px;
  }

  path {
    fill: ${({ theme }) => theme.Color.Black};
    fill-opacity: 0.55;
  }
`;

export const CheckmarkIconStyle = styled.svg.attrs({ className: 'CheckmarkIconStyle' })`
  display: block;
  width: 21px;

  path {
    stroke: currentColor;
    stroke-linecap: round;
  }
`;
