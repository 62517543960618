import React from 'react';
import { TemplatedText, directory } from '@pointdotcom/pds';
import CheckboxStackField from '../../FormComponents/CheckboxStackField';
import { FieldProps } from '../../constants';
import i18n from './i18n';

const { pointLink } = directory;

const escrowDisclosureAuthorize = (
  <TemplatedText
    values={{
      thisDocument: () => {
        return (
          <a
            href="https://apply.point.com/documents/escrow-disclosure-authorization.pdf"
            target="_blank"
            rel="noreferrer"
          >
            {i18n.thisDocument}
          </a>
        );
      },
    }}
  >
    {i18n.iHerebyAuthorize}
  </TemplatedText>
);

const creditCheckTerm = (
  <TemplatedText
    values={{
      creditReportPolicy: () => {
        return (
          <a href={pointLink.terms.credit} target="_blank" rel="noreferrer">
            {i18n.creditReportPolicy}
          </a>
        );
      },
      concentToElectronic: () => {
        return (
          <a href={pointLink.terms.eces} target="_blank" rel="noreferrer" key="concentToElectronic">
            {i18n.concentToElectronic}
          </a>
        );
      },
      termsOfUse: () => {
        return (
          <a href={pointLink.terms.use} target="_blank" rel="noreferrer" key="termsOfUse">
            {i18n.termsOfUse}
          </a>
        );
      },
      privacyPolicy: () => {
        return (
          <a href={pointLink.terms.privacy} target="_blank" rel="noreferrer" key="privacyPolicy">
            {i18n.privacyPolicy}
          </a>
        );
      },
    }}
  >
    {i18n.iHaveReadAndAgree}
  </TemplatedText>
);

const listItems = {
  employmentVerificationAuthorize: i18n.iHerebyAuthorizePointToVerify,
  creditCheckAuthorize: i18n.iHerebyAuthorizePointToObtain,
  escrowDisclosureAuthorize,
  contractorSharingAuthorize: i18n.iAuthorizePoint,
  creditCheckTerm,
};

const summaryOfYourRights = (
  <TemplatedText
    values={{
      summaryOfYourRights: () => {
        return (
          <a href="https://point.com/fcra-summary" target="_blank" rel="noreferrer">
            {i18n.summaryOfYourRights}
          </a>
        );
      },
    }}
  >
    {i18n.inConnectionWith}
  </TemplatedText>
);

const ConsentList = (fieldProps: Partial<FieldProps>) => {
  const description = (
    <>
      <p>{i18n.pointWillUse}</p>
      <p>{summaryOfYourRights}</p>
      <p> {i18n.youAcknowledge}</p>
    </>
  );
  return <CheckboxStackField keyValues={listItems} description={description} {...fieldProps} />;
};

export default ConsentList;
