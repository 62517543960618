import { TransitionStatus } from 'react-transition-group';
import styled, { DefaultTheme } from 'styled-components';
import { HeaderStyles, HelpTextStyles } from '@pointdotcom/pds';
import {
  BodyCopyStyle,
  HeroContainerStyle,
  MainPageStyle,
  SplashCopySerifStyle,
  UnderstatedSectionStyle,
} from 'styles/';

const innerPadding = '6%';
const innerPaddingMobile = '10%';

const DecisionPageStyle = styled(MainPageStyle).attrs({ className: 'DecisionPageStyle' })`
  background: transparent;
`;

const decisionFlagProps = (theme: DefaultTheme, decline: boolean) => {
  const props = {
    accept: {
      icon: '^',
      color: `${theme.Color.DarkYorange1}`,
    },
    decline: {
      icon: 'x',
      color: `${theme.Color.PurpleBlue2}`,
    },
  };

  const key = decline ? 'decline' : 'accept';
  return props[key];
};

const acceptAnimation = 'all 500ms ease-in-out 320ms';

const AnimatedSplashCopySerifStyle = styled(SplashCopySerifStyle)<{
  animationStatus: TransitionStatus;
}>`
  transition: ${acceptAnimation};
  ${({ animationStatus }) => {
    let opacity = '1';
    let translateY = '0';

    if (animationStatus === 'exiting' || animationStatus === 'exited') {
      opacity = '0';
      translateY = '-1rem';
    }

    return `
      opacity: ${opacity};
      transform: translateY(${translateY});
    `;
  }}
`;

const DecisionPageInnerContentStyle = styled.div.attrs({
  className: 'DecisionPageInnerContentStyle',
})`
  display: flex;
  align-items: center;
  ${HeroContainerStyle} {
    align-items: center;
  }
  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    flex-flow: column;
  }
`;

const HeroSectionLeftStyle = styled.div.attrs({ className: 'HeroSectionLeftStyle' })<{
  closed: boolean;
}>`
  position: relative;
  padding-right: ${innerPadding};
  flex: 80%;
  ${HeaderStyles.HeaderStyle} {
    position: relative;
  }
  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    width: 100%;
    padding-right: 0;
    padding-bottom: ${({ closed }) => (closed ? '0' : innerPaddingMobile)};
    ${HeaderStyles.HeaderStyle} {
      text-align: center;
      border-bottom: 1px solid
        ${({ closed, theme }) => (closed ? 'transparent' : theme.line.color.default)};
      padding-bottom: ${innerPaddingMobile};
      margin: 0;
    }
    ${SplashCopySerifStyle} {
      text-align: center;
      padding: ${innerPaddingMobile} ${innerPaddingMobile} 0;
      margin-bottom: 10px;
    }
  }
`;

const HeroSectionRightStyle = styled.div.attrs({ className: 'HeroSectionRightStyle' })`
  padding-left: ${innerPadding};
  border-style: solid;
  border-color: ${({ theme }) => theme.line.color.default};
  border-width: 0;
  border-left-width: 1px;
  width: 60%;
  padding-top: 3%;
  padding-bottom: 3%;
  > p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  ${HelpTextStyles.HelpTextStyle} {
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
  }
  ${UnderstatedSectionStyle} {
    text-align: center;
    padding-top: 20px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    width: 100%;
    border-left-width: 0;
    border-top-width: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const DisclaimerTextStyle = styled(HelpTextStyles.HelpTextStyle).attrs({
  className: 'DisclaimerTextStyle',
})`
  padding-top: 3rem;
`;

const DecisionFlagStyle = styled.div.attrs({ className: 'DecisionFlagStyle' })<{
  animationStatus: TransitionStatus;
  decline: boolean;
}>`
  top: 100%;
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.sansSerif.name};
  transition: ${acceptAnimation};
  background-color: ${({ theme, decline }) => decisionFlagProps(theme, decline).color};
  color: white;
  padding: 0.5em 7em;
  text-align: center;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
  position: absolute;
  display: inline-block;
  margin-top: 20px;
  left: 50%;

  ${({ animationStatus }) => {
    let opacity = '0';
    let translateY = '-1rem';
    let scale = '0.5';

    if (animationStatus === 'entered') {
      opacity = '1';
      scale = '1';
      translateY = '0';
    }

    return `
      opacity: ${opacity};
      transform: translateY(${translateY}) translateX(-50%) scale(${scale});
    `;
  }}

  &::before {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    top: -10px;
    background-color: ${({ theme, decline }) => decisionFlagProps(theme, decline).color};
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  svg {
    display: inline-block;
    margin-right: 1.2rem;
    position: relative;
    top: 2px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    display: block;
    padding-left: 0;
    padding-right: 0;
    width: calc(100% + 80px);
    margin-left: -${({ theme }) => theme.container.gutter.default};
    margin-right: -${({ theme }) => theme.container.gutter.default};
    margin-top: 0;
    left: 0;
    left: 0;
    transform: translateX(0);
  }
`;

const ChoiceResultComponentStyle = styled.div.attrs({ className: 'ChoiceResultComponentStyle' })`
  ${BodyCopyStyle}:last-child {
    border-top: 1px solid ${({ theme }) => theme.line.color.default};
    padding: ${innerPadding} 0;
  }
  ${SplashCopySerifStyle} {
    max-width: 220px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    padding: 0;
    ${SplashCopySerifStyle} {
      text-align: center;
      margin-right: auto;
      margin-left: auto;
    }
    ${BodyCopyStyle}:last-child {
      text-align: center;
      box-sizing: border-box;
      padding: ${innerPaddingMobile} ${innerPaddingMobile};
    }
  }
`;

export {
  AnimatedSplashCopySerifStyle,
  ChoiceResultComponentStyle,
  DecisionPageStyle,
  DecisionFlagStyle,
  DecisionPageInnerContentStyle,
  DisclaimerTextStyle,
  HeroSectionLeftStyle,
  HeroSectionRightStyle,
};
