import React from 'react';
import { DirectionAndPlacement } from '@pointdotcom/pds';
import ShieldGraphic from '../ShieldGraphic';
import { CapHouseGraphicMode, CapHouseGraphicProps } from './constants';
import * as styles from './styles';

export default function CapHouseGraphic({
  mode = CapHouseGraphicMode.AtStartingValue,
}: CapHouseGraphicProps) {
  return (
    <>
      <styles.CapHouseGraphicStyle mode={mode}>
        <styles.MiniCoinsStyle />
        <styles.CapHouseGoldStyle />
        <ShieldGraphic direction={DirectionAndPlacement.Right} />
      </styles.CapHouseGraphicStyle>
      <styles.CapHouseHillStyle />
    </>
  );
}
