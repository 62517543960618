import React from 'react';

interface HorizontalBracketProps {
  fill?: string;
  fillOpacity?: string;
}

export const HorizontalBracket = ({
  fill = '#717475',
  fillOpacity = '1.0',
}: HorizontalBracketProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="644"
      height="88"
      viewBox="0 0 644 88"
      fill="none"
      aria-hidden
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M322.073 -0.370117L322.538 0.63715C333.041 23.3929 354.066 34.2988 380.886 39.3023C407.171 44.2061 438.914 43.4193 471.551 42.6104L473.601 42.5596C506.926 41.7361 541.071 41.0139 571.043 46.4636C601.026 51.9151 626.906 63.5555 643.631 87.5123L642.789 88.0768C626.275 64.423 600.695 52.8721 570.857 47.4468C541.009 42.0197 506.975 42.7352 473.626 43.5593L471.509 43.6118C438.927 44.4195 407.09 45.2088 380.695 40.2846C354.098 35.3228 332.918 24.5385 322.072 2.03463C311.354 24.2479 290.292 34.7949 263.792 39.588C237.866 44.2772 206.625 43.4797 174.55 42.6609C173.378 42.6309 172.204 42.601 171.03 42.5712C137.696 41.7277 103.626 41.075 73.6763 46.6614C43.7371 52.2459 17.9836 64.0525 1.21156 88.0768L0.369141 87.5123C17.3496 63.1893 43.3984 51.2911 73.4854 45.6791C103.562 40.0691 137.742 40.7285 171.056 41.5716C172.212 41.6008 173.367 41.6303 174.52 41.6597C206.644 42.4795 237.789 43.2743 263.607 38.6046C290.337 33.7699 311.237 23.0998 321.608 0.637116L322.073 -0.370117Z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};
