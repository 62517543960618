import styled from 'styled-components';
import {
  ButtonStyles,
  ContainerStyles,
  GraphicsStyles,
  HeaderStyles,
  ResponsiveTextStyles,
  mixins,
} from '@pointdotcom/pds';
import * as appStyles from 'styles/';

const heroContainerBreakPoint = '980px';

export const HeroWithOptionAmtSectionStyle = styled(appStyles.HeroContainerStyle).attrs({
  className: 'HeroWithOptionAmtSectionStyle',
})`
  padding-bottom: 0 !important;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.line.color.default};
  ${HeaderStyles.HeaderStyle} {
    &.splash2 {
      margin-bottom: 0.3em;
      font-size: ${(props) => mixins.getRemFromPx(70, props.theme.globals.baseFontSize.default)};
    }
  }
  ${appStyles.PaddedSectionCSS}
  ${ContainerStyles.ContainerStyle} {
    &:last-child {
      position: relative;
      margin-top: 4%;
      text-align: center;
      height: 224px;
      ${ResponsiveTextStyles.ResponsiveTextStyle} {
        display: block;
        position: absolute;
        bottom: 0;
        &:first-child {
          left: 0;
        }
        &:last-child {
          right: -50px;
        }
        @media (max-width: ${heroContainerBreakPoint}) {
          width: 38%;
          ${GraphicsStyles.GraphicStyle} {
            width: 100%;
          }
        }
        @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
          width: 164px;
          &:first-child {
            left: 20px;
          }
          &:last-child {
            right: 0px;
          }
        }
      }
      ${ButtonStyles.ButtonBaseStyle} {
        min-width: 290px;
      }
      @media (max-width: ${heroContainerBreakPoint}) {
        height: 210px;
      }
      @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
        flex-flow: column;
        min-width: auto;
        ${ButtonStyles.ButtonBaseStyle} {
          min-width: auto;
        }
      }
    }
  }
`;
