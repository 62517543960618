import React from 'react';
import { DirectionAndPlacement, Header, LinkButton, Size, directory } from '@pointdotcom/pds';
import { helpCenterUrls } from 'containers/PostFundingCalculator/constants';
import { ContractExitDetails } from 'services/apiTypes/contractTypes';
import { getViewsByExitData } from '.';
import { Equation, getPayoffValuesFromModeAndExitData } from './PayoffExpressionModalContent';
import { ContentMode, ContentModeExcludingSummary, contentMap } from './constants';
import i18n from './i18n';
import * as styles from './styles';

interface SummaryProps {
  exitData?: ContractExitDetails;
  modalClose?: () => void;
}

export default function SummaryModalContent({ exitData, modalClose }: SummaryProps) {
  if (!exitData) return null;
  const views = getViewsByExitData(exitData).filter((view) => view !== ContentMode.Summary);
  const payoffValues = getPayoffValuesFromModeAndExitData({
    exitData,
    mode: ContentMode.PayoffAmount,
  });
  return (
    <styles.SummaryModalContentStyle>
      <Header styleSize={Size.Large} styleAlign={DirectionAndPlacement.Center}>
        Summary
      </Header>
      <styles.SummaryItemContainerStyle>
        {views.map((mode) => {
          const content = contentMap[mode as ContentModeExcludingSummary];
          const isPayoffAmountMode = mode === ContentMode.PayoffAmount;
          const summaryText = `${content.headline} ${content.description}`;

          if (isPayoffAmountMode) {
            return (
              <styles.SummaryItemStyle
                key={`itemtype-${ContentMode.Summary}`}
                aria-label={summaryText}
              >
                <Header styleSize={Size.Medium} styleAlign={DirectionAndPlacement.Center}>
                  {content.headline}
                </Header>
                <p>{content.description}</p>
                <Header styleSize={Size.Splash} styleAlign={DirectionAndPlacement.Center}>
                  {payoffValues?.primaryAmount}
                </Header>
                <Equation exitData={exitData} mode={mode} />
              </styles.SummaryItemStyle>
            );
          }

          return (
            <styles.SummaryItemStyle key={`itemtype-${mode}`} aria-label={summaryText}>
              <p>{summaryText}</p>
              <Equation exitData={exitData} mode={mode as ContentModeExcludingSummary} />
            </styles.SummaryItemStyle>
          );
        })}
      </styles.SummaryItemContainerStyle>
      <styles.SummaryItemFooterStyle>
        <h5>{i18n.stillHaveQuestions}</h5>
        <styles.ContactOptionsStyle>
          <a href={helpCenterUrls.repayCategory} target="_blank" rel="noreferrer">
            {i18n.homeownerHelpCenter}
          </a>
          <a href={`mailto: ${directory.PointEmail.Servicing}`}>{directory.PointEmail.Servicing}</a>
          <a href={`tel: ${directory.PointNumber.Servicing}`} key={'phone'}>
            {directory.PointNumber.Servicing}
          </a>
        </styles.ContactOptionsStyle>
        <div>
          <LinkButton onClick={modalClose}>Close</LinkButton>
        </div>
      </styles.SummaryItemFooterStyle>
    </styles.SummaryModalContentStyle>
  );
}
