import * as React from 'react';
import hpcShieldPng from './images/hpc-shield.png';
import hpcShieldWebp from './images/hpc-shield.webp';
import { CapShieldStyle } from './styles';

export default function CapShield(): JSX.Element {
  return (
    <CapShieldStyle>
      <picture>
        <source type="image/webp" srcSet={hpcShieldWebp} />
        <img src={hpcShieldPng} alt="Homeowner Protection Cap" />
      </picture>
    </CapShieldStyle>
  );
}
