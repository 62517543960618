import styled from 'styled-components';
import { HeaderStyles } from '@pointdotcom/pds';

export const AboutPageStyle = styled.div.attrs({ className: 'AboutPageStyle' })`
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    ${HeaderStyles.HeaderStyle} {
      max-width: 500px;
    }
  }
`;
