import React, { useState } from 'react';
import 'video.js/dist/video-js.css';
import { ModalProps, useIsMobile } from '@pointdotcom/pds';
import { useGetVideoQuery } from 'services/api/homeownerApi';
import i18n from './i18n';
import VideoPlayer, { VideoPlayerProps } from './index';
import { ModalVideoStyle } from './styles';

const VIDEO_LABEL = process.env.REACT_APP_EDUCATION_VIDEO_LABEL || 'heiEducation';
const VideoLoading = () => <div>{i18n.loading}</div>;
const VideoError = () => <div>{i18n.error}</div>;

interface VideoPlayerModalProps extends ModalProps {
  onClose?: () => void;
  upperContent?: React.ReactNode;
  lowerContent?: React.ReactNode;
  videoPlayerProps?: Partial<VideoPlayerProps>;
}

const VideoPlayerModal = ({
  isOpen,
  upperContent,
  lowerContent,
  videoPlayerProps,
  onClose,
  bgClickToClose = false,
  escToClose = false,
  showX = false,
}: VideoPlayerModalProps) => {
  const [loaded, setLoaded] = useState(0);
  const {
    data: video,
    error: videoApiError,
    isLoading: videoLoading,
  } = useGetVideoQuery(VIDEO_LABEL);
  const { isLandscape } = useIsMobile();

  let Video = VideoPlayer;

  if (!video || videoLoading) {
    Video = VideoLoading;
  }

  if (videoApiError) {
    Video = VideoError;
  }

  if (!video) {
    return null;
  }

  return (
    <ModalVideoStyle
      maxWidth="800px"
      width="calc(100% - 40px)"
      isOpen={isOpen}
      onModalClose={onClose}
      bgClickToClose={bgClickToClose}
      escToClose={escToClose}
      showX={showX}
      shadeBg
      className="videoModal"
      focusableSelector="button.vjs-play-control,button.vjs-chapters-button,button.vjs-fullscreen-control,button.ButtonBaseStyle"
      forceInit={Boolean(loaded)}
      isLandscape={isLandscape}
    >
      {upperContent}
      <Video video={video} onReady={() => setLoaded(1)} {...videoPlayerProps} />
      {lowerContent}
    </ModalVideoStyle>
  );
};

export default VideoPlayerModal;
