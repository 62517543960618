import React from 'react';
import { Container, UnstyledButton, directory } from '@pointdotcom/pds';
import i18n from './i18n';
import * as styles from './styles';

const { PointNumber, pointLink } = directory;

const PhoneAndHELOCLinkSection = ({ hideHelocLink = false }) => (
  <styles.PhoneAndHELOCLinkSectionStyle hideHelocLink={hideHelocLink}>
    <Container>
      <section>
        <UnstyledButton href={`tel:${PointNumber.Support}`} className="supportButton">
          <span>{PointNumber.Support}</span>
          <span>Mon-Fri 6am-6pm PST Sat-Sun 7am-4pm PST</span>
        </UnstyledButton>
        {!hideHelocLink && (
          <UnstyledButton href={pointLink.heloc} className="helocButton">
            <span>{i18n.lookingFor}</span>
          </UnstyledButton>
        )}
      </section>
    </Container>
  </styles.PhoneAndHELOCLinkSectionStyle>
);

export default PhoneAndHELOCLinkSection;
