import styled, { css } from 'styled-components';
import { Accordion, AccordionProps, AccordionStyles, Style, mixins } from '@pointdotcom/pds';
import { Table2ColProps } from './constants';

export const Table2ColLabelStyle = styled.span.attrs({ className: 'Table2ColLabelStyle' })`
  display: flex;
  align-items: center;
`;
export const Table2ColValueStyle = styled.span.attrs({ className: 'Table2ColValueStyle' })`
  display: flex;
  align-items: center;
`;

export const Table2ColTitleStyle = styled.span.attrs({ className: 'Table2ColTitleStyle' })`
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  gap: var(--table2colTitleGap);
  padding: var(--accordionTopBottomPadding) var(--accordionLeftRightPadding);
  ${Table2ColLabelStyle} {
    text-align: left;
  }
  ${Table2ColValueStyle} {
    text-align: right;
    font-weight: bold;
  }
  // The accordion will handle the padding
  ${AccordionStyles.AccordionItemStyle} & {
    padding: 0;
  }
`;

const stripedCSS = css`
  position: relative;
  @media only screen {
    &:nth-child(odd) {
      &::before {
        pointer-events: none;
        content: '';
        z-index: 0;
        background-color: ${(props) => mixins.rgba(props.theme.Color.PointBlack, 0.13)};
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        mix-blend-mode: difference;
      }
    }
  }
`;

export const Table2ColItemStyle = styled.div.attrs({ className: 'Table2ColItemStyle' })<
  Pick<Table2ColProps, 'hasStripedRows'>
>`
  ${({ hasStripedRows }) => (hasStripedRows ? stripedCSS : undefined)};
`;

export const Table2ColContentWrapperStyle = styled.span.attrs({
  className: 'Table2ColContentWrapperStyle',
})`
  display: block;
  padding-block: 2rem;
`;

const borderedCSS = css<Pick<Table2ColProps, 'styleType'>>`
  --accordionItemBorderColor: ${({ theme, styleType }) =>
    styleType === Style.White ? theme.line.color.invertedSubtle : theme.line.color.default};
  border-block: 1px solid
    ${({ theme, styleType }) =>
      styleType === Style.White ? theme.line.color.invertedSubtle : theme.line.color.default};
`;

const innerBorderedCSS = css<Pick<Table2ColProps, 'styleType'>>`
  ${Table2ColContentWrapperStyle} {
    border-top: 1px solid
      ${({ theme, styleType }) =>
        styleType === Style.White ? theme.line.color.invertedSubtle : theme.line.color.default};
  }
  ${Table2ColTitleStyle} {
    padding-right: var(--table2colTitleGap);
    border-right: 1px solid
      ${({ theme, styleType }) =>
        styleType === Style.White ? theme.line.color.invertedSubtle : theme.line.color.default};
  }
`;

const whiteStyle = css`
  ${AccordionStyles.AccordionItemHeaderStyle},
  [role='region'] {
    color: white;
  }
`;

const defaultStyle = css`
  ${AccordionStyles.AccordionItemHeaderStyle},
  [role='region'] {
    color: inherit;
  }
`;

export const Table2ColStyle = styled(Accordion).attrs({ className: 'Table2ColStyle' })<
  Partial<AccordionProps> & Pick<Table2ColProps, 'isBordered' | 'styleType' | 'isInnerBordered'>
>`
  --table2colTitleGap: 1rem;
  --accordionItemBorderColor: transparent;
  ${({ isBordered }) => (isBordered ? borderedCSS : undefined)}
  ${({ styleType }) => (styleType === Style.White ? whiteStyle : defaultStyle)}
  ${({ isInnerBordered }) => (isInnerBordered ? innerBorderedCSS : undefined)}
`;
