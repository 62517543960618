import nullthrows from 'nullthrows';
import { z } from 'zod';
import { mortgageBalanceSchema, ownershipSchemaWithOwnersSchema } from './generated/HomeownerService.Applications.types';

export * from './generated/HomeownerService.types';
export * from './generated/HomeownerService.Applications.types';
export * from './generated/HomeownerService.BrowserLogs.types';
export * from './generated/HomeownerService.Dashboard.types';
export * from './generated/HomeownerService.Documents.types';

export type { Task as HosDashboardTask } from './generated/HomeownerService.Tasks.types';

export { ApplicationStatus } from './generated/Common.types';
export { AutomatedDocketQualificationStatus, DocketStatus } from './generated/Underwrite.Core.Dockets.types';

export const ownershipStatusWithOthersEnumSchema = ownershipSchemaWithOwnersSchema.shape.ownershipStatus;
export type OwnershipStatusWithOthersEnum = z.infer<typeof ownershipStatusWithOthersEnumSchema>;

export const mortgageMaxAmount = nullthrows(mortgageBalanceSchema.maxValue);
