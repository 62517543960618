import styled from 'styled-components';
import { mixins } from '@pointdotcom/pds';
import {
  FooterBookOrContinueStyle,
  PrimaryCTAContainerStyle,
  SecondaryCTAContainerStyle,
} from 'components/FooterBookOrContinue/styles';
import { MainPageStyle } from 'styles';

export const HighlightedNumberStyle = styled.span.attrs({ className: 'HighlightedNumberStyle' })`
  display: inline-block;
  background: ${({ theme }) => mixins.rgba(theme.Color.PurpleBlue2, 0.2)};
  padding: 0.05em 0.125em;
  margin: -0.05em -0.125em;
`;

export const HEIOfferEstimatorPageStyle = styled(MainPageStyle).attrs({
  className: 'HEIOfferEstimatorPageStyle',
})`
  ${FooterBookOrContinueStyle} {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${FooterBookOrContinueStyle} {
      display: block;
    }
    ${PrimaryCTAContainerStyle} {
      display: none;
    }
    ${SecondaryCTAContainerStyle} {
      border: none;
      padding-bottom: 0;
      &:after {
        display: none;
      }
    }
  }
`;
