import React from 'react';
import { Logo } from '@pointdotcom/pds';
import { getPathFromPage, pages } from 'containers/helpers';
import * as styles from './styles';

const MainHeader = ({ logoHref }) => {
  const nameSpace = 'MainHeader';
  return (
    <styles.MainHeaderStyle className={nameSpace}>
      <div className="logoContainer">
        <Logo href={logoHref || getPathFromPage(pages.PREQUAL_DEFAULT)} />
      </div>
    </styles.MainHeaderStyle>
  );
};

export default MainHeader;
