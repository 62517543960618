import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';
import {
  ButtonStyles,
  ContainerStyles,
  DropdownStyles,
  Header,
  HeaderStyles,
  LoaderStyles,
  SplashTextStyles,
  mixins,
} from '@pointdotcom/pds';
import { pxToRem } from 'styleHelpers';
import { PaddedSectionStyle, basePadding } from 'styles';
import { PaginatorItemStyle } from './SlideContent/styles';
import texturebg from './images/texturebg.jpg';

export const CONTROLS_ANIM_SPEED_MS = 250;
export const MODE_ANIM_SPEED_MS = 250;
export const LOADING_ANIM_SPEED_MS = 230;
export const LOADING_DURATION_MS = 500;

export const PaginatorButtonStyle = styled.button.attrs({ className: 'PaginatorButtonStyle' })<{
  slideColor: string;
}>`
  ${mixins.appearance('none')}
  width: var(--leftRightPadding);
  height: 100%;
  position: absolute;
  background: transparent;
  top: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    cursor: default;
    &:first-child {
      span {
        transform: translateX(-50%);
        opacity: 0;
      }
    }
    &:nth-child(2) {
      span {
        transform: translateX(50%);
        opacity: 0;
      }
    }
  }
  span {
    transition: all ${CONTROLS_ANIM_SPEED_MS}ms ease-in-out;
    display: block;
    background: white;
    width: 100%;
    height: var(--leftRightPadding);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    box-shadow: 10px 10px red;
    path {
      fill: ${({ slideColor }) => slideColor};
      stroke: ${({ slideColor }) => slideColor};
    }
  }
  ${mixins.hover(css`
    span {
      transform: scale(1.2);
    }
  `)}

  &:first-child {
    span {
      background: linear-gradient(
        to right,
        ${({ theme }) => theme.Color.GrayLight1} 50%,
        ${({ theme }) => theme.Color.White} 80%
      );
      border-top-right-radius: calc(var(--leftRightPadding) / 2);
      border-bottom-right-radius: calc(var(--leftRightPadding) / 2);
      box-shadow: 12px 0 10px -5px rgba(0, 0, 0, 0.1);
      left: -50%;
    }
  }
  &:nth-child(2) {
    span {
      background: linear-gradient(
        to right,
        ${({ theme }) => theme.Color.White} 50%,
        ${({ theme }) => theme.Color.GrayLight1} 80%
      );
      border-top-left-radius: calc(var(--leftRightPadding) / 2);
      border-bottom-left-radius: calc(var(--leftRightPadding) / 2);
      box-shadow: -12px 0 10px -5px rgba(0, 0, 0, 0.1);
      right: -50%;
    }
  }
`;

export const SliderHeaderStyle = styled(Header).attrs({ className: 'SliderHeaderStyle' })`
  transition: all ${MODE_ANIM_SPEED_MS}ms ease-in-out;
  > * {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-size: 11px; // using px here instead of rem to match the requirements of the number flip package
    gap: 0.4em;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fonts.serif.name};
    font-weight: normal;
    letter-spacing: 0.2em;
  }
`;

export const CarouselNavStyle = styled.nav.attrs({ className: 'CarouselNavStyle' })`
  transition: all ${MODE_ANIM_SPEED_MS}ms ease-in-out;
  width: 100%;
  bottom: 0;
  left: 0;
  border-top: 1px solid ${({ theme }) => theme.line.color.invertedSubtle};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  button {
    ${mixins.appearance('none')}
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    padding: calc(var(--topBottomPadding) / 2) 1rem;
    flex: 1;
    transition: all ${CONTROLS_ANIM_SPEED_MS}ms ease-in-out;
    position: relative;
    &:before {
      transition: inherit;
      opacity: 0;
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      right: -1px;
      top: 0;
      background: linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.1) 100%);
    }
    &:disabled {
      opacity: 0.2;
      &:before {
        display: none;
      }
    }
    &:first-child {
      text-align: right;
      background-size: 200%;
      background-position: 0% 0;
    }
    &:last-child {
      text-align: left;
      &:before {
        right: unset;
        left: -1px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, transparent 100%);
      }
    }
    ${mixins.hover(css`
      &:before {
        opacity: 1;
      }
    `)}
  }
`;

export const IntroNavStyle = styled.nav.attrs({ className: 'IntroNavStyle' })`
  transition: all ${MODE_ANIM_SPEED_MS}ms ease-in-out;
  ${ButtonStyles.ButtonBaseStyle} {
    box-shadow: 0px 0px 9px -3px ${({ theme }) => theme.line.color.default};
    font-family: ${({ theme }) => theme.fonts.serif.name};
    font-weight: normal;
    font-size: ${pxToRem(23)};
    background: transparent;
    border: 1px solid ${({ theme }) => theme.line.color.default};
    ${mixins.hover(css`
      border-color: ${({ theme }) => theme.Color.PointBlack};
    `)}
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${ButtonStyles.ButtonBaseStyle} {
      box-shadow: 0px 0px69px -3px ${({ theme }) => theme.line.color.default};
      font-size: ${pxToRem(18)};
    }
  }
`;

export const CarouselContentContainerStyle = styled.div.attrs({
  className: 'CarouselContentContainerStyle',
})`
  position: relative;

  transition: all ${MODE_ANIM_SPEED_MS}ms ease-in-out;
  p {
    &:last-child {
      margin: 0;
    }
  }
  em {
    font-style: normal;
  }
`;

export const CarouselTitleStyle = styled.div.attrs({ className: 'CarouselTitleStyle' })`
  transition: all ${MODE_ANIM_SPEED_MS}ms ease-in-out;
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  font-size: inherit;
  ${mixins.hover(css`
    color: inherit;
  `)}
  ${SplashTextStyles.SplashCopySerifStyle} {
    font-size: inherit;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1em;
  }
  ${DropdownStyles.DropDownMainControlContainerStyle} {
    ${mixins.hover(css`
      select {
        border-color: ${({ theme }) => theme.Color.GrayLightestAccessible};
      }
    `)}
  }
  ${DropdownStyles.DropdownSelectWrapStyle} {
    font-size: inherit;
    color: inherit;
    select,
    label {
      padding-block: 0.5em;
    }
    label {
      font-size: inherit;
      font-family: inherit;
      color: inherit;
    }
    path {
      fill: currentColor;
      stroke: currentColor;
    }
  }
`;

export const CarouselContainerStyle = styled.div.attrs({ className: 'CarouselContainerStyle' })<{
  slideColor: string;
}>`
  --leftRightPadding: 4rem;
  --topBottomPadding: 4.5rem;
  transition: all ${CONTROLS_ANIM_SPEED_MS}ms ease-out;
  border: 1px solid transparent;
  border-bottom: none;
  color: white;
  background-color: ${({ slideColor }) => slideColor};
  background-size: cover;
  background-blend-mode: multiply;
  width: 100%;
  max-width: 700px;
  /* height: 410px; */
  padding: var(--topBottomPadding) var(--leftRightPadding) 0;
  position: relative;
  box-shadow:
    -26px 4px 20px -26px rgba(113, 116, 117, 0.2),
    26px 4px 20px -26px rgba(113, 116, 117, 0.2);
  &:before {
    pointer-events: none;
    transition: all ${MODE_ANIM_SPEED_MS}ms ease-out;
    position: absolute;
    background-image: url('${texturebg}');
    background-size: cover;
    content: '';
    mix-blend-mode: multiply;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    opacity: 0.1;
  }
  ${PaginatorButtonStyle} {
    &:first-child {
      left: 0;
    }
    &:nth-child(2) {
      right: 0;
    }
  }
  ${CarouselTitleStyle} {
    color: inherit;
    display: none;
    margin-bottom: 2em;
    ${SplashTextStyles.SplashCopySerifStyle} {
      flex-flow: column;
      text-align: center;
      margin: 0 auto;
      max-width: 13em;
    }
    ${DropdownStyles.DropdownStyle} {
      width: 100%;
    }
    ${DropdownStyles.DropDownMainControlContainerStyle} {
      select {
        border-color: ${({ theme }) => theme.line.color.invertedSubtle};
      }
      label {
        justify-content: center;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    --leftRightPadding: ${(props) => props.theme.container.gutter.default};
    --topBottomPadding: calc(${(props) => props.theme.container.gutter.default} + 0.5rem);

    ${CarouselTitleStyle} {
      display: block;
    }
  }
`;

export const IntroContentStyle = styled.div.attrs({ className: 'IntroContentStyle' })`
  --contentMaxWidth: 75%;
  --headerSize: ${pxToRem(32)};
  position: absolute;
  transition: all ${MODE_ANIM_SPEED_MS}ms ease-in-out;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-inline: var(--leftRightPadding);
  color: ${({ theme }) => theme.Color.PointBlack};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  overflow: hidden;
  header {
    transition: inherit;
    display: flex;
    flex-flow: column;
    gap: 1rem;
  }
  ${HeaderStyles.HeaderStyle} {
    > * {
      font-size: var(--headerSize);
    }
  }
  ${SplashTextStyles.SplashCopySerifStyle} {
    font-size: ${pxToRem(18)};
    max-width: var(--contentMaxWidth);
  }
  ${DropdownStyles.DropdownStyle} {
    width: var(--contentMaxWidth);
    margin: 0 auto;
  }
  ${DropdownStyles.DropdownSelectWrapStyle} {
    select,
    label {
      padding-block: 0.4em;
    }
    label {
      background-color: white;
      font-size: var(--headerSize);
    }
  }
  ${IntroNavStyle} {
    width: var(--contentMaxWidth);
  }
  ${CarouselNavStyle} {
    border-color: ${({ theme }) => theme.line.color.default};
    button {
      &:before {
        background: radial-gradient(circle at center, rgba(68, 68, 68, 0.1) 0%, transparent 100%);
      }
      text-align: center;
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    --headerSize: ${pxToRem(26)};
    ${DropdownStyles.DropdownStyle}, ${IntroNavStyle} {
      width: 100%;
    }
    ${SplashTextStyles.SplashCopySerifStyle} {
      max-width: 100%;
    }
    header {
      justify-content: center;
      flex-grow: 0;
    }
  }
`;

export const CarouselSlideGraphicsContainerStyle = styled.div.attrs({
  className: 'CarouselSlideGraphicsContainerStyle',
})`
  transition: all ${MODE_ANIM_SPEED_MS}ms ease-in-out;
  opacity: 0;
`;

export const CarouselContentWrapperStyle = styled.div.attrs({
  className: 'CarouselContentWrapperStyle',
})<{ isCarouselMode?: boolean; animationStatus: TransitionStatus }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-flow: column nowrap;
  position: relative;
  gap: 2rem;
  > ${CarouselTitleStyle} {
    position: absolute;
    bottom: 100%;
    margin-bottom: 1rem;
  }

  ${({ isCarouselMode }) => {
    if (isCarouselMode) {
      return css`
        ${IntroContentStyle} {
          header {
            opacity: 0;
            transform: translateY(-40%) scale(0.7);
          }
          ${CarouselNavStyle} {
            transform: translateY(100%);
          }
        }
        ${IntroNavStyle} {
          opacity: 0;
          transform: translateY(100%);
        }
        ${CarouselSlideGraphicsContainerStyle} {
          opacity: 1;
        }
      `;
    }
    return css`
      ${CarouselContentContainerStyle} {
        transform: scale(0.9);
        opacity: 0;
      }
      ${CarouselTitleStyle} {
        opacity: 0;
        transform: translateY(200%);
      }
      ${SliderHeaderStyle} {
        opacity: 0;
        transform: scale(0%);
      }
      ${CarouselContainerStyle} {
        background-color: ${({ theme }) => theme.Color.GrayLight1};
        box-shadow: none;
        border-color: ${({ theme }) => theme.line.color.default};
        &:before {
          opacity: 0;
        }
        > ${CarouselNavStyle} {
          transform: translateY(100%);
        }
      }
    `;
  }}

  ${({ animationStatus }) => {
    if (animationStatus === 'entering') {
      return css`
        ${IntroContentStyle} {
          transition-delay: ${MODE_ANIM_SPEED_MS * 0.5}ms;
        }
        ${CarouselContainerStyle}, ${CarouselContainerStyle}::before, ${CarouselContentContainerStyle} {
          transition-delay: ${MODE_ANIM_SPEED_MS * 0.7}ms;
        }
        ${CarouselTitleStyle}, ${CarouselSlideGraphicsContainerStyle} {
          transition-delay: ${MODE_ANIM_SPEED_MS * 0.9}ms;
        }
      `;
    }

    if (animationStatus === 'entered') {
      return css`
        ${IntroContentStyle} {
          z-index: -1;
        }
      `;
    }
  }}

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    > ${CarouselTitleStyle} {
      display: none;
    }
  }
`;

export const CarouselGraphicsContainerStyle = styled.div.attrs({
  className: 'CarouselGraphicsContainerStyle',
})`
  --graphicsHighlightColor: ${({ theme }) => mixins.lightenDarkenColor(theme.Color.Yorange, 0.11)};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none; // PEH
  position: absolute;
`;

export const LoaderContainerStyle = styled.div.attrs({ className: 'LoaderContainerStyle' })<{
  slideColor: string;
}>`
  transition: all ${LOADING_ANIM_SPEED_MS}ms ease-in;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -36px;
  margin-left: -26px;
  z-index: 3;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 150%;
    height: 150%;
    background: ${({ slideColor }) => slideColor};
    border-radius: 50%;
    z-index: -1;
    filter: blur(15px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  ${LoaderStyles.LoaderBorderStyle} {
    border-top-color: white;
  }
  ${LoaderStyles.LoaderBorderContainerStyle} {
    border-color: white;
  }
`;

export const PricingCarouselSectionStyle = styled(PaddedSectionStyle).attrs({
  className: 'PricingCarouselSectionStyle',
})<{ isLoading: boolean }>`
  background: ${({ theme }) => theme.Color.GrayLight1};
  padding-top: calc(${basePadding.desktop} + 1.8rem);
  padding-bottom: 0;
  position: relative;
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    padding-top: ${basePadding.mobile};
    overflow-x: clip;
    > ${ContainerStyles.ContainerStyle} {
      padding: 0 calc(${(props) => props.theme.container.gutter.default} / 2);
    }
  }

  ${PaginatorItemStyle} {
    transition: all ${LOADING_ANIM_SPEED_MS}ms ease-in;
  }

  ${({ isLoading }) => css`
    ${LoaderContainerStyle} {
      opacity: ${isLoading ? 1 : 0};
    }
    ${PaginatorItemStyle} {
      opacity: ${isLoading ? 0.1 : 1};
    }
  `}
`;
