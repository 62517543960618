import React from 'react';
import {
  Button,
  Container,
  DirectionAndPlacement,
  Header,
  Icon,
  IconName,
  Size,
  Style,
  TemplatedText,
  useIsMobile,
} from '@pointdotcom/pds';
import { useTaskCompletionModal } from 'containers/hooks/useTaskCompletionModal';
import FollowUpModel from 'models/FollowUpModel';
import { CompletedTask } from 'store/general';
import { BorderedSectionStyle, HeroContainerStyle } from 'styles/';
import i18n from './i18n';
import {
  CTAChoiceStyle,
  DebtOptionInnerContainerStyle,
  DebtOptionOuterContainerStyle,
  DebtPayoffSelectionStyle,
  FundingStepStyle,
  HighlightedNumberStyle,
  ItemContentStyle,
  MobileGraphicStyle,
  StepItemStyle,
  StepListStyle,
  StepMarkerStyle,
} from './styles';

interface StepItemProps {
  children: React.ReactNode;
  type?: 'complete' | 'active' | 'incomplete';
}

function StepItem({ children, type = 'incomplete' }: StepItemProps) {
  return (
    <StepItemStyle active={type === 'active'}>
      <StepMarkerStyle active={type === 'active'}>
        {type === 'complete' ? <Icon name={IconName.Check} styleSize={20} /> : null}
      </StepMarkerStyle>
      <ItemContentStyle>{children}</ItemContentStyle>
    </StepItemStyle>
  );
}

export const DebtPayoffSection = ({ followUp }: { followUp: FollowUpModel | null }) => {
  const { isMobile } = useIsMobile();
  const completeTask = useTaskCompletionModal();

  const formattedOfferAmount = followUp?.getFormattedOfferAmount();

  const onSelection = (helpRequested: boolean) => {
    // Send them back to dashboard
    const task: CompletedTask = {
      title: i18n.reviewYourOffer,
      heading: i18n.thanksForLetting,
      summary: helpRequested ? i18n.notYetAvailable : i18n.thisTaskIs,
      detailList: {
        ordered: false,
        items: [
          i18n.wellWorkWith,
          i18n.wellReviewLegal,
          i18n.theEscrowCompany,
          i18n.youllReceiveMoney,
        ],
      },
    };
    completeTask(task);
  };

  return (
    <DebtPayoffSelectionStyle>
      <HeroContainerStyle styleSize={Size.Small}>
        <Container styleGutterSize={Size.Medium}>
          <Header
            styleSize={Size.Large}
            styleAlign={isMobile ? DirectionAndPlacement.Left : DirectionAndPlacement.Center}
          >
            <TemplatedText
              values={{
                offerAmount: (
                  <HighlightedNumberStyle key="offerAmount">
                    {formattedOfferAmount}
                  </HighlightedNumberStyle>
                ),
              }}
            >
              {i18n.congratulations}
            </TemplatedText>
          </Header>
        </Container>
      </HeroContainerStyle>
      <DebtOptionOuterContainerStyle>
        <MobileGraphicStyle />
        <MobileGraphicStyle />
        <MobileGraphicStyle />
        <MobileGraphicStyle />
        <DebtOptionInnerContainerStyle styleGutterSize={Size.Medium}>
          <h3>{i18n.wouldYouBe}</h3>
          <CTAChoiceStyle>
            <Button styleType={Style.Dark} onClick={() => onSelection(false)} block>
              {i18n.noIDont}
            </Button>
            <Button onClick={() => onSelection(true)} block>
              {i18n.yesHelpPay}
            </Button>
          </CTAChoiceStyle>
        </DebtOptionInnerContainerStyle>
      </DebtOptionOuterContainerStyle>
      <FundingStepStyle>
        <Header styleSize={Size.Small} styleAlign={DirectionAndPlacement.Center}>
          {i18n.yourSteps}
        </Header>
        <StepListStyle>
          <StepItem type="complete">
            <TemplatedText values={{ offerAmount: formattedOfferAmount }}>
              {i18n.acceptYourOffer}
            </TemplatedText>
          </StepItem>
          <StepItem type="active">{i18n.wellWorkWith}</StepItem>
          <StepItem>{i18n.wellReviewLegal}</StepItem>
          <StepItem>{i18n.theEscrowCompany}</StepItem>
          <StepItem>{i18n.youllReceiveMoney}</StepItem>
        </StepListStyle>
      </FundingStepStyle>
      <BorderedSectionStyle />
    </DebtPayoffSelectionStyle>
  );
};
