import React from 'react';
import { useLocation } from 'react-router';
import { DirectionAndPlacement } from '@pointdotcom/pds';
import LeftRightPaginator, { PaginateFunctionType } from 'components/LeftRightPaginator';
import { NavItem, NavProps, getNavItemProp } from 'components/MainHeader/nav';
import { useHistory } from 'containers/routerHelpers';

export interface SubNavPaginatorProps {
  navItems: Array<NavItem>;
  navProps: NavProps;
}

const SubNavPaginator = ({ navItems, navProps }: SubNavPaginatorProps) => {
  const location = useLocation();
  const history = useHistory();

  const currentIndex = navItems.findIndex((navItem) => {
    const href = getNavItemProp('href', navProps)(navItem);
    return href === location.pathname;
  });

  const handlePaginate: PaginateFunctionType = (direction) => {
    let paginatedIndex = Math.max(0, currentIndex);
    if (direction === DirectionAndPlacement.Left) {
      paginatedIndex -= 1;
    } else if (direction === DirectionAndPlacement.Right) {
      paginatedIndex += 1;
    }
    const paginatedItem = navItems[paginatedIndex];
    const href = getNavItemProp('href', navProps)(paginatedItem);
    href && history.push(href);
  };

  return (
    <LeftRightPaginator
      currentPageNum={currentIndex + 1}
      onPaginate={handlePaginate}
      totalNumPages={navItems.length}
    />
  );
};

export default SubNavPaginator;
