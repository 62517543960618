import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { MODE_ANIM_SPEED_MS } from '../styles';
import { BillPileGraphicStyle } from './BillPileGraphic/styles';
import { CapBillGraphicStyle } from './CapBillGraphic/styles';
import { CapGraphGraphicStyle, GraphBarStyle } from './CapGraphGraphic/styles';
import { CapHouseGraphicStyle, CapHouseHillStyle } from './CapHouseGraphic/styles';
import { FishingBoatGraphicStyle, FishingGraphicStyle } from './FishingGraphic/styles';
import { GoldHouseMagnifyGraphicStyle } from './GoldHouseMagnifyGraphic/styles';
import { GoldenHouseStyle, GoldenHousesGraphicStyle } from './GoldenHousesGraphic/styles';
import { HandCheckGraphicStyle } from './HandCheckGraphic/styles';
import { MagicCalendarGraphicStyle } from './MagicCalendarGraphic/styles';
import { MountainGraphicStyle } from './MountainGraphic/styles';
import { PiggyBankGraphicStyle } from './PiggyBankGraphic/styles';
import { ShieldGraphicStyle } from './ShieldGraphic/styles';
import {
  HillStyle,
  ShineStyle,
  SoldHouseGraphicStyle,
  SoldHouseStyle,
  SoldSignStyle,
} from './SoldHouseGraphic/styles';
import { ButterflyCanvasStyle, TandemBikeGraphicStyle } from './TandemBikeGraphic/styles';

const LeftRightGraphicStyle = styled.div`
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  width: 50%;
  display: flex;
`;
export const LeftGraphicStyle = styled(LeftRightGraphicStyle).attrs({
  className: 'LeftGraphicStyle',
})`
  left: 0;
`;
export const RightGraphicStyle = styled(LeftRightGraphicStyle).attrs({
  className: 'RightGraphicStyle',
})`
  right: 0;
`;

export const CarouselSlideGraphicsStyle = styled.div.attrs({
  className: 'CarouselSlideGraphicsStyle',
})<{ animationStatus: TransitionStatus }>`
  width: 100%;
  height: 100%;
  transition: all ${MODE_ANIM_SPEED_MS}ms ease-in-out;
  opacity: 0;
  ${({ animationStatus }) => {
    if (animationStatus.includes('enter')) {
      return css`
        opacity: 1;
      `;
    }
  }}
`;

export const IntroGraphicStyle = styled.div.attrs({ className: 'IntroGraphicStyle' })`
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${LeftGraphicStyle} {
      width: 100%;
    }
    ${RightGraphicStyle} {
      top: 16%;
      width: 100%;
      bottom: unset;
    }
    ${GoldHouseMagnifyGraphicStyle} {
      --goldHouseMagnifySize: 26%;
      left: 50%;
      transform: translateX(-50%);
    }
    ${CapGraphGraphicStyle} {
      --capGraphicSize: 5%;
      right: unset;
      left: 50%;
      transform: translateX(-50%);
    }
    ${ShieldGraphicStyle} {
      display: none;
    }
  }
  @media (max-width: 560px) {
    ${CapGraphGraphicStyle} {
      --capGraphicSize: 8%;
    }
    ${GoldHouseMagnifyGraphicStyle} {
      --goldHouseMagnifySize: 32%;
    }
  }
  @media (max-width: 440px) {
    ${CapGraphGraphicStyle} {
      --capGraphicSize: 10%;
    }
    ${GoldHouseMagnifyGraphicStyle} {
      --goldHouseMagnifySize: 47%;
    }
  }
`;

export const Slide1GraphicStyle = styled.div.attrs({ className: 'Slide1GraphicStyle' })`
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${LeftGraphicStyle} {
      width: 100%;
      height: 100%;
      bottom: 4.3rem;
    }
    ${MagicCalendarGraphicStyle} {
      --calendarSize: 20%;
      left: 40%;
    }
  }
  @media (max-width: 528px) {
    ${MagicCalendarGraphicStyle} {
      --calendarSize: 30%;
      left: 30%;
    }
  }
  @media (max-width: 400px) {
    ${MagicCalendarGraphicStyle} {
      --calendarSize: 40%;
      left: 28%;
    }
  }
`;

export const Slide2GraphicStyle = styled.div.attrs({ className: 'Slide2GraphicStyle' })`
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${LeftGraphicStyle} {
      display: none;
    }
    ${RightGraphicStyle} {
      bottom: 4.3rem;
      width: 100%;
      height: 50%;
      overflow: hidden;
    }
    ${PiggyBankGraphicStyle} {
      --piggyBankSize: 23%;
      bottom: -12%;
      left: unset;
      right: 12%;
      &:before {
        left: -26%;
        bottom: -12%;
      }
    }
  }
  @media (max-width: 540px) {
    ${PiggyBankGraphicStyle} {
      --piggyBankSize: 28%;
    }
  }
  @media (max-width: 440px) {
    ${PiggyBankGraphicStyle} {
      --piggyBankSize: 42%;
      bottom: -12%;
    }
  }
`;

export const Slide3GraphicStyle = styled.div.attrs({ className: 'Slide3GraphicStyle' })`
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${LeftGraphicStyle} {
      width: 100%;
    }
    ${BillPileGraphicStyle} {
      --billPileGraphicSize: 28%;
      left: 5%;
      transform: translateX(-50%);
    }
  }
  @media (max-width: 540px) {
    ${BillPileGraphicStyle} {
      --billPileGraphicSize: 40%;
    }
  }
  @media (max-width: 440px) {
    ${BillPileGraphicStyle} {
      left: 3%;
      --billPileGraphicSize: 50%;
    }
  }
`;

export const Slide4GraphicStyle = styled.div.attrs({ className: 'Slide4GraphicStyle' })`
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${LeftGraphicStyle} {
      bottom: 4.3rem;
      width: 100%;
    }
    ${RightGraphicStyle} {
      display: none;
    }
    ${TandemBikeGraphicStyle} {
      --hillSize: 40%;
      --tandemBikeSize: 48%;
      --shineImageSize: 15%;
      left: 40%;
      &:after {
        left: 6%;
        top: unset;
        bottom: 1%;
      }
      &:before {
        left: 78%;
      }
    }
    ${ButterflyCanvasStyle} {
      display: none;
    }
  }
  @media (max-width: 440px) {
    ${TandemBikeGraphicStyle} {
      --hillSize: 50%;
      --tandemBikeSize: 58%;
      --shineImageSize: 25%;
    }
  }
`;

export const Slide5GraphicStyle = styled.div.attrs({ className: 'Slide5GraphicStyle' })`
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${LeftGraphicStyle} {
      bottom: 4.3rem;
      width: 100%;
    }
    ${RightGraphicStyle} {
      display: none;
    }
    ${HandCheckGraphicStyle} {
      --handCheckSize: 25%;
      left: 35%;
    }
  }
  @media (max-width: 460px) {
    ${HandCheckGraphicStyle} {
      --handCheckSize: 40%;
      left: 20%;
    }
  }
  @media (max-width: 450px) {
    ${HandCheckGraphicStyle} {
      --handCheckSize: 45%;
      left: 25%;
    }
  }
`;

export const Slide6to8GraphicStyle = styled.div.attrs({ className: 'Slide6to8GraphicStyle' })`
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${RightGraphicStyle} {
      height: 100%;
      overflow-y: clip;
    }
    ${CapHouseGraphicStyle} {
      --capHouseSize: 32%;
      right: -12%;
      bottom: 7%;
      &:before {
        height: 76%;
        bottom: -19%;
      }
    }
    ${CapHouseHillStyle} {
      right: -55%;
      bottom: -2%;
    }
  }
  @media (max-width: 620px) {
    ${CapHouseHillStyle} {
      right: -50%;
    }
  }
  @media (max-width: 540px) {
    ${CapHouseHillStyle} {
      right: -45%;
    }
  }
  @media (max-width: 490px) {
    ${CapHouseHillStyle} {
      --hillSize: 120%;
      right: -52%;
    }
    ${CapHouseGraphicStyle} {
      --capHouseSize: 35%;
    }
  }
  @media (max-width: 430px) {
    ${CapHouseHillStyle} {
      --hillSize: 140%;
      right: -66%;
    }
    ${CapHouseGraphicStyle} {
      --capHouseSize: 45%;
      right: -20%;
    }
  }
  @media (max-width: 405px) {
    ${CapHouseHillStyle} {
      --hillSize: 140%;
      right: -66%;
    }
    ${CapHouseGraphicStyle} {
      --capHouseSize: 50%;
      right: -23%;
      bottom: 7%;
    }
  }
`;

export const Slide9GraphicStyle = styled.div.attrs({ className: 'Slide9GraphicStyle' })`
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${CapBillGraphicStyle} {
      --capBillGaphicSize: 42%;
      bottom: 4.3rem;
      left: 70%;
    }
    ${GraphBarStyle} {
      &:after {
        font-size: 1.8rem;
      }
    }
  }
  @media (max-width: 595px) {
    ${CapBillGraphicStyle} {
      --capBillGaphicSize: 52%;
      left: 65%;
    }
  }
  @media (max-width: 485px) {
    ${CapBillGraphicStyle} {
      --capBillGaphicSize: 70%;
      left: 50%;
    }
  }
  @media (max-width: 400px) {
    ${CapBillGraphicStyle} {
      --capBillGaphicSize: 80%;
      left: 44%;
    }
  }
`;

export const Slide10GraphicStyle = styled.div.attrs({ className: 'Slide10GraphicStyle' })`
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${LeftGraphicStyle} {
      width: 100%;
    }
    ${CapGraphGraphicStyle} {
      --capGraphicSize: 19%;
      left: unset;
      right: 5%;
    }
  }
  @media (max-width: 595px) {
    ${CapGraphGraphicStyle} {
      --capGraphicSize: 23%;
    }
  }
  @media (max-width: 420px) {
    ${CapGraphGraphicStyle} {
      --capGraphicSize: 26%;
      right: -2%;
    }
  }
`;

export const Slide11to13GraphicStyle = styled.div.attrs({ className: 'Slide11to13GraphicStyle' })`
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${RightGraphicStyle} {
      width: 100%;
    }
    ${SoldHouseGraphicStyle} {
      overflow: visible;
      overflow-y: clip;
    }
    ${SoldHouseStyle} {
      --soldHouseSize: 26%;
      right: -10%;
      bottom: -1%;
    }
    ${HillStyle} {
      left: -25%;
      right: unset;
    }
    ${SoldSignStyle} {
      --signSize: 13%;
      left: 3%;
      right: unset;
      bottom: 8%;
    }
    ${HillStyle} {
      --hillSize: 50%;
    }
    ${ShineStyle} {
      display: none;
    }
  }
  @media (max-width: 500px) {
    ${SoldHouseStyle} {
      --soldHouseSize: 35%;
    }
    ${HillStyle} {
      --hillSize: 53%;
    }
    ${SoldSignStyle} {
      --signSize: 18%;
      bottom: 7%;
    }
  }
`;

export const Slide14GraphicStyle = styled.div.attrs({ className: 'Slide14GraphicStyle' })`
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${LeftGraphicStyle} {
      display: none;
    }
    ${RightGraphicStyle} {
      width: 100%;
      bottom: 4.3rem;
    }
    ${GoldenHouseStyle} {
      &:first-child {
        --goldenHouseSize: 12%;
      }
      &:last-child {
        --goldenHouseSize: 18%;
        right: 24%;
      }
    }
    ${GoldenHousesGraphicStyle} {
      left: -12%;
    }
  }
  @media (max-width: 500px) {
    ${GoldenHouseStyle} {
      &:first-child {
        --goldenHouseSize: 20%;
      }
      &:last-child {
        --goldenHouseSize: 24%;
        right: 20%;
      }
    }
  }
`;

export const Slide15GraphicStyle = styled.div.attrs({ className: 'Slide15GraphicStyle' })`
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${MountainGraphicStyle} {
      --mountainGraphicSize: 100%;
      left: -40%;
    }
    ${FishingGraphicStyle} {
      --fishingGraphicSize: 90%;
      right: -30%;
    }
    ${FishingBoatGraphicStyle} {
      bottom: -7%;
    }
  }
  @media (max-width: 410px) {
    ${MountainGraphicStyle} {
      --mountainGraphicSize: 120%;
    }
    ${FishingGraphicStyle} {
      --fishingGraphicSize: 110%;
    }
  }
`;
