import { useEffect } from 'react';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { useProduct } from 'containers/prequal/hooks/useProduct';
import { useHistory, useLocation } from 'containers/routerHelpers';
import { useSelector } from 'store/hooks';

/**
 * Redirect the user to the beginning of the appropriate prequal flow
 * if they haven't already completed the first step, e.g. maybe followed a link
 * to a page in the middle of the flow.
 */
export const useRedirectToBeginning = () => {
  const { product } = useProduct();
  const history = useHistory();
  const location = useLocation();

  const streetAddress = useSelector((state) => state.property.address?.streetAddress);
  const currentRoute = location.pathname;
  const startRoute = generateUrlFromPage(pages.PREQUAL_START, { product });

  useEffect(() => {
    if (currentRoute === startRoute) return;
    if (streetAddress) return;

    history.replace(startRoute);
  }, [product, history, currentRoute, streetAddress, startRoute]);
};
