import { createApi } from '@reduxjs/toolkit/query/react';
import { getUnderwriteHost } from 'lib/hosts';
import { dashboardTag, homeownerApi } from 'services/api/homeownerApi';
import {
  UnderwriteSuccessResponse,
  underwriteSuccessResponseSchema,
} from '../apiTypes/underwriteTypes';
import { axiosBaseQuery, losAxiosOptions, parseResponse } from './baseQuery';

export const underwriteApi = createApi({
  reducerPath: 'underwriteApi',
  tagTypes: ['underwrite-followup'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${getUnderwriteHost()}`,
  }),
  endpoints: (builder) => ({
    followup: builder.query<TSFixMe, string>({
      query: (followUpId) => {
        return { url: `/followup/${followUpId}.json`, ...losAxiosOptions() };
      },
      providesTags: ['underwrite-followup'],
    }),

    acceptFollowup: builder.mutation<
      UnderwriteSuccessResponse,
      { followUpId: string; csrfToken: string | null }
    >({
      query: ({ followUpId, csrfToken }) => ({
        url: `/followup/${followUpId}.json`,
        ...losAxiosOptions('POST', csrfToken),
        data: `accept=true`,
      }),
      transformResponse: (response: unknown) =>
        parseResponse(response, underwriteSuccessResponseSchema),
      invalidatesTags: ['underwrite-followup'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(homeownerApi.util.invalidateTags([dashboardTag]));
      },
    }),

    rejectFollowup: builder.mutation<
      UnderwriteSuccessResponse,
      {
        followUpId: string;
        rejectReason: string;
        csrfToken: string | null;
      }
    >({
      query: ({ followUpId, rejectReason, csrfToken }) => ({
        url: `/followup/${followUpId}.json`,
        ...losAxiosOptions('POST', csrfToken),
        data: `reject=true&reject_reason=${encodeURIComponent(rejectReason)}`,
      }),
      transformResponse: (response: unknown) =>
        parseResponse(response, underwriteSuccessResponseSchema),
      invalidatesTags: ['underwrite-followup'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(homeownerApi.util.invalidateTags([dashboardTag]));
      },
    }),

    requestNewAmount: builder.mutation<
      UnderwriteSuccessResponse,
      {
        followUpId: string;
        amountRequested: number;
        csrfToken: string | null;
      }
    >({
      query: ({ followUpId, amountRequested, csrfToken }) => ({
        url: `/followup/${followUpId}.json`,
        ...losAxiosOptions('POST', csrfToken),
        data: `new_request_amount=${amountRequested}`,
      }),
      transformResponse: (response: unknown) =>
        parseResponse(response, underwriteSuccessResponseSchema),
      invalidatesTags: ['underwrite-followup'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(homeownerApi.util.invalidateTags([dashboardTag]));
      },
    }),

    counselingSelection: builder.mutation<
      TSFixMe,
      {
        followUpId: string;
        csrfToken: string;
        choice: string;
      }
    >({
      query: ({ followUpId, csrfToken, choice }) => ({
        url: `/followup/${followUpId}.json`,
        ...losAxiosOptions('POST', csrfToken),
        data: `choice=${choice}`,
      }),
      invalidatesTags: ['underwrite-followup'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(homeownerApi.util.invalidateTags([dashboardTag]));
      },
    }),

    magicLink: builder.mutation<UnderwriteSuccessResponse, string>({
      query: (key) => ({
        url: `/magic_link/${key}/renew`,
        ...losAxiosOptions('POST'),
      }),
      transformResponse: (response: unknown) =>
        parseResponse(response, underwriteSuccessResponseSchema),
    }),
  }),
});

export const {
  useFollowupQuery,
  useAcceptFollowupMutation,
  useRejectFollowupMutation,
  useRequestNewAmountMutation,
  useCounselingSelectionMutation,
  useMagicLinkMutation,
} = underwriteApi;
