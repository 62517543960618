import React from 'react';
import { DirectionAndPlacement } from '@pointdotcom/pds';
import MagnifyGraphic from '../MagnifyGraphic';
import * as styles from './styles';

export default function GoldHouseMagnifyGraphic() {
  return (
    <styles.GoldHouseMagnifyGraphicStyle>
      <MagnifyGraphic direction={DirectionAndPlacement.Left} />
    </styles.GoldHouseMagnifyGraphicStyle>
  );
}
