import * as React from 'react';
import { useCallback, useImperativeHandle } from 'react';

interface FileInputProps {
  onAddFiles: (files: File[]) => unknown;
  multiple?: boolean;
  contentTypes?: Iterable<string>;
}

interface FileInputImperatives {
  open(): void;
}

export default React.forwardRef(function FileInput(
  { onAddFiles, contentTypes, multiple = false }: FileInputProps,
  imperativeRef: React.Ref<FileInputImperatives>
): JSX.Element {
  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileInput = event.target;
      if (fileInput.files) {
        const files = [...fileInput.files];
        fileInput.value = '';
        onAddFiles(files);
      }
    },
    [onAddFiles]
  );

  const fileInputRef = React.useRef<null | HTMLInputElement>(null);

  const accept = contentTypes && [...contentTypes].join(',');

  useImperativeHandle(
    imperativeRef,
    () => ({
      open: () => {
        fileInputRef.current?.click();
      },
    }),
    []
  );

  return (
    <input
      ref={fileInputRef}
      style={{ display: 'none' }}
      type="file"
      multiple={multiple}
      accept={accept}
      onChange={handleFileChange}
    />
  );
});
