import styled from 'styled-components';
import { ProfileImageStyles } from '@pointdotcom/pds';

// import { ProfileImageStyle, ProfileImageImgStyle } from '../../ProfileImage/styles';

export const ButtonPicWrapperStyle = styled.div`
  position: relative;
  margin-top: 2em;
  button {
    display: inline-block;
  }
  ${ProfileImageStyles.ProfileImageStyle} {
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  ${ProfileImageStyles.ProfileImageImgStyle} {
    box-shadow: 0 0 20px -5px rgba(0, 0, 0, 1);
  }
`;

export const ButtonScheduleNextAvailableStyle = styled.div.attrs({
  className: 'ButtonScheduleNextAvailableStyle' as string,
})`
  position: relative;
`;

export const DescriptionTextStyle = styled.div.attrs({
  className: 'DescriptionTextSyle' as string,
})<{ error: boolean }>`
  color: ${({ theme, error }) => (error ? theme.Color.Red : 'inherit')};
  margin-bottom: 2em;
`;

export const ButtonAndLinkContainerStyle = styled.div.attrs({
  className: 'ButtonAndLinkContainerStyle' as string,
})`
  text-align: center;
  .ButtonLinkStyle {
    position: absolute;
    bottom: -3em;
    left: 0;
    display: inline-block;
    text-align: center;
    width: 100%;
  }
`;

export const LoadingStateStyle = styled.div.attrs({
  className: 'LoadingStateStyle' as string,
})`
  top: 1em;
  padding: 5%;
  text-align: center;
  .loader {
    top: 1em;
  }
`;

export const BoldTextStyle = styled.b`
  font-weight: normal;
  font-family: ${(props) => props.theme.fonts.sansSerif.name};
  font-weight: 700;
`;
