export default {
  pageNotFound: 'Page not found',
  thankYou: 'Thank you for your interest in Point. Your application has expired.',
  thankYouContract: 'Thank you for your interest in Point. Your contract has exited.',
  pleaseContact: 'Please contact us at %phone% to re-open your application.',
  offerExpired:
    'Sorry, but your offer has expired because the information you originally provided is now outdated. %prequal%',
  getANew: 'Get a new offer',
  weveEnjoyed:
    'Weʼve enjoyed serving you and hope you had an excellent experience working with us.',
  ifYouNeed: 'If you need anything else, donʼt hesitate to call us at %phone%.',
  forAdditional: 'For additional information, please contact us at %phone%.',
  unfortunately: 'Unfortunately, we werenʼt able to move forward with your application.',
  thankYouBestOfLuck: 'Thank you for your interest, and best of luck!',

  previouslyStartedAnApplication: 'You previously started an application that is no longer active.',
  createNewOffer: 'You can create a new offer by going here',
  ifYouHaveQuestions:
    '%createNewOffer%. If you have questions, please email us at %email% or call us at %phone%.',
};
