import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import {
  Button,
  DirectionAndPlacement,
  Header,
  IconName,
  Modal,
  ModalProps,
  Size,
  SplashText,
  TemplatedText,
} from '@pointdotcom/pds';
import AuthenticatedDashboard from 'components/AuthenticatedDashboard';
import DashboardMainHeader from 'components/DashboardMainHeader';
import FullScreenLoading from 'components/FullScreenLoading';
import HeiTermsFooter from 'components/HeiTermsFooter';
import MainFooter from 'components/MainFooter';
import ErrorPage, { ErrorType } from 'containers/ErrorPage';
import HeiOfferEstimatorPageScenariosSection from 'containers/hei/HeiOfferEstimatorPage/HeiOfferEstimatorPageScenariosSection';
import ScheduleCallOverlay from 'containers/hei/HeiOfferEstimatorPage/ScheduleCallOverlay';
import DashboardModel from 'models/DashboardModel';
import OfferEstimateModel from 'models/OfferEstimateModel';
import PricingRangeModel from 'models/PriceRangeModel';
import { RootState } from 'store';
import { Products } from 'store/constants';
import {
  getEstimatesAreLoading,
  getEstimatesHaveError,
  getEstimatesLoadNotStarted,
  getHEIEstimateModel,
  getPriceRangeModel,
  getPricingRangeHasError,
  getPricingRangeIsLoading,
  getRawHEIEstimate,
} from 'store/estimates';
import { useDispatch, useSelector } from 'store/hooks';
import { getEstimate, getPricingRange } from 'store/thunks/estimates';
import { Contact } from 'types';
import i18n from './i18n';
import * as styles from './styles';
import { HighlightedNumberStyle } from './styles';

interface DashboardEstimatorPageImplProps {
  dashboard: DashboardModel;
}

interface RequestDifferentAmountModalProps extends ModalProps {
  onModalClose: () => unknown;
  currentAmount: string;
  estimate: OfferEstimateModel;
  contact: null | Contact;
}

function RequestDifferentAmountModal({
  currentAmount,
  onModalClose,
  estimate,
  contact,
  ...modalProps
}: RequestDifferentAmountModalProps) {
  const [scheduleCallModalIsOpen, setScheduleCallModalIsOpen] = useState(false);

  const handleClickScheduleCall = useCallback(() => {
    onModalClose();
    setScheduleCallModalIsOpen(true);
  }, [onModalClose]);

  return (
    <>
      <Modal {...modalProps} onModalClose={onModalClose}>
        <Header styleSize={Size.Splash}>{i18n.requestDifferentAmount}</Header>
        <SplashText>
          <TemplatedText
            values={{ amount: <HighlightedNumberStyle>{currentAmount}</HighlightedNumberStyle> }}
          >
            {i18n.fundingAmountModalText}
          </TemplatedText>
        </SplashText>
        <Button
          block
          iconType={IconName.ChevronRight}
          styleAlignText={DirectionAndPlacement.Left}
          styleSize={Size.Large}
          onClick={handleClickScheduleCall}
        >
          {i18n.scheduleACall}
        </Button>
      </Modal>
      <ScheduleCallOverlay
        isOpen={scheduleCallModalIsOpen}
        onModalClose={() => setScheduleCallModalIsOpen(false)}
        estimate={estimate}
        applicant={contact}
      />
    </>
  );
}

function DashboardEstimatorPageImpl({
  dashboard,
}: DashboardEstimatorPageImplProps): null | JSX.Element {
  const estimateKey = dashboard.application?.estimateKey;

  const dispatch = useDispatch();

  const rawHeiEstimate = useSelector(getRawHEIEstimate);
  const estimateFromStore = useSelector(getHEIEstimateModel);
  const estimateIsLoading = useSelector(getEstimatesAreLoading);
  const estimateHasError = useSelector(getEstimatesHaveError);

  const pricingRangeFromStore = useSelector(getPriceRangeModel);
  const pricingRangeIsLoading = useSelector(getPricingRangeIsLoading);
  const pricingRangeHasError = useSelector(getPricingRangeHasError);

  const [estimate, pricingRange]: [null | OfferEstimateModel, null | PricingRangeModel] =
    useMemo(() => {
      const latestOffer = dashboard.getDocket()?.getLatestOffer();

      if (latestOffer != null) {
        return [
          new OfferEstimateModel({
            ...rawHeiEstimate,
            key: rawHeiEstimate?.key ?? 'docket',
            pricing: latestOffer,
            termYears: latestOffer.termYears,
            minOptionAmount: latestOffer.optionInvestmentAmount,
            maxOptionAmount: latestOffer.optionInvestmentAmount,
          }),
          null,
        ];
      }
      if (estimateIsLoading || pricingRangeIsLoading) {
        return [null, null];
      }
      return [estimateFromStore, pricingRangeFromStore];
    }, [
      dashboard,
      estimateFromStore,
      estimateIsLoading,
      pricingRangeFromStore,
      pricingRangeIsLoading,
      rawHeiEstimate,
    ]);

  // Get the estimate
  const estimateLoadNotStarted = useSelector(getEstimatesLoadNotStarted);
  useEffect(() => {
    if (!estimateKey || !estimateLoadNotStarted || estimateFromStore) {
      return;
    }
    dispatch(getEstimate({ estimateKey }));
  }, [estimateKey, estimate, estimateLoadNotStarted, dispatch, estimateFromStore]);

  // Get the priceRangeEstimate
  const pricingRangeEstimateLoadNotStarted = useSelector(
    (state: RootState) => !state.estimates[Products.HEI].pricingRangeLoadState
  );
  useEffect(() => {
    if (!estimateKey || !pricingRangeEstimateLoadNotStarted || pricingRangeFromStore) {
      return;
    }
    dispatch(getPricingRange({ estimateKey }));
  }, [
    estimateKey,
    pricingRange,
    pricingRangeEstimateLoadNotStarted,
    dispatch,
    pricingRangeFromStore,
  ]);

  const [requestModalIsOpen, setRequestModalIsOpen] = useState(false);

  if (estimate == null) {
    if (estimateIsLoading || pricingRangeIsLoading) {
      return <FullScreenLoading />;
    }

    if (estimateHasError || pricingRangeHasError) {
      return <ErrorPage errorType={ErrorType.General} />;
    }
    return null;
  }

  return (
    <>
      <styles.DashboardStyle data-ga-tracking-id="HODashboardEstimator">
        <Helmet title={i18n.yourDashboardCostEstimator} />
        <DashboardMainHeader
          dashboard={dashboard}
          crumbProps={{ currentCrumb: i18n.yourCostEstimator }}
        />
        <HeiOfferEstimatorPageScenariosSection
          isHero
          estimate={estimate}
          pricingRange={pricingRange}
          numberedSliders
          showCurrentOffer
          longDivider
          header={
            <>
              <Header styleSize={Size.Massive}>{i18n.yourCostEstimator}</Header>
              <SplashText noMargin>{i18n.description}</SplashText>
            </>
          }
          onRequestDifferentAmount={pricingRange == null ? () => setRequestModalIsOpen(true) : null}
        />
        <HeiTermsFooter />
        <MainFooter noBackground />
      </styles.DashboardStyle>
      <RequestDifferentAmountModal
        isOpen={requestModalIsOpen}
        onModalClose={() => setRequestModalIsOpen(false)}
        currentAmount={estimate.getFormattedMaxOptionAmount()}
        estimate={estimate}
        contact={dashboard.getContact()}
        shadeBg
      />
    </>
  );
}

export default function DashboardEstimatorPage() {
  return (
    <AuthenticatedDashboard>
      {({ dashboard }) => dashboard != null && <DashboardEstimatorPageImpl dashboard={dashboard} />}
    </AuthenticatedDashboard>
  );
}
