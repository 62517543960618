import * as React from 'react';
import { useId } from 'react';
import { useTheme } from 'styled-components';

interface PieWithSliceProps {
  percentage: number;
}

const radius = 80;
const sliceRadius = 1.1 * radius;
const strokeWidth = 2.75;

export default function PieWithSlice({ percentage }: PieWithSliceProps): JSX.Element {
  const theme = useTheme();

  const pieSliceRadians = (2 * Math.PI * percentage) / 100;
  const pieSliceDegrees = (360 * percentage) / 100;

  const filterId = useId();

  return (
    <svg role="img" width="50" height="50" viewBox="-100 -100 200 200" fill="none">
      <circle
        cx="0"
        cy="0"
        r={radius}
        fill={theme.Color.White}
        stroke={theme.Color.Gray1}
        strokeWidth={strokeWidth}
      />
      <g filter={`url(#${filterId})`}>
        <path
          d={[
            'M 0 0',
            `L 0 -${sliceRadius}`,
            `A ${sliceRadius} ${sliceRadius} ${pieSliceDegrees} ${
              pieSliceDegrees >= 180 ? '1' : '0'
            } 1 ${sliceRadius * Math.sin(pieSliceRadians)} ${
              -sliceRadius * Math.cos(pieSliceRadians)
            }`,
            'L 0 0',
          ].join(' ')}
          fill={theme.Color.White}
          stroke={theme.Color.Gray1}
          strokeWidth={strokeWidth}
        />
      </g>
      <defs>
        <filter
          id={filterId}
          x="-100"
          y="-100"
          width="200"
          height="200"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.443137 0 0 0 0 0.454902 0 0 0 0 0.458824 0 0 0 0.24 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1223_8666" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1223_8666"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
