import { Pricing } from 'services/apiTypes/prequalTypes';
import { Expand } from 'types';
import BaseModel from './BaseModel';
import PricingModel from './PricingModel';

interface PriceRange {
  estimates?: Array<Pricing | PricingRangeModel>;
}

class PricingRangeModel extends BaseModel<PriceRange> {
  estimates?: TSFixMe;

  constructor(props: Expand<PriceRange>) {
    super(props);
    Object.assign(this, props);
    if (props.estimates) {
      this.estimates = props.estimates.map((estimate) => {
        if (estimate instanceof PricingRangeModel) {
          return estimate;
        }
        return new PricingModel(estimate);
      });
    }
  }

  getStep(): number {
    const [firstEstimate, secondEstimate] = this.estimates;

    if (!this.estimates || this.estimates.length === 0) {
      return 0;
    }

    if (this.estimates.length === 1) {
      return firstEstimate.optionInvestmentAmount;
    }

    if (firstEstimate && secondEstimate) {
      return secondEstimate.getOptionInvestmentAmount() - firstEstimate.getOptionInvestmentAmount();
    }

    return 0;
  }

  hasMinEstimatesForUIControl(): boolean {
    const { min, max } = this.getMinMaxInvestmentEstimates();
    if (min && max) {
      return max >= min + this.getStep() * 2;
    }
    return false;
  }

  getMinMaxInvestmentEstimates(): { min?: number; max?: number } {
    if (!Array.isArray(this.estimates) || !this.estimates.length) {
      return {};
    }

    const firstEstimate = this.estimates[0];
    const lastEstimate = this.estimates[this.estimates.length - 1];
    return firstEstimate
      ? {
          min: firstEstimate.optionInvestmentAmount,
          max: lastEstimate.optionInvestmentAmount,
        }
      : {};
  }
}

export default PricingRangeModel;
