import React from 'react';
import { Container, DirectionAndPlacement, Header, Size } from '@pointdotcom/pds';
import navI18n from 'components/MainHeader/i18n';
import UnderwriteFollowUpDocumentViewer from 'components/UnderwriteFollowUpDocumentViewer';
import {
  EstimatorPageInnerContentStyle,
  HeroSectionLeftStyle,
  HeroSectionRightStyle,
} from 'containers/EstimatorPage/styles';
import { useFollowUpFetch } from 'containers/prequal/hooks';
import { HeroContainerStyle, SplashCopySerifStyle } from 'styles/';
import ClosingDisclosurePage from '..';
import i18n from './i18n';
import { DocumentsContentStyle } from './styles';

const ClosingDisclosureDocumentsPage = () => {
  const followUpFetchResults = useFollowUpFetch();
  const { followUp, followUpId } = followUpFetchResults;

  return (
    <ClosingDisclosurePage
      pageName={navI18n.aboutYourPoint}
      followUpFetchResults={followUpFetchResults}
    >
      <HeroContainerStyle styleSize={Size.Small}>
        <Container>
          <EstimatorPageInnerContentStyle>
            <HeroSectionLeftStyle>
              <Header
                styleSize={Size.Splash}
                styleAlign={DirectionAndPlacement.Left}
                styleAlignMobile={DirectionAndPlacement.Center}
              >
                {i18n.yourDocuments}
              </Header>
            </HeroSectionLeftStyle>
            <HeroSectionRightStyle>
              <SplashCopySerifStyle>
                <p>{i18n.yourClosingDisclosure}</p>
              </SplashCopySerifStyle>
            </HeroSectionRightStyle>
          </EstimatorPageInnerContentStyle>
        </Container>
      </HeroContainerStyle>
      <DocumentsContentStyle>
        <Container>
          <UnderwriteFollowUpDocumentViewer followUp={followUp} followUpId={followUpId} />
        </Container>
      </DocumentsContentStyle>
    </ClosingDisclosurePage>
  );
};

export default ClosingDisclosureDocumentsPage;
