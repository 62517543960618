export default {
  pageTitle: 'Point - Your Offer',
  goodNews: 'Good news, %name%!',
  yourePrequalifiedSingle: 'You’re prequalified for up to %amount% from Point',
  yourePrequalifiedMany:
    'You’re prequalified for up to %amount% from Point and you’ve got options.',
  seePricing: 'See pricing',
  reviewYourOffers: 'Review your offers',
  theAddressYouEntered: 'the address you entered is outside our %product% service area',
  notAvailable: 'Not available in your area',
};
