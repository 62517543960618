import styled from 'styled-components';
import { PaddedSectionStyle } from 'styles/';

const DocumentsContentStyle = styled(PaddedSectionStyle).attrs({
  className: 'DocumentsContentStyle',
})`
  padding-top: 0 !important;
`;

export { DocumentsContentStyle };
