import React from 'react';
import { TemplatedText } from '@pointdotcom/pds';
import { ButtonPostApplicationWithMessaging } from 'containers/EstimatorPage/BookingAndNavFooter';
import { SlideData } from '../constants';
import i18n from './i18n';
import * as styles from './styles';

export const getSlideContent = ({
  homeValue,
  homeValueRiskAdjusted,
  homeValueAppreciated,
  appreciatedDiff,
  appreciatedOwnerPays,
  selectedAmount,
  optionPercentage,
  totalOwnerPays,
  term,
  capApr,
  equivApr,
  estimate,
}: SlideData): JSX.Element[] => {
  const values = {
    homeValue,
    homeValueRiskAdjusted,
    homeValueAppreciated,
    appreciatedDiff,
    appreciatedOwnerPays,
    selectedAmount,
    optionPercentage,
    totalOwnerPays,
    term,
    capApr,
    equivApr,
    minus: () => <em key="minus">{i18n.minus}</em>,
    plus: () => <em key="plus">{i18n.plus}</em>,
    continueMyApplication: () => (
      <ButtonPostApplicationWithMessaging estimate={estimate} key="continueMyApplication" />
    ),
  };
  return [
    <styles.PaginatorItemStyle key="carouselSlide1">
      <p>
        <TemplatedText values={values}>{i18n.thePointHEI}</TemplatedText>
      </p>
    </styles.PaginatorItemStyle>,
    <styles.PaginatorItemStyle key="carouselSlide2">
      <p>{i18n.withNoMonthly}</p>
    </styles.PaginatorItemStyle>,
    <styles.PaginatorItemStyle key="carouselSlide3">
      <p>
        <TemplatedText values={values}>{i18n.insteadOf}</TemplatedText>
      </p>
    </styles.PaginatorItemStyle>,
    <styles.PaginatorItemStyle key="carouselSlide4">
      <p>{i18n.thereIsNo}</p>
    </styles.PaginatorItemStyle>,
    <styles.PaginatorItemStyle key="carouselSlide5">
      <p>
        <TemplatedText values={values}>{i18n.whenYouExit}</TemplatedText>
      </p>
    </styles.PaginatorItemStyle>,
    <styles.PaginatorItemStyle key="carouselSlide6">
      <p>
        <TemplatedText values={values}>{i18n.youIndicated}</TemplatedText>
      </p>
    </styles.PaginatorItemStyle>,
    <styles.PaginatorItemStyle key="carouselSlide7">
      <p>
        <TemplatedText values={values}>{i18n.whenYouExitIfYourHome}</TemplatedText>
      </p>
    </styles.PaginatorItemStyle>,
    <styles.PaginatorItemStyle key="carouselSlide8">
      <p>
        <TemplatedText values={values}>{i18n.ifYourHomeValueIsHigher}</TemplatedText>
      </p>
    </styles.PaginatorItemStyle>,
    <styles.PaginatorItemStyle key="carouselSlide9">
      <p>{i18n.importantlyYoullNever}</p>
    </styles.PaginatorItemStyle>,
    <styles.PaginatorItemStyle key="carouselSlide10">
      <p>
        <TemplatedText values={values}>{i18n.theHomeownerProtectionCap}</TemplatedText>
      </p>
    </styles.PaginatorItemStyle>,
    <styles.PaginatorItemStyle key="carouselSlide11">
      <p>
        <TemplatedText values={values}>{i18n.letsWorkThrough}</TemplatedText>
      </p>
    </styles.PaginatorItemStyle>,
    <styles.PaginatorItemStyle key="carouselSlide12">
      <p>
        <TemplatedText values={values}>{i18n.wedLookAtTheDifference}</TemplatedText>
      </p>
    </styles.PaginatorItemStyle>,
    <styles.PaginatorItemStyle key="carouselSlide13">
      <p>
        <TemplatedText values={values}>{i18n.addingTheOriginal}</TemplatedText>
      </p>
    </styles.PaginatorItemStyle>,
    <styles.PaginatorItemStyle key="carouselSlide14">
      <p>{i18n.thisIsJustAnExample}</p>
    </styles.PaginatorItemStyle>,
    <styles.PaginatorItemStyle key="carouselSlide15">
      <TemplatedText values={values}>{i18n.withAnHEI}</TemplatedText>
    </styles.PaginatorItemStyle>,
  ];
};
