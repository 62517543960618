import React from 'react';
import { DirectionAndPlacement } from '@pointdotcom/pds';
import { MagnifyGraphicProps } from './constants';
import * as styles from './styles';

export default function MagnifyGraphic({
  direction = DirectionAndPlacement.Right,
}: MagnifyGraphicProps) {
  return (
    <styles.MagnifyGraphicStyle direction={direction}>
      <styles.MagnifyContentStyle>
        <styles.MagnifyShineStyle />
      </styles.MagnifyContentStyle>
    </styles.MagnifyGraphicStyle>
  );
}
