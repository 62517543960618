export enum VideoButtonStyleType {
  Icon = 'icon',
  Mini = 'mini',
  Default = 'default',
}

export interface VideoButtonProps {
  styleType?: VideoButtonStyleType;
  label?: string;
  onClick?: () => void;
}
