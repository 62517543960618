import styled from 'styled-components';
import mountainImage from './images/mountains.png';

export const MountainGraphicStyle = styled.div.attrs({ className: 'MountainGraphicStyle' })`
  --mountainGraphicSize: 68%;
  background-image: url(${mountainImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  width: var(--mountainGraphicSize);
  padding-bottom: var(--mountainGraphicSize);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
`;
