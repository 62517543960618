import React, { useState } from 'react';
import {
  ButtonProps,
  Container,
  IconName,
  QueryComponent,
  SplashText,
  Style,
} from '@pointdotcom/pds';
import ButtonScheduleNextAvailable from 'components/ButtonScheduleNextAvailable';
import { YCBMCal } from 'components/ScheduleCalendar/constants';
import ScheduleCallOverlay from 'containers/hei/HeiOfferEstimatorPage/ScheduleCallOverlay';
import { useCalendar } from 'containers/prequal/hooks/useCalendar';
import OfferEstimateModel from 'models/OfferEstimateModel';
import { getApplicantModel } from 'store/applicant';
import { useSelector } from 'store/hooks';
import i18n from './i18n';
import * as styles from './styles';

interface FooterBookOrContinueProps {
  buttonProps?: Partial<ButtonProps>;
  estimate: null | OfferEstimateModel;
  calendar?: YCBMCal;
  children: React.ReactNode;
  team?: string;
}

function EmptyDescription() {
  return null;
}

type ShowScheduleOverlayOptions = Pick<
  React.ComponentProps<typeof ScheduleCallOverlay>,
  'showTimeSelectorOnly'
>;

export default function FooterBookOrContinue({
  buttonProps,
  estimate,
  calendar: calendarFromProps,
  children,
  team,
}: FooterBookOrContinueProps) {
  const { calendar: ycbmCalendar } = useCalendar({
    calendar: calendarFromProps,
    forStateAbbr: estimate?.property?.address?.state,
  });

  const contact = useSelector(getApplicantModel);
  const [showScheduleOverlay, setShowScheduleOverlay] = useState<
    boolean | ShowScheduleOverlayOptions
  >(false);

  if (!estimate) {
    return null;
  }

  return (
    <styles.FooterBookOrContinueStyle>
      <Container mobileColumn>
        <styles.SecondaryCTAContainerStyle data-content={i18n.or}>
          <styles.MessageAndButtonStyle>
            <SplashText italic noMargin>
              {i18n.stillHaveQuestions}
            </SplashText>
            <styles.SimplifyScheduleButtonStyle>
              <ButtonScheduleNextAvailable
                buttonProps={{
                  ...buttonProps,
                  styleType: Style.Tertiary,
                  iconType: IconName.ChevronRight,
                  onClick: () => setShowScheduleOverlay(true),
                  content: (
                    <QueryComponent
                      queriedComponent={i18n.scheduleACall}
                      mobileBreakpoint={'largestTabletScreen' as TSFixMe}
                    >
                      {i18n.scheduleACallWithUs}
                    </QueryComponent>
                  ),
                }}
                onDifferentTimeLinkClick={() =>
                  setShowScheduleOverlay({ showTimeSelectorOnly: true })
                }
                team={team}
                apiDomain={process.env.REACT_APP_MIDDLEWARE_API_ENDPOINT}
                calendar={ycbmCalendar}
                Description={EmptyDescription}
              />
            </styles.SimplifyScheduleButtonStyle>
          </styles.MessageAndButtonStyle>
          <ScheduleCallOverlay
            isOpen={showScheduleOverlay !== false}
            onModalClose={() => setShowScheduleOverlay(false)}
            estimate={estimate}
            applicant={contact}
            calendar={calendarFromProps}
            {...(typeof showScheduleOverlay === 'object' ? showScheduleOverlay : {})}
          />
        </styles.SecondaryCTAContainerStyle>
        <styles.PrimaryCTAContainerStyle>
          <styles.MessageAndButtonStyle>
            <SplashText italic noMargin>
              {i18n.readyToGetStarted}
            </SplashText>
            <div>{children}</div>
          </styles.MessageAndButtonStyle>
        </styles.PrimaryCTAContainerStyle>
      </Container>
    </styles.FooterBookOrContinueStyle>
  );
}
