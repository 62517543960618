import { useEffect, useMemo } from 'react';
import cloneDeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';
import { usePatchApplicationMutation } from 'services/api/homeownerApi';
import { useSelector } from 'store/hooks';
import { ApplicationDataType, getApplicationData } from 'store/productApplication';

const HOS_UPDATE_DELAY_MS = process.env.NODE_ENV === 'test' ? 100 : 2500;

/**
 * This hook monitors the application data store and will periodically send the
 * state to HOS so that the user's progress is saved on page reload or when they
 * return to continue filling out the application in a new session.
 */
export function useHomeownerApplicationDataSync() {
  const applicationState = useSelector(getApplicationData);
  const [patchApplication] = usePatchApplicationMutation();

  const debouncedPatchApplication = useMemo(
    () =>
      debounce(
        (applicationData: ApplicationDataType) => {
          // Remove senstive values before sending in-progress app data to HOS
          const inProgressData = cloneDeep(applicationData);
          delete inProgressData?.applicant?.ssn;
          delete inProgressData?.applicant?.ssnConfirm;

          patchApplication(inProgressData);
        },
        HOS_UPDATE_DELAY_MS,
        { trailing: true }
      ),
    [patchApplication]
  );

  useEffect(() => {
    if (Object.keys(applicationState).length > 0) {
      debouncedPatchApplication(applicationState);
    }
  }, [applicationState, debouncedPatchApplication]);
}
