import React from 'react';
import {
  BackButton,
  Button,
  Container,
  IconName,
  ResponsiveText,
  TemplatedText,
} from '@pointdotcom/pds';
import { getNavItemProp } from 'components/MainHeader/nav';
import { useHistory, useLocation } from 'containers/routerHelpers';
import i18n from './i18n';
import * as styles from './styles';

const FooterNav = ({
  navItems,
  navProps,
  styleType,
  styleAlign: styleAlignFromProps,
  bordered = true,
  weightedBottom,
  showPrevNext, // will automatically put a "next" button until on the last page of navItems. Last page will have "prev" button
  showBack = true,
  children,
  nextPrevItemText,
  nextPrevStyleType,
}) => {
  const location = useLocation();
  const history = useHistory();
  if (!navItems) {
    return null;
  }

  const getHref = getNavItemProp('href', navProps);
  const getText = getNavItemProp('text', navProps);
  const getFooterText = getNavItemProp('footerText', navProps);
  const currentPageIndex = navItems?.findIndex((item) => {
    const compareHref = getHref(item);
    return compareHref === location?.pathname;
  });
  const currentItem = navItems[currentPageIndex];
  const backItem = navItems[currentPageIndex - 1];
  const nextItem = navItems[currentPageIndex + 1];
  const handleBack = () => {
    const href = getHref(backItem);
    if (backItem.action) {
      backItem.action(navProps);
    } else if (href) {
      history.push({
        pathname: href,
        search: location.search,
      });
    }
  };

  const handleNext = () => {
    const href = getHref(nextItem);
    if (nextItem.action) {
      nextItem.action(navProps);
    } else if (href) {
      history.push({
        pathname: href,
        search: location.search,
      });
    }
  };

  let styleAlign = 'space-between';
  let mobileBlock = false;

  if (!showBack || !backItem) {
    styleAlign = styleAlignFromProps || 'flex-end';
    mobileBlock = true;
  }

  if (!showPrevNext && !showBack) {
    styleAlign = 'flex-end';
  }

  const nextText = getFooterText(currentItem) || getText(nextItem);

  return (
    <styles.FooterNavStyle
      styleAlign={styleAlign}
      weightedBottom={weightedBottom}
      mobileBlock={mobileBlock}
      bordered={bordered}
      data-testid="FooterNavStyle"
    >
      <Container>
        {backItem && showBack && (
          <BackButton
            onClick={handleBack}
            iconStyleAlign="left"
            iconType="chevronLeft"
            block={false}
            styleType={styleType === 'error' ? 'invertedError' : 'tertiary'}
            gaTrackingId={backItem.gaTrackingId}
          />
        )}
        <styles.ButtonGroupStyle styleAlign={styleAlign}>
          {nextItem && !showPrevNext && (
            <Button
              onClick={handleNext}
              mobileBlock={mobileBlock}
              styleType={styleType === 'error' ? 'invertedError' : 'primary'}
              {...currentItem?.footerButtonProps}
            >
              {currentItem?.footerTextMobile ? (
                <ResponsiveText mobileText={currentItem.footerTextMobile}>
                  {nextText}
                </ResponsiveText>
              ) : (
                nextText
              )}
            </Button>
          )}
          {children}
          {showPrevNext && (
            <Button
              styleType={nextPrevStyleType}
              iconType={nextItem ? IconName.ChevronRight : IconName.ChevronLeft}
              iconStyleAlign={nextItem ? 'right' : 'left'}
              onClick={nextItem ? handleNext : handleBack}
            >
              <ResponsiveText mobileText={nextItem ? i18n.next : i18n.prev}>
                <TemplatedText values={{ item: nextPrevItemText }}>
                  {nextItem ? i18n.viewNextItem : i18n.viewPreviousItem}
                </TemplatedText>
              </ResponsiveText>
            </Button>
          )}
        </styles.ButtonGroupStyle>
      </Container>
    </styles.FooterNavStyle>
  );
};

export default FooterNav;
