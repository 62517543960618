import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import smoothscroll from 'smoothscroll-polyfill';
import {
  CloseButton,
  Container,
  Icon,
  IconName,
  Key,
  eventHasKey,
  focusableSelector,
  handleModalFocus,
  mixins,
} from '@pointdotcom/pds';
import DotIndicators from 'components/DotIndicators';
import { Swipe } from 'components/ReactSwipe';
import i18n from './i18n';
import {
  CarouselItemStyle,
  CarouselLeftRightControlStyle,
  CarouselTopSectionStyle,
  FullScreenCarouselStyle,
} from './styles';
import { Direction, LineStyleType } from './types';

smoothscroll.polyfill();

type IndicatorProps = Omit<
  React.ComponentProps<typeof DotIndicators>,
  'items' | 'currentIndex' | 'onIndicatorClick'
>;

type DataAttributeProps = Record<`data-${string}`, string>;

export interface FullScreenCarouselChildProps<TItem extends { key: React.Key }> {
  item: TItem;
  handlePrevNext: (e: React.MouseEvent<HTMLElement>, swipe?: Direction) => void;
  isCurrent: boolean;
  showPrev: boolean;
  showNext: boolean;
}

interface FullScreenCarouselProps<TItem extends { key: React.Key }> {
  items: ReadonlyArray<TItem>;
  children: (props: FullScreenCarouselChildProps<TItem>) => React.ReactNode;
  footer?: React.ReactNode;
  show: boolean;
  style?: React.CSSProperties;
  banner?: React.ReactNode;
  bannerLineSeparationType?: null | LineStyleType;
  indicatorProps?: IndicatorProps;
  onClose?: () => unknown;
  dataAttributes?:
    | DataAttributeProps
    | ((currentItem: null | TItem) => undefined | null | DataAttributeProps);
}

interface CarouselLeftRightControlProps {
  position: Direction;
  color?: string;
  atFirstIndex?: boolean;
  atLastIndex?: boolean;
  onClick: React.MouseEventHandler;
}

const CarouselLeftRightControl = ({
  position,
  color,
  atFirstIndex = false,
  atLastIndex = false,
  onClick,
}: CarouselLeftRightControlProps) => (
  <CarouselLeftRightControlStyle
    position={position}
    atFirstIndex={atFirstIndex}
    atLastIndex={atLastIndex}
    onClick={onClick}
    data-action={position === Direction.Left ? 'prev' : 'next'}
    aria-label={position === Direction.Left ? i18n.previous : i18n.next}
  >
    <Icon
      color={color}
      name={position === Direction.Left ? IconName.ChevronLeft : IconName.ChevronRight}
    />
  </CarouselLeftRightControlStyle>
);

export const CarouselItem = (props: React.ComponentProps<typeof CarouselItemStyle>) => (
  /* eslint-disable react/destructuring-assignment */
  <CarouselItemStyle {...props} data-left={props.offsetLeft} data-right={props.offsetRight}>
    {props.children}
  </CarouselItemStyle>
  /* eslint-enable react/destructuring-assignment */
);

export default function FullScreenCarousel<TItem extends { key: React.Key }>({
  items,
  children,
  footer = null,
  show,
  style,
  banner = null,
  bannerLineSeparationType = null,
  indicatorProps = {},
  dataAttributes,
  onClose,
}: FullScreenCarouselProps<TItem>) {
  const targetRef = useRef<null | HTMLDivElement>(null);
  const [currentDirection, setCurrentDirection] = useState<null | Direction>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleFocus = (e?: KeyboardEvent) => {
    const thisElm = targetRef.current;
    if (!thisElm) {
      return;
    }
    const elementContext = thisElm.querySelector('.CarouselItemStyle.active');
    const itemElements = elementContext?.querySelectorAll(focusableSelector) || [];
    const controlElements =
      thisElm.querySelectorAll('.CarouselIndicatorItemStyle, .CloseButtonStyle') || [];
    handleModalFocus(e, {
      elementContext: undefined,
      focusableElements: [...itemElements, ...controlElements] as HTMLElement[],
    });
  };

  const handleClose = useCallback(
    (e?: KeyboardEvent | React.MouseEvent) => {
      e?.stopPropagation();
      setCurrentIndex(0);
      onClose?.();
    },
    [onClose]
  );

  const handlePrevNext = useCallback(
    (e: undefined | KeyboardEvent | React.MouseEvent, swipe?: Direction) => {
      let newCurrentIndex = currentIndex;
      let newDirection = currentDirection;
      const action =
        e && e.target instanceof Element
          ? e.target.closest('[data-action]')?.getAttribute('data-action')
          : null;
      const goingLeft = action === 'prev' || swipe === Direction.Left;
      const goingRight = action === 'next' || swipe === Direction.Right;

      if (goingLeft && currentIndex > 0) {
        newCurrentIndex -= 1;
        newDirection = Direction.Left;
      } else if (goingRight && currentIndex < items.length - 1) {
        newCurrentIndex += 1;
        newDirection = Direction.Right;
      } else if (action === 'close') {
        handleClose(e);
        return;
      }

      setCurrentIndex(newCurrentIndex);
      setCurrentDirection(newDirection);
      try {
        targetRef.current?.scrollTo({
          top: 0,
          behavior: 'smooth', // Optional, adds animation
        });
      } catch (error) {
        if (targetRef.current?.scrollTo) {
          targetRef.current?.scrollTo(0, 0);
        }
      }
    },
    [currentDirection, currentIndex, handleClose, items.length]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!e) {
        return;
      }

      if (eventHasKey(e, Key.Esc) && show) {
        handleClose(e);
      }

      if (eventHasKey(e, Key.Tab)) {
        handleFocus(e);
      }

      if (eventHasKey(e, Key.Right)) {
        handlePrevNext(undefined, Direction.Right);
      }

      if (eventHasKey(e, Key.Left)) {
        handlePrevNext(undefined, Direction.Left);
      }
    },
    [handleClose, handlePrevNext, show]
  );

  useLayoutEffect(() => {
    const target = targetRef.current;
    if (target != null) {
      target.addEventListener('keydown', handleKeyDown, false);
      return () => target.removeEventListener('keydown', handleKeyDown, false);
    }
    return undefined;
  }, [handleKeyDown]);

  useEffect(() => {
    handleFocus();
  }, []);

  const handleCarouselIndicatorClick = (e: unknown, { index }: { index: number }) => {
    const newDirection = index > currentIndex ? Direction.Right : Direction.Left;
    setCurrentIndex(index);
    setCurrentDirection(newDirection);
  };

  const handleSwipe = ({ direction }: { direction: Direction }) => {
    handlePrevNext(undefined, direction);
  };

  const styleNew = { ...style };
  const currentItem = items[currentIndex] || null;

  return (
    <Swipe
      stopPropagation
      preventDefault /* ??? */
      onSwipedLeft={() => {
        handleSwipe({ direction: Direction.Right });
      }}
      onSwipedRight={() => {
        handleSwipe({ direction: Direction.Left });
      }}
    >
      <FullScreenCarouselStyle
        show={show}
        style={styleNew}
        ref={targetRef}
        role="dialog"
        {...(typeof dataAttributes === 'function' ? dataAttributes(currentItem) : dataAttributes)}
      >
        {show && (
          <>
            <CarouselLeftRightControl
              color={styleNew.backgroundColor}
              position={Direction.Right}
              atLastIndex={currentIndex === items.length - 1}
              onClick={handlePrevNext}
            />
            <CarouselLeftRightControl
              color={styleNew.backgroundColor}
              position={Direction.Left}
              atFirstIndex={currentIndex === 0}
              onClick={handlePrevNext}
            />
          </>
        )}
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        {show ? <mixins.BodyForFixedStyle onlyMobileFixed={false} /> : null}
        {banner}
        <CarouselTopSectionStyle bannerLineSeparationType={bannerLineSeparationType}>
          <div>
            <CloseButton onClick={() => handleClose()} inverted />
          </div>
          <div>
            <DotIndicators
              {...indicatorProps}
              items={items}
              currentIndex={currentIndex}
              onIndicatorClick={handleCarouselIndicatorClick}
            />
          </div>
        </CarouselTopSectionStyle>
        <Container>
          {items.map((item, i) => {
            const isCurrent = i === currentIndex;
            const itemChild = children({
              item,
              isCurrent,
              showPrev: currentIndex > 0,
              showNext: currentIndex < items.length - 1,
              handlePrevNext,
            });
            return (
              <Transition timeout={400} key={item.key || i} in={isCurrent}>
                {(status) => (
                  <CarouselItem
                    data-testid={`carouselItem${i + 1}`}
                    animationStatus={status}
                    direction={currentDirection}
                    offsetRight={i > currentIndex}
                    offsetLeft={i < currentIndex}
                    className={isCurrent ? 'active' : undefined}
                  >
                    {itemChild}
                  </CarouselItem>
                )}
              </Transition>
            );
          })}
        </Container>
        {footer}
      </FullScreenCarouselStyle>
    </Swipe>
  );
}
