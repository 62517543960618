import * as React from 'react';
import { useRef } from 'react';
import { captureProperties, usePostHogEvents } from 'lib/posthogEvents';

export enum Workflow {
  Application = 'Application',
  ClosingDisclosureFlow = 'Closing Disclosure Flow',
  Dashboard = 'Dashboard',
  Day1Estimate = 'Day 1 Estimate',
  Prequal = 'Prequal',
  PrequalOffer = 'Prequal Offer',
  ProductVideoAndQuiz = 'Product Video & Quiz',
}

function applyAttributes(
  element: null | undefined | Element,
  attributes: Record<string, string>
): void | (() => void) {
  if (element == null) {
    return;
  }

  const filteredAttributes = Object.entries(attributes).filter(
    ([attributeName]) => !element.hasAttribute(attributeName)
  );

  for (const [attributeName, value] of filteredAttributes) {
    element.setAttribute(attributeName, value);
  }

  return () => {
    for (const [attributeName] of filteredAttributes) {
      element.removeAttribute(attributeName);
    }
  };
}

interface TagPageProps {
  page?: string;
  workflow?: Workflow;
}

export function TagPage({ page, workflow }: TagPageProps) {
  React.useLayoutEffect(() => {
    return applyAttributes(document.body, captureProperties({ page, workflow }));
  }, [page, workflow]);

  return <span style={{ display: 'none' }} />;
}

interface TagModalProps {
  overlay: string;
}

export function TagModal({ overlay }: TagModalProps) {
  const ref = useRef<HTMLElement>(null);
  const posthogEvents = usePostHogEvents();

  React.useLayoutEffect(() => {
    const element = ref.current;
    if (element == null) {
      return;
    }

    const parentModal = element.closest('[role="dialog"]');
    const unmountFn = applyAttributes(parentModal, captureProperties({ overlay }));

    posthogEvents.capture('modal opened', null, { element });

    return () => {
      posthogEvents.capture('modal closed', null, { element });
      unmountFn?.();
    };
  }, [overlay, posthogEvents]);

  return <span ref={ref} style={{ display: 'none' }} />;
}
