import React, { useState } from 'react';
import classnames from 'classnames';
import { Waypoint } from 'react-waypoint';
import {
  Container,
  DirectionAndPlacement,
  Header,
  Icon,
  IconName,
  ResponsiveText,
  Size,
} from '@pointdotcom/pds';
import i18n from './i18n';
import * as styles from './styles';

const PriceTableItem = ({ selected = false } = {}) => (
  <li className={classnames(['PriceTableItem'], { selected })}>
    <Icon name={IconName.Check} color="#FFFFFF" />
  </li>
);

const PriceTable = ({ children }) => (
  <styles.PriceTableStyle>
    <styles.LabelColumnStyle>
      <styles.PriceTableHeaderStyle />
      <ul>
        <li>{i18n.noMonthly}</li>
        <li>{i18n.noLate}</li>
        <li>{i18n.noPrepayment}</li>
        <li>{i18n.noAge}</li>
      </ul>
    </styles.LabelColumnStyle>
    <styles.PointColumnStyle>
      <styles.PriceTableHeaderStyle>
        <Header
          styleSize={Size.Small}
          styleAlign={DirectionAndPlacement.Center}
          styleAlignMobile={DirectionAndPlacement.Center}
          noMargin
        >
          {i18n.pointInvestment}
        </Header>
      </styles.PriceTableHeaderStyle>
      <ul>
        <PriceTableItem selected />
        <PriceTableItem selected />
        <PriceTableItem selected />
        <PriceTableItem selected />
      </ul>
      {children}
    </styles.PointColumnStyle>
    <styles.LoansColumnStyle>
      <styles.PriceTableHeaderStyle>
        <Header
          styleSize={Size.Small}
          styleAlign={DirectionAndPlacement.Center}
          styleAlignMobile={DirectionAndPlacement.Center}
          noMargin
        >
          {i18n.mostLoans}
        </Header>
      </styles.PriceTableHeaderStyle>
      <ul>
        <PriceTableItem />
        <PriceTableItem />
        <PriceTableItem />
        <PriceTableItem selected />
      </ul>
    </styles.LoansColumnStyle>
  </styles.PriceTableStyle>
);

const PointComparisonTableSection = ({ children }) => {
  const [wayPointPriceTableIn, setWayPointPriceTableIn] = useState(false);
  return (
    <styles.PointComparisonTableSectionStyle>
      <Container>
        <Header
          styleSize={Size.Large}
          styleAlign={DirectionAndPlacement.Left}
          styleAlignMobile={DirectionAndPlacement.Left}
          maxWidth="370px"
          noMargin
        >
          {i18n.howDoes}
        </Header>
      </Container>
      <Waypoint onEnter={() => setWayPointPriceTableIn(true)} bottomOffset="30%">
        <styles.PriceTableContainerStyle
          styleSize={Size.Default}
          className={classnames({ entered: wayPointPriceTableIn })}
        >
          <PriceTable>{children}</PriceTable>
        </styles.PriceTableContainerStyle>
      </Waypoint>
      <ResponsiveText mobileText={<Container>{children}</Container>} />
    </styles.PointComparisonTableSectionStyle>
  );
};

export default PointComparisonTableSection;
