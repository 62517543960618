import React from 'react';
import {
  AccordionItem,
  AccordionItemObj,
  DirectionAndPlacement,
  IconName,
  Style,
} from '@pointdotcom/pds';
import { Table2ColProps } from './constants';
import * as styles from './styles';

const TableTitle = ({ label, value }: { label: React.ReactNode; value: React.ReactNode }) => {
  return (
    <styles.Table2ColTitleStyle>
      <styles.Table2ColLabelStyle>{label}</styles.Table2ColLabelStyle>
      <styles.Table2ColValueStyle>{value}</styles.Table2ColValueStyle>
    </styles.Table2ColTitleStyle>
  );
};

export default function Table2Col({
  labels,
  values,
  foldingContent,
  hasStripedRows = true,
  isBordered = true,
  isInnerBordered = true,
  styleType = Style.Default,
  isStaticUntilMobile,
}: Table2ColProps) {
  const hasFoldingContent = foldingContent && foldingContent.length > 0;
  return (
    <styles.Table2ColStyle
      iconStyleAlign={DirectionAndPlacement.Right}
      iconType={IconName.ChevronUp}
      isPadded
      styleType={styleType}
      isBordered={hasFoldingContent && isBordered}
      isInnerBordered={hasFoldingContent && isInnerBordered}
      isStaticUntilMobile={isStaticUntilMobile}
    >
      {labels.map((label, idx) => {
        const title = <TableTitle label={label} value={values[idx]} />;
        const id = `table2ColItem${idx}`;
        if (hasFoldingContent) {
          const item: AccordionItemObj = {
            id,
            title,
            content: (
              <styles.Table2ColContentWrapperStyle>
                {foldingContent[idx]}
              </styles.Table2ColContentWrapperStyle>
            ),
          };
          return (
            <styles.Table2ColItemStyle
              as={AccordionItem}
              {...item}
              key={id}
              hasStripedRows={hasStripedRows}
            />
          );
        }

        return (
          <styles.Table2ColItemStyle key={id} hasStripedRows={hasStripedRows}>
            {title}
          </styles.Table2ColItemStyle>
        );
      })}
    </styles.Table2ColStyle>
  );
}
