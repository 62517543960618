// both used for determining which landing page to show
export enum URLParms {
  Style = 'style', // marketing can set this manually
}

export enum LayoutStyles {
  BasicWhite = 'basicWhite',
  LockKey = 'lockKey',
}

export const HELOC_PRIME_RATE_PERCENT = process.env.REACT_APP_PRIME_RATE_PCT || `7.00`;
export const HELOC_PRIME_RATE_EFFECTIVE_DATE =
  process.env.REACT_APP_PRIME_RATE_EFF_DATE || `12/1/2022`;
