import styled from 'styled-components';
import { HeaderStyles, LogoStyles } from '@pointdotcom/pds';

export const SchedulePageStyle = styled.div.attrs({ className: 'SchedulePageStyle' })`
  background: white;
  text-align: center;
  ${LogoStyles.LogoStyle} {
    margin-bottom: 5vh;
  }
  ${HeaderStyles.HeaderStyle} {
    &.medium {
      color: ${(props) => props.theme.Color.Gray4};
    }
  }
`;
