import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import * as styles from './styles';

const bgImgAvif = 'https://cdn.point.com/landingPage/bg.avif';
const bgImgWebP = 'https://cdn.point.com/landingPage/bg.webp';
const birds = 'https://cdn.point.com/landingPage/birds.gif';
const cloudImgAvif = 'https://cdn.point.com/landingPage/cloud.avif';
const cloudImgWebP = 'https://cdn.point.com/landingPage/cloud.webp';
const keyImgAvif = 'https://cdn.point.com/landingPage/key.avif';
const keyImgWebP = 'https://cdn.point.com/landingPage/key.webp';
const lghouseImgAvif = 'https://cdn.point.com/landingPage/lghouse.avif';
const lghouseImgWebP = 'https://cdn.point.com/landingPage/lghouse.webp';
const lghouseMImgAvif = 'https://cdn.point.com/landingPage/lghouse_m.avif';
const lghouseMImg = 'https://cdn.point.com/landingPage/lghouse_m.png';
const lockImgAvif = 'https://cdn.point.com/landingPage/lock.avif';
const lockImgWebP = 'https://cdn.point.com/landingPage/lock.webp';
const maskImgAvif = 'https://cdn.point.com/landingPage/mask.avif';
const maskImg = 'https://cdn.point.com/landingPage/mask.png';
const maskMImgAvif = 'https://cdn.point.com/landingPage/mask_m.avif';
const maskMImg = 'https://cdn.point.com/landingPage/mask_m.png';
const mountainsImgAvif = 'https://cdn.point.com/landingPage/mountains.avif';
const mountainsImgWebP = 'https://cdn.point.com/landingPage/mountains.webp';
const mountainsMImgAvif = 'https://cdn.point.com/landingPage/mountains_m.avif';
const mountainsMImg = 'https://cdn.point.com/landingPage/mountains_m.png';
const smhouseImgAvif = 'https://cdn.point.com/landingPage/smhouse.avif';
const smhouseImgWebP = 'https://cdn.point.com/landingPage/smhouse.webp';
const stack1ImgAvif = 'https://cdn.point.com/landingPage/stack1.avif';
const stack1ImgWebP = 'https://cdn.point.com/landingPage/stack1.webp';
const stack2ImgAvif = 'https://cdn.point.com/landingPage/stack2.avif';
const stack2ImgWebP = 'https://cdn.point.com/landingPage/stack2.webp';
const sunImgAvif = 'https://cdn.point.com/landingPage/sun.avif';
const sunImgWebP = 'https://cdn.point.com/landingPage/sun.webp';
const treeImgAvif = 'https://cdn.point.com/landingPage/tree.avif';
const treeImgWebP = 'https://cdn.point.com/landingPage/tree.webp';

const Birds = () => {
  const [play, setPlay] = useState(false);
  const ANIM_DUR = 8200;
  useEffect(() => {
    const to = setTimeout(() => {
      setPlay(!play);
    }, ANIM_DUR);
    return () => {
      clearTimeout(to);
    };
  }, [play]);

  return play === true ? <img src={birds} alt="birds" className="birds" /> : null;
};

const Graphic = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setMounted(true), 500);
    return () => {
      clearTimeout(timeout);
      setMounted(false);
    };
  }, []);

  return (
    <CSSTransition in={mounted} timeout={1000}>
      <styles.GraphicStyle>
        <div className="mainWrap">
          <picture>
            <source type="image/avif" srcSet={bgImgAvif} />
            <img
              src={bgImgWebP}
              alt="House background"
              className="bg"
              width="769px"
              height="228px"
            />
          </picture>

          <picture>
            <source type="image/avif" srcSet={cloudImgAvif} />
            <img src={cloudImgWebP} alt="Cloud" className="cloud" width="118px" height="33px" />
          </picture>
          <div className="overflowWrap">
            <picture>
              <source type="image/avif" srcSet={treeImgAvif} />
              <img src={treeImgWebP} alt="Tree" className="tree" width="92px" height="290px" />
            </picture>
            <picture>
              <source type="image/avif" srcSet={mountainsImgAvif} />
              <img
                src={mountainsImgWebP}
                alt="Mountains"
                className="mountains"
                width="1538px"
                height="493px"
              />
            </picture>
            <picture>
              <source type="image/avif" srcSet={mountainsMImgAvif} />
              <img
                src={mountainsMImg}
                alt="Mountains"
                className="mountainsM"
                width="484px"
                height="162px"
              />
            </picture>
            <picture>
              <source type="image/avif" srcSet={smhouseImgAvif} />
              <img
                src={smhouseImgWebP}
                alt="Small house"
                className="smhouse"
                width="73px"
                height="110px"
              />
            </picture>

            <picture>
              <source type="image/avif" srcSet={stack1ImgAvif} />
              <img
                src={stack1ImgWebP}
                alt="Coin Stack"
                className="stack1"
                width="42px"
                height="120px"
              />
            </picture>

            <picture>
              <source type="image/avif" srcSet={stack2ImgAvif} />
              <img
                src={stack2ImgWebP}
                alt="Coin Stack"
                className="stack2"
                width="142px"
                height="120px"
              />
            </picture>

            <picture>
              <source type="image/avif" srcSet={lghouseImgAvif} />
              <img
                alt="House"
                src={lghouseImgWebP}
                className="lghouse"
                width="140px"
                height="257px"
              />
            </picture>

            <Birds />
          </div>

          <picture>
            <source type="image/avif" srcSet={lghouseMImgAvif} />
            <img alt="House" src={lghouseMImg} width="127px" height="234px" className="lghouseM" />
          </picture>

          <picture>
            <source type="image/avif" srcSet={maskImgAvif} />
            <img src={maskImg} alt="Mask" className="mask" width="769px" height="191px" />
          </picture>

          <picture>
            <source type="image/avif" srcSet={maskMImgAvif} />
            <img src={maskMImg} alt="mask" className="maskM" width="562px" height="185px" />
          </picture>

          <picture>
            <source type="image/avif" srcSet={sunImgAvif} />
            <img src={sunImgWebP} alt="sun" className="sun" width="229px" height="229px" />
          </picture>
        </div>
        <picture>
          <source type="image/avif" srcSet={lockImgAvif} />
          <img src={lockImgWebP} alt="Lock" className="lock" width="40px" height="62px" />
        </picture>
        <div className="keyWrap">
          <picture>
            <source type="image/avif" srcSet={keyImgAvif} />
            <img src={keyImgWebP} alt="Key" className="key" width="95px" height="176px" />
          </picture>
          <i className="shine l" />
          <i className="shine t" />
          <i className="shine r" />
        </div>
      </styles.GraphicStyle>
    </CSSTransition>
  );
};

export default Graphic;
