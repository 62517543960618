export default {
  applicationReceived: 'Application received!',
  congrats: 'Congrats',
  congratsWithName: 'Congrats, %name%',
  currentOffer: 'Your current offer is for %offerAmount%!',
  pageTitleTemplate: 'Point HEI - Application: %page%',
  resumeApplication: 'Resume application',
  itLooksLike:
    'It looks like there is already an application in progress for your property. Let’s get you logged in so you can continue from there.',
  proceedTo: 'Proceed to login',
};
