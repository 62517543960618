export default {
  birthdate: 'Birthdate',
  month: 'Month',
  date: 'Date',
  year: 'Year',

  citizenshipStatus: 'Citizenship status',
  whatIsYourPrimary: 'What is your primary employment status?',
  howDidYou: 'How did you hear about us?',
  chooseOne: 'Choose one',

  areYouCurrently:
    'Are you currently legally married or in a legally recognized domestic partnership?',
  noteIfYouAre:
    'NOTE: If you are legally married or in a legally recognized domestic partnership then your spouse or partner will need to sign your Point closing documents.',
  isYourSpouse: 'Is your spouse on the title of the property?',

  legalName: 'Legal name',
  firstName: 'First name',
  middleName: 'Middle name',
  lastName: 'Last name',
  email: 'Email',
  phoneNumber: 'Phone number',
  errorDuplicateEmail: 'Spouse email cannot match primary applicant email',
};
