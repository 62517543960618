export default {
  above: 'above',
  aboveAmount: '%above% %amount%',
  aTimeBased: 'A time-based, max amount designed to protect you if your home appreciates a lot',
  close: 'Close',
  howPointDetermines: 'How Point determines pricing',
  learnHow: 'Learn how Point determines pricing',
  ofYourHome: 'of your home’s appreciation',
  percOfYourHome: '%perc% %ofYourHome% %aboveAmount%',
  plus: 'plus',
  plusTheLower: 'plus the lower of',
  pricingIsBased:
    'Pricing is based on your estimated current home value, the equity you have in your home, your credit profile, and the amount of money youʼd like. The pricing displayed here is a preliminary estimate based on the information you have provided.',
  seeMyCap: 'See my cap by year',
  learnMore: 'Learn more',
  theHomeowner: 'The Homeowner Protection Cap',
  theLowerOf: 'the lower of',
  theOriginal: 'The original amount',
  whenYou: 'When you exit your contract, youʼll pay to Point:',
};
