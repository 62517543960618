import * as React from 'react';
import * as styles from './iconStyles';

export function FileIcon({
  uploadProgress = 1,
  hasError = false,
}: {
  uploadProgress?: number;
  hasError?: boolean;
}) {
  // Note that path is duplicated so that one can be cross faded (using uploadProgress)
  return (
    <styles.FileIconStyle
      width="20"
      height="25"
      viewBox="0 0 20 25"
      uploadProgress={uploadProgress}
      hasError={hasError}
    >
      <path
        className="fileUploadNotStartedPath"
        d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H12.4782C12.8878 0.5 13.2796 0.66754 13.5626 0.963713L19.0845 6.74239C19.3512 7.02148 19.5 7.39265 19.5 7.77867V23C19.5 23.8284 18.8284 24.5 18 24.5H2C1.17157 24.5 0.5 23.8284 0.5 23V2Z"
      />
      <path
        className="fileUploadEndedPath"
        d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H12.4782C12.8878 0.5 13.2796 0.66754 13.5626 0.963713L19.0845 6.74239C19.3512 7.02148 19.5 7.39265 19.5 7.77867V23C19.5 23.8284 18.8284 24.5 18 24.5H2C1.17157 24.5 0.5 23.8284 0.5 23V2Z"
      />
      <path d="M13 1V8H19.5" stroke="white" fill="none" />
    </styles.FileIconStyle>
  );
}

export function TrashCanIcon({
  hasError = false,
  deleting = false,
}: {
  hasError?: boolean;
  deleting?: boolean;
}) {
  return (
    <styles.TrashCanIconStyle
      hasError={hasError}
      deleting={deleting}
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
    >
      <path d="M14 4L6 4L6 3C6 1.89543 6.89543 1 8 1L12 1C13.1046 1 14 1.89543 14 3L14 4Z" />
      <path d="M3 4H17V20C17 21.1046 16.1046 22 15 22H5C3.89543 22 3 21.1046 3 20V4Z" />
      <path d="M19 4L1 4" strokeLinecap="round" />
      <path d="M8 8V17.5" strokeLinecap="round" />
      <path d="M12 8V17.5" strokeLinecap="round" />
    </styles.TrashCanIconStyle>
  );
}
