import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { Waypoint } from 'react-waypoint';
import { ThemeContext } from 'styled-components';
import {
  Container,
  DirectionAndPlacement,
  Graphics,
  Header,
  Icon,
  IconName,
  Size,
  SplashText,
  TemplatedText,
} from '@pointdotcom/pds';
import i18n from './i18n';
import * as styles from './styles';

const { PointBill, PieSlice, MoneyInFrontOfHouse } = Graphics;

const PointExplained3ImgsSection = ({ estimate }) => {
  const theme = useContext(ThemeContext);
  const [wayPointIllysIn, setWayPointIllysIn] = useState(false);
  const [wayPointCalIconIn, setWayPointCalIconIn] = useState(false);

  return (
    <styles.PointExplained3ImgsSectionStyle>
      <Container>
        <Header
          styleSize={Size.Large}
          styleAlign={DirectionAndPlacement.Center}
          styleAlignMobile={DirectionAndPlacement.Left}
          maxWidth="700px"
        >
          {i18n.maybeYou}
        </Header>
        <SplashText
          styleAlign={DirectionAndPlacement.Center}
          styleAlignMobile={DirectionAndPlacement.Left}
        >
          {i18n.itsCalled}
        </SplashText>
      </Container>
      <styles.ItemCalloutContainerStyle styleSize={Size.Default}>
        <Waypoint onEnter={() => setWayPointIllysIn(true)} bottomOffset="20%">
          <styles.IllustratedCalloutsContainerStyle
            className={classnames({ entered: wayPointIllysIn })}
          >
            <div>
              <MoneyInFrontOfHouse />
              <styles.CalloutTextStyle>{i18n.weInvest}</styles.CalloutTextStyle>
            </div>
            <div>
              <PieSlice />
              <styles.CalloutTextStyle>
                <TemplatedText values={{ term: estimate?.getFormattedTerm() }}>
                  {i18n.whenYou}
                </TemplatedText>
              </styles.CalloutTextStyle>
            </div>
            <div>
              <PointBill />
              <styles.CalloutTextStyle>{i18n.yourCost}</styles.CalloutTextStyle>
            </div>
          </styles.IllustratedCalloutsContainerStyle>
        </Waypoint>
      </styles.ItemCalloutContainerStyle>
      <Container>
        <Waypoint onEnter={() => setWayPointCalIconIn(true)} bottomOffset="30%">
          <styles.RepayContainerStyle className={classnames({ entered: wayPointCalIconIn })}>
            <Icon
              name={IconName.AccentCalendar}
              color={theme.Color.PointBlack}
              accentColor={theme.Color.Gray1}
            />
            <Header
              styleSize={Size.Medium}
              styleAlign={DirectionAndPlacement.Center}
              styleAlignMobile={DirectionAndPlacement.Left}
              maxWidth="700px"
            >
              {i18n.repayWhen}
            </Header>
            <SplashText>
              <TemplatedText values={{ term: estimate?.getFormattedTerm({ singular: true }) }}>
                {i18n.pointTies}
              </TemplatedText>
            </SplashText>
          </styles.RepayContainerStyle>
        </Waypoint>
      </Container>
    </styles.PointExplained3ImgsSectionStyle>
  );
};

export default PointExplained3ImgsSection;
