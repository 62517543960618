import * as React from 'react';
import { Button, ButtonPropsAsButton } from '@pointdotcom/pds';
import FileInput from 'components/FileInput';
import i18n from './i18n';

type BrowseFilesButtonProps = Omit<ButtonPropsAsButton, 'onClick' | 'children'> &
  Pick<React.ComponentProps<typeof FileInput>, 'onAddFiles' | 'contentTypes'> & {
    children?: React.ReactNode;
  };

export default function BrowseFilesButton({
  onAddFiles,
  children = i18n.browse,
  contentTypes,
  ...buttonProps
}: BrowseFilesButtonProps) {
  const fileInputRef = React.useRef<null | React.ComponentRef<typeof FileInput>>(null);

  return (
    <>
      <FileInput ref={fileInputRef} multiple contentTypes={contentTypes} onAddFiles={onAddFiles} />
      <Button {...buttonProps} onClick={() => fileInputRef.current?.open()}>
        {children}
      </Button>
    </>
  );
}
