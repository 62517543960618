import styled, { keyframes } from 'styled-components';
import shineImage from '../images/shine.png';
import hillImage from './images/hill.png';
import tandemBikeImage from './images/tandembike.png';
import tandemBikeMobileImage from './images/tandembikemobile.png';

export const pulse = keyframes`
  0%, 100% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
`;

const twinkle = keyframes`
  0%, 100% {
    opacity: 0.9;
  }
  50% {
    opacity: 1;
    
  }
`;

export const TandemBikeGraphicStyle = styled.div.attrs({ className: 'TandemBikeGraphicStyle' })`
  --hillSize: 75%;
  --tandemBikeSize: 40%;
  --shineImageSize: 20%;

  background-image: url('${hillImage}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: absolute;
  bottom: 0;
  left: 45%;
  width: var(--hillSize);
  padding-bottom: var(--hillSize);
  transform: translateX(-50%);
  &:after {
    content: '';
    display: block;
    background-image: url('${shineImage}');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: var(--shineImageSize);
    padding-bottom: var(--shineImageSize);
    position: absolute;
    left: -5%;
    top: 40%;
    animation:
      ${pulse} 3s infinite ease-in-out,
      ${twinkle} 2s infinite ease-in-out;
  }
  &:before {
    content: '';
    display: block;
    background-image: url('${tandemBikeImage}');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    position: absolute;
    width: var(--tandemBikeSize);
    padding-bottom: var(--tandemBikeSize);
    bottom: 5%;
    left: -3%;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    &:before {
      background-image: url('${tandemBikeMobileImage}');
      left: 90%;
      bottom: 0;
    }
  }
`;
export const ButterflyCanvasStyle = styled.canvas.attrs({ className: 'ButterflyCanvasStyle' })`
  display: block;
  position: absolute;
  bottom: 0;
  left: 23%;
  width: 60%;
  height: 50%;
  opacity: 0.5;
  z-index: -1;
`;
