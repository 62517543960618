import * as z from "zod";

export const hosPresignedPostSchema = z
  .object({
    key: z.string().uuid(),
    url: z.string().url(),
    fields: z.record(z.string(), z.string()),
  })
  .catchall(z.unknown() as z.ZodType);
export type HosPresignedPost = z.infer<typeof hosPresignedPostSchema>;
