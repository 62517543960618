import { darken } from 'polished';
import styled from 'styled-components';
import { ButtonStyles, ContainerStyles } from '@pointdotcom/pds';

export const BannerMessageStyle = styled.div.attrs({ className: 'BannerMessageStyle' })`
  background: ${(props) =>
    props.blueTheme ? darken(0.05, props.theme.Color.PurpleBlue6) : props.theme.Color.PointBlack};
  color: white;
  position: relative;
  z-index: 2;
  .text {
    font-family: ${(props) => props.theme.fonts.sansSerif.name};
    font-weight: bold;
    line-height: 1.5em;
    padding-right: 15px;
    box-sizing: border-box;
  }
  ${ContainerStyles.ContainerStyle} {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 105px;
  }
  ${ButtonStyles.ButtonBaseStyle} {
    border: 1px solid ${(props) => props.theme.line.color.invertedSubtle};
    background: transparent;
    color: white;
    white-space: nowrap;
    &:hover {
      background: ${(props) =>
        props.blueTheme
          ? darken(0.07, props.theme.Color.PurpleBlue6)
          : darken(0.02, props.theme.Color.PointBlack)};
      border-color: transparent;
    }
  }
`;
