import React, { useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';
import ReactDOM from 'react-dom';
import { useMergeRefs } from 'rooks';
import { DirectionAndPlacement } from '@pointdotcom/pds';
import * as styles from './styles';

// TODO: add animations

interface TooltipProps extends styles.TooltipStyleProps {
  children: React.ReactNode;
  positionRef?: React.RefObject<HTMLElement>;
  positionElement?: HTMLElement;
  onClose?: () => void;
  autoPosition?: boolean; // if the tooltip flows off the edge of the screen, try another position
  id?: string;
}

const Tooltip = React.forwardRef<HTMLSpanElement, TooltipProps>(
  (
    {
      children,
      id,
      isOpen = false,
      positionRef,
      positionElement,
      onClose,
      autoPosition = true,
      inline = false,
      xPos: xPosFromProps = DirectionAndPlacement.Center,
      yPos: yPosFromProps = DirectionAndPlacement.Top,
      yMargin,
      maxWidth,
    },
    ref
  ) => {
    const tooltipRef = useRef<HTMLSpanElement>(null);
    const [position, setPosition] = useState<{ left: number; top: number }>({ left: 0, top: 0 });
    const [xPos, setXPos] = useState(xPosFromProps);
    const [yPos, setYPos] = useState(yPosFromProps);
    const positionedElement = positionElement || positionRef?.current;

    const mergedRef = useMergeRefs(ref, tooltipRef);

    const getArrowPosition = () => {
      if (positionedElement?.parentElement) {
        const parentStyle = positionedElement.parentElement.style;
        if (!parentStyle.position || parentStyle.position === 'static') {
          parentStyle.position = 'relative';
        }
        return (
          positionedElement.offsetLeft -
          styles.arrowSizePx +
          positionedElement.getBoundingClientRect().width / 2
        );
      }
      return 0;
    };

    const getTTSize = () => {
      if (tooltipRef.current) {
        const { height, width } = tooltipRef.current.getBoundingClientRect();
        return { width, height };
      }
      return { width: 0, height: 0 };
    };

    const getAndSetPosition = () => {
      if (!positionedElement) {
        return;
      }
      const { height, width } = getTTSize();
      const rect = positionedElement.getBoundingClientRect();
      let addToTop = 0;
      let addToLeft = 0;

      if (yPos === DirectionAndPlacement.Bottom) {
        addToTop = rect.height + styles.arrowSizePx * 2;
      } else if (yPos === DirectionAndPlacement.Top) {
        addToTop = -height - styles.arrowSizePx * 2;
      } else if (yPos === DirectionAndPlacement.Center) {
        addToTop = -height / 2 + rect.height / 2;
      }

      if (xPos === DirectionAndPlacement.Left) {
        addToLeft =
          yPos === DirectionAndPlacement.Center
            ? -width - styles.arrowSizePx * 2
            : -width + rect.width / 2 + styles.arrowGapPx + styles.arrowSizePx;
      } else if (xPos === DirectionAndPlacement.Right) {
        addToLeft =
          yPos === DirectionAndPlacement.Center
            ? rect.width + styles.arrowSizePx * 2
            : rect.width / 2 - styles.arrowGapPx - styles.arrowSizePx;
      } else if (xPos === DirectionAndPlacement.Center) {
        addToLeft = -width / 2 + rect.width / 2;
      }

      const left = rect.left + addToLeft;
      const top = rect.top + addToTop;
      if (position.left !== left || position.top !== top) {
        setPosition({ left, top });
      }
    };

    // initialize when opening
    useEffect(() => {
      if (isOpen) {
        getAndSetPosition();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, positionElement, positionRef, xPos, yPos]);

    // handle clicking outside the tt
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (tooltipRef.current && positionedElement) {
          if (
            !tooltipRef.current.contains(event.target as Node) &&
            !positionedElement.contains(event.target as Node)
          ) {
            onClose?.();
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onClose]);

    // on scroll and resize, recalculate positions
    useEffect(() => {
      const throttledResize = throttle(getAndSetPosition, 10);
      const throttledScroll = throttle(getAndSetPosition, 10);
      window.addEventListener('resize', throttledResize);
      window.addEventListener('scroll', throttledScroll);
      return () => {
        window.removeEventListener('resize', throttledResize);
        window.removeEventListener('scroll', throttledScroll);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [positionedElement, xPos, yPos]);

    // detect body animations that would affect the position
    useEffect(() => {
      document.body.addEventListener('transitionend', getAndSetPosition);
      return () => {
        document.body.removeEventListener('transitionend', getAndSetPosition);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [positionedElement, xPos, yPos]);

    // use autoPosition, set the default position
    useEffect(() => {
      if (!autoPosition) {
        return;
      }
      setXPos(xPosFromProps);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoPosition, positionedElement]);

    // use autoPosition, use edge detection
    useEffect(() => {
      if (!autoPosition) {
        return;
      }
      const overflowingRight = window.innerWidth < getTTSize().width + position.left;
      const overflowingLeft = position.left < 0;

      if (overflowingRight) {
        setXPos(DirectionAndPlacement.Left);
      } else if (overflowingLeft) {
        setXPos(DirectionAndPlacement.Right);
      }
      if ((overflowingLeft || overflowingRight) && yPos === DirectionAndPlacement.Center) {
        setYPos(DirectionAndPlacement.Top);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoPosition, position]);

    const root = document.getElementById('root') ?? document.body;

    const toolTip = (
      <styles.TooltipStyle
        ref={mergedRef}
        id={id}
        style={{ ...position }}
        isOpen={isOpen}
        inline={inline}
        xPos={xPos}
        yPos={yPos}
        arrowX={getArrowPosition()}
        yMargin={yMargin}
        maxWidth={maxWidth}
        role="tooltip"
      >
        {children}
      </styles.TooltipStyle>
    );

    return inline ? toolTip : ReactDOM.createPortal(toolTip, root);
  }
);

export default Tooltip;
