import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { HeaderStyles, mixins } from '@pointdotcom/pds';

const thtdCSS = css`
  border-color: ${({ theme }) => theme.Color.Gray2};
  border-style: solid;
  padding: 3%;
`;

export const PricingTableMonthStyle = styled.table.attrs({ className: 'PricingTableMonthStyle' })`
  width: 100%;
`;

export const PricingTableStyle = styled.table.attrs({ className: 'PricingTableStyle' })`
  border-collapse: collapse;
  width: 100%;
  height: 1px; /* HACK: IE - https://stackoverflow.com/questions/22220698/height-100-for-div-inside-div-with-display-table-cell */
  th {
    ${thtdCSS}
    ${HeaderStyles.HeaderStyle} {
      max-width: 172px;
      margin: 0 auto;
    }
    border-width: 1px;
    text-align: center;
  }
  td {
    height: 100%;
    text-align: center;
  }
`;

export const PricingTableYearRow = styled.tr<{ selected: boolean }>`
  td {
    ${thtdCSS};
    border-width: ${({ selected }) => (selected ? '2px' : '1px')};
    &:first-child {
      color: ${({ selected, theme }) => (selected ? 'inherit' : theme.link.color.default)};
      font-family: ${({ theme }) => theme.fonts.sansSerif.name};
      font-weight: 700;
      border-right-width: 1px;
    }
    &:last-child {
      border-left-width: 1px;
    }
  }

  ${mixins.desktopHoverCondition()} {
    &:hover {
      td {
        cursor: pointer;
        background: ${({ theme }) =>
          mixins.rgba(theme.link.color.default ?? theme.Color.PointBlue, 0.05)};
      }
    }
  }
`;

export const PricingTableMonthRowStyle = styled.tr<{
  animationStatus: TransitionStatus;
  lastChild?: boolean;
}>`
  td {
    border-width: 0;
    padding: 0;
    text-align: right;
    div {
      ${thtdCSS}
      transition: all 0.2s ease-in-out;
      opacity: ${({ animationStatus }) => (animationStatus === 'entered' ? '1' : '0')};
      max-height: ${({ animationStatus }) => (animationStatus === 'entered' ? '100px' : '0')};
      padding: ${({ animationStatus }) => (animationStatus === 'entered' ? '10px 0' : '0')};
      border-width: 0px;
      border-bottom-width: ${({ lastChild }) => (lastChild ? '0' : '1px')};
      width: 100%;
      box-sizing: content-box;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      white-space: nowrap;
      background: ${({ theme }) =>
        mixins.rgba(theme.link.color.default ?? theme.Color.PointBlue, 0.05)};
    }
    &:first-child {
      padding-left: 2%;
    }
    &:last-child {
      div {
        border-left-width: 1px;
      }
    }
  }
`;

export const TipTextStyle = styled.div.attrs({ className: 'TipTextStyle' })`
  color: ${({ theme }) => theme.Color.Gray4};
  font-size: 0.95rem;
  margin-bottom: 3em;
  text-align: center;
`;

export const CapPriceTableStyle = styled.div.attrs({ className: 'CapPriceTableStyle' })`
  ${PricingTableStyle} {
    margin-bottom: 10%;
  }
`;
