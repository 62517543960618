import nullthrows from 'nullthrows';
import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { GraphicsStyles, mixins } from '@pointdotcom/pds';
import { appTheme } from 'styles/';

export enum FlexFlow {
  Row = 'row',
  Column = 'column',
}

function extractValUnit(
  stringValue: Parameters<typeof mixins.extractValUnit>[0]
): [number, string] {
  const [val, unit] = mixins.extractValUnit(stringValue);
  return [nullthrows(val), nullthrows(unit)];
}

const pagePadding = '8vh';
const containerWidth = '70vw';
const containerMaxWidth = '900px';
const containerMinWidth = '750px';
const paddingSize = '100px';
const paddingSizeMobile = '3rem';
const [paddingVal, paddingUnit] = extractValUnit(paddingSize);
const [paddingValMobile, paddingUnitMobile] = extractValUnit(paddingSizeMobile);

export const transitionSpeedMS = 300;
export const MainContentStyle = styled.div.attrs({ className: 'MainContentStyle' })<{
  animationStatus: TransitionStatus;
}>`
  transition: all ${transitionSpeedMS}ms ease-in-out;
  opacity: ${({ animationStatus }) => (animationStatus === 'entered' ? '1' : '0')};
  max-height: ${({ animationStatus }) => (animationStatus === 'entered' ? '1500px' : '0')};
`;

export const ModalPageStyle = styled.div.attrs({ className: 'ModalPageStyle' })`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  padding: ${pagePadding} 0;

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding: 0 0 2.5rem;
  }
`;

export const MainPageContainerStyle = styled.section`
  width: ${containerWidth};
  max-width: ${containerMaxWidth};
  min-width: ${containerMinWidth};
  box-sizing: border-box;
  @media (max-width: ${containerMinWidth}) {
    width: 100% !important;
    min-width: auto !important;
    max-width: auto !important;
  }
`;

const headerAndFooterCSS = css`
  justify-content: space-between;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const ModalPageHeaderStyle = styled(MainPageContainerStyle).attrs({
  className: 'ModalPageHeaderStyle',
})`
  ${headerAndFooterCSS}

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    height: ${appTheme.mainHeaders.height.default};
    padding: 0 ${paddingSizeMobile};
  }
`;

export const modalContainerCSS = css`
  box-sizing: border-box;
  box-shadow: 0 0 60px ${(props) => mixins.rgba(props.theme.Color.Gray4, 0.17)};
  background-color: white;
  position: relative;
  width: ${containerWidth};
  &:before {
    ${GraphicsStyles.TextureGrayCSS};
    background-position-y: top;
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 450px;
    top: -5px;
    left: -30%;
    z-index: -1;
    transform: rotate(-101deg);
    opacity: 0.3;
  }
`;

export const ModalPageContainerStyle = styled(MainPageContainerStyle).attrs({
  className: 'ModalPageContainerStyle',
})<{ flexFlow: FlexFlow }>`
  ${modalContainerCSS}
  form {
    display: flex;
    flex-flow: ${({ flexFlow }) => flexFlow} nowrap;
    width: 100%;
    gap: ${({ flexFlow }) => (flexFlow === FlexFlow.Column ? 0 : '1rem')};
    > * {
      flex: 4;
      &:last-child {
        flex: 2;
      }
    }
  }
  > section {
    background: inherit;
  }
  .topSection {
    padding: ${paddingSize};
    padding-bottom: 0;
    margin-bottom: 4.5vh;
  }
  .contentSection {
    padding: 0 ${paddingSize} ${paddingVal / 2}${paddingUnit};
    &.weighted {
      padding-bottom: ${paddingSize};
    }
  }
  .contextSection {
    padding: ${paddingVal / 3}${paddingUnit} ${paddingSize};
    border-top: 1px solid ${(props) => props.theme.line.color.default};
  }
  .afterContextSection {
    border-top: 1px solid ${(props) => props.theme.line.color.default};
    padding: ${paddingVal / 2}${paddingUnit} ${paddingSize};
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    box-shadow: none;
    .topSection {
      padding: 0 ${paddingSizeMobile};
    }
    .contentSection {
      padding: ${paddingSizeMobile};
      &.weighted {
        padding-bottom: ${paddingSizeMobile};
      }
    }
    .contextSection {
      padding: ${paddingValMobile / 2}${paddingUnitMobile} ${paddingSizeMobile};
      border-top: 1px solid ${(props) => props.theme.line.color.default};
    }
    .afterContextSection {
      padding: ${paddingSizeMobile};
    }
    .bottomSection {
      box-shadow: 0 0 60px ${(props) => mixins.rgba(props.theme.Color.Gray4, 0.17)};
    }
    form {
      gap: 0;
      flex-flow: column nowrap;
      > * {
        flex: 1;
      }
    }
    &:before {
      display: none;
    }
  }
`;

export const FooterSectionStyle = styled(MainPageContainerStyle).attrs({
  className: 'FooterSectionStyle',
})`
  ${headerAndFooterCSS}
  a {
    color: ${(props) => props.theme.Color.GrayLightestAccessible};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    flex-flow: column nowrap;
  }
`;
