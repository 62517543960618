import React from 'react';
import { Container, DirectionAndPlacement, Header, Size } from '@pointdotcom/pds';
import { useFollowUpFetch } from 'containers/prequal/hooks';
import { HeroContainerStyle, SplashCopySerifStyle } from 'styles/';
import FinancialCounselingPage from '..';
import i18n from '../i18n';
import * as styles from './styles';

const FinancialCounselingCompletionPage = () => {
  const followUpFetchResults = useFollowUpFetch();
  const listItems = i18n.navicoreNextSteps;

  return (
    <FinancialCounselingPage followUpFetchResults={followUpFetchResults}>
      <HeroContainerStyle styleSize={Size.Small}>
        <styles.CounselingContentStyle>
          <Container>
            <Header styleSize={Size.Splash} styleAlignMobile={DirectionAndPlacement.Center}>
              {i18n.financialCounseling}
            </Header>
            <SplashCopySerifStyle mobileSeparator>{i18n.youWillParticipate}</SplashCopySerifStyle>
            <styles.ListStyle>
              {listItems.map((text) => (
                <styles.ListItemStyle key={text.slice(5)}>{text}</styles.ListItemStyle>
              ))}
            </styles.ListStyle>
          </Container>
        </styles.CounselingContentStyle>
      </HeroContainerStyle>
    </FinancialCounselingPage>
  );
};

export default FinancialCounselingCompletionPage;
