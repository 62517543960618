import styled from 'styled-components';
import { ContainerStyles, Size } from '@pointdotcom/pds';
import { ConfettiBackgroundStyle } from 'components/ConfettiBackground/styles';
import { HeroContainerStyle as AppHeroContainerStyle } from 'styles/';

export const HeroContainerStyle = styled(AppHeroContainerStyle)<{ styleSize: Size }>`
  background: ${(props) => props.theme.Color.White};
  position: relative;
  overflow: hidden;
  ${ConfettiBackgroundStyle} {
    z-index: 1;
  }
  ${ContainerStyles.ContainerStyle} {
    z-index: 2;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
  }
`;

export const ErrorPageInnerContainerStyle = styled.div.attrs({
  className: 'ErrorPageInnerContainerStyle',
})`
  max-width: 630px;
`;
