export default {
  pageName: 'Confirm Address',
  continue: 'Continue',
  confirmAddress: 'Please confirm your address',
  whyDoesPoint: 'Why does Point need my unit number?',
  toProvideYou:
    'To provide you with the most accurate offer, we need a preliminary estimate of your home’s value. Our system has detected that the address you entered may be a multi-residential building.',
  streetAddress: 'Street',
  addressError: 'There was a problem with your address. Please try again.',
  select: 'Select...',
  unit: 'Unit',
  city: 'City',
  state: 'State',
  zip: 'Zip',
};
