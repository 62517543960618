import styled from 'styled-components';

export const HeaderCapsStyle = styled.h4.attrs({ className: 'HeaderCapsStyle' })`
  text-transform: uppercase;
  font-family: inherit;
  font-size: ${(props) => `${15 / parseInt(props.theme.globals.baseFontSize.default, 10)}rem`};
  font-weight: normal;
  margin: 0.3rem 0 0.3rem;
  text-align: ${(props) => props.styleAlign};
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    text-align: ${(props) => props.styleAlignMobile};
  }
`;

export const HeaderUpperLowerStyle = styled.header.attrs({ className: 'HeaderUpperLowerStyle' })`
  @media only print {
    > * {
      text-align: left;
    }
  }
`;
