export default {
  belowItems:
    'The below items are the important things to know about your contract, written in plain English, to help you understand your obligations when you sign with Point. Note that this is not your contract, and it’s important to review all the legal documents as well.',
  payingMortgage:
    'You are responsible for paying your mortgage(s), property taxes, and insurance. If you do not, you will be in default on your Point Agreement and Point may foreclose on your home.',
  additionalDebt:
    'You are responsible for paying off any of your debts that are not paid off with the funds received from Point.',
  noLoans:
    'You are not permitted to take out loans on your home whose repayment would take priority over your repayment to Point. This includes PACE or HERO loans. You must get Point’s written permission before taking out any loan against your home. However, if the loan’s purpose is to repay Point, you do not need permission.',
  notBuying:
    'Point is %not% buying part of your home at closing. Instead, Point is buying the right to %optionPercentage% of your home’s future change in value. This right is called the “option.”',
  readyToRepay:
    'When you are ready to repay Point, Point will determine your home’s change in value by subtracting the Appreciation Starting Value from the Final Home Value. To determine the Appreciation Starting Value, Point lowered the assessed value of your home by %riskAdjPerc%. This is called the risk adjustment. The Appreciation Starting Value for your home is %riskAdjHomeValue%. To determine your home’s Final Home Value, Point will use your home’s sale price, a final appraisal, or AVM (except in some cases of default).',
  repaymentAmount:
    'Your repayment amount will be the %lesser% of two amounts. The first amount is the sum of the funds you receive from Point plus Point’s share of your home’s change in value. The second amount is the result of applying an annual rate, %capPercentage%, compounded monthly, to the funds you receive from Point. This rate is called the Homeowner Protection Cap and protects you in case your home greatly increases in value. You can use the Cost Estimator that came with your offer to estimate your repayment amount.',
  valueIncreases:
    'If your home value increases, your repayment amount will also increase. Even if your home value does not increase, your repayment amount may increase over time. This is because the Appreciation Starting Value is less than your home’s current assessed value.',
  mustRepay:
    'You must repay Point within %repaymentTerm%. Homeowners usually repay Point by selling their home, refinancing their mortgage, or taking out a new home equity loan. The repayment is a one-time paid-in-full event. Point does not accept partial payments.',
  repayBySelling:
    'If you repay Point by selling your home, you must pay all costs associated with the sale. At that time, you must pay off your mortgage and any other liens.',
  repayIn3Years:
    'If you repay Point within three years of the Effective Date, and you did not sell your home during that time, your repayment amount will be the %greater% of two amounts. The first amount is the sum of funds you receive from Point.  The second amount is the sum of the funds you receive from Point plus Point’s share of your home’s change in value, subject to the Homeowner Protection Cap.',
  lendersUnwilling:
    'Lenders may not be willing to refinance your mortgage unless you also repay Point.',
};
