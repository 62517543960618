import Cookies from 'js-cookie';
import { posthogFeatureFlagDefinitions } from './featureFlagDefinitions';
import { FeatureFlag, FeatureFlagValue } from './featureFlagObjects';

/*
 * Load environment variable overrides
 *
 * Vite does not allow dynamic environment variable names at runtime, so instead build a
 * map of overrides from a single environment variable and then check the map at runtime
 * based on the flag name.
 *
 * `REACT_APP_FEATURE_FLAG_OVERRIDES` must be a quote-escaped JSON string mapping
 * feature flag keys to their override values, e.g.:
 * REACT_APP_FEATURE_FLAG_OVERRIDES={\"m1_followups\": true, \"test_experiment_20230508\": \"control\"}
 */
let envOverrideMap: Record<string, unknown> = {};
try {
  // Unescape the JSON string and parse it
  const envVal = (process.env.REACT_APP_FEATURE_FLAG_OVERRIDES ?? '{}').replaceAll('\\', '');
  envOverrideMap = JSON.parse(envVal);
} catch (e) {
  console.error(
    `Invalid feature flag override setting: ${process.env.REACT_APP_FEATURE_FLAG_OVERRIDES}`,
    e
  );
}

export function getFeatureFlagOverride<TFlag extends FeatureFlag>(
  flag: TFlag
): undefined | FeatureFlagValue<TFlag> {
  const flagDefinition = posthogFeatureFlagDefinitions[flag];
  const flagValues: readonly FeatureFlagValue<TFlag>[] = flagDefinition.values;

  const cookieOverride = flagValues.find(
    // Supports matching of e.g. `'true'` (the string value from cookie) with `true` (boolean flag value)
    (value) => Cookies.get(flagDefinition.key) === String(value)
  );

  const envOverride = flagValues.find((value) => envOverrideMap[flagDefinition.key] === value);

  return cookieOverride ?? envOverride;
}
