import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Header } from '@pointdotcom/pds';

/**
 * Creates a layout for header-specific attributes found in "PageContent" as defined in models/PageFormModel.Content
 */

const PageContentHeader = ({
  content,
  children,
  contrastingSubheader,
  titleSize,
  titleAlign,
  titleMaxWidth,
  subTitleMaxWidth,
}) => {
  const nameSpace = 'PageContentHeader';
  const contentNew = content || children; // { title, subtitle, className }
  const subTitle =
    typeof contentNew?.subtitle === 'string' ? (
      // eslint-disable-next-line react/no-danger
      <span dangerouslySetInnerHTML={{ __html: contentNew?.subtitle }} />
    ) : (
      contentNew?.subtitle
    );
  const cn = ['PageContent', nameSpace, contentNew?.className];
  if (contrastingSubheader) {
    cn.push('contrastingSubheader');
  }

  return (
    <>
      <Header
        styleSize={titleSize}
        styleAlign={titleAlign}
        className={classnames(cn)}
        maxWidth={titleMaxWidth}
      >
        {contentNew?.title}
        <Header styleAlign={titleAlign} maxWidth={subTitleMaxWidth}>
          {subTitle}
        </Header>
      </Header>
      {contentNew.subContent}
    </>
  );
};

PageContentHeader.defaultProps = {
  titleSize: 'large',
  titleAlign: 'left',
  titleMaxWidth: null,
  contrastingSubheader: false,
  content: null,
};

PageContentHeader.propTypes = {
  titleMaxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  titleSize: PropTypes.string,
  titleAlign: PropTypes.string,
  contrastingSubheader: PropTypes.bool, // Will make a small, sans-serif subheading under the main heading
  content: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    className: PropTypes.string,
  }),
};

export default PageContentHeader;
