export default {
  aboutPoint: 'About Point',
  yourPricing: 'Your pricing',
  yourPotential: 'Your potential offer',
  yourOfferFromPoint: 'Your offer from Point',
  yourCost: 'Your cost estimator',
  estimator: 'Estimator',
  yourDecision: 'Your decision',
  decision: 'Decision',
  yourPrelimOffer: 'Your preliminary offer',
  yourFinalOffer: 'Your final offer',
  offer: 'Offer',
  yourApplication: 'Your application',
  yourApplicationIsnt:
    'We are unable to create this application with the appropriate attribution. Please contact your Partner Account Manager.',
  getStarted: 'Get started',
  estimateYour: 'Estimate your cost',
  reviewAgreementTerms: 'Review agreement terms',
  iAgree: 'I agree',
  overview: 'Overview',
  documents: 'Documents',
  aboutYourPoint: 'About your Point agreement',
  agreement: 'Agreement',
  viewNewOffer: 'View new offer',
  looksLike: 'Looks like you have a more recently created offer',
  tellUs: 'Tell us your decision',
  estimateMy: 'Estimate my cost',
  nextEstimate: 'Next: Estimate my cost',
  nextEstimateYour: 'Next: Estimate your cost',
  offerNum: 'Offer %num%',
  yourOffer: 'Your offer',
  yourOffers: 'Your offers',
  viewPreviousOffer: 'View previous offer',
  viewNextOffer: 'View next offer',
  viewPrevious: 'View previous',
  viewNext: 'View next',
};
