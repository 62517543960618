import styled from 'styled-components';
import { ContainerStyles, DropdownStyles, HeaderStyles, RangeStyles } from '@pointdotcom/pds';
import {
  CalloutItemStyle,
  PricingPresentationWithMathStyle,
} from 'components/PricingWithMath/styles';
import * as tableMobileStyles from 'components/TableMobile/styles';
import * as appStyles from 'styles/';

export const ExpertExplainSectionStyle = styled.div.attrs({
  className: 'ExpertExplainSectionStyle',
})`
  border-width: 1px;
  border-style: solid;
  border-left: none;
  border-right: none;
  border-color: ${(props) =>
    props.bordered ? `${props.theme.line.color.invertedSubtle}` : 'transparent'};
  margin-top: ${(props) => (props.bordered ? '5rem' : '0')};
  ${ContainerStyles.ContainerStyle} {
    height: 8rem;
  }
  a {
    color: inherit;
    text-decoration: none;
    display: inline-block;
    flex: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    margin-top: 0;
    border-top: none;
  }
  @media only print {
    display: none;
  }
`;

export const EstimatedPricingSectionStyle = styled(appStyles.HeroContainerStyle).attrs({
  className: 'EstimatedPricingSectionStyle',
})`
  box-sizing: border-box;
  width: 100%;
  ${HeaderStyles.HeaderStyle} {
    &.medium {
      margin-bottom: 3rem;
    }
  }
  ${tableMobileStyles.MainContentStyle} {
    font-size: 1rem;
    h3 {
      font-size: 1.1rem;
    }
    h4 {
      font-size: 0.7rem;
    }
  }
  ${tableMobileStyles.RightContentStyle} {
    font-size: 1.625rem;
    font-family: ${(props) => props.theme.fonts.serif.name};
  }

  @media only print {
    color: inherit;
    background: white;
    max-width: 85%;
    margin: 0 auto;
    ${RangeStyles.RangeInputStyle}, ${DropdownStyles.DropdownStyle} {
      display: none;
    }
    ${HeaderStyles.HeaderStyle} {
      margin-bottom: 0;
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding: 7rem 0 6%;
  }
`;

export const PricingPageStyle = styled.div.attrs({ className: 'PricePageStyle' })`
  background: white;
  ${EstimatedPricingSectionStyle} {
    padding: 10rem 0 6%;
  }
  .estimatedPricingSectionMainHeader {
    margin-bottom: 5rem;
  }

  ${CalloutItemStyle} {
    color: white;
    border-color: transparent;
  }

  .firstCallout {
    background: ${(props) => props.theme.Color.AccentBlue};
  }

  .secondCallout {
    background: ${(props) => props.theme.Color.PurpleBlue5};
  }

  .thirdCallout {
    background: ${(props) => props.theme.Color.PurpleBlue7};
  }

  ${ExpertExplainSectionStyle} {
    border-bottom: none;
    border-color: ${(props) => props.theme.line.color.default};
    a {
      color: ${(props) => props.theme.Color.GrayLightestAccessible};
      flex: none;
    }
  }

  ${PricingPresentationWithMathStyle} {
    ${HeaderStyles.HeaderStyle} {
      margin-bottom: 6rem;
    }
  }

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    .secondCallout,
    .thirdCallout {
      border-color: white;
    }
    ${PricingPresentationWithMathStyle} {
      ${HeaderStyles.HeaderStyle} {
        margin-bottom: 3rem;
      }
    }
  }
`;

export const FooterSectionStyle = styled.footer.attrs({ className: 'FooterSectionStyle' })`
  padding: 3rem 0 10%;
  ${ContainerStyles.ContainerStyle} {
    justify-content: space-between;
    > * {
      flex: none;
    }
  }
`;
