import React from 'react';
import {
  Button,
  Container,
  Header,
  Size,
  SplashText,
  Style,
  TemplatedText,
} from '@pointdotcom/pds';
import VideoButton from 'components/VideoPlayer/VideoButton';
import { VideoButtonStyleType } from 'components/VideoPlayer/VideoButton/constants';
import { useHistory } from 'containers/routerHelpers';
import { BoldCopyStyle } from 'styles/';
import i18n from './i18n';
import imgLaptop from './images/neal.png';
import * as styles from './styles';

interface QuizIntroductionProps {
  onVideoClick: (videoPosition: number) => void;
  onOpenQuizClick: () => void;
  scheduleCallUrl: string;
}

const QuizIntroduction = ({
  onVideoClick,
  onOpenQuizClick,
  scheduleCallUrl,
}: QuizIntroductionProps) => {
  const history = useHistory();

  const handleScheduleClick = () => {
    history.push(scheduleCallUrl);
  };

  const condition2 = (
    <TemplatedText
      values={{
        fourOfSix: <BoldCopyStyle>{i18n.fourOfSix}</BoldCopyStyle>,
      }}
    >
      {i18n.condition2}
    </TemplatedText>
  );
  const quizNotes = [i18n.condition1, condition2, i18n.condition3];

  return (
    <styles.QuizIntroductionStyle>
      <styles.VideoSectionStyle>
        <Container flex mobileColumn mobileCollapse>
          <styles.OverviewStyle>
            <styles.OverviewHeaderStyle>
              <Header styleSize={Size.Large} noMargin>
                {i18n.watchVideo}
              </Header>
              <VideoButton onClick={() => onVideoClick(0)} styleType={VideoButtonStyleType.Icon} />
            </styles.OverviewHeaderStyle>
            <SplashText>{i18n.beforeWeCan}</SplashText>
            <VideoButton onClick={() => onVideoClick(0)} />
          </styles.OverviewStyle>
          <styles.QuizDetailStyle>
            <Header styleSize={Size.Medium}>{i18n.thenQuiz}</Header>
            <p>{i18n.aFewThings}</p>
            <styles.QuizListStyle>
              {quizNotes.map((note) => (
                <li key={note.toString()}>{note}</li>
              ))}
            </styles.QuizListStyle>
            <Button styleSize={Size.Large} onClick={onOpenQuizClick}>
              {i18n.startTheQuiz}
            </Button>
          </styles.QuizDetailStyle>
        </Container>
      </styles.VideoSectionStyle>
      <styles.SecondaryContentStyle>
        <Container mobileCollapse>
          <styles.ScheduleSectionStyle>
            <div>
              <Header>{i18n.preferExpert}</Header>
              <p>{i18n.ourExperts}</p>
              <Button styleType={Style.Tertiary} onClick={handleScheduleClick}>
                {i18n.scheduleACall}
              </Button>
            </div>
            <img src={imgLaptop} alt="laptop illustration" />
          </styles.ScheduleSectionStyle>
        </Container>
      </styles.SecondaryContentStyle>
    </styles.QuizIntroductionStyle>
  );
};

export default QuizIntroduction;
