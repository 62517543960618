export default {
  howDoes: 'How does an investment from Point compare to a loan?',
  noMonthly: 'No monthly payments',
  noDings: 'No dings to your credit',
  noLate: 'No late payment fees',
  noPrepayment: 'No prepayment penalty',
  noAge: 'No age restrictions',
  pointInvestment: 'Point investment',
  mostLoans: 'Most loans',
};
