export default {
  heiPageTitle: 'Point HEI - Cost Estimate',
  appreciation: 'Appreciation',
  appreciationStarting: 'Appreciation Starting Value',
  annualPerc: 'Annual increase in home value',
  declineIn: 'Decline in home value',
  selectA:
    'Select a home appreciation scenario you would like to use to calculate your repayment amount',
  useTheControls:
    'Use the tools below to see the repayment amount associated with different scenarios of home appreciation.',
  yourCostEstimator: 'Your cost estimator',
  whenYoure:
    'When you’re ready to buy back your equity, your repayment amount will depend on how much your home has changed in value by that time.',
  whenYoureInquiry:
    'When you’re ready to exit your Point contract, your repayment amount will depend on how much your home has changed in value by that time.',
  useTheToolsInquiry:
    'Use the tools below to see the repayment amount associated with different scenarios of home appreciation.',
  useTheToolsEstimateNote:
    'Note that these amounts are estimates only. Final pricing will depend on a review of your finances, home value, and available equity.',
  whenDoYou: 'When do you think you might want to buy back your equity?',
  amountDesired: 'Amount desired:',
  buyBackIn: 'Buyback in:',
  year: 'year',
  years: 'years',
  toDetermine:
    'To determine your repayment amount, Point does two calculations. The lower result is your repayment amount.',
  homeowners:
    'Homeowners typically get the funds to buy back their equity via a refinance, home sale, or their own savings.',
  customers:
    'Customers typically get the funds to pay back Point via a refinance, home sale, or their own savings.',
  theOrigInvestment:
    'The original investment amount (%origInvestment%) plus Point’s Share of the Appreciation (%pointsShare%).',
  sinceThisResult: 'Since this result is lower, it would be your repayment',
  apprBasedCost: 'Appreciation-based repayment',
  cappedCost: 'Capped repayment',
  capped: 'capped',
  finalHomeValue: 'Final Home Value',
  pointsShare: 'Point’s share',
  pointsShareOfAppr: 'Point’s Share of the Appreciation',
  totalAppr: 'Total appreciation',
  pointsShareOfApprIsCalculated:
    'Point’s Share of the Appreciation is calculated by multiplying the HEI Percentage (%optionPerc%) by the total Appreciation (%appreciation%).',
  theEquivOf:
    'The equivalent of the total amount owed on a %loanAmount% loan with a %apr% interest rate after %duration%, compounded monthly.',
  aTimeBased: 'A time-based, max amount designed to protect you if your home appreciates a lot.',
  inThisScenario:
    'In this scenario, your home appreciated a lot, so we applied a cap to keep your cost low. Your cost is the equivalent of the total amount owed on a %loanAmount% loan with a %apr% interest rate after %duration%, compounded monthly.',
  thisIsHowMuch:
    'This is how much equity you’ll retain after you buy out Point’s share, in this particular scenario. Note that this does not reflect any amounts you may owe on any mortgage loan secured by the property.',
  yourTotalRepayment: 'Your Total Repayment',
  yourShare: 'Your Share',
  youKeep: 'You Keep',
  equivAPR: 'Equivalent APR',
  estHomeValue: 'Estimated Home Value',
  yourEstHome: 'Your Estimated Home Value',
  yourHomeValue: 'Your Home Value',
  appraisedHomeValue: 'Appraised Home Value',
  thisIsTheAppraised: 'This is the appraised value of your home.',
  thisIncludes: 'This includes the amount you owe on your mortgage at time of exit.',
  weEstimated:
    'We estimated your home value. Once your appraisal is ready, we’ll update this number.',
  riskAdj: 'Risk Adjustment',
  originalAgreedValue: 'Appreciation Starting Value',
  theAppreciation: 'The Appreciation',
  theDifference:
    'The difference between your %termDescription% (%startingHomeValue%) and your Final Home Value (%finalHomeValue%) based on the scenario you selected is the Appreciation (%appreciation%).',
  weDiscounted:
    'We discounted your estimated home value by %riskAdjustment% to get the Appreciation Starting Value of your home. Because we share in downside, we apply this discount (or “risk adjustment”) to offset small declines in home value and protect our initial investment.',
  wellDiscount:
    'We’ll discount your estimated home value by %riskAdjustment% to get the Appreciation Starting Value of your home. Because we share in downside, we apply this discount (or “risk adjustment”) to offset small declines in home value and protect our initial investment.',
  weDiscountedAppraised:
    'We discounted your home value by %riskAdjustment% to get the Appreciation Starting Value of your home. Because we share in downside, we apply this discount (or “risk adjustment”) to offset small declines in home value and protect our initial investment.',
  wellDiscountAppraised:
    'We’ll discount your home value by %riskAdjustment% to get the Appreciation Starting Value of your home. Because we share in downside, we apply this discount (or “risk adjustment”) to offset small declines in home value and protect our initial investment.',
  optionPerc: 'HEI Percentage',
  theOptionPerc: 'The HEI Percentage',
  thePercOf:
    'The percent of the future appreciation Point would like to buy from you is called the HEI Percentage.',
  thePercOfMight:
    'The percent of the future appreciation Point might like to buy from you is called the HEI Percentage.',
  calcBasedOn:
    'Calculations based on a %perc% annual appreciation scenario over %duration% and a Final Home Value of %finalHomeValue% in %year%',
  calcBasedOnDecline:
    'Calculations based on a %perc% decline in home value and a Final Home Value of %finalHomeValue% in %year%',
  scenarioSummary: 'Scenario Summary',
  roundedTo: '(rounded to the nearest %nearest% of a percent)',
  theseNumbers: 'These numbers are based on the scenario you selected.',
  howMuchMoney: 'How much money would you like?',
  weCalculated:
    'We calculated this based on your estimated current home value, the equity you have in your home, your debt load, and the amount of money you’d like.',
  weCalculatedAppraised:
    'We calculated this based on your homeʼs appraised value, the equity you have in your home, your debt load, and the amount of money youʼd like.',
  preparingYour: 'Preparing your application...',
  yourApplicationIsnt:
    'We are unable to create this application with the appropriate attribution. Please contact your Partner Account Manager.',
  preparingApplication: 'Preparing application...',
  continueMy: 'Continue my application',
  or: 'or',
  continueApplication: 'Continue application',
  applyForThisOffer: 'Apply for this offer',
};
