import * as React from 'react';
import { useState } from 'react';
import { Container, Header, LinkButton, Size, TemplatedText } from '@pointdotcom/pds';
import { YCBMCal } from 'components/ScheduleCalendar/constants';
import { usePointContact } from 'containers/prequal/hooks/usePointContact';
import ApplicantModel from 'models/ApplicantModel';
import OfferEstimateModel from 'models/OfferEstimateModel';
import { BorderedSectionStyle } from 'styles';
import ScheduleCallOverlay from '../ScheduleCallOverlay';
import i18n from './i18n';
import * as styles from './styles';

interface HeiOfferEstimatorPageFooterSectionProps {
  applicant: null | ApplicantModel;
  estimate: null | OfferEstimateModel;
  calendar: YCBMCal;
  onOpenCapModal: () => void;
}

const questionsAndAnswers: Array<{ question: string; answer: string }> = [
  { question: i18n.howDoIRepay, answer: i18n.howDoIRepayAnswer },
  { question: i18n.howMuchWillThisCost, answer: i18n.howMuchWillThisCostAnswer },
  { question: i18n.whatIfMyHomeValueDrops, answer: i18n.whatIfMyHomeValueDropsAnswer },
  {
    question: i18n.whatIfMyHomeValueIncreasesALot,
    answer: i18n.whatIfMyHomeValueIncreasesALotAnswer,
  },
];

export default function HeiOfferEstimatorPageFooterSection({
  applicant,
  estimate,
  calendar,
  onOpenCapModal,
}: HeiOfferEstimatorPageFooterSectionProps): null | JSX.Element {
  const [scheduleOverlayIsOpen, setScheduleOverlayIsOpen] = useState(false);
  const { supportNumber } = usePointContact();

  if (estimate == null) {
    return null;
  }

  return (
    <>
      <BorderedSectionStyle>
        <Container>
          <styles.QuestionsListStyle>
            {questionsAndAnswers.map(({ question, answer }) => (
              <styles.QuestionAndAnswerStyle key={question}>
                <Header styleSize={Size.Small}>{question}</Header>
                <p>
                  <TemplatedText
                    values={{
                      learnMoreCapLink: (
                        <LinkButton onClick={onOpenCapModal}>{i18n.learnMore}</LinkButton>
                      ),
                      scheduleACallLink: (
                        <LinkButton onClick={() => setScheduleOverlayIsOpen(true)}>
                          {i18n.scheduleACall}
                        </LinkButton>
                      ),
                      supportPhone: supportNumber,
                      termLength: estimate.getFormattedTerm({ singular: true, hyphenated: true }),
                    }}
                  >
                    {answer}
                  </TemplatedText>
                </p>
              </styles.QuestionAndAnswerStyle>
            ))}
          </styles.QuestionsListStyle>
        </Container>
      </BorderedSectionStyle>
      <ScheduleCallOverlay
        isOpen={scheduleOverlayIsOpen}
        onModalClose={() => setScheduleOverlayIsOpen(false)}
        estimate={estimate}
        applicant={applicant}
        calendar={calendar}
      />
    </>
  );
}
