import styled from 'styled-components';

export const SocialContainerStyle = styled.div.attrs({ className: 'SocialContainerStyle' })`
  display: flex;
  flex-flow: row nowrap;
  > * {
    margin-right: 1rem;
    &:last-child {
      margin: 0;
    }
  }
  a {
    color: inherit;
  }
  path {
    fill-opacity: 1;
    fill: currentColor;
  }
`;
