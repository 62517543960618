import { BrowserLogRequest, LogSeverity } from 'services/apiTypes/homeownerTypes';
import { getHomeownerHost } from './hosts';

interface LogEvent {
  eventType: string;
  detail: Record<string, unknown> | string;
}

const hosLogEndpoint = `${getHomeownerHost()}/browser-logs`;

/**
 * @param eventType - a string with the category or tag signifying the action or error type which occurred.
 *                    this serves as a dimension to select these lines out of the full log.
 * @param detail - a string or object with details relevant to the event. if this contains a property called
 *                 `message` then it will be included in the log title
 * @param {*} logLevel one of the values of LogSeverity enum
 */
async function log({ eventType, detail }: LogEvent, logLevel: LogSeverity): Promise<void> {
  const logData: BrowserLogRequest = {
    message: { eventType, detail },
    severity: logLevel,
  };

  // Post to HOS
  try {
    await fetch(hosLogEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(logData),
    });
  } catch (e) {
    console.warn('Error sending log to HOS', e);
  }

  // Send to console
  if (process.env.NODE_ENV === 'development') {
    const consoleStyling =
      'background-color: black; color: yellow; font-weight: 600; padding: 2px;';
    let logFn = console.info;
    if (logLevel === LogSeverity.Warn) {
      logFn = console.warn;
    } else if (logLevel === LogSeverity.Error) {
      logFn = console.error;
    }
    logFn(`%cLOG\n`, consoleStyling, logData.message);
  }
}

function logInfo({ eventType, detail }: LogEvent): void {
  log({ eventType, detail }, LogSeverity.Info);
}

function logWarning({ eventType, detail }: LogEvent): void {
  log({ eventType, detail }, LogSeverity.Warn);
}

function logError({ eventType, detail }: LogEvent): void {
  log({ eventType, detail }, LogSeverity.Error);
}

export { logInfo, logWarning, logError };
