import React from 'react';
import { Container, directory } from '@pointdotcom/pds';
import { usePointContact } from 'containers/prequal/hooks/usePointContact';
import { Products } from 'store/constants';
import PointCopyright from './PointCopyright';
import i18n from './i18n';
import * as styles from './styles';

const { PointDomain, pointLink } = directory;

type NavItem = {
  [x: string]: string;
};

export const navItems: NavItem[] = [
  { Point: process.env.REACT_APP_POINT_URL || PointDomain.WWW },
  { [i18n.about]: pointLink.aboutUs },
  { [i18n.terms]: pointLink.terms.use },
  { [i18n.privacy]: pointLink.terms.privacy },
  { [i18n.contact]: pointLink.contact },
  { [i18n.homeownerHelp]: PointDomain.Help },
];

interface CallUsSectionProps {
  product: Products;
}

const CallUsSection = ({ product }: CallUsSectionProps) => {
  const { supportNumber } = usePointContact({ product });
  return (
    <styles.CallUsSectionStyle>
      <a
        href={`tel: ${supportNumber}`}
        target="_blank"
        rel="noopener noreferrer"
      >{`${i18n.callUs} ${supportNumber}`}</a>
    </styles.CallUsSectionStyle>
  );
};

interface MainFooterProps {
  product?: Products;
  noBackground?: boolean;
}

const MainFooter = ({ product = Products.HEI, noBackground = false }: MainFooterProps) => {
  return (
    <styles.FooterContainerStyle>
      {!noBackground && <styles.BodyWithFooterStyle />}
      <Container>
        <styles.FooterSectionTopStyle>
          <PointCopyright />
          <CallUsSection product={product} />
        </styles.FooterSectionTopStyle>
        <styles.FooterSectionBottomStyle>
          <styles.FooterSectionNavStyle>
            {navItems.map((item) => (
              <a
                href={Object.keys(item).map((key) => item[key])[0]}
                key={Object.keys(item)[0]}
                target="_blank"
                rel="noopener noreferrer"
              >
                {Object.keys(item)[0]}
              </a>
            ))}
          </styles.FooterSectionNavStyle>
          <PointCopyright />
        </styles.FooterSectionBottomStyle>
      </Container>
    </styles.FooterContainerStyle>
  );
};

export default MainFooter;
