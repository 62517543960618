import React, { useState } from 'react';
import { z } from 'zod';
import {
  Button,
  DirectionAndPlacement,
  Dropdown,
  HelpTextAnimated,
  Input,
  Style,
  Validation,
} from '@pointdotcom/pds';
import { generateUrlFromPage, pages } from 'containers/helpers';
import ModalPage, { FlexFlow } from 'containers/prequal/ModalPage';
import { useRedirectToBeginning } from 'containers/prequal/hooks';
import { useProduct } from 'containers/prequal/hooks/useProduct';
import { NotInServiceAreaError, verifyServiceArea } from 'containers/prequal/pages/utils';
import { getPrequalPageFlow } from 'containers/prequal/productPageFlow';
import { useHistory } from 'containers/routerHelpers';
import { useLazyGetProductsForServiceAreaQuery } from 'services/api/prequalApi';
import { useDispatch, useSelector } from 'store/hooks';
import { answerAddress } from 'store/property';
import i18n from './i18n';
import * as styles from './styles';

// prettier-ignore
const states = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL',
  'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME',
  'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH',
  'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI',
  'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY',
];

const FullAddress = () => {
  useRedirectToBeginning();
  const history = useHistory();
  const dispatch = useDispatch();
  const [getProductsForServiceArea] = useLazyGetProductsForServiceAreaQuery();

  const { product } = useProduct();
  const { nextPage, totalPages, flowIndex } = getPrequalPageFlow(
    product,
    pages.PREQUAL_UNIT_NUMBER
  );

  const address = useSelector((state) =>
    Object.keys(state.property.address).length ? state.property?.address : state.applicant?.address
  );

  // NOTE: the values map to i18n text
  const field = {
    STREET: 'streetAddress',
    UNIT: 'unit',
    CITY: 'city',
    STATE: 'state',
    ZIP: 'zip',
  };

  const [value, setValue] = useState({
    [field.STREET]: address?.streetAddress ?? '',
    [field.UNIT]: address?.unit ?? '',
    [field.CITY]: address?.city ?? '',
    [field.STATE]: address?.state ?? '',
    [field.ZIP]: address?.zip ?? '',
  });

  const [loading, setLoading] = useState(false);
  const [fieldError, setFieldError] = useState({});
  const [formError, setFormError] = useState(null);
  const statesArray = states.map((state) => ({
    text: state,
    value: state,
  }));

  // Add the null option
  statesArray.unshift({
    text: i18n.select,
    value: 0,
    disabled: true,
  });

  // These get set on each field in addition to the common props set in the map below
  const fieldProps = {
    [field.STATE]: {
      options: statesArray,
    },
    [field.ZIP]: {
      maxLength: 5,
      mask: 'zip',
    },
  };

  const errorMessage = Validation.i18n.fieldRequired;
  const fieldValidationSchema = z.object({
    [field.STREET]: z.string().min(2, { message: errorMessage }),
    [field.CITY]: z.string().min(2, { message: errorMessage }),
    [field.STATE]: z.string().length(2, { message: errorMessage }),
    [field.ZIP]: z.string().length(5, { message: errorMessage }),
  });

  const clearErrors = () => {
    setFieldError({});
    setFormError(null);
  };

  const getFieldError = () => {
    const result = fieldValidationSchema.safeParse(value);
    if (!result.success) {
      return result.error.flatten().fieldErrors;
    }
    return {};
  };

  const isValid = () => Object.keys(getFieldError()).length === 0;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);

    if (!isValid()) {
      setFieldError(getFieldError());
      setLoading(false);
      return;
    }

    try {
      dispatch(answerAddress(value));
      const { availableProducts } = await getProductsForServiceArea(value).unwrap();
      verifyServiceArea(product, availableProducts);

      history.push(nextPage(value));
    } catch (error) {
      if (error instanceof NotInServiceAreaError) {
        history.push(generateUrlFromPage(pages.PREQUAL_WAITLIST_SIGNUP, { product }));
      } else {
        setFormError(i18n.addressError);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange =
    (fieldKey) =>
    (e, { value: fieldValue }) => {
      const newValue = { ...value };
      if (newValue[fieldKey] !== undefined) {
        newValue[fieldKey] = fieldValue;
      }
      setValue(newValue);
    };

  let focused = false;

  return (
    <ModalPage
      pageName={i18n.pageName}
      flexFlow={FlexFlow.Column}
      titleMaxWidth="400px"
      numIndicators={totalPages}
      currentIndicatorIndex={flowIndex}
      content={{ title: i18n.confirmAddress }}
      contextHelp={[
        {
          headline: i18n.whyDoesPoint,
          body: i18n.toProvideYou,
        },
      ]}
    >
      <form noValidate onSubmit={handleSubmit}>
        <styles.FieldContainerStyle>
          {Object.values(field).map((fieldKey) => {
            const commonFieldProps = {
              placeholder: i18n[fieldKey],
              onChange: handleChange(fieldKey),
              value: value[fieldKey],
              error: !!fieldError[fieldKey],
              helptext: fieldError[fieldKey],
              onFocus: clearErrors,
            };

            const FieldComponent = fieldKey === field.STATE ? Dropdown : Input;
            if (!focused && !value[fieldKey]) {
              focused = fieldKey;
            }

            return (
              <FieldComponent
                key={fieldKey}
                {...commonFieldProps}
                {...fieldProps[fieldKey]}
                focused={focused === fieldKey}
              />
            );
          })}
        </styles.FieldContainerStyle>
        <Button block type="submit" loading={loading}>
          {i18n.continue}
        </Button>
        <HelpTextAnimated
          show={!!formError}
          styleMarginPosition={DirectionAndPlacement.Top}
          styleType={Style.Error}
        >
          {formError}
        </HelpTextAnimated>
      </form>
    </ModalPage>
  );
};

export default FullAddress;
