import styled from 'styled-components';
import cloudImage from './images/cloud.png';
import hillDownImage from './images/hilldown.png';
import tandemBikeDownImage from './images/tandembikedown.png';

export const TandemBikeDownGraphicStyle = styled.div.attrs({
  className: 'TandemBikeDownGraphicStyle',
})`
  --hillDownSize: 40%;
  background-image: url('${hillDownImage}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: absolute;
  bottom: 0;
  right: -5%;
  width: var(--hillDownSize);
  padding-bottom: var(--hillDownSize);
  transform: translateX(-50%);
  &:after {
    --tandemBikeDownSize: 40%;
    content: '';
    display: block;
    background-image: url('${tandemBikeDownImage}');
    background-size: contain;
    background-repeat: no-repeat;
    width: var(--tandemBikeDownSize);
    padding-bottom: var(--tandemBikeDownSize);
    position: absolute;
    right: -6%;
    bottom: 2%;
  }
`;

export const CloudGraphicStyle = styled.div.attrs({ className: 'CloudGraphicStyle' })`
  --cloudSize: 82%;
  background-image: url('${cloudImage}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: absolute;
  top: -35%;
  left: -8%;
  width: var(--cloudSize);
  padding-bottom: var(--cloudSize);
  transform: translateX(50%);
  z-index: 1;
`;

export const RainStyle = styled.canvas`
  position: absolute;
  bottom: -63%;
  left: 0;
  width: 100%;
  height: 90%;
  background: transparent;
  opacity: 0.7;
`;
