import React, { useRef } from 'react';
import { Transition } from 'react-transition-group';
import { useScrollSpy } from '@pointdotcom/pds';
import { TRANSITION_SPEED_MS } from 'components/Paginator/styles';
import { SlideData } from '../constants';
import BillPileGraphic from './BillPileGraphic';
import CapBillGraphic from './CapBillGraphic';
import CapGraphGraphic, {
  CapGraphGraphicEightBar,
  CapGraphGraphicThreeBar,
} from './CapGraphGraphic';
import CapHouseGraphic from './CapHouseGraphic';
import { CapHouseGraphicMode } from './CapHouseGraphic/constants';
import CoinPileGraphic from './CoinPileGraphic';
import FishingGraphic from './FishingGraphic';
import GoldHouseMagnifyGraphic from './GoldHouseMagnifyGraphic';
import GoldenHousesGraphic from './GoldenHousesGraphic';
import HandCheckGraphic from './HandCheckGraphic';
import HandShakeGraphic from './HandShakeGraphic';
import MagicCalendarGraphic from './MagicCalendarGraphic';
import MountainGraphic from './MountainGraphic';
import PiggyBankGraphic from './PiggyBankGraphic';
import SoldHouseGraphic from './SoldHouseGraphic';
import TandemBikeDownGraphic from './TandemBikeDownGraphic';
import TandemBikeGraphic from './TandemBikeGraphic';
import * as styles from './styles';

// Handles graphic container transitions between slides
const GraphicTransition = React.forwardRef<
  HTMLDivElement,
  { show: boolean; children: React.ReactNode }
>(({ show, children }, ref) => {
  return (
    <Transition in={show} timeout={TRANSITION_SPEED_MS} unmountOnExit component={null}>
      {(animationStatus) => (
        <styles.CarouselSlideGraphicsStyle animationStatus={animationStatus} ref={ref}>
          {children}
        </styles.CarouselSlideGraphicsStyle>
      )}
    </Transition>
  );
});

// Intro before the slides start
export const IntroGraphics = ({ show }: { show: boolean }) => {
  const scrollSpyRef = useRef(null);
  const { spyIsFullyShown } = useScrollSpy({ spyOn: scrollSpyRef });
  return (
    <GraphicTransition ref={scrollSpyRef} show={show}>
      <styles.IntroGraphicStyle>
        <styles.LeftGraphicStyle>
          <GoldHouseMagnifyGraphic />
        </styles.LeftGraphicStyle>
        <styles.RightGraphicStyle>
          <CapGraphGraphic isShown={spyIsFullyShown} />
        </styles.RightGraphicStyle>
      </styles.IntroGraphicStyle>
    </GraphicTransition>
  );
};

type SlideGraphicProps = { currentIndex: number; isInCarouselMode?: boolean } & Partial<SlideData>;
type SlideGraphic = (props: SlideGraphicProps) => JSX.Element;

const Slide1Graphic: SlideGraphic = ({ currentIndex, isInCarouselMode }) => {
  const show = currentIndex === 0 && !!isInCarouselMode;
  return (
    <GraphicTransition show={show}>
      <styles.Slide1GraphicStyle>
        <styles.LeftGraphicStyle>
          <MagicCalendarGraphic startAnimation={show} />
        </styles.LeftGraphicStyle>
      </styles.Slide1GraphicStyle>
    </GraphicTransition>
  );
};

const Slide2Graphic: SlideGraphic = ({ currentIndex }) => {
  return (
    <GraphicTransition show={currentIndex === 1}>
      <styles.Slide2GraphicStyle>
        <styles.LeftGraphicStyle>
          <CoinPileGraphic />
        </styles.LeftGraphicStyle>
        <styles.RightGraphicStyle>
          <PiggyBankGraphic />
        </styles.RightGraphicStyle>
      </styles.Slide2GraphicStyle>
    </GraphicTransition>
  );
};

const Slide3Graphic: SlideGraphic = ({ currentIndex }) => {
  return (
    <GraphicTransition show={currentIndex === 2}>
      <styles.Slide3GraphicStyle>
        <styles.LeftGraphicStyle>
          <BillPileGraphic />
        </styles.LeftGraphicStyle>
      </styles.Slide3GraphicStyle>
    </GraphicTransition>
  );
};

const Slide4Graphic: SlideGraphic = ({ currentIndex }) => {
  return (
    <GraphicTransition show={currentIndex === 3}>
      <styles.Slide4GraphicStyle>
        <styles.LeftGraphicStyle>
          <TandemBikeGraphic />
        </styles.LeftGraphicStyle>
        <styles.RightGraphicStyle>
          <TandemBikeDownGraphic />
        </styles.RightGraphicStyle>
      </styles.Slide4GraphicStyle>
    </GraphicTransition>
  );
};

const Slide5Graphic: SlideGraphic = ({ currentIndex }) => {
  return (
    <GraphicTransition show={currentIndex === 4}>
      <styles.Slide5GraphicStyle>
        <styles.LeftGraphicStyle>
          <HandCheckGraphic totalOwnerPays="$" />
        </styles.LeftGraphicStyle>
        <styles.RightGraphicStyle>
          <HandShakeGraphic />
        </styles.RightGraphicStyle>
      </styles.Slide5GraphicStyle>
    </GraphicTransition>
  );
};

const Slide6to8: SlideGraphic = ({ currentIndex }) => {
  let theMode = CapHouseGraphicMode.AtStartingValue;
  if (currentIndex === 6) {
    theMode = CapHouseGraphicMode.BelowStartingValue;
  }
  if (currentIndex === 7) {
    theMode = CapHouseGraphicMode.AboveStartingValue;
  }

  return (
    <GraphicTransition show={[5, 6, 7].includes(currentIndex)}>
      <styles.Slide6to8GraphicStyle>
        <styles.RightGraphicStyle>
          <CapHouseGraphic mode={theMode} />
        </styles.RightGraphicStyle>
      </styles.Slide6to8GraphicStyle>
    </GraphicTransition>
  );
};

const Slide9Graphic: SlideGraphic = ({ currentIndex }) => {
  return (
    <GraphicTransition show={currentIndex === 8}>
      <styles.Slide9GraphicStyle>
        <styles.LeftGraphicStyle>
          <CapBillGraphic />
        </styles.LeftGraphicStyle>
      </styles.Slide9GraphicStyle>
    </GraphicTransition>
  );
};

const Slide10Graphic: SlideGraphic = ({ currentIndex }) => {
  return (
    <GraphicTransition show={currentIndex === 9}>
      <styles.Slide10GraphicStyle>
        <styles.LeftGraphicStyle>
          <CapGraphGraphicThreeBar />
        </styles.LeftGraphicStyle>
      </styles.Slide10GraphicStyle>
    </GraphicTransition>
  );
};

const Slide11to13Graphic: SlideGraphic = ({ currentIndex }) => {
  return (
    <GraphicTransition show={[10, 11, 12].includes(currentIndex)}>
      <styles.Slide11to13GraphicStyle>
        <styles.RightGraphicStyle>
          <SoldHouseGraphic />
        </styles.RightGraphicStyle>
      </styles.Slide11to13GraphicStyle>
    </GraphicTransition>
  );
};

const Slide14Graphic: SlideGraphic = ({ currentIndex }) => {
  return (
    <GraphicTransition show={currentIndex === 13}>
      <styles.Slide14GraphicStyle>
        <styles.LeftGraphicStyle>
          <CapGraphGraphicEightBar />
        </styles.LeftGraphicStyle>
        <styles.RightGraphicStyle>
          <GoldenHousesGraphic />
        </styles.RightGraphicStyle>
      </styles.Slide14GraphicStyle>
    </GraphicTransition>
  );
};

const Slide15Graphic: SlideGraphic = ({ currentIndex }) => {
  return (
    <GraphicTransition show={currentIndex === 14}>
      <styles.Slide15GraphicStyle>
        <styles.LeftGraphicStyle>
          <MountainGraphic />
        </styles.LeftGraphicStyle>
        <styles.RightGraphicStyle>
          <FishingGraphic />
        </styles.RightGraphicStyle>
      </styles.Slide15GraphicStyle>
    </GraphicTransition>
  );
};

export const SlideGraphics = ({ currentIndex, isInCarouselMode }: SlideGraphicProps) => {
  return (
    <>
      <Slide1Graphic currentIndex={currentIndex} isInCarouselMode={isInCarouselMode} />
      <Slide2Graphic currentIndex={currentIndex} />
      <Slide3Graphic currentIndex={currentIndex} />
      <Slide4Graphic currentIndex={currentIndex} />
      <Slide5Graphic currentIndex={currentIndex} />
      <Slide6to8 currentIndex={currentIndex} />
      <Slide9Graphic currentIndex={currentIndex} />
      <Slide10Graphic currentIndex={currentIndex} />
      <Slide11to13Graphic currentIndex={currentIndex} />
      <Slide14Graphic currentIndex={currentIndex} />
      <Slide15Graphic currentIndex={currentIndex} />
    </>
  );
};
