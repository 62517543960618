import { ReactNode } from 'react';
import { z } from 'zod';
import {
  AnyEvent,
  FormChangeEvent,
  FormChangeEventProps,
  FormFocusEvent,
  InputMask,
  InputMaskType,
  InputStyleSize,
} from '@pointdotcom/pds';
import {
  AdditionalOwners,
  ApplicationApiResponse,
  HeiApplicationConsent,
} from 'services/apiTypes/homeownerTypes';

export type FieldProps = {
  handleChange: HandleChangeFunc;
  handleFocus: HandleFocusFunc;
  getFieldValueByPath: GetFieldValueByPathFunc;
  getPathWasFocused: (path: string) => boolean;
  getBaseFormFieldProps: GetBaseFormFieldPropsFunc;
  onChange: FormChangeEvent;
  onFocus: FormFocusEvent;
  field?: FormStructureField;
  path: string;
  styleSize: InputStyleSize;
  value: FormFieldValue;
  showErrors: boolean;
  error: boolean;
  helptext?: string;
  issues?: Array<z.ZodIssue>;
  shouldShowErrorForField: ShouldShowErrorForFieldFunc;
  shouldShowErrors?: boolean;
  shouldShowErrorMessages?: boolean;
};

export type ShouldShowErrorForFieldFunc = (props: {
  field: FormStructureField;
  issues?: Array<z.ZodIssue>;
}) => boolean;

export type HandleChangeFunc = (arg1: string | FormStructureField) => ChangeEvent;

export type HandleFocusFunc = (path: string) => FormFocusEvent;

export type ChangeEvent = (e: AnyEvent, props: FormChangeEventProps) => void;

export type FormDescriptionType = JSX.Element | string;

export type FormFieldValue =
  | string
  | number
  | Array<string>
  | AdditionalOwners
  | HeiApplicationConsent
  | null;

export interface ValidationProps {
  fieldValue?: FormFieldValue;
  field?: FormStructureField;
  getFieldValueByPath?: GetFieldValueByPathFunc;
}

export type ValidationFuncReturnType = true | z.ZodError;

export type ValidationFunc = (props?: ValidationProps) => ValidationFuncReturnType;
export type FormLabel = ReactNode;

export interface FormStructureField {
  path: string; // unique string path (used to update store)
  relatedPaths?: Array<string>;
  parentPath?: string;
  label?: FormLabel; // will add a label around the component separate from the placeholder text
  description?: FormDescriptionType; // shows as text under the field label
  validation?: ValidationFunc;
  Component?: TSFixMe; // an input component
  props?: Record<string, unknown>; // the props that get passed to Component
}

export type GetFieldValueByPathFunc = (path: string) => FormFieldValue;
export type GetBaseFormFieldPropsFunc = (field: FormStructureField) => FieldProps;
export type ShowFunc = (props: {
  correcting?: boolean;
  getFieldValueByPath: GetFieldValueByPathFunc;
}) => boolean;

export interface FormStructureSection {
  key: string; // used only for components unique key complaining
  majorLabel?: string; // puts a page spanning label over the section
  description?: FormDescriptionType; // shows as text under the field label
  label?: FormLabel; // labels a group of fields
  cols?: number; // how many fields to fit horizontally
  fields?: Array<FormStructureField>; // form fields
  inset?: boolean; // moves the field inward and add a vertical line
  show?: ShowFunc; // leverage to hide/show a section
}

export type SubmitApplicationError = {
  status: number;
  data: ApplicationApiResponse;
};

export type FormStructure = Array<FormStructureSection>;

export const SingleSpaceMask = new InputMask({
  type: InputMaskType.AlphaNumeric,
  formatter: (value) => String(value).replace(/\s{2}/g, ' '),
});
