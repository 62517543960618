import React from 'react';
import HeiEstimatorPage from 'containers/hei/HeiEstimatorPage';
import { useFollowUpFetch } from 'containers/prequal/hooks';
import EstimateCalculatorPage from '..';
import i18n from './i18n';

const EstimateCalculatorEstimatorPage = () => {
  const followUpFetchResults = useFollowUpFetch();
  const { followUp } = followUpFetchResults;

  const estimate = followUp?.getEstimate();
  return (
    <EstimateCalculatorPage followUpFetchResults={followUpFetchResults} pageName={i18n.pageName}>
      <HeiEstimatorPage
        followUp={followUp}
        estimate={estimate}
        isFinalPricing={!estimate?.preliminary}
      />
    </EstimateCalculatorPage>
  );
};

export default EstimateCalculatorEstimatorPage;
