import React from 'react';
import { directory } from '@pointdotcom/pds';
import { usePointContact } from 'containers/prequal/hooks/usePointContact';
import * as styles from './styles';

const ContactLinks = ({ product, className }) => {
  const { PointNumber, PointEmail } = directory;
  const { supportNumber } = usePointContact({ product });

  return (
    <styles.ContactLinksStyle className={className}>
      <div>
        <a href={`mailto: ${PointEmail.Support}`}>Email Us</a>
      </div>
      <div>
        General inquiries: <a href={`tel: ${supportNumber}`}>{supportNumber}</a>
      </div>
      <div>
        Servicing: <a href={`tel: ${PointNumber.Servicing}`}>{PointNumber.Servicing}</a>
      </div>
      <div>Fax: {PointNumber.Fax}</div>
    </styles.ContactLinksStyle>
  );
};

export default ContactLinks;
