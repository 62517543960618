export default {
  maybeYou: 'Maybe you noticed—Point offers something different...',
  itsCalled: 'It’s called a Home Equity Investment, and it’s simple',
  weInvest: 'We invest in your home, giving you cash today.',
  whenYou:
    'When you decide to sell or refinance any time in the next %term%, we earn part of the proceeds.',
  repayWhen: 'Repay when it makes sense for you',
  pointTies:
    'Point ties repayment to the time when you’re flush—when you’re selling or refinancing your home, all in one lump sum. Repay at any time during the %term% term, with no prepayment penalty. ',
  yourCost:
    'Your cost is based on how much your home appreciates over the life of the contract, not an interest rate.',
};
