import React, { useEffect, useRef } from 'react';
import {
  Container,
  DirectionAndPlacement,
  Header,
  HeaderProps,
  Size,
  useScrollSpy,
} from '@pointdotcom/pds';
import FollowSection from 'components/FollowSection';
import RangeBuyBackTime from 'components/RangeBuyBackTime';
import RangeEstimatePrepayment, {
  RangeEstimatePrepaymentProps,
} from 'components/RangeEstimatePrepayment';
import OfferEstimateModel from 'models/OfferEstimateModel';
import PricingRangeModel from 'models/PriceRangeModel';
import { Products } from 'store/constants';
import BuyBackControlSection from './BuyBackControlSection';
import { ScenariosTableHeader } from './ScenariosTable';
import i18n from './i18n';
import * as styles from './styles';

interface FixedControlsProps {
  scrollShown?: boolean;
  pricingRange: null | PricingRangeModel;
  setBuyBackTime: (newValue: number | string) => unknown;
  getBuyBackTime: () => string | number;
  product: Products;
  estimate: null | OfferEstimateModel;
  onBuyBackScrollShownChange?: (scrollShown: boolean) => unknown;
}

export function FixedControls({
  scrollShown = false,
  pricingRange,
  setBuyBackTime,
  getBuyBackTime,
  estimate,
  onBuyBackScrollShownChange,
}: FixedControlsProps) {
  const prevScrollShownRef = useRef(scrollShown);
  useEffect(() => {
    const prevScrollShown = prevScrollShownRef.current;
    prevScrollShownRef.current = scrollShown;

    if (scrollShown !== prevScrollShown) {
      onBuyBackScrollShownChange?.(scrollShown);
    }
  }, [onBuyBackScrollShownChange, scrollShown]);

  const dropdownIncrementable = !estimate || !pricingRange?.hasMinEstimatesForUIControl();

  const rangeProps: RangeEstimatePrepaymentProps['rangeProps'] = {
    dropdownIncrementable,
    styleSize: Size.Default,
    thumbSize: undefined,
    afterLabelFormatter: () => '',
    showMinMaxLabels: false,
  };

  const headerProps: HeaderProps = {
    noMargin: true,
    styleSize: Size.Small,
    styleAlign: DirectionAndPlacement.Left,
    styleAlignMobile: dropdownIncrementable
      ? DirectionAndPlacement.Center
      : DirectionAndPlacement.Left,
  };

  const headerText = i18n.buyBackIn;

  return (
    <FollowSection show={scrollShown}>
      <styles.FollowControlsContainer>
        <Container>
          {estimate && pricingRange?.hasMinEstimatesForUIControl() && (
            <styles.FollowControlContainer>
              <Header {...headerProps}>{i18n.amountDesired}</Header>
              <RangeEstimatePrepayment
                rangeProps={rangeProps}
                pricingRange={pricingRange}
                estimate={estimate}
              />
            </styles.FollowControlContainer>
          )}
          <styles.FollowControlContainer>
            <Header {...headerProps}>{headerText}</Header>
            <RangeBuyBackTime
              rangeProps={rangeProps}
              estimate={estimate}
              buyBackTime={getBuyBackTime()}
              setBuyBackTime={setBuyBackTime}
            />
          </styles.FollowControlContainer>
        </Container>
      </styles.FollowControlsContainer>
      <ScenariosTableHeader collapsed />
    </FollowSection>
  );
}

interface PrepaymentAndBuyBackSectionProps {
  product: Products;
  estimate: null | OfferEstimateModel;
  pricingRange: PricingRangeModel;
  onBuyBackScrollShownChange: (scrollShown: boolean) => unknown;
  getBuyBackTime: () => string | number;
  setBuyBackTime: (newValue: number | string) => unknown;
}

const PrepaymentAndBuyBackSection = ({
  product,
  estimate,
  pricingRange,
  onBuyBackScrollShownChange,
  getBuyBackTime,
  setBuyBackTime,
}: PrepaymentAndBuyBackSectionProps) => {
  const ref = useRef(null);

  const { scrollIsPastSpyBottom } = useScrollSpy({
    spyOn: ref,
  });

  return (
    <>
      <BuyBackControlSection
        ref={ref}
        estimate={estimate}
        getBuyBackTime={getBuyBackTime}
        setBuyBackTime={setBuyBackTime}
      />
      <FixedControls
        product={product}
        onBuyBackScrollShownChange={onBuyBackScrollShownChange}
        pricingRange={pricingRange}
        getBuyBackTime={getBuyBackTime}
        setBuyBackTime={setBuyBackTime}
        estimate={estimate}
        scrollShown={scrollIsPastSpyBottom}
      />
    </>
  );
};

export default PrepaymentAndBuyBackSection;
