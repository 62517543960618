import { useEffect } from 'react';
import { getUtmParams } from 'lib/utmParameters';
import { setUtmParameters } from 'store/general';
import { useDispatch } from 'store/hooks';

export const useUtmParameters = () => {
  const dispatch = useDispatch();

  // Look for UTM params: first in query string, then check cookies.
  useEffect(() => {
    const utmParameters = getUtmParams(window.location);

    if (utmParameters) {
      dispatch(setUtmParameters(utmParameters));
    }
  }, [dispatch]);
};
