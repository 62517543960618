import styled from 'styled-components';
import { mixins } from '@pointdotcom/pds';
import { HeroSectionLeftStyle, HeroSectionRightStyle } from 'containers/EstimatorPage/styles';

const ListStyle = styled.ol.attrs({ className: 'ListStyle' })`
  font-size: ${(props) => mixins.getRemFromPx(20, props.theme.globals.baseFontSize.mobile)};
  margin: 0;
`;

const ListItemStyle = styled.li.attrs({ className: 'ListItemStyle' })`
  margin-bottom: 2em;
  padding-left: 0.5em;

  &:last-child {
    margin-bottom: 0;
  }

  .unbold {
    font-weight: normal;
  }
`;

const AgreementHeroLeftStyle = styled(HeroSectionLeftStyle)`
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding-bottom: 74px;
  }
`;

const AgreementHeroRightStyle = styled(HeroSectionRightStyle)`
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding-top: 74px;
  }
`;

const AboutContentStyle = styled.div.attrs({ className: 'AboutContentStyle' })`
  padding: 12%;
  border: 1px solid ${(props) => props.theme.line.color.default};
  position: relative;

  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    border: none;
    padding: 0;
  }
`;

const LaptopImageStyle = styled.div.attrs({ className: 'BikeImageStyle' })`
  background-image: url(https://cdn.point.com/laptop-RE2j7UzPYG2oRZa3RhJfhyRs3NxBXsfLNPTpXiuFuju.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  position: absolute;
  width: 300px;
  height: 190px;
  bottom: -110px;
  left: 60px;

  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    display: none;
  }
`;

const LaptopImageMobileStyle = styled.div.attrs({ className: 'BikeImageStyle' })`
  background-image: url(https://cdn.point.com/laptop-RE2j7UzPYG2oRZa3RhJfhyRs3NxBXsfLNPTpXiuFuju.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin: 0 auto;
  width: 66%;
  display: none;
  height: 120px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  bottom: 0;

  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    display: block;
  }
`;

export {
  ListStyle,
  ListItemStyle,
  AboutContentStyle,
  LaptopImageStyle,
  LaptopImageMobileStyle,
  AgreementHeroLeftStyle,
  AgreementHeroRightStyle,
};
