import styled, { css } from 'styled-components';
import { ButtonStyles, IconStyles, mixins } from '@pointdotcom/pds';
import { GenericMessageBannerStyle } from 'components/GenericMessageBanner/styles';
import { pxToRem } from 'styleHelpers';

export const PaginatorItemStyle = styled.div.attrs({
  className: 'PaginatorItemStyle',
})<{
  show?: boolean;
}>`
  font-family: ${({ theme }) => theme.fonts.serif.name};
  font-size: ${pxToRem(20)};
  padding-bottom: 2rem;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;
  em {
    padding-inline: 0.25em;
    display: inline-block;
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.17;
      z-index: -1;
    }
  }
  ${GenericMessageBannerStyle} {
    &:empty {
      display: none;
    }
  }
  ${ButtonStyles.ButtonBaseStyle} {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.line.color.invertedSubtle};
    color: white;
    font-family: inherit;
    font-size: inherit;
    font-weight: normal;
    width: 60%;
    margin: 0 auto;
    ${mixins.hover(css`
      border-color: currentColor;
    `)}
    ${IconStyles.IconStyle} {
      path {
        fill: currentColor;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    font-size: ${pxToRem(16)};
    ${ButtonStyles.ButtonBaseStyle} {
      width: 100%;
    }
  }
`;
