import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { mix } from 'polished';
import FlipNumbers from 'react-flip-numbers';
import { useSwipeable } from 'react-swipeable';
import { Transition } from 'react-transition-group';
import { useTheme } from 'styled-components';
import {
  Button,
  Container,
  DirectionAndPlacement,
  Dropdown,
  DropdownChangeEvent,
  DropdownChangeEventProps,
  Header,
  Icon,
  IconName,
  Loader,
  Size,
  SplashText,
  Style,
  TemplatedText,
} from '@pointdotcom/pds';
import { Vr } from 'components/HrVr';
import Paginator from 'components/Paginator';
import { TRANSITION_SPEED_MS } from 'components/Paginator/styles';
import SectionShadow from 'components/SectionShadow';
import scenarios, { ScenarioType } from 'containers/EstimatorPage/ScenariosTable/scenarios';
import { PaginationMethod, captureProperties, usePostHogEvents } from 'lib/posthogEvents';
import PricingModel from 'models/PricingModel';
import { currencyMask } from 'models/helpers';
import {
  getHEIEstimateModel,
  getHEIPriceRangeSelectedIndex,
  getPriceRangeModel,
  setHEIPriceRangeSelectedIndex,
} from 'store/estimates';
import { useDispatch, useSelector } from 'store/hooks';
import { getSlideContent } from './SlideContent';
import { IntroGraphics, SlideGraphics } from './SlideGraphics';
import { SlideData } from './constants';
import i18n from './i18n';
import * as styles from './styles';

function generateGradientSteps(color1: string, color2: string, steps: number): string[] {
  const colors: string[] = [];
  for (let i = 0; i <= steps; i++) {
    // Calculate the ratio for mixing
    const ratio = i / steps;
    const color = mix(ratio, color2, color1);
    colors.push(color);
  }
  return colors;
}

export default function PricingCarouselSection() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const selectedIndex = useSelector(getHEIPriceRangeSelectedIndex);
  const estimate = useSelector(getHEIEstimateModel);
  const [containerHeight, setContainerHeight] = useState(0);
  const [isInCarouselMode, setIsInCarouselMode] = useState(false);
  const paginatorRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const indexAtBeginning = currentIndex < 0;
  const pricingRange = useSelector(getPriceRangeModel);
  const posthogEvents = usePostHogEvents();
  const amountOptions = useMemo(
    () =>
      (pricingRange?.estimates as Array<PricingModel>)?.map((pricingRangePricing, idx) => {
        return {
          text: pricingRangePricing.getFormattedOptionInvestmentAmount(),
          value: `${idx}`,
        };
      }) || [],
    [pricingRange]
  );

  const slideData = useMemo<SlideData | null>(() => {
    const pricing = estimate?.getPricing();
    if (!estimate || !pricing) return null;
    const scenario = scenarios[ScenarioType.MODERATE];
    const appreciationPerc = (scenario.appreciationPerc ?? 0) / 100;

    const optionPercentage = pricing?.getFormattedOptionPercentage();
    const durationInYears = 8;
    const homeValueAppreciated = estimate.getHomeValueScenario({
      appreciationPerc,
      durationInYears,
    });
    const appreciatedDiff = homeValueAppreciated - pricing.getRiskAdjustedHomeValue();
    const appreciatedOwnerPays = Math.round(appreciatedDiff * pricing?.getOptionPercentage());
    const totalOwnerPays = pricing?.optionInvestmentAmount
      ? appreciatedOwnerPays + pricing.optionInvestmentAmount
      : 0;
    return {
      estimate,
      term: estimate.getTerm(),
      optionPercentage,
      selectedAmount: pricing.getFormattedOptionInvestmentAmount(),
      homeValue: pricing.getFormattedHomeValue(),
      homeValueRiskAdjusted: pricing.getFormattedRiskAdjustedHomeValue(),
      homeValueAppreciated: currencyMask.getFormatted(homeValueAppreciated),
      appreciatedDiff: currencyMask.getFormatted(appreciatedDiff),
      appreciatedOwnerPays: currencyMask.getFormatted(appreciatedOwnerPays),
      totalOwnerPays: currencyMask.getFormatted(totalOwnerPays),
      capApr: pricing.getFormattedCapPercentage(),
      equivApr: estimate.getFormattedEquivAPR({
        appreciationPerc,
        durationInYears,
        pointsShareCustom: totalOwnerPays,
      }),
    };
  }, [estimate]);
  const slides = useMemo(() => (slideData ? getSlideContent(slideData) : []), [slideData]);
  const indexIsAtEnd = currentIndex >= slides.length - 1;

  // handle matching the container height to the paginators content height
  useEffect(() => {
    if (paginatorRef.current) {
      const childrenArray = Array.from(paginatorRef.current.children) as HTMLElement[];

      const getHeights = () => {
        const heights = childrenArray.map((child) => child.offsetHeight);
        const maxHeight = Math.max(...heights);
        setContainerHeight(maxHeight);
      };

      const observer = new ResizeObserver(getHeights);
      childrenArray.forEach((child) => observer.observe(child));

      return () => {
        childrenArray.forEach((child) => observer.unobserve(child));
      };
    }
  }, [slides]);

  const paginate = useCallback(
    (
      direction: DirectionAndPlacement.Left | DirectionAndPlacement.Right,
      method?: PaginationMethod
    ) =>
      () => {
        const inc = direction === DirectionAndPlacement.Left ? -1 : 1;
        const nextIndex = currentIndex + inc;
        if (nextIndex < 0) {
          setIsInCarouselMode(false);
          return;
        }

        if (nextIndex >= slides.length) {
          return;
        }

        if (method) {
          posthogEvents.capturePricingCarouselPaginated({
            method,
            direction,
            item: nextIndex,
          });
        }

        setCurrentIndex(nextIndex);
      },
    [posthogEvents, currentIndex, slides]
  );

  const gradientColors = generateGradientSteps(theme.Color.PurpleBlue6, '#9B8566', slides.length);
  const slideColor = gradientColors[currentIndex];

  const handleAmountChange: DropdownChangeEvent = (e, props) => {
    const { selectedIndex: dropdownSelectedIndex } = props as DropdownChangeEventProps;
    dispatch(setHEIPriceRangeSelectedIndex(dropdownSelectedIndex));
    setIsLoading(true);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isLoading) {
      timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, styles.LOADING_DURATION_MS);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isLoading]);

  const miniAmountDropdown = (
    <Dropdown
      noMargin
      styleSize={Size.Small}
      value={selectedIndex}
      onChange={handleAmountChange}
      options={amountOptions}
      key="amountDropdown"
    />
  );

  const carouselTitle = (
    <styles.CarouselTitleStyle>
      <SplashText italic as="div">
        <TemplatedText
          values={{
            amount: () => miniAmountDropdown,
          }}
        >
          {i18n.yourPersonalizedPricing}
        </TemplatedText>
      </SplashText>
    </styles.CarouselTitleStyle>
  );

  const handleStartPricingCarousel = useCallback(() => {
    setIsInCarouselMode(true);
    posthogEvents.capturePricingCarouselStarted();
  }, [posthogEvents]);

  // TODO: integrate this into the paginator component
  const swipeHandlers = useSwipeable({
    onSwiped: ({ dir }) => {
      let normalizedDirection = DirectionAndPlacement.Left;
      if (dir === 'Left') normalizedDirection = DirectionAndPlacement.Right;
      else if (dir === 'Right') normalizedDirection = DirectionAndPlacement.Left;
      else return;
      paginate(normalizedDirection, PaginationMethod.Swipe)();
    },
  });

  return (
    <styles.PricingCarouselSectionStyle
      isLoading={isLoading}
      {...captureProperties({
        ['pricing_carousel_item']: isInCarouselMode ? `${currentIndex}` : 'intro',
      })}
    >
      <SectionShadow stylePosition={DirectionAndPlacement.Top} />
      <Container>
        <Transition
          in={isInCarouselMode}
          timeout={{ enter: styles.MODE_ANIM_SPEED_MS, exit: styles.MODE_ANIM_SPEED_MS }}
          classNames="carouselMode"
        >
          {(animationStatus) => (
            <styles.CarouselContentWrapperStyle
              animationStatus={animationStatus}
              isCarouselMode={['entering', 'entered'].includes(animationStatus)}
            >
              {pricingRange && carouselTitle}
              <styles.CarouselContainerStyle slideColor={slideColor} {...swipeHandlers}>
                <styles.PaginatorButtonStyle
                  aria-label={i18n.previousSlide}
                  onClick={paginate(DirectionAndPlacement.Left, PaginationMethod.LeftRightButton)}
                  disabled={indexAtBeginning || !isInCarouselMode}
                  slideColor={slideColor}
                >
                  <span aria-label={i18n.previousSlide}>
                    <Icon name={IconName.ChevronLeft} styleSize={15} strokeSize={2} />
                  </span>
                </styles.PaginatorButtonStyle>
                <styles.PaginatorButtonStyle
                  aria-label={i18n.nextSlide}
                  onClick={paginate(DirectionAndPlacement.Right, PaginationMethod.LeftRightButton)}
                  disabled={indexIsAtEnd || !isInCarouselMode}
                  slideColor={slideColor}
                >
                  <span aria-label={i18n.nextSlide}>
                    <Icon name={IconName.ChevronRight} styleSize={15} strokeSize={2} />
                  </span>
                </styles.PaginatorButtonStyle>
                <header>
                  {carouselTitle}
                  <styles.SliderHeaderStyle
                    sideLines
                    inverted
                    styleAlign={DirectionAndPlacement.Center}
                    styleSize={Size.Small}
                  >
                    <FlipNumbers
                      height={12}
                      width={7}
                      play
                      numbers={(currentIndex + 1).toString()}
                      color="inherit"
                      duration={(TRANSITION_SPEED_MS / 1000) * 2}
                    />{' '}
                    of {slides.length}
                  </styles.SliderHeaderStyle>
                </header>
                <styles.IntroContentStyle>
                  <header>
                    <Header
                      styleSize={Size.Large}
                      styleAlign={DirectionAndPlacement.Center}
                      maxWidth="70%"
                      noMargin
                    >
                      {i18n.seeYourPersonalized}
                    </Header>
                    <SplashText styleAlign={DirectionAndPlacement.Center} noMargin>
                      {i18n.firstSelect}
                    </SplashText>
                    <Dropdown
                      noMargin
                      styleSize={Size.Splash}
                      styleAlignText={DirectionAndPlacement.Center}
                      value={selectedIndex}
                      onChange={handleAmountChange}
                      options={amountOptions}
                      key="amountDropdown"
                    />
                  </header>
                  <styles.IntroNavStyle>
                    <Button onClick={handleStartPricingCarousel} block>
                      {i18n.next}
                    </Button>
                  </styles.IntroNavStyle>
                </styles.IntroContentStyle>
                <styles.CarouselContentContainerStyle style={{ height: `${containerHeight}px` }}>
                  <styles.LoaderContainerStyle slideColor={slideColor}>
                    <Loader />
                  </styles.LoaderContainerStyle>
                  <Paginator items={slides} currentIndex={currentIndex} ref={paginatorRef} />
                </styles.CarouselContentContainerStyle>
                <styles.CarouselNavStyle>
                  <button
                    onClick={paginate(DirectionAndPlacement.Left, PaginationMethod.PrevNextButton)}
                    disabled={indexAtBeginning || !isInCarouselMode}
                    aria-label={i18n.previousSlide}
                  >
                    {i18n.prev}
                  </button>
                  <Vr styleType={Style.InvertedSubtle} height="2rem" />
                  <button
                    onClick={paginate(DirectionAndPlacement.Right, PaginationMethod.PrevNextButton)}
                    disabled={indexIsAtEnd || !isInCarouselMode}
                    aria-label={i18n.nextSlide}
                  >
                    {i18n.next}
                  </button>
                </styles.CarouselNavStyle>
              </styles.CarouselContainerStyle>

              <styles.CarouselGraphicsContainerStyle>
                <IntroGraphics show={!isInCarouselMode} />
                <styles.CarouselSlideGraphicsContainerStyle>
                  <SlideGraphics
                    currentIndex={currentIndex}
                    isInCarouselMode={isInCarouselMode}
                    {...slideData}
                  />
                </styles.CarouselSlideGraphicsContainerStyle>
              </styles.CarouselGraphicsContainerStyle>
            </styles.CarouselContentWrapperStyle>
          )}
        </Transition>
      </Container>
      <SectionShadow stylePosition={DirectionAndPlacement.Bottom} />
    </styles.PricingCarouselSectionStyle>
  );
}
