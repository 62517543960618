import { css } from 'styled-components';
import ringsImage from './images/rings.png';

export const ringsCSS = css`
  background-image: url('${ringsImage}');
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: -410% 480%;
  width: 50%;
  height: 50%;
  position: absolute;
  bottom: 0;
  filter: brightness(0);
  left: 95%;
  opacity: 0.8;
  z-index: -1;
`;
