import styled from 'styled-components';
import { LogoStyles } from '@pointdotcom/pds';

const sectionSeparationPadding = '5em';
const layoutBreak = '730px';

export const MailerLandingStyle = styled.div.attrs({ className: 'MailerLandingStyle' })`
  color: white;
  form {
    margin: 2em 0 1.4em;
  }

  .heroContainer {
    display: flex;
    flex-wrap: wrap;

    .leftCol,
    .rightCol {
      position: relative;
      flex: 50%;
      @media only screen and (max-width: ${layoutBreak}) {
        flex: 100%;
      }
    }

    .leftCol {
      height: 100px;

      img {
        position: absolute;
      }

      @media only screen and (max-width: ${layoutBreak}) {
        height: 100px;
        flex: 100%;
      }
    }

    .rightCol {
      z-index: 2;
      text-align: left;
      .thisWont {
        text-align: center;
      }
    }
  }

  .mailerImg {
    position: absolute;
  }

  .mailerImg {
    top: -38px;
    @media only screen and (max-width: ${layoutBreak}) {
      top: -115%;
      left: -65px;
      width: 263px;
    }
  }

  .FeaturedIn {
    position: relative;
    z-index: 2;
  }

  ${LogoStyles.LogoStyle} {
    position: relative;
    z-index: 2;
    margin-left: 92px;
  }

  .topSection {
    background-color: ${(props) => props.theme.Color.PurpleBlue6};
    @media only screen and (max-width: ${layoutBreak}) {
      padding: 0 5%;
    }
  }

  .container,
  .mainContainer {
    width: ${(props) => props.theme.container.width.medium};
  }

  .mainContainer {
    @media only screen and (max-width: ${(props) => props.theme.container.width.medium}) {
      width: auto !important;
    }
    padding-top: ${sectionSeparationPadding};
    padding-bottom: ${sectionSeparationPadding};
    color: white;

    .column {
      // border: 1px solid red;
      padding: 0;
    }

    .header {
      font-size: 2.8em;
      margin-bottom: 0.2em;
      @media only screen and (max-width: ${(props) => props.theme.responsive.mobileLrgBreakpoint}) {
        font-size: 1.9em;
      }
    }

    .dontHave {
      color: inherit;
      text-decoration: underline;
      display: inline-block;

      &:hover {
        text-decoration: none;
      }
    }

    .sub {
      font-family: $fontBold;
    }

    .headerContainer {
      text-align: left;
      @media only screen and (max-width: ${layoutBreak}) {
        text-align: right;
      }
    }
  }

  .HelpTextStyle {
    color: inherit;
    background: transparent;
  }

  // TODO: If this is used more than once, add to PDS
  .InputContainerStyle {
    .InputStyle {
      &:focus {
        border-color: transparent;
      }
    }

    &.active {
      .input input {
        border-color: darken(${(props) => props.theme.Color.PurpleBlue6}, 2%);
      }

      .LabelTextStyle {
        color: white;
        top: -13px;

        &::before {
          display: none;
        }
      }
    }
  }
  .tpSectionStyle {
    background: ${(props) => props.theme.Color.GrayLight1};
    border-bottom: 1px solid ${(props) => props.theme.line.color.default};
  }
`;
