import { DirectionAndPlacement } from '@pointdotcom/pds';
import OfferEstimateModel from 'models/OfferEstimateModel';
import aboutYouTemplate from './ApplicationForm/FormPages/AboutYouForm';
import financialsTemplate from './ApplicationForm/FormPages/FinancialsForm';
import propertyTemplate from './ApplicationForm/FormPages/PropertyForm';
import reviewTemplate from './ApplicationForm/FormPages/ReviewForm';
import { FormStructure } from './ApplicationForm/constants';
import navI18n from './ApplicationPageNav/i18n';

export type PageNavigationFunctionType = (direction: DirectionAndPlacement) => void;
export type FormTemplateFunction = (options: FormTemplateOptions) => FormStructure;

export enum ApplicationPageType {
  AboutYou = 'AboutYou',
  Property = 'Property',
  Financials = 'Financials',
  Review = 'Review',
}

export interface FormTemplateOptions {
  isApplicationHEIAmountTestEnabled: boolean;
  estimate?: OfferEstimateModel;
}

type PageTemplateMap = {
  [key in ApplicationPageType]: FormTemplateFunction;
};

export const pageTitleMap = {
  [ApplicationPageType.AboutYou]: navI18n.aboutYou,
  [ApplicationPageType.Financials]: navI18n.financials,
  [ApplicationPageType.Property]: navI18n.property,
  [ApplicationPageType.Review]: navI18n.review,
};

// NOTE: this dictates the order of the pages
export const pageTemplateMap: PageTemplateMap = {
  [ApplicationPageType.AboutYou]: aboutYouTemplate,
  [ApplicationPageType.Property]: propertyTemplate,
  [ApplicationPageType.Financials]: financialsTemplate,
  [ApplicationPageType.Review]: reviewTemplate,
};
