import bugsnagClient from 'lib/bugsnagClient';
import BaseModel from 'models/BaseModel';
import PointPerson from './pointPerson';

/**
 * Based on the response item from the youCanBookMe API
 * curl "https://middleware.point.com/api/youcanbook/calendar"
 * If busy is true, these properties will not be present
 *
 * required: ['busy', 'timestamp', 'time', 'date'],
 * optional: ['href', 'memberId', 'memberName'],
 */

export default class Availability extends BaseModel {
  static NAMESPACE = 'Availability';

  static CONFIG = {
    required: ['busy', 'timestamp', 'time', 'date'],
    optional: ['href', 'memberId', 'memberName', 'calendar', 'memberPicture'], // If busy is true, these properties will not be present
  };

  constructor(data) {
    super(data, Availability.CONFIG, Availability.NAMESPACE);
  }

  getPointPersonModel() {
    const firstLastName = this.memberName ? this.memberName.split(' ') : [];
    const [firstName, lastName] = firstLastName;
    const pic = this.memberPicture;
    const person = new PointPerson({ firstName, lastName, pic });

    if (!this.memberName) {
      bugsnagClient.notify(new Error('No member name being returned from calendar/ api call'));
    } else if (!person) {
      bugsnagClient.notify(
        new Error('A person model cannot be created from the calendar/ api call response')
      );
    }

    return person;
  }
}
