import styled from 'styled-components';
import { LogoStyles } from '@pointdotcom/pds';

export const MainHeaderStyle = styled.header.attrs({ className: 'MainHeaderStyle' })`
  padding: 4% 0 3%;
  @media only screen and (max-width: $largestMobileScreen) {
    padding: 7% 0 6%;
  }
  ${LogoStyles.LogoStyle} {
    display: inline-block;
    width: 80px;
    height: 54px;
    @media only screen and (max-width: $largestMobileScreen) {
      width: 70px;
    }
  }
`;
