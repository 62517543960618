import styled from 'styled-components';

export const PlayButtonStyle = styled.div.attrs({ className: 'PlayButtonStyle' })<{
  inverted?: boolean;
}>`
  --bg-color: ${({ theme }) => theme.Color.Yorange};
  --fg-color: ${({ theme }) => theme.Color.White};
  --size: 5.5rem;
  @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    --size: 4.5rem;
  }
  @media (max-width: ${({ theme }) => theme.responsive.mobileMidBreakpoint}) {
    --size: 3.5rem;
  }

  display: block;
  width: var(--size);
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: ${({ inverted = false }) => (inverted ? 'var(--fg-color)' : 'var(--bg-color)')};
  border: none;
  position: relative;
  box-shadow: 0 0rem 1.5rem rgba(0, 0, 0, 0.2);
  transition: all 200ms ease-in-out;

  &::after {
    content: '';
    height: 30%;
    aspect-ratio: ${Math.sqrt(3) / 2};
    clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
    background: ${({ inverted = false }) => (inverted ? 'var(--bg-color)' : 'var(--fg-color)')};

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-33.3%, -50%);
  }
`;
