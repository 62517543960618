import React from 'react';
import { PlayButtonStyle } from 'components/VideoPlayer/PlayButton';
import { VideoButtonProps, VideoButtonStyleType } from './constants';
import i18n from './i18n';
import * as styles from './styles';

export const imgThumbnail = 'https://cdn.point.com/quiz_flow_placeholder_video.png';

const PlayButton = ({ label }: Pick<VideoButtonProps, 'label'>) => (
  <PlayButtonStyle aria-label={label} />
);

const VideoThumbnail = () => {
  return (
    <styles.ThumbnailStyle>
      <img src={imgThumbnail} alt={i18n.thumbnailImageAlt} />
      <PlayButton />
    </styles.ThumbnailStyle>
  );
};

const VideoButton = ({
  styleType = VideoButtonStyleType.Default,
  onClick,
  label = i18n.playButtonLabel,
}: VideoButtonProps) => {
  const Style =
    styleType === VideoButtonStyleType.Mini ? styles.VideoButtonMiniStyle : styles.VideoButtonStyle;

  return (
    <Style onClick={onClick} aria-label={styleType !== VideoButtonStyleType.Icon ? label : ''}>
      {styleType === VideoButtonStyleType.Icon ? <PlayButton label={label} /> : <VideoThumbnail />}
    </Style>
  );
};

export default VideoButton;
