export default {
  emailMeMyOffer: 'Email me my offer',
  saveThisOffer: 'Save this offer',
  firstName: 'First name',
  lastName: 'Last name',
  phoneNumber: 'Phone number',
  email: 'Email',
  thanksName: 'Thanks %firstName%!',
  weveSentALink:
    'We’ve sent a link to your email so that you can revisit your offer whenever you’d like. Someone from Point will call you soon to answer any questions you may have.',
  inTheMeantime: 'In the meantime, follow the link below to get started on your application.',
  applyNow: 'Apply now',
  homeowner: 'Homeowner',
  pleaseEnter: 'Please enter your contact details so we know where to send your offer.',
};
