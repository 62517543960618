import styled from 'styled-components';

export const FieldContainerStyle = styled.div.attrs({ className: 'FieldContainerStyle' })`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
  margin-bottom: 1rem;
  > * {
    &:first-child {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
`;
