import styled from 'styled-components';
import { ContainerStyles } from '@pointdotcom/pds';
import { paddedSectionPaddingMobile } from 'styles';

export const MobilePaginatorWrapperStyle = styled.div.attrs({
  className: 'MobilePaginatorWrapperStyle',
})`
  display: none;
  padding-top: ${paddedSectionPaddingMobile};
  ${ContainerStyles.ContainerStyle} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    display: block;
  }
`;
