import styled from 'styled-components';

export const CapShieldStyle = styled.div.attrs({ className: 'CapShieldStyle' })`
  display: grid;

  /* Image overhangs the bounding box by 15% */
  > picture > img {
    display: block;
    margin: -15%;
    width: 130%;
  }
`;
