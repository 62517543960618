import React from 'react';
import dayjs from 'dayjs';
import {
  Button,
  DirectionAndPlacement,
  Header,
  IconName,
  LinkButton,
  Size,
  SplashText,
  Style,
  TemplatedText,
  useIsMobile,
} from '@pointdotcom/pds';
import { HorizontalBracket } from 'components/HorizontalBracket/HorizontalBracket';
import { currencyMask, percMask } from 'models/helpers';
import { ContractExitDetails } from 'services/apiTypes/contractTypes';
import {
  ContentMode,
  ContentModeExcludingSummary,
  ExitValues,
  ForwardNavigation,
  contentMap,
  expressionLabelTextMap,
} from './constants';
import i18n from './i18n';
import shieldImage from './images/shield.png';
import * as styles from './styles';

interface PayoffExpressionProps {
  mode: ContentModeExcludingSummary;
  exitData?: ContractExitDetails;
  showFooterCloseButton?: boolean;
  onNext: () => void;
  modalClose: () => void;
}

export const getPayoffValuesFromModeAndExitData = ({
  mode,
  exitData,
}: Pick<PayoffExpressionProps, 'mode' | 'exitData'>) => {
  if (!exitData) return null;

  let payoffValues: ExitValues | null;
  const fundingDate = exitData.dateFunded
    ? dayjs(exitData.dateFunded).format('MMMM D, YYYY')
    : null;

  switch (mode) {
    case ContentMode.PayoffAmount:
      payoffValues = {
        primaryAmount: currencyMask.getFormatted(exitData.payoffEstimate),
        operand1: currencyMask.getFormatted(exitData.investmentPayment),
        operand2: currencyMask.getFormatted(exitData.pointShare),
        fundingDate,
      };
      break;
    case ContentMode.PointsShare:
      payoffValues = {
        primaryAmount: currencyMask.getFormatted(exitData.pointShare),
        operand1: `${exitData.optionPercentage}%`,
        operand2: currencyMask.getFormatted(exitData.appreciation),
      };
      break;
    case ContentMode.TotalAppreciation:
      payoffValues = {
        primaryAmount: currencyMask.getFormatted(exitData.appreciation),
        operand1: currencyMask.getFormatted(exitData.homeValue),
        operand2: currencyMask.getFormatted(exitData.originalAgreedValue),
      };
      break;
    case ContentMode.CappedPayoff:
      payoffValues = {
        primaryAmount: currencyMask.getFormatted(exitData.capAmount),
        operand1: currencyMask.getFormatted(exitData.uncappedAmount!),
        operand2: currencyMask.getFormatted(exitData.capAmount),
        investmentAmount: currencyMask.getFormatted(exitData.investmentPayment),
        capPercentage: percMask.getFormatted(exitData.capPercentage),
        pointShare: currencyMask.getFormatted(exitData.uncappedAmount - exitData.investmentPayment),
      };
      break;
    default:
      payoffValues = null;
      break;
  }
  return payoffValues;
};

const formatForAriaLabels = (str: string) => str.replace(/\s+/g, '-').toLowerCase();

const EquationDivider = () => {
  const { isMobile } = useIsMobile();

  return isMobile ? (
    <styles.VertLineStyle />
  ) : (
    <styles.BracketStyle>
      <HorizontalBracket fillOpacity=".30" />
    </styles.BracketStyle>
  );
};

export const Equation = ({
  mode,
  exitData,
  onNext,
}: Pick<PayoffExpressionProps, 'mode' | 'exitData'> & {
  onNext?: () => void;
}) => {
  const content = contentMap[mode];
  const payoffValues = getPayoffValuesFromModeAndExitData({
    exitData,
    mode,
  });

  if (!exitData || !payoffValues) return null;

  const { capped } = exitData;

  return (
    <styles.EquationSectionStyle>
      <styles.Operand1Style
        role="group"
        aria-labelledby={`operand-title-${formatForAriaLabels(content.operand1Title)}`}
        aria-describedby={
          content.operand1Description
            ? `section-description-${formatForAriaLabels(content.operand1Description)}`
            : undefined
        }
        capped={capped}
      >
        <div>
          <span id={`operand-title-${formatForAriaLabels(content.operand1Title)}`}>
            {content.operand1Title}
          </span>
          <SplashText noMargin>{payoffValues.operand1}</SplashText>
        </div>
        {content.operand1Description && (
          <styles.OperandDescriptionStyle
            id={
              content.operand1Description
                ? `operand-description-${formatForAriaLabels(content.operand1Description)}`
                : undefined
            }
          >
            <TemplatedText values={{ ...payoffValues }}>
              {content.operand1Description}
            </TemplatedText>
          </styles.OperandDescriptionStyle>
        )}
      </styles.Operand1Style>
      <styles.OperatorStyle
        aria-label={expressionLabelTextMap[content.expressionType]}
        role="img"
        capped={capped}
      >
        {content.expressionType}
      </styles.OperatorStyle>
      <styles.Operand2Style
        role="group"
        aria-labelledby={`operand-title-${formatForAriaLabels(content.operand2Title)}`}
        aria-describedby={
          content.operand2Description
            ? `operand-description-${formatForAriaLabels(content.operand2Description)}`
            : undefined
        }
        capped={capped}
      >
        {capped && <styles.ProtectionImageStyle src={shieldImage} alt="" />}
        <div>
          <span id={`operand-title-${formatForAriaLabels(content.operand2Title)}`}>
            {content.operand2Title}
          </span>
          <SplashText noMargin>{payoffValues.operand2}</SplashText>
        </div>
        {content.operand2Description && (
          <styles.OperandDescriptionStyle
            id={
              content.operand2Description
                ? `operand-description-${formatForAriaLabels(content.operand2Description)}`
                : undefined
            }
          >
            <TemplatedText values={{ ...payoffValues }}>
              {content.operand2Description}
            </TemplatedText>
          </styles.OperandDescriptionStyle>
        )}
        {content.forwardNavigation === ForwardNavigation.SeeTheMath && (
          <Button styleType={Style.Dark} onClick={onNext} iconType={IconName.ChevronRight} block>
            {i18n.seeTheMath}
          </Button>
        )}
        <styles.OperandConnectorLineContainer>
          <div className="topVertLine" />
          <div className="middleHorizLine" />
          <div className="bottomVertLine" />
        </styles.OperandConnectorLineContainer>
      </styles.Operand2Style>
    </styles.EquationSectionStyle>
  );
};

const PayoffExpressionModalContent = ({
  mode,
  exitData,
  onNext,
  modalClose,
  showFooterCloseButton,
}: PayoffExpressionProps) => {
  const content = contentMap[mode];
  const payoffValues = getPayoffValuesFromModeAndExitData({
    exitData,
    mode,
  });
  if (!exitData || !payoffValues) return null;

  return (
    <styles.PayoffExpressionStyle>
      <Header styleSize={Size.Large} styleAlign={DirectionAndPlacement.Center} tabIndex={-1}>
        {content.headline}
      </Header>
      <styles.SubheaderStyle>{content.description}</styles.SubheaderStyle>
      <styles.HeadlineAmountStyle
        styleSize={Size.Massive}
        styleAlign={DirectionAndPlacement.Center}
        noMargin
      >
        {payoffValues.primaryAmount}
      </styles.HeadlineAmountStyle>
      <EquationDivider />
      <Equation mode={mode} exitData={exitData} onNext={onNext} />
      {content.forwardNavigation === ForwardNavigation.Summary && (
        <styles.SummaryButtonStyle>
          <Button styleType={Style.Dark} onClick={onNext} block>
            {i18n.summary}
          </Button>
        </styles.SummaryButtonStyle>
      )}
      {showFooterCloseButton && (
        <styles.ContentFooterStyle>
          <LinkButton onClick={modalClose}>Close</LinkButton>
        </styles.ContentFooterStyle>
      )}
    </styles.PayoffExpressionStyle>
  );
};

export default PayoffExpressionModalContent;
