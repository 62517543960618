import styled, { createGlobalStyle } from 'styled-components';

const topBottomSectionHeight = '100px';
const topBottomSectionOffset = '1%';
const mobileBreakPoint = 'largestMobileScreen';
const CallUsSectionStyle = styled.div``;

export const BodyWithFooterStyle = createGlobalStyle`
  body {
    background: ${(props) => props.theme.footer.backgroundColor.default};
  }
`;

const FooterContainerStyle = styled.footer.attrs({ className: 'FooterContainerStyle' })`
  background: ${(props) => props.theme.footer.backgroundColor.default};
  padding-bottom: 10%;
  color: ${(props) => props.theme.Color.GrayLightestAccessible};
  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: ${(props) => props.theme.Color.PointBlack};
    }
  }
`;

const FooterSectionTopStyle = styled.div.attrs({ className: 'FooterSectionTopStyle' })`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.line.color.default};
  height: ${topBottomSectionHeight};
  align-items: center;
  padding-top: ${topBottomSectionOffset};
  > div {
    flex: 1;
  }

  ${CallUsSectionStyle} {
    text-align: right;
    @media (max-width: ${(props) => props.theme.responsive[mobileBreakPoint]}) {
      text-align: revert;
    }
  }

  .pointCopyright {
    display: block;
    @media (max-width: ${(props) => props.theme.responsive[mobileBreakPoint]}) {
      display: none;
    }
  }
`;

const FooterSectionBottomStyle = styled.div.attrs({ className: 'FooterSectionBottomStyle' })`
  display: flex;
  flex-flow: column;
  align-items: flex-end;

  .pointCopyright {
    display: none;
    @media (max-width: ${(props) => props.theme.responsive[mobileBreakPoint]}) {
      border-top: 1px solid ${(props) => props.theme.line.color.default};
      display: flex;
      width: 100%;
      height: ${topBottomSectionHeight};
      align-items: center;
      justify-content: flex-start;
    }
  }
`;

const FooterSectionNavStyle = styled.nav.attrs({ className: 'FooterSectionNavStyle' })`
  display: flex;
  align-items: center;
  height: ${topBottomSectionHeight};
  padding-bottom: ${topBottomSectionOffset};
  > a {
    white-space: nowrap;
    flex: auto;
    margin: 0 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
  @media (max-width: ${(props) => props.theme.responsive[mobileBreakPoint]}) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 10% 0;
    height: auto;
    a {
      padding: 2% 0;
      margin: 0;
    }
  }
`;

export {
  CallUsSectionStyle,
  FooterContainerStyle,
  FooterSectionTopStyle,
  FooterSectionBottomStyle,
  FooterSectionNavStyle,
};
