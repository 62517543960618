import styled from 'styled-components';
import { HeaderStyles } from '@pointdotcom/pds';
import { pxToRem } from 'styleHelpers';
import { CapShieldStyle } from '../CapShield/styles';
import { HighlightedNumberStyle } from '../styles';

export { HighlightedNumberStyle };

const sectionVerticalPadding = '3.75rem';

export const SectionStyle = styled.section.attrs({ className: 'SectionStyle' })`
  padding: 0 5rem ${sectionVerticalPadding};

  & + & {
    padding-top: ${sectionVerticalPadding};
    border-top: 1px solid ${({ theme }) => theme.line.color.default};
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;

    &:first-child {
      padding-bottom: 2rem;
    }
  }

  ${HeaderStyles.HeaderStyle} {
    > h1 {
      font-size: ${pxToRem(45)};
    }
  }

  ${HeaderStyles.HeaderStyle}, p {
    margin: 0;
  }

  ${HeaderStyles.HeaderStyle} + ${HeaderStyles.HeaderStyle} {
    margin-top: 0.9em;
  }

  ${HeaderStyles.HeaderStyle} + p {
    margin-top: 1.25em;
  }
  p + p {
    margin-top: 1.5em;
  }

  ${HighlightedNumberStyle} {
    padding: 0.1em 0.3em;
    margin: -0.1em -0.3em;
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${HeaderStyles.HeaderStyle} {
      > h1 {
        font-size: ${pxToRem(30)};
      }
      > h2 {
        font-family: ${({ theme }) => theme.fonts.sansSerif.name};
        font-weight: 900;
        font-size: ${pxToRem(24)};
      }

      > h3 {
        font-family: ${({ theme }) => theme.fonts.sansSerif.name};
        font-weight: 700;
        font-size: ${pxToRem(18)};
      }
    }
  }

  ${CapShieldStyle} {
    width: clamp(54px, 14%, 69px);
    margin-top: -1rem;
    margin-bottom: 1.5rem;

    @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
      margin-bottom: 1.25rem;
    }
  }
`;

export const TextWithImageStyle = styled.div.attrs({ className: 'TextWithImageStyle' })`
  display: grid;
  grid-template-columns: auto auto;
  gap: 1.25rem;

  ${HeaderStyles.HeaderStyle} {
    margin: 0;
    grid-column: 1 / 3;
  }

  p {
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  }

  > img {
    display: block;
    width: 130px;
    height: 121px;
    margin-top: -6px;
    margin-right: -25px;
    grid-row: 2;
    grid-column: 2;

    @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
      grid-row: 1;
      grid-column: 1;
      margin-top: -46px;
      margin-bottom: -15px;
      margin-left: -26px;
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    grid-template-columns: auto;
  }
`;

export const FootnoteStyle = styled.aside.attrs({ className: 'FootnoteStyle' })`
  font-size: ${pxToRem(14)};
  font-style: italic;
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
`;
