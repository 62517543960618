export default {
  dashboardName: 'Point dashboard',
  ifEmailExists:
    'If %email% exists in our system, weʼve sent an email with a link to get you where you need to go.',
  checkYourEmail: 'Check your email!',
  didntGetTheLink: 'Didn’t get the link? Resend now',
  resendLink: 'Resend link',
  prequalifyNow: 'Prequalify now',
  needToPrequalify: 'Need to prequalify? Prequalify now',
  backToLogin: 'Back to login',
  manageTheStatus: 'Manage your HEI from application to payoff all in one place.',
  toReceive:
    'To receive a link to your Point dashboard, enter the email address associated with your account:',
  yourEmail: 'Your email address',
  continue: 'Continue',
  logIn: 'Log in',
  needHelp: 'Need help? Call us %phone%',
  thanksWeveSent:
    'Thanks! Weʼve sent a temporary login link to your email. When you click it, it will automatically log you in.',
  sending: 'Sending...',
  sorryTheLink: 'Sorry, the link you clicked was expired.',
  sendMeANew: 'Send me a new login link',
  thisLinkIs: 'This link is invalid.',
  youveBeen: 'You have been logged out.',
  youllNeedTo: 'Youʼll need to login in order to continue your progress.',
  thePageYouWereOn:
    'The page you were on expired. Enter the email address associated with your application and you can continue your progress.',
};
