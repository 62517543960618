import React, { useEffect, useState } from 'react';
import TwinkleGraphic from '../TwinkleGraphic';
import * as styles from './styles';

export default function GoldenHousesGraphic() {
  const ANIM_DELAY_MS = 200;
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsShown(true), ANIM_DELAY_MS);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <styles.GoldenHousesGraphicStyle isShown={isShown}>
      <styles.GoldenHouseStyle>
        <TwinkleGraphic />
        <TwinkleGraphic />
      </styles.GoldenHouseStyle>
      <styles.GoldenHouseStyle>
        <TwinkleGraphic />
      </styles.GoldenHouseStyle>
    </styles.GoldenHousesGraphicStyle>
  );
}
