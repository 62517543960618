import { FeatureFlag, getPosthogFeatureFlagKey } from 'lib/featureFlags';
import { SourceChannel, SourceOrigin } from 'store/constants';
import { Estimate, Expand, Pricing } from 'types';
import ApplicantModel from './ApplicantModel';
import BaseModel from './BaseModel';
import PricingModel from './PricingModel';
import { currencyMask, normalizeProps, percMask } from './helpers';
import i18n from './i18n';

export enum ShareType {
  CapBased = 'cap',
  AppreciationBased = 'appr',
}

class BaseEstimateModel extends BaseModel<Estimate> implements Estimate {
  key = '';

  applicant: TSFixMe;

  maxOptionAmount: TSFixMe;

  pricing?: Pricing;

  promotion: TSFixMe;

  termYears: TSFixMe;

  property: TSFixMe;

  expires: TSFixMe;

  source: TSFixMe;

  preliminary: TSFixMe;

  canSave?: TSFixMe;

  csrfToken?: TSFixMe;

  features?: Record<string, unknown>;

  constructor(props: Expand<Estimate>) {
    super(props);
    const revisedPromotionProps = normalizeProps(props.promotion, {
      promotion_term: 'promotionTerm',
      cap_percentage: 'capPercentage',
    });

    Object.assign(this, { ...props, promotion: revisedPromotionProps });
  }

  getPricing(): PricingModel | null {
    if (this.pricing) {
      return new PricingModel(this.pricing);
    }

    return null;
  }

  getApplicant(): ApplicantModel | null {
    if (this.applicant) {
      return new ApplicantModel(this.applicant);
    }

    return null;
  }

  getMaxOptionAmount(): number {
    if (!Number.isNaN(parseFloat(this.maxOptionAmount))) {
      return this.maxOptionAmount;
    }
    return 0;
  }

  getPointsShare(appreciation = 0): number {
    const pointPerc = this.getPricing()?.getOptionPercentage() || 0;
    return appreciation * pointPerc;
  }

  getCapPercentageAtMonth(atMonth: number): number {
    let perc = this.getPricing()?.getCapPercentage();

    const { promotionTerm: termInMonths, capPercentage: promotionCapPercentage } =
      this.promotion || {};

    if (termInMonths && atMonth && atMonth <= parseInt(termInMonths, 10)) {
      perc = promotionCapPercentage / 100;
    }

    if (!this.promotion || !atMonth) {
      perc = this.getPricing()?.getCapPercentage();
    }

    return perc || 0;
  }

  getHomeownerRepaymentBase({
    lessTheOfferAmount = false,
    appreciationPerc = 0,
    durationInYears = 0,
    shareType,
    homeValue,
  }: {
    lessTheOfferAmount?: boolean;
    appreciationPerc?: number;
    durationInYears?: number;
    shareType?: ShareType;
    homeValue?: number;
  }): number {
    const pricing = this.getPricing();
    if (!pricing) {
      return 0;
    }
    const MONTHS_PER_YEAR = 12;
    const capPercent = this.getCapPercentageAtMonth(durationInYears * MONTHS_PER_YEAR);
    const riskAdjustedHomeValue = pricing?.getRiskAdjustedHomeValue();
    const pointOffer = pricing?.getOptionInvestmentAmount();
    const amountToBase = lessTheOfferAmount ? pointOffer : 0;
    const pointPerc = pricing?.getOptionPercentage();

    // We only calculate _annual_ home value increase in years for increase in home value. For declines in home
    // value, we look at the percentage change as a whole (so the years will default to 1 in this case)
    const durationOfAnnualIncreaseInYears = appreciationPerc <= 0 ? 1 : durationInYears;
    const endHomeValue =
      homeValue !== undefined
        ? homeValue
        : this.getHomeValueScenario({
            appreciationPerc,
            durationInYears: durationOfAnnualIncreaseInYears,
          });
    const capBasedPointShare =
      Math.round(pointOffer * (1 + capPercent / 12) ** (durationInYears * 12)) - amountToBase;
    const apprBasedShare =
      Math.round((endHomeValue - riskAdjustedHomeValue) * pointPerc + pointOffer) - amountToBase;

    const shareTypes = {
      [ShareType.CapBased]: capBasedPointShare,
      [ShareType.AppreciationBased]: apprBasedShare,
    };

    const pointsShare =
      shareType && shareTypes[shareType]
        ? shareTypes[shareType]
        : Math.min(apprBasedShare, capBasedPointShare);

    return Math.round(pointsShare);
  }

  getHomeownerRepayment({
    appreciationPerc,
    durationInYears,
    shareType,
  }: {
    appreciationPerc: number;
    durationInYears: number;
    shareType?: ShareType;
  }): number {
    return Math.max(
      this.getHomeownerRepaymentBase({ appreciationPerc, durationInYears, shareType }),
      0
    );
  }

  static getHomeValueScenario({
    appreciationPerc,
    durationInYears,
    homeValue,
  }: {
    appreciationPerc: number;
    durationInYears: number;
    homeValue: number;
  }): number {
    const endHomeValue = Math.round(homeValue * (1 + appreciationPerc) ** durationInYears);
    return endHomeValue;
  }

  getHomeValueScenario({
    appreciationPerc,
    durationInYears,
  }: {
    appreciationPerc: number;
    durationInYears: number;
  }): number {
    return BaseEstimateModel.getHomeValueScenario({
      appreciationPerc,
      durationInYears,
      homeValue: this.getPricing()?.getHomeValue() || 0,
    });
  }

  getFormattedHomeValue({
    appreciationPerc,
    durationInYears,
  }: {
    appreciationPerc: number;
    durationInYears: number;
  }): string {
    return currencyMask.getFormatted(
      this.getHomeValueScenario({ appreciationPerc, durationInYears })
    );
  }

  getProtectionCapByMonths(numMonths: number): number {
    const optionAmount = this.getPricing()?.getOptionInvestmentAmount() || 0;
    return (
      // eslint-disable-next-line no-restricted-properties
      optionAmount * Math.pow(1 + this.getCapPercentageAtMonth(numMonths) / 12, numMonths) -
      optionAmount
    );
  }

  static getEquivAPR({
    durationInYears,
    pointsShare,
    homeOwnerRepayment,
    pricing,
  }: {
    durationInYears: number;
    pointsShare?: number;
    homeOwnerRepayment: number;
    pricing: PricingModel;
  }): number {
    const startingAmount = pricing.getOptionInvestmentAmount() || 0;
    const repayment = pointsShare || homeOwnerRepayment;
    const equivApr = (repayment / startingAmount) ** (1 / durationInYears) - 1;
    return equivApr * 100;
  }

  getEquivAPR({
    appreciationPerc,
    durationInYears,
    pointsShareCustom,
  }: {
    appreciationPerc: number;
    durationInYears: number;
    pointsShareCustom: number;
  }): number {
    return BaseEstimateModel.getEquivAPR({
      durationInYears,
      pointsShare: pointsShareCustom,
      homeOwnerRepayment: this.getHomeownerRepayment({ appreciationPerc, durationInYears }),
      pricing: this.getPricing()!,
    });
  }

  getFormattedProtectionCapByMonths(numMonths: number): string {
    return currencyMask.getFormatted(this.getProtectionCapByMonths(numMonths));
  }

  getFormattedEquivAPR({
    appreciationPerc,
    durationInYears,
    pointsShareCustom,
  }: {
    appreciationPerc: number;
    durationInYears: number;
    pointsShareCustom: number;
  }): string {
    const equivAPR = this.getEquivAPR({ appreciationPerc, durationInYears, pointsShareCustom });
    return Number.isNaN(equivAPR) ? i18n.NA : percMask.getFormatted(equivAPR);
  }

  getHomeOwnerShare({
    appreciationPerc,
    durationInYears,
    homeValue,
  }: {
    appreciationPerc: number;
    durationInYears: number;
    homeValue?: number;
  }): number {
    const finalHomeValue =
      homeValue || this.getHomeValueScenario({ appreciationPerc, durationInYears });
    const repayment = this.getHomeownerRepayment({ appreciationPerc, durationInYears });
    return finalHomeValue - repayment;
  }

  getFormattedHomeOwnerShare({
    appreciationPerc,
    durationInYears,
    homeValue,
  }: {
    appreciationPerc: number;
    durationInYears: number;
    homeValue: number;
  }): string {
    return currencyMask.getFormatted(
      this.getHomeOwnerShare({ appreciationPerc, durationInYears, homeValue })
    );
  }

  getTerm(): number {
    return this.termYears || this.getPricing()?.termYears;
  }

  getFormattedTerm({
    showAsRange = false,
    singular = false,
    hyphenated = false,
  }: {
    showAsRange?: boolean;
    singular?: boolean;
    hyphenated?: boolean;
  } = {}): string {
    const prefix = showAsRange ? '0 - ' : '';
    const term = this.getTerm();
    const suffix = `${term}${hyphenated ? '-' : ' '}${
      term === 1 || singular ? i18n.year : i18n.years
    }`;
    return `${prefix}${suffix}`;
  }

  getFormattedAppraisedPropertyValue(): string {
    return currencyMask.getFormatted(this.property?.homeValue);
  }

  getFormattedCapPercentage(atMonth: number): string {
    return percMask.getFormatted(this.getCapPercentageAtMonth(atMonth) * 100);
  }

  getFormattedMaxOptionAmount(): string {
    return currencyMask.getFormatted(this.getMaxOptionAmount());
  }

  getIsExpired(): boolean {
    // Do not expire if testing
    if (process.env.REACT_APP_ENV === 'test') {
      return false;
    }

    if (this.expires) {
      return new Date() > new Date(this.expires);
    }

    return false;
  }

  usePartnerSources(): boolean {
    return this.getSourceIsPartner() || this.getSourceIsGeneralContractor();
  }

  getSourceIsEnhancedEducation(): boolean {
    return this.source?.origin === SourceOrigin.API;
  }

  getSourceIsPartner(): boolean {
    return this.source?.channel === SourceChannel.Partners;
  }

  getSourceIsGeneralContractor(): boolean {
    return this.source?.channel === SourceChannel.GeneralContractor;
  }

  getSourceIsPrequal(): boolean {
    return this.source?.origin === SourceOrigin.Prequal;
  }

  getSourceIsUWEstimateCalc(): boolean {
    return (
      this.source?.origin === SourceOrigin.Underwrite &&
      this.source?.platform === SourceChannel.FollowUpEstimateCalculator
    );
  }

  getSourceIsUnderwrite(): boolean {
    return this.source?.origin === SourceOrigin.Underwrite;
  }

  getFeatureValue(feature: FeatureFlag): unknown | null {
    const posthogKey = getPosthogFeatureFlagKey(feature);
    if (!posthogKey) return null;

    return this.features?.[posthogKey] ?? null;
  }

  hasFeatureWithValue(feature: FeatureFlag, value: unknown): boolean {
    return this.getFeatureValue(feature) === value;
  }
}

export default BaseEstimateModel;
