import { dayjs } from 'lib/dayjs';
import { Applicant, Expand } from 'types';
import ContactModel from './ContactModel';

class ApplicantModel extends ContactModel implements Applicant {
  authorizeCreditPull: TSFixMe; // TODO Add a type guard for ISO 8601 Date time

  acknowledgeContact: TSFixMe; // TODO Add a type guard for ISO 8601 Date time

  ssnLastFour: TSFixMe;

  ficoRange: TSFixMe;

  isNameConfirmed = false;

  address: TSFixMe;

  householdIncome?: string;

  contactPolicyConsentDate: TSFixMe;

  static getCurrentTimeAsISO(): string {
    return dayjs().toISOString();
  }

  constructor(props: Expand<Applicant>) {
    super(props);
    Object.assign(this, props);
  }

  getIsComplete(): boolean {
    return !!this.firstName && !!this.lastName && !!this.phone && !!this.email;
  }

  getContactPolicyConsentDate(): string | null {
    return this.contactPolicyConsentDate;
  }
}

export default ApplicantModel;
