import React, { useEffect, useState } from 'react';
import TwinkleGraphic from '../TwinkleGraphic';
import * as styles from './styles';

export default function SoldHouseGraphic() {
  const ANIM_DELAY_MS = 200;
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsShown(true), ANIM_DELAY_MS);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <styles.SoldHouseGraphicStyle isShown={isShown}>
      <styles.ShineStyle />
      <styles.SoldHouseStyle />
      <styles.SoldSignStyle>
        <TwinkleGraphic />
        <TwinkleGraphic />
        <TwinkleGraphic />
      </styles.SoldSignStyle>
      <styles.HillStyle />
    </styles.SoldHouseGraphicStyle>
  );
}
