/* eslint-disable no-alert */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import clamp from 'lodash.clamp';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  DirectionAndPlacement,
  Modal,
  SplashText,
  templatedString,
} from '@pointdotcom/pds';
import ApplicationLoading from 'components/ApplicationLoading';
import FullScreenLoading from 'components/FullScreenLoading';
import MainFooter from 'components/MainFooter';
import MainHeader, { HeaderType } from 'components/MainHeader';
import SaveApplicantContactModal from 'components/SaveApplicantContactModal';
import { TagPage, Workflow } from 'components/TagPage';
import { ErrorType } from 'containers/ErrorPage';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { useHomeownerApplication } from 'containers/hooks/useHomeownerApplication';
import { useHomeownerApplicationDataSync } from 'containers/hooks/useHomeownerApplicationDataSync';
import useLottie from 'containers/hooks/useLottie';
import OfferEstimateModel from 'models/OfferEstimateModel';
import { useGetApplicationQuery } from 'services/api/homeownerApi';
import {
  ApplicationStatus,
  DocketStatus,
  GetApplicationNotFoundResponse,
} from 'services/apiTypes/homeownerTypes';
import { setSaveApplicantContactModalOpen } from 'store/general';
import { useDispatch, useSelector } from 'store/hooks';
import {
  DocketCreationFailure,
  checkDocketCreationFailure,
  clearDocketCreationFailure,
} from 'store/productApplication';
import { useHeiNavItems } from '../heiNavigation';
import ApplicationForm from './ApplicationForm';
import ApplicationPageHero from './ApplicationPageHero';
import ApplicationPageNav from './ApplicationPageNav';
import {
  ApplicationPageType,
  PageNavigationFunctionType,
  pageTemplateMap,
  pageTitleMap,
} from './constants';
import i18n from './i18n';
import * as styles from './styles';
import { useHandleApplicationSubmitted } from './useHandleApplicationSubmitted';

// This is the shell of the application.
// it includes all the major UI elements surrounding the form

const DocketCreationFailureModal = ({ isOpen }: { isOpen: boolean }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(clearDocketCreationFailure());
    const dashboardPage = generateUrlFromPage(pages.DASHBOARD);
    navigate({ pathname: dashboardPage }, { replace: true });
  };

  return (
    <Modal
      isOpen={isOpen}
      onModalClose={onClose}
      showX={false}
      bgClickToClose={false}
      escToClose={false}
      shadeBg
      headerText={i18n.resumeApplication}
      maxWidth="100%"
      headerMaxWidth="100%"
    >
      <SplashText>{i18n.itLooksLike}</SplashText>
      <Button onClick={onClose}>{i18n.proceedTo}</Button>
    </Modal>
  );
};

interface ApplicationPageProps {
  estimate: OfferEstimateModel;
}
const ApplicationPage = ({ estimate }: ApplicationPageProps) => {
  const [pageLoading, setPageLoading] = useState(true);
  const applicantComplete = estimate.getApplicant()?.getIsComplete();
  const { estimateKey, page } = useParams<{
    estimateKey: string;
    page: string;
  }>();
  // Load saved application data from server
  const {
    isError: isErrorGet,
    error: errorGet,
    isFetching: isFetchingGet,
    refetch: refetchGet,
  } = useGetApplicationQuery(estimateKey as string);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createHomeownerApplication, applicationErrorMessage } = useHomeownerApplication();
  useHomeownerApplicationDataSync();
  const docketCreationFailure = useSelector(checkDocketCreationFailure);
  const errorAlertShownRef = useRef(false);

  const pageArr = Object.keys(pageTemplateMap);
  const totalNumPages = pageArr.length;
  const pageInt = clamp(parseInt(page || '', 10) || 1, 1, totalNumPages);
  const currentPage = pageArr[pageInt - 1] as ApplicationPageType;
  const offerAmount = estimate.getFormattedMaxOptionAmount();
  const dashboardPageUrl = generateUrlFromPage(pages.DASHBOARD);
  const pageTitle = templatedString({
    template: i18n.pageTitleTemplate,
    values: { page: pageTitleMap[currentPage] },
  });
  const { loadLottie } = useLottie();

  const handlePageNavigation: PageNavigationFunctionType = useCallback(
    (direction: DirectionAndPlacement) => {
      let newPageNum = pageInt;
      if (direction === DirectionAndPlacement.Right) {
        newPageNum += 1;
      } else if (direction === DirectionAndPlacement.Left) {
        newPageNum -= 1;
      }
      navigate(
        generateUrlFromPage(pages.HEI_APPLICATION, {
          estimateKey,
          page: String(newPageNum),
        })
      );
    },
    [estimateKey, navigate, pageInt]
  );

  const applicationPageSubheaderContent = (
    <styles.ApplicationPageSubheaderStyle>
      <ApplicationPageNav />
      <div>
        {templatedString({
          values: { offerAmount },
          template: i18n.currentOffer,
        })}
      </div>
    </styles.ApplicationPageSubheaderStyle>
  );

  useEffect(() => {
    // Preload Lottie when this page loads
    loadLottie();
  }, [loadLottie]);

  // Ensure we have contact info for the customer
  useEffect(() => {
    dispatch(setSaveApplicantContactModalOpen(!applicantComplete));
  }, [applicantComplete, page, dispatch]); // using page here so that modal is opened again if they switch pages

  // Show error message if there was an unknown error in docket creation
  useEffect(() => {
    if (docketCreationFailure === DocketCreationFailure.Unknown) {
      if (!errorAlertShownRef.current) {
        errorAlertShownRef.current = true;
        alert(applicationErrorMessage);
      }
    }
  }, [docketCreationFailure, applicationErrorMessage]);

  // Do the main functionality here to either create the new application or redirect to appropriate location
  useEffect(() => {
    if (!isFetchingGet) {
      if (!applicantComplete || !isErrorGet) {
        setPageLoading(false);
        return;
      }
    }

    if (isErrorGet) {
      const typedError = errorGet as {
        status: number;
        data: GetApplicationNotFoundResponse;
      };

      // A 404 response means the application is not available in HOS. Redirect to appropriate location based on status flags.
      if (typedError.status === 404) {
        const { docketStatus, applicationStatus, followUpUrl } = typedError.data;

        // Inactive docket messaging will be handled by a status check on the Dashboard page
        if (docketStatus !== DocketStatus.Active) {
          navigate({ pathname: dashboardPageUrl });
          return;
        }

        switch (applicationStatus) {
          case ApplicationStatus.New:
          case ApplicationStatus.Open:
            // This is a legacy application in Underwrite. Redirect to the followup URL if we have one.
            if (followUpUrl) {
              window.location.assign(followUpUrl);
            } else {
              navigate({ pathname: dashboardPageUrl });
            }
            break;
          case ApplicationStatus.Expired:
            navigate(
              generateUrlFromPage(pages.ERROR_GENERAL, {
                errorType: ErrorType.ApplicationExpired,
              }),
              { replace: true }
            );
            break;
          default:
            navigate({ pathname: dashboardPageUrl });
            break;
        }
        return;
      }

      /*
        401: User is not authenticated. This might be a new application or a returning user but we can't tell yet.
        412: User is authenticated but for a different estimate.
        In either case, try to create a new application. User will get redirected by createHomeownerApplication()
        if there is an existing application.
      */
      if (typedError.status === 401 || typedError.status === 412) {
        setPageLoading(true);
        createHomeownerApplication(estimate)
          .then(() => {
            refetchGet();
          })
          .catch(() => {
            alert(applicationErrorMessage);
          })
          .finally(() => setPageLoading(false));
      }
    }
    // TODO: understand why estimate & createHomeownerApplication are causing effect loops when included in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applicantComplete,
    applicationErrorMessage,
    dashboardPageUrl,
    errorGet,
    isErrorGet,
    isFetchingGet,
    navigate,
    refetchGet,
  ]);

  const navItems = useHeiNavItems({ estimate });

  const { handleApplicationSubmitted, isProcessing } = useHandleApplicationSubmitted({
    estimateKey,
  });

  if (pageLoading) {
    return <FullScreenLoading />;
  }

  return (
    <div>
      <Helmet title={pageTitle} />
      <TagPage page="Application" workflow={Workflow.Application} />
      <MainHeader
        headerType={HeaderType.Progress}
        navItems={navItems}
        navProps={{
          estimateKey,
          page,
        }}
        subHeaderProps={{
          hideOnMobile: true,
          leftContent: applicationPageSubheaderContent,
        }}
      />
      <ApplicationPageHero
        page={currentPage}
        currentPageNum={pageInt}
        totalNumPages={totalNumPages}
        onPaginate={handlePageNavigation}
        stringTemplateValues={{ offerAmount }}
      />
      {applicantComplete && (
        <ApplicationForm
          page={currentPage}
          currentPageNum={pageInt}
          totalNumPages={totalNumPages}
          onPaginate={handlePageNavigation}
          onApplicationSubmitted={handleApplicationSubmitted}
          isProcessing={isProcessing}
          estimate={estimate}
        />
      )}
      <MainFooter />
      <SaveApplicantContactModal
        applicant={estimate?.applicant}
        modalProps={{ shadeBg: true, onModalClose: undefined, showX: false }} // modal should not be closable
      />
      <DocketCreationFailureModal
        isOpen={docketCreationFailure === DocketCreationFailure.RedirectRequired}
      />
      <ApplicationLoading show={isProcessing} />
    </div>
  );
};

export default ApplicationPage;
