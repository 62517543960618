import React, { useEffect, useRef, useState } from 'react';
import TwinkleGraphic from '../TwinkleGraphic';
import * as styles from './styles';

interface SquareProps {
  delay: number;
  randomX: number;
  randomY: number;
  backgroundPosition: string;
  backgroundSize: string;
}

const DissolvingBill = ({ startAnimation }: { startAnimation: boolean }) => {
  const [squares, setSquares] = useState<SquareProps[]>([]);
  const [animate, setAnimate] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const calculateSquares = () => {
    const delayIncrement = 0.2;

    const newSquares: SquareProps[] = [];
    const containerWidth = containerRef.current?.offsetWidth || 0;
    const containerHeight = containerRef.current?.offsetHeight || 0;
    const cellWidth = containerWidth / styles.gridSize;
    const cellHeight = containerHeight / styles.gridSize;
    const backgroundSize = `${containerWidth}px ${containerHeight}px`;

    for (let row = 0; row < styles.gridSize; row++) {
      for (let col = 0; col < styles.gridSize; col++) {
        newSquares.push({
          delay: (row * styles.gridSize + col) * delayIncrement,
          randomX: Math.random(),
          randomY: Math.random(),
          backgroundPosition: `${-col * cellWidth}px ${-row * cellHeight}px`,
          backgroundSize,
        });
      }
    }

    setSquares(newSquares);
  };

  useEffect(() => {
    if (!startAnimation) return;
    calculateSquares(); // Initial calculation

    // Delay for the entire animation start
    const totalAnimationDelay = 1000;
    const timeoutId = setTimeout(() => setAnimate(true), totalAnimationDelay);

    // Recalculate squares on resize
    const handleResize = () => calculateSquares();
    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(timeoutId); // Cleanup timeout on unmount
      window.removeEventListener('resize', handleResize); // Cleanup event listener
    };
  }, [startAnimation]);

  return (
    <styles.DissolvingBillStyle ref={containerRef} animate={animate}>
      {squares.map((square, index) => (
        <styles.SmallSquareStyle
          key={index}
          style={
            {
              backgroundPosition: square.backgroundPosition,
              backgroundSize: square.backgroundSize,
              animationDelay: `${square.delay}s`,
              '--random-x': square.randomX.toString(),
              '--random-y': square.randomY.toString(),
            } as React.CSSProperties
          }
          className="small-square"
        />
      ))}
    </styles.DissolvingBillStyle>
  );
};

const Wand = () => {
  return (
    <styles.WandStyle>
      <TwinkleGraphic />
      <TwinkleGraphic />
      <TwinkleGraphic />
    </styles.WandStyle>
  );
};

export default function MagicCalendarGraphic({ startAnimation }: { startAnimation: boolean }) {
  return (
    <styles.MagicCalendarGraphicStyle>
      <DissolvingBill startAnimation={startAnimation} />
      <Wand />
    </styles.MagicCalendarGraphicStyle>
  );
}
