import styled, { css } from 'styled-components';
import { SubHeaderStyle } from 'components/SubHeader/styles';
import { HeroContainerStyle } from 'styles/';

const PAGE_COLOR = 'white';
const LANDSCAPE_HEADER_FOOTER_HEIGHT = '100px';

export const ProductQuizPageStyle = styled.div.attrs({ className: 'ProductQuizStyle' })`
  ${SubHeaderStyle} {
    position: relative;
    z-index: 3;
  }

  ${HeroContainerStyle} {
    background: ${PAGE_COLOR};
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    ${HeroContainerStyle} {
      padding-top: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${HeroContainerStyle} {
      padding: 0;
    }

    button {
      width: 100%;
    }
  }
`;

export const HeaderButtonContainerStyle = styled.header.attrs({
  className: 'HeaderButtonContainerStyle' as string,
})<{ isLandscape: boolean }>`
  margin-bottom: 1em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    margin-bottom: 0;
    padding: 2em ${({ theme }) => theme.container.gutter.default};
  }
  ${({ isLandscape }) => {
    if (isLandscape) {
      return css`
        margin-bottom: 0;
        padding: 1em ${({ theme }) => theme.container.gutter.default};
        @media all and not (display-mode: fullscreen) {
          height: ${LANDSCAPE_HEADER_FOOTER_HEIGHT};
        }
      `;
    }
    return null;
  }}
`;

export const FooterButtonContainerStyle = styled.footer.attrs({
  className: 'FooterButtonContainerStyle',
})<{ isLandscape: boolean }>`
  margin-top: 20px;
  display: flex;
  justify-content: end;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    padding: 1em ${({ theme }) => theme.container.gutter.default};
  }
  ${({ isLandscape }) => {
    if (isLandscape) {
      return css`
        margin-top: 0;
        padding: 1em ${({ theme }) => theme.container.gutter.default};
        @media all and not (display-mode: fullscreen) {
          height: ${LANDSCAPE_HEADER_FOOTER_HEIGHT};
        }
      `;
    }
    return null;
  }}
`;
