import styled, { css } from 'styled-components';
import { ContainerStyles } from '@pointdotcom/pds';
import bloombergImg from '../images/bloomberg.svg';
import forbesImg from '../images/forbes.svg';
import fortuneImg from '../images/fortune.svg';
import foxImg from '../images/fox.svg';
import theWallStreetJournalImg from '../images/theWallStreetJournal.svg';
import theWashingtonPostImg from '../images/theWashingtonPost.svg';

const FeaturedInMixin = (featuredInArr) => {
  const cssArr = [];

  featuredInArr.forEach((featuredInItem) => {
    const [featuredInName, featuredInImg, featuredInSize, featuredInFlex] = featuredInItem;

    cssArr.push(`
      ${ContainerStyles.ContainerStyle} {
        .${featuredInName} {
          background-image: url(${featuredInImg});
          background-size: ${featuredInSize};
          flex: ${featuredInFlex};
        }
      }
    `);
  });

  return css`
    ${cssArr.join('\n')}
  `;
};

// Order matters
// (class, img, width, flex)

const featuredIn = [
  ['theWallStreetJournal', theWallStreetJournalImg, '65px', '1'],
  ['bloomberg', bloombergImg, '68px', '1'],
  ['theWashingtonPost', theWashingtonPostImg, '150px', '2'],
  ['fortune', fortuneImg, '50px', '1'],
  ['fox', foxImg, '54px', '1'],
  ['forbes', forbesImg, '58px', '1'],
];

const featuredInMobile = [
  ['fortune', fortuneImg, '50px', '33%'],
  ['fox', foxImg, '54px', '33%'],
  ['bloomberg', bloombergImg, '68px', '33%'],
  ['theWallStreetJournal', theWallStreetJournalImg, '65px', '50%'],
  ['forbes', fortuneImg, '58px', '50%'],
  ['theWashingtonPost', theWashingtonPostImg, '150px', '100%'],
];

export const FeaturedInStyle = styled.section.attrs({ className: 'FeaturedInStyle' })`
  background: ${(props) => props.theme.Color.PurpleBlue6};
  border-top: 1px solid ${(props) => props.theme.Color.PurpleBlue7};
  text-align: center;

  h5 {
    $fontSize: 0.7em;
    background: ${(props) => props.theme.Color.PurpleBlue6};
    color: $white;
    display: inline-block;
    font-family: $headerFont;
    font-size: $fontSize;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    top: -1em;
    text-indent: -9999px;
    margin: 0;
    padding: 0;
  }

  ${ContainerStyles.ContainerStyle} > div {
    display: flex;
    flex-wrap: wrap;
    padding: 3% 0 6%;
    margin: 0 auto;

    > * {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100%;
      box-sizing: border-box;
      height: 60px;
    }
  }

  ${FeaturedInMixin(featuredIn)}
  @media screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    ${ContainerStyles.ContainerStyle} > div {
      max-width: 400px;
    }

    ${FeaturedInMixin(featuredInMobile)}
  }
`;
