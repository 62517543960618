import React from 'react';
import { Style } from '@pointdotcom/pds';
import { HrVrProps } from './constants';
import * as styles from './styles';

export const Hr = ({ styleType = Style.Default, width }: HrVrProps & { width?: string }) => (
  <styles.HrStyle styleType={styleType} width={width} />
);
export const Vr = ({ styleType = Style.Default, height }: HrVrProps & { height?: string }) => (
  <styles.VrStyle styleType={styleType} height={height} />
);
