import styled, { css, keyframes } from 'styled-components';
import { DirectionAndPlacement } from '@pointdotcom/pds';
import { MagnifyGraphicProps } from './constants';
import magnifyImage from './images/magnify.png';

export const magnifyShineMoveAnim = keyframes`
  0%, 100% {
    transform: scale(1) translateY(-38%) rotate(-30deg);
  }
  50% {
    transform: scale(1.1) translateY(13%) rotate(-30deg);
  }
`;

export const MagnifyContentStyle = styled.div.attrs({ className: 'MagnifyContentStyle' })`
  --magnifyContentSize: 43%;
  width: var(--magnifyContentSize);
  height: var(--magnifyContentSize);
  border-radius: 50%;
  position: absolute;
  left: 20%;
  top: 17%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const MagnifyShineStyle = styled.div.attrs({ className: 'MagnifyShineStyle' })`
  position: absolute;
  width: 40%;
  height: 20%;
  top: 15%;
  left: 15%;
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0.7));
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
  transform: rotate(-30deg);
  opacity: 0.5;
`;

export const MagnifyGraphicStyle = styled.div.attrs({
  className: 'MagnifyGraphicStyle',
})<MagnifyGraphicProps>`
  --magnifySize: 80%;
  position: absolute;
  bottom: 0;
  right: 0;
  width: var(--magnifySize);
  padding-bottom: var(--magnifySize);
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    display: block;
    background-image: url('${magnifyImage}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: ${({ direction }) =>
      direction === DirectionAndPlacement.Right ? 'scaleX(-1)' : 'scale(1, 1)'};
  }

  ${({ direction }) => {
    if (direction === DirectionAndPlacement.Left) {
      return css`
        ${MagnifyContentStyle} {
          left: 37%;
        }
      `;
    }
    return null;
  }}
`;
