import React from 'react';
import { Container, TPWidgetType, TrustPilotWidget, directory } from '@pointdotcom/pds';
import BBB from 'components/BBB';
import LogoBar from 'components/LogoBar';
import ContactLinks from './ContactLinks';
import Hours from './Hours';
import LicencesLinksCopyright from './LicencesLinksCopyright';
import SocialContainer from './SocialContainer';
import * as styles from './styles';

const MainFooter = ({ styleType = 'default', hideTpWiget = false, product }) => {
  const { PointAddress } = directory;
  const bbbContainer = (
    <div className="bbbContainer">
      <BBB />
    </div>
  );
  const tpContainer = (
    <div className="tpContainer">
      <TrustPilotWidget widgetType={TPWidgetType.MicroStar} />
    </div>
  );
  return (
    <styles.MainFooterStyle styleType={styleType}>
      <styles.BodyWithFooterStyle styleType={styleType} />
      <div className="mainContent">
        <Container mobileCollapse>
          <div className="mainContentGrid">
            {hideTpWiget ? null : tpContainer}
            {hideTpWiget ? null : bbbContainer}
            <LicencesLinksCopyright className="flexStackContent" />
            <div className="logoContainer">
              <LogoBar useBBB={false} grid useLogoSet={LogoBar.LOGOSETS.GRID} />
            </div>
          </div>
        </Container>
      </div>
      <div className="subContent">
        <Container mobileCollapse>
          <div className="socialFineTextContainer">
            <SocialContainer />
          </div>
          <div className="contactContainter">
            <ContactLinks product={product} className="flexStackContent" />
            <div className="flexStackContent">
              <Hours />
              <div className="address">{PointAddress.Main}</div>
            </div>
          </div>
        </Container>
      </div>
    </styles.MainFooterStyle>
  );
};

export default MainFooter;
