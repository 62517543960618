import { AddressField, StructuredAddress } from 'lib/smartyStreets';

export type FlexibleFormAddress = Pick<
  StructuredAddress,
  AddressField.STREET | AddressField.CITY | AddressField.STATE | AddressField.ZIP
>;

export type FlexibleFormParams = FlexibleFormAddress & {
  product?: string;
  hasUnit?: '1' | '0';
  addressConfirmationRequired?: '1' | '0';
  address?: string;
};

export const flexibleFormAddressFields: (keyof FlexibleFormAddress)[] = [
  AddressField.STREET,
  AddressField.CITY,
  AddressField.STATE,
  AddressField.ZIP,
];

export const flexibleFormFields: (keyof FlexibleFormParams)[] = [
  'product',
  'hasUnit',
  'addressConfirmationRequired',
  'address',
  ...flexibleFormAddressFields,
];
