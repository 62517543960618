import React from 'react';
import { templatedString } from '@pointdotcom/pds';
import { addBannerToSessionStorage } from 'components/GenericMessageBanner/sessionStorageBanner';
import { FollowUpPageWrapperProps } from 'containers/FollowUpPage/types';
import { getPathFromPage, pages } from 'containers/helpers';
import { Redirect } from 'containers/routerHelpers';
import { FollowUpStatus } from 'models/FollowUpModel';
import i18n from './i18n';
import * as styles from './styles';

type FinancialCounselingPageProps = Pick<
  FollowUpPageWrapperProps,
  'children' | 'followUpFetchResults'
>;

const FinancialCounselingPage = ({
  children,
  followUpFetchResults,
}: FinancialCounselingPageProps) => {
  const { followUp } = followUpFetchResults;
  if (followUp && followUp.isFinancialCounseling() && followUp.status === FollowUpStatus.Closed) {
    const { firstName } = followUp.getContact();
    const template = firstName ? i18n.thanks : i18n.thanksWeve;
    const bannerMessage = templatedString({ template, values: { firstName } });

    addBannerToSessionStorage({
      message: bannerMessage,
      timer: 10000,
      style: 'secondary',
      inverted: false,
    });
    const dashboardPage = getPathFromPage(pages.DASHBOARD);
    if (dashboardPage) {
      return <Redirect to={dashboardPage} />;
    }
  }

  return (
    <styles.FinancialCounselingPageStyle
      followUpName={i18n.financialCounseling}
      followUpFetchResults={followUpFetchResults}
      crumbProps={{
        currentCrumb: i18n.financialCounseling,
      }}
    >
      {children}
    </styles.FinancialCounselingPageStyle>
  );
};

export default FinancialCounselingPage;
