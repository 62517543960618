import styled from 'styled-components';
import { ContainerStyles } from '@pointdotcom/pds';
import { LeftRightPaginatorStyle } from 'components/LeftRightPaginator/styles';
import { PaddedSectionCSS, PaddedSectionCSSProps } from 'styles';

export const ApplicationPageHeroStyle = styled.section<PaddedSectionCSSProps>`
  border-bottom: 1px solid ${({ theme }) => theme.line.color.default};
  background: white;
  ${ContainerStyles.ContainerStyle} {
    gap: 3rem;
    align-items: end;
    > div {
      &:first-child {
        flex: 2;
        display: flex;
        flex-flow: column nowrap;
        ${PaddedSectionCSS}
      }
      &:last-child {
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }
  }
  ${LeftRightPaginatorStyle} {
    margin-bottom: 0.6rem;
  }
  img {
    max-width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${ContainerStyles.ContainerStyle} {
      gap: 1rem;
      > div {
        &:first-child {
          padding-bottom: 0;
        }
      }
    }
    img {
      max-width: 200px;
    }
  }
`;
