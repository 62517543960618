export default {
  ourNext: 'Our next available time is %day%, at %time% with %person%',
  aPointRep: 'a Point representative',
  preparingYour: 'Preparing your application...',
  yourApplicationIsnt:
    'We are unable to create this application with the appropriate attribution. Please contact your Partner Account Manager.',
  preparingApplication: 'Preparing application...',
  continueMy: 'Continue my application',
  or: 'or',
  continueApplication: 'Continue application',
  scheduleACallWithUs: 'Schedule a call with us',
  scheduleACall: 'Schedule a call',
  stillHaveQuestions: 'Still have questions?',
  readyToGetStarted: 'Ready to get started?',
};
