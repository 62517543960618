/* eslint-disable */
// checks a Partial json object for presence of key
// useful for checking for a value on a non exhaustive obj[enum]
export function isKeyOfObject<T extends object>(
  key: string | number | symbol,
  obj: T
): key is keyof T {
  return key in obj;
}

export function isNonNullish<TValue>(value: TValue | undefined | null): value is TValue {
  return value != null;
}
