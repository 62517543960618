export default {
  browse: 'Browse',
  complete: 'Complete',
  documentRequirements: 'Document requirements',
  documentRequirementsList: [
    'The document(s) must be valid',
    'The document(s) must be fully legible',
    'The document(s) must be complete  (including all pages, even those with fine print)',
    'The name and address on the document(s) must match the name on your Point application',
    'Please DO NOT upload your Social Security card',
  ],
  haveAQuestion: 'Have a question?',
  helpWithThisDocument: 'Help with this document',
  requestText:
    'Please upload the following documents so Point can verify the information you’ve provided.',
  title: 'Upload your documents',
};
