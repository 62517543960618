import React, { ReactNode } from 'react';
import { Error as BugsnagError } from '@bugsnag/core/types/event';
import Bugsnag, { Event as BugsnagEvent, OnErrorCallback } from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import { AxiosError } from 'axios';
import nullthrows from 'nullthrows';
import { logInfo } from 'lib/logger';

let BugsnagClient: typeof Bugsnag | { notify: (typeof Bugsnag)['notify'] };
let ErrorBoundary: BugsnagErrorBoundary;

interface IgnoreFilterCriteria {
  errorClass?: RegExp;
  errorMessage?: RegExp;
  stacktrace?: RegExp;
  requestUrl?: RegExp;
}

/**
 * Add defintions for error types which we do not want to send to Bugsnag. Typically
 * these should be 3rd party errors which we can't easily fix or control. The following
 * fields can be used to match against the error:
 *
 *   errorMessage: matches against report.errors[0].errorMessage
 *   errorClass: matches against report.errors[0].errorClass
 *   stacktrace: matches against report.errors[0].stacktrace[0].file. It only looks at
 *               the first (top-most) stacktrace entry. It does not search the entire
 *               stack. This may need to be extended in the future.
 *   requestUrl: matches against report.request.url
 *
 * All filter criteria must be regular expressions. Each field is optional, but all
 * provided fields must match in order for the error to be filtered out. Filtered errors
 * are not sent to Bugsnag but will instead log a message to the console.
 *
 * Note that "handled" errors, i.e. those raised via explicit calls to Bugsnag's notify()
 * function will always be sent to Bugsnag regardless of the filter criteria.
 */
const commonIgnoreFilter: IgnoreFilterCriteria[] = [
  {
    // PDS address autocomplete control
    errorMessage: /Field must contain full address/i,
  },
  {
    // Chatlio widget
    errorClass: /NotAllowedError/,
    errorMessage: /possibly because the user denied permission/i,
  },
];

// The following filters will only be used in non-Production envrionments
const stagingIgnoreFilter: IgnoreFilterCriteria[] = [
  {
    errorClass: /^Error$/,
    errorMessage: /No key set for Google Analytics/i,
  },
];

const errorsToIgnore: IgnoreFilterCriteria[] = [
  ...commonIgnoreFilter,
  ...(process.env.REACT_APP_ENV === 'production' ? [] : stagingIgnoreFilter),
];

/**
 * If REACT_APP_ERRORS_TO_LOG is present, build a list of error messages to watch and send detailed
 * information to the log. These errors will still be sent to Bugsnag, but logging out full deatils
 * here will allow us to decide if they should be added to the filter list in the future.
 */
let errorsToLog: RegExp[] = [];
if (process.env.REACT_APP_BUGSNAG_ERRORS_TO_LOG) {
  errorsToLog = process.env.REACT_APP_BUGSNAG_ERRORS_TO_LOG.split(',').map(
    (error) => new RegExp(error, 'i')
  );
}

function shouldIgnoreError({
  error,
  requestUrl,
}: {
  error: BugsnagError;
  requestUrl: string;
}): boolean {
  try {
    if (process.env.REACT_APP_BUGSNAG_IGNORE_ALL_ERRORS === 'true') {
      return true;
    }

    const errorFields: { errorClass: string; errorMessage: string; stacktrace?: string } = {
      errorClass: error.errorClass,
      errorMessage: error.errorMessage,
    };
    if (error.stacktrace && error.stacktrace.length) {
      errorFields.stacktrace = error.stacktrace[0].file;
    }

    // Check if the error matches any of those in the filter list
    return errorsToIgnore.some(
      (filterCriteria) =>
        (filterCriteria.errorMessage
          ? filterCriteria.errorMessage.test(errorFields.errorMessage)
          : true) &&
        (filterCriteria.errorClass
          ? filterCriteria.errorClass.test(errorFields.errorClass)
          : true) &&
        (filterCriteria.stacktrace
          ? filterCriteria.stacktrace.test(errorFields.stacktrace ?? '')
          : true) &&
        (filterCriteria.requestUrl ? filterCriteria.requestUrl.test(requestUrl) : true)
    );
  } catch (e) {
    console.error(`Error in filter check: ${e}`);
    return false;
  }
}

/**
 * Use this function to gather info on an error even if axios is involved
 * @param {Bugsnag.Event} event
 * @param {Axios.Error} error
 */
export const attachAxiosError = (event: BugsnagEvent, error: AxiosError) => {
  if (!error.isAxiosError) {
    return;
  }
  const e = error.toJSON();
  event.addMetadata('axios.error', e);
};

/**
 * Determine whether to send error report to Bugsnag or to skip it.
 *
 * @param report - Full Report object (provided by Bugsnag)
 * @returns false if error should be skipped, true if it should be sent
 */
const filterReport: OnErrorCallback = (report) => {
  const requestUrl = report.request?.url ?? 'None';
  const error = report?.errors?.length ? report.errors[0] : null;
  if (error == null) {
    return true;
  }

  // If this is an unhandled error we don't care about, skip the Bugsnag report and just send a log
  if (report.unhandled && shouldIgnoreError({ error, requestUrl })) {
    logInfo({
      eventType: 'bugsnagClient',
      detail: {
        description: 'error ignored due to filter criteria',
        message: error.errorMessage,
        requestUrl,
      },
    });
    return false;
  }

  // Log full details for error messages we want to watch
  if (
    process.env.REACT_APP_BUGSNAG_LOG_ALL_ERRORS === 'true' ||
    errorsToLog.some((messageRegex) => messageRegex.test(error.errorMessage))
  ) {
    logInfo({
      eventType: 'bugsnagClient',
      detail: { description: 'logError', message: error.errorMessage, report },
    });
  }

  return true;
};

const validBugsnagEnvironments = ['production', 'staging', 'development'];
if (
  process.env.REACT_APP_BUGSNAG_API_KEY &&
  validBugsnagEnvironments.includes(process.env.REACT_APP_ENV ?? '')
) {
  const appVersion = BUILD_METADATA.version ?? 'Unknown';
  const bugsnagClient = Bugsnag;
  bugsnagClient.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    appVersion,
    releaseStage: process.env.REACT_APP_ENV,
    plugins: [new BugsnagPluginReact()],
    onError: filterReport,
  });
  const originalNotify = bugsnagClient.notify;
  // eslint-disable-next-line no-inner-declarations
  const wrapNotify: typeof originalNotify = (error, fn, cb) => {
    const handleEvent: OnErrorCallback = (event) => {
      attachAxiosError(event, error as TSFixMe);
      if (typeof fn === 'function') {
        fn(event, () => {});
      }
    };
    return originalNotify(error, handleEvent, cb);
  };
  bugsnagClient.notify = wrapNotify;

  BugsnagClient = bugsnagClient;
  ErrorBoundary = nullthrows(bugsnagClient.getPlugin('react')).createErrorBoundary(React);
} else {
  // When there is no key, return a stubbed mock of the client that console errors
  const consoleStyling = 'background-color: black; color: yellow; font-weight: 600; padding: 2px;';
  if (process.env.REACT_APP_ENV !== 'test') {
    console.warn(
      '%cBugsnag Client',
      consoleStyling,
      '\nNo API key found or running in dev mode. Reports will be sent to console.'
    );
  }
  BugsnagClient = {
    notify: (e) => {
      if (process.env.REACT_APP_ENV !== 'test') {
        console.error(`%cBugsnag report\n`, consoleStyling, e);
      }
    },
  };

  ErrorBoundary = ({ children }: { children?: ReactNode }) => {
    if (process.env.REACT_APP_ENV !== 'test') {
      console.warn(
        '%cBugsnag ErrorBoundary',
        consoleStyling,
        '\nNo API key found or running in dev mode. Client mock placeholder in ErrorBoundary'
      );
    }
    return children;
  };
}

export default BugsnagClient;
export { ErrorBoundary };
