import styled from 'styled-components';
import { LogoStyles } from '@pointdotcom/pds';

export const FullScreenLoadingStyle = styled.div.attrs({ className: 'FullScreenLoadingStyle' })`
  position: ${({ fullPage }) => (fullPage ? 'static' : 'fixed')};
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background: white;
  z-index: 99;
  top: 0px;
  left: 0;
  ${LogoStyles.LogoStyle} {
    position: relative;
    bottom: 1em;
  }
  /* HACK: Loader needs some work */
  .ui.massive.active.loader {
    margin-left: -6px;
  }
`;
