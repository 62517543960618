interface AnimationAsset {
  id: string;
  p?: string; // Path to the image file
  u?: string; // Directory path
  w?: number; // Width of the asset
  h?: number; // Height of the asset
  e?: number; // Image extension type or other properties
}

interface AnimationData {
  assets: AnimationAsset[];
  v: string; // Version of Lottie
  fr: number; // Frame rate
  ip: number; // In-point of the animation
  op: number; // Out-point of the animation
  w: number; // Width of the composition
  h: number; // Height of the composition
  nm: string; // Name of the animation
  ddd: number; // Whether the animation is 3D
  [key: string]: unknown; // Any other properties that might exist
}

// Lottie files are exported with public paths by default. This allows images to be imported
// and remapped into the generated animation data file
export const mapImagePaths = (
  animationData: AnimationData,
  imageMap: Record<string, string>
): AnimationData => {
  // Clone the animationData to avoid mutating the original object
  const updatedAnimationData: AnimationData = { ...animationData };

  // Map over the assets and replace paths with React-imported paths
  updatedAnimationData.assets = updatedAnimationData.assets.map((asset): AnimationAsset => {
    if (asset.p && imageMap[asset.p]) {
      return {
        ...asset,
        u: '', // Clear the "u" property since we're providing full paths
        p: imageMap[asset.p], // Replace with imported image URL
      };
    }
    return asset;
  });

  return updatedAnimationData;
};
