import React from 'react';
import { directory } from '@pointdotcom/pds';
import { ProductType } from 'services/apiTypes/commonTypes';
import { Products } from 'store/constants';
import { useProduct } from './useProduct';

export type SupportLinkProps = JSX.IntrinsicElements['a'] & {
  supportNumber: string;
};

export function SupportLink({ supportNumber, children, ...otherProps }: SupportLinkProps) {
  return (
    <a href={`tel:${encodeURIComponent(supportNumber)}`} {...otherProps}>
      {children}
    </a>
  );
}

export const usePointContact = ({ product: productFromProps }: { product?: ProductType } = {}) => {
  const { product: productFromHook } = useProduct();
  const product = productFromProps ?? productFromHook ?? ProductType.HEI;
  const { PointNumber } = directory;

  const productSupportNumberMap = {
    [Products.HEI]: PointNumber.Support,
  };

  const supportNumber = productSupportNumberMap[product] || PointNumber.Support;

  return { supportNumber };
};
