import React from 'react';
import { Container, Header } from '@pointdotcom/pds';
import RangeBuyBackTime from 'components/RangeBuyBackTime';
import i18n from './i18n';
import * as styles from './styles';

const BuyBackControlSection = React.forwardRef(
  ({ estimate, getBuyBackTime, setBuyBackTime }, ref) => {
    const buybackQuestion = i18n.whenDoYou;
    const buybackNote = i18n.homeowners;

    return (
      <styles.ControlSectionStyle className="BuyBackControlSection" ref={ref}>
        <Container>
          <styles.HeaderDescInputContainerStyle>
            <styles.HeaderDescriptionStyle>
              <Header styleAlign="left" styleAlignMobile="left" noMargin>
                {buybackQuestion}
              </Header>
              <p>{buybackNote}</p>
            </styles.HeaderDescriptionStyle>
            <RangeBuyBackTime
              estimate={estimate}
              buyBackTime={getBuyBackTime()}
              setBuyBackTime={setBuyBackTime}
            />
          </styles.HeaderDescInputContainerStyle>
        </Container>
      </styles.ControlSectionStyle>
    );
  }
);

export default BuyBackControlSection;
