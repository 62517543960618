import styled from 'styled-components';

export const UploadIconStyle = styled.svg.attrs({ className: 'UploadIconStyle' })`
  display: block;
  width: 21px;

  path {
    stroke: currentColor;
  }
`;
