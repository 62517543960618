import React from 'react';
import { Style } from '@pointdotcom/pds';
import ConsentManagementLink from 'components/ConsentManagementLink';
import HeiTermsFooter from 'components/HeiTermsFooter';
import MainFooter from 'components/prequal/MainFooter';
import LockKeyLandingPage from 'containers/prequal/pages/PreQualLandingPage/LockKeyLandingPage';

const LockKeyLandingPageSingleProduct = () => {
  return (
    <LockKeyLandingPage>
      <MainFooter styleType={Style.White} />
      <div className="hideWhenFieldFocused">
        <HeiTermsFooter noBorder />
      </div>
      <div className="hideWhenFieldFocused">
        <ConsentManagementLink />
      </div>
    </LockKeyLandingPage>
  );
};

export default LockKeyLandingPageSingleProduct;
