import {
  navItemsHeiEnhancedEducationOfferEstimator,
  navItemsHeiSinglePageOfferEstimator,
} from 'components/MainHeader/nav';
import OfferEstimateModel from 'models/OfferEstimateModel';

export function useHeiNavItems({ estimate }: { estimate?: OfferEstimateModel | null }) {
  return estimate?.getSourceIsEnhancedEducation()
    ? navItemsHeiEnhancedEducationOfferEstimator
    : navItemsHeiSinglePageOfferEstimator;
}
