export default {
  yes: 'Yes',
  no: 'No',
  pleaseExplain: 'Please explain below.',
  theAboveConsents: 'You must authorize the above consents in order to continue',
  invalidInput: 'Invalid input',
  valueMustBeGreater: 'Value must be greater than or equal to %num%',
  firstName: 'First name',
  lastName: 'Last name',
  add: 'Add',
  remove: 'Remove',
};
