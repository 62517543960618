export default {
  stillHave: 'Still have questions? We can help you decide if Point is right for you. ',
  ourNext: 'Our next available time is %day%, at %time% with %person%',
  clickOn: 'Click on a time below to book a consultation',
  chooseA: 'Choose a different time',
  aPointRep: 'a Point representative',
  oops: 'Oops, looks like that time slot is no longer available. Please choose another time below',
  findAnAvailable: 'Find an available time',
  tryClickingBelow: 'Try clicking below to find another available time.',
  bookThisTime: 'Book this time',
};
