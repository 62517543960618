import { createSlice } from '@reduxjs/toolkit';
import { TaskType } from 'services/apiTypes/taskTypes';
import { RootState } from 'store';
import { UtmParameters } from 'types';

export enum NoTasksFooterLinkType {
  Dashboard = 'dashboard',
  Default = 'default',
}

export interface CompletedTask {
  noTasksFooterLinkType?: NoTasksFooterLinkType; // Effect on the task complete modal: determines which link to show in the footer when there are no tasks
  subtle?: boolean; // Effect on the task complete modal: 1. No header graphic, 2. Terse text (Next task:) in the footer
  title?: string;
  heading?: string;
  summary: string;
  detailList?: {
    ordered: boolean;
    items: string[];
  };
  taskType?: TaskType;
}

interface GeneralState {
  applicantContactModalOpen: boolean;
  saveApplicantContactModalOpen: boolean;
  taskCompletedModalOpen: boolean;
  taskCompleted?: CompletedTask;
  bannerMessage?: string;
  bannerWasDismissed: boolean;
  mainHeaderMobileNavMenuOpen: boolean;
  utmParameters: UtmParameters;
  featureFlags: Record<string, string | boolean | number>;
  dashboardChatAcknowledged: boolean;
}

export const initialState: GeneralState = {
  bannerWasDismissed: false,
  mainHeaderMobileNavMenuOpen: false,
  applicantContactModalOpen: false,
  saveApplicantContactModalOpen: false,
  taskCompletedModalOpen: false,
  taskCompleted: undefined,
  utmParameters: {},
  featureFlags: {},
  dashboardChatAcknowledged: false,
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    openTaskCompletedModal: (state, { payload }: { payload: CompletedTask }) => {
      state.taskCompletedModalOpen = true;
      state.taskCompleted = payload;
    },
    setTaskCompletedModalOpen: (state, { payload }: { payload: boolean }) => {
      state.taskCompletedModalOpen = payload;
    },
    resetTaskCompleted: (state) => {
      state.taskCompleted = undefined;
    },
    setMainHeaderMobileNavMenuOpen: (state, { payload }) => {
      state.mainHeaderMobileNavMenuOpen = payload;
    },
    setBannerMessage: (state, { payload }) => {
      state.bannerMessage = payload;
    },
    dismissBannerMessage: (state) => {
      state.bannerWasDismissed = true;
    },
    setApplicantContactModalOpen: (state, { payload }) => {
      state.applicantContactModalOpen = payload;
    },
    setSaveApplicantContactModalOpen: (state, { payload }) => {
      state.saveApplicantContactModalOpen = payload;
    },
    setUtmParameters: (state, { payload }) => {
      state.utmParameters = payload;
    },
    recordFeatureFlag: (state, { payload }) => {
      if (payload.value != null) {
        state.featureFlags[payload.flag] = payload.value;
      }
    },
    setDashboardChatAcknowledged: (state, { payload }) => {
      state.dashboardChatAcknowledged = payload;
    },
  },
});

export const getBannerMessage = ({ general: generalStore }: RootState): string | undefined =>
  generalStore.bannerMessage;

export const getBannerWasDismissed = ({ general: generalStore }: RootState): boolean =>
  generalStore.bannerWasDismissed;

export const getMainHeaderMobileNavMenuOpen = ({ general: generalStore }: RootState): boolean =>
  generalStore.mainHeaderMobileNavMenuOpen;

export const getApplicantContactModalOpen = ({ general: generalStore }: RootState): boolean =>
  generalStore.applicantContactModalOpen;

export const getSaveApplicantContactModalOpen = ({ general: generalStore }: RootState): boolean =>
  generalStore.saveApplicantContactModalOpen;

export const getTaskCompletedModalOpen = ({ general: generalStore }: RootState): boolean =>
  generalStore.taskCompletedModalOpen;

export const getTaskCompleted = ({ general: generalStore }: RootState): CompletedTask | undefined =>
  generalStore.taskCompleted;

export const getDashboardChatAcknowledged = ({ general: generalStore }: RootState): boolean =>
  generalStore.dashboardChatAcknowledged;

export const {
  openTaskCompletedModal,
  setBannerMessage,
  dismissBannerMessage,
  setMainHeaderMobileNavMenuOpen,
  setApplicantContactModalOpen,
  setSaveApplicantContactModalOpen,
  setTaskCompletedModalOpen,
  resetTaskCompleted,
  setUtmParameters,
  recordFeatureFlag,
  setDashboardChatAcknowledged,
} = generalSlice.actions;

export default generalSlice.reducer;
