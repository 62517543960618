import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Header,
  Icon,
  IconName,
  LinkButton,
  Modal,
  ModalStyles,
  QueryComponent,
  Style,
  TemplatedText,
} from '@pointdotcom/pds';
import CapPriceTable, { capDescriptionTitleText } from 'components/CapPriceTable';
import i18n from './i18n';
import * as styles from './styles';

export const modalContentType = {
  CAP: 'cap',
  PRICING: 'pricing',
};

const PricingModalContents = ({ onModalClose }) => (
  <>
    <ModalStyles.ModalSplashHeaderStyle styleAlign="left">
      {i18n.pricingIsBased}
    </ModalStyles.ModalSplashHeaderStyle>
    <LinkButton onClick={onModalClose}>{i18n.close}</LinkButton>
  </>
);

export default function PricingPresentationWithMath({
  estimate,
  callOutTextColor,
  callOutLineColor,
  headerProps = {
    styleAlign: 'center',
    styleAlignMobile: 'center',
  },
  capModalOpen,
  pricingModalOpen,
  onModalClose = () => null,
}) {
  const [modalType, setModalType] = useState();
  const [modalOpen, setModalOpen] = useState();

  useEffect(() => {
    if (capModalOpen) {
      openCapModal();
    } else if (pricingModalOpen) {
      openPricingModal();
    }
  }, [capModalOpen, pricingModalOpen]);

  const modalRef = useRef(null);

  const handleModalClose = () => {
    setModalOpen(false);
    onModalClose();
  };

  const openCapModal = (e) => {
    e?.preventDefault();
    setModalOpen(true);
    setModalType(modalContentType.CAP);
  };

  const openPricingModal = (e) => {
    e?.preventDefault();

    setModalOpen(true);
    setModalType(modalContentType.PRICING);
  };

  let headerText = null;
  let content = null;

  if (!estimate) {
    return null;
  }

  if (modalType === modalContentType.PRICING) {
    headerText = i18n.howPointDetermines;
    content = <PricingModalContents onModalClose={handleModalClose} />;
  } else if (modalType === modalContentType.CAP) {
    headerText = capDescriptionTitleText;
    content = (
      <CapPriceTable
        estimate={estimate}
        onModalClose={handleModalClose}
        numRowsVisible={3}
        verbose
      />
    );
  }

  const estimatePricing = estimate.getPricing();

  return (
    <>
      <Modal
        onModalClose={handleModalClose}
        ref={modalRef}
        headerText={headerText}
        headerMaxWidth="390px"
        isOpen={modalOpen}
      >
        {content}
      </Modal>
      <styles.PricingPresentationWithMathStyle>
        <Container>
          <Header {...headerProps}>{i18n.whenYou}</Header>
          <styles.CalloutContainerStyle>
            {/* First item */}
            <styles.CalloutItemStyle className="firstCallout">
              <styles.CalloutHeaderTextStyle isPlaceholder>&nbsp;</styles.CalloutHeaderTextStyle>
              <styles.CalloutPreheaderTextStyle>
                {i18n.theOriginal}
              </styles.CalloutPreheaderTextStyle>
              <styles.CalloutHeaderTextStyle>
                {estimatePricing?.getFormattedOptionInvestmentAmount()}
              </styles.CalloutHeaderTextStyle>
            </styles.CalloutItemStyle>

            {/* Plus */}
            <styles.CalloutItemPlusStyle
              callOutTextColor={callOutTextColor}
              callOutLineColor={callOutLineColor}
            >
              <Header noMargin>
                <QueryComponent
                  queriedComponent={i18n.plusTheLower}
                  breakpoint={styles.calloutContainerBreakpoint}
                >
                  {i18n.plus}
                </QueryComponent>
              </Header>
            </styles.CalloutItemPlusStyle>

            {/* Second, third items */}
            <styles.CalloutOrItemsContainerStyle
              data-pretext={i18n.theLowerOf}
              callOutTextColor={callOutTextColor}
              callOutLineColor={callOutLineColor}
            >
              <styles.CalloutItemStyle className="secondCallout">
                <TemplatedText
                  values={{
                    perc: (
                      <styles.CalloutHeaderTextStyle>
                        {estimatePricing?.getFormattedOptionPercentage()}
                      </styles.CalloutHeaderTextStyle>
                    ),
                    ofYourHome: (
                      <styles.CalloutPreheaderTextStyle>
                        {i18n.ofYourHome}
                      </styles.CalloutPreheaderTextStyle>
                    ),
                    aboveAmount: (
                      <div>
                        <TemplatedText
                          values={{
                            above: (
                              <styles.CalloutPreheaderTextStyle style={{ marginBottom: 0 }}>
                                {i18n.above}
                              </styles.CalloutPreheaderTextStyle>
                            ),
                            amount: (
                              <styles.CalloutHeaderTextStyle>
                                {estimatePricing?.getFormattedRiskAdjustedHomeValue()}
                              </styles.CalloutHeaderTextStyle>
                            ),
                          }}
                        >
                          {i18n.aboveAmount}
                        </TemplatedText>
                      </div>
                    ),
                  }}
                >
                  {i18n.percOfYourHome}
                </TemplatedText>
                <styles.CalloutLinkContainerStyle onClick={openPricingModal}>
                  <styles.CalloutLinkStyle>{i18n.learnHow}</styles.CalloutLinkStyle>
                  <Icon name={IconName.ChevronRight} styleType={Style.InvertedSubtle} />
                </styles.CalloutLinkContainerStyle>
              </styles.CalloutItemStyle>
              <styles.CalloutItemStyle className="thirdCallout">
                <styles.CalloutHeaderTextStyle isPlaceholder>&nbsp;</styles.CalloutHeaderTextStyle>
                <styles.CalloutPreheaderTextStyle>
                  {i18n.theHomeowner}
                </styles.CalloutPreheaderTextStyle>
                <styles.CalloutPlainTextStyle>{i18n.aTimeBased}</styles.CalloutPlainTextStyle>
                <styles.CalloutLinkContainerStyle onClick={openCapModal}>
                  <styles.CalloutLinkStyle>{i18n.learnMore}</styles.CalloutLinkStyle>
                  <Icon name={IconName.ChevronRight} styleType={Style.InvertedSubtle} />
                </styles.CalloutLinkContainerStyle>
              </styles.CalloutItemStyle>
            </styles.CalloutOrItemsContainerStyle>
          </styles.CalloutContainerStyle>
        </Container>
      </styles.PricingPresentationWithMathStyle>
    </>
  );
}
