import { HosDashboardResponse, HosDashboardTask } from 'services/apiTypes/homeownerTypes';
import { User } from 'services/apiTypes/middlewareTypes';
import { TaskStatus, TaskType } from 'services/apiTypes/taskTypes';
import {
  DashboardApplication,
  DashboardPerson,
  DocketStage,
} from 'services/apiTypes/underwriteTypes';
import BaseModel from './BaseModel';
import ContactModel from './ContactModel';
import DocketModel, { Docket } from './DocketModel';
import { currencyMask, normalizeProps } from './helpers';

export interface DashboardActivity {
  id: string;
  description: string;
}

export interface RecentActivity {
  id: number;
  description: string;
  date: string;
}

export interface DashboardStage {
  name: DocketStage;
  title: string;
  description: string;
  steps?: Array<DashboardActivity>;
  // eslint-disable-next-line camelcase
  completed_at?: string; // TODO: camel case
}

export type DashboardAccountManager = User | string;

export interface Dashboard {
  readOnly: boolean;
  docket?: Docket;
  accountManager: DashboardAccountManager;
  authenticate: boolean;
  activeStage: string;
  stages: Array<DashboardStage>;
  recentActivity?: Array<RecentActivity>;
  person: DashboardPerson;
  application?: DashboardApplication;
}

type TaskFilters<TType extends TaskType = TaskType> = {
  status?: TaskStatus | TaskStatus[];
  type?: TType;
};

export function formatStageName(stage: DashboardStage): string {
  const stageName = stage.name;

  // Capitalize stage name and handle snake_case values from back end.
  return stageName
    .replaceAll('_', ' ')
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
}

class DashboardModel extends BaseModel<Dashboard> implements Dashboard {
  docket: TSFixMe;

  readOnly = true;

  authenticate = true;

  stages = [] as DashboardStage[];

  activeStage = DocketStage.Application;

  person = {} as DashboardPerson;

  recentActivity = [] as RecentActivity[];

  accountManager = '';

  application?: DashboardApplication;

  protected tasks?: HosDashboardTask[];

  constructor(props: Dashboard | HosDashboardResponse) {
    const revisedProps = normalizeProps<Dashboard>(props as TSFixMe, {
      active_stage: 'activeStage',
      read_only: 'readOnly',
      account_manager: 'accountManager',
      recent_activity: 'recentActivity',
    });
    super(revisedProps);
    Object.assign(this, revisedProps);
  }

  getContact(): ContactModel {
    return new ContactModel(this.person);
  }

  getDocket(): DocketModel | null {
    if (this.docket) {
      return new DocketModel(this.docket);
    }

    return null;
  }

  getStageByName(stageName: DocketStage): undefined | DashboardStage {
    return this.stages.find((stage) => stage.name === stageName);
  }

  getActiveStage(): DashboardStage | undefined {
    const stage = this.stages.find(this.getStageIsActive.bind(this));
    return stage;
  }

  getTasks<TType extends TaskType = TaskType>(
    filters: TaskFilters<TType> = {}
  ): Array<HosDashboardTask & { type: TType }> {
    const statuses = typeof filters.status === 'string' ? [filters.status] : filters.status;

    return (this.tasks ?? []).filter(
      (task) =>
        (!statuses || statuses.includes(task.status)) &&
        (filters.type == null || task.type === filters.type)
    ) as Array<HosDashboardTask & { type: TType }>;
  }

  getTask<TType extends TaskType = TaskType>(
    filters: TaskFilters<TType> = {}
  ): undefined | (HosDashboardTask & { type: TType }) {
    return this.getTasks(filters)[0];
  }

  getFollowupQuestionItems(): HosDashboardTask[] {
    // TODO: CE-1462 Support other task types (and expect `type` field to always be present)
    return (this.tasks ?? []).filter(
      (task) =>
        task.type == null ||
        task.type === TaskType.GenericTextFollowupQuestion ||
        task.type === TaskType.GenericDocumentFollowupQuestion
    );
  }

  getFollowupQuestionItemsByStatus(itemStatus: TaskStatus): HosDashboardTask[] {
    return this.getFollowupQuestionItems().filter(({ status }) => status === itemStatus);
  }

  getFormattedActiveStageName(): string | null {
    const activeStage = this.getActiveStage();
    if (!activeStage) {
      return null;
    }

    return formatStageName(activeStage);
  }

  getIndexOfStage(stage: DashboardStage): number {
    return this.stages.findIndex(({ name }: DashboardStage) => name === stage.name);
  }

  getStageIsActive(stage: DashboardStage): boolean {
    return stage.name === this.activeStage;
  }

  getStageIsComplete(stage: DashboardStage): boolean {
    const activeStage = this.getActiveStage();
    const activeStageIndex = activeStage ? this.getIndexOfStage(activeStage) : -1;
    const stageIndex = this.getIndexOfStage(stage);
    return stageIndex > -1 && stageIndex < activeStageIndex;
  }

  getStageIsIncomplete(stage: DashboardStage): boolean {
    const activeStage = this.getActiveStage();
    const activeStageIndex = activeStage ? this.getIndexOfStage(activeStage) : -1;
    const stageIndex = this.getIndexOfStage(stage);
    return stageIndex > -1 && stageIndex > activeStageIndex;
  }

  getNeedsAuth(): boolean {
    return this.authenticate;
  }

  getAccountManager(): DashboardAccountManager {
    return this.accountManager;
  }

  getRecentActivity(): RecentActivity | undefined {
    return this.recentActivity?.[0];
  }

  getCurrentOffer(): null | number {
    return this.getDocket()?.getLatestOffer()?.optionInvestmentAmount ?? null;
  }

  getFormattedCurrentOffer(): null | string {
    const currentOffer = this.getCurrentOffer();
    if (currentOffer == null) {
      return null;
    }
    return currencyMask.getFormatted(currentOffer);
  }

  getApplicationTask(): (HosDashboardTask & { type: TaskType.Application }) | undefined {
    return this.getTask({
      type: TaskType.Application,
      status: TaskStatus.Active,
    });
  }

  getScheduleCallParams() {
    const task = this.getTask({
      type: TaskType.QuizOrProductCall,
      status: TaskStatus.Active,
    })?.detail;
    let scheduleParams = {};
    if (task) {
      scheduleParams = {
        EMAIL: task.email,
        FNAME: task.firstName,
        LNAME: task.lastName,
        PHONENUMBER: task.phoneNumber,
        team: task.callWith,
      };
    }
    return scheduleParams;
  }
}

export default DashboardModel;
