import React from 'react';
import {
  ButtonLeftRightIconType,
  DropDownProps,
  Range,
  RangeChangeEvent,
  RangeProps,
  Size,
  Style,
} from '@pointdotcom/pds';
import {
  PricingRangeType,
  getHEIPriceRangeSelectedIndex,
  setHEIPriceRangeSelectedIndex,
} from 'store/estimates';
import { useDispatch, useSelector } from 'store/hooks';
import i18n from './i18n';

export interface RangeEstimatePrepaymentProps {
  dropdownButtonProps?: DropDownProps['buttonLeftRightProps'];
  styleType?: RangeProps['styleType'];
  onChange?: RangeChangeEvent;
  estimate?: unknown;
  pricingRange: null | PricingRangeType;
  rangeProps?: Partial<
    Omit<RangeProps, 'dropdownProps' | 'max' | 'min' | 'onChange' | 'styleType' | 'value'>
  >;
}

export default function RangeEstimatePrepayment({
  dropdownButtonProps = {},
  styleType = Style.Default,
  onChange = () => null,
  pricingRange,
  rangeProps,
}: RangeEstimatePrepaymentProps): null | JSX.Element {
  const dispatch = useDispatch();
  const selectedIndex = useSelector(getHEIPriceRangeSelectedIndex);

  if (!pricingRange || !Array.isArray(pricingRange.estimates)) {
    return null;
  }
  const handleAmountChange: RangeChangeEvent = (e, { value }) => {
    const newSelectedIndex = parseInt(`${value}`, 10);
    dispatch(setHEIPriceRangeSelectedIndex(newSelectedIndex));
    onChange(e, { value });
  };

  const formatValue: RangeProps['valFormatter'] = (index) =>
    pricingRange.estimates[index]?.getFormattedOptionInvestmentAmount();

  const numEstimates = pricingRange.estimates?.length;
  let tickVisibility;
  if (numEstimates >= 40) {
    tickVisibility = '6n+0';
  } else if (numEstimates >= 30) {
    tickVisibility = '4n+0';
  } else if (numEstimates >= 20) {
    tickVisibility = '2n+0';
  }

  return (
    <Range
      label={i18n.howMuchMoney}
      dropdownProps={{
        buttonLeftRightProps: {
          iconType: ButtonLeftRightIconType.PlusMinus,
          ...dropdownButtonProps,
        },
      }}
      min={0}
      max={pricingRange.estimates.length - 1}
      valFormatter={formatValue}
      tickVisibility={tickVisibility}
      onChange={handleAmountChange}
      value={selectedIndex}
      styleSize={Size.Large}
      styleType={styleType}
      gaTrackingId="RangeEstimatePrepayment"
      thumbSize={
        rangeProps?.styleSize == null
          ? {
              width: 168,
              height: 57,
            }
          : undefined
      }
      {...rangeProps}
    />
  );
}
