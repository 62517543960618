import React from 'react';
import Helmet from 'react-helmet';
import { Button, DirectionAndPlacement } from '@pointdotcom/pds';
import FAQSection from 'components/FAQSection';
import HeroWithOptionAmtSection from 'components/HeroWithOptionAmtSection';
import HowItWorks3ImgsSection from 'components/HowItWorks3ImgsSection';
import MainHeader from 'components/MainHeader';
import PhoneAndHELOCLinkSection from 'components/PhoneAndHELOCLinkSection';
import PointComparisonTableSection from 'components/PointComparisonTableSection';
import PointExplained3ImgsSection from 'components/PointExplained3ImgsSection';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { useHistory } from 'containers/routerHelpers';
import i18n from './i18n';
import * as styles from './styles';

interface MainPageCTAProps {
  estimateKey: string;
  children?: React.ReactNode;
  styleAlign?: DirectionAndPlacement.Center | DirectionAndPlacement.Left;
}

const MainPageCTA = ({
  children,
  styleAlign = DirectionAndPlacement.Center,
  estimateKey,
}: MainPageCTAProps) => {
  const history = useHistory();
  if (!estimateKey) {
    return null;
  }

  const handleClick = () => {
    history.push(
      generateUrlFromPage(pages.HEI_PRICING, {
        estimateKey,
      })
    );
  };
  return (
    <Button mobileBlock styleAlign={styleAlign} onClick={handleClick}>
      {children || i18n.nextView}
    </Button>
  );
};

interface AboutPageProps {
  estimateKey: string;

  navItems: TSFixMe;
  navProps: TSFixMe;
  bannerProps: TSFixMe;
  headerType: TSFixMe;
  subHeaderProps: TSFixMe;
  estimate: TSFixMe;
}

const AboutPage = ({
  navItems,
  navProps,
  bannerProps,
  headerType,
  subHeaderProps,
  estimate,
  estimateKey,
}: AboutPageProps) => {
  return (
    <styles.AboutPageStyle>
      <Helmet title={i18n.aboutPoint} />
      <MainHeader
        bannerProps={bannerProps}
        navProps={navProps}
        navItems={navItems}
        headerType={headerType}
        subHeaderProps={subHeaderProps}
      />
      <HeroWithOptionAmtSection estimate={estimate}>
        <MainPageCTA estimateKey={estimateKey} />
      </HeroWithOptionAmtSection>
      <PointExplained3ImgsSection estimate={estimate} />
      <PointComparisonTableSection>
        <MainPageCTA estimateKey={estimateKey}>{i18n.viewMy}</MainPageCTA>
      </PointComparisonTableSection>
      <HowItWorks3ImgsSection />
      <FAQSection>
        <MainPageCTA styleAlign={DirectionAndPlacement.Center} estimateKey={estimateKey} />
      </FAQSection>
      <PhoneAndHELOCLinkSection hideHelocLink />
    </styles.AboutPageStyle>
  );
};

export default AboutPage;
