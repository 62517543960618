import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'containers/routerHelpers';
import BugsnagClient from 'lib/bugsnagClient';
import ExpiredLoginLinkPage from './ExpiredLoginLinkPage';
import i18n from './i18n';

export const MESSAGE_TIMEOUT_MS = 5000;

export enum BannerMessageSource {
  Expired = 'expired',
  Invalid = 'invalid',
  Logout = 'logout',
  ApplicationFormExpired = 'applicationformexpired',
  AuthRequired = 'authrequired',
}

export enum SourceBehavior {
  ClearBannerMessage = 'clearBannerMessage',
}

type UseLoginPageMessaging =
  | {
      bannerMessage: string | null;
      shouldShowExpiredPage: true;
      expiredLinkPage: JSX.Element;
    }
  | {
      bannerMessage: string | null;
      shouldShowExpiredPage: false;
    };

const useLoginPageMessaging = (): UseLoginPageMessaging => {
  const SEARCH_PARAM = 'source';

  const sourceMap = {
    [BannerMessageSource.Expired]: i18n.sorryTheLink,
    [BannerMessageSource.Invalid]: i18n.thisLinkIs,
    [BannerMessageSource.Logout]: i18n.youveBeen,
    [BannerMessageSource.ApplicationFormExpired]: i18n.thePageYouWereOn,
    [BannerMessageSource.AuthRequired]: i18n.youllNeedTo,
  };
  const sourceBehavior: Record<
    SourceBehavior,
    Partial<Record<BannerMessageSource, boolean>>
  > = useMemo(() => {
    return {
      [SourceBehavior.ClearBannerMessage]: {
        [BannerMessageSource.ApplicationFormExpired]: false,
      },
    };
  }, []);
  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const source = (searchParams.get(SEARCH_PARAM) || '').toLowerCase() as BannerMessageSource;
  const key = searchParams.get('key');
  const sourceMapSource = sourceMap[source];
  const shouldShowExpiredPage = source === BannerMessageSource.Expired && !!key;
  const [bannerMessage, setBannerMessage] = useState(source ? sourceMap[source] : null);

  useEffect(() => {
    const shouldClearBannerMessage =
      source && sourceBehavior[SourceBehavior.ClearBannerMessage][source] !== false;

    if (shouldShowExpiredPage) {
      return;
    }

    searchParams.delete('source');
    history.replace({ pathname: location.pathname, search: searchParams.toString() });

    if (bannerMessage && shouldClearBannerMessage) {
      setTimeout(() => {
        setBannerMessage(null);
      }, MESSAGE_TIMEOUT_MS);
    }
  }, [
    bannerMessage,
    history,
    location.pathname,
    searchParams,
    source,
    sourceBehavior,
    shouldShowExpiredPage,
  ]);

  if (source && !sourceMapSource) {
    BugsnagClient.notify(
      new Error(`Unknown logout source: ${source}. Cannot display message to user on login page.`)
    );
  }

  if (shouldShowExpiredPage) {
    return {
      bannerMessage,
      shouldShowExpiredPage,
      expiredLinkPage: <ExpiredLoginLinkPage urlKey={key} />,
    };
  }

  return {
    bannerMessage,
    shouldShowExpiredPage,
  };
};

export default useLoginPageMessaging;
