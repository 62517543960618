import styled from 'styled-components';
import { mixins } from '@pointdotcom/pds';
import { PlayButtonStyle } from 'components/VideoPlayer/PlayButton';

export const ThumbnailStyle = styled.div.attrs({ className: 'ThumbnailStyle' })`
  display: flex;
  flex-direction: column;
  position: relative;

  img {
    width: 100%;
  }

  ${PlayButtonStyle} {
    position: absolute;
    bottom: calc(var(--size) / -2);
    right: calc(var(--size) / 3);
    box-shadow: 0 0rem 3rem rgba(0, 0, 0, 0.5);
  }
`;

export const VideoButtonStyle = styled.button.attrs({
  className: 'VideoButtonStyle',
  type: 'button',
})`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  ${mixins.desktopHoverCondition()} {
    &:hover {
      ${PlayButtonStyle} {
        transform: scale(1.1);
      }
    }
  }
`;

export const VideoButtonMiniStyle = styled(VideoButtonStyle)`
  box-shadow: 0 0rem 2rem rgba(0, 0, 0, 0.2);
  padding: 2rem;
  max-width: 180px;
  background: white;
  ${PlayButtonStyle} {
    --size: 2rem;
  }
`;
