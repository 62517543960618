import { Location } from 'history';
import { HistoryNavigation } from 'containers/routerHelpers';

// HACK: BE doesnt want direct-mail form keys -
// https://github.com/pointdotcom/prequal/issues/1378
// https://pointfinance.slack.com/archives/D9RM0NGRE/p1606871757008800

const directMailRegExp = /direct-mail/;

export const clearDirectMailFormKeyFromURL = (history: HistoryNavigation, location: Location) => {
  const params = new URLSearchParams(location.search);

  const formKeyParam = params.get('form_key');
  if (formKeyParam != null && directMailRegExp.test(formKeyParam)) {
    params.delete('form_key');
    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  }
};
