import React, { ReactNode } from 'react';

export function reactNodeToString(node: ReactNode): string {
  if (node === null || node === undefined) {
    return '';
  }

  if (typeof node === 'string' || typeof node === 'number') {
    return node.toString();
  }

  if (React.isValidElement(node)) {
    const children = React.Children.toArray(node.props.children);
    return children.map((child) => reactNodeToString(child)).join(' ');
  }

  if (Array.isArray(node)) {
    return node.map((item) => reactNodeToString(item)).join('');
  }

  return '';
}
