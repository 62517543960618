import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { ContainerStyles, mixins } from '@pointdotcom/pds';
import { Direction, LineStyleType } from './types';

const FullScreenCarouselStyle = styled.div.attrs({ className: 'FullScreenCarouselStyle' })<{
  show: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  color: white;
  ${ContainerStyles.ContainerStyle} {
    position: relative;
  }
  ${(props) => {
    let zIndex = '1';
    let opacity = '0';
    let scale = '0';
    let transition = '';

    if (props.show) {
      scale = '1';
      opacity = '1';
      zIndex = '100';
      transition = 'transition: opacity 320ms ease-out';
    }

    return `
      transform: scale(${scale});
      z-index: ${zIndex};
      opacity: ${opacity};
      ${transition}
    `;
  }};
  @media only print {
    background: white !important;
    color: inherit;
  }
`;

const CarouselItemStyle = styled.div.attrs({ className: 'CarouselItemStyle' })<{
  offsetRight: boolean;
  offsetLeft: boolean;
  animationStatus: TransitionStatus;
  direction: null | Direction;
}>`
  top: 0;
  left: 0;
  width: 100%;
  user-select: none;
  cursor: ew-resize;
  ${(props) => {
    const offsetAmount = '50%';
    const transition = 'transition: all 400ms ease-out, transform 400ms ease-out;';
    let opacity = '0';
    let position = 'absolute';
    let zIndex = '1';
    let x = props.offsetRight ? offsetAmount : '0';
    let scale = '0.7';
    x = props.offsetLeft ? `-${offsetAmount}` : x;

    if (props.animationStatus === 'entering' || props.animationStatus === 'entered') {
      scale = '1';
      opacity = '1';
    }

    if (props.animationStatus === 'entered') {
      position = 'relative;';
      zIndex = '2';
      x = '0';
    }

    if (props.animationStatus === 'exiting') {
      x = props.direction === 'right' ? `-${offsetAmount}` : offsetAmount;
    }

    return `
      transform: translateX(${x}) scale(${scale});
      ${transition};
      opacity: ${opacity};
      position: ${position};
      z-index: ${zIndex};
    `;
  }};
`;

const CarouselLeftRightControlStyle = styled.button.attrs({
  className: 'CarouselLeftRightControlStyle',
})<{
  atFirstIndex: boolean;
  atLastIndex: boolean;
  current?: boolean;
  position: 'left' | 'right';
}>`
  ${mixins.appearance('none')}
  transition: all 520ms ease-in-out 120ms, background 220ms ease-in-out;
  width: 100px;
  height: 100px;
  background: white;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: calc(50% - 50px);
  cursor: pointer;
  z-index: 101;
  ${(props) => {
    let x = '0';
    if (props.atFirstIndex === true && props.position === 'left') {
      x = '-100%';
    } else if (props.atLastIndex === true && props.position === 'right') {
      x = '100%';
    }

    return `
      transform: translateX(${x});
      ${props.position}: -12.5px;
    `;
  }};
  border-radius: ${(props) => (props.position === 'left' ? '0 50% 50% 0' : '50% 0 0 50%')};
  display: flex;
  ${mixins.desktopHoverCondition()} {
    &:hover {
      background: ${(props) =>
        props.current ? props.theme.Color.White : mixins.rgba(props.theme.Color.White, 0.5)};
    }
  }
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    display: none;
  }
  @media only print {
    display: none;
  }
`;

const CarouselTopSectionStyle = styled.div.attrs({ className: 'CarouselTopSectionStyle' })<{
  bannerLineSeparationType: null | LineStyleType;
}>`
  display: flex;
  flex-flow: column;
  flex-direction: column;
  position: relative;
  z-index: 2;
  > div {
    display: flex;
    flex: 1;
    align-items: center;
    &:first-child {
      flex: 100px;
      justify-content: flex-end;
    }
    &:last-child {
      align-items: flex-end;
      justify-content: center;
    }
  }
  ${({ bannerLineSeparationType, theme }) => {
    const lineColor =
      bannerLineSeparationType != null ? theme.line.color[bannerLineSeparationType] : null;
    if (lineColor) {
      return css`
        border-top: 1px solid ${lineColor};
      `;
    }
    return null;
  }}
`;

export {
  CarouselLeftRightControlStyle,
  CarouselTopSectionStyle,
  CarouselItemStyle,
  FullScreenCarouselStyle,
};
