import { Location } from 'history';
import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';
import nullthrows from 'nullthrows';
import querystring from 'querystring';
import { PathMatch, matchPath } from 'react-router';
import { generatePath } from 'react-router-dom';
import { dayjs } from 'lib/dayjs';
import { Products } from 'store/constants';
import { Address } from 'types';

// TODO: we should probably have the full paths here and import them into the router to keep all in sync
export enum Page {
  // Common
  OFFER = 'offer',
  ESTIMATOR = 'estimator',
  DECISION = 'decision',
  ERROR_GENERAL = 'errorgeneral',
  SCHEDULE_COMPLETE = 'schedulecomplete',

  // Dashboard
  DASHBOARD = 'dashboard',
  DASHBOARD_ESTIMATOR = 'dashboardestimator',
  DASHBOARD_LOGIN = 'dashboardlogin',
  DASHBOARD_PROCESS_OVERVIEW = 'dashboardprocessoverview',
  DASHBOARD_TASKS_LIST = 'dashboardtaskslist',
  DASHBOARD_TASK = 'dashboardtask',

  // Follow ups
  FOLLOW_UP_DEFAULT = 'followupdefault',
  FOLLOW_UP_PAGE = 'followuppage',
  FOLLOW_UP_SCHEDULE_COMPLETE = 'followupschedulecomplete',

  // HEI
  HEI_SCHEDULE_COMPLETE = 'heischedulecomplete',
  HEI_DEFAULT = 'heidefault',
  HEI_OFFER = 'heioffer',
  HEI_ESTIMATOR = 'heiestimator',
  HEI_ABOUT = 'heiabout',
  HEI_PRICING = 'heipricing',
  HEI_APPLICATION = 'heiapplication',

  // OLD HEI estimate catch-all route. Remove after transition period of UW application to CP application
  DEPRECIATED_ESTIMATE_ROUTE = 'depreciatedestimateroute',

  // Prequal
  PREQUAL_DEFAULT = 'prequaldefault',
  PREQUAL_PAGE = 'prequalpage',
  PREQUAL_SCHEDULE = 'prequalschedule',
  PREQUAL_START = 'prequalstart',
  PREQUAL_OFFER = 'prequaloffer',
  PREQUAL_OFFER_CODE = 'prequaloffercode',
  PREQUAL_OFFER_CODE_DIRECT = 'prequaloffercodedirect',
  PREQUAL_UNIT_NUMBER = 'prequalunitnumber',

  // Estimate calculator
  ESTIMATE_CALCULATOR_DEFAULT = 'estimatecalculatordefault',
  ESTIMATE_CALCULATOR_OFFER = 'estimatecalculatoroffer',
  ESTIMATE_CALCULATOR_ESTIMATOR = 'estimatecalculatorestimator',
  ESTIMATE_CALCULATOR_DECISION = 'estimatecalculatordecision',
  ESTIMATE_CALCULATOR_PAGE = 'estimatecalculatorpage',

  // Application
  APPLICATION = 'application',

  // Financial Counseling
  FINANCIAL_COUNSELING_DEFAULT = 'financialcounselingdefault',
  FINANCIAL_COUNSELING_SELECTION = 'financialcounselingselection',
  FINANCIAL_COUNSELING_COMPLETE = 'financialcounselingcomplete',

  // Closing disclosure
  CLOSING_DISCLOSURE_DEFAULT = 'closingdisclosuredefault',
  CLOSING_DISCLOSURE_OVERVIEW = 'closingdisclosureoverview',
  CLOSING_DISCLOSURE_OFFER = 'closingdisclosureoffer',
  CLOSING_DISCLOSURE_ESTIMATOR = 'closingdisclosureestimator',
  CLOSING_DISCLOSURE_ABOUT_AGREEMENT = 'closingdisclosureabout',
  CLOSING_DISCLOSURE_DOCUMENTS = 'closingdisclosuredocuments',
  CLOSING_DISCLOSURE_DECISION = 'closingdisclosuredecision',

  // HEI prequal flow
  PREQUAL_CONFIRM_NAME = 'prequalconfirmname',
  PREQUAL_CONTACT_INFO = 'prequalcontactinfo',
  PREQUAL_COOP_PROPERTY = 'prequalcoopproperty',
  PREQUAL_CREDIT_SCORE = 'prequalcreditscore',
  PREQUAL_HOME_ADDRESS = 'prequalhomeaddress',
  PREQUAL_HOME_VALUE = 'prequalhomevalue',
  PREQUAL_MORTGAGE_BALANCE = 'prequalmortgagebalance',
  PREQUAL_INELIGIBLE = 'prequalineligible',
  PREQUAL_SSN = 'prequalssn',
  PREQUAL_WAITLIST = 'prequalwaitlist',
  PREQUAL_WAITLIST_SIGNUP = 'prequalwaitlistsignup',

  // Post-funding
  POST_FUNDING_CALCULATOR = 'postfundingcalculator',

  // HEI Product Quiz
  HEI_EDUCATION = 'heieducation',
}

// TODO: Replace `pages` with `Page` in all other files.
export { Page as pages };

const productValues = Object.values(Products).join('|');
const productParam = `:product(${productValues})`;

export type PageRouteMapItem = {
  key: Page;
  path: string;
  exact: true;
  legacyPaths?: Array<string>;
};
export type PageRouteMap = Partial<Record<Page, PageRouteMapItem>>;

export const pageRouteMap: PageRouteMap = {
  // general schedule complete page

  [Page.SCHEDULE_COMPLETE]: {
    key: Page.SCHEDULE_COMPLETE,
    path: '/schedule-complete',
    exact: true,
  },

  // Error/whoops page
  [Page.ERROR_GENERAL]: {
    key: Page.ERROR_GENERAL,
    path: '/whoops/:errorType?',
    exact: true,
  },

  // Dashboard
  [Page.DASHBOARD]: {
    key: Page.DASHBOARD,
    path: '/dashboard',
    exact: true,
  },

  [Page.DASHBOARD_ESTIMATOR]: {
    key: Page.DASHBOARD_ESTIMATOR,
    path: '/dashboard/estimator',
    exact: true,
  },

  [Page.DASHBOARD_LOGIN]: {
    key: Page.DASHBOARD_LOGIN,
    path: '/dashboard/login',
    exact: true,
    legacyPaths: ['/login'],
  },

  [Page.DASHBOARD_PROCESS_OVERVIEW]: {
    key: Page.DASHBOARD_PROCESS_OVERVIEW,
    path: '/dashboard/process-overview',
    exact: true,
  },

  [Page.DASHBOARD_TASKS_LIST]: {
    key: Page.DASHBOARD_TASKS_LIST,
    path: '/dashboard/tasks',
    exact: true,
  },

  [Page.DASHBOARD_TASK]: {
    key: Page.DASHBOARD_TASK,
    path: '/dashboard/tasks/:taskId',
    exact: true,
    legacyPaths: ['/dashboard/tasks/followup/:taskId'],
  },

  [Page.HEI_EDUCATION]: {
    key: Page.HEI_EDUCATION,
    path: '/hei/education',
    exact: true,
  },

  // Follow ups (Renamed to estimate-calculator)
  [Page.FOLLOW_UP_DEFAULT]: {
    key: Page.FOLLOW_UP_DEFAULT,
    path: '/follow-up/:followUpId',
    exact: true,
  },

  [Page.FOLLOW_UP_PAGE]: {
    key: Page.FOLLOW_UP_PAGE,
    path: '/follow-up/:followUpId/:page',
    exact: true,
  },

  // Estimate calculator
  [Page.ESTIMATE_CALCULATOR_DEFAULT]: {
    key: Page.ESTIMATE_CALCULATOR_DEFAULT,
    path: '/estimate-calculator/:followUpId',
    exact: true,
  },

  [Page.ESTIMATE_CALCULATOR_PAGE]: {
    key: Page.ESTIMATE_CALCULATOR_PAGE,
    path: '/estimate-calculator/:followUpId/:page',
    exact: true,
  },

  [Page.ESTIMATE_CALCULATOR_OFFER]: {
    key: Page.ESTIMATE_CALCULATOR_OFFER,
    path: '/estimate-calculator/:followUpId/offer',
    exact: true,
  },

  [Page.ESTIMATE_CALCULATOR_ESTIMATOR]: {
    key: Page.ESTIMATE_CALCULATOR_ESTIMATOR,
    path: '/estimate-calculator/:followUpId/estimator',
    exact: true,
  },

  [Page.ESTIMATE_CALCULATOR_DECISION]: {
    key: Page.ESTIMATE_CALCULATOR_DECISION,
    path: '/estimate-calculator/:followUpId/decision',
    exact: true,
  },

  // HEI
  [Page.HEI_SCHEDULE_COMPLETE]: {
    key: Page.HEI_OFFER,
    path: '/estimate/:estimateKey/schedule-complete',
    exact: true,
    legacyPaths: [
      '/hei/offer/:applicationKey/estimates/:estimateKey/schedule-complete',
      '/estimates/:estimateKey/schedule-complete',
    ],
  },

  [Page.HEI_OFFER]: {
    key: Page.HEI_OFFER,
    path: '/estimate/:estimateKey/offer',
    exact: true,
    legacyPaths: [
      '/hei/offer/:applicationKey/estimates/:estimateKey/offer',
      '/estimates/:estimateKey/offer',
    ],
  },

  [Page.HEI_ESTIMATOR]: {
    key: Page.HEI_ESTIMATOR,
    path: '/estimate/:estimateKey/estimator',
    exact: true,
    legacyPaths: [
      '/hei/offer/:applicationKey/estimates/:estimateKey/estimator',
      '/estimates/:estimateKey/estimator',
    ],
  },

  [Page.HEI_DEFAULT]: {
    key: Page.HEI_DEFAULT,
    path: '/estimate/:estimateKey/:page?',
    exact: true,
    legacyPaths: [
      '/hei/offer/:applicationKey/estimates/:estimateKey/:page?',
      '/estimates/:estimateKey/:page?',
    ],
  },

  [Page.HEI_ABOUT]: {
    key: Page.HEI_DEFAULT,
    path: '/estimate/:estimateKey/about',
    exact: true,
    legacyPaths: [
      '/hei/offer/:applicationKey/estimates/:estimateKey/about',
      '/estimates/:estimateKey/about',
    ],
  },

  [Page.HEI_PRICING]: {
    key: Page.HEI_DEFAULT,
    path: '/estimate/:estimateKey/pricing',
    exact: true,
    legacyPaths: [
      '/hei/offer/:applicationKey/estimates/:estimateKey/pricing',
      '/estimates/:estimateKey/pricing',
    ],
  },

  [Page.HEI_APPLICATION]: {
    key: Page.HEI_APPLICATION,
    path: '/estimate/:estimateKey/application/:page?',
    exact: true,
    legacyPaths: [
      '/hei/offer/:applicationKey/estimates/:estimateKey/application',
      '/estimates/:estimateKey/application',
    ],
  },

  // OLD HEI estimate catch-all route. Remove after transition period of UW application to CP application
  [Page.DEPRECIATED_ESTIMATE_ROUTE]: {
    key: Page.DEPRECIATED_ESTIMATE_ROUTE,
    path: '/estimates/:estimateKey/:page',
    exact: true,
  },

  // Prequal
  [Page.PREQUAL_DEFAULT]: {
    key: Page.PREQUAL_DEFAULT,
    path: '/',
    exact: true,
  },

  [Page.PREQUAL_OFFER_CODE]: {
    key: Page.PREQUAL_OFFER_CODE,
    path: '/offercode',
    exact: true,
  },

  [Page.PREQUAL_OFFER_CODE_DIRECT]: {
    key: Page.PREQUAL_OFFER_CODE_DIRECT,
    path: '/offercode/:offerCode',
    exact: true,
  },

  [Page.CLOSING_DISCLOSURE_DEFAULT]: {
    key: Page.CLOSING_DISCLOSURE_DEFAULT,
    path: '/closing-disclosure/:followUpId',
    exact: true,
  },

  [Page.CLOSING_DISCLOSURE_OVERVIEW]: {
    key: Page.CLOSING_DISCLOSURE_OVERVIEW,
    path: '/closing-disclosure/:followUpId/overview',
    exact: true,
  },

  [Page.CLOSING_DISCLOSURE_OFFER]: {
    key: Page.CLOSING_DISCLOSURE_OFFER,
    path: '/closing-disclosure/:followUpId/offer',
    exact: true,
  },

  [Page.CLOSING_DISCLOSURE_ESTIMATOR]: {
    key: Page.CLOSING_DISCLOSURE_ESTIMATOR,
    path: '/closing-disclosure/:followUpId/estimator',
    exact: true,
  },

  [Page.CLOSING_DISCLOSURE_ABOUT_AGREEMENT]: {
    key: Page.CLOSING_DISCLOSURE_ABOUT_AGREEMENT,
    path: '/closing-disclosure/:followUpId/about',
    exact: true,
  },

  [Page.CLOSING_DISCLOSURE_DOCUMENTS]: {
    key: Page.CLOSING_DISCLOSURE_DOCUMENTS,
    path: '/closing-disclosure/:followUpId/documents',
    exact: true,
  },

  [Page.CLOSING_DISCLOSURE_DECISION]: {
    key: Page.CLOSING_DISCLOSURE_DECISION,
    path: '/closing-disclosure/:followUpId/decision',
    exact: true,
  },

  // Financial Counseling
  [Page.FINANCIAL_COUNSELING_DEFAULT]: {
    key: Page.FINANCIAL_COUNSELING_DEFAULT,
    path: '/financial-counseling/:followUpId',
    exact: true,
    legacyPaths: ['/acknowledgment-of-heir/:followUpId'],
  },

  [Page.FINANCIAL_COUNSELING_SELECTION]: {
    key: Page.FINANCIAL_COUNSELING_SELECTION,
    path: '/financial-counseling/:followUpId/selection',
    exact: true,
    legacyPaths: ['/acknowledgment-of-heir/:followUpId/selection'],
  },

  [Page.FINANCIAL_COUNSELING_COMPLETE]: {
    key: Page.FINANCIAL_COUNSELING_COMPLETE,
    path: '/financial-counseling/:followUpId/counseling',
    exact: true,
    legacyPaths: ['/acknowledgment-of-heir/:followUpId/counseling'],
  },

  // Prequal flow
  [Page.PREQUAL_START]: {
    key: Page.PREQUAL_START,
    // react-router uses path-to-regex, this means a param that matches one of our `Products` values
    path: `/${productParam}`,
    exact: true,
  },

  [Page.PREQUAL_SCHEDULE]: {
    key: Page.PREQUAL_SCHEDULE,
    path: `/${productParam}/schedule-call/:calendar?`,
    exact: true,
  },

  [Page.PREQUAL_SSN]: {
    key: Page.PREQUAL_SSN,
    path: `/${productParam}/ssn`,
    exact: true,
  },

  [Page.PREQUAL_COOP_PROPERTY]: {
    key: Page.PREQUAL_COOP_PROPERTY,
    path: `/${productParam}/co-op`,
    exact: true,
  },

  [Page.PREQUAL_CREDIT_SCORE]: {
    key: Page.PREQUAL_CREDIT_SCORE,
    path: `/${productParam}/credit-score`,
    exact: true,
  },

  [Page.PREQUAL_CONFIRM_NAME]: {
    key: Page.PREQUAL_CONFIRM_NAME,
    path: `/${productParam}/confirm-name`,
    exact: true,
  },

  [Page.PREQUAL_HOME_ADDRESS]: {
    key: Page.PREQUAL_HOME_ADDRESS,
    path: `/${productParam}/homeaddress`,
    exact: true,
  },

  [Page.PREQUAL_UNIT_NUMBER]: {
    key: Page.PREQUAL_UNIT_NUMBER,
    path: `/${productParam}/unit-number`,
    exact: true,
  },

  [Page.PREQUAL_HOME_VALUE]: {
    key: Page.PREQUAL_HOME_VALUE,
    path: `/${productParam}/home-value`,
    exact: true,
  },

  [Page.PREQUAL_MORTGAGE_BALANCE]: {
    key: Page.PREQUAL_MORTGAGE_BALANCE,
    path: `/${productParam}/mortgage-balance`,
    exact: true,
  },

  [Page.PREQUAL_CONTACT_INFO]: {
    key: Page.PREQUAL_CONTACT_INFO,
    path: `/${productParam}/contact-info`,
    exact: true,
  },

  [Page.PREQUAL_WAITLIST]: {
    key: Page.PREQUAL_WAITLIST,
    path: `/${productParam}/waitlist`,
    exact: true,
  },

  [Page.PREQUAL_WAITLIST_SIGNUP]: {
    key: Page.PREQUAL_WAITLIST_SIGNUP,
    path: `/${productParam}/waitlist-signup`,
    exact: true,
  },

  [Page.PREQUAL_INELIGIBLE]: {
    key: Page.PREQUAL_INELIGIBLE,
    path: `/${productParam}/ineligible`,
    exact: true,
  },

  [Page.FOLLOW_UP_SCHEDULE_COMPLETE]: {
    key: Page.FOLLOW_UP_SCHEDULE_COMPLETE,
    path: `/${productParam}/follow-up/:followUpId/schedule-complete`,
    exact: true,
  },

  [Page.POST_FUNDING_CALCULATOR]: {
    key: Page.POST_FUNDING_CALCULATOR,
    path: `/payoff-estimator`,
    exact: true,
  },
};

export type Params = Record<string, undefined | string>;

// NOTE: this is an expensive search. Use sparingly
const getRouteParamsByMatchPath = (pathname: string) => {
  let found: undefined | Params;
  Object.values(pageRouteMap).forEach((item) => {
    const page = matchPath(item, pathname);
    if (page) {
      found = page.params;
    }
  });
  return found;
};

interface ContainerProps {
  match: PathMatch<string>;
  location: Location;
}

// Note: containerProps in all helper params should contain withRouter props
export const getParam = (containerProps: ContainerProps, param: string) => {
  let params;
  if (containerProps?.match?.params && Object.keys(containerProps.match.params).length) {
    params = containerProps.match.params;
  } else {
    params = getRouteParamsByMatchPath(containerProps?.location?.pathname);
  }

  if (params) {
    return params[param];
  }

  return null;
};

export const getEstimateKey = (containerProps: ContainerProps) =>
  getParam(containerProps, 'estimateKey');

export const getPageRouteItemByPage = (page: Page) => pageRouteMap[page];

export const getPathFromPage = (page: Page) => {
  const route = getPageRouteItemByPage(page);
  return route ? route.path : null;
};

const getQueryParameters = (locationSearchString: string) =>
  querystring.parse(locationSearchString?.slice(1));

type GetUpdatedQueryStringOptions = {
  keepExisting?: boolean;
  removeEmptyValues?: boolean;
};

const getUpdatedQueryString = (params: Params, options: GetUpdatedQueryStringOptions = {}) => {
  // start fresh if chosen as an option, otherwise use the existing parameters
  let queryObject = options.keepExisting ? getQueryParameters(window.location.search) : {};

  // merge new params, overwriting any key collisions
  Object.entries(params).forEach(([key, value]) => {
    queryObject[key] = value && typeof value === 'object' ? JSON.stringify(value) : value;
  });

  // this could be changed to allow a more general filter function, for now it will just be empty key/value pairs
  if (options.removeEmptyValues) {
    queryObject = omitBy(queryObject, isNil);
  }

  return querystring.stringify(queryObject);
};

export const generateUrl = (
  routePath: string,
  routeParams: Params = {},
  queryParams: Params = {},
  options: GetUpdatedQueryStringOptions = {}
) => {
  const cleanedRouteParams = Object.keys(routeParams)
    .filter((key) => {
      return routeParams[key] !== undefined;
    })
    .reduce((acc: Params, key) => {
      // eslint-disable-next-line no-param-reassign
      acc[key] = encodeURIComponent(routeParams[key] as TSFixMe);
      return acc;
    }, {});

  const url = decodeURIComponent(
    generatePath(routePath.replace(/\([^)]*\)/g, ''), cleanedRouteParams)
  );

  const defaults: GetUpdatedQueryStringOptions = {
    keepExisting: false,
    removeEmptyValues: true,
  };

  // override defaults with passed in options
  const queryOptions = { ...defaults, ...options };

  const queryString = getUpdatedQueryString(queryParams, queryOptions);
  return `${url}${queryString.length > 0 ? `?${queryString}` : ''}`;
};

export const generateUrlFromPage = (
  page: Page,
  routeParams?: Params,
  queryParams?: Params,
  options?: GetUpdatedQueryStringOptions
) => generateUrl(nullthrows(getPathFromPage(page)), routeParams, queryParams, options);

export const appendQueryParams = (url: string) => {
  let urlNew = url;
  if (document.location.search) {
    urlNew += document.location.search;
  }

  return urlNew;
};

export const isPointSource = (source: string) => {
  let regex = /([a-zA-Z0-9_-]*\.)?(point\.dev|point\.com|point-com.*.herokuapp.com)\/?/;
  if (process.env.REACT_APP_ENV === 'development') {
    regex = /([a-zA-Z0-9_-]*\.)?(point\.dev|point\.com|point-com.*.herokuapp.com|localhost)\/?/;
  }
  return regex.test(source);
};

export const isComingFromPoint = () => isPointSource(document.referrer);

export const setRedirectCookie = () => {
  const contents = new URLSearchParams({
    original_url: window.location.href,
    expires_on: dayjs().add(15, 'minutes').toISOString(),
  }).toString();
  const domain = `.${document.location.host.split('.').slice(-2).join('.')}`;
  const params = [`original_url=${contents}`, 'samesite=strict', 'path=/'];

  if (window.location.hostname !== 'localhost') {
    params.push(`domain=${domain}`);
    params.push('secure');
  }

  document.cookie = params.join('; ');
};

export const getAddressString = (address: Address) =>
  address?.streetAddress
    ? `${address.streetAddress}${address.unit ? ` #${address.unit}` : ''}, ${address.city}, ${
        address.state
      } ${address.zip}`
    : ``;

// TODO: switch to react router dom v6 and this function is no longer necessary
// https://reactrouter.com/en/main/hooks/use-search-params#usesearchparams
export const getURLParam = (key: string, location: Location) => {
  const parsedUrl = location || new URL(window.location.href);
  if (!parsedUrl?.search) {
    return null;
  }

  const params = new URLSearchParams(parsedUrl.search);
  return params.get(key);
};
