import styled from 'styled-components';
import { DirectionAndPlacement } from '@pointdotcom/pds';
import { TwinkleGraphicStyle } from '../TwinkleGraphic/styles';
import { ShieldGraphicProps } from './constants';
import shieldImage from './images/shield.png';

export const ShieldGraphicStyle = styled.div.attrs({
  className: 'ShieldGraphicStyle',
})<ShieldGraphicProps>`
  --shieldSize: 100%;
  --dot-color: white;
  --dot-size: 4px; /* Diameter of each dot */
  --line-size: 120%;
  background-image: url('${shieldImage}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  width: var(--shieldSize);
  padding-bottom: var(--shieldSize);
  position: absolute;
  bottom: 0;
  left: 10%;
  z-index: 1;
  &:before {
    content: '';
    display: block;
    width: 10%;
    height: 10%;
    background: white;
    border-radius: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: var(--line-size); /* Width of the dotted line */
    height: var(--dot-size); /* Height to match dot size */
    background-image: radial-gradient(circle, var(--dot-color) 50%, transparent 0); /* Dot color */
    background-repeat: repeat-x; /* Repeat dots horizontally */
    background-size: calc(var(--dot-size) + var(--dot-size) * 1.3) var(--dot-size); /* Control dot spacing and size */
    top: 50%;
    transform: translateY(-50%);
    ${({ direction }) => {
      const placement = `calc((var(--line-size) * -1) + 50%);`; /* Center the dots */
      return direction === DirectionAndPlacement.Left
        ? `left: ${placement};`
        : `right: ${placement};`;
    }};
  }
  ${TwinkleGraphicStyle} {
    path {
      fill: white;
    }
    &:first-child {
      left: 12%;
      top: 40%;
    }
    &:nth-child(2) {
      left: 20%;
      top: 75%;
      --twinkleSize: 4px;
    }
    &:last-child {
      left: 60%;
      top: 10%;
      --twinkleSize: 10px;
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    --dot-size: 2px;
  }
`;
