import React from 'react';
import {
  Accordion,
  AccordionItem,
  IconName,
  ModalProps,
  Size,
  templatedString,
} from '@pointdotcom/pds';
import { DocumentHelp } from 'services/apiTypes/taskTypes';
import i18n from './i18n';
import * as styles from './styles';

const DocumentHelpModal = ({
  docName,
  help,
  ...modalProps
}: ModalProps & { docName: string; help?: DocumentHelp }) => {
  // TODO: should there be a default "sorry" mode if we don't have any data for this doc type? or will followup page hide the button?
  if (!help?.what && !help?.where && !help?.exampleUrl) {
    return null;
  }

  return (
    <styles.DocumentHelpModalStyle
      shadeBg
      {...modalProps}
      headerProps={{ tabIndex: -1 }}
      upperHeaderText={templatedString({
        values: { docName },
        template: i18n.helpWithADocName,
      })}
    >
      <Accordion
        styleSize={Size.Splash}
        isPadded
        isStaticUntilMobile
        iconType={IconName.ChevronUp}
        shouldCompactAlignContent
      >
        {help.what.length && (
          <AccordionItem title={i18n.whatIsIt} isDefaultExpanded>
            {help.what.map((paragraph) => (
              <p key={paragraph}>{paragraph}</p>
            ))}
          </AccordionItem>
        )}

        {help.where && (
          <AccordionItem title={i18n.whereCanIFindIt}>
            {help.where.description.map((paragraph) => (
              <styles.HelpParagraphStyle key={paragraph}>{paragraph}</styles.HelpParagraphStyle>
            ))}
            <styles.HelpListStyle>
              {help.where.sources?.map((point) => (
                <li key={point.source}>
                  <strong>{`${point.source}: `}</strong>
                  {point.instruction}
                </li>
              ))}
            </styles.HelpListStyle>
          </AccordionItem>
        )}

        {help.exampleUrl && (
          <AccordionItem title={i18n.whatDoesItLookLike} hasNoContentPadding>
            <styles.DocExampleStyle>
              <img src={help.exampleUrl} alt={i18n.exampleDocument} />
            </styles.DocExampleStyle>
          </AccordionItem>
        )}
      </Accordion>
    </styles.DocumentHelpModalStyle>
  );
};

export default DocumentHelpModal;
