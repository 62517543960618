import React from 'react';
import * as styles from './styles';

export default function TwinkleGraphic() {
  return (
    <styles.TwinkleGraphicStyle>
      <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.21 11.21">
        <g id="Layer_1-2">
          <path
            d="M11.21,11.21c-3.03-3.03-8.19-3.03-11.21,0C3.03,8.19,3.03,3.03,0,0c3.03,3.03,8.19,3.03,11.21,0-3.03,3.03-3.03,8.19,0,11.21Z"
            fill="#f3d695"
          />
        </g>
      </svg>
    </styles.TwinkleGraphicStyle>
  );
}
