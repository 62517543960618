import i18n from './i18n';

export enum ScenarioType {
  HIGH = 'high',
  MODERATE = 'moderate',
  NONE = 'none',
  DECLINE = 'decline',
  CUSTOM = 'custom',
}

export const scenarioOrder = [
  ScenarioType.NONE,
  ScenarioType.MODERATE,
  ScenarioType.HIGH,
  ScenarioType.DECLINE,
  ScenarioType.CUSTOM,
];

export type Scenario = {
  label: string;
  appreciationPerc?: number;
  percLabelSm: string;
  percLabel: string;
};

const scenarios: Record<ScenarioType, Scenario> = {
  [ScenarioType.HIGH]: {
    label: i18n.highAppreciation,
    appreciationPerc: 5.5,
    percLabelSm: i18n.annualIncrease,
    percLabel: i18n.annualIncreaseIn,
  },
  [ScenarioType.MODERATE]: {
    label: i18n.moderateAppreciation,
    appreciationPerc: 2.5,
    percLabelSm: i18n.annualIncrease,
    percLabel: i18n.annualIncreaseIn,
  },
  [ScenarioType.NONE]: {
    label: i18n.noChange,
    appreciationPerc: 0,
    percLabelSm: i18n.annualIncrease,
    percLabel: i18n.annualIncreaseIn,
  },
  [ScenarioType.DECLINE]: {
    label: i18n.largeDecline,
    appreciationPerc: -35,
    percLabelSm: i18n.decline,
    percLabel: i18n.declineIn,
  },
  [ScenarioType.CUSTOM]: {
    label: i18n.custom,
    percLabelSm: i18n.annualIncrease,
    percLabel: i18n.annualIncreaseIn,
  },
};

export default scenarios;
