export default {
  closeToCashing:
    'You’re very close to cashing out %offerAmount% of your home equity. Please take care of the steps below as soon as you can.',
  closeToCashingClosing:
    'You’re very close to cashing out %offerAmount% of your home equity. There are a few steps for you to take care of today:',
  homeStretchNow: 'It’s the home stretch now, %name%!',
  reviewYourFinalOfferHead: 'Review your final offer.',
  reviewYourFinalOfferTail:
    'Your offer includes the cash you’ll receive, any debts to be paid off, fees, and pricing. Please make sure everything looks accurate.',
  estimateCostsHead: 'Estimate your costs.',
  estimateCostsTail:
    'Use the cost estimator to confirm your understanding of how pricing works, and what your payoff could be given different timelines and home appreciation scenarios.',
  checkAgreementHead: 'Check out “%agreementPageName%”.',
  checkAgreementTail:
    'These are the important things to know about your contract, written in plain English, to help you understand your obligations when you sign with Point.',
  reviewDocumentsHead: 'Review the Point Closing Disclosure.',
  reviewDocumentsTail:
    'This explains the terms of the Option Agreement and the closing costs. You’ll also find copies of the Point homeowner agreements that you’ll be asked to sign during your closing appointment.',
  tellDecisionHead: 'Tell us your decision.',
  tellDecisionTail:
    'To move forward with Point and schedule your closing appointment, you must express your intent to proceed.',
};
