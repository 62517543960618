import React, { useCallback, useRef, useState } from 'react';
import {
  Container,
  DirectionAndPlacement,
  Header,
  LinkButton,
  Modal,
  Size,
  SplashText,
  TemplatedText,
  normalizeToNumber,
  templatedString,
  useIsMobile,
} from '@pointdotcom/pds';
import CapPriceTable from 'components/CapPriceTable';
import HeaderUpperLower from 'components/HeaderUpperLower';
import Table2Col from 'components/Table2Col';
import { useFollowUpFetch } from 'containers/prequal/hooks';
import { dayjs } from 'lib/dayjs';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import { logInfo } from 'lib/logger';
import FollowUpEstimateModel from 'models/FollowUpEstimateModel';
import { HeroContainerStyle } from 'styles/';
import PreviewOfferAmountModal from './PreviewOfferAmountModal';
import i18n from './i18n';
import {
  HeroSectionStyle,
  OfferContentStyle,
  OfferNoticesStyle,
  PreviewOfferAmountHero,
  TableContainerStyle,
  TableHeaderLeftStyle,
  TableHeaderRightStyle,
  TableHeaderStyle,
} from './styles';

const TableHeader = ({ headerUpperText = '', headerLowerText = '', headerRightText = '' }) => (
  <TableHeaderStyle>
    <TableHeaderLeftStyle>
      <HeaderUpperLower capsText={headerUpperText} boldText={headerLowerText} styleAlign="center" />
    </TableHeaderLeftStyle>
    <TableHeaderRightStyle>{headerRightText}</TableHeaderRightStyle>
  </TableHeaderStyle>
);

interface OfferNoticesProps {
  isClosingDisclosure: boolean;
  isBelowMaximumOffer: boolean;
  formattedMaximumOfferAmount: string;
  expirationDate: string;
}

const OfferNotices = ({
  isClosingDisclosure,
  isBelowMaximumOffer,
  formattedMaximumOfferAmount,
  expirationDate,
}: OfferNoticesProps) => {
  let notices = null;

  // only estimate offers show maximum offer, not closing disclosures
  if (isBelowMaximumOffer && !isClosingDisclosure) {
    notices = (
      <>
        <TemplatedText
          values={{
            maximumPossibleOptionPayment: <b>{`up to ${formattedMaximumOfferAmount}`}</b>,
            humanizedExpirationDate: dayjs(expirationDate).format('MMMM Do, YYYY'),
          }}
        >
          {i18n.eligibleUpToWithExpiration1}
        </TemplatedText>
        <br />
        <br />
        {i18n.eligibleUpToWithExpiration2}
      </>
    );
  }
  // both of them always show at least expiry
  else {
    notices = (
      <TemplatedText
        values={{ humanizedExpirationDate: dayjs(expirationDate).format('MMMM Do, YYYY') }}
      >
        {i18n.termsWillExpire}
      </TemplatedText>
    );
  }

  if (!notices) {
    return null;
  }

  return <OfferNoticesStyle>{notices}</OfferNoticesStyle>;
};

interface OfferPageProps {
  followUpFetchResults: ReturnType<typeof useFollowUpFetch>;
}

const HeroContents = ({
  followUp,
  followUpId,
}: Pick<ReturnType<typeof useFollowUpFetch>, 'followUp' | 'followUpId'>) => {
  const { isMobile } = useIsMobile();
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const estimate = followUp?.getEstimate();

  const maxAmount = estimate?.getMaxOptionAmount();
  const offerAmount = estimate?.getPricing()?.getOptionInvestmentAmount();
  const offerAmountFormatted = estimate?.getPricing()?.getFormattedOptionInvestmentAmount();
  const isClosingDisclosure = followUp?.isClosingDisclosure();
  const selfServeOfferAmountFlagEnabled =
    useFeatureFlag(FeatureFlag.SelfServeOfferAmount) === 'test';
  const STEP_SIZE = 10000;
  let shouldRunExperiment = offerAmount !== maxAmount && selfServeOfferAmountFlagEnabled;
  const steppedPricingOptions = estimate?.steppedPricing || [];

  if (selfServeOfferAmountFlagEnabled && steppedPricingOptions?.length === 0) {
    logInfo({
      eventType: 'SelfServeOfferAmountFeature',
      detail: {
        followUpId,
        message: `Experiment not presented to user: /pricing?stepSize=${STEP_SIZE} didnt return any results within ${offerAmount} and ${maxAmount}`,
        steppedPricingOptions,
        offerAmount,
        maxAmount,
      },
    });
    shouldRunExperiment = false;
  }

  const handlePreviewClick = useCallback(() => {
    setPreviewModalOpen(true);
  }, [setPreviewModalOpen]);

  const handleModalClose = useCallback(() => {
    setPreviewModalOpen(false);
  }, [setPreviewModalOpen]);

  // test
  if (shouldRunExperiment) {
    return (
      <>
        <Container mobileCollapse>
          <PreviewOfferAmountHero>
            <div>
              <Header
                sideLines
                styleAlign={DirectionAndPlacement.Center}
                styleSize={Size.Large}
                noMargin={isMobile}
              >
                {i18n.yourOffer}
              </Header>
            </div>
            <div>
              <Header styleSize={Size.Massive} noMargin styleAlign={DirectionAndPlacement.Center}>
                {offerAmountFormatted}
              </Header>
              <aside>
                <TemplatedText
                  values={{
                    humanizedExpirationDate: dayjs(estimate?.expires).format('MMMM Do, YYYY'),
                  }}
                >
                  {i18n.thisOfferWillExpire}
                </TemplatedText>
              </aside>
            </div>
            <div>
              <SplashText>
                <TemplatedText
                  values={{
                    maximumPossibleOptionPayment: estimate?.getFormattedMaxPossibleOptionPayment(),
                  }}
                >
                  {i18n.youreEligibleFor}
                </TemplatedText>
                <br />
                <LinkButton onClick={handlePreviewClick}>{i18n.previewADifferent}</LinkButton>
              </SplashText>
            </div>
          </PreviewOfferAmountHero>
        </Container>
        <PreviewOfferAmountModal
          followUp={followUp}
          isOpen={previewModalOpen}
          onModalClose={handleModalClose}
          defaultValue={offerAmountFormatted}
          steppedPricingOptions={steppedPricingOptions}
          followUpId={followUpId}
        />
      </>
    );
  }

  // Control
  return (
    <Container>
      <HeroSectionStyle>
        <Header styleSize={Size.Splash} styleAlign={DirectionAndPlacement.Center}>
          {i18n.yourOffer}
        </Header>
        <Header styleSize={Size.Massive} styleAlign={DirectionAndPlacement.Center} sideLines>
          {offerAmountFormatted}
        </Header>
        <OfferNotices
          isClosingDisclosure={Boolean(isClosingDisclosure)}
          formattedMaximumOfferAmount={estimate?.getFormattedMaxPossibleOptionPayment() || ''}
          expirationDate={estimate?.expires}
          isBelowMaximumOffer={Boolean(estimate?.isBelowMaximumOffer())}
        />
      </HeroSectionStyle>
    </Container>
  );
};

const OfferPage = ({ followUpFetchResults }: OfferPageProps) => {
  const { followUp, followUpId } = followUpFetchResults;
  const estimate = followUp?.getEstimate() as FollowUpEstimateModel;
  const modalRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);

  if (!estimate) {
    return null;
  }

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const debtPayoffLabels = estimate.getPricing()?.closingCosts?.getDebtPayoffLabels();
  const debtPayoffValues = estimate.getPricing()?.closingCosts?.getFormattedDebtPayoffValues();

  const totalFees = estimate.getPricing()?.closingCosts?.getTotalFees();
  const feeList = estimate.getPricing()?.closingCosts?.getFeeList() || [];

  let debtPayoffText = i18n.wellWork;
  const hasDetailedDebtPayoff =
    normalizeToNumber(estimate?.pricing?.closingCosts?.totalPayoffAmount) > 0 &&
    normalizeToNumber(debtPayoffLabels?.length) > 0 &&
    normalizeToNumber(debtPayoffValues?.length) > 0;
  if (hasDetailedDebtPayoff) {
    debtPayoffText = i18n.wellWrite;
  }

  const feeLabels = feeList.map((feeItem) => {
    const labelModMap: { [key: string]: string } = {
      origination_fee: templatedString({
        template: estimate.isDefaultProcessingFee()
          ? i18n.processing_fee
          : i18n.processing_fee_no_rate,
        values: { fee_rate: estimate.getPricing()?.getFormattedFeeRate() },
      }),
    };
    const { label } = feeItem;
    return labelModMap[feeItem.key] ? labelModMap[feeItem.key] : label;
  });

  const feeValues = estimate.getPricing()?.closingCosts?.getFormattedFeeList();

  return (
    <>
      <HeroContainerStyle>
        <HeroContents followUp={followUp} followUpId={followUpId} />
      </HeroContainerStyle>
      <OfferContentStyle>
        <Container>
          {parseInt(estimate.cashToClose, 10) > 0 && (
            <TableContainerStyle data-testid="cashTable">
              <TableHeader
                headerUpperText={i18n.cashToYou}
                headerLowerText={estimate.getFormattedCashToClose()}
                headerRightText={i18n.thisMoney}
              />
            </TableContainerStyle>
          )}

          {normalizeToNumber(estimate?.pricing?.closingCosts?.totalPayoffAmount) > 0 && (
            <TableContainerStyle data-testid="debtTable">
              <TableHeader
                headerUpperText={i18n.debtPayoff}
                headerLowerText={estimate.getFormattedTotalPayoffAmount()}
                headerRightText={debtPayoffText}
              />
              {hasDetailedDebtPayoff && debtPayoffLabels && debtPayoffValues && (
                <Table2Col labels={debtPayoffLabels} values={debtPayoffValues} />
              )}
            </TableContainerStyle>
          )}

          {normalizeToNumber(totalFees) > 0 && feeValues && (
            <TableContainerStyle data-testid="feesTable">
              <TableHeader
                headerUpperText={i18n.fees}
                headerLowerText={estimate.getPricing()?.closingCosts?.getFormattedTotalFees()}
                headerRightText={i18n.thisIncludes}
              />
              <Table2Col labels={feeLabels} values={feeValues} />
            </TableContainerStyle>
          )}

          <TableContainerStyle last data-testid="pricingTable">
            <TableHeader headerUpperText={i18n.pricing} headerRightText={i18n.thisIsWhat} />
            <Table2Col
              labels={[i18n.percOf, i18n.startingValue, i18n.homeownerProtectionCap]}
              values={[
                estimate.getPricing()?.getFormattedOptionPercentage() || '',
                estimate.getPricing()?.getFormattedRiskAdjustedHomeValue() || '',
                <LinkButton key="link" onClick={handleModalOpen}>
                  {i18n.seeMyCap}
                </LinkButton>,
              ]}
            />
          </TableContainerStyle>
        </Container>
      </OfferContentStyle>
      <Modal
        isOpen={modalOpen}
        onModalClose={handleModalClose}
        ref={modalRef}
        headerMaxWidth="390px"
      >
        <CapPriceTable estimate={estimate} onModalClose={handleModalClose} numRowsVisible={3} />
      </Modal>
    </>
  );
};

export { OfferPage };
