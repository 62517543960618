import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { Expand } from 'types';

export interface QuizAnswer {
  questionId: number;
  answerId: number;
}

export interface ProductQuizState {
  answers: Array<QuizAnswer>;
  currentQuestionIndex: number;
  quizIsOpen: boolean;
  videoIsOpen: boolean;
  videoModalFromQuiz: boolean;
  videoReferenceTime: number;
}

export const initialState: ProductQuizState = {
  answers: [],
  quizIsOpen: false,
  videoIsOpen: false,
  videoModalFromQuiz: true,
  currentQuestionIndex: 0,
  videoReferenceTime: 0,
};

const productQuizSlice = createSlice({
  name: 'productQuiz',
  initialState,
  reducers: {
    prevQuestion: (state) => {
      state.currentQuestionIndex = Math.max(0, state.currentQuestionIndex - 1);
    },
    nextQuestion: (state, { payload }: { payload: number }) => {
      state.currentQuestionIndex = Math.min(state.currentQuestionIndex + 1, payload - 1);
    },
    openQuiz: (state) => {
      state.quizIsOpen = true;
    },
    closeQuiz: (state) => {
      state.currentQuestionIndex = 0;
      state.quizIsOpen = false;
    },
    addQuizAnswer: (state, { payload }: { payload: Expand<QuizAnswer> }) => {
      // swap the question if it exists, and preserve order
      const index = state.answers.findIndex((answer) => answer.questionId === payload.questionId);
      if (index > -1) {
        state.answers = [
          ...state.answers.slice(0, index),
          payload,
          ...state.answers.slice(index + 1),
        ];
      } else {
        // otherwise push to answers
        state.answers.push(payload);
      }
    },
    resetQuizAnswers: (state) => {
      state.answers = [];
    },
    openVideo: (state, { payload }: { payload: number }) => {
      state.videoIsOpen = true;
      state.videoModalFromQuiz = false;
      state.videoReferenceTime = payload;
    },
    openVideoFromQuiz: (state, { payload }: { payload: number }) => {
      state.videoIsOpen = true;
      state.videoModalFromQuiz = true;
      state.quizIsOpen = false;
      state.videoReferenceTime = payload;
    },
    closeVideo: (state) => {
      state.videoIsOpen = false;
    },
    backToQuizFromVideo: (state) => {
      state.videoIsOpen = false;
      state.quizIsOpen = true;
    },
  },
});

export const getQuizIsOpen = ({ productQuiz: productQuizStore }: RootState) => {
  return productQuizStore.quizIsOpen;
};

export const getCurrentQuestionIndex = ({ productQuiz: productQuizStore }: RootState) => {
  return productQuizStore.currentQuestionIndex;
};

export const getVideoReferencePosition = ({ productQuiz: productQuizStore }: RootState) => {
  return productQuizStore.videoReferenceTime;
};

export const getAnswers = ({ productQuiz: productQuizStore }: RootState) => {
  return productQuizStore.answers;
};

export const getVideoIsOpen = ({ productQuiz: productQuizStore }: RootState) => {
  return productQuizStore.videoIsOpen;
};

export const inVideoFromQuiz = ({ productQuiz: productQuizStore }: RootState) => {
  return productQuizStore.videoModalFromQuiz;
};

export const getAnswerIdByQuestionId =
  (questionId: number) =>
  ({ productQuiz: productQuizStore }: RootState) => {
    return productQuizStore.answers.find((answer) => answer.questionId === questionId)?.answerId;
  };

export const {
  addQuizAnswer,
  resetQuizAnswers,
  openQuiz,
  closeQuiz,
  nextQuestion,
  prevQuestion,
  openVideo,
  closeVideo,
  backToQuizFromVideo,
  openVideoFromQuiz,
} = productQuizSlice.actions;

export default productQuizSlice.reducer;
