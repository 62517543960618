import React, { RefObject } from 'react';
import { Button, Container, Header, Modal, Size } from '@pointdotcom/pds';
import { useHomeownerApplication } from 'containers/hooks/useHomeownerApplication';
import FormTemplate from '../ApplicationForm/FormComponents/FormTemplate';
import {
  FormStructure,
  GetBaseFormFieldPropsFunc,
  GetFieldValueByPathFunc,
} from '../ApplicationForm/constants';
import * as styles from '../ApplicationForm/styles';
import pageFooterI18n from '../ApplicationPageFooter/i18n';
import i18n from './i18n';

const CorrectionsModal = ({
  correctionsTemplate,
  onModalClose,
  isOpen,
  getBaseFormFieldProps,
  getFieldValueByPath,
  onFormSubmit,
  strongWarning = false,
  useAltHeaderText = false,
  _ref,
}: {
  correctionsTemplate: FormStructure;
  onModalClose: () => void;
  isOpen: boolean;
  getBaseFormFieldProps: GetBaseFormFieldPropsFunc;
  getFieldValueByPath: GetFieldValueByPathFunc;
  onFormSubmit: () => void;
  strongWarning: boolean;
  useAltHeaderText: boolean;
  _ref: RefObject<HTMLDivElement>;
}) => {
  const { submitApplicationState } = useHomeownerApplication();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!submitApplicationState.isLoading) {
      onFormSubmit();
    }
  };

  return (
    <Modal
      ref={_ref}
      isOpen={isOpen}
      padContainer={false}
      onModalClose={onModalClose}
      width="700px"
      shadeBg
      focusFirstElement={false}
      bgClickToClose={false}
    >
      <styles.CorrectionsModalStyle strongWarning={strongWarning}>
        <Container>
          <Header styleSize={Size.Medium} maxWidth="400px" noMargin>
            {useAltHeaderText ? i18n.pleaseCorrect : i18n.looksLike}
          </Header>
        </Container>
        <styles.FormStyle onSubmit={handleSubmit}>
          <FormTemplate
            correcting
            template={correctionsTemplate}
            getBaseFormFieldProps={getBaseFormFieldProps}
            getFieldValueByPath={getFieldValueByPath}
          />
          <footer>
            <Container>
              <Button type="submit" loading={submitApplicationState.isLoading}>
                {pageFooterI18n.submit}
              </Button>
            </Container>
          </footer>
        </styles.FormStyle>
      </styles.CorrectionsModalStyle>
    </Modal>
  );
};

export default CorrectionsModal;
