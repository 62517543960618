import styled, { css } from 'styled-components';

export const FileUploadAreaStyle = styled.div.attrs({ className: 'FileUploadAreaStyle' })<{
  isDraggingOver: boolean;
  compact: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 2px;
  --border-width: 1px;
  border: var(--border-width) dashed ${({ theme }) => theme.Color.Gray4};
  background: ${({ theme }) => theme.Color.White};
  gap: 2rem;
  padding: calc(2rem - var(--border-width)) calc(1.125rem - var(--border-width));
  min-height: 15rem;
  cursor: pointer;

  ${({ theme, isDraggingOver }) =>
    isDraggingOver
      ? css`
          --border-width: 4px;
          border-color: ${theme.Color.PointBlueHighlight};
          * {
            pointer-events: none;
          }
        `
      : ''}

  ${({ compact, theme }) =>
    compact &&
    css`
      @media (min-width: ${theme.responsive.largestMobileScreen}) {
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
        padding: calc(2.5rem - var(--border-width)) calc(2rem - var(--border-width));
        min-height: auto;
      }
    `}
`;

export const FileUploadIconAndTextStyle = styled.div.attrs({
  className: 'FileUploadIconAndTextStyle',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
`;
