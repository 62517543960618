import React from 'react';
import { Container, DirectionAndPlacement, Header, Size, TemplatedText } from '@pointdotcom/pds';
import navI18n from 'components/MainHeader/i18n';
import { EstimatorPageInnerContentStyle } from 'containers/EstimatorPage/styles';
import { useFollowUpFetch } from 'containers/prequal/hooks';
import { BoldCopyStyle, HeroContainerStyle, SplashCopySerifStyle } from 'styles/';
import ClosingDisclosurePage from '..';
import i18n from './i18n';
import {
  AboutContentStyle,
  AgreementHeroLeftStyle,
  AgreementHeroRightStyle,
  LaptopImageMobileStyle,
  LaptopImageStyle,
  ListItemStyle,
  ListStyle,
} from './styles';

const ClosingDisclosureAboutAgreementPage = (props) => {
  const { product } = props;
  const followUpFetchResults = useFollowUpFetch({ product });
  const { followUp } = followUpFetchResults;
  const pricing = followUp?.getEstimate()?.getPricing();
  if (!followUp) {
    return null;
  }

  const listItems = [
    i18n.payingMortgage,
    i18n.additionalDebt,
    i18n.noLoans,
    i18n.notBuying,
    i18n.readyToRepay,
    i18n.repaymentAmount,
    i18n.valueIncreases,
    i18n.mustRepay,
    i18n.repayBySelling,
    i18n.lendersUnwilling,
  ];

  const bold = (str) => <BoldCopyStyle>{str}</BoldCopyStyle>;
  const templateValues = {
    optionPercentage: pricing?.getFormattedOptionPercentage(),
    capPercentage: pricing?.getFormattedCapPercentage(),
    riskAdjPerc: pricing?.getFormattedRiskAdjustment(),
    riskAdjDollarAmt: pricing?.getFormattedRiskAdjustmentDollarAmount(),
    riskAdjHomeValue: pricing?.getFormattedRiskAdjustedHomeValue(),
    repaymentTerm: followUp?.getRepaymentTerm(),
    not: bold,
    lesser: bold,
    greater: bold,
  };

  return (
    <ClosingDisclosurePage
      followUpFetchResults={followUpFetchResults}
      pageName={navI18n.aboutYourPoint}
    >
      <HeroContainerStyle styleSize={Size.Small}>
        <Container>
          <EstimatorPageInnerContentStyle>
            <AgreementHeroLeftStyle styleAlign={DirectionAndPlacement.Left}>
              <Header styleSize={Size.Splash} styleAlignMobile={DirectionAndPlacement.Center}>
                {navI18n.aboutYourPoint}
              </Header>
              <LaptopImageMobileStyle />
            </AgreementHeroLeftStyle>
            <AgreementHeroRightStyle>
              <SplashCopySerifStyle>
                <p>{i18n.belowItems}</p>
              </SplashCopySerifStyle>
            </AgreementHeroRightStyle>
          </EstimatorPageInnerContentStyle>
        </Container>
      </HeroContainerStyle>
      <Container>
        <AboutContentStyle>
          <ListStyle>
            {listItems.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItemStyle key={index}>
                <TemplatedText values={templateValues}>{item}</TemplatedText>
              </ListItemStyle>
            ))}
          </ListStyle>
          <LaptopImageStyle />
        </AboutContentStyle>
      </Container>
    </ClosingDisclosurePage>
  );
};

export default ClosingDisclosureAboutAgreementPage;
