import styled from 'styled-components';
import { ButtonStyles, Container } from '@pointdotcom/pds';

export const FloatingContainerStyle = styled(Container).attrs({
  className: 'FloatingContainerStyle',
})`
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    a {
      display: none;
    }
    ${ButtonStyles.ButtonContainerStyle} {
      flex: 1;
    }
  }
`;
