import styled from 'styled-components';

const padding = '1.3rem';

export const HeaderParaComboStyle = styled.div.attrs({ className: 'HeaderParaComboStyle' })`
  flex: 0 0 auto;
  box-sizing: border-box;
  padding-bottom: ${padding};
  text-align: ${(props) => props.styleAlign};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'auto')};
  margin: 0 auto;
  &:last-child {
    padding-bottom: 0;
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    flex: auto;
    width: 100%;
    &:nth-child(odd),
    &:nth-child(even) {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export const HeaderParaComboSectionStyle = styled.div.attrs({
  className: 'HeaderParaComboSectionStyle',
})`
  width: 100%;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: ${padding};
  ${HeaderParaComboStyle} {
    width: ${(props) =>
      props.cols ? `calc(${Number.parseFloat(100 / props.cols).toFixed(2)}% - ${padding})` : 0};
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    ${HeaderParaComboStyle} {
      width: 100%;
    }
    flex-flow: column nowrap;
  }
`;
