import styled from 'styled-components';
import { LockKeyLandingPageStyle } from 'containers/prequal/pages/PreQualLandingPage/LockKeyLandingPage/styles';
import { BorderedSectionStyle } from 'styles';

export const HeiTermsFooterSectionStyle = styled(BorderedSectionStyle)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};

  ${LockKeyLandingPageStyle} & {
    border-top: none;
  }

  p:last-child {
    margin: 0;
  }

  a {
    color: inherit;
  }
`;
