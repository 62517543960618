import styled from 'styled-components';
import { ButtonStyles, ContainerStyles, HeaderStyles, mixins } from '@pointdotcom/pds';
import { VideoButtonStyle } from 'components/VideoPlayer/VideoButton/styles';

const pageColor = 'white';

export const QuizIntroductionStyle = styled.div.attrs({ className: 'QuizIntroductionStyle' })`
  background: ${pageColor};
  ${ButtonStyles.ButtonBaseStyle} {
    min-width: 230px;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    button {
      width: 100%;
    }
  }
`;

export const VideoSectionStyle = styled.div.attrs({
  className: 'VideoSectionStyle' as string,
})`
  padding: 4rem 0 0;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${ContainerStyles.ContainerStyle} {
    gap: 5rem;
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    ${ContainerStyles.ContainerStyle} {
      padding-left: 0;
      gap: 6%;
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    gap: 1rem;
    box-shadow: 0px 85px 50px 17px rgba(113, 116, 117, 0.05);
  }
`;

const overviewPadding = '8%';
export const OverviewStyle = styled.div.attrs({ className: 'OverviewStyle' })`
  background: ${pageColor};
  ${HeaderStyles.HeaderStyle} {
    > * {
      font-size: ${({ theme }) => mixins.getRemFromPx(49, theme.globals.baseFontSize.default)};
    }
  }

  p {
    font-size: ${({ theme }) => mixins.getRemFromPx(22, theme.globals.baseFontSize.default)};
  }

  padding: ${overviewPadding};
  box-shadow: 0px 4px 87px 17px rgba(113, 116, 117, 0.11);
  flex-basis: 60%;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    margin: 0;
    margin-top: 3rem;
    padding: 0 3rem;
    box-shadow: none;
    > ${VideoButtonStyle} {
      display: none;
    }
    ${HeaderStyles.HeaderStyle} {
      > * {
        font-size: ${({ theme }) => mixins.getRemFromPx(30, theme.globals.baseFontSize.default)};
        line-height: ${({ theme }) => mixins.getRemFromPx(39, theme.globals.baseFontSize.default)};
      }
    }
    p {
      font-size: ${({ theme }) => mixins.getRemFromPx(16, theme.globals.baseFontSize.default)};
      color: ${({ theme }) => theme.Color.GrayLightestAccessible};
    }
  }
`;

export const OverviewHeaderStyle = styled.div.attrs({ className: 'OverviewHeaderStyle' })`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1.5rem;

  ${VideoButtonStyle} {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    align-items: center;
    gap: 0.5rem;
    ${VideoButtonStyle} {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      position: relative;
    }
  }
`;

export const QuizDetailStyle = styled.div.attrs({ className: 'QuizDetailStyle' })`
  flex-basis: 50%;

  ${HeaderStyles.HeaderStyle} {
    > * {
      font-size: ${({ theme }) => mixins.getRemFromPx(42, theme.globals.baseFontSize.default)};
    }
  }

  p {
    font-family: ${({ theme }) => theme.fonts.serif.name};
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
    margin-bottom: 0;
  }

  button {
    margin-top: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    padding: 3rem 3rem 5rem;
    border-top: ${({ theme }) => `1px solid ${theme.Color.Gray2}`};

    ${HeaderStyles.HeaderStyle} {
      > * {
        font-size: ${({ theme }) => mixins.getRemFromPx(30, theme.globals.baseFontSize.default)};
      }
    }
    button {
      margin: 1rem 0;
    }
  }
`;

export const QuizListStyle = styled.ul.attrs({ className: 'QuizListStyle' })`
  padding: 0 1rem;

  li {
    padding-bottom: 1rem;
  }

  li::marker {
    color: ${({ theme }) => theme.line.color.default};
    font-size: 1.2rem;
    transform: translateX(3rem);
    padding: none;
    margin: none;
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    li {
      color: ${({ theme }) => theme.Color.GrayLightestAccessible};
    }
  }
`;

export const SecondaryContentStyle = styled.div.attrs({
  className: 'SecondaryContentStyle' as string,
})`
  top: -2rem;
  padding: 6rem 0 5rem;
  position: relative;
  z-index: 1;
  border-top: 1px solid ${({ theme }) => theme.line.color.default};
  @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    ${ContainerStyles.ContainerStyle} {
      padding-left: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    padding: 0;
    border: none;
    top: 0;
  }
`;

export const ScheduleSectionStyle = styled.div.attrs({ className: 'ScheduleSectionStyle' })`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 0 ${overviewPadding};
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};

  ${ButtonStyles.ButtonBaseStyle} {
    color: inherit;
  }

  > div {
    flex: 0 0 50%;
    max-width: 400px;
  }

  img {
    align-self: flex-end;
    padding: 0 3rem;
    width: 40%;
    max-width: calc(300px + 10rem);
    border-bottom: 1px solid ${({ theme }) => theme.line.color.default};
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    img {
      padding: 0 8%;
      max-width: calc(300px + 16%);
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    flex-direction: column;
    padding: 3rem 3rem 5rem;
    > div {
      max-width: none;
    }
    img {
      margin-top: 4rem;
      width: 80%;
      align-self: center;
    }

    ${HeaderStyles.HeaderStyle} {
      > * {
        font-size: ${({ theme }) => mixins.getRemFromPx(21, theme.globals.baseFontSize.default)};
      }
    }
  }
`;
