import React from 'react';
import { TemplatedText, templatedString } from '@pointdotcom/pds';
import { HeaderParaCombo, HeaderParaComboSection } from 'components/HeaderParaCombo';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { Products } from 'store/constants';
import i18n, { heiFAQs } from './i18n';
import * as styles from './styles';

const defaultProduct = Products.HEI;

const productFAQMap = {
  [Products.HEI]: heiFAQs,
};

const getFillValues = (product) => {
  const scheduleURL = generateUrlFromPage(pages.PREQUAL_SCHEDULE, { product });

  const fillValues = {
    scheduleACall: () => {
      return (
        <a href={scheduleURL} key="scheduleCalButton">
          {i18n.scheduleACall}
        </a>
      );
    },
  };

  return fillValues;
};

export const getQuestions = (product = defaultProduct) => {
  const faqs = productFAQMap[product];
  const fillValues = getFillValues(product);
  const questions = Object.keys(faqs)
    .filter((faq) => faq.indexOf('q') > -1)
    .map((key) => templatedString({ values: fillValues, template: faqs[key] }));
  return questions;
};

export const getAnswers = (product = defaultProduct) => {
  const faqs = productFAQMap[product];
  const fillValues = getFillValues(product);
  const answers = Object.keys(faqs)
    .filter((faq) => faq.indexOf('a') > -1)
    .map((key) => (
      <TemplatedText key={key} values={fillValues}>
        {faqs[key]}
      </TemplatedText>
    ));
  return answers;
};

const ProductFAQs = ({ product = defaultProduct }) => {
  const questions = getQuestions(product);
  const answers = getAnswers(product);

  return (
    <styles.ProductFAQsStyle>
      <HeaderParaComboSection>
        {questions.map((_, index) => {
          return (
            <HeaderParaCombo key={questions[index]} headerText={questions[index]}>
              {answers[index]}
            </HeaderParaCombo>
          );
        })}
      </HeaderParaComboSection>
    </styles.ProductFAQsStyle>
  );
};

export default ProductFAQs;
