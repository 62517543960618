import styled from 'styled-components';
import { DirectionAndPlacement } from '@pointdotcom/pds';
import { pxToRem } from 'styleHelpers';
import { BorderedSectionStyle } from 'styles';

export const ContentManagementSectionStyle = styled(BorderedSectionStyle).attrs({
  className: 'ContentManagementSectionStyle',
})`
  padding: 1rem;
`;

export const ContentManagementLinkStyle = styled.a.attrs({
  className: 'ContentManagementLinkStyle',
})`
  display: flex;
  align-items: center;
  gap: 0.375em;

  color: inherit;
  font-size: ${pxToRem(14)};

  @media (hover: hover) {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  > img {
    display: block;
    height: 1em;
  }
`;

export const PipedItemRowStyle = styled.div.attrs({ className: 'PipedItemRowStyle' })<{
  styleAlign: DirectionAndPlacement.Left | DirectionAndPlacement.Center;
}>`
  display: flex;
  justify-content: ${({ styleAlign }) =>
    styleAlign === DirectionAndPlacement.Center ? 'center' : 'flex-start'};
  align-items: center;
  flex-wrap: wrap;
  --gap: 0.75em;
  gap: var(--gap);

  @media (min-width: ${({ theme }) => theme.responsive.mobileLrgBreakpoint}) {
    > * {
      &:not(:last-child) {
        padding-right: var(--gap);
        border-right: 1px solid ${({ theme }) => theme.line.color.default};
      }
    }
  }

  @media not (min-width: ${({ theme }) => theme.responsive.mobileLrgBreakpoint}) {
    flex-direction: column;
    --gap: 0.5em;
  }
`;
