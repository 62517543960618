/* eslint-disable camelcase */
import { Contact, Expand } from 'types';
import BaseModel from './BaseModel';
import { normalizeProps } from './helpers';

const getFirstCharAsUpper = (val: string): string => {
  return val ? val.charAt(0).toUpperCase() : '';
};

interface ContactProps extends Contact {
  // normalized props
  first_name?: string;
  last_name?: string;
}

class ContactModel extends BaseModel<ContactProps> {
  firstName?: string;

  lastName?: string;

  phone: TSFixMe;

  email: TSFixMe;

  constructor(props: Expand<ContactProps>) {
    const reshapedProps = normalizeProps<ContactProps>(props, {
      first_name: 'firstName',
      last_name: 'lastName',
      primary_email: 'email',
    });
    super(reshapedProps);
    Object.assign(this, reshapedProps);
  }

  getFullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  getFirstInitial(): string {
    return getFirstCharAsUpper(this.firstName || '');
  }

  getLastInitial(): string {
    return getFirstCharAsUpper(this.lastName || '');
  }
}

export default ContactModel;
