import React, { useEffect, useState } from 'react';
import { DirectionAndPlacement } from '@pointdotcom/pds';
import ShieldGraphic from '../ShieldGraphic';
import { GraphicProps } from '../constants';
import * as styles from './styles';

// reusable elements
export const GraphBar = ({ perc }: { perc: number }) => {
  return <styles.GraphBarStyle perc={perc} />;
};

export const GraphBarContainer = ({
  children,
}: {
  children: React.ReactElement<typeof GraphBar>[];
}) => {
  return <styles.GraphBarContainerStyle>{children}</styles.GraphBarContainerStyle>;
};

export const CapGraphGraphicEightBar = () => {
  const ANIM_DELAY_MS = 200;
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsShown(true), ANIM_DELAY_MS);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <styles.CapGraphGraphicEightBarStyle>
      <ShieldGraphic direction={DirectionAndPlacement.Right} />
      <GraphBarContainer>
        <GraphBar perc={isShown ? 0.1 : 0} />
        <GraphBar perc={isShown ? 0.2 : 0} />
        <GraphBar perc={isShown ? 0.3 : 0} />
        <GraphBar perc={isShown ? 0.4 : 0} />
        <GraphBar perc={isShown ? 0.5 : 0} />
        <GraphBar perc={isShown ? 0.5 : 0} />
        <GraphBar perc={isShown ? 0.5 : 0} />
        <GraphBar perc={isShown ? 0.5 : 0} />
      </GraphBarContainer>
    </styles.CapGraphGraphicEightBarStyle>
  );
};

export const CapGraphGraphicThreeBar = () => {
  const ANIM_DELAY_MS = 200;
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsShown(true), ANIM_DELAY_MS);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <styles.CapGraphGraphicThreeBarStyle>
      <ShieldGraphic direction={DirectionAndPlacement.Right} />
      <GraphBarContainer>
        <GraphBar perc={isShown ? 0.4 : 0} />
        <GraphBar perc={isShown ? 0.6 : 0} />
        <GraphBar perc={isShown ? 0.8 : 0} />
      </GraphBarContainer>
    </styles.CapGraphGraphicThreeBarStyle>
  );
};

export default function CapGraphGraphic({ isShown }: GraphicProps) {
  return (
    <styles.CapGraphGraphicStyle>
      <ShieldGraphic />
      <GraphBarContainer>
        <GraphBar perc={isShown ? 0.3 : 0} />
        <GraphBar perc={isShown ? 0.6 : 0} />
        <GraphBar perc={isShown ? 0.8 : 0} />
        <GraphBar perc={isShown ? 0.8 : 0} />
        <GraphBar perc={isShown ? 0.8 : 0} />
      </GraphBarContainer>
    </styles.CapGraphGraphicStyle>
  );
}
