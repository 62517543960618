import styled from 'styled-components';

export const WhiteLayoutStyle = styled.div.attrs({ className: 'WhiteLayoutStyle' as string })`
  background: white;
  &.headerless {
    padding-top: $sectionSeparationPadding;
  }

  .logoContainer {
    text-align: left;
  }

  &.centerAligned {
    .logoContainer {
      text-align: center;
    }
    .HeaderStyle {
      max-width: 710px;
    }
    .topSection {
      text-align: center;
      section {
        margin: 0 auto;
      }
    }
  }

  .topSection {
    padding: 2vh 0 10vh;
  }

  &.compact {
    .topSection {
      padding: 0em 1em;
    }

    .MainHeader {
      padding: 12% 0 4%;
    }

    .PageContentHeader {
      font-size: 3em;
    }

    @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
      .MainHeader {
        padding-top: 90px;
        padding-bottom: 20px;
      }

      .ui.sub.header {
        display: none !important;
      }
    }
  }
`;
