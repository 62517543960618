import React, { forwardRef, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import { PaginationDirection, PaginatorProps } from './constants';
import * as styles from './styles';

// TODO: add this to PDS

// TODO: implement this on fullscreen carousel and consider for main prequal
const Paginator = forwardRef<HTMLDivElement, PaginatorProps>(
  (
    {
      items,
      currentIndex = 0,
      testIdPrefix = 'paginatorItem',
      focusSelector, // after paginating, focus needs to be reset into new view
    },
    ref
  ) => {
    const prevIndex = useRef<number>(currentIndex);
    const itemRefs = useRef<Array<HTMLDivElement>>([]);
    const minHeight = itemRefs.current[currentIndex]?.offsetHeight;

    useEffect(() => {
      prevIndex.current = currentIndex;
      if (focusSelector) {
        const elm = itemRefs.current[currentIndex].querySelector(focusSelector) as HTMLElement;
        setTimeout(() => {
          elm?.focus();
        }, styles.TRANSITION_SPEED_MS);
      }
    }, [currentIndex, focusSelector]);

    let direction = PaginationDirection.Static;
    if (prevIndex.current < currentIndex) {
      direction = PaginationDirection.Right;
    }
    if (prevIndex.current > currentIndex) {
      direction = PaginationDirection.Left;
    }

    return (
      <styles.PaginatorStyle style={{ minHeight }} ref={ref}>
        {items.map((item, i) => {
          const itemCurrent = i === currentIndex;
          const itemBeforeCurrent = i < currentIndex;
          const itemAfterCurrent = i > currentIndex;
          const testId = `${testIdPrefix}${i + 1}`;
          return (
            <Transition in={itemCurrent} timeout={styles.TRANSITION_SPEED_MS} key={testId}>
              {(status) => (
                <styles.ItemStyle
                  data-testid={testId}
                  animationStatus={status}
                  direction={direction}
                  aria-hidden={!itemCurrent}
                  itemBeforeCurrent={itemBeforeCurrent}
                  itemAfterCurrent={itemAfterCurrent}
                  className={classNames(
                    { itemCurrent, itemBeforeCurrent, itemAfterCurrent },
                    status
                  )}
                  ref={(el) => {
                    if (el) {
                      itemRefs.current[i] = el;
                    }
                  }}
                >
                  {item}
                </styles.ItemStyle>
              )}
            </Transition>
          );
        })}
      </styles.PaginatorStyle>
    );
  }
);

export default Paginator;
