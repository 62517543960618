import styled from 'styled-components';
import coinPileImage from './images/coinpile.png';

export const CoinPileGraphicStyle = styled.div.attrs({ className: 'CoinPileGraphicStyle' })`
  --coinPileGraphicSize: 35%;
  position: absolute;
  width: var(--coinPileGraphicSize);
  padding-bottom: var(--coinPileGraphicSize);
  bottom: -1px;
  background-image: url('${coinPileImage}');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  left: 11%;
`;
