import styled from 'styled-components';
import { ContainerStyles } from '@pointdotcom/pds';
import { appTheme } from 'styles/';

export const MainHeaderNoNavStyle = styled.header.attrs({ className: 'MainHeaderNoNavStyle' })``;

export const MainHeaderNoNavContainerStyle = styled.div.attrs({
  className: 'MainHeaderNoNavContainerStyle',
})`
  background: ${(props) => props.theme.Color.White};
  align-items: center;
  position: relative;
  display: flex;
  align-content: center;
  flex-flow: column nowrap;
  height: ${appTheme.mainHeaders.height.default};
  border-bottom: ${(props) => `1px solid ${props.theme.line.color.default}`};
  > ${ContainerStyles.ContainerStyle} {
    position: relative;
    height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
  }

  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    height: ${appTheme.mainHeaders.height.mobile};
  }
`;

export const HeaderContentStyle = styled.div.attrs({ className: 'HeaderContentStyle' })`
  display: flex;
  justify-content: space-between;
`;
