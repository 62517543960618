import * as React from 'react';
import { BreadCrumbProps } from 'components/Breadcrumbs';
import { DashboardCrumbs } from 'components/DashboardTasksCrumbs';
import MainHeader from 'components/MainHeader';
import { NavItem, NavProps } from 'components/MainHeader/nav';
import ReadOnlyFlag from 'components/ReadOnlyFlag';
import { SubHeaderProps } from 'components/SubHeader';
import { Page, generateUrlFromPage, getPathFromPage } from 'containers/helpers';
import { useDashboardLogout } from 'containers/prequal/hooks';
import DashboardModel from 'models/DashboardModel';
import i18n from './i18n';

interface DashboardMainHeaderProps {
  dashboard: DashboardModel;
  navProps?: NavProps;
  crumbProps?: BreadCrumbProps;
  subHeaderProps?: SubHeaderProps;
}

export default function DashboardMainHeader({
  crumbProps,
  subHeaderProps,
  dashboard,
  navProps = {},
}: DashboardMainHeaderProps): JSX.Element {
  const { dashboardLogout } = useDashboardLogout();

  const navItems: Array<NavItem> = [
    {
      text: i18n.dashboard,
      path: getPathFromPage(Page.DASHBOARD),
      href: generateUrlFromPage(Page.DASHBOARD),
    },
    {
      text: i18n.tasks,
      path: getPathFromPage(Page.DASHBOARD_TASKS_LIST),
      href: generateUrlFromPage(Page.DASHBOARD_TASKS_LIST),
    },
    {
      text: i18n.processOverview,
      path: getPathFromPage(Page.DASHBOARD_PROCESS_OVERVIEW),
      href: generateUrlFromPage(Page.DASHBOARD_PROCESS_OVERVIEW),
    },
    {
      text: i18n.costEstimator,
      path: getPathFromPage(Page.DASHBOARD_ESTIMATOR),
      href: generateUrlFromPage(Page.DASHBOARD_ESTIMATOR),
    },
    {
      text: i18n.logOut,
      action: () => {
        dashboardLogout();
      },
    },
  ];

  return (
    <>
      {dashboard.readOnly && <ReadOnlyFlag />}
      <MainHeader
        navItems={navItems}
        navProps={{
          ...navProps,
          showAsPopup: true,
          hidePopupTitle: true,
        }}
        subHeaderProps={{
          leftContent: <DashboardCrumbs {...crumbProps} />,
          hideCrumbs: !crumbProps?.currentCrumb,
          ...subHeaderProps,
        }}
      />
    </>
  );
}
