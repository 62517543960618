import React from 'react';
import { SlideData } from '../../constants';
import MagnifyGraphic from '../MagnifyGraphic';
import * as styles from './styles';

export default function HandCheckGraphic({ totalOwnerPays }: Pick<SlideData, 'totalOwnerPays'>) {
  return (
    <styles.HandCheckGraphicStyle>
      <MagnifyGraphic />
      <styles.TextContainerStyle>{totalOwnerPays}</styles.TextContainerStyle>
    </styles.HandCheckGraphicStyle>
  );
}
