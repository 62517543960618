import styled from 'styled-components';
import { ButtonStyles, Container, HeaderStyles, mixins } from '@pointdotcom/pds';
import { pxToRem } from 'styleHelpers';
import { BorderedSectionStyle, HeroContainerStyle } from 'styles';
import bill from './images/bill.png';
import bills from './images/bills.png';
import bubble from './images/bubble.png';
import bubbleandcard from './images/bubbleandcard.png';
import drowning from './images/drowning.png';
import lifesaver from './images/lifesaver.png';

export const DebtPayoffSelectionStyle = styled.div.attrs({ className: 'DebtPayoffSelectionStyle' })`
  ${HeroContainerStyle} {
    ${HeaderStyles.HeaderStyle} {
      h2 {
        font-size: ${pxToRem(60)};
        line-height: 1.28em;
        @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
          font-size: ${pxToRem(30)};
        }
      }
    }
  }

  ${BorderedSectionStyle} {
    padding: 0;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${HeaderStyles.HeaderStyle} {
      margin-bottom: 0;
    }
  }
`;

export const DebtOptionOuterContainerStyle = styled.div.attrs({
  className: 'DebtOptionOuterContainerStyle',
})`
  position: relative;
  background: ${({ theme }) => theme.Color.GrayLight1};
  text-align: center;
  overflow-x: clip;
  display: flex;
  align-items: center;
  justify-content: stretch;
  h3 {
    /* text-wrap: balance; */
    padding-bottom: 2rem;
    margin: 0;
    color: ${({ theme }) => theme.Color.Gray4};
    text-transform: uppercase;
    font-weight: normal;
    font-size: ${pxToRem(16)};
    letter-spacing: 0.1rem;
    text-align: center;
    margin: auto;
    max-width: 70%;
  }

  @media (max-width: ${({ theme }) => theme.responsive.computerBreakpoint}) {
    h3 {
      max-width: 80%;
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    text-align: left;

    h3 {
      font-size: ${pxToRem(14)};
    }
  }
`;

export const DebtOptionInnerContainerStyle = styled(Container).attrs({
  className: 'DebtOptionInnerContainerStyle',
})`
  padding-block: 6rem;
  max-width: 580px; // magic number here for optimal text formatting
  position: relative;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 60%;
    height: 110%;
    top: 0;
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
  }
  &:before {
    right: 100%;
    margin-right: -2rem;
    background-image: url('${bills}');
    background-position: right;
  }
  &:after {
    left: 100%;
    margin-left: -2rem;
    background-image: url('${drowning}');
    background-position: left;
  }
  @media (max-width: ${({ theme }) => theme.responsive.computerBreakpoint}) {
    padding-block: 4.3rem;
    &:before {
      margin-right: -10vw;
    }
    &:after {
      margin-left: -10vw;
    }
  }
  @media (max-width: 1080px) {
    max-width: 80%;
    ${ButtonStyles.ButtonBaseStyle} {
      white-space: nowrap;
      justify-content: center;
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    max-width: none;

    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
`;

export const CTAChoiceStyle = styled.div.attrs({ className: 'CTAChoiceStyle' })`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const HighlightedNumberStyle = styled.span.attrs({ className: 'HighlightedNumberStyle' })`
  display: inline-block;
  background: ${({ theme }) => mixins.rgba(theme.Color.Yorange, 0.2)};
  padding: 0.05em 0.125em;
  margin: -0.05em -0.125em;
`;

export const FundingStepStyle = styled(Container).attrs({ className: 'FundingStepStyle' })`
  padding-bottom: 5rem;
  h4 {
    padding-block: 3rem;
    margin-bottom: 3rem;
    border-bottom: 1px solid ${({ theme }) => theme.line.color.default};
    font-size: ${pxToRem(21)};
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    padding-bottom: 0;
    h4 {
      font-size: ${pxToRem(18)};
    }
  }
`;

export const StepListStyle = styled.ul.attrs({ className: 'StepListStyle' })`
  --marker-size: 26px;
  margin-left: calc(var(--marker-size) / 2);
  padding: 0;
  list-style: none;
`;

export const StepItemStyle = styled.li.attrs({ className: 'StepItemStyle' })<{ active: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  color: ${({ theme, active }) =>
    active ? theme.Color.PointBlack : theme.Color.GrayLightestAccessible};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  padding-bottom: 1.5rem;

  /* Connector line between list items */
  &::before {
    content: '';
    position: absolute;
    display: block;
    left: -2px;
    width: 0;
    height: 100%;
    border: 1px solid ${({ theme }) => theme.Color.Gray2};
  }

  &:last-of-type::before {
    display: none;
  }
`;

export const ItemContentStyle = styled.div.attrs({ className: 'ItemContentStyle' })`
  display: flex;
  align-items: center;
`;

export const StepMarkerStyle = styled.div.attrs({ className: 'StepMarkerStyle' })<{
  active: boolean;
}>`
  display: grid;
  place-content: center;
  height: var(--marker-size);
  width: var(--marker-size);
  border-radius: 50%;
  border: ${({ active }) => (active ? '3px' : '2px')} solid ${({ theme }) => theme.Color.Gray2};
  transform: translate(calc(-50% - 1px), 0);
  background-color: ${({ theme, active }) => (active ? theme.Color.PointBlack : theme.Color.Gray1)};
`;

export const MobileGraphicStyle = styled.div.attrs({ className: 'MobileGraphicStyle' })`
  position: absolute;
  width: 200px;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: none;
  pointer-events: none;
  &:nth-child(1) {
    left: 15%;
    top: 10%;
    background-image: url('${bubble}');
    width: 15px;
    height: 15px;
  }
  &:nth-child(2) {
    right: -2%;
    top: -28%;
    background-image: url('${lifesaver}');
    width: 200px;
    height: 200px;
  }
  &:nth-child(3) {
    left: 10%;
    bottom: -15%;
    background-image: url('${bill}');
    width: 130px;
    height: 130px;
  }
  &:nth-child(4) {
    right: 10%;
    bottom: -10%;
    background-image: url('${bubbleandcard}');
    width: 120px;
    height: 90px;
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    display: block;
  }
`;
