import styled from 'styled-components';
import { CheckboxStyles, mixins } from '@pointdotcom/pds';

export const ListStyle = styled.ol.attrs({ className: 'ListStyle' })`
  font-size: ${(props) => mixins.getRemFromPx(20, props.theme.globals.baseFontSize.mobile)};
  font-weight: bold;
  margin: 0;
`;

export const ListItemStyle = styled.li.attrs({ className: 'ListItemStyle' })`
  margin-bottom: 1.5em;
  padding-left: 0.5em;

  &:last-child {
    margin-bottom: 0;
  }

  .unbold {
    font-weight: normal;
  }
`;

export const SelectionContentStyle = styled.div.attrs({ className: 'SelectionContentStyle' })`
  form {
    max-width: 700px;
  }

  ${CheckboxStyles.CheckboxStyle} {
    a {
      display: inline-block;
      white-space: normal;
    }
  }
`;

// TODO: add this to PDS
export const InputGroupStyle = styled.div.attrs({ className: 'InputGroupStyle' })`
  margin-bottom: 2rem;
`;
