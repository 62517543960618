import styled, { css } from 'styled-components';
import { HeaderStyles, mixins } from '@pointdotcom/pds';
import { Table2ColStyle } from 'components/Table2Col/styles';
import { SplashCopySerifStyle } from 'styles/';
import { ScenarioProps, ScenarioShapeStyle, scenarioRescourceMap } from '../ScenariosTable/styles';

const CarouselItemCopyStyle = styled.p.attrs({ className: 'CarouselItemCopyStyle' })`
  margin: 0 auto;
  padding: 1rem 0 0;
  margin-top: 1rem;
  line-height: 1.5rem;
`;

const itemPadding = '5%';
const mobileItemPadding = '10%';

const CarouselItemStyle = styled.div.attrs({ className: 'CarouselItemStyle' as string })<{
  stretch?: boolean;
}>`
  justify-content: center;
  flex: ${(props) => (props.stretch ? '50%' : '0 1 auto')};
  padding: ${itemPadding};
  position: relative;
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    flex: 100%;
    padding: ${mobileItemPadding};
    text-align: center;
  }
`;

const CarouselItemHeaderComboStyle = styled.header.attrs({
  className: 'CarouselItemHeaderComboStyle',
})`
  text-align: center;
  width: 100%;
  white-space: nowrap;
  text-transform: capitalize;
  ${HeaderStyles.HeaderStyle} {
    text-align: inherit;
    margin-top: 0.1em;
    font-size: ${(props) => mixins.getRemFromPx(38, props.theme.globals.baseFontSize.default)};
  }
`;

const CarouselItemUnderStyle = styled(CarouselItemStyle).attrs({
  className: 'CarouselItemUnderStyle',
})<ScenarioProps>`
  flex-flow: column;
  background: ${(props) => (props.scenario ? scenarioRescourceMap(props).color : 'transparent')};
  border: 1px solid
    ${(props) => (props.scenario ? scenarioRescourceMap(props).color : 'transparent')};
  > img {
    margin: ${itemPadding} auto;
  }
  ${CarouselItemHeaderComboStyle} {
    position: relative;
    z-index: 2;
  }
  ${CarouselItemCopyStyle} {
    position: relative;
    border-top: 1px solid
      ${(props) => {
        let color = 'transparent';
        if (props.scenario) {
          color = scenarioRescourceMap(props).color as TSFixMe;
        }
        return color;
      }};
  }
  @media only print {
    border-color: ${(props) => props.theme.line.color.default};
  }
  @media only screen {
    &::before {
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      mix-blend-mode: difference;
      background-color: ${(props) => mixins.rgba(props.theme.Color.PointBlack, 0.13)};
    }
  }
  ${mixins.ieAndEdgeOnly(
    css<ScenarioProps>`
      &::before {
        ${(props) =>
          props.scenario &&
          `background-color: ${mixins.lightenDarkenColor(
            scenarioRescourceMap(props).color as TSFixMe,
            -0.13
          )}`};
      }
    ` as TSFixMe
  )}
`;

const CarouselItemOverStyle = styled(CarouselItemStyle).attrs({
  className: 'CarouselItemOverStyle',
})<{ hasItemInfo?: boolean; itemInfoPosition?: 'top' | 'bottom' }>`
  flex-flow: column;
  border: 1px solid ${(props) => props.theme.line.color.inverted};
  ${CarouselItemCopyStyle} {
    border-top: 1px solid ${(props) => mixins.rgba(props.theme.line.color.inverted, 0.23)};
  }
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    border-left: none;
    border-right: none;
    ${(props) =>
      props.itemInfoPosition
        ? `padding-${props.itemInfoPosition}: 0; margin-${props.itemInfoPosition} : 40px`
        : ''}
  }
  @media only print {
    border-color: ${(props) => props.theme.line.color.default};
  }
`;

const CarouselItemMathStyle = styled(CarouselItemStyle).attrs({
  className: 'CarouselItemMathStyle',
})<{ textContent?: boolean }>`
  padding: ${itemPadding} 3%;
  flex: 0 1 auto;
  font-size: ${(props) => (props.textContent ? '1.1em' : '2em')};
  flex-flow: row;
  > i {
    font-style: normal;
    &:last-child {
      display: none;
    }
  }
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    justify-content: center;
    padding: 3% ${itemPadding};
    > i {
      &:first-child {
        display: none;
      }
      &:last-child {
        display: block;
      }
    }
  }
`;

const CarouselNextBackComboStyle = styled.nav.attrs({ className: 'CarouselNextBackComboStyle' })`
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  display: flex;
  padding: ${itemPadding};

  > button {
    ${mixins.appearance('none')}
    font-family: inherit;
    font-size: inherit;
    border: none;
    background: none;
    color: inherit;
    cursor: pointer;
    padding: 20px;
    &[data-action='prev'] {
      border-right: 1px solid ${(props) => mixins.rgba(props.theme.Color.White, 0.23)};
    }
  }
`;

const CarouselItemContainerStyle = styled.div.attrs({ className: 'CarouselItemContainerStyle' })<
  Partial<ScenarioProps> & {
    left?: boolean;
  }
>`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  text-align: ${(props) => (props.left ? 'left' : 'center')};

  ${Table2ColStyle} {
    --accordionLeftRightPadding: 7%;
    --accordionTopBottomPadding: 1.3rem;
    width: 90%;
    margin: 0 auto;
    background: ${(props) =>
      props.scenario != null
        ? scenarioRescourceMap({
            scenario: props.scenario,
            theme: props.theme,
          }).color
        : 'transparent'};
  }
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    flex-flow: column;
    margin-left: -${(props) => props.theme.container.gutter.default};
    margin-right: -${(props) => props.theme.container.gutter.default};
    width: ${(props) => {
      const [val, unit] = mixins.extractValUnit(props.theme.container.gutter.default);
      return `calc(100% + ${(val as TSFixMe) * 2}${unit})`;
    }};

    ${Table2ColStyle} {
      width: 100%;
    }
  }
`;

const CalcCarouselItemStyle = styled.div.attrs({
  className: 'CalcCarouselItemStyle',
})<ScenarioProps>`
  padding: 6% 8% 8%;
  ${ScenarioShapeStyle} {
    height: 100vh;
  }
  ${SplashCopySerifStyle} {
    margin-left: auto;
    margin-right: auto;
    max-width: 650px;
    margin-bottom: 7%;
  }
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding: 10% 0;
  }
`;

const CarouselAsideStyle = styled.aside.attrs({ className: 'CarouselAsideStyle' })`
  width: 45%;
  margin: 0 auto;
  text-align: center;
  font-size: ${(props) =>
    mixins.getRemFromPx(
      props.theme.globals.baseFontSize.mini as TSFixMe,
      props.theme.globals.baseFontSize.default
    )};
  b {
    font-weight: normal;
    font-family: ${(props) => props.theme.fonts.sansSerif.name};
    font-weight: 900;
  }
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    width: 100%;
  }
`;

const CarouselItemInfoStyle = styled.div.attrs({ className: 'CarouselItemInfoStyle' })<
  ScenarioProps & { position: 'top' | 'bottom' }
>`
  color: ${(props) => (props.scenario ? scenarioRescourceMap(props).color : 'transparent')};
  background: ${(props) => props.theme.Color.White};
  position: absolute;
  width: 90%;
  box-sizing: border-box;
  right: -20px;
  top: -35px;
  padding: 5% 4%;
  text-align: center;
  font-size: 13px;
  font-style: italic;
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    right: 0;
    top: auto;
    bottom: auto;
    position: relative;
    padding: 30px;
    width: 80%;
    ${(props) => `${props.position}: -25px`};
  }
`;

const CarouselItemAsideStyle = styled.aside.attrs({ className: 'CarouselItemAsideStyle' })`
  margin-top: 1rem;
  font-size: ${(props) =>
    mixins.getRemFromPx(
      props.theme.globals.baseFontSize.micro as TSFixMe,
      props.theme.globals.baseFontSize.default
    )};
`;

const CarouselSplashItemStyle = styled.div.attrs({ className: 'CarouselSplashItemStyle' })`
  padding: 3% 0 5%;
  @media only screen and (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding: 11% 0;
  }
`;

const CarouselInnerItemContainerStyle = styled.div.attrs({
  className: 'CarouselInnerItemContainerStyle',
})`
  width: 100%;
`;

export {
  CarouselItemInfoStyle,
  CarouselItemAsideStyle,
  CarouselSplashItemStyle,
  CarouselItemCopyStyle,
  CarouselAsideStyle,
  CarouselInnerItemContainerStyle,
  CarouselNextBackComboStyle,
  CarouselItemHeaderComboStyle,
  CarouselItemContainerStyle,
  CarouselItemUnderStyle,
  CarouselItemOverStyle,
  CalcCarouselItemStyle,
  CarouselItemMathStyle,
};
