import styled, { css } from 'styled-components';
import { DirectionAndPlacement, mixins } from '@pointdotcom/pds';
import { pxToRem } from 'styleHelpers';

export const ANIM_SPEED_MS = 250;

export const arrowSizePx = 11;
export const arrowGapPx = arrowSizePx * 2;
export interface TooltipStyleProps {
  isOpen: boolean;
  inline?: boolean;
  xPos?: DirectionAndPlacement.Left | DirectionAndPlacement.Right | DirectionAndPlacement.Center;
  yPos?: DirectionAndPlacement.Top | DirectionAndPlacement.Bottom | DirectionAndPlacement.Center;
  arrowX?: number;
  yMargin?: string;
  maxWidth?: string;
}

const inlineTTcss = css<TooltipStyleProps>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: static;
  ${({ yPos }) => {
    // TODO: handle xPos
    if (yPos === DirectionAndPlacement.Bottom) {
      return css`
        margin-top: ${arrowSizePx}px;
      `;
    }
  }}
`;

const fixedTTcss = css<TooltipStyleProps>`
  display: block;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  position: fixed;
  max-width: ${({ maxWidth }) => maxWidth ?? '200px'};
`;

export const TooltipStyle = styled.span.attrs({ className: 'TooltipStyle' })<TooltipStyleProps>`
  ${({ inline }) => (inline ? inlineTTcss : fixedTTcss)};
  width: 100%;
  box-sizing: border-box;
  background: ${({ theme }) => theme.Color.White};
  border-radius: 3px;
  filter: drop-shadow(0 0 3px ${({ theme }) => mixins.rgba(theme.Color.Gray4, 0.2)});
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  font-size: ${pxToRem(14)};
  font-weight: 400;
  gap: 1rem;
  align-items: center;
  padding: 2em;
  z-index: 2;
  margin-top: ${({ yMargin }) => (yMargin ? yMargin : null)};
  &::after {
    display: ${({ xPos, yPos }) =>
      xPos === DirectionAndPlacement.Center && yPos === DirectionAndPlacement.Center
        ? 'none'
        : 'block'};
    content: '';
    position: absolute;
    z-index: -1;
    border: ${arrowSizePx}px solid transparent;
    box-sizing: content-box;
    width: 0;
    height: 0;

    ${({ yPos }) => {
      if (yPos === DirectionAndPlacement.Bottom) {
        return css`
          border-bottom-color: ${({ theme }) => theme.Color.White};
          border-top-width: 0;
          top: ${-arrowSizePx}px;
        `;
      } else if (yPos === DirectionAndPlacement.Top) {
        return css`
          border-top-color: ${({ theme }) => theme.Color.White};
          border-bottom-width: 0;
          bottom: ${-arrowSizePx}px;
        `;
      }
    }}

    ${({ xPos, yPos }) => {
      if (xPos === DirectionAndPlacement.Right) {
        if (yPos === DirectionAndPlacement.Center) {
          return css`
            border-right-color: ${({ theme }) => theme.Color.White};
            left: ${-arrowSizePx * 2}px;
            top: calc(50% - ${arrowSizePx}px);
          `;
        }
        return css`
          left: ${arrowGapPx}px;
        `;
      } else if (xPos === DirectionAndPlacement.Left) {
        if (yPos === DirectionAndPlacement.Center) {
          return css`
            border-left-color: ${({ theme }) => theme.Color.White};
            right: ${-arrowSizePx * 2}px;
            top: calc(50% - ${arrowSizePx}px);
          `;
        }
        return css`
          right: ${arrowGapPx}px;
        `;
      } else if (xPos === DirectionAndPlacement.Center) {
        return css`
          left: calc(50% - ${arrowSizePx}px);
        `;
      }
    }}

    ${({ inline, arrowX }) => {
      if (inline) {
        return css`
          left: ${arrowX}px;
        `;
      }
    }}
  }
`;
