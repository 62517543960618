import React from 'react';
import {
  ButtonClickFunc,
  DirectionAndPlacement,
  HelpTextAnimated,
  LinkButton,
  Loader,
  ModalProps,
  Size,
  Style,
} from '@pointdotcom/pds';
import { TrashCanIcon } from 'components/UploadedFiles/icons';
import { TaskFile } from 'services/apiTypes/taskTypes';
import i18n from './i18n';
import * as styles from './styles';

interface FilePreviewModalProps extends ModalProps {
  taskId: string;
  taskFile?: null | TaskFile;
  isDeleting?: boolean;
  onDeleteFile: ButtonClickFunc;
  deleteFileError?: string | null;
}

export default function FilePreviewModal({
  taskId,
  taskFile,
  isDeleting,
  onDeleteFile,
  deleteFileError,
  isOpen: isOpenFromProps,
  ...props
}: FilePreviewModalProps) {
  return (
    <styles.FilePreviewModalStyle
      upperHeaderText={taskFile?.fileName ?? ''}
      {...props}
      isOpen={isOpenFromProps && taskFile != null}
      footer={
        <footer>
          <LinkButton invertTextDecoration onClick={onDeleteFile}>
            {i18n.delete}
            {isDeleting ? <Loader styleSize={Size.Small} /> : <TrashCanIcon />}
          </LinkButton>
          <HelpTextAnimated
            styleType={Style.Error}
            styleAlign={DirectionAndPlacement.Right}
            show={!!deleteFileError}
          >
            {deleteFileError}
          </HelpTextAnimated>
        </footer>
      }
    >
      {taskFile?.previewUrl != null && (
        <>
          <img src={taskFile.previewUrl} alt={taskFile.fileName} />
          <Loader />
        </>
      )}
    </styles.FilePreviewModalStyle>
  );
}
