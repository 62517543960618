import * as z from "zod";

export enum LogSeverity {
  Debug = 1,
  Verbose = 2,
  Info = 3,
  Warn = 4,
  Error = 5,
  Critical = 6,
}
export const logSeveritySchema = z.nativeEnum(LogSeverity);

export const browserLogRequestSchema = z
  .object({
    severity: logSeveritySchema,
    message: z.unknown(),
  })
  .catchall(z.unknown() as z.ZodType);
export type BrowserLogRequest = z.infer<typeof browserLogRequestSchema>;
