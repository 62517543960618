export enum PaginationDirection {
  Left = 'left',
  Right = 'right',
  Static = 'static',
}

export interface PaginatorProps {
  items: Array<React.ReactNode>;
  currentIndex: number;
  testIdPrefix?: string;
  focusSelector?: string;
}
