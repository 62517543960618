import * as React from 'react';
import * as styles from './iconStyles';

export function UploadIcon() {
  return (
    <styles.UploadIconStyle width="22" height="21" viewBox="0 0 22 21" fill="none">
      <path
        d="M18.875 13.125V16.625C18.875 17.0891 18.6906 17.5342 18.3624 17.8624C18.0342 18.1906 17.5891 18.375 17.125 18.375H4.875C4.41087 18.375 3.96575 18.1906 3.63756 17.8624C3.30937 17.5342 3.125 17.0891 3.125 16.625V13.125"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.375 7L11 2.625L6.625 7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11 2.625V13.125" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </styles.UploadIconStyle>
  );
}
