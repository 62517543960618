import styled from 'styled-components';
import { LeftRightPaginatorStyle } from 'components/LeftRightPaginator/styles';
import { SubNavStyle } from 'components/SubNav/styles';

export const COLLISION_BREAKPOINT = '860px';

// TODO: if used again, add as a part of SubNav
export const SubNavWrapperStyle = styled.div.attrs({ className: 'SubNavWrapperStyle' })`
  ${LeftRightPaginatorStyle} {
    display: none;
  }
  @media (max-width: ${COLLISION_BREAKPOINT}) {
    // magic number to prevent subnav and
    ${SubNavStyle} {
      display: none;
    }
    ${LeftRightPaginatorStyle} {
      display: flex;
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${LeftRightPaginatorStyle} {
      display: none;
    }
  }
`;
