import * as z from "zod";

export const userSchema = z
  .object({
    email: z.string(),
    name: z.string(),
    legalName: z.string().nullable(),
    firstName: z.string().nullable(),
    lastName: z.string().nullable(),
    phoneNumber: z.string().nullable(),
    jobTitle: z.string().nullable(),
    image: z.string().nullable(),
  })
  .catchall(z.unknown() as z.ZodType);
export type User = z.infer<typeof userSchema>;

export const lookupByEmailSchema = z.array(userSchema);
export type LookupByEmail = z.infer<typeof lookupByEmailSchema>;
