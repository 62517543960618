import * as React from 'react';
import * as styles from './styles';

interface CapShieldIconProps {
  children?: React.ReactNode;
}

export default function CapShieldIcon({ children }: CapShieldIconProps): JSX.Element {
  return (
    <styles.CapShieldIconStyle>
      <styles.CapShieldSvgStyle width="12" height="16" viewBox="0 0 12 16">
        <path d="M6 0L0 2.27449V6.89633C0 6.89633 0 6.91252 0 6.92331C0.00267023 8.11317 0.221629 9.23558 0.662216 10.2932C0.750334 10.5064 0.849132 10.7168 0.953271 10.9246C1.16422 11.3401 1.41255 11.7448 1.69559 12.136C1.7757 12.2466 1.85848 12.3465 1.94126 12.4517C2.88652 13.6551 4.02403 14.4969 5.36182 14.9663C5.57009 15.0392 5.78104 15.1039 6 15.1579C6.65154 14.9933 7.26035 14.7478 7.82644 14.4213C8.56609 13.9977 9.23365 13.4338 9.82911 12.7377C9.99199 12.5461 10.1495 12.3465 10.3017 12.136C10.8678 11.3509 11.2924 10.5199 11.5754 9.64569C11.7864 8.99005 11.9199 8.31283 11.9733 7.60863C11.992 7.37389 12 7.13646 12 6.89633V2.27449L6 0Z" />
      </styles.CapShieldSvgStyle>
      {children}
    </styles.CapShieldIconStyle>
  );
}
