import styled, { keyframes } from 'styled-components';
import { ringsCSS } from '../RingsGraphic/styles';
import { TwinkleGraphicStyle } from '../TwinkleGraphic/styles';
import billImage from './images/bill.png';
import calImage from './images/cal.png';
import calMobileImage from './images/calmobile.png';
import wandImage from './images/wand.png';

const dissolve = keyframes`
  0% {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
  100% {
    opacity: 0;
    transform: rotate(12deg) translate(
      calc(-40px + 200px * var(--random-x)),
      calc(-40px + 200px * var(--random-y))
    ) scale(5, 5.5);
    filter: blur(0.5px);
  }
`;

const orbit = keyframes`
  from {  -webkit-transform: rotate(0deg) translateX(8px) rotate(0deg); }
  to   {  -webkit-transform: rotate(360deg) translateX(8px) rotate(-360deg); }
`;

export const SmallSquareStyle = styled.div`
  background-image: url('${billImage}');
  background-size: 1000% 1000%; /* Large scale for individual squares */
  width: 100%; /* Fill grid cell */
  height: 100%; /* Fill grid cell */
`;

export const gridSize = 10;

export const DissolvingBillStyle = styled.div.attrs({ className: 'DissolvingBillStyle' })<{
  animate: boolean;
}>`
  --dissolveSpeed: 6s;
  display: grid;
  grid-template-columns: repeat(${gridSize}, 1fr); /* 10 columns */
  grid-template-rows: repeat(${gridSize}, 1fr); /* 10 rows */
  width: 22%;
  height: 16%;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 61.5%;
  top: 71%;
  ${SmallSquareStyle} {
    animation: ${({ animate }) => (animate ? dissolve : 'none')} var(--dissolveSpeed) ease-out
      forwards;
  }
`;

export const WandStyle = styled.div.attrs({ className: 'WandStyle' })`
  background-image: url('${wandImage}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 25%;
  height: 25%;
  position: absolute;
  top: 60%;
  left: 100%;
  animation: ${orbit} 4s linear infinite;
  ${TwinkleGraphicStyle} {
    &:first-child {
      --twinkleSize: 5px;
      bottom: 100%;
      left: 70%;
    }
    &:nth-child(2) {
      --twinkleSize: 5px;
      top: -25%;
      left: 30%;
    }
    &:last-child {
      --twinkleSize: 2px;
      bottom: 90%;
      left: 40%;
    }
  }
`;

export const MagicCalendarGraphicStyle = styled.div.attrs({
  className: 'MagicCalendarGraphicStyle',
})`
  --calendarSize: 45%;
  background-image: url('${calImage}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  width: var(--calendarSize);
  padding-bottom: var(--calendarSize);
  position: absolute;
  bottom: 0;
  &:before {
    content: '';
    ${ringsCSS};
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    background-image: url('${calMobileImage}');
  }
`;
