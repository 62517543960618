import React from 'react';
import { Container } from '@pointdotcom/pds';
import { SectionShadowProps } from './constants';
import * as styles from './styles';

export default function SectionShadow({ stylePosition }: SectionShadowProps) {
  return (
    <styles.SectionShadowStyle stylePosition={stylePosition}>
      <Container>
        <styles.SectionShadowShadowStyle stylePosition={stylePosition} />
      </Container>
    </styles.SectionShadowStyle>
  );
}
