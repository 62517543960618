export default {
  haveYouYourSpouseOrAnyOwner:
    'Have you, your spouse, or any owner of the property ever been convicted or pled guilty to a felony or misdemeanor in relation to financial crimes?',
  areThereAnyOutstandingJudgments:
    'Are there any outstanding judgments against you, your spouse, or any owner of the property?',
  areYouYourSpouseOrAnyOwnerPlanning:
    'Are you, your spouse, or any owner of the property planning to file for bankruptcy, presently involved in any bankruptcy, or have received a bankruptcy discharge or dismissal within the past 2 years?',
  haveYouYourSpouseOrAnyOwnerDirectlyOrIndirectly:
    'Have you, your spouse, or any owner of the property directly or indirectly been obligated on any loan which resulted in foreclosure, transfer of title in lieu of foreclosure, or judgment?',
  doYouYourSpouseOrAnyOwnerPlan:
    'Do you, your spouse, or any owner of the property plan to close any new mortgages, home equity loans, HELOCs, HERO loans, PACE loans, or any other type of solar or energy-efficiency loans prior to receiving funds from Point? This includes any new debts or liens secured by your property.',
  tellUsHowMuchMoney:
    'Tell us how much money you are looking for and how you will use the Point funds. The amount of funding will impact what you pay in fees and the final repayment.',
  howMuchMoney: 'How much money would you like to receive?',
  howWillYouUseTheseFunds: 'How will you use these funds?',
  pleaseExplainInFurtherDetail: 'Please explain in further detail how you will use these funds.',
  chooseOne: 'Choose one',
  youHavePrequalified:
    'You have prequalified for up to %optionAmount%. You can request an amount from $30,000 to %optionAmount%. This is an estimate only and may change based on your application and home appraisal',
};
