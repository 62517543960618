import styled from 'styled-components';

export const LicencesLinksCopyrightStyle = styled.div.attrs({
  className: 'LicencesLinksCopyrightStyle',
})`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  .pipedItemRow {
    > * {
      padding: 0 0.5em;
      border-right: 1px solid ${({ theme }) => theme.line.color.default};
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        border: none;
        padding-right: 0;
      }
    }
  }
  > * {
    &:first-child {
      display: flex;
      flex-flow: row nowrap;
    }
  }
`;
