import React from 'react';
import navI18n from 'components/MainHeader/i18n';
import { OfferPage } from 'containers/FollowUpOfferPage';
import { useFollowUpFetch } from 'containers/prequal/hooks';
import ClosingDisclosurePage from '..';

const ClosingDisclosureOfferPage = () => {
  const followUpFetchResults = useFollowUpFetch();
  return (
    <ClosingDisclosurePage followUpFetchResults={followUpFetchResults} pageName={navI18n.yourOffer}>
      <OfferPage followUpFetchResults={followUpFetchResults} />
    </ClosingDisclosurePage>
  );
};

export default ClosingDisclosureOfferPage;
