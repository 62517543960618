import * as z from "zod";
import * as Underwrite_Core_Dockets from "./Underwrite.Core.Dockets.types";
import * as Underwrite_Core_Dashboard from "./Underwrite.Core.Dashboard.types";
import * as Middleware from "./Middleware.types";
import * as HomeownerService_Tasks from "./HomeownerService.Tasks.types";

export const hosDashboardSuccessResponseSchema = z
  .object({
    success: z.literal(true),
    active_stage: Underwrite_Core_Dockets.docketStageSchema,
    recent_activity: z.array(Underwrite_Core_Dashboard.completedTaskSchema),
    initial_offer: z.number().nullable(),
    stages: z.array(Underwrite_Core_Dashboard.docketStageItemSchema),
    person: Underwrite_Core_Dashboard.dashboardPersonSchema,
    docket: Underwrite_Core_Dashboard.docketSchema,
    application:
      Underwrite_Core_Dashboard.dashboardApplicationSchema.optional(),
    authenticate: z.boolean(),
    read_only: z.boolean(),
    account_manager: z.union([z.string(), Middleware.userSchema]).nullable(),
    tasks: z.array(HomeownerService_Tasks.taskSchema).optional(),
  })
  .catchall(z.unknown() as z.ZodType);
export type HosDashboardSuccessResponse = z.infer<
  typeof hosDashboardSuccessResponseSchema
>;

export const logInByEmailRequestSchema = z
  .object({
    emailAddress: z.string(),
  })
  .catchall(z.unknown() as z.ZodType);
export type LogInByEmailRequest = z.infer<typeof logInByEmailRequestSchema>;

export const loginByEmailResponseSchema = z
  .object({})
  .catchall(z.unknown() as z.ZodType);
export type LoginByEmailResponse = z.infer<typeof loginByEmailResponseSchema>;

export const hosDashboardResponseSchema = z.union([
  hosDashboardSuccessResponseSchema,
  Underwrite_Core_Dashboard.dashboardErrorResponseSchema,
]);
export type HosDashboardResponse = z.infer<typeof hosDashboardResponseSchema>;
