import { v1 as uuidv1 } from 'uuid';
import { logError } from 'lib/logger';

function getIsPointInternal(host) {
  const isPointInternal =
    host?.match(/localhost(:\d+)?$/) && process.env.NODE_ENV === 'development';
  return isPointInternal || host?.match(/(point|equitycushion)\.(com|dev)$/);
}

// hotfix - https://pointdf.atlassian.net/browse/PPC-1246
function getIsPrequalHost(host) {
  const isPointInternal =
    host?.match(/localhost(:\d+)?$/) && process.env.NODE_ENV === 'development';
  return isPointInternal || host?.match(/get\.point\.(com|dev)$/);
}

// https://pointdf.atlassian.net/browse/PPC-1189
function attachRequestId(request) {
  const host = new URL(request.url)?.host;
  const isPrequalHost = getIsPrequalHost(host);

  if (isPrequalHost) {
    // eslint-disable-next-line no-param-reassign
    request.headers['X-Request-ID'] = uuidv1();
  }
  return request;
}

/**
 * Sets `withCredentials=true` on requests to Point services to ensure that cookies are passed
 * correctly.
 *
 * @param {RequestConfig} request The Axios RequestConfiguration prior to be sent.
 * @returns {RequestConfig} The modified request
 */
function makeInternalRequestsWithCredentials(request) {
  const host = new URL(request.url)?.host;
  const isPointInternal = getIsPointInternal(host);

  if (isPointInternal && request.withCredentials !== false) {
    // the purpose of this function is to modify the function parameter directly
    // eslint-disable-next-line no-param-reassign
    request.withCredentials = true;
  }

  return request;
}

// https://pointdf.atlassian.net/browse/PPC-1170
function logErrorWithContext(error) {
  const response = error?.response;
  const errorMessage = response?.statusText || error?.message || 'No error given in response';
  const endPoint = response?.config?.url || error?.config?.url || 'No endPoint given in response';
  const payload =
    response?.config?.data ||
    error?.config?.data ||
    response?.data ||
    'No payload given in response';
  const statusCode = response?.status || 'No statusCode given in response';
  const requestId = response?.config?.headers['X-Request-ID'];

  logError({
    eventType: 'axiosInterceptor',
    detail: {
      message: errorMessage,
      endPoint,
      payload,
      statusCode,
      requestId,
    },
  });

  throw error;
}

export { attachRequestId, makeInternalRequestsWithCredentials, logErrorWithContext };
