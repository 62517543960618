import * as React from 'react';
import { useRef, useState } from 'react';
import { Button, Style } from '@pointdotcom/pds';
import FileInput from 'components/FileInput';
import i18n from './i18n';
import { UploadIcon } from './icons';
import * as styles from './styles';

type FileUploadAreaProps = Pick<
  React.ComponentProps<typeof FileInput>,
  'onAddFiles' | 'contentTypes'
> & {
  compact?: boolean;
};

// See: https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/types#value
const DATA_TRANSFER_TYPE_FILES = 'Files';

export default function FileUploadArea({
  onAddFiles,
  contentTypes,
  compact = false,
}: FileUploadAreaProps): JSX.Element {
  const dragAreaRef = useRef<null | HTMLDivElement>(null);
  const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);

  const handleDragEnter: React.DragEventHandler = (e) => {
    const { dataTransfer } = e;
    if (!dataTransfer.types.includes(DATA_TRANSFER_TYPE_FILES)) {
      return;
    }

    e.preventDefault();

    setIsDraggingOver(true);
    dataTransfer.dropEffect = 'copy';
  };

  const handleDragLeaveOrEnd: React.DragEventHandler = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
  };

  const handleDrop: React.DragEventHandler = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);

    const { dataTransfer } = e;
    const files = [...dataTransfer.files];
    if (!dataTransfer.types.includes(DATA_TRANSFER_TYPE_FILES)) {
      return;
    }

    if (files.length > 0) {
      onAddFiles(files);
    }
  };

  const fileInputRef = React.useRef<null | React.ComponentRef<typeof FileInput>>(null);

  return (
    <styles.FileUploadAreaStyle
      ref={dragAreaRef}
      isDraggingOver={isDraggingOver}
      compact={compact}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragEnter}
      onDragLeave={handleDragLeaveOrEnd}
      onDragEnd={handleDragLeaveOrEnd}
      onDrop={handleDrop}
      onClick={() => fileInputRef.current?.open()}
    >
      <FileInput ref={fileInputRef} multiple onAddFiles={onAddFiles} contentTypes={contentTypes} />
      <styles.FileUploadIconAndTextStyle>
        <UploadIcon />
        {i18n.dragAndDropAFile}
      </styles.FileUploadIconAndTextStyle>
      <Button
        styleType={Style.Dark}
        onClick={(e) => {
          e.stopPropagation();
          fileInputRef.current?.open();
        }}
      >
        {i18n.browse}
      </Button>
    </styles.FileUploadAreaStyle>
  );
}
