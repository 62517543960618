import styled from 'styled-components';
import {
  AutocompleteStyles,
  ButtonStyles,
  HeaderStyles,
  HelpTextStyles,
  InputStyles,
  LogoStyles,
  mixins,
} from '@pointdotcom/pds';
import { LogoBarStyle } from 'components/LogoBar/styles';
import { MainFooterStyle } from 'components/prequal/MainFooter/styles';
import { GraphicStyle } from '../Graphic/styles';

const avif = 'https://cdn.point.com/landingPage/textureGray.avif';
const webp = 'https://cdn.point.com/landingPage/textureGray.webp';

export const FOCUS_ANIM_SPEED_MS = 300;
export const FOCUS_ANIM_DELAY_MS = 400;

export const CallOutSectionStyle = styled.section.attrs({ className: 'CallOutSectionStyle' })`
  position: relative;
  /* margin-bottom: 5vh; */
  > div {
    box-shadow: 0 0 37px ${(props) => mixins.rgba(props.theme.Color.Gray4, 0.17)};
    padding: 3rem;
    position: relative;
    z-index: 2;
    background: white;
  }
  &:before {
    background-image: image-set(url(${avif}) type('image/avif'), url(${webp}) type('image/webp'));
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-position-x: left;
    content: '';
    display: block;
    position: absolute;
    width: 275px;
    height: 190px;
    top: 50%;
    left: -24px;
    z-index: 1;
    opacity: 0.4;
    transform: scale(1) translateY(-50%);
    transform-origin: 50% 0%;
  }

  form {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    > * {
      &:first-child {
        margin-right: 1rem;
        flex: 3;
      }
    }
  }
  ${InputStyles.InputContainerStyle} {
    margin: 0;
  }

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    margin-bottom: 0;
    &:before {
      display: none;
    }
  }

  @media (max-width: 375px) {
    > div {
      padding: 2.2rem 2.4rem;
    }
    ${InputStyles.InputContainerStyle} {
      margin-bottom: 0.6rem;
    }
  }
`;

export const CalloutListStyle = styled.ul.attrs({ className: 'CalloutListStyle' })`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 4vh;
  li {
    display: flex;
    flex-flow: row nowrap;
    border-right: 1px solid ${(props) => props.theme.line.color.default};
    padding: 1% 1.8% 1.8%;
    min-width: 200px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      border: none;
      padding-right: 0;
    }
    aside {
      display: block;
      padding-right: 1rem;
      i {
        width: 22px;
        height: 22px;
        border: 1px solid ${(props) => props.theme.line.color.default};
        path {
          stroke: ${(props) => props.theme.Color.PointBlack};
        }
      }
    }
    span {
      font-size: 1.4rem;
      font-family: ${(props) => props.theme.fonts.serif.name};
      margin-top: -0.27em;
    }
  }
  @media (max-width: 1000px) {
    li {
      min-width: 140px;
      border: none;
      padding-left: 0;
      padding-bottom: 0;
      span {
        font-size: 1.1rem;
      }
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    flex-flow: column nowrap;
    li {
      aside {
        padding-right: 0.5rem;
        i {
          border: none;
        }
      }
      span {
        margin-top: 0;
      }
    }
  }
  @media (max-width: 460px) {
    li {
      padding-top: 0;
      padding-bottom: 0;
      aside {
        padding-right: 0.2rem;
        margin-left: -5px;
      }
      span {
        font-family: inherit;
        font-size: ${(props) => mixins.getRemFromPx(14, props.theme.globals.baseFontSize.default)};
      }
    }
  }
`;

// prettier-ignore
// prettier-ignore needed for line 381 (wrapping breaks the line and throws)
export const LockKeyLandingPageStyle = styled.div.attrs({ className: 'LockKeyLandingPageStyle' })`
  position: relative;
  .headerTop,
  > footer {
    transition: all ${FOCUS_ANIM_SPEED_MS}ms ease-in-out ${FOCUS_ANIM_DELAY_MS};
  }
  .headerTop {
    max-height: 500px;
  }
  .graphicWrapper {
    position: absolute;
    z-index: -1;
    width: 100%;
    overflow: hidden;
    height: 300px;
  }
  ${AutocompleteStyles.AutoCompleteStyle} {
    margin-bottom: 0;
  }
  ${GraphicStyle} {
    left: 45%;
    width: 770px;
    height: 188px;
    z-index: 1;
    max-width: 769px;
    max-height: 188px;
    top: 0;
  }
  form {
    align-items: flex-start;
  }
  > header {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    position: relative;
    z-index: 2;
    padding: 7vh 0;
    > * {
      width: 100%;
    }
    ${HeaderStyles.HeaderStyle} {
      &.splash {
        margin-bottom: 2em;
        > * {
          font-size: 5rem;
        }
      }
    }
    mark {
      background: none;
      border-bottom: 6px solid ${(props) => props.theme.Color.Yorange};
      color: inherit;
    }
    sup {
      font-size: 0.28em;
      margin-left: 0.25em;
      margin-top: -1em;
      position: absolute;
    }
    ${LogoStyles.LogoStyle} {
      margin-bottom: 5%;
    }
    ${LogoBarStyle} {
      margin-top: 2rem;
    }
  }
  @media (max-width: 1300px) {
    ${GraphicStyle} {
      width: 59vw;
      height: 14.4vw;
    }
  }
  @media (max-width: 1075px) {
    > header {
      ${HeaderStyles.HeaderStyle} {
        &.splash {
          > * {
            font-size: 7.3vw;
          }
        }
      }
    }
    ${GraphicStyle} {
      width: 70vw;
      height: 17.1vw;
      left: 35%;
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    ${LogoBarStyle} {
      margin-top: 0;
    }
    &.fieldFocused-enter,
    &.fieldFocused-enter-done {
      .hideWhenFieldFocused {
        display: none;
      }
      .graphicWrapper {
        position: fixed;
        height: 100px;
      }
      > header {
        justify-content: flex-start !important;
      }
      ${CallOutSectionStyle} > div {
        box-shadow: 0 -12px 22px -9px ${(props) => mixins.rgba(props.theme.Color.Gray4, 0.17)};
        padding: 0;
      }
      .headerTop,
      > footer {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
      }
      ${ButtonStyles.ButtonBaseStyle} {
        display: none;
      }

      /* TODO: If used again, put in PDS */
      ${AutocompleteStyles.AutoCompleteStyle} {
        .menu {
          position: static !important;
          border: none;
          border-bottom: 1px solid ${(props) => props.theme.line.color.default};
          > * {
            padding-left: ${(props) => props.theme.container.gutter.default};
            padding-right: ${(props) => props.theme.container.gutter.default};
            &:first-child {
              border-top: none;
            }
          }
          &:before {
            content: 'Suggestions:';
            padding-left: ${(props) => props.theme.container.gutter.default};
            padding-right: ${(props) => props.theme.container.gutter.default};
            color: ${(props) => props.theme.Color.GrayLightestAccessible};
            font-size: ${(props) => props.theme.helpText.fontSize.default.mobile};
          }
          &:after {
            background-position: ${(props) => props.theme.container.gutter.default} center;
          }
        }
        > div ${HelpTextStyles.HelpTextStyle} {
          padding: ${(props) => props.theme.container.gutter.default};
          padding-bottom: 0;
        }
        &.hasHelpText {
          ${InputStyles.InputContainerStyle} {
            padding-top: 0;
          }
        }
      }
      ${InputStyles.InputContainerStyle} {
        padding: ${(props) => props.theme.container.gutter.default};
        padding-bottom: 0;
      }
    }

    ${AutocompleteStyles.AutoCompleteStyle} {
      flex: 100%;
      width: 100%;
    }

    ${InputStyles.InputContainerStyle} {
      margin-bottom: 1rem;
    }

    ${GraphicStyle} {
      height: 18.7vw;
      width: 63.1vw;
      right: -9.9%;
      left: auto;
    }

    form {
      align-items: normal;
      flex-flow: column nowrap;
    }

    > header {
      margin-bottom: -1px;
      padding-bottom: ${({ showLogoBar }) => (showLogoBar ? '2vh' : null)};
      br {
        display: none;
      }
      mark {
        border-width: 5px;
      }
      ${HeaderStyles.HeaderStyle} {
        &.splash {
          > * {
            font-size: 7vw;
          }
        }
      }
    }
  }
  ${MainFooterStyle} {
    padding-bottom: 0;
    border-bottom: 1px solid ${(props) => props.theme.line.color.default};
  }
  .disclaimerContainer {
    ol {
      display: block;
      margin: 0;
      padding: 0;
      font-size: ${(props) => mixins.getRemFromPx(12, props.theme.globals.baseFontSize.default)};
      color: ${(props) => props.theme.Color.GrayLightestAccessible};
      padding: 36px 0;
      margin-bottom: 9vh;
    }
  }
  @media (max-width: 575px) {
    ${GraphicStyle} {
      height: 23vw;
      width: 77.9vw;
      right: -12.2%;
    }
  }
  // Break sections to specific porportions of view
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) and (max-height: 845px) {
    > header {
      height: ${({ showLogoBar }) => (showLogoBar ? 'auto' : '78vh')};
      padding-bottom: 0;
      justify-content: flex-end;
    }
    .tpContainer {
      height: calc(12vh + 1px);
    }
  }

  @media (max-width: 450px) and (max-height: 845px) {
    ${GraphicStyle} {
      height: 33vw;
      width: 110.9vw;
      right: -14.4%;
    }
  }

  @media (max-height: 665px) {
    > header {
      justify-content: flex-start;
      height: auto;
    }
  }

  @media (max-height: 678px) {
    ${GraphicStyle} {
      height: 22vw;
      width: 74vw;
      right: -10.3%;
    }
  }

  @media (max-width: 430px) {
    > header {
      ${HeaderStyles.HeaderStyle} {
        margin-bottom: 1.3em;
        > * {
          font-size: 1.89rem;
        }
      }
    }
  }

  @media (max-width: 375px) and (max-height: 690px) {
    > header {
      ${HeaderStyles.HeaderStyle} {
        > * {
          font-size: 1.5rem;
        }
      }
    }
  }
`;
