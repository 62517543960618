import React from 'react';
import 'focus-visible';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { FocusVisible, ResetStyle, defaultTheme } from '@pointdotcom/pds';
import configureAxios from 'config/axios';
import { ErrorPageTemplate } from 'containers/ErrorPage';
import { ErrorBoundary } from 'lib/bugsnagClient';
import reduxStore from 'store/index';
import { shouldForwardProp } from 'styleHelpers';
import { AppResetStyle } from 'styles/';
import App from './App';

// Report build info on startup
console.info('Build info', BUILD_METADATA);

// configure the Axios client
configureAxios();

// use mock server for development
async function enableMocking(): Promise<void> {
  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_USE_MOCK_SERVER === 'true') {
    const { mockUploads, worker, unhandledRequestHandler } = await import('./mocks/browser');

    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    await worker.start({ onUnhandledRequest: unhandledRequestHandler });
    mockUploads();
  }
}

// ErrorPageTemplate must be used so that we can use a version of ErrorPage without hooks.
// This is necessary since we render this page as an error boundary which
// is outside the the scope of a router. Many of our hooks use useParams,
// which will throw if used outside a router context
const ErrorFallback = () => (
  <StyleSheetManager shouldForwardProp={shouldForwardProp}>
    <ThemeProvider theme={defaultTheme}>
      <ResetStyle />
      <ErrorPageTemplate showMainHeader={false} showFooter={false} />
    </ThemeProvider>
  </StyleSheetManager>
);

// eslint-disable-next-line react/prefer-stateless-function
export default class Index extends React.Component {
  render() {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ReduxProvider store={reduxStore}>
          <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <ThemeProvider theme={defaultTheme}>
              <FocusVisible />
              <ResetStyle />
              <AppResetStyle />
              <App />
            </ThemeProvider>
          </StyleSheetManager>
        </ReduxProvider>
      </ErrorBoundary>
    );
  }
}

const container = document.getElementById('root') || document.createElement('div');
const root = createRoot(container);
enableMocking().then(() => root.render(<Index />));
