import styled from 'styled-components';
import {
  CheckboxStyles,
  Container,
  ContainerStyles,
  Modal,
  ModalStyles,
  TextAreaStyles,
} from '@pointdotcom/pds';

export const ToAccountManagerSectionStyle = styled(Container).attrs({
  className: 'ToAccountManagerSectionStyle',
})`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 2rem;
  --image-size: clamp(60px, 20vw, 90px);
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  > * {
    &:last-child {
      display: flex;
      flex-flow: column;
      gap: 0.2rem;
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  img {
    width: var(--image-size);
    height: var(--image-size);
    max-width: 100%;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
  }
`;

export const CheckboxContainerStyle = styled(Container).attrs({
  className: 'CheckboxContainerStyle',
})`
  // TODO: https://pointdf.atlassian.net/browse/CE-1204
  display: flex;
  flex-flow: column;
  gap: 0.8rem;
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    position: relative;
    gap: 0;
    ${CheckboxStyles.CheckboxLabelStyle} {
      width: 100%;
      position: relative;
      border-radius: 0;
      border-left-width: 0;
      border-right-width: 0;
      border-color: ${({ theme }) => theme.Color.Gray1};
    }
    > * {
      &:not(:first-child) {
        margin-top: -1px;
      }
    }
  }
`;

export const SubmitButtonContainerStyle = styled(Container).attrs({
  className: 'SubmitButtonContainerStyle',
})`
  display: flex;
  flex-flow: column;
  gap: 1em;
`;

export const FormSectionStyle = styled.form.attrs({ className: 'FormSectionStyle' })`
  display: flex;
  flex-flow: column;
  gap: 2rem;
  ${TextAreaStyles.TextAreaStyle} {
    margin-top: 1rem;
  }
`;

export const DefaultContentContainerStyle = styled.div.attrs({
  className: 'DefaultContentContainerStyle',
})`
  display: flex;
  flex-flow: column;
  gap: 2.3rem;
`;

export const EmailSentContainerStyle = styled(Container).attrs({
  className: 'EmailSentContainerStyle',
})`
  display: flex;
  flex-flow: column;
  gap: 2rem;
  align-items: flex-start;
  img {
    position: relative;
    width: 40%;
    max-width: 210px;
  }
  a {
    display: inline-block;
    margin-top: 1em;
  }
`;

export const HaveAQuestionModalStyle = styled(Modal).attrs({
  className: 'HaveAQuestionModalStyle',
})`
  --containerPadding: 15%;
  ${ContainerStyles.ContainerStyle} {
    width: 100%;
    padding: 0 var(--containerPadding);
  }

  // TODO: https://pointdf.atlassian.net/browse/CE-1205
  ${ModalStyles.ModalContainerInnerStyle} {
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    ${ModalStyles.ModalContainerInnerStyle} {
      padding-bottom: var(--containerPadding); // TODO: https://pointdf.atlassian.net/browse/CE-1205
    }
    ${CheckboxContainerStyle} {
      padding: 0;
    }
    ${CheckboxStyles.CheckboxLabelStyle} {
      padding: 1.2rem var(--containerPadding);
    }
  }
`;
