import { z } from 'zod';
import { FormTemplateFunction } from 'containers/hei/ApplicationPage/constants';
import {
  applicantSignatureSchema,
  heiApplicationConsentSchema,
  socialSecurityNumberSchema,
} from 'services/apiTypes/homeownerTypes';
import SSNField from '../../FormComponents/SSNField';
import { SingleSpaceMask } from '../../constants';
import ConsentList from './ConsentList';
import i18n from './i18n';

const reviewTemplate: FormTemplateFunction = () => {
  return [
    {
      key: 'auth1',
      cols: 1,
      fields: [
        {
          path: 'consents',
          Component: ConsentList,
          validation: ({ fieldValue } = {}) => {
            const result = heiApplicationConsentSchema.safeParse(fieldValue);
            if (result.success) {
              return true;
            }

            return result.error;
          },
        },
      ],
    },
    {
      key: 'signatureAndSSN',
      cols: 3,
      fields: [
        {
          path: 'applicant.signature',
          label: i18n.electronicSig,
          props: {
            placeholder: i18n.typeYour,
            mask: SingleSpaceMask,
          },
          validation: ({ fieldValue, getFieldValueByPath } = {}) => {
            let mismatch = false;

            // Let zod handle the basics on whether the value has even been filled in, etc
            const baseValidation = applicantSignatureSchema.safeParse(fieldValue);
            if (!baseValidation.success) {
              return baseValidation.error;
            }

            // Then dig in to see if we have a valid signature of "First Middle|Initial|<blank> Last"
            const ciFirstName = String(
              getFieldValueByPath?.('applicant.contactInfo.firstName')
            ).toLowerCase();
            const ciMiddleName = String(
              getFieldValueByPath?.('applicant.contactInfo.middleName')
            ).toLowerCase();
            const ciLastName = String(
              getFieldValueByPath?.('applicant.contactInfo.lastName')
            ).toLowerCase();

            let signedName = String(fieldValue).toLowerCase().trim();

            // We need at least one space in there somewhere
            mismatch = !/\s/.test(signedName);

            // Identify signature elements and remove them as we go
            // Starts with first name
            const firstRE = new RegExp(`^${ciFirstName}`);
            if (firstRE.test(signedName)) {
              signedName = signedName.replace(firstRE, '').trim();
            } else {
              mismatch = true;
            }

            // Ends with last name
            const lastRE = new RegExp(`${ciLastName}$`);
            if (lastRE.test(signedName)) {
              signedName = signedName.replace(lastRE, '').trim();
            } else {
              mismatch = true;
            }

            // What remains (if anything, as middle name is optional) should be the middle name or initial
            const middleInitialRegex = new RegExp(`^${ciMiddleName.charAt(0)}\\.?$`);
            if (signedName && signedName !== ciMiddleName && !middleInitialRegex.test(signedName)) {
              mismatch = true;
            }

            if (mismatch) {
              return new z.ZodError([
                {
                  code: z.ZodIssueCode.custom,
                  path: [],
                  message: i18n.signatureMismatch,
                },
              ]);
            }
            return true;
          },
        },
        {
          path: 'applicant.ssn',
          label: i18n.socialSecurity,
          Component: SSNField,
          validation: ({ fieldValue, getFieldValueByPath } = {}) => {
            const baseValidation = socialSecurityNumberSchema.safeParse(fieldValue);
            if (!baseValidation.success) {
              return baseValidation.error;
            }

            // If SSN fields do not match, mark both fields as invalid since we don't know which one is wrong
            const ssnConfirm = getFieldValueByPath?.('applicant.ssnConfirm');
            if (ssnConfirm !== fieldValue) {
              return new z.ZodError([
                {
                  code: z.ZodIssueCode.custom,
                  path: [],
                  message: i18n.ssnMismatch,
                },
              ]);
            }

            return true;
          },
        },
        {
          path: 'applicant.ssnConfirm',
          label: i18n.confirmSocialSecurity,
          Component: SSNField,
          validation: ({ fieldValue, getFieldValueByPath } = {}) => {
            const ssnEntry = getFieldValueByPath?.('applicant.ssn');
            if (!fieldValue || fieldValue !== ssnEntry) {
              return new z.ZodError([
                {
                  code: z.ZodIssueCode.custom,
                  path: [],
                  message: i18n.ssnMismatch,
                },
              ]);
            }
            return true;
          },
        },
      ],
    },
  ];
};

export default reviewTemplate;
