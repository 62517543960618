import { PolicyType } from './constants';

export const policyLinkTexts = {
  cellularPhoneContactPolicyLinkText: 'Cellular Phone Contact Policy',
  termsOfUseLinkText: 'Terms of Use',
  creditReportPolicyLinkText: 'Credit Report Policy',
  privacyPolicyLinkText: 'Privacy Policy',
  ecesLinkText: 'Electronic Communications and Electronic Signatures Policy',
};

// generic policy texts
export const policyTexts = {
  [PolicyType.Contact]:
    'Point’s %cellularPhoneContactPolicyLinkText%, %termsOfUseLinkText%, %privacyPolicyLinkText% and %ecesLinkText%',
  [PolicyType.CreditReport]:
    'Point’s %creditReportPolicyLinkText%. I authorize Point to obtain my credit report to match me to potential offers available by Point.',
  [PolicyType.ECES]:
    'I have read and agree to Point’s %ecesLinkText% and consent to receive all disclosures electronically and have the ability to do so.',
  [PolicyType.Share]:
    'I authorize Point to share my information including my name and address with general contractors or referring partners that I may be working with for the sole purpose of providing the partner with the status of my transaction.',
  [PolicyType.ShareLender]:
    'I authorize Point to share my information including name, address, and prequalified amount with the first mortgage lender that I may be working with for the sole purpose of providing the partner with the status of my application.',
  [PolicyType.Prequalification]:
    'I understand this Prequalification request is neither an application for credit nor a guarantee of approval.',
};

// For inline messages refering to elements around surrounding text
export const inlinePolicyTexts = {
  contact:
    'I agree that Point may call or text me with an automated telephone dialing system at this number. I have read and agree to Point’s full %cellularPhoneContactPolicyLinkText%.',
};

export default {
  policyLinkTexts,
  policyTexts,
  inlinePolicyTexts,
};
