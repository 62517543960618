import * as React from 'react';
import { useCallback, useLayoutEffect, useState } from 'react';
import {
  AirgapAPI,
  ConsentChangeEventDetails,
  ConsentManagerAPI,
} from '@transcend-io/airgap.js-types';
import { Container, DirectionAndPlacement, HelpText, directory } from '@pointdotcom/pds';
import i18n from './i18n';
import privacyOptionsIconSvg from './images/privacyOptionsIcon.svg';
import * as styles from './styles';

const { pointLink } = directory;

interface AirgapAPIs {
  readonly airgap?: AirgapAPI;
  readonly transcend?: ConsentManagerAPI;
}

const getConsentAPIs = (): AirgapAPIs => {
  const { airgap, transcend } = window as unknown as AirgapAPIs;

  return {
    transcend,
    airgap: typeof airgap?.getConsent === 'function' ? airgap : undefined,
  };
};

function useConsent({ airgap, onChoicesUpdated }: AirgapAPIs & { onChoicesUpdated: () => void }) {
  const [consent, setConsent] = useState(() => airgap?.getConsent());

  useLayoutEffect(() => {
    if (airgap != null) {
      const listener = (event: Event) => {
        const { detail } = event as { detail?: ConsentChangeEventDetails };
        if (detail?.consent != null) {
          setConsent(detail.consent);
        }
        if (detail?.changes != null) {
          onChoicesUpdated();
        }
      };
      airgap.addEventListener('consent-change', listener);
      return () => {
        airgap.removeEventListener('consent-change', listener);
      };
    }
  }, [airgap, onChoicesUpdated]);

  return consent;
}

interface ConsentManagementLinkProps {
  styleAlign?: DirectionAndPlacement.Left | DirectionAndPlacement.Center;
}

export default function ConsentManagementLink({
  styleAlign = DirectionAndPlacement.Left,
}: ConsentManagementLinkProps): null | JSX.Element {
  const { airgap, transcend } = getConsentAPIs();
  const [choicesUpdated, setChoicesUpdated] = useState<boolean>(false);

  useConsent({
    airgap,
    onChoicesUpdated: useCallback(() => {
      setChoicesUpdated(true);
    }, []),
  });

  const handleClick = useCallback(() => {
    transcend?.showConsentManager({ viewState: 'CompleteOptions' });
  }, [transcend]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleClick();
      }
    },
    [handleClick]
  );

  if (airgap == null || transcend == null || !airgap.getRegimes().has('CPRA')) {
    return null;
  }

  const linkLabel: string = (() => {
    if (choicesUpdated) {
      return i18n.choicesUpdated;
    }
    return i18n.yourPrivacyChoices;
  })();

  return (
    <styles.ContentManagementSectionStyle>
      <HelpText noMargin>
        <Container>
          <styles.PipedItemRowStyle styleAlign={styleAlign}>
            <styles.ContentManagementLinkStyle
              role="button"
              onClick={handleClick}
              tabIndex={0}
              onKeyDown={handleKeyDown}
            >
              <img src={privacyOptionsIconSvg} alt={i18n.optOutIcon} />
              {linkLabel}
            </styles.ContentManagementLinkStyle>
            <styles.ContentManagementLinkStyle
              href={new URL('#California-Privacy-Notice', pointLink.terms.privacy).toString()}
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.noticeAtCollection}
            </styles.ContentManagementLinkStyle>
          </styles.PipedItemRowStyle>
        </Container>
      </HelpText>
    </styles.ContentManagementSectionStyle>
  );
}
