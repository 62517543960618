import React, { useRef, useState } from 'react';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import { posthogFeatureFlagDefinitions } from 'lib/featureFlags/featureFlagDefinitions';
import { FeatureFlagValue } from 'lib/featureFlags/featureFlagObjects';
import flagIcon from './flag.svg';
import * as styles from './styles';

interface FlagData {
  key: string;
  values: Array<FeatureFlagValue<FeatureFlag>>;
}

function setCookie(name: string, value: string) {
  document.cookie = `${name}=${value}`;
}

const Flag = ({ flagName, data }: { flagName: FeatureFlag; data: FlagData }) => {
  const [flagValue, setFlagValue] = useState(useFeatureFlag(flagName));

  function handleClick(value: string | boolean) {
    setCookie(data.key, String(value));
    setFlagValue(value as FeatureFlagValue<FeatureFlag>);
  }

  return (
    <styles.FlagStyle>
      <h3>{flagName}</h3>
      <styles.FlagKeyStyle>{data.key}</styles.FlagKeyStyle>
      <styles.FlagValuesStyle>
        {data.values.map((value) => (
          <styles.FlagValueOptionStyle
            key={String(value)}
            current={value === flagValue}
            onClick={() => handleClick(value)}
          >
            {String(value)}
          </styles.FlagValueOptionStyle>
        ))}
      </styles.FlagValuesStyle>
    </styles.FlagStyle>
  );
};

function FeatureFlags() {
  return (
    <styles.FlagContainerStyle>
      {Object.entries(posthogFeatureFlagDefinitions).map(([flagName, flagData]) => (
        <Flag key={flagName} flagName={flagName as FeatureFlag} data={flagData as FlagData}></Flag>
      ))}
    </styles.FlagContainerStyle>
  );
}

export function Devtools() {
  const dialogRef = useRef<HTMLDialogElement>(null);

  function showDevtools(show: boolean) {
    if (show) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }

  function handleClick(show: boolean) {
    if (document.startViewTransition) {
      document.startViewTransition(() => {
        showDevtools(show);
      });
    } else {
      showDevtools(show);
    }
  }

  if (process.env.REACT_APP_ENABLE_DEVTOOLS !== 'true') {
    return null;
  }

  console.info('Initializing devtools...');

  return (
    <styles.DevtoolsStyle>
      <styles.OpenButtonStyle onClick={() => handleClick(true)}>
        <img src={flagIcon} alt="flag" />
      </styles.OpenButtonStyle>
      <styles.DialogStyle ref={dialogRef}>
        <styles.DialogHeaderStyle>
          <h1>Feature Flags</h1>
          <styles.CloseButtonStyle onClick={() => handleClick(false)}>X</styles.CloseButtonStyle>
        </styles.DialogHeaderStyle>
        <FeatureFlags />
      </styles.DialogStyle>
    </styles.DevtoolsStyle>
  );
}
