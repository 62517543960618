import React, { useCallback, useEffect, useRef, useState } from 'react';
import scriptjs from 'scriptjs';
import { LinkButton } from '@pointdotcom/pds';
import bugsnagClient from 'lib/bugsnagClient';
import { getUnderwriteHost } from 'lib/hosts';
import i18n from './i18n';
import { DocumentViewerStyle, DownloadLinkStyle, ViewerErrorStyle } from './styles';

const pdfJsScripts = [
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/1.1.114/pdf.min.js',
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/1.1.114/compatibility.min.js',
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/1.1.114/pdf.combined.min.js',
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/1.1.114/pdf.worker.min.js',
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/1.1.114/pdf_viewer.min.js',
];

const PdfLoadError = () => (
  <ViewerErrorStyle>
    <span>Whoops, we were unable to display your documents due to a technical glitch. </span>
    <LinkButton onClick={() => window.location.reload()}>Click here to try again</LinkButton>
    <span> or you can download them instead.</span>
  </ViewerErrorStyle>
);

const browserCanRenderPdf = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  // obsolete browsers fail
  if (/(msie|edge)\//gi.test(userAgent)) {
    return false;
  }

  // mobile fails
  if (/mobile/gi.test(userAgent)) {
    return false;
  }

  // modern browsers should pass
  return /(safari|gecko|chrome)\//gi.test(userAgent);
};

const UnderwriteFollowUpDocumentViewer = ({ followUp, followUpId }) => {
  const [pdfLoadError, setPdfLoadError] = useState(false);
  const viewerRef = useRef();
  const accessUrl = followUp.getPdfAccessUrl();

  const showPdfInViewer = useCallback(async () => {
    try {
      const container = viewerRef.current;
      const pdfViewer = new window.PDFJS.PDFViewer({
        container,
      });

      container.addEventListener('pagesinit', () => {
        // we can use pdfViewer now, e.g. let's change default scale.
        pdfViewer.currentScaleValue = 'page-width';
      });

      const pdfDocument = await window.PDFJS.getDocument({
        url: accessUrl,
        withCredentials: true,
      });

      if (pdfDocument) {
        pdfViewer.setDocument(pdfDocument);
      }
    } catch {
      setPdfLoadError(true);
    }
  }, [accessUrl]);

  const attemptPdfLoad = useCallback(() => {
    /* FUTURE:
      Would like to replace this raw library usage with react-pdf, but as of this date (March 3rd) react-pdf does not build properly.
      This problem has been fixed in pdf.js but the release has not yet occurred and may be a few months out.
    */
    try {
      if (window.PDFJS && window.PDFJS.PDFViewer) {
        showPdfInViewer(accessUrl);
      } else {
        setPdfLoadError(true);
      }
    } catch (e) {
      setPdfLoadError(true);
    }
  }, [accessUrl, showPdfInViewer]);

  useEffect(() => {
    async function loadPdfWithLibs() {
      await new Promise((resolve) => {
        if (!browserCanRenderPdf()) {
          scriptjs(pdfJsScripts, resolve);
        } else {
          resolve();
        }
      });
      attemptPdfLoad();
    }

    loadPdfWithLibs().catch((e) => {
      bugsnagClient.notify(`DocumentViewer PDF error: ${e.toString()}`);
      setPdfLoadError(true);
    });
  }, [attemptPdfLoad]);

  if (!followUp) {
    return null;
  }

  if (!accessUrl) {
    return null;
  }

  const losHost = getUnderwriteHost();
  const pdfDownloadUrl = `${losHost}/followup/${followUpId}?pdf=download`;
  let embeddedPdf;

  // Safari crashes when accessUrl is null (while loading)
  if (accessUrl && browserCanRenderPdf()) {
    embeddedPdf = (
      <object
        data={accessUrl}
        type="application/pdf"
        width="100%"
        height="640px"
        aria-label="Closing disclosure PDF"
      >
        <PdfLoadError />
      </object>
    );
  } else {
    embeddedPdf = (
      <div ref={viewerRef} title="Closing disclosure PDF" width="100%" height="640px">
        <div className="pdfViewer" />
        {pdfLoadError ? <PdfLoadError /> : null}
      </div>
    );
  }

  // stylesheet is here to only load it when necessary
  return (
    <DocumentViewerStyle>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/1.1.114/pdf_viewer.min.css"
      />
      {embeddedPdf}
      <DownloadLinkStyle href={`${pdfDownloadUrl}`}>{i18n.downloadYourDocuments}</DownloadLinkStyle>
    </DocumentViewerStyle>
  );
};

export default UnderwriteFollowUpDocumentViewer;
