import React, { useEffect, useRef } from 'react';
import FullScreenLoading from 'components/FullScreenLoading';
import ErrorPage, { ErrorType } from 'containers/ErrorPage';
import { getPathFromPage, pages } from 'containers/helpers';
import { useDashboardData } from 'containers/prequal/hooks';
import { Redirect } from 'containers/routerHelpers';
import { usePostHogEvents } from 'lib/posthogEvents';
import DashboardModel from 'models/DashboardModel';
import { DocketStatus } from 'models/DocketModel';

interface AuthenticatedDashboardProps {
  children: React.ReactNode | ((props: { dashboard: DashboardModel | null }) => React.ReactNode);
}

/* 
How to add auth to a page component - 

If dashboard model is NOT needed in page component:
<AuthenticatedDashboard><PageComponent /></AuthenticatedDashboard>

If dashboard model is needed in page component:
<AuthenticatedDashboard>{({dashboard}) => <PageComponent dashboard={dashboard}/>}</AuthenticatedDashboard>
*/

const AuthenticatedDashboard = ({ children }: AuthenticatedDashboardProps) => {
  const { dashboard, isLoading, isError } = useDashboardData();
  const posthogEvents = usePostHogEvents();

  const ref = useRef<null | HTMLDivElement>(null);

  const needsAuth = dashboard?.getNeedsAuth();
  const userEmail = dashboard?.getContact().email;
  const docketId = dashboard?.getDocket()?.id;

  useEffect(() => {
    if (needsAuth === false && userEmail) {
      posthogEvents.captureDashboardLoggedIn(
        { email: userEmail, docketId },
        { element: ref.current }
      );
    }
    if (needsAuth) {
      posthogEvents.clearDashboardLogin();
    }
  }, [docketId, needsAuth, posthogEvents, userEmail]);

  if (isLoading) {
    return <FullScreenLoading />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (dashboard?.docket?.status === DocketStatus.Inactive) {
    return <ErrorPage errorType={ErrorType.ApplicationExpired} />;
  }

  if (dashboard?.docket?.status === DocketStatus.Closed) {
    return (
      <ErrorPage errorType={ErrorType.ApplicationClosed} confetti={dashboard.docket?.funded} />
    );
  }

  if ([DocketStatus.Rejected, DocketStatus.Canceled].includes(dashboard?.docket?.status)) {
    return <ErrorPage errorType={ErrorType.ApplicationRejected} />;
  }

  if (needsAuth) {
    return <Redirect to={`${getPathFromPage(pages.DASHBOARD_LOGIN)}${document.location.search}`} />;
  }

  if (typeof children === 'function') {
    return <div>{children({ dashboard })}</div>;
  }

  return <div ref={ref}>{children}</div>;
};

export default AuthenticatedDashboard;
