import styled, { css } from 'styled-components';

const horizPadding = '20px';

const borderedCSS = css`
  border: 1px solid ${({ theme }) => theme.line.color.default};
  padding: 0.5rem ${horizPadding};
`;

export const BBBStyle = styled.a.attrs({ className: 'BBBStyle' })`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  ${({ bordered }) => (bordered ? borderedCSS : null)}
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    path {
      fill-opacity: 1;
      fill: ${({ theme }) => theme.Color.PointBlue};
    }
  }
  > * {
    padding: 0 ${horizPadding};
    &:first-child {
      padding-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid ${({ theme }) => theme.line.color.default};
    }
    &:last-child {
      padding-right: 0;
    }
  }
  svg {
    transform: scale(0.8);
  }
`;
