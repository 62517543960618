import * as React from 'react';
import * as styles from './styles';

export default function SectionCheckmark() {
  return (
    <styles.SectionCheckmarkStyle>
      <styles.SectionCheckmarkIconStyle width="14" height="12" viewBox="0 0 14 12">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.401062 6.41794L5.20314 11.0971L13.5989 2.91621C14.1337 2.39515 14.1337 1.55241 13.5989 1.03136C13.071 0.516892 12.2272 0.516892 11.6992 1.03136L5.20314 7.36116L2.30076 4.53308C1.77278 4.01862 0.929042 4.01862 0.401062 4.53308C-0.133687 5.05414 -0.133687 5.89688 0.401062 6.41794Z"
        />
      </styles.SectionCheckmarkIconStyle>
    </styles.SectionCheckmarkStyle>
  );
}
