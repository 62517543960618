import React from 'react';
import { Link, To } from 'react-router-dom';
import { getNavItemProp } from 'components/MainHeader/nav';
import { SubNavItem, SubNavProps } from 'components/SubNav';
import * as styles from './styles';

export type Crumb = SubNavItem;
export type BreadCrumbProps = Pick<SubNavProps, 'navProps'> & {
  navItems?: Array<SubNavItem>;
  currentCrumb?: string;
};

const BreadCrumbs = ({ navItems, navProps, currentCrumb }: BreadCrumbProps) => {
  const getHref = getNavItemProp('href', navProps);
  const getText = getNavItemProp('text', navProps);
  const separator = '/';

  return (
    <styles.BreadCrumbsStyle>
      {navItems?.length
        ? navItems.map((item) => {
            const href = getHref(item);
            const text = getText(item);
            return href && text ? (
              <React.Fragment key={text}>
                <Link to={href as To}>{text}</Link>
                {separator}
              </React.Fragment>
            ) : null;
          })
        : null}
      {currentCrumb ? <span>{currentCrumb}</span> : null}
    </styles.BreadCrumbsStyle>
  );
};

export default BreadCrumbs;
