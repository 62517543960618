import styled from 'styled-components';

export const DevtoolsStyle = styled.div.attrs({ className: 'DevtoolsStyle' })`
  position: relative;
`;

export const OpenButtonStyle = styled.button.attrs({ className: 'OpenButtonStyle' })`
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  margin: 0;
  padding: 0;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  --iconSize: 3rem;
  height: var(--iconSize);
  width: var(--iconSize);

  border: 3px solid ${({ theme }) => theme.Color.Yorange};
  background-color: ${({ theme }) => theme.Color.PointBlue};
  border-radius: 50%;
  cursor: pointer;

  img {
    width: 60%;
    aspect-ratio: 1/1;
  }

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    --iconSize: 2rem;
  }
`;

export const DialogStyle = styled.dialog.attrs({ className: 'DialogStyle' })`
  padding: 0;

  border: 3px solid ${({ theme }) => theme.Color.Yorange};
  background-color: ${({ theme }) => theme.Color.Gray4};
  border-radius: 1rem;

  scrollbar-width: none;
  overscroll-behavior: none;

  &[open] {
    display: grid;
    grid-template-rows: 5rem 1fr;
  }
`;

export const DialogHeaderStyle = styled.div.attrs({ className: 'DialogHeaderStyle' })`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.Color.PointBlue};
  color: ${({ theme }) => theme.Color.Yorange};
  border-bottom: 1px solid currentColor;
`;

export const CloseButtonStyle = styled.button.attrs({ className: 'CloseButtonStyle' })`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  border: none;
  border-radius: 0 0 0 1rem;
  background-color: ${({ theme }) => theme.Color.Yorange};
  color: ${({ theme }) => theme.Color.PointBlue};
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
`;

export const FlagContainerStyle = styled.div.attrs({ className: 'FlagContainerStyle' })`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 3rem;
  padding: 2rem;

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    grid-template-columns: 1fr;
  }
`;

export const FlagStyle = styled.div.attrs({ className: 'FlagStyle' })`
  color: ${({ theme }) => theme.Color.White};
  h3 {
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.Color.Yorange};
  }
`;

export const FlagKeyStyle = styled.span.attrs({ className: 'FlagKeyStyle' })`
  padding-left: 1rem;
  font-style: italic;
  font-size: 14px;
`;

export const FlagValuesStyle = styled.div.attrs({ className: 'FlagValuesStyle' })`
  display: flex;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
`;

export const FlagValueOptionStyle = styled.button.attrs({ className: 'FlagValueOptionStyle' })<{
  current: boolean;
}>`
  min-width: 6rem;
  background-color: transparent;
  padding: 0.5rem;
  border: 1px solid currentColor;
  border-radius: 4px;
  font-size: 1rem;

  color: ${({ theme, current }) => (current ? theme.Color.Yorange : theme.Color.White)};
  font-weight: ${({ current }) => (current ? 'bold' : null)};
  box-shadow: ${({ current }) => (current ? '0 0 8px #f4C65d88' : 'none')};
`;
