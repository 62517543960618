import React from 'react';
import Helmet from 'react-helmet';
import { IconName, templatedString } from '@pointdotcom/pds';
import AuthenticatedDashboard from 'components/AuthenticatedDashboard';
import DashboardMainHeader from 'components/DashboardMainHeader';
import { dashboardTaskCrumbs } from 'components/DashboardTasksCrumbs';
import FooterNav from 'components/FooterNav';
import FullScreenLoading from 'components/FullScreenLoading';
import ReadOnlyFlag from 'components/ReadOnlyFlag';
import ErrorPage, { ErrorType } from 'containers/ErrorPage';
import { FollowUpStatus } from 'models/FollowUpModel';
import i18n from './i18n';
import * as styles from './styles';

const FollowUpPage = ({
  followUpName,
  children,
  className = undefined,
  crumbProps,
  dashboard,
  followUpFetchResults,
  footer = undefined,
  header = undefined,
  headerType,
  navItems: navItemsFromProps,
  navProps,
  pageName,
  style = undefined,
  subHeaderProps,
}) => {
  const { followUp, followUpId, followUpLoading, followUpHasError } = followUpFetchResults;

  // if the follow up is loading
  if (followUpLoading) {
    return <FullScreenLoading />;
  }

  // if the there is an error
  if (followUpHasError || !followUpId) {
    return <ErrorPage />;
  }

  if (!followUp) {
    return null;
  }
  // if the follow up is inactive
  if (followUp.status === FollowUpStatus.Inactive) {
    return <ErrorPage errorType={ErrorType.ApplicationExpired} />;
  }

  let navItems = navItemsFromProps;
  // if its a multi-page flow, show chevrons on the footer buttons
  if (navItems?.length > 1) {
    navItems = navItems.map((item) => ({
      ...item,
      footerButtonProps: { iconType: IconName.ChevronRight },
    }));
  }

  const conditionalHeader = (
    <DashboardMainHeader
      dashboard={dashboard}
      crumbProps={{ navItems: dashboardTaskCrumbs, ...crumbProps }}
      subHeaderProps={subHeaderProps}
    />
  );

  let followUpTitle =
    followUpName &&
    templatedString({
      values: { followUpName },
      template: i18n.followUpPageTitleTemplate,
    });

  if (pageName) {
    followUpTitle = templatedString({
      values: {
        followUpPageTitleTemplate: followUpTitle,
        pageName,
      },
      template: i18n.followUpPageTitleMultiPageTemplate,
    });
  }

  return (
    <styles.FollowUpPageStyle {...style} className={className}>
      {followUpTitle !== undefined ? <Helmet title={followUpTitle} /> : null}
      {header !== undefined ? header : conditionalHeader}
      {typeof children === 'function'
        ? children({ navItems, navProps, headerType, subHeaderProps })
        : children}
      {footer !== undefined ? (
        footer
      ) : (
        <FooterNav bordered={false} navItems={navItems} navProps={navProps} weightedBottom />
      )}
      {followUp?.readOnly ? <ReadOnlyFlag /> : null}
    </styles.FollowUpPageStyle>
  );
};

const FollowUpPageWithAuth = (props) => {
  return (
    <AuthenticatedDashboard>
      {({ dashboard }) => dashboard != null && <FollowUpPage dashboard={dashboard} {...props} />}
    </AuthenticatedDashboard>
  );
};

export default FollowUpPageWithAuth;
