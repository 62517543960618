import React from 'react';
import tapOrClick from 'react-tap-or-click';
import * as styles from './styles';

const DotIndicators = ({
  items = [],
  styleInactiveColor,
  styleActiveColor,
  currentIndex,
  onIndicatorClick = () => null,
  readOnly,
  styleType = styles.styleTypes.OVERDARK,
}) => {
  const handleClick = (e) => {
    const index = parseInt(e.target.getAttribute('data-index'), 10);
    onIndicatorClick(e, { index });
  };

  const DotIndicatorItemStyle = readOnly
    ? styles.DotIndicatorItemReadOnlyStyle
    : styles.DotIndicatorItemStyle;

  return (
    <styles.DotIndicatorStyle styleType={styleType}>
      {items.map((item, i) => (
        <DotIndicatorItemStyle
          styleType={styleType}
          aria-label={i + 1}
          key={item.key || i}
          styleInactiveColor={styleInactiveColor}
          styleActiveColor={styleActiveColor}
          current={i === currentIndex}
          data-index={i}
          readOnly={readOnly}
          {...tapOrClick(handleClick)}
        />
      ))}
    </styles.DotIndicatorStyle>
  );
};

export const { styleTypes } = styles;
export default DotIndicators;
