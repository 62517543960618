import React, { forwardRef, useEffect, useState } from 'react';
import {
  Button,
  CloseButton,
  Container,
  DirectionAndPlacement,
  HelpTextAnimated,
  HelpTextStyleAlign,
  HelpTextStyleMarginPosition,
  HelpTextStyleSize,
  HelpTextStyleType,
  ResponsiveText,
  Size,
  Style,
  TemplatedText,
  Validation,
  directory,
} from '@pointdotcom/pds';
import ApplicationError from 'components/ApplicationError';
import i18nEstimatorPage from 'containers/EstimatorPage/i18n';
import * as helpers from 'containers/helpers';
import { dismissBannerMessage, getBannerMessage, getBannerWasDismissed } from 'store/general';
import { useDispatch, useSelector } from 'store/hooks';
import i18n from './i18n';
import { clearBannerMessageFromStorage, getBannerFromSessionStorage } from './sessionStorageBanner';
import * as styles from './styles';

const { PointEmail, PointNumber } = directory;

// TODO: create a toast widget in PDS, move some of this logic in
export enum BannerMessageType {
  PageError = 'pageError',
  GenericError = 'genericError',
  CallEstimate = 'callEstimate',
  Preliminary = 'preliminary',
  Estimate = 'estimate',
  InactiveDocket = 'inactiveDocket',
  LogOutIssue = 'logOutIssue',
  RecentOffer = 'recentOffer',
}

type ActionProps = Record<string, unknown>;

export interface GenericBannerProps {
  hasClose?: boolean;
  blueTheme?: boolean;
  noMargin?: boolean;
  inverted?: boolean;
  styleSize?: HelpTextStyleSize;
  styleType?: HelpTextStyleType;
  styleAlign?: HelpTextStyleAlign;
  styleMarginPosition?: HelpTextStyleMarginPosition;
  bordered?: boolean;
  show?: boolean;
  children?: React.ReactNode;
  messageType?: null | BannerMessageType;
  sans?: boolean;
  timer?: number;
  message?: string;
  onClose?: () => void;
  onAction?: (actionProps?: ActionProps) => void;
  actionProps?: ActionProps;
  actionText?: string;
}

const emailPhoneValues = {
  email: <a href={`mailto: ${PointEmail.Support}`}>{PointEmail.Support}</a>,
  phone: <a href={`tel: ${PointNumber.Support}`}>{PointNumber.Support}</a>,
  // TODO: delete these after old application is dead
  newOffer: (
    <a href={helpers.generateUrlFromPage(helpers.pages.PREQUAL_DEFAULT)}>{i18n.createNewOffer}</a>
  ),
};

export const messageMap = {
  [BannerMessageType.GenericError]: (
    <TemplatedText values={emailPhoneValues}>
      {`${Validation.i18n.whoops} ${Validation.i18n.wereSorry}`}
    </TemplatedText>
  ),
  [BannerMessageType.PageError]: Validation.i18n.pageError,
  [BannerMessageType.CallEstimate]: (
    <ResponsiveText mobileText={i18n.thisIsMobile}>{i18n.thisIs}</ResponsiveText>
  ),
  [BannerMessageType.Estimate]: i18nEstimatorPage.useTheToolsEstimateNote,

  // TODO: delete these after old application is dead
  [BannerMessageType.InactiveDocket]: (
    <ApplicationError
      headerText={i18n.previouslyStartedAnApplication}
      paragraphText={
        <TemplatedText values={emailPhoneValues}>{i18n.ifYouHaveQuestions}</TemplatedText>
      }
    />
  ),

  [BannerMessageType.Preliminary]: (
    <div style={{ maxWidth: '572px', margin: '0 auto' }}>{i18n.thisIsAnEstimate}</div>
  ),
  [BannerMessageType.LogOutIssue]: i18n.thereWasAProblem,
  [BannerMessageType.RecentOffer]: i18n.looksLikeOffer,
};

const messageTypeActionMap = {
  [BannerMessageType.RecentOffer]: ({ estimate, estimateKey, history }: TSFixMe) => {
    const newEstimateId = estimate?.mostRecentEstimateKey || estimateKey;
    const redirectUrl = helpers.generateUrlFromPage(helpers.pages.HEI_DEFAULT, {
      estimateKey: newEstimateId,
    });
    history.push(redirectUrl);
  },
};

const messageTypeActionTextMap = {
  [BannerMessageType.RecentOffer]: i18n.viewNewOffer,
};

const bannerStyleButtonStyleMap = {
  mono: 'primary',
};

export const getMessageFromType = (messageType?: null | BannerMessageType | string) => {
  if (!messageType) {
    return undefined;
  }
  return (messageMap as TSFixMe)[messageType];
};

const GenericMessageBanner = forwardRef<HTMLDivElement, GenericBannerProps>(
  (
    {
      hasClose = false,
      blueTheme: blueThemeFromProps = false,
      noMargin = true,
      inverted: invertedFromProps = true,
      styleSize = Size.Medium,
      styleType: styleTypeFromProps = Style.Mono,
      styleAlign = DirectionAndPlacement.Center,
      styleMarginPosition = DirectionAndPlacement.Bottom,
      bordered = false,
      show: showFromProps = false,
      children,
      messageType,
      sans = false,
      timer: timerFromProps,
      message: messageFromProps,
      onClose = () => null,
      onAction: onActionFromProps,
      actionProps,
      actionText: actionTextFromProps,
    },
    ref
  ) => {
    const bannerFromStorage = getBannerFromSessionStorage();
    const dispatch = useDispatch();
    const bannerMessageFromStore = useSelector(getBannerMessage);
    const bannerWasDismissed = useSelector(getBannerWasDismissed);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [actionErrorMessage, setActionErrorMessage] = useState<string | null>(null);
    const message =
      children ||
      messageFromProps ||
      getMessageFromType(messageType) ||
      bannerFromStorage?.message ||
      getMessageFromType(bannerMessageFromStore) ||
      bannerMessageFromStore;
    const blueTheme = blueThemeFromProps && !actionErrorMessage;

    /* We currently only support one closeable banner in the app. Closing any banner will prevent other closeable banners from showing
       due to the `bannerWasDismissed` flag. Visibility of banners with prop `hasClose: false` are not affected by this flag. */
    const show =
      (showFromProps || !!bannerFromStorage || !!bannerMessageFromStore) &&
      (hasClose ? !bannerWasDismissed : true);

    const styleType = actionErrorMessage
      ? Style.Error
      : styleTypeFromProps || bannerFromStorage?.styleType;
    const buttonStyle = (bannerStyleButtonStyleMap as TSFixMe)[styleType] || styleType;
    let onAction = onActionFromProps;
    let actionText = actionTextFromProps;
    if (messageType) {
      onAction = (messageTypeActionMap as TSFixMe)[messageType];
      actionText = (messageTypeActionTextMap as TSFixMe)[messageType];
    }
    const inverted =
      typeof invertedFromProps === 'boolean' ? invertedFromProps : bannerFromStorage?.inverted;
    const timerAmount = parseInt(timerFromProps || bannerFromStorage?.timer, 10);

    const handleClose = () => {
      onClose();
      dispatch(dismissBannerMessage());
    };

    useEffect(() => {
      let timer: NodeJS.Timeout | undefined;
      if (!Number.isNaN(timerAmount)) {
        timer = setTimeout(() => {
          clearBannerMessageFromStorage();
          setForceUpdate(!forceUpdate);
        }, timerAmount);
      }

      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }, [timerAmount, forceUpdate]);

    const hasAction = !!onAction && !!actionText;
    const actionHandler = async (): Promise<void> => {
      if (!hasAction) {
        return;
      }

      setActionErrorMessage(null);
      setActionLoading(true);
      try {
        if (onAction) {
          await onAction(actionProps);
        }
        setActionLoading(false);
      } catch (e) {
        setActionLoading(false);
        setActionErrorMessage(getMessageFromType(BannerMessageType.PageError));
      }
    };

    return (
      <styles.GenericMessageBannerStyle
        blueTheme={blueTheme}
        bordered={bordered}
        show={show}
        ref={ref}
      >
        <HelpTextAnimated
          styleMarginPosition={styleMarginPosition}
          noMargin={noMargin}
          inverted={inverted}
          styleSize={styleSize}
          styleType={styleType}
          styleAlign={styleAlign}
          sans={sans}
          show={show}
        >
          <Container>
            <styles.MessageStyle hasAction={hasAction} hasClose={hasClose}>
              <span className="message">{actionErrorMessage ?? message}</span>
              {hasAction && (
                <Button
                  styleSize={Size.Small}
                  styleType={buttonStyle}
                  onClick={actionHandler}
                  loading={actionLoading}
                >
                  {actionText}
                </Button>
              )}
              {hasClose && (
                <div className="closeContainer">
                  <CloseButton onClick={handleClose} />
                </div>
              )}
            </styles.MessageStyle>
          </Container>
        </HelpTextAnimated>
      </styles.GenericMessageBannerStyle>
    );
  }
);

export default GenericMessageBanner;
