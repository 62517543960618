import React from 'react';
import { useParams } from 'react-router-dom';
import SubNav, { SubNavItem } from 'components/SubNav';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { ApplicationPageType, pageTemplateMap } from '../constants';
import i18n from './i18n';

// NOTE: order matter
export const pageTitleMap = {
  [ApplicationPageType.AboutYou]: i18n.aboutYou,
  [ApplicationPageType.Property]: i18n.property,
  [ApplicationPageType.Financials]: i18n.financials,
  [ApplicationPageType.Review]: i18n.review,
};

const ApplicationPageNav = () => {
  const { estimateKey } = useParams<{
    estimateKey: string;
  }>();
  const subNavItems: Array<SubNavItem> = Object.keys(pageTemplateMap).map((key, i) => {
    const href = generateUrlFromPage(pages.HEI_APPLICATION, {
      estimateKey,
      page: String(i + 1),
    });
    const text = pageTitleMap[key as ApplicationPageType];
    return { text, href };
  });

  return <SubNav navItems={subNavItems} />;
};

export default ApplicationPageNav;
