import styled from 'styled-components';
import {
  ButtonStyles,
  ContainerStyles,
  GraphicsStyles,
  HeaderStyles,
  IconStyles,
} from '@pointdotcom/pds';
import * as appStyles from 'styles';

const midCalloutBreakPoint = '895px';
const PriceTableCheckSize = '26px';

export const LabelColumnStyle = styled.div.attrs({ className: 'LabelColumnStyle' })`
  flex: 2;
  ul li {
    align-items: flex-start;
    padding-right: 20px;
    @media (max-width: ${midCalloutBreakPoint}) {
      padding-left: ${(props) => props.theme.container.gutter.default};
    }
  }
`;

export const PriceTableContainerStyle = styled(ContainerStyles.ContainerStyle).attrs({
  className: 'PriceTableContainerStyle',
})`
  &:after {
    content: '';
    transition: all 0.5s ease-out 1.5s;
    position: absolute;
    transform: scale(0.5);
    margin-left: -50px;
    display: block;
    width: 300px;
    height: 300px;
    bottom: -130px;
    ${GraphicsStyles.GoldHouseCSS}
  }
  &.entered {
    &:after {
      bottom: -110px;
      transform: scale(1);
    }
  }
  @media (max-width: ${midCalloutBreakPoint}) {
    max-width: none;
    padding: 0;
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    &:after {
      margin-left: 0;
      left: 50%;
      width: 200px;
      height: 200px;
      bottom: -88px;
      transform: translateX(-50%) scale(0.5);
    }
    &.entered {
      &:after {
        bottom: -73px;
        transform: translateX(-50%) scale(1);
      }
    }
  }
`;

export const PriceTableHeaderStyle = styled.div.attrs({ className: 'PriceTableHeaderStyle' })`
  height: 150px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`;

export const LoansColumnStyle = styled.div.attrs({ className: 'LoansColumnStyle' })`
  flex: 1;
  text-align: center;
  background: ${(props) => props.theme.Color.Gray0};
  position: relative;
  z-index: 0;
  padding: 0 5% 8%;
  margin: 20px 0;
  ${PriceTableHeaderStyle} {
    height: 130px;
  }
  &:before {
    ${GraphicsStyles.TextureGrayCSS};
    content: '';
    display: block;
    position: absolute;
    width: 150%;
    height: 284px;
    top: -66px;
    left: -123%;
    z-index: 1;
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    margin: 0;
    ${PriceTableHeaderStyle} {
      height: 150px;
    }
    &:before {
      display: none;
    }
  }
`;

export const PointColumnStyle = styled.div.attrs({ className: 'PointColumnStyle' })`
  flex: 1;
  background: white;
  text-align: center;
  box-shadow: 0 0 50px ${(props) => props.theme.line.color.default};
  position: relative;
  z-index: 2;
  padding: 0 5% 8%;
  ${ButtonStyles.ButtonBaseStyle} {
    margin: 15% 0 0;
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.line.color.default};
    border-top: none;
    border-bottom: none;
    ${ButtonStyles.ButtonBaseStyle} {
      display: none;
    }
  }
`;

export const PointComparisonTableSectionStyle = styled.div.attrs({
  className: 'PointComparisonTableSectionStyle',
})`
  ${appStyles.PaddedSectionCSS}
  border-top: 1px solid ${(props) => props.theme.line.color.default};
  position: relative;
  > ${ContainerStyles.ContainerStyle} {
    > ${HeaderStyles.HeaderStyle} {
      margin-bottom: -50px;
      @media (max-width: ${midCalloutBreakPoint}) {
        margin-bottom: 1em;
      }
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding-bottom: 140px;
  }
`;

export const PriceTableStyle = styled.div.attrs({ className: 'PriceTableStyle' })`
  display: flex;
  flex-flow: row nowrap;
  ${IconStyles.IconStyle} {
    position: absolute;
    z-index: 2;
    display: inline-block;
    width: ${PriceTableCheckSize};
    height: ${PriceTableCheckSize};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.8);
  }
  ${HeaderStyles.HeaderStyle} {
    margin-top: 20%;
  }
  ul {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      position: relative;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      height: 50px;
      border-bottom: 1px solid ${(props) => props.theme.line.color.default};
      &:last-child {
        border-bottom: none;
      }
      &.PriceTableItem {
        &:before {
          content: '';
          display: block;
          width: ${PriceTableCheckSize};
          height: ${PriceTableCheckSize};
          border-radius: 50%;
          position: absolute;
          left: 50%;
          z-index: 1;
          transition-property: all;
          transition-duration: 0.5s;
          transition-timing-function: ease-out;
          transform: translateX(-50%) scale(0);
        }
        ${IconStyles.IconStyle} {
          opacity: 0;
          visibility: hidden;
          transition-property: all;
          transition-duration: 0.5s;
          transition-timing-function: ease-out;
        }
        &:nth-child(2) ${IconStyles.IconStyle}, &:nth-child(2):before {
          transition-delay: 0s;
        }
        &:nth-child(3) ${IconStyles.IconStyle}, &:nth-child(3):before {
          transition-delay: 0.2s;
        }
        &:nth-child(4) ${IconStyles.IconStyle}, &:nth-child(4):before {
          transition-delay: 0.4s;
        }
        &:nth-child(5) ${IconStyles.IconStyle}, &:nth-child(5):before {
          transition-delay: 0.6s;
        }
        .entered & {
          ${IconStyles.IconStyle} {
            opacity: 1;
          }
          &:before {
            transform: translateX(-50%) scale(1);
          }
          &.selected {
            &:before {
              background: ${(props) => props.theme.Color.AccentBlue};
            }
          }
        }
        &:before {
          background: ${(props) => props.theme.line.color.default};
        }
        &.selected {
          ${IconStyles.IconStyle} {
            visibility: visible;
          }
        }
      }
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    margin-bottom: 8%;
    ul li {
      height: 70px;
    }
  }
  @media (max-width: 450px) {
    ul li {
      height: 70px;
    }
  }
`;
