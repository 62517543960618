import bugsnagClient from 'lib/bugsnagClient';
import { Expand } from 'types';
import BaseModel from './BaseModel';
import { currencyMask, normalizeProps } from './helpers';

export interface Fee {
  key: string;
  label: string;
  amount: number;
  notes?: string;
  method?: string;
}

export interface PayOff {
  name: string;
  balance: string | number;
}

export interface ClosingCosts {
  totalFees?: number;
  totalPayoffs?: Array<PayOff>;
  totalPayoffAmount?: number;
  fees?: Array<Fee>;
}

class ClosingCostsModel extends BaseModel<ClosingCosts> implements ClosingCosts {
  totalFees?: number;

  totalPayoffs = [] as Array<PayOff>;

  totalPayoffAmount?: number;

  fees = [] as Array<Fee>;

  static NAMESPACE = 'EstimateModel.Pricing.ClosingCosts';

  static CONFIG = {
    optional: ['total_fees', 'total_payoffs', 'total_payoff_amount', 'fees'],
    ignoreForeignError: true,
  };

  constructor(props: Expand<ClosingCosts>) {
    const reshapedProps = normalizeProps<ClosingCosts>(props, {
      total_fees: 'totalFees',
      total_payoffs: 'totalPayoffs',
      total_payoff_amount: 'totalPayoffAmount',
    });
    super(reshapedProps);
    Object.assign(this, reshapedProps);
    this.logErrorIfFeesDontAddUp();
  }

  getTotalFees(): number {
    const feeList = this.getFeeList();
    return feeList?.map((fee) => fee.amount).reduce((a, b) => a + b, 0);
  }

  getFormattedTotalFees(): string {
    const totalFees = this.getTotalFees();
    return currencyMask.getFormatted(totalFees);
  }

  getTotal(): number {
    return this.totalFees || 0;
  }

  getFeeList(): Array<Fee> {
    const exclude = [
      'other_costs',
      'origination_charge',
      'second_appraisal_fee',
      'third_appraisal_fee',
      'fourth_appraisal_fee',
      'fifth_appraisal_fee',
      'third_party_services',
      'total_closing_costs',
      'total_option_agreement_costs',
    ];

    const filteredAndSortedFees = [...this.fees]
      .filter((fee) => exclude.indexOf(fee.key) === -1)
      .sort((fee1, fee2) =>
        fee1.label && fee2.label && fee1.label.charAt(0) < fee2.label.charAt(0) ? -1 : 1
      );

    return filteredAndSortedFees;
  }

  getFormattedFeeList(): Array<string> {
    return this.getFeeList().map((feeItem) => currencyMask.getFormatted(feeItem.amount));
  }

  getDebtPayoffLabels(): Array<string> {
    return (this.totalPayoffs || []).map((item) => item.name);
  }

  getFormattedDebtPayoffValues(): Array<string> {
    return (this.totalPayoffs || []).map((item) => currencyMask.getFormatted(item.balance));
  }

  getTotalFeesFromList(): number {
    let total = 0;
    this.getFeeList().forEach((next) => {
      const nextValue = typeof next === 'object' ? next.amount : 0;
      total = parseFloat((total + nextValue).toFixed(10));
    });

    return total;
  }

  logErrorIfFeesDontAddUp(): void {
    const totalFees = this.getTotalFees();
    const totalFeesFromPluckedList = this.getTotalFeesFromList();
    if (parseFloat(totalFees.toFixed(2)) !== parseFloat(totalFeesFromPluckedList.toFixed(2))) {
      const errorMessage = `
        List of fees: ${totalFeesFromPluckedList}
        (${(this.getFeeList() || [])
          .map((o) => (o && o.label && o.amount ? `${o.label}: ${o.amount}` : ''))
          .join(' + ')})
        does not add up to
        total: ${totalFees}
      `;
      const error = new Error(errorMessage);
      bugsnagClient.notify(error);
    }
  }
}

export default ClosingCostsModel;
