import nullthrows from 'nullthrows';

export class SubscribableValue<TValue> {
  #value: TValue;
  #subscribers: Set<() => void> = new Set();

  constructor(value: TValue) {
    this.#value = value;
  }

  get value() {
    return this.#value;
  }

  set value(newValue: TValue) {
    if (Object.is(this.#value, newValue)) {
      return;
    }

    this.#value = newValue;
    const subscribers = [...this.#subscribers];
    for (const subscriber of subscribers) {
      subscriber();
    }
  }

  subscribe(callback: () => void) {
    const wrappedCallback = () => callback();
    this.#subscribers.add(wrappedCallback);

    return () => {
      this.#subscribers.delete(wrappedCallback);
    };
  }
}

type PromiseResolver<T> = (value: T | PromiseLike<T>) => void;

export async function waitForSubscribable<TValue>(
  subscribable: SubscribableValue<TValue>,
  untilCondition: (value: TValue) => boolean
): Promise<TValue> {
  while (!untilCondition(subscribable.value)) {
    let resolver: undefined | PromiseResolver<void>;
    const promise = new Promise((resolve) => {
      resolver = resolve;
    });
    const unsubscribe = subscribable.subscribe(nullthrows(resolver));
    try {
      await promise;
    } finally {
      unsubscribe();
    }
  }

  return subscribable.value;
}
