import styled, { keyframes } from 'styled-components';
import handShakeImage from './images/shakehands.png';

export const pulse = keyframes`
  0%, 100% {
    transform: scale(0.8);
    opacity: 0.4;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
`;

const shoulderRotate = keyframes`
  0%, 100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(8deg); /* Slight movement at shoulder */
  }
`;

const elbowRotate = keyframes`
  0%, 100% {
    transform: rotate(-30deg);
  }
  50% {
    transform: rotate(-25deg); /* Slight movement at elbow */
  }
`;

export const ArmStyle = styled.svg.attrs({ className: 'ArmStyle' })`
  --armSize: 50%;
  width: var(--armSize);
  padding-bottom: var(--armSize);
  display: block;
  margin: 0 auto;
  position: absolute;
  path {
    stroke: #ccc;
    stroke-width: 14;
  }
  .arm {
    transform-origin: 25% 25%; /* Shoulder point (50, 50 in percentages) */
    animation: ${shoulderRotate} 1.5s infinite alternate ease-in-out;
  }

  .forearm {
    transform-origin: 50% 50%; /* Elbow point (100, 100 in percentages) */
    animation: ${elbowRotate} 1.5s infinite alternate ease-in-out;
  }
`;

export const ShakeShineStyle = styled.div.attrs({
  className: 'ShakeShineStyle',
})`
  --shakeShineSize: 20%;
  position: absolute;
  width: var(--shakeShineSize);
  height: var(--shakeShineSize);
  background: ${({ theme }) => theme.Color.Yorange};
  opacity: 0.8;
  filter: blur(5px);
  border-radius: 50%;
  left: 35%;
  top: 25%;
  animation: ${pulse} 3s infinite ease-in-out;
  z-index: -1;
`;

export const HandShakeGraphicStyle = styled.div.attrs({
  className: 'HandShakeGraphicStyle',
})`
  --handShakeSize: 20%;
  background-image: url('${handShakeImage}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom -1px;
  position: absolute;
  bottom: 0;
  right: 10%;
  width: var(--handShakeSize);
  padding-bottom: var(--handShakeSize);
  ${ArmStyle} {
    top: 6%;
    left: 0%;
    transform: rotate(-12deg);
    transform-origin: top;

    &:last-child {
      --handShakeSize: 26%;
      transform: scaleX(-1) rotate(-2deg);
      transform-origin: top center;
      top: 3%;
      left: initial;
      right: 15%;
    }
  }
`;
