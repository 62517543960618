import React, { useRef, useState } from 'react';
import {
  Button,
  DirectionAndPlacement,
  HelpText,
  LinkButton,
  PolicyType,
  TemplatedText,
  messagingI18n,
} from '@pointdotcom/pds';
import ContactForm, { ContactFormField, ContactFormRef } from 'components/ContactForm';
import FollowSection from 'components/FollowSection';
import { pages } from 'containers/helpers';
import ModalPage, { FlexFlow } from 'containers/prequal/ModalPage';
import { useProduct, useRedirectToBeginning } from 'containers/prequal/hooks';
import { usePrequalApplication } from 'containers/prequal/hooks/usePrequalApplication';
import { getPrequalPageFlow } from 'containers/prequal/productPageFlow';
import { RootState } from 'store';
import { answerContactInfo } from 'store/applicant';
import { Products } from 'store/constants';
import { useDispatch, useSelector } from 'store/hooks';
import { APPLICATION_VALIDATION_CODES, PREQUAL_FLOW_TYPE } from 'types';
import i18n from './i18n';
import * as styles from './styles';

const Contact = () => {
  useRedirectToBeginning();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { product = Products.HEI } = useProduct();
  const applicant = useSelector((state: RootState) => state.applicant);
  const { totalPages, flowIndex } = getPrequalPageFlow(product, pages.PREQUAL_CONTACT_INFO);
  const formRef = useRef<ContactFormRef>(null);
  const policyHeaderRef = useRef<HTMLHeadingElement>(null);
  const { postPrequalApplication, processApplicationResponse } = usePrequalApplication();

  const isDirectMail = !!useSelector(
    (state: RootState) => state.prequalApplication?.directMailData
  );
  const isFirmOffer = useSelector(
    (state: RootState) => state.prequalApplication?.flow === PREQUAL_FLOW_TYPE.FIRM_OFFER
  );
  const isAltFirmOffer = useSelector(
    (state: RootState) => state.prequalApplication?.flow === PREQUAL_FLOW_TYPE.ALT_FIRM_OFFER
  );

  const handleSubmitValid: React.ComponentProps<typeof ContactForm>['onSubmitValid'] = async (
    e,
    { setFormError, setValidationError, value }
  ) => {
    setFormError(null);
    dispatch(answerContactInfo(value));
    try {
      setLoading(true);
      const result = await postPrequalApplication();
      processApplicationResponse(result);
    } catch (error: TSFixMe) {
      switch (error.message) {
        case APPLICATION_VALIDATION_CODES.INVALID_FIRST_NAME: {
          setValidationError(ContactFormField.FirstName);
          break;
        }
        case APPLICATION_VALIDATION_CODES.INVALID_LAST_NAME: {
          setValidationError(ContactFormField.LastName);
          break;
        }
        case APPLICATION_VALIDATION_CODES.INVALID_EMAIL: {
          setValidationError(ContactFormField.EmailAddress);
          break;
        }
        case APPLICATION_VALIDATION_CODES.INVALID_PHONE_NUMBER: {
          setValidationError(ContactFormField.PhoneNumber);
          break;
        }
        default: {
          // Any other failure is a generic error message
          setFormError(messagingI18n.errors.formError);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  let title = i18n.youreAlmostDoneHeader;
  let subtitle = i18n.youllReceive;
  let buttonText = i18n.viewMyOffer;
  let policies: React.ComponentProps<typeof ContactForm>['policies'] = true;

  let contextHelp = [
    {
      headline: i18n.whyDoesPoint,
      body: i18n.weUnderstand,
    },
  ];

  if (isFirmOffer) {
    // Omit the credit report policy in this case - https://pointdf.atlassian.net/browse/PPC-1493
    policies = [PolicyType.Contact, PolicyType.Share, PolicyType.ECES];
    title = i18n.reachYou;
    subtitle = i18n.offerOnNextPage;
    buttonText = i18n.continue;
    contextHelp = [
      {
        headline: i18n.whyContactMe,
        body: i18n.customerServiceTeam,
      },
    ];
  } else if (isAltFirmOffer) {
    title = i18n.lastStep;
    subtitle = i18n.checkYourCreditScore;
    buttonText = i18n.confirmEligibility;
    contextHelp = [
      {
        headline: i18n.whyContactMe,
        body: i18n.customerServiceTeam,
      },
      {
        headline: i18n.willSubmitting,
        body: i18n.noYourCredit,
      },
      {
        headline: i18n.canEligibilityFor,
        body: i18n.yesYourEligibility,
      },
    ];
  }

  const hiddenFields = [];
  if (isDirectMail) {
    if (applicant.firstName) {
      hiddenFields.push(ContactFormField.FirstName);
    }
    if (applicant.lastName) {
      hiddenFields.push(ContactFormField.LastName);
    }
  }

  const handleFloatingCTAClick = () => {
    formRef.current?.submit();
  };

  const handleTermsButtonClick = () => {
    policyHeaderRef.current?.scrollIntoView?.({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const subContent = (
    <HelpText>
      <TemplatedText
        values={{
          buttonText,
          termsAndConditions: () => (
            <LinkButton onClick={handleTermsButtonClick}>{i18n.termsAndConditions}</LinkButton>
          ),
          sup: () => <sup>*</sup>,
        }}
      >
        {i18n.byClickingShort}
      </TemplatedText>
    </HelpText>
  );

  return (
    <styles.ContactPageWrapperStyle>
      <ModalPage
        pageName={i18n.pageName}
        numIndicators={totalPages}
        currentIndicatorIndex={flowIndex}
        flexFlow={FlexFlow.Column}
        content={{ title, subtitle, subContent }}
        contextHelp={contextHelp}
      >
        <ContactForm
          showPolicyHeader
          policyHeaderRef={policyHeaderRef}
          focused={ContactFormField.FirstName}
          hiddenFields={hiddenFields}
          firstName={applicant.firstName}
          lastName={applicant.lastName}
          policies={policies}
          onSubmitValid={handleSubmitValid}
          buttonText={buttonText}
          ref={formRef}
          loading={loading}
          helpText={
            <TemplatedText values={{ not: <strong>{i18n.not}</strong> }}>
              {i18n.prequalificationWillNot}
            </TemplatedText>
          }
        />
        <FollowSection show placement={DirectionAndPlacement.Bottom} adjustBody>
          <styles.CTAContainerStyle>
            <Button onClick={handleFloatingCTAClick} loading={loading}>
              {i18n.viewMyOffer}
            </Button>
            <HelpText styleAlign={DirectionAndPlacement.Center} noMargin>
              <TemplatedText values={{ not: <strong>{i18n.not}</strong> }}>
                {i18n.prequalificationWillNot}
              </TemplatedText>
            </HelpText>
          </styles.CTAContainerStyle>
        </FollowSection>
      </ModalPage>
    </styles.ContactPageWrapperStyle>
  );
};

export default Contact;
