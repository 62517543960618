import styled, { css } from 'styled-components';
import {
  ButtonStyles,
  ContainerStyles,
  GraphicsStyles,
  HeaderStyles,
  IconStyles,
  LinkButtonStyles,
  mixins,
} from '@pointdotcom/pds';
import * as appStyles from 'styles/';

const SectionHeaderCSS = css`
  margin-bottom: 4em;
`;

export const FAQTableStyle = styled.div.attrs({ className: 'FAQTableStyle' })`
  display: flex;
  flex-flow: row nowrap;
  padding: 12% 9%;
  box-shadow: 0 0 50px ${(props) => props.theme.Color.PurpleBlue7};
  font-size: ${(props) => mixins.getRemFromPx(18, props.theme.globals.baseFontSize.default)};
  background: ${(props) => props.theme.Color.PurpleBlue6};
  ${LinkButtonStyles.ButtonLinkStyle} {
    color: inherit;
    text-decoration: none;
    padding: 16px 0;
    border-bottom: 1px solid ${(props) => props.theme.line.color.invertedSubtle};
    width: 100%;
    text-align: left;
    font-size: inherit;
    font-weight: inherit;
    white-space: normal;
    display: flex;
    flex-flow: row nowrap;
    gap: 1em;
    justify-content: space-between;
    align-items: center;
    &:hover {
      border-color: white;
    }
  }
  ${IconStyles.IconStyle} {
    width: 20px;
    height: 20px;
    transition: all 0.24s ease-in-out;
    opacity: 0;
    transform: translateX(-10px);
  }
  span {
    width: calc(100% - 20px);
  }
  ul {
    padding: 0;
    margin: 0;
    flex: 3;
  }
  li {
    list-style: none;
    &.active {
      ${IconStyles.IconStyle} {
        opacity: 1;
        transform: translateX(0);
      }
      ${LinkButtonStyles.ButtonLinkStyle} {
        font-weight: 900;
      }
    }
    main {
      flex: auto;
      width: 100%;
      margin: 0;
    }
  }
  main {
    margin-left: 7%;
    flex: 3;
    line-height: 1.7em;
    transition: all 320ms linear;
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding: 0;
    box-shadow: none;
    border-top: 1px solid ${(props) => props.theme.line.color.invertedSubtle};
    ${LinkButtonStyles.ButtonLinkStyle} {
      padding-top: 1.4em;
      padding-bottom: 1.4em;
      transition: all 0.24s ease-in-out;
    }
    main {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      max-height: 0;
      padding: 0;
      &.exited {
        max-height: 0;
        padding: 0;
        opacity: 0;
      }
      &.entered {
        max-height: 500px;
        padding-top: 1%;
        padding-bottom: 10%;
        opacity: 1;
      }
    }
    li {
      &:last-child {
        main {
          border-bottom: 1px solid ${(props) => props.theme.line.color.invertedSubtle};
        }
      }
      ${LinkButtonStyles.ButtonLinkStyle}, main {
        padding-left: ${(props) => props.theme.container.gutter.default};
        padding-right: ${(props) => props.theme.container.gutter.default};
      }
      &.active {
        ${LinkButtonStyles.ButtonLinkStyle} {
          border-bottom-color: transparent;
        }
        + li {
          ${LinkButtonStyles.ButtonLinkStyle} {
            border-top: 1px solid ${(props) => props.theme.line.color.invertedSubtle};
          }
        }
        ${IconStyles.IconStyle} {
          transform: translateX(0) rotate(90deg);
        }
      }
    }
  }
`;

export const FAQSectionStyle = styled.div.attrs({ className: 'FAQSectionStyle' })`
  ${appStyles.PaddedSectionCSS}
  background: ${(props) => props.theme.Color.PurpleBlue6};
  color: white;
  position: relative;
  ${HeaderStyles.HeaderStyle} {
    ${SectionHeaderCSS}
  }
  > * {
    position: relative;
    z-index: 2;
  }
  &:after {
    content: '';
    transform: rotate(180deg) scale(0.8);
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    bottom: -22px;
    ${GraphicsStyles.TexturePurpleBlue6CSS}
  }
  ${ContainerStyles.ContainerStyle} {
    &:last-child {
      margin-top: 3rem;
      ${ButtonStyles.ButtonBaseStyle} {
        min-width: 290px;
      }
      @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
        ${ButtonStyles.ButtonBaseStyle} {
          min-width: auto;
        }
      }
    }
  }
`;
