import styled from 'styled-components';
import { ButtonStyles, ContainerStyles, HeaderStyles, Icon, mixins } from '@pointdotcom/pds';

const mainHeaderHeightPx = 161; // get with js if used again
const columnPaddingTop = '5.5rem';
const pageColor = 'white';

export const QuizResultStyle = styled.div.attrs({ className: 'QuizResultStyle' })`
  background: white;
  margin-top: 0;
  .ConfettiBackgroundStyle {
    left: 0;
    width: 100%;
    height: calc(100% + ${mainHeaderHeightPx}px);
    top: -${mainHeaderHeightPx}px;
    position: absolute;
    z-index: 1;
  }
  ${ContainerStyles.ContainerStyle} {
    gap: 5rem;
    align-items: flex-start;
    z-index: 2;
    position: relative;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    background: white;
    ${ContainerStyles.ContainerStyle} {
      gap: 0;
    }
  }
`;

export const QuizScoreStyle = styled.div.attrs({ className: 'QuizScoreStyle' as string })<{
  passedQuiz: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: ${({ passedQuiz }) => (!passedQuiz ? columnPaddingTop : '0')};
  aside {
    position: relative;
    padding-top: 10%;
    margin-bottom: 2rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 95%;
    padding: 0 15%;
    border-bottom: ${({ theme }) => `1px solid ${theme.Color.Gray2}`};
  }

  p {
    font-family: ${({ theme }) => theme.fonts.serif.name};
    font-size: ${({ theme }) => mixins.getRemFromPx(22, theme.globals.baseFontSize.default)};
    margin-bottom: 3rem;
  }

  ${ButtonStyles.ButtonBaseStyle} {
    margin-bottom: 0.8rem;
  }

  // prettier-ignore
  @media (min-width: ${({ theme }) => theme.responsive.largestMobileScreen}) 
  and (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    ${HeaderStyles.HeaderStyle} {
      > * {
        font-size: 4vw;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    background: white;
    box-shadow: 0px 0px 60px ${(props) => mixins.rgba(props.theme.Color.Gray4, 0.17)};
    padding-bottom: 4rem;
    img {
      width: 100%;
      padding: 0 2rem;
      max-width: 300px;
      align-self: center;
    }
    p {
      font-size: ${({ theme }) => mixins.getRemFromPx(18, theme.globals.baseFontSize.default)};
    }
  }
`;

export const ScheduleCallStyle = styled.div.attrs({ className: 'ScheduleCallStyle' })`
  line-height: 1.5;
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: ${({ theme }) => `1px solid ${theme.Color.Gray2}`};
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};

  div {
    padding-bottom: 6px;
  }

  a {
    text-decoration: none;
  }
`;

export const QuizSummaryColumnStyle = styled.div`
  padding: ${columnPaddingTop} 0 4rem;
  flex: 1;
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    padding: 0;
  }
`;

export const QuestionSummaryStyle = styled.div.attrs({
  className: 'QuestionSummaryStyle',
})`
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  position: relative;
  &:before {
    content: '';
    width: calc(100% - 70px);
    height: calc(100% - 70px);
    background: white;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    box-shadow: 0px 0px 60px ${(props) => mixins.rgba(props.theme.Color.Gray4, 0.17)};
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    p {
      padding: 2rem 0;
    }
    &:before {
      display: none;
    }
  }
`;

export const SummaryScrollStyle = styled.div.attrs({ className: 'SummaryScrollStyle' })`
  position: relative;
  z-index: 2;
  background: ${pageColor};
  p {
    font-family: ${({ theme }) => theme.fonts.serif.name};
    font-style: italic;
    font-size: ${({ theme }) => mixins.getRemFromPx(18, theme.globals.baseFontSize.default)};
    border-bottom: ${({ theme }) => `1px solid ${theme.line.color.default}`};
    padding: 2.5rem 4rem;
    margin-bottom: 0;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    background: transparent;
    p {
      padding: ${({ theme }) => theme.container.gutter.default};
    }
  }
`;

export const QuestionReportStyle = styled.li.attrs({ className: 'QuestionReportStyle' })`
  padding: 0 3rem 3.5rem 4rem;
  margin-bottom: 3.5rem;
  border-bottom: ${({ theme }) => `1px solid ${theme.line.color.default}`};

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }

  ${HeaderStyles.HeaderStyle} {
    > * {
      display: flex;
      gap: 1rem;
      font-size: ${({ theme }) => mixins.getRemFromPx(18, theme.globals.baseFontSize.default)};
    }

    /* Don't let the width of the index number push the question over */
    span:first-of-type {
      max-width: 1ch;
    }
  }

  ${ButtonStyles.ButtonBaseStyle} {
    margin-top: 3rem;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
    font-size: ${({ theme }) => mixins.getRemFromPx(16, theme.globals.baseFontSize.default)};
    font-weight: normal;
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    padding-left: 0;
    padding-right: 0;
  }

  input[type='radio'] {
    --colorCorrect: ${({ theme }) => theme.Color.Gray4};
    --colorIncorrect: ${({ theme }) => theme.Color.Red};

    flex: 0 0 1em;
    appearance: none;
    position: relative;
    width: 1em;
    height: 1em;
    margin: 0.25rem 1rem 0 0;
    background: ${pageColor};
    border: 1px solid var(--colorCorrect);
    border-radius: 50%;
    box-shadow: inset 0 0 0 0.2em ${pageColor};

    &:checked[data-correct='true'] {
      background: var(--colorCorrect);
    }

    &:checked[data-correct='false'] {
      background: var(--colorIncorrect);
      border-color: var(--colorIncorrect);
    }
  }
`;

export const IndicatorIconStyle = styled(Icon).attrs({ className: 'IndicatorIconStyle' })<{
  isCorrect: boolean;
}>`
  border-radius: 50%;
  background-color: ${({ isCorrect, theme }) =>
    isCorrect ? theme.Color.GrayLightestAccessible : theme.Color.Red};
  margin: 3rem 0 2rem;
  padding: 5px;
`;

export const AnswersOptionWrapper = styled.div.attrs({ className: 'AnswersOptionWrapper' })`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

interface OptionLabelProps {
  isCorrect: boolean;
  wasChosen: boolean;
}

export const OptionLabelStyle = styled.label<OptionLabelProps>`
  font-weight: ${({ isCorrect }) => (isCorrect ? 'bold' : 'normal')};
  color: ${({ wasChosen, isCorrect, theme }) =>
    wasChosen && !isCorrect ? theme.Color.Red : 'inherit'};
  line-height: 1.4;
`;

export const CorrectionStyle = styled.div.attrs({ className: 'CorrectionStyle' })`
  padding-top: 3rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
