import styled, { createGlobalStyle } from 'styled-components';
import { ContainerStyles, mixins } from '@pointdotcom/pds';

const getAttrByStyleStyle = (props, attr) => {
  const styleTypeAttrMap = {
    backgroundColor: {
      default: props.theme.footer.backgroundColor.default,
      white: 'white',
    },
    borderColor: {
      default: 'transparent',
      white: props.theme.line.color.default,
    },
  };

  return styleTypeAttrMap[attr][props.styleType];
};

export const BodyWithFooterStyle = createGlobalStyle`
  body {
    background: ${(props) => getAttrByStyleStyle(props, 'backgroundColor')};
  }
`;

export const LogoGridStyle = styled.div.attrs({ className: 'LogoGridStyle' })`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-auto-rows: minmax(min-content, max-content);
  align-items: center;
  justify-items: center;
  > * {
    &:first-child {
      padding-bottom: 8px;
    }
    &:nth-child(2) {
      padding: 8px 0 0;
    }
    &:nth-child(5) {
      padding: 0 20px 8px;
    }
  }
`;

const VERT_SECTION_GAP = '32px';

export const MainFooterStyle = styled.footer.attrs({ className: 'MainFooterStyle' })`
  background-color: ${(props) => getAttrByStyleStyle(props, 'backgroundColor')};
  font-size: ${(props) => mixins.getRemFromPx(14, props.theme.globals.baseFontSize.default)};
  color: ${(props) => props.theme.footer.color.default};
  padding-bottom: 9vh;
  .flexStackContent {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.4em;
  }
  a {
    color: inherit;
    &:hover {
      cursor: pointer;
      path {
        fill: ${(props) => props.theme.Color.PointBlue};
      }
    }
  }
  path {
    fill-opacity: 1;
    fill: currentColor;
  }
  .mainContent {
    border-width: 1px;
    border-style: solid;
    border-top-color: ${(props) => getAttrByStyleStyle(props, 'borderColor')};
    border-bottom-color: ${(props) => props.theme.line.color.default};
    border-left: none;
    border-right: none;
  }
  .mainContentGrid {
    > * {
      display: flex;
      padding: ${VERT_SECTION_GAP} 0;
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'primary secondary'
      'tertiary quartiary';
    > .tpContainer {
      grid-area: primary;
      border-bottom: 1px solid ${(props) => props.theme.line.color.default};
      > * {
        margin-left: -16px;
        margin-top: 5px;
        padding: 0;
      }
      > *,
      iframe {
        width: auto;
      }
    }
    > .bbbContainer {
      grid-area: secondary;
      border-bottom: 1px solid ${(props) => props.theme.line.color.default};
      justify-content: flex-end;
    }
    > .licContainer {
      grid-area: tertiary;
      display: inline-flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;
      line-height: ${(props) => mixins.getRemFromPx(27, props.theme.globals.baseFontSize.default)};
      .bbbContainer {
        margin-bottom: 1rem;
      }
      > * {
        &:last-child {
          margin: 0;
        }
      }
      a {
        text-decoration: none;
      }
    }
    > .logoContainer {
      padding: ${VERT_SECTION_GAP} 0;
      justify-content: flex-end;
      grid-area: quartiary;
    }
  }
  .subContent {
    ${ContainerStyles.ContainerStyle} {
      display: flex;
      justify-content: space-between;
      flex-flow: row nowrap;
      align-items: flex-start;
      > * {
        padding: ${VERT_SECTION_GAP} 0;
        flex: auto;
      }
    }
    .address {
      white-space: nowrap;
    }
    .contactContainter {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      > * {
        &:first-child {
          padding-right: 2rem;
          border-right: 1px solid ${(props) => props.theme.line.color.default};
        }
        &:last-child {
          padding-left: 2rem;
        }
        > * {
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    .mainContentGrid {
      grid-template-columns: 100%;
      grid-auto-rows: minmax(min-content, max-content);
      grid-template-areas:
        'primary'
        'secondary'
        'quartiary'
        'tertiary';
      .bbbContainer,
      .logoContainer {
        justify-content: center;
        margin: 0 ${(props) => props.theme.container.gutter.default};
      }
      .licContainer {
        padding: 0 ${(props) => props.theme.container.gutter.default};
      }
      .licContainer {
        padding: ${VERT_SECTION_GAP} ${(props) => props.theme.container.gutter.default};
        border-top: 1px solid ${(props) => props.theme.line.color.default};
        .bbbContainer {
          justify-content: center;
          align-items: center;
          margin: 0 auto 1rem;
        }
      }
      .LicencesLinksCopyrightStyle {
        margin: 0 ${(props) => props.theme.container.gutter.default};
      }
      .tpContainer {
        > * {
          margin-left: 0;
        }
        > *,
        iframe {
          width: 100% !important;
        }
      }
    }
    .subContent {
      ${ContainerStyles.ContainerStyle} {
        flex-flow: column-reverse;
        > * {
          width: 100%;
        }
      }
      .socialFineTextContainer,
      .contactContainter {
        padding: ${VERT_SECTION_GAP} ${(props) => props.theme.container.gutter.default};
      }
      .socialFineTextContainer {
        border-top: 1px solid ${(props) => props.theme.line.color.default};
      }
      .contactContainter {
        flex-flow: column nowrap;
        > * {
          flex: 100%;
          width: 100%;
          padding: 0;
          &:first-child {
            padding: 0 0 1rem;
            border: none;
          }
          &:last-child {
            padding-left: 0;
          }
        }
      }
    }
  }
  @media (max-width: 443px) {
    .mainContentGrid {
      .logoContainer {
        transform: scale(0.9);
      }
    }
  }
  @media (max-width: 345px) {
    .mainContentGrid {
      .logoContainer {
        transform: scale(0.8);
      }
    }
  }
`;
