import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import OfferRejection, { reason as REASONS } from 'components/prequal/OfferRejection';
import WhiteContainer from 'containers/prequal/WhiteContainer';
import { useProduct } from 'containers/prequal/hooks/useProduct';
import { clearApplication } from 'store/actions';
import { useDispatch, useSelector } from 'store/hooks';
import i18n from './i18n';

const Ineligible = () => {
  const { product } = useProduct();
  const dispatch = useDispatch();
  const ineligibility = useSelector((state) => state.prequalApplication?.ineligibility);

  // This is a terminal page of the prequal flow, so clean out application data
  useEffect(() => {
    dispatch(clearApplication({ product }));
  }, [dispatch, product]);

  const { rejectionReasons, expired } = ineligibility;

  let reason;

  if (expired) {
    reason = REASONS.EXPIRED;
  } else if (rejectionReasons?.includes(REASONS.CREDIT_FILE_NOT_FOUND)) {
    reason = REASONS.CREDIT_FILE_NOT_FOUND;
  } else if (rejectionReasons?.includes(REASONS.CREDIT_FILE_FROZEN)) {
    reason = REASONS.CREDIT_FILE_FROZEN;
  } else if (rejectionReasons?.includes(REASONS.TITLE_GAP)) {
    reason = REASONS.TITLE_GAP;
  } else if (rejectionReasons?.includes(REASONS.SOFT_REJECT)) {
    reason = REASONS.SOFT_REJECT;
  } else if (rejectionReasons?.includes(REASONS.NEW_INFORMATION_FROM_HOMEOWNER)) {
    reason = REASONS.NEW_INFORMATION_FROM_HOMEOWNER;
  } else if (rejectionReasons?.includes(REASONS.NO_PRODUCTS_MATCHED)) {
    reason = REASONS.NO_PRODUCTS_MATCHED;
  } else {
    reason = null;
  }

  // Default
  return (
    <>
      <Helmet title={i18n[`${product}PageTitle`]} />
      <WhiteContainer product={product} align="left">
        <OfferRejection product={product} reason={reason} />
      </WhiteContainer>
    </>
  );
};

export default Ineligible;
