export default {
  financialCounseling: 'Financial Counseling',
  pointIsCommitted:
    'Point is committed to helping you make informed decisions about whether to obtain our product. To ensure you understand the financial implications, we require you to complete a one-hour financial counseling session with Navicore Solutions, a non-profit organization specializing in financial wellness consultations.',
  proceedToCounseling: 'Proceed with counseling',
  submit: 'Submit',
  thanks:
    'Thanks, %firstName%, weʼve received your response! Please review your task list below for any remaining outstanding items.',
  thanksWeve:
    'Thanks, weʼve received your response! Please review your task list below for any remaining outstanding items.',
  youWillParticipate:
    'You will participate in a one-hour session of financial counseling from Navicore Solutions. Please see below for next steps.',
  navicoreNextSteps: [
    'Navicore Solutions will contact you soon!',
    'Select a time for the session that works for you. The session will happen over the phone.',
    'Navicore Solutions will notify Point once you’ve completed the session.',
  ],
  prepareForCounseling: 'Prepare for financial counseling',
};
