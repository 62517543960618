// Searchable AKA:
// exit calculator, payoff estimator
import React, { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classNames from 'classnames';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import { nanoid } from 'nanoid';
import { CSSTransition } from 'react-transition-group';
import { useTheme } from 'styled-components';
import {
  Button,
  Container,
  DirectionAndPlacement,
  Header,
  Icon,
  IconName,
  Input,
  InputChangeEvent,
  Key,
  Loader,
  Size,
  SplashText,
  Style,
  TemplatedText,
  directory,
  eventHasKey,
  messagingI18n,
  normalizeToNumber,
  normalizeToString,
  templatedString,
  useIsMobile,
  useScrollSpy,
} from '@pointdotcom/pds';
import FullScreenLoading from 'components/FullScreenLoading';
import MainHeader from 'components/MainHeader';
import { NavItem } from 'components/MainHeader/nav';
import PayoffModal from 'components/PayoffModal';
import ShadowBox from 'components/ShadowBox';
import Tooltip from 'components/Tooltip';
import ErrorPage, { ErrorType } from 'containers/ErrorPage';
import { Page, getPathFromPage } from 'containers/helpers';
import { useDashboardLogout } from 'containers/prequal/hooks';
import { Redirect } from 'containers/routerHelpers';
import { usePostHogEvents } from 'lib/posthogEvents';
import { currencyMask, percMask } from 'models/helpers';
import {
  useGetContractProjectionsQuery,
  useLazyGetContractExitQuery,
} from 'services/api/homeownerApi';
import {
  ContractErrorCode,
  ContractErrors,
  ContractExitDetails,
  ContractProjections,
  PayoffProjection,
  contractErrorsSchema,
} from 'services/apiTypes/contractTypes';
import { helpCenterUrls } from './constants';
import i18n from './i18n';
import * as styles from './styles';

const MIN_HOME_VALUE = 100_000;
const MAX_HOME_VALUE = 20_000_000;

enum InputFieldLabel {
  HomeValue = 'homeValue',
  PayoffDate = 'payoffDate',
}

const labelFieldMap = {
  [InputFieldLabel.HomeValue]: i18n.estimatedHomeValue,
  [InputFieldLabel.PayoffDate]: i18n.estimatedPayoffDate,
};

// Both ContractErrorCode.Unknown and ContractErrorCode.InvalidFormat produce the fallback string of i18n.pleaseEnterAValidField
const errorcodeTextMap: Partial<Record<ContractErrorCode, Record<InputFieldLabel, string>>> = {
  [ContractErrorCode.OutOfRange]: {
    [InputFieldLabel.HomeValue]: i18n.fieldShouldContainAValue,
    [InputFieldLabel.PayoffDate]: i18n.fieldShouldContainADate,
  },
};

type ContractApiError = FetchBaseQueryError & {
  data: ContractErrors;
};

interface WelcomeSectionProps {
  homeownerName: string;
}

// https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling
export function isContractApiError(error: unknown): error is ContractApiError {
  if (typeof error === 'object' && error !== null && 'data' in error) {
    const contractApiError = error as ContractApiError;
    if (
      contractApiError.status !== undefined &&
      typeof contractApiError.data === 'object' &&
      contractApiError.data !== null
    ) {
      const parsedError = contractErrorsSchema.safeParse(contractApiError.data);
      return parsedError.success;
    }
  }
  return false;
}

const getErrorMessage = (
  fieldName: InputFieldLabel,
  fieldErrorCode: ContractErrorCode,
  templateValues?: Record<string, unknown>
): React.ReactNode => {
  const field = labelFieldMap[fieldName];
  const template = errorcodeTextMap?.[fieldErrorCode]?.[fieldName] || i18n.pleaseEnterAValidField;

  const dateMin =
    typeof templateValues?.dateMin === 'string' ? (
      <styles.DateTextStyle key="dateMin">
        {dayjs(templateValues.dateMin).format('MM/DD/YYYY')}
      </styles.DateTextStyle>
    ) : null;
  const dateMax =
    typeof templateValues?.dateMax === 'string' ? (
      <styles.DateTextStyle key="dateMax">
        {dayjs(templateValues.dateMax).format('MM/DD/YYYY')}
      </styles.DateTextStyle>
    ) : null;

  const values = {
    field,
    ...templateValues,
    minHomeValue: currencyMask.getFormatted(MIN_HOME_VALUE),
    maxHomeValue: currencyMask.getFormatted(MAX_HOME_VALUE),
    dateMin,
    dateMax,
  };

  return <TemplatedText values={values}>{template}</TemplatedText>;
};

const homeValueIsInRange = (homeValue: number): boolean => {
  return homeValue >= MIN_HOME_VALUE && homeValue <= MAX_HOME_VALUE;
};

const payoffDateIsInRange = (payoffDate: string, dateMin: string, dateMax: string): boolean => {
  const payoff = dayjs(payoffDate);
  const minDate = dayjs(dateMin).add(-1, 'day');
  const maxDate = dayjs(dateMax).add(1, 'day');

  return payoff.isAfter(minDate) && payoff.isBefore(maxDate);
};

const WelcomeSection = React.forwardRef<HTMLElement, WelcomeSectionProps>(
  ({ homeownerName }, ref) => {
    const headerId = useId();
    return (
      <styles.WelcomeSectionStyle ref={ref} aria-labelledby={headerId}>
        <Header preHeader={`${homeownerName},`} styleSize={Size.Large} id={headerId}>
          Welcome to your <br />
          payoff estimator
        </Header>
        <SplashText>
          <TemplatedText
            values={{
              homeownerHelpCenter: (
                <a href={helpCenterUrls.payoffArticle} target="_blank" rel="noreferrer">
                  {i18n.homeownerHelpCenter}
                </a>
              ),
            }}
          >
            {i18n.youCanReview}
          </TemplatedText>
        </SplashText>
      </styles.WelcomeSectionStyle>
    );
  }
);

type LabeledContainerTTClick = (isOpen: boolean) => void;

const LabeledContainer = ({
  children,
  labelText,
  disclaimer,
  tooltipContent,
  onTTChange,
}: {
  children: React.ReactNode;
  labelText: string;
  disclaimer?: DisclaimerItem;
  tooltipContent: React.ReactNode;
  onTTChange?: LabeledContainerTTClick;
}) => {
  const [tTOpen, setTTOpen] = useState(false);
  const ttRef = useRef<HTMLElement>(null);
  const id = useId();

  useEffect(() => {
    onTTChange?.(tTOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tTOpen]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (eventHasKey(e, [Key.Enter, Key.Space])) {
      e.preventDefault();
      setTTOpen(!tTOpen);
    }
  };

  const footnoteNum = disclaimer?.number;
  const footenoteId = disclaimer?.id;

  return (
    <styles.LabeledContainerStyle
      dim={typeof children === 'string'}
      className={classNames({ tTOpen })}
    >
      <aside>
        <styles.LabelContainerHeaderStyle aria-describedby={footenoteId}>
          {labelText}
          {footnoteNum ? (
            <sup className="labelText" aria-hidden="true">
              {footnoteNum}
            </sup>
          ) : null}
        </styles.LabelContainerHeaderStyle>
        {/* eslint-disable jsx-a11y/no-noninteractive-tabindex */}
        {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
        <abbr
          aria-haspopup="true"
          className="tooltip"
          tabIndex={0}
          aria-describedby={`${id}-tooltip`}
          ref={ttRef}
          onClick={() => {
            setTTOpen(!tTOpen);
          }}
          onKeyDown={handleKeyDown}
          aria-label={labelText}
        >
          <Icon name={IconName.QuestionMark} />
        </abbr>
      </aside>
      <div>
        <Tooltip
          inline
          positionRef={ttRef}
          id={`${id}-tooltip`}
          isOpen={tTOpen}
          yPos={DirectionAndPlacement.Bottom}
          onClose={() => {
            setTTOpen(false);
          }}
        >
          {tooltipContent}
        </Tooltip>
      </div>
      {children}
    </styles.LabeledContainerStyle>
  );
};

const disclaimerMap = {
  homeValue: {
    id: nanoid(),
    text: i18n.disclaimer1,
    number: 1,
  },
  heiPercentage: {
    id: nanoid(),
    text: i18n.disclaimer2,
    number: 2,
  },
  appreciationStartingValue: {
    id: nanoid(),
    text: i18n.disclaimer3,
    number: 3,
  },
  cap: {
    id: nanoid(),
    text: i18n.disclaimer4,
    number: 4,
  },
  estimateOnly: {
    id: nanoid(),
    text: i18n.disclaimer5,
    number: 5,
  },
  futureRepayment: {
    id: nanoid(),
    text: i18n.disclaimer6,
    number: 6,
  },
} as const;

type DisclaimerItem = (typeof disclaimerMap)[keyof typeof disclaimerMap];
const disclaimerArray: DisclaimerItem[] = Object.values(disclaimerMap).sort(
  (a, b) => a.number - b.number
);

interface PayoffFactorsSectionProps {
  payoffData: ContractExitDetails;
  error?: ContractApiError;
  homeValue: number | null | string;
  setHomeValue: (homeValue: string | number | null) => void;
  payoffDate: string | null;
  setPayoffDate: (payoffDate: string | null) => void;
  dateMin: string;
  dateMax: string;
}

const PayoffFactorsSection = ({
  payoffData,
  error,
  homeValue,
  setHomeValue,
  payoffDate,
  setPayoffDate,
  dateMin,
  dateMax,
}: PayoffFactorsSectionProps) => {
  const [typingHomeValueError, setTypingHomeValueError] = useState<ContractErrorCode | null>(null);
  const [typingPayoffDateError, setTypingPayoffDateError] = useState<ContractErrorCode | null>(
    null
  );
  const [formTTOpen, setFormTTOpen] = useState(false);
  const [textTTOpen, setTextTTOpen] = useState(false);

  const handleFormTTChange: LabeledContainerTTClick = useCallback((isOpen) => {
    setFormTTOpen(isOpen);
  }, []);

  const handleTextTTChange: LabeledContainerTTClick = useCallback((isOpen) => {
    setTextTTOpen(isOpen);
  }, []);

  const handleHomeValueChange: InputChangeEvent = useCallback(
    (e, { value }) => {
      setTypingHomeValueError(null);
      const numberValue = normalizeToNumber(value);

      if (numberValue === homeValue) {
        return;
      }

      if (!homeValueIsInRange(numberValue)) {
        setTypingHomeValueError(ContractErrorCode.OutOfRange);
      }

      // only allow the update when the change to the field is NOT being made internally (such as when it is being masked as it is here)
      // This should probably be addressed in PDS in some way
      if (!e.target) {
        return;
      }
      // allow for an empty string in the field. Without this, the empty string value will turn to $0
      setHomeValue(value === '' ? value : numberValue);
    },
    [homeValue, setHomeValue]
  );

  const handleDateChange: InputChangeEvent = useCallback(
    (e, { value }) => {
      setTypingPayoffDateError(null);
      if (value === payoffDate) {
        return;
      }

      if (!payoffDateIsInRange(value, dateMin, dateMax)) {
        setTypingPayoffDateError(ContractErrorCode.OutOfRange);
      }

      setPayoffDate(value);
    },
    [payoffDate, setPayoffDate, dateMin, dateMax]
  );

  // Home value error message
  const isContractApiErrorResult = isContractApiError(error);

  const homeValueError = isContractApiErrorResult && error.data[InputFieldLabel.HomeValue];
  const homeValueErrorType = typingHomeValueError || homeValueError;
  const homeValueErrorMessage = homeValueErrorType
    ? getErrorMessage(InputFieldLabel.HomeValue, homeValueErrorType, { field: i18n.thisField })
    : null;

  // payoff date error message
  const payoffDateError = isContractApiErrorResult && error.data[InputFieldLabel.PayoffDate];
  const payoffDateErrorType = typingPayoffDateError || payoffDateError;
  const payoffDateErrorMessage = payoffDateErrorType
    ? getErrorMessage(InputFieldLabel.PayoffDate, payoffDateErrorType, {
        field: i18n.thisField,
        dateMin,
        dateMax,
      })
    : null;

  const handleFocus = useCallback(() => {
    setTypingHomeValueError(null);
    setTypingPayoffDateError(null);
  }, [setTypingHomeValueError, setTypingPayoffDateError]);

  return (
    <styles.PayoffFactorsSectionStyle>
      <Header styleSize={Size.Medium} noMargin>
        Payoff Factors
      </Header>
      <styles.PayoffFactorsContainerStyle>
        <form
          className={classNames({ ttOpenSection: formTTOpen })}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <LabeledContainer
            labelText={labelFieldMap[InputFieldLabel.HomeValue]}
            disclaimer={disclaimerMap.homeValue}
            tooltipContent={
              <>
                <p>{i18n.thisIsAnEstimate}</p>
                <span>{i18n.youMayAdjust}</span>
              </>
            }
            onTTChange={handleFormTTChange}
          >
            <Input
              aria-label={labelFieldMap[InputFieldLabel.HomeValue]}
              noMargin
              value={normalizeToString(homeValue)}
              min={MIN_HOME_VALUE}
              max={MAX_HOME_VALUE}
              onChange={handleHomeValueChange}
              onFocus={handleFocus}
              EXPERIMENTAL_useUnformattedValues
              mask={currencyMask}
              error={!!homeValueError}
              helptext={homeValueErrorMessage}
            />
          </LabeledContainer>

          <LabeledContainer
            labelText={labelFieldMap[InputFieldLabel.PayoffDate]}
            tooltipContent={i18n.thisIsTheDate}
            onTTChange={handleFormTTChange}
          >
            <Input
              aria-label={labelFieldMap[InputFieldLabel.PayoffDate]}
              noMargin
              type="date"
              dateMin={dayjs(dateMin).format('YYYY-MM-DD')}
              dateMax={dayjs(dateMax).format('YYYY-MM-DD')}
              value={normalizeToString(payoffDate)}
              onChange={handleDateChange}
              onFocus={handleFocus}
              error={!!payoffDateError}
              helptext={payoffDateErrorMessage}
            />
          </LabeledContainer>
        </form>
        <div className={classNames({ ttOpenSection: textTTOpen })}>
          <LabeledContainer
            labelText={i18n.investmentAmount}
            tooltipContent={i18n.thisIsTheMoney}
            onTTChange={handleTextTTChange}
          >
            {currencyMask.getFormatted(payoffData.investmentPayment)}
          </LabeledContainer>

          <LabeledContainer
            labelText={i18n.heiPercentage}
            disclaimer={disclaimerMap.heiPercentage}
            tooltipContent={i18n.thisIsThePortion}
            onTTChange={handleTextTTChange}
          >
            {percMask.getFormatted(payoffData.optionPercentage)}
          </LabeledContainer>

          <LabeledContainer
            labelText={i18n.pointsShare}
            tooltipContent={i18n.thisIsTheAmount}
            onTTChange={handleTextTTChange}
          >
            {currencyMask.getFormatted(payoffData.pointShare)}
          </LabeledContainer>

          <LabeledContainer
            labelText={i18n.appreciationStartingValue}
            disclaimer={disclaimerMap.appreciationStartingValue}
            tooltipContent={i18n.thisIsTheValue}
            onTTChange={handleTextTTChange}
          >
            {currencyMask.getFormatted(payoffData.originalAgreedValue)}
          </LabeledContainer>
          <LabeledContainer
            labelText={i18n.totalAppreciation}
            tooltipContent={
              <TemplatedText
                values={{
                  footnote3: () => (
                    <sup aria-hidden="true">{disclaimerMap.appreciationStartingValue.number}</sup>
                  ),
                  disclaimer3: () => (
                    <span role="note">({disclaimerMap.appreciationStartingValue.text})</span>
                  ),
                }}
              >
                {i18n.thisIsEqual}
              </TemplatedText>
            }
            onTTChange={handleTextTTChange}
          >
            {currencyMask.getFormatted(payoffData.appreciation)}
          </LabeledContainer>
          <LabeledContainer
            labelText={i18n.cappedRepaymentAmount}
            tooltipContent={
              <TemplatedText
                values={{
                  disclaimer4: () => <span role="note">({disclaimerMap.cap.text})</span>,
                  footnote4: () => <sup aria-hidden="true">{disclaimerMap.cap.number}</sup>,
                }}
              >
                {i18n.theHomeownerProtectionCap}
              </TemplatedText>
            }
            onTTChange={handleTextTTChange}
          >
            {currencyMask.getFormatted(payoffData.capAmount)}
          </LabeledContainer>
        </div>
      </styles.PayoffFactorsContainerStyle>
    </styles.PayoffFactorsSectionStyle>
  );
};

interface PayoffEstimateSectionProps {
  payoffEstimate: number;
  capped: boolean;
  openModal: () => void;
  isLoading?: boolean;
  error: ContractApiError;
  dateMin: string;
  dateMax: string;
}

const PayoffEstimateSection = ({
  payoffEstimate,
  capped,
  openModal,
  isLoading,
  error,
  dateMin,
  dateMax,
}: PayoffEstimateSectionProps) => {
  const { scrollY } = useScrollSpy();
  const scrolledPastABit = scrollY > 40;
  const errorId = useId();
  const isContractApiErrorResult = isContractApiError(error);

  let headerText = i18n.yourPayoffEstimate;
  if (capped) {
    headerText = i18n.yourCappedPayoff;
  }

  let content = (
    <CSSTransition in={isLoading} timeout={0} classNames="fade">
      <styles.PayoffEstimateMainContentStyle>
        <SplashText noMargin styleAlign={DirectionAndPlacement.Center} id="payoffEstimateLabel">
          {headerText}
        </SplashText>
        <Header
          styleSize={Size.Splash2}
          styleAlign={DirectionAndPlacement.Center}
          aria-live="polite"
          aria-labelledby="payoffEstimateLabel"
        >
          {currencyMask.getFormatted(payoffEstimate)}
          <sup aria-hidden="true">*</sup>
        </Header>
        <span role="note">{i18n.theTotalPayoff}</span>
        {payoffEstimate > 0 && (
          <Button block styleType={Style.Dark} onClick={openModal} disabled={isLoading}>
            {i18n.seeTheMath}
          </Button>
        )}
      </styles.PayoffEstimateMainContentStyle>
    </CSSTransition>
  );

  // Remove any unknown fields from the error data
  const filteredErrorData =
    isContractApiErrorResult && error.data
      ? Object.entries(error.data).filter(([fieldName]) => {
          return Object.values(InputFieldLabel).includes(fieldName as InputFieldLabel);
        })
      : [];

  const unknownErrorPresent = !!error && filteredErrorData.length === 0;

  if (unknownErrorPresent) {
    content = (
      <styles.PayoffEstimateErrorContentStyle>
        <h2 id={errorId} className="error">
          <TemplatedText
            values={{
              email: () => (
                <a href={`mailto: ${directory.PointEmail.Servicing}`}>
                  {directory.PointEmail.Support}
                </a>
              ),
              phone: () => (
                <a href={`tel: ${directory.PointNumber.Servicing}`}>
                  {directory.PointNumber.Support}
                </a>
              ),
            }}
          >
            {messagingI18n.errors.wereSorry}
          </TemplatedText>
        </h2>
      </styles.PayoffEstimateErrorContentStyle>
    );
  }

  if (filteredErrorData.length > 0) {
    content = (
      <styles.PayoffEstimateErrorContentStyle>
        <h2 id={errorId}>{i18n.pleaseCorrect}</h2>
        <hr />
        <ul aria-live="polite" aria-labelledby={errorId}>
          {filteredErrorData.map((errorItem) => {
            const [fieldName, fieldErrorCode] = errorItem as [InputFieldLabel, ContractErrorCode];

            return (
              <li key={`${fieldName}-error`}>
                {getErrorMessage(fieldName, fieldErrorCode, { dateMin, dateMax })}
              </li>
            );
          })}
        </ul>
      </styles.PayoffEstimateErrorContentStyle>
    );
  }

  return (
    <styles.PayoffEstimateSectionStyle scrolledPastABit={scrolledPastABit}>
      <ShadowBox framed>
        {content}
        {isLoading && (
          <styles.PayoffEstimateLoadingContentStyle>
            <SplashText noMargin styleAlign={DirectionAndPlacement.Center}>
              {i18n.updating}
            </SplashText>
            <Loader styleSize={Size.Default} />
          </styles.PayoffEstimateLoadingContentStyle>
        )}
      </ShadowBox>
    </styles.PayoffEstimateSectionStyle>
  );
};

const FinePrintSection = () => {
  return (
    <styles.FinePrintSectionStyle>
      <p aria-hidden="true">
        <sup className="leading">*</sup>
        {i18n.theTotalPayoff}
      </p>
      <div>
        <TemplatedText
          values={{
            thisIsAnEstimateOnly: (
              <h4 aria-describedby={disclaimerMap.estimateOnly.id}>{i18n.thisIsAnEstimateOnly}</h4>
            ),
            footnote5: (
              <strong>
                <sup aria-hidden="true">{disclaimerMap.estimateOnly.number}</sup>
              </strong>
            ),
            homeownerHelpCenter: (
              <a
                key="repayCategory-link"
                href={helpCenterUrls.repayCategory}
                target="_blank"
                rel="noreferrer"
              >
                {i18n.homeownerHelpCenter}
              </a>
            ),
          }}
        >
          {i18n.thisIsAnEstimateTheFinalAmount}
        </TemplatedText>
      </div>
    </styles.FinePrintSectionStyle>
  );
};

const ProjectionSummary = ({ projection }: { projection: PayoffProjection }) => {
  const payoffDate = dayjs(projection.payoffDate).format('YYYY');
  const payoffAmount = currencyMask.getFormatted(Math.round(projection.payoffEstimate));

  return (
    <styles.ProjectionSummaryStyle>
      <span>
        <TemplatedText values={{ payoffDate }}>{i18n.yourProjection}</TemplatedText>
      </span>
      <span>
        <strong>{payoffAmount}</strong>
      </span>
    </styles.ProjectionSummaryStyle>
  );
};

interface GraphSectionProps {
  termYears: number;
  projectionsData?: ContractProjections;
}

const GraphSection = ({ termYears, projectionsData }: GraphSectionProps) => {
  const theme = useTheme();
  const [positionElement, setPositionElement] = useState<HTMLElement>();
  const [toolTipIndexOpen, setToolTipIndexOpen] = useState<number | null>(null);
  const tooltipTimerRef = useRef<NodeJS.Timeout>();
  const [tooltipContent, setTooltipContent] = useState<React.ReactNode>(null);
  const { isMobile } = useIsMobile();
  const maxBarHeightPercentage = 80;
  const maxGraphBars = isMobile ? 10 : 15;

  const graphData = useMemo(() => {
    const data = projectionsData?.payoffProjections ?? [];
    return data.slice(0, maxGraphBars);
  }, [projectionsData, maxGraphBars]);

  const maxPayoffAmount = useMemo(() => {
    const payoffAmounts = graphData.map((projection) => projection.payoffEstimate);
    if (payoffAmounts.length) {
      return Math.max(...payoffAmounts);
    }
    return 0;
  }, [graphData]);

  const handleBarEnter = (
    e: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>,
    index: number
  ) => {
    setPositionElement(e.currentTarget);
    setTooltipContent(<ProjectionSummary projection={graphData[index]} />);
    setToolTipIndexOpen(index);
    if (tooltipTimerRef.current) {
      clearTimeout(tooltipTimerRef.current);
    }
  };

  const handleBarLeave = () => {
    tooltipTimerRef.current = setTimeout(() => setToolTipIndexOpen(null), 250);
  };

  if (!maxPayoffAmount) {
    return null;
  }

  if (termYears < 30) {
    return null;
  }

  return (
    <styles.GraphSectionStyle>
      <Tooltip
        positionElement={positionElement}
        yPos={DirectionAndPlacement.Top}
        id="bar"
        isOpen={toolTipIndexOpen !== null}
        yMargin="20px"
        maxWidth="14rem"
      >
        {tooltipContent}
      </Tooltip>
      <styles.GraphStyle>
        {graphData?.map((projection, idx) => {
          const label = `${templatedString({ values: { ...projection }, template: i18n.yourProjection })}: ${currencyMask.getFormatted(Math.round(projection.payoffEstimate))}`;
          return (
            <styles.GraphBarStyle
              role="img"
              aria-label={label}
              tabIndex={0}
              key={idx}
              style={{
                height: `${(projection.payoffEstimate / maxPayoffAmount) * maxBarHeightPercentage}%`,
                backgroundColor: toolTipIndexOpen === idx ? theme.Color.PointBlack : undefined,
              }}
              onMouseEnter={(e) => handleBarEnter(e, idx)}
              onMouseLeave={handleBarLeave}
              onFocus={(e) => handleBarEnter(e, idx)}
              onBlur={handleBarLeave}
            ></styles.GraphBarStyle>
          );
        })}
      </styles.GraphStyle>
      <span>{i18n.futureRepayment}</span>
      <sup>{disclaimerMap.futureRepayment.number}</sup>
    </styles.GraphSectionStyle>
  );
};

interface FooterSectionProps {
  pointOptionId: string;
  serviceAccountId: string;
  appreciationRate?: number;
}

const FooterSection = ({
  pointOptionId,
  serviceAccountId,
  appreciationRate = 0.045,
}: FooterSectionProps) => {
  const pointIdElementId = useId();
  const subservicerAccountElementId = useId();
  return (
    <styles.FooterSectionStyle>
      <Container>
        <styles.AccountDetailStyle>
          <div>
            <h5>
              <TemplatedText values={{ br: () => <br /> }}>{i18n.haveAnyQuestions}</TemplatedText>
            </h5>
            <styles.ContactOptionsStyle>
              <a href={helpCenterUrls.repayCategory} target="_blank" rel="noreferrer">
                {i18n.homeownerHelpCenter}
              </a>
              <a href={`mailto: ${directory.PointEmail.Servicing}`}>
                {directory.PointEmail.Servicing}
              </a>
              <a href={`tel: ${directory.PointNumber.Servicing}`} key="servicing-link">
                {directory.PointNumber.Servicing}
              </a>
            </styles.ContactOptionsStyle>
          </div>
          <div>
            <div>
              <div id={pointIdElementId} aria-hidden="true">
                {i18n.pointId}
              </div>
              <div aria-labelledby={pointIdElementId}>{pointOptionId}</div>
            </div>
            <hr aria-hidden="true" />
            <div>
              <div id={subservicerAccountElementId} aria-hidden="true">
                {i18n.subservicerAccount}
              </div>
              <div aria-labelledby={subservicerAccountElementId}>{serviceAccountId}</div>
            </div>
          </div>
        </styles.AccountDetailStyle>
      </Container>
      <hr />
      <Container>
        <styles.DisclaimerSectionStyle>
          <ol>
            {disclaimerArray.map(({ text, id, number }) => (
              <li key={id} id={id}>
                <span>{number}. </span>
                <TemplatedText
                  values={{
                    appreciationPct: () => percMask.getFormatted(appreciationRate * 100),
                  }}
                >
                  {text}
                </TemplatedText>
              </li>
            ))}
          </ol>
        </styles.DisclaimerSectionStyle>
      </Container>
    </styles.FooterSectionStyle>
  );
};

export default function PostFundingCalculator() {
  const { dashboardLogout } = useDashboardLogout();
  const { data: projectionsData, error: projectionsApiError } = useGetContractProjectionsQuery();
  const [homeValue, setHomeValue] = useState<null | number | string>(null);
  const [payoffDate, setPayoffDate] = useState<null | string>(null);
  // Loading has to be managed separately from isLoading below since the debounced call to triggerGetContractExit seems to confuse that isLoading prop
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [triggerGetContractExit, { data: payoffData, isLoading, error: exitApiError }] =
    useLazyGetContractExitQuery();
  const FETCH_DEBOUNCE_MS = 1000;

  const debouncedTriggerGetContractExit = useMemo(() => {
    return debounce(
      async (typedHomeValue: number | null, typedPayoffDate: string | null) => {
        try {
          await triggerGetContractExit({
            homeValue: typedHomeValue,
            payoffDate: typedPayoffDate,
          }).unwrap();
        } catch (_) {
          // Note that errors are handled in "error" above
        } finally {
          setIsUpdateLoading(false);
        }
      },
      FETCH_DEBOUNCE_MS,
      { trailing: true }
    );
  }, [triggerGetContractExit]);

  // get the initial data
  useEffect(() => {
    (async () => {
      try {
        const data = await triggerGetContractExit({ homeValue: null, payoffDate: null }).unwrap();
        setHomeValue(data.homeValue);
        setPayoffDate(data.payoffDate);
      } catch (_) {
        // Error is handled by the `error` property, so nothing is needed here
      }
    })();
  }, [triggerGetContractExit]);

  const posthogEvents = usePostHogEvents();
  const optionId = payoffData?.optionId;
  useEffect(() => {
    if (optionId != null) {
      posthogEvents.capturePayoffEstimatorLoaded({ optionId });
    }
  }, [optionId, posthogEvents]);

  const [payoffModalOpen, setPayoffModalOpen] = React.useState(false);
  const welcomeSectionRef = useRef<HTMLElement>(null);
  const navItems: Array<NavItem> = [
    {
      text: i18n.logOut,
      action: () => {
        dashboardLogout();
      },
    },
  ];

  if ((exitApiError as FetchBaseQueryError)?.status === 410) {
    return <ErrorPage errorType={ErrorType.ContractExpired} />;
  }

  if (isLoading) {
    return <FullScreenLoading />;
  }

  const needsAuth =
    (projectionsApiError && (projectionsApiError as FetchBaseQueryError).status === 401) ||
    (exitApiError && (exitApiError as FetchBaseQueryError).status === 401);

  if (needsAuth) {
    return <Redirect to={`${getPathFromPage(Page.DASHBOARD_LOGIN)}?source=authrequired`} />;
  }

  if (!payoffData) {
    if (exitApiError) {
      return <ErrorPage />;
    }
    return null;
  }

  // Round the dollar values returned by the exit API before display
  const roundedPayoffData = {
    ...payoffData,
    appreciation: Math.round(payoffData.appreciation),
    capAmount: Math.round(payoffData.capAmount),
    originalAgreedValue: Math.round(payoffData.originalAgreedValue),
    uncappedAmount: Math.round(payoffData.uncappedAmount),

    // Never show a negative payoff
    payoffEstimate: Math.round(Math.max(0, payoffData.payoffEstimate)),
    pointShare: Math.round(Math.max(payoffData.pointShare, -payoffData.investmentPayment)),
  };

  const dateMin = payoffData.quarterlyPayoffDate;
  const dateMax = payoffData.contractEndDate;

  const setHomeValueStateAndTrigger = (typedHomeValue: string | number | null) => {
    setHomeValue(typedHomeValue);
    if (homeValueIsInRange(normalizeToNumber(typedHomeValue))) {
      setIsUpdateLoading(true);
      debouncedTriggerGetContractExit(normalizeToNumber(typedHomeValue), payoffDate);
    }
  };

  const setPayoffDateStateAndTrigger = (typedPayoffDate: string | null) => {
    setPayoffDate(typedPayoffDate);
    if (typedPayoffDate && payoffDateIsInRange(typedPayoffDate, dateMin, dateMax)) {
      setIsUpdateLoading(true);
      debouncedTriggerGetContractExit(normalizeToNumber(homeValue), typedPayoffDate);
    }
  };

  return (
    <>
      <MainHeader navItems={navItems} showSubHeader={false} />
      <PayoffModal
        exitData={roundedPayoffData}
        isOpen={payoffModalOpen}
        onClose={() => setPayoffModalOpen(false)}
      />
      <Container mobileCollapse>
        <styles.MainContentContainer>
          <WelcomeSection
            homeownerName={roundedPayoffData.contact.firstName}
            ref={welcomeSectionRef}
          />
          <PayoffEstimateSection
            error={exitApiError as ContractApiError}
            payoffEstimate={roundedPayoffData.payoffEstimate}
            capped={roundedPayoffData.capped}
            openModal={() => setPayoffModalOpen(true)}
            isLoading={isLoading || isUpdateLoading}
            dateMin={dateMin}
            dateMax={dateMax}
          />
          <PayoffFactorsSection
            payoffData={roundedPayoffData}
            error={exitApiError as ContractApiError}
            homeValue={homeValue}
            setHomeValue={setHomeValueStateAndTrigger}
            payoffDate={payoffDate}
            setPayoffDate={setPayoffDateStateAndTrigger}
            dateMin={dateMin}
            dateMax={dateMax}
          />
          <styles.FinePrintAndGraphSection>
            <FinePrintSection />
            <GraphSection
              termYears={roundedPayoffData.contractTerm}
              projectionsData={projectionsData}
            />
          </styles.FinePrintAndGraphSection>
        </styles.MainContentContainer>
      </Container>
      <FooterSection
        pointOptionId={roundedPayoffData.optionId}
        serviceAccountId={roundedPayoffData.subservicerId}
        appreciationRate={projectionsData?.projectedAppreciationRate}
      />
    </>
  );
}
