export default {
  haveAQuestion: 'Have a question?',
  weWillSend: 'We will send this message to your account manager',
  toName: 'To: %name%',
  howDoIFind: 'How do I find the requested document?',
  whatInformation: 'What information is needed on the document?',
  other: 'Other',
  tellUsMore: 'Tell us more about your question',
  submit: 'Submit',
  messageSent: 'Message sent',
  thankYou:
    'Thank you for reaching out, we will get back to you shortly. Please check your email for our response.',
  close: 'Close',
};
