import styled, { css } from 'styled-components';
import { ContainerStyles, SplashTextStyles } from '@pointdotcom/pds';
import { pxToRem } from 'styleHelpers';
import { BorderedSectionStyle, paddedSectionPadding } from 'styles/';

const ctaPadding = '11%';
const ctaPaddingMobile = '3rem';

const CTAContainerStyle = styled.div.attrs({ className: 'ContainerStyle' as string })`
  flex: 1;
  width: 50%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    flex: auto;
    width: 100%;
    max-width: 450px;
    justify-content: center;
  }
`;

export const SecondaryCTAContainerStyle = styled(CTAContainerStyle).attrs({
  className: 'SecondaryCTAContainerStyle',
})`
  padding-right: ${ctaPadding};

  &::before {
    content: '';
    display: none;
    position: absolute;
    border-right: 1px solid ${({ theme }) => theme.line.color.default};
    top: calc(0px - ${paddedSectionPadding});
    bottom: calc(0px - ${paddedSectionPadding});
    right: 0;
    width: 0;
  }

  &::before {
    display: block;
  }
  position: relative;
  &::after {
    content: attr(data-content);
    display: none;
    background: white;
    position: absolute;
    right: 0;
    bottom: 50%;
    padding: 1.1em;
    transform: translate(50%, 50%);
    color: ${(props) => props.theme.Color.Gray3};
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding: 0 0 ${ctaPaddingMobile};
    border-right: 0;
    border-bottom: 1px solid ${(props) => props.theme.line.color.default};
    &::before {
      display: none;
    }
    &::after {
      display: block;
      right: 50%;
      bottom: 0;
    }
  }
`;

export const PrimaryCTAContainerStyle = styled(CTAContainerStyle).attrs({
  className: 'PrimaryCTAContainerStyle',
})<{ singleCTA?: boolean }>`
  padding-left: ${ctaPadding};
  ${(props) => {
    if (props.singleCTA) {
      return css`
        display: flex;
        flex-flow: row nowrap;
      `;
    }

    return null;
  }}
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding: ${ctaPaddingMobile} 0 0;
    flex-flow: column nowrap;
  }
`;

export const FooterBookOrContinueStyle = styled(BorderedSectionStyle).attrs({
  className: 'FooterBookOrContinueStyle',
})`
  ${ContainerStyles.ContainerStyle} {
    align-items: flex-end;
  }

  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    flex-flow: column nowrap;
    ${ContainerStyles.ContainerStyle} {
      align-items: center;
    }
  }

  @media (min-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    padding-bottom: ${paddedSectionPadding};
  }

  @media only print {
    display: none;
  }
`;

export const SimplifyScheduleButtonStyle = styled.div.attrs({
  className: 'SimplifyScheduleButtonStyle',
})`
  display: contents;

  .ButtonAndLinkContainerStyle {
    > div {
      margin: 0;
    }
  }

  .ButtonLinkStyle {
    display: none;
  }

  @media not (min-width: ${({ theme }) => theme.responsive.mobileMidBreakpoint}) {
    /* Hide profile image for smallest mobiles */
    .ProfileImageStyle {
      display: none;
    }
  }
`;

export const MessageAndButtonStyle = styled.div.attrs({ className: 'MessageAndButtonStyle' })`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;

  ${SplashTextStyles.SplashCopySerifStyle} {
    font-size: ${pxToRem(16)};
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  }
`;
