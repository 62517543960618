import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { Button, DirectionAndPlacement, Input, InputMaskType, Style } from '@pointdotcom/pds';
import { getPathFromPage, pages } from 'containers/helpers';
import MailerLanding from 'containers/prequal/MailerLanding';
import { useUtmParameters } from 'containers/prequal/hooks';
import { getNextPage } from 'containers/prequal/productPageFlow';
import { useHistory } from 'containers/routerHelpers';
import { useLazyGetOfferCodeDetailQuery } from 'services/api/prequalApi';
import { Products } from 'store/constants';

export default function OfferCodePageController() {
  useUtmParameters();
  const history = useHistory();
  const [getOfferCodeDetail, { isFetching }] = useLazyGetOfferCodeDetailQuery();

  const [offerCode, setOfferCode] = useState('');
  const [validationError, setValidationError] = useState(null);

  const handleOfferCodeValueChange = (e, { value }) => {
    setOfferCode(value && value.toUpperCase());
  };

  const clearError = () => {
    setValidationError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (offerCode.length < 6) {
      setValidationError('Offer code is too short');
      return;
    }
    try {
      // Need to .unwrap() this response to trigger the catch block below when offercode is not found
      await getOfferCodeDetail({ offerCode }).unwrap();
      history.push(getNextPage(Products.HEI, pages.PREQUAL_OFFER_CODE));
    } catch (_) {
      history.push(getPathFromPage(pages.PREQUAL_DEFAULT));
    }
  };

  return (
    <>
      <Helmet title="Point HEI - Offer Code" />
      <MailerLanding headerText="Enter your offer code">
        <form noValidate onSubmit={handleSubmit}>
          <Input
            centered
            styleAlign={DirectionAndPlacement.Center}
            styleType={Style.Mono}
            maxLength={7}
            uppercase
            helptext={validationError}
            autoComplete="off"
            mask={InputMaskType.AlphaNumeric}
            placeholder="Your offer code"
            onChange={handleOfferCodeValueChange}
            onFocus={clearError}
            value={offerCode}
          />
          <Button block content="Get started" primary type="submit" loading={isFetching} />
        </form>
      </MailerLanding>
    </>
  );
}
