import styled from 'styled-components';

export const ApplicationPageSubheaderStyle = styled.div.attrs({
  className: 'ApplicationPageSubheaderStyle',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
`;
