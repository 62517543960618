export function getUnderwriteHost() {
  return process.env.REACT_APP_UNDERWRITE_API_ENDPOINT || 'https://apply.point.dev';
}

export function getPrequalHost() {
  return process.env.REACT_APP_PREQUAL_API_ENDPOINT || 'https://get.point.dev';
}

export function getMiddlewareHost() {
  return process.env.REACT_APP_MIDDLEWARE_API_ENDPOINT || 'https://middleware.point.dev';
}

export function getHomeownerHost() {
  return process.env.REACT_APP_HOMEOWNER_API_ENDPOINT || 'https://homeowner-service.point.dev';
}
