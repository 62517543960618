import React from 'react';
import { Header } from '@pointdotcom/pds';
import * as styles from './styles';

const HeaderUpperLower = ({
  styleAlign = 'left',
  styleAlignMobile = 'left',
  capsText = '',
  size = 'default',
  boldText = '',
  capsOnTop = true,
}) => {
  const CapsHeader = (
    <styles.HeaderCapsStyle styleAlign={styleAlign} styleAlignMobile={styleAlignMobile}>
      {capsText}
    </styles.HeaderCapsStyle>
  );
  const BoldHeader = (
    <Header styleAlign={styleAlign} styleAlignMobile={styleAlignMobile} noMargin size={size}>
      {boldText}
    </Header>
  );

  return (
    <styles.HeaderUpperLowerStyle>
      {capsOnTop ? CapsHeader : BoldHeader}
      {capsOnTop ? BoldHeader : CapsHeader}
    </styles.HeaderUpperLowerStyle>
  );
};

export default HeaderUpperLower;
