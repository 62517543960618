export default {
  watchVideo: 'Watch an HEI Overview Video',
  beforeWeCan:
    'Before we can begin scheduling an appraisal, we ask that you watch the HEI overview video below and answer a short questionnaire.',
  thenQuiz: 'Then, take the quiz',
  aFewThings: 'A few things to note before you begin:',
  fourOfSix: 'You must answer at least 4 out of 6 questions correctly',
  condition1:
    'Point is committed to homeowner education so please contact us anytime you have any questions about HEI. Please note that passing this quiz allows you to skip a 20-minute educational call with us, but you are welcome to still have this call.  We are here to help!',
  condition2: '%fourOfSix% in order to opt out of the educational call with an expert.',
  condition3:
    'You may re-watch the video and submit the quiz as many times as you’d like to get a passing score.',
  startTheQuiz: 'Start the quiz',
  resumeTheQuiz: 'Resume the quiz',
  preferExpert: 'Prefer to speak with an expert?',
  ourExperts:
    'Our experts are happy to discuss the home equity investment with you on a 20-minute educational call.',
  scheduleACall: 'Schedule a call',
};
