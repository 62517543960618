import styled from 'styled-components';
import { PaddedSectionStyle } from 'styles';
import { CapShieldStyle } from '../CapShield/styles';

export const HPCSectionStyle = styled(PaddedSectionStyle).attrs({ className: 'HPCSectionStyle' })`
  position: relative;
  padding-top: 0;

  p {
    max-width: 34em;
    margin-left: auto;
    margin-right: auto;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${CapShieldStyle} {
    flex-shrink: 0;
    width: clamp(94px, 12vw, 123px);
  }
`;

export const HPCSectionTopDividerStyle = styled.div.attrs({
  className: 'HPCSectionTopDividerStyle',
})`
  display: grid;
  gap: 36px;
  grid-template-columns: auto min-content auto;
  align-items: center;
  margin-bottom: 1.5rem;
  position: relative;
  isolation: isolate;

  @media not (min-width: ${({ theme }) => theme.responsive.computerBreakpoint}) {
    gap: 14px;
  }

  &::before,
  &::after {
    display: block;
    content: '';
    border-top: 1px solid ${({ theme }) => theme.line.color.default};
  }

  > :first-child {
    grid-column: 2 / 3;
  }
`;
