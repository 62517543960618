export default {
  logOut: 'Log out',
  homeownerHelpCenter: 'Homeowner Help Center',
  youCanReview:
    'You can review the details of your HEI below and explore how your estimated payoff may change based on your final home value and when you choose to payoff Point. For additional details about your final appraisal and the exit process, check out the %homeownerHelpCenter%.',
  thisIsAnEstimate:
    'This is an estimate of your home’s value when you choose to pay off your HEI. We have pre-populated this based on the increase in the FHFA index for your metropolitan area, applied to the most recent appraisal we have on your home. Please note that your final home value will be determined by a valid appraisal.',
  youMayAdjust:
    'You may adjust this to see what your estimated payoff would be at a different home value.',
  estimatedHomeValue: 'Estimated home value',
  estimatedPayoffDate: 'Estimated payoff date',
  thisIsTheDate:
    'This is the date you plan to pay off your HEI. You may adjust the payoff date to see your estimated payoff based on when you plan to exit.',
  investmentAmount: 'Investment amount',
  thisIsTheMoney: 'This is the money you received from Point.',
  heiPercentage: 'HEI percentage',
  thisIsThePortion:
    'This is the portion of your home’s appreciation you agreed to share with Point.',
  pointsShare: 'Point’s share of appreciation',
  thisIsTheAmount:
    'This is the amount of appreciation Point receives in this scenario. It is based on your final home value.',
  appreciationStartingValue: 'Appreciation Starting Value',
  thisIsTheValue:
    'This is the value from which Point starts calculating appreciation. It was determined by adjusting the initial home value in order to reduce some of Point’s investment risk.',
  totalAppreciation: 'Total appreciation',
  thisIsEqual:
    'This is equal to the final home value minus the Appreciation Starting Value%footnote3%%disclaimer3%.',
  cappedRepaymentAmount: 'Capped Repayment Amount',
  theHomeownerProtectionCap:
    'The Homeowner Protection Cap%disclaimer4%%footnote4% limits your payoff amount. This amount increases daily at an annual rate that’s compounded monthly based on your original investment amount.',
  yourPayoffEstimate: 'Your payoff estimate',
  yourCappedPayoff: 'Your capped payoff estimate',
  seeTheMath: 'See the math',
  theTotalPayoff:
    'The total payoff will include additional fees and/or unpaid Preservation Payments (a payment made to cure a default to protect Point’s interest in the property) as applicable.',
  thisIsAnEstimateTheFinalAmount:
    '%thisIsAnEstimateOnly%%footnote5%. The final amount will be determined at exit by a valid appraisal. Learn more about the appraisal and exit process in our %homeownerHelpCenter%.',
  thisIsAnEstimateOnly: 'This is an estimate only',
  haveAnyQuestions: 'Have any questions, or%br%ready to payoff Point?',
  pointId: 'Point ID',
  subservicerAccount: 'Subservicer Account',
  disclaimer1:
    'This is based on the increase in the FHFA index for your metropolitan area, applied to the most recent appraisal Point has on file for your property.',
  disclaimer2: 'Also referred to as the “Option Percentage.”',
  disclaimer3: 'Also referred to as the “Original Agreed Value.”',
  disclaimer4: 'Also referred to as the “Point Proceeds Cap Factor.”',
  disclaimer5:
    'The total cost will include additional fees and/or unpaid Preservation Payments, as applicable.',
  disclaimer6:
    'This is an illustrative example of the estimated payoff amount based on an annual home price appreciation of %appreciationPct%. Please note that the actual payoff amount will be determined at exit by a valid appraisal.',
  futureRepayment: 'Future repayment estimates',
  yourProjection: 'Your %payoffDate% payoff estimate',
  updating: 'Updating...',

  // errors
  pleaseCorrect: 'Please correct the following errors with the input you’ve given',
  pleaseEnterAValidField: '%field% has an invalid entry',
  fieldShouldContainADate: '%field% should contain a date between %dateMin% and %dateMax%',
  fieldShouldContainAValue:
    '%field% should contain a value between %minHomeValue% and %maxHomeValue%',
  thisField: 'This field',
};
