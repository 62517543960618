import React, { useEffect, useState } from 'react';
import { DirectionAndPlacement } from '@pointdotcom/pds';
import { GraphBar } from '../CapGraphGraphic';
import ShieldGraphic from '../ShieldGraphic';
import * as styles from './styles';

export default function CapBillGraphic() {
  const [loaded, setIsLoaded] = useState(false);
  const billPerc = loaded ? 0.6 : 0;

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 250);
  }, []);

  return (
    <styles.CapBillGraphicStyle>
      <ShieldGraphic direction={DirectionAndPlacement.Right} />
      <GraphBar perc={billPerc} />
    </styles.CapBillGraphicStyle>
  );
}
