import styled from 'styled-components';
import { GraphBarStyle } from '../CapGraphGraphic/styles';
import { ringsCSS } from '../RingsGraphic/styles';
import { ShieldGraphicStyle } from '../ShieldGraphic/styles';
import { TwinkleGraphicStyle } from '../TwinkleGraphic/styles';

export const CapBillGraphicStyle = styled.div.attrs({
  className: 'CapBillGraphicStyle',
})`
  --capBillGaphicSize: 43%;
  position: absolute;
  width: var(--capBillGaphicSize);
  padding-bottom: calc(var(--capBillGaphicSize) * 1.3);
  bottom: 0;
  overflow-y: clip;
  &::before {
    content: '';
    ${ringsCSS};
    width: 60%;
    left: unset;
    right: -31%;
    background-position: center 350%;
  }

  &:after {
    content: '';
    background: ${({ theme }) => theme.Color.PointBlack};
    position: absolute;
    display: block;
    border-radius: 50%;
    border-radius: 50%;
    bottom: -9%;
    width: 62%;
    height: 60%;
    left: 41%;
    filter: blur(7px);
    mix-blend-mode: multiply;
    z-index: -1;
    opacity: 0.9;
  }
  ${ShieldGraphicStyle} {
    --shieldSize: 55%;
    --line-size: 125%;
    bottom: 12%;
  }
  ${GraphBarStyle} {
    transition-duration: 1000ms;
    right: 0;
    position: absolute;
    bottom: 0;
    height: 55%;
    width: 60%;
    clip-path: polygon(
      0% 10%,
      4.55% 5%,
      9.09% 10%,
      13.64% 5%,
      18.18% 10%,
      22.73% 5%,
      27.27% 10%,
      31.82% 5%,
      36.36% 10%,
      40.91% 5%,
      45.45% 10%,
      50% 5%,
      54.55% 10%,
      59.09% 5%,
      63.64% 10%,
      68.18% 5%,
      72.73% 10%,
      77.27% 5%,
      81.82% 10%,
      86.36% 5%,
      90.91% 10%,
      95.45% 5%,
      100% 10%,
      100% 100%,
      0% 100%
    );
    &:after {
      bottom: 18%;
      display: block;
    }
  }
  ${TwinkleGraphicStyle} {
    &:last-child {
      left: 22%;
    }
  }
`;
