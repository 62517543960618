import styled from 'styled-components';
import { ContainerStyles, Header, mixins } from '@pointdotcom/pds';
import { SubHeaderStyle } from 'components/SubHeader/styles';
import {
  ReadOnlyCheckboxStyle,
  itemGapDesktop,
  itemGapMobile,
  taskLeftPaddingDesktop,
  taskLeftPaddingMobile,
} from 'containers/DashboardPage/styles';
import { pxToRem } from 'styleHelpers';
import { paddedSectionPadding, paddedSectionPaddingMobile } from 'styles';

export const DashboardTasksPageStyle = styled.div.attrs({
  className: 'DashboardTasksPageStyle',
})`
  background: white;

  ${SubHeaderStyle} {
    position: relative;
    z-index: 3;
  }
`;

export const PageHeaderStyle = styled.section.attrs({ className: 'PageHeaderStyle' })<{
  hasOpenItems: boolean;
  hasAnyItems: boolean;
  hasCompletedItems: boolean;
}>`
  display: flex;
  height: ${({ hasAnyItems, hasOpenItems, hasCompletedItems }) => {
    if (!hasAnyItems) {
      return 'clamp(500px, 71vh, 700px)';
    }
    if (!hasOpenItems) {
      return 'clamp(250px, 40vh, 320px)';
    }
    if (!hasCompletedItems && hasOpenItems) {
      return 'clamp(250px, 30vh, 320px)';
    }
    return 'auto';
  }};
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    padding: ${paddedSectionPaddingMobile} 0;
    height: auto;
  }
  ${ContainerStyles.ContainerStyle} {
    > * {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-flow: column;
      &:first-child {
        flex: ${({ hasAnyItems }) => (hasAnyItems ? '5' : '4')};
        gap: 2rem;
      }
      &:last-child {
        flex: 3;
      }
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
      flex-direction: column-reverse;
      gap: 1rem;
      > * {
        &:first-child {
          justify-content: flex-start;
          flex: 1;
        }
        &:last-child {
          justify-content: flex-end;
          flex: 1;
        }
      }
    }
  }
`;

export const PageContentStyle = styled.section.attrs({ className: 'PageContentStyle' })`
  display: flex;
  flex-flow: column;
  gap: ${paddedSectionPadding};
  padding-bottom: 15vh;
`;

export const TaskGraphicStyle = styled.div.attrs({ className: 'TaskGraphicStyle' })<{
  hasCompletedItems: boolean;
}>`
  align-self: center;
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    align-self: revert;
  }

  img {
    position: relative;
    bottom: ${({ hasCompletedItems }) => (hasCompletedItems ? '-2.1rem' : '-0.5rem')};
    max-width: 100%;
    @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
      max-width: 400px;
      width: 70%;
      left: -1rem;
      margin: 0;
      bottom: 0;
    }
  }
`;

export const TaskSubHeaderStyle = styled(Header).attrs({ className: 'TaskSubHeaderStyle' })`
  > * {
    line-height: 1.2em;
  }
  a {
    margin-top: 1em;
    display: inline-block;
    font-family: ${({ theme }) => theme.header.fontFamily.splash};
    text-decoration: none;

    ${mixins.desktopHoverCondition()} {
      &:hover {
        text-decoration: revert;
      }
    }
  }
`;

export const CompletedTasksSectionStyle = styled.div.attrs({
  className: 'CompletedTasksSectionStyle',
})`
  ${PageHeaderStyle} + & {
    padding-top: 0;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    padding: 1rem;
    padding-left: ${taskLeftPaddingDesktop};
    border-top: 1px solid ${({ theme }) => theme.line.color.default};
    display: grid;
    grid-template-columns: 1rem 1fr 1fr;
    gap: ${itemGapDesktop};
    align-items: center;

    @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
      grid-template-columns: 1rem 1fr;
      gap: ${itemGapMobile};
      padding-left: ${taskLeftPaddingMobile};
      row-gap: 1rem;
    }

    color: ${({ theme }) => theme.Color.GrayLightestAccessible};

    &:last-child {
      border-bottom: 1px solid ${({ theme }) => theme.line.color.default};
    }
  }
`;

export const ActiveTaskListStyle = styled.div.attrs({
  className: 'ActiveTaskListStyle',
})`
  padding-top: 0;

  & + ${CompletedTasksSectionStyle} {
    padding-top: 0;
  }
`;

export const ReadOnlyCheckboxCheckedStyle = styled(ReadOnlyCheckboxStyle).attrs({
  className: 'ReadOnlyCheckboxCheckedStyle',
})`
  background-color: ${({ theme }) => theme.Color.PointBlue};
  display: flex;
  border: none;
  &::before {
    display: block;
    flex: 1;
    content: '';
    background-image: url('data:image/svg+xml,<svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.741211 2.70368L3.11158 5.07405L7.25973 0.925903" stroke="white" stroke-width="1.4"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const CompletedDateStyle = styled.span.attrs({ className: 'CompletedDateStyle' })`
  color: ${({ theme }) => theme.Color.Gray3};
  justify-self: end;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    grid-column: 2;
    justify-self: start;
    font-size: ${pxToRem(14)};
  }
`;
