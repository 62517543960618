import React, { useState } from 'react';
import { DirectionAndPlacement, ResponsiveText, Size } from '@pointdotcom/pds';
import { continueMyApplicationBreakpoint } from 'containers/EstimatorPage/BookingAndNavFooter';
import bugsnagClient, { attachAxiosError } from 'lib/bugsnagClient';
import { useGetVisitorDataQuery, useLazyGetHeiApplicationUrlQuery } from 'services/api/prequalApi';
import GenericMessageBanner from '../../GenericMessageBanner';
import i18n from './i18n';

export const getText = (visitor) => {
  let actionText = null;
  let messageText = null;

  if (visitor?.hasActiveDocket && !visitor?.hasActiveApplicantFlow) {
    messageText = i18n.looksLikeApplication;
    actionText = i18n.checkStatus;
  } else if (visitor?.hasActiveDocket) {
    messageText = i18n.looksLikeApplication;
    actionText = (
      <ResponsiveText
        mobileText={i18n.continueApplication}
        breakpoint={continueMyApplicationBreakpoint}
      >
        {i18n.continueMy}
      </ResponsiveText>
    );
  } else if (visitor?.estimateKey) {
    messageText = i18n.recentlyCreatedOffer;
    actionText = i18n.viewLatestOffer;
  }

  return [messageText, actionText];
};

const actionHandler = async (getHeiApplicationUrl) => {
  let applicationData = null;

  try {
    applicationData = await getHeiApplicationUrl().unwrap();
    if (applicationData?.url) {
      window.location.assign(applicationData.url);
    } else {
      throw new Error('Invalid application url');
    }

    return true;
  } catch (e) {
    bugsnagClient.notify('Error in ResumeApplication.actionHandler()', (event) => {
      attachAxiosError(event, e);
      event.addMetadata('applicationData', applicationData);
    });

    throw e;
  }
};

export const getBannerProps = (isVisible = false, options = {}) => {
  const { actionText, message, blueTheme, onClose, getHeiApplicationUrl } = options;

  return {
    blueTheme,
    message,
    styleMarginPosition: DirectionAndPlacement.Bottom,
    inverted: false,
    sans: true,
    bordered: true,
    styleAlign: DirectionAndPlacement.Left,
    noMargin: true,
    styleSize: Size.Medium,
    hasClose: true,
    onClose,
    actionText,
    onAction: () => actionHandler(getHeiApplicationUrl),
    show: isVisible,
  };
};

export default function ResumeApplicationBanner({ blueTheme }) {
  const [getHeiApplicationUrl] = useLazyGetHeiApplicationUrlQuery();

  // Some HEI pages do not have `hei` in the params, hence we have to check for products other than HEI

  const [isVisible] = useState(true);
  const { data: visitor } = useGetVisitorDataQuery();
  const [message, actionText] = getText(visitor);

  if (!actionText) return null;

  const options = {
    message,
    actionText,
    blueTheme,
    getHeiApplicationUrl,
  };

  return <GenericMessageBanner {...getBannerProps(isVisible, options)} />;
}
