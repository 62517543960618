import React from 'react';
import { Container } from '@pointdotcom/pds';
import SubNavPaginator, { SubNavPaginatorProps } from 'components/SubNav/SubNavPaginator';
import * as styles from './styles';

const MobilePaginator = ({ navItems, navProps }: SubNavPaginatorProps) => {
  return (
    <styles.MobilePaginatorWrapperStyle>
      <Container>
        <SubNavPaginator navItems={navItems} navProps={navProps} />
      </Container>
    </styles.MobilePaginatorWrapperStyle>
  );
};

export default MobilePaginator;
