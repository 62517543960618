import { useMemo } from 'react';
import { Page, generateUrlFromPage } from 'containers/helpers';
import { useDashboardData } from 'containers/prequal/hooks';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import DashboardModel from 'models/DashboardModel';
import { Task, TaskStatus, TaskType } from 'services/apiTypes/taskTypes';
import { AutomatedDocketQualificationStatus } from 'services/apiTypes/underwriteTypes';

export type ApplicationProcessingOutcome = {
  isProcessing?: boolean;
  applicationTask?: Task & { type: TaskType.Application };
  dashboard?: DashboardModel;
  navigateTo?: string;
  showDashboardModal?: boolean;
  willReview?: boolean;
};

export function useApplicationProcessingOutcome(): ApplicationProcessingOutcome {
  const { dashboard, isLoading: dashboardIsLoading } = useDashboardData();
  const applicationTask = dashboard?.getTask({ type: TaskType.Application });

  const adqStatus = applicationTask?.detail.adqStatus;
  const documentUploadTask = dashboard?.getTask({
    type: TaskType.DocumentUpload,
    status: TaskStatus.Active,
  });

  const isProcessing =
    dashboardIsLoading ||
    dashboard == null ||
    dashboard.authenticate ||
    (dashboard.application?.estimateKey != null && applicationTask == null) ||
    (applicationTask != null && adqStatus == null) ||
    adqStatus === AutomatedDocketQualificationStatus.Processing;
  const isPassed =
    adqStatus === AutomatedDocketQualificationStatus.Passed ||
    adqStatus === AutomatedDocketQualificationStatus.CiqRequired;
  const willReview = !isProcessing && adqStatus != null && !isPassed;

  const applicationTaskCompleteModalFlagEnabled =
    useFeatureFlag(FeatureFlag.ApplicationTaskCompleteModal, {
      skip: !isPassed || documentUploadTask == null,
    }) === 'modal';

  return useMemo(() => {
    if (isProcessing) {
      return { isProcessing, applicationTask };
    }
    if (applicationTask == null || dashboard == null) {
      return { navigateTo: generateUrlFromPage(Page.DASHBOARD) };
    }
    if (!applicationTaskCompleteModalFlagEnabled && documentUploadTask != null) {
      return {
        navigateTo: generateUrlFromPage(Page.DASHBOARD_TASK, { taskId: documentUploadTask.id }),
        willReview,
      };
    } else {
      return {
        applicationTask,
        dashboard,
        navigateTo: generateUrlFromPage(Page.DASHBOARD),
        showDashboardModal: true,
        willReview,
      };
    }
  }, [
    applicationTask,
    applicationTaskCompleteModalFlagEnabled,
    dashboard,
    documentUploadTask,
    isProcessing,
    willReview,
  ]);
}
