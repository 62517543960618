import styled from 'styled-components';
import { IconStyles, mixins } from '@pointdotcom/pds';

export const LeftRightPaginatorStyle = styled.div.attrs({ className: 'LeftRightPaginatorStyle' })`
  font-family: ${({ theme }) => theme.fonts.serif.name};
  font-style: italic;
  font-size: ${({ theme }) => mixins.getRemFromPx(18, theme.globals.baseFontSize.default)};
  color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  display: flex;
  align-items: center;
  gap: 0.4rem;
  button {
    padding: calc(0.5rem + 0.3rem);
    border: 1px solid ${({ theme }) => theme.line.color.default};
    &:active {
      margin-top: 0;
    }
    &:disabled {
      opacity: 0.5;
      background-color: ${({ theme }) => theme.Color.GrayLight1};
      border-color: transparent;
    }
  }
  ${IconStyles.IconStyle} {
    color: ${({ theme }) => theme.Color.Gray4};
  }
`;
