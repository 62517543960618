// https://www.zillow.com/howto/api/BrandingRequirements.htm#zillowlogo
import React from 'react';
import { TemplatedText } from '@pointdotcom/pds';
import i18n from './i18n';
import Logo from './images/Zillow_Wordmark_Blue_RGB_lrg.png';
import * as styles from './styles';

// TODO: API may need to return additional meta info about the brand in order to meet the link
// text requirement (see i18n file) in the terms
// Passing down the chain is hacky and unstable since the address does not persist in the flow

const Zillow = ({ zestimate, zhvi }) => {
  const questionSource = zestimate?.value ? zestimate : zhvi;

  if (!questionSource) {
    return null;
  }

  return (
    <styles.ZillowPartnerBrandingStyle>
      <styles.ImageLinkStyle
        href="http://www.zillow.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={Logo} width="100%" alt="Real Estate on Zillow" />
      </styles.ImageLinkStyle>
      {questionSource.address && questionSource.details_url && (
        <styles.AddressLinkStyle
          href={questionSource.details_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TemplatedText values={{ addr: questionSource.address }}>{i18n.seeMore}</TemplatedText>
        </styles.AddressLinkStyle>
      )}
      {questionSource === zestimate && <p>{i18n.theData}</p>}
      <p>
        {`© Zillow, Inc., 2006-${new Date().getFullYear()}.`}
        {i18n.useIs}
      </p>
    </styles.ZillowPartnerBrandingStyle>
  );
};

export default Zillow;
