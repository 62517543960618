import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import {
  Button,
  DirectionAndPlacement,
  IconName,
  InputMask,
  InputMaskType,
  Logo,
  ProfileImage,
  Size,
  Style,
  TemplatedText,
  directory,
  useRequest,
} from '@pointdotcom/pds';
import { SCHEDULED_STORAGE_KEY } from 'components/ButtonScheduleNextAvailable';
import FullScreenLoading from 'components/FullScreenLoading';
import { navItems as footerNavItems } from 'components/MainFooter';
import PointCopyright from 'components/MainFooter/PointCopyright';
import ContactModal from 'components/SaveApplicantContactModal';
import { generateUrlFromPage, getURLParam, pages } from 'containers/helpers';
import { ModalPageStyle } from 'containers/prequal/ModalPage/styles';
import { useHistory, useLocation, useParams } from 'containers/routerHelpers';
import { dayjs } from 'lib/dayjs';
import AvailabilityModel from 'models/prequal/availability';
import { fetchDirectoryUser } from 'services/middleware';
import { getApplicantModel } from 'store/applicant';
import { Products, productFullName } from 'store/constants';
import { getEstimatesAreLoading, getHEIEstimateModel } from 'store/estimates';
import { setSaveApplicantContactModalOpen } from 'store/general';
import { useDispatch, useSelector } from 'store/hooks';
import { getEstimate } from 'store/thunks/estimates';
import * as appStyles from 'styles/';
import i18n from './i18n';
import * as styles from './styles';

// NOTE: For YCBM redirects to this page, afterbooking URL should be -
// [endpoint]/schedule-complete?email={TEAM-EMAIL}&phone={PHONENUMBER}&datetime={START-BOOKER-ISO-8601}&estimateid={ESTIMATEID}

const P = appStyles.SplashCopySerifStyle; // Naming convenience;

const HeaderBlock = ({ dateTimeObj }) => {
  let preHeader = null;
  let headerText = i18n.youreNowNoData;
  if (dateTimeObj) {
    headerText = i18n.dateAtTime;
    preHeader = i18n.youreNowPreHeader;
  }

  return (
    <styles.ScheduleHeaderStyle
      styleSize={Size.Large}
      styleAlign={DirectionAndPlacement.Left}
      styleAlignMobile={DirectionAndPlacement.Left}
      preHeader={preHeader}
      maxWidth="450px"
    >
      <TemplatedText
        values={{
          date: () => {
            return (
              <>
                {dateTimeObj?.format('dddd MMMM Do')}
                <br />
              </>
            );
          },
          time: dateTimeObj?.format('h:mm a'),
        }}
      >
        {headerText}
      </TemplatedText>
    </styles.ScheduleHeaderStyle>
  );
};

const Footer = () => {
  return (
    <styles.FooterStyle>
      <Logo styleType={Style.MonoLight} />
      <nav>
        {footerNavItems.map((item) => (
          <a
            href={Object.keys(item).map((key) => item[key])[0]}
            key={Object.keys(item)[0]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {Object.keys(item)[0]}
          </a>
        ))}
      </nav>
    </styles.FooterStyle>
  );
};

const BodyBlockDefault = ({ product, name, firstName, phoneFormatted }) => {
  const bodyText = [];

  if (name) {
    bodyText.push(i18n.withName);
  }

  if (phoneFormatted) {
    bodyText.push(i18n.nameWillCallYouAtPhone);
  }

  bodyText.push(i18n.wereGoing);

  return (
    <TemplatedText
      values={{
        name,
        firstName: firstName || i18n.we,
        phone: phoneFormatted,
        aOrAnProductName: `${product === Products.HEI ? i18n.an : i18n.a} ${
          productFullName[product]
        }`,
      }}
    >
      {bodyText.join(' ')}
    </TemplatedText>
  );
};

const BodyBlockWithApplication = ({ hasScheduleTime, name, firstName, phoneFormatted }) => {
  const bodyText = [];

  if (name && hasScheduleTime) {
    bodyText.push(i18n.withName);
  }

  if (firstName && phoneFormatted) {
    bodyText.push(i18n.nameWillCallYouAtPhone);
  }

  bodyText.push(i18n.duringTheCall);

  return (
    <TemplatedText
      values={{
        firstName,
        name,
        phone: phoneFormatted,
      }}
    >
      {bodyText.join(' ')}
    </TemplatedText>
  );
};

const BodyBlock = ({ product, hasScheduleTime, name, firstName, phoneFormatted, estimate }) => {
  if (estimate) {
    return (
      <P>
        <BodyBlockWithApplication
          hasScheduleTime={hasScheduleTime}
          name={name}
          firstName={firstName}
          phoneFormatted={phoneFormatted}
        />
      </P>
    );
  }

  return (
    <P>
      <BodyBlockDefault
        product={product}
        name={name}
        firstName={firstName}
        phoneFormatted={phoneFormatted}
      />
    </P>
  );
};

const LastBlockWithHEIBrochureLink = () => {
  return (
    <>
      <P>
        <TemplatedText
          values={{
            brochureLinkText: (
              <a href={directory.pointLink.brochure} target="_blank" rel="noopener noreferrer">
                {i18n.brochureLinkText}
              </a>
            ),
          }}
        >
          {i18n.inThe}
        </TemplatedText>
      </P>
      <P>{i18n.lookingForward}</P>
    </>
  );
};

const LastBlockWithStartApplicationCTA = ({ estimate }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = () => {
    if (!estimate.getApplicant()?.getIsComplete()) {
      dispatch(setSaveApplicantContactModalOpen(true));
      return;
    }

    const applicationPage = generateUrlFromPage(pages.HEI_APPLICATION, {
      estimateKey: estimate.key,
    });
    history.push(applicationPage);
  };

  return (
    <styles.CTASection>
      <Button
        styleSize="large"
        styleAlign="right"
        mobileBlock
        iconType={IconName.ChevronRight}
        onClick={handleClick}
      >
        {i18n.startApplication}
      </Button>
    </styles.CTASection>
  );
};

const LastBlock = ({ product, estimate }) => {
  if (estimate) {
    return <LastBlockWithStartApplicationCTA estimate={estimate} />;
  }
  if (product === Products.HEI) {
    return <LastBlockWithHEIBrochureLink />;
  }
  return <P>{i18n.lookingForward}</P>;
};

const ScheduleCompletePage = ({ applicant: applicantFromProps, product = Products.HEI }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const applicantFromStore = useSelector(getApplicantModel);
  const applicant = applicantFromStore || applicantFromProps;
  const estimate = useSelector(getHEIEstimateModel);
  const estimateLoading = useSelector(getEstimatesAreLoading);
  const params = useParams();
  const location = useLocation();
  const scheduledAvailability = AvailabilityModel.getFromSessionStorage(SCHEDULED_STORAGE_KEY);
  const estimateKey =
    params?.estimateKey ||
    getURLParam('estimateid', location) ||
    getURLParam('estimateKey', location);
  const email = getURLParam('email', location);
  const phone = getURLParam('phone', location) || applicant.phone;
  const dateTime = getURLParam('datetime', location);
  const { response, loading: middlewareLoading } = useRequest({
    request: fetchDirectoryUser,
    runOnMount: true,
    requestData: email,
  });

  let time = scheduledAvailability ? parseInt(scheduledAvailability?.timestamp, 10) : null;
  if (dateTime) {
    time = dateTime;
  }

  // accept numerical timestamps
  if (time?.length && /^[0-9]*$/.test(time)) {
    time = parseInt(time, 10);
  }

  const dateTimeObj = time && dayjs(time).isValid() ? dayjs(time) : null;
  const phoneFormatted = phone
    ? new InputMask({ type: InputMaskType.Phone }).getFormatted(phone)
    : null;

  const [accountManager] = response || [];

  const { image: accountManagerImage, name, firstName } = accountManager || {};
  const hasImage = typeof accountManagerImage === 'string';

  // get the hei estimate if there is an estimate id in the URL
  useEffect(() => {
    if (!estimateKey || estimate) {
      return;
    }
    dispatch(getEstimate({ estimateKey }));
  }, [dispatch, estimateKey, estimate]);

  if (middlewareLoading || estimateLoading) {
    return <FullScreenLoading />;
  }

  const handleApplicantSave = () => {
    const applicationPage = generateUrlFromPage(pages.HEI_APPLICATION, {
      estimateKey: estimate.key,
    });
    history.push(applicationPage);
  };

  return (
    <>
      <styles.ScheduleCompletePageStyle>
        <styles.ScheduleCompleteBodyStyle />
        <Helmet title={i18n.heiPageTitle} />
        <ModalPageStyle>
          <styles.SchedulePageContainerStyle hasImage={hasImage}>
            <main>
              {hasImage && <ProfileImage image={accountManagerImage} styleSize={Size.Large} />}
              <HeaderBlock
                product={product}
                firstName={firstName}
                name={name}
                phoneFormatted={phoneFormatted}
                dateTimeObj={dateTimeObj}
              />
              <BodyBlock
                product={product}
                firstName={firstName}
                name={name}
                phoneFormatted={phoneFormatted}
                hasScheduleTime={dateTimeObj?.isValid()}
                estimate={estimate}
              />
              <LastBlock estimate={estimate} product={product} />
            </main>
            <Footer />
            <PointCopyright />
          </styles.SchedulePageContainerStyle>
        </ModalPageStyle>
      </styles.ScheduleCompletePageStyle>
      <ContactModal
        applicant={applicant}
        estimateKey={estimateKey}
        onSubmitValid={handleApplicantSave}
      />
    </>
  );
};

export default ScheduleCompletePage;
